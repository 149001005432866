<template>
  <div>
    <td style="width: 100%; display: block">
      <v-menu
        v-model="menu"
        :close-on-content-click="false"
        :nudge-width="70"
        :width="400"
        :max-width="400"
        offset-x
        :tile="false"
        v-if="!appointment"
      >
        <template v-slot:activator="{ attrs }">
          <div
            @click="checkGroup"
            v-bind="attrs"
            class="fach pa-2 white--text"
            style="width: 100%; display: block"
          >
            <v-row class="ml-2 mr-1 font-weight-medium">
              <div
                class="pa-3 mr-2"
                :style="'position: relative;border-radius: 10px; color: #BDBDBD; box-shadow: 1px 2px 3px silver; width: 100%; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; background-color: #FFF'"
              >
                <img
                  v-if="getSrc()"
                  class="iconSelect"
                  :src="getSrc()"
                  alt="Fach-Icon"
                />
                <div v-else class="iconSelect" style="border-radius: 5px"></div>
                {{ thisSubject ? thisSubject.name : "Hinzufügen" }}
                <!--                                <img :src="getColorIcon()" style="position: absolute; height: 25px; top: 0; right: 10px" alt="Lesezeichen-Icon"/>-->
              </div>

              <!--                            <div style="width: 35px">-->
              <!--                                <v-btn x-small class="pa-0" width="30px" style="height: 100%; background-color: white; border-radius: 8px; margin-right: 5px">-->
              <!--                                    <img :src="beitretenKameraIcon" style="height: 30px" alt="beitreten-Icon"/>-->
              <!--                                </v-btn>-->
              <!--                            </div>-->
            </v-row>
          </div>
        </template>

        <v-card>
          <div
            class="fach; white--text pb-3 pt-5"
            :style="
              'background-color: ' +
              (thisSubject ? thisSubject.color : '#CACACA')
            "
          >
            <v-row class="ml-5 font-weight-medium" style="font-size: large">
              <v-icon
                style="
                  margin-right: 5px;
                  margin-bottom: 2px;
                  background-color: #ffffff55;
                  padding: 5px;
                "
                x-small
                >fas fa-school</v-icon
              >
              <v-icon
                style="margin-left: auto; margin-right: 40px"
                @click="cancel"
                dark
                >fas fa-times</v-icon
              >
            </v-row>
          </div>

          <v-row class="mx-1">
            <v-col cols="5" class="textGrau"> Fach: </v-col>
            <v-col cols="7">
              <select v-model="thisSubject" class="input">
                <option
                  v-for="(item, index) in subjects"
                  :key="index"
                  selected
                  :value="item"
                  class="textGrau"
                >
                  {{ item.name }}
                </option>
              </select>
            </v-col>

            <v-col cols="5" class="textGrau"> Lehrer: </v-col>
            <v-col cols="7">
              <select
                :disabled="!thisSubject"
                v-model="thisTeacher"
                class="input"
              >
                <option
                  v-for="(item, index) in thisSubject
                    ? thisSubject.teachers
                    : []"
                  :key="index"
                  selected
                  :value="item"
                >
                  {{ item.lastName + " " + item.name }}
                </option>
              </select>
            </v-col>
          </v-row>
          <v-row class="mx-1 pb-0">
            <v-col class="textGrau pb-0"> Wiederholung: </v-col>
          </v-row>
          <v-row class="mx-1 py-0">
            <v-col cols="6" class="py-0">
              <v-checkbox
                style="margin-top: 0 !important"
                v-model="weekly"
                label="Wöchentlich bis"
              />
            </v-col>
            <v-col cols="6" class="py-0">
              <input
                v-model="weeklyTill"
                type="date"
                class="input"
                :disabled="!weekly"
                :class="{ errorInput: fields.weeklyField }"
              />
            </v-col>
          </v-row>

          <v-btn
            dark
            class="mb-3 mt-4"
            style="
              background-color: #6995cd;
              border-radius: 8px;
              width: 50%;
              margin-left: auto !important;
              margin-right: auto !important;
              display: block;
            "
            tile
            @click="saveAppointment"
            >Speichern</v-btn
          >
          <v-btn
            class="my-0 textGrau"
            style="
              background-color: #eaeaea;
              border-radius: 8px;
              width: 50%;
              margin-left: auto !important;
              margin-right: auto !important;
              display: block;
            "
            tile
            @click="cancel"
            dark
            >Abbrechen</v-btn
          >
          <br />
        </v-card>
      </v-menu>

      <v-menu
        v-model="menu"
        :close-on-content-click="false"
        :nudge-width="70"
        :width="400"
        :max-width="400"
        offset-x
        :tile="false"
        v-else
      >
        <template v-slot:activator="{ attrs }">
          <div
            @click="checkGroup"
            v-bind="attrs"
            class="fach; pa-2 white--text"
            style="width: 100%; display: block"
          >
            <v-row class="ml-2 mr-1 font-weight-medium">
              <div
                class="pa-3 mr-2"
                :style="'position: relative; border-radius: 10px; color: #707070; box-shadow: 1px 2px 3px silver; width: 100%; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; background-color: #FFF'"
              >
                <img
                  :src="getColorIcon()"
                  style="position: absolute; height: 25px; top: 0; right: 10px"
                  alt="Lesezeichen-Icon"
                />
                <img
                  v-if="getSrc()"
                  class="iconSelect"
                  :src="getSrc()"
                  alt="Fach-Icon"
                />
                <div v-else class="iconSelect"></div>
                {{ appointment.schoolSubject.name }}
              </div>

              <!--                            <div style="width: 35px">-->
              <!--                                <v-btn x-small class="pa-0" width="30px" style="height: 100%; background-color: white; border-radius: 8px; margin-right: 5px">-->
              <!--                                    <img :src="beitretenKameraIcon" style="height: 30px" alt="beitreten-Icon"/>-->
              <!--                                </v-btn>-->
              <!--                            </div>-->
            </v-row>
          </div>
        </template>

        <v-card>
          <div
            class="fach; white--text pb-3 pt-5"
            :style="
              'background-color: ' +
              (thisSubject ? thisSubject.color : '#CACACA')
            "
          >
            <v-row class="ml-5 font-weight-medium" style="font-size: large">
              <v-icon
                style="
                  margin-right: 5px;
                  margin-bottom: 2px;
                  background-color: #ffffff55;
                  padding: 5px;
                "
                x-small
                >fas fa-school</v-icon
              >
              <v-icon
                style="margin-left: auto; margin-right: 40px"
                @click="cancel"
                dark
                >fas fa-times</v-icon
              >
            </v-row>
          </div>

          <v-row class="mx-1">
            <v-col cols="5" class="textGrau"> Fach: </v-col>
            <v-col cols="7">
              <select v-model="thisSubject" class="input textGrau">
                <option
                  v-for="(item, index) in subjects"
                  :key="index"
                  selected
                  :value="item"
                  class="textGrau"
                >
                  {{ item.name }}
                </option>
              </select>
            </v-col>

            <v-col cols="5" class="textGrau"> Lehrer: </v-col>
            <v-col cols="7">
              <select
                :disabled="!thisSubject"
                v-model="thisTeacher"
                class="input"
              >
                <option
                  v-for="(item, index) in thisSubject
                    ? thisSubject.teachers
                    : []"
                  :key="index"
                  selected
                  :value="item"
                  class="textGrau"
                >
                  {{ item.lastName + " " + item.name }}
                </option>
              </select>
            </v-col>
          </v-row>

          <v-btn
            class="success mb-3 mt-4"
            style="
              width: 90%;
              margin-left: auto !important;
              margin-right: auto !important;
              display: block;
            "
            tile
            @click="updateAppointment"
            >Speichern</v-btn
          >
          <v-btn
            class="error mb-3 mt-4"
            style="
              width: 90%;
              margin-left: auto !important;
              margin-right: auto !important;
              display: block;
            "
            tile
            @click="deleteDialog = true"
            >Löschen</v-btn
          >
          <v-btn
            class="grey my-0"
            style="
              width: 90%;
              margin-left: auto !important;
              margin-right: auto !important;
              display: block;
            "
            tile
            @click="cancel"
            dark
            >Schließen</v-btn
          >
          <br />
        </v-card>
      </v-menu>
    </td>

    <!--        Delete dialog-->
    <v-dialog v-model="deleteDialog" persistent max-width="400">
      <v-card>
        <v-card-title class="headline">Termin löschen?</v-card-title>
        <v-card-text>
          Sind sie sicher, dass sie diesen Termin löschen möchten? <br/>
          <v-radio-group v-model="deleteRecurringAppointment" v-if="appointment && appointment.rule">
            <v-radio :value="'onlyThis'" label="Nur diesen Termin"></v-radio>
            <v-radio :value="'thisAndFollowing'" label="Diesen Termin und alle folgenden"></v-radio>
            <v-radio :value="'allOfThem'" label="Alle Termine"></v-radio>
          </v-radio-group>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="" text @click="deleteDialog = false">Abbrechen</v-btn>
          <v-btn
            color="red red-1"
            text
            @click="deleteAction"
            >Löschen</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar bottom v-model="snackbar" color="error" timeout="3000">
      {{ snackbarText }}
    </v-snackbar>

    <v-snackbar bottom v-model="snackbarSave" color="success" timeout="3000">
      Gespeichert!
    </v-snackbar>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import dropdownIcon from "../../assets/Icons/unten-dropdown-12.svg";
import beitretenIcon from "../../assets/Icons/beitretensvg-06.svg";
import beitretenKameraIcon from "../../assets/Icons/kamera-video-73.svg";
import buchzeichenIcon from "../../assets/Icons/lesezeichen-01-43.svg";

import bioIcon from "../../assets/Icons/f-biologie-35.svg";
import chemieIcon from "../../assets/Icons/f-chemie-37.svg";
import deutschIcon from "../../assets/Icons/f-deutsch-29.svg";
import englischIcon from "../../assets/Icons/f-englisch-30.svg";
import erdkundeIcon from "../../assets/Icons/f-erdkunde-31.svg";
import geschichteIcon from "../../assets/Icons/f-geschichte-40.svg";
import matheIcon from "../../assets/Icons/f-mathe-32.svg";
import musikIcon from "../../assets/Icons/f-musik-84.svg";
import physikIcon from "../../assets/Icons/f-physik-36.svg";
import politikIcon from "../../assets/Icons/f-politik-34.svg";
import religionIcon from "../../assets/Icons/f-religion-39.svg";
import sportIcon from "../../assets/Icons/f-sport-38.svg";
import wirtschaftIcon from "../../assets/Icons/f-wirtschaft-33.svg";

import colorfcd20a from "../../assets/Icons/lesezeichen-gelb-47.svg";
import colorf0b423 from "../../assets/Icons/lesezeichen-orange-48.svg";
import colorff6941 from "../../assets/Icons/lesezeichen-rot-49.svg";
import color91c364 from "../../assets/Icons/lesezeichen-hellgrün-50.svg";
import color91c887 from "../../assets/Icons/lesezeichen-mintgrün-51.svg";
import colord7875f from "../../assets/Icons/lesezeichen-braun-52.svg";
import colorebaabe from "../../assets/Icons/lesezeichen-rosa-53.svg";
import color9b91c8 from "../../assets/Icons/lesezeichen-lila-54.svg";
import color6ec3dc from "../../assets/Icons/lesezeichen-blau-55.svg";
import color9bcdc3 from "../../assets/Icons/lesezeichen-minze-56.svg";
import colorb2b2b2 from "../../assets/Icons/lesezeichen-hellgrau-43.svg";
import color707070 from "../../assets/Icons/lesezeichen-dunkelgrau-44.svg";

export default {
  name: "Fach",
  components: {},
  data() {
    return {
      menu: false,
      deleteDialog: false,
      deleteRecurringAppointment: 'onlyThis',
      snackbar: false,
      snackbarText: "",
      snackbarSave: false,
      subjects: [],
      weekly: false,
      weeklyTill: "",
      fields: {
        weeklyField: false,
      },

      thisSubject: null,
      thisTeacher: null,
      //   appointment: null,

      beitretenIcon,
      beitretenKameraIcon,
      buchzeichenIcon,

      bioIcon,
      chemieIcon,
      deutschIcon,
      englischIcon,
      erdkundeIcon,
      geschichteIcon,
      matheIcon,
      musikIcon,
      physikIcon,
      politikIcon,
      religionIcon,
      sportIcon,
      wirtschaftIcon,

      colorfcd20a,
      colorf0b423,
      colorff6941,
      color91c364,
      color91c887,
      colord7875f,
      colorebaabe,
      color9b91c8,
      color6ec3dc,
      color9bcdc3,
      colorb2b2b2,
      color707070,
    };
  },
  props: {
    subject: { required: false, default: null },
    id: { required: false, default: null },
    teacher: { required: false, default: null },
    group: { required: false, default: null },
    day: { required: false, default: null },
    timeslot: { required: false, default: null },
    appointment: { required: false, default: null },
    requestGroup: { required: true },
  },
  computed: {
    ...mapGetters('subjects', [ 'getSubjectInvalidStatus' ]),
  },
  async mounted() {
    this.thisSubject = this.subject;
    this.thisTeacher = this.teacher;
  },
  watch: {
    subject() {
      this.thisSubject = this.subject;
    },
    teacher() {
      this.thisTeacher = this.teacher;
    },
    // appointment() {
    //   this.appointment = this.appointment;
    // },
    thisSubject() {
      if (this.thisSubject && this.thisSubject.teachers) {
        this.thisTeacher = this.thisSubject.teachers.find((el) => {
          return el._id === this.appointment.teacher._id;
        });
      }
    },
    getSubjectInvalidStatus(newVal, oldVal) {
      if (newVal && !oldVal) {
        this.requestSubjects();
      }
    },
  },
  methods: {
    ...mapActions('subjects', ['getSubjects', 'setSubjectListStatus']),
    ...mapActions('appointments', [
        'createAppointment',
        'editAppointment',
        'deleteAppointment',
        'deleteAppointmentFromRule',
        'deleteAppointmentByRule',
    ]),
    getSrc() {
      return this.thisSubject
        ? this[this.thisSubject.icon]
        : this.appointment
        ? this[this.appointment.schoolSubject.icon]
        : null;
    },

    getColorIcon() {
      return this.thisSubject
        ? this["color" + this.thisSubject.color.substring(1)]
        : this.appointment
        ? this["color" + this.appointment.schoolSubject.color.substring(1)]
        : this.colorb2b2b2;
    },
    checkGroup() {
      if (!this.group) {
        this.menu = false;
        this.snackbarText = "Bitte erst eine Gruppe auswählen!";
        this.snackbar = true;
      } else {
        this.requestSubjects();
        this.menu = true;
      }
    },
    cancel() {
      this.thisSubject = this.subject;
      this.thisTeacher = this.teacher;
      this.menu = false;
    },
    async requestSubjects() {
      this.subjects = await this.getSubjects();
      if (this.appointment) {
        this.thisSubject = this.subjects.find((el) => {
          return el._id == this.appointment.schoolSubject._id;
        });
        this.thisTeacher = this.thisSubject.teachers.find((el) => {
          return el._id == this.appointment.teacher._id;
        });
      }
      this.setSubjectListStatus(false);
    },

    validateInput() {
      let result = true;

      this.fields.weeklyField = false;

      if (this.weeklyTill.replace(/\s/g, "") === "" || this.isWeeklyInPast()) {
        this.fields.weeklyField = true;
        result = false;
      }
      return result;
    },
    isWeeklyInPast() {
      const tillDate = new Date(this.weeklyTill);
      const now = new Date();
      return tillDate.toString() !== "Invalid Date" && tillDate < now;
    },
    async updateAppointment() {
      if (!this.thisTeacher || !this.thisSubject) {
        this.snackbarText = "Bitte alle Daten eintragen!";
        this.snackbar = true;
        return;
      }
      let data = {
        _id: this.appointment._id,
        schoolSubject: this.thisSubject._id,
        teacher: this.thisTeacher._id,
        groupId: this.group._id,
        day: this.day,
        timeslot: this.timeslot._id,
      };
      let response = await this.editAppointment(data);
      if (Number.isInteger(response)) {
        if (response === 409) {
          this.snackbarText =
            "Das Bearbeiten dieses Termins war nicht möglich.";
        } else {
          this.snackbarText = "Ein unerwarteter Fehler ist aufgetretten!";
        }
        this.snackbar = true;
      } else {
        this.snackbarSave = true;
        this.requestGroup();
        this.menu = false;
      }
    },

    async saveAppointment() {
      if (!this.thisTeacher || !this.thisSubject) {
        this.snackbarText = "Bitte alle Daten eintragen!";
        this.snackbar = true;
        return;
      } else if (this.weekly) {
        if (!this.validateInput()) {
          this.snackbarText = "Bitte tragen Sie ein Datum in der Zukunft ein!";
          this.snackbar = true;
          return;
        }
      }
      let data = {
        schoolSubject: this.thisSubject._id,
        teacher: this.thisTeacher._id,
        groupId: this.group._id,
        day: this.day,
        timeslot: this.timeslot._id,
        ...(this.weekly
          ? {
              repeat: {
                interval: "weekly",
                from: new Date(this.day),
                until: this.weeklyTill,
              },
            }
          : {}),
      };
      let response = await this.createAppointment(data);
      if (Number.isInteger(response)) {
        if (response === 409) {
          this.snackbarText =
            "Der Termin konnte nicht erstellt werden.";
        } else {
          this.snackbarText = "Ein unerwarteter Fehler ist aufgetretten!";
        }
        this.snackbar = true;
      } else {
        this.snackbarSave = true;
        this.requestGroup();
        this.menu = false;
      }
    },

    /**
     * Method to delete one appointment or a repeating appointment
     * pretty nifty i know :)
     * @returns {Promise<void>} nothing of importance
     */
    async deleteAction() {

      let snackbar409Text = 'Der Termin konnte nicht gelöscht werden.'
      let response;

      const requestParams = {type: 'onlyThis', appointment: this.appointment};

      if (this.appointment.rule
              && (this.deleteRecurringAppointment === 'thisAndFollowing'
                      || this.deleteRecurringAppointment === 'allOfThem')){
          requestParams.type = this.deleteRecurringAppointment;
          snackbar409Text = 'Die Termine konnten nicht gelöscht werden.'
      }

      response = await this.deleteAppointment(requestParams);

      if (Number.isInteger(response)) {
        if (response === 409) {
          this.snackbarText = snackbar409Text;
        } else {
          this.snackbarText = "Ein unerwarteter Fehler ist aufgetreten!";
        }
        this.snackbar = true;
      } else {
        this.snackbarSave = true;
        this.thisSubject = null;
        this.requestGroup();
        this.menu = false;
      }
      this.deleteDialog = false;
    },
  },
};
</script>



<style lang="scss" scoped>
input[type="date"] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  min-height: 1.6rem;
}

.fach {
  cursor: pointer;
}

.textGrau {
  color: #707070;
}

.scroll-area {
  position: relative;
  margin: auto;
  max-height: 100px;
}

.scroll-area2 {
  position: relative;
  margin: auto;
  max-height: 100px;
}

.scroll-area3 {
  position: relative;
  margin: auto;
  max-height: 50px;
}

.input {
  display: block;
  width: 100%;
  max-width: 100%;
  border-radius: 5px;
  border-width: 0;
  color: #707070;
  padding: 1px 5px 1px 5px;
  border: none !important;
  background-color: #eaeaea;
  overflow: hidden;
  appearance: none;
}

.input[disabled] {
  color: grey;
  background-color: grey;
}

.iconSelect {
  display: inline-flex;
  width: 24px;
  height: 24px;
  padding: 2px;
  margin-bottom: -6px;
  border-radius: 10px;
  margin-right: 2px;
  background-color: rgba(234, 234, 234, 0.7);
}
</style>
