<template>
    <div style="position: absolute; top: 0; bottom: 0; left: 0; right: 0; background-color: #f8f8f8">
        <v-btn v-if="false" @click="showLoginTutorial()" class="mt-5 mr-5 helpBtn questionmarkIcon" text fixed right top>
            <v-img class="questionmarkIcon" :src="questionmark" contain/>
        </v-btn>

        <div class="schoolBoardBackgroundWrapper">
            <img class="schoolBoardBackground" :src="tafel"/>
        </div>
        <!--login with tafel background-->
        <div class="loginFormWrapper">
            <div class="loginForm positionLoginForm">
                <div>
                    <v-img :src="logo" style="width: 80%" class="center mb-4"/>
                </div>

                <div class="loginFormInputs">
                    <div class="loginFormRow">
                        <v-text-field
                            label="Name"
                            outlined
                            light
                            hide-details
                            @keydown="(keyEvent) => {if (keyEvent.key === 'Enter') {clickLoginUser();} }"
                            v-model="userName"
                            class="font-weight-bold"
                            color="#707070 !important"
                        >
                            <template v-slot:append-outer>
                                <img :src="headIcon" style="display: block; height: 30px;"/>
                            </template>
                        </v-text-field>
                    </div>

                    <div class="loginFormRow">
                        <v-text-field
                            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                            @click:append="show1 = !show1"
                            :type="show1 ? 'text' : 'password'"
                            @keydown="(keyEvent) => {if (keyEvent.key === 'Enter') {clickLoginUser();} }"
                            label="Passwort"
                            outlined
                            light
                            hide-details
                            v-model="password"
                            class="font-weight-bold"
                            color="#707070 !important"
                        >
                            <template v-slot:append-outer>
                                <img :src="loginIcon" style="height: 30px"/>
                            </template>
                        </v-text-field>
                    </div>

                    <div class="loginFormRow stayLoggedIn mb-0" style="justify-content: center">
                        <v-checkbox class="mt-0 pt-0" label="Angemeldet bleiben" v-model="stayLoggedIn">  </v-checkbox>
                    </div>
                </div>

                <v-row class="loginBtnWrapper">
                    <v-col class="pa-0">

                    <v-dialog
                        v-model="qrcodeDialog"
                        fullscreen
                        hide-overlay
                        transition="dialog-bottom-transition"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                large
                                v-bind="attrs"
                                v-on="on"
                                outlined
                                class="text-capitalize loginBtn"
                                id="qrLoginButton"
                            >
                                <img :src="qrLoginIcon" class="mr-2 icon"/>
                                QR-Code Login
                            </v-btn>
                        </template>
                        <v-card>
                            <q-r-scan-login
                                :code-scan-active="qrcodeDialog"
                                :return-function="qrLoginUser"
                                :close-function="() => {qrcodeDialog = false;}"
                            >
                            </q-r-scan-login>
                        </v-card>
                    </v-dialog>
                    </v-col>
                    <v-col class="pa-0">

                    <v-btn
                        outlined
                        large
                        @click="clickLoginUser"
                        class="loginBtn text-capitalize"
                        id="regularLoginBtn"
                    >
                        <img :src="beitretenIcon" class="mr-2 icon"/>
                        Login
                    </v-btn>
                    </v-col>
                    <v-col :cols="$vuetify.breakpoint.width > 500 ? 1 : 0" class="pa-0"></v-col>
                </v-row>
            </div>
        </div>

        <!--<TTS></TTS>-->

        <!--TODO implement password recovery-->
        <!--<v-btn
            elevation="0"
            style="position: absolute; left: 2%; bottom: 5%; color: #707070"
            color="transparent"
        >
            <p class="text-none ma-0">Passwort verloren?</p>
        </v-btn>-->

        <v-snackbar
            bottom v-model="snackbars.credentialsNotFound"
            color="error"
            timeout="5000">
            Kein Nutzer mit diesen Daten gefunden!
        </v-snackbar>
        <v-snackbar
            v-model="snackbars.backendNotReachable"
            timeout="5000"
            color="error"
        >
            Die Server sind gerade nicht erreichbar. Versuche es später nochmal.
        </v-snackbar>
    </div>
</template>

<script>
import {mapState, mapMutations, mapActions, mapGetters} from "vuex";
import logo from "../assets/logo_klassenraum.png";
import closeIcon from "../assets/Icons/abbrechen-08.svg";
import qrLoginIcon from "../assets/Icons/qr-code-69.svg";
import qrLoginBigIcon from "../assets/Icons/qr-code-72-duenn.svg";
import loginIcon from "../assets/Icons/passwort-02.svg";
import headIcon from "../assets/Icons/profil-schueler.svg";
import beitretenIcon from "../assets/Icons/beitretensvg-06.svg";
import tafel from "../assets/tafel_login.png";
import questionmark from "@/assets/Tooltip/fragezeichen.svg";
import TTS from "./TextToSpeech";
import QRScanLogin from "./QRScanLogin";
import {tutorials} from "@/constants/tutorial";

export default {
    name: "Login",
    components: {
        QRScanLogin,
        /*TTS,*/
    },
    data() {
        return {
            userName: "",
            password: "",
            show1: false,
            snackbars: {
                credentialsNotFound: false,
                backendNotReachable: false,
            },
            qrcodeDialog: false,
            stayLoggedIn: false,
            logo,
            closeIcon,
            qrLoginIcon,
            qrLoginBigIcon,
            headIcon,
            loginIcon,
            beitretenIcon,
            tafel,
            questionmark,
            tutorials,
            showLoginTut: false,
        };
    },
    props: {},
    computed: {
        ...mapState("auth", ["account"]),
    },
    mounted() {
        const storageShowLoginTutorial = localStorage.getItem("showLoginTutorial");
        this.showLoginTut =
            storageShowLoginTutorial === null
                ? true
                : storageShowLoginTutorial === "true";
    },
    watch: {
        stayLoggedIn(newVal)  {
          this.changeStayLoggedIn(newVal);
        },
    },
    methods: {
        ...mapActions("auth", ["loginUser", "changeStayLoggedIn"]),
        closeLoginTutorial() {
            console.log("Close login tutorial");
            this.showLoginTut = false;
            localStorage.setItem("showLoginTutorial", "false");
            this.qrcodeDialog = true;
        },

        showLoginTutorial() {
            this.showLoginTut = true;
            localStorage.setItem("showLoginTutorial", "true");
        },

        async clickLoginUser() {
            try {
                if (
                    !(await this.loginUser({
                        accountName: this.userName,
                        password: this.password,
                    }))
                ) {
                    this.snackbars.credentialsNotFound = true;
                }
            } catch (e) {
                this.snackbars.backendNotReachable = true;
            }
        },
        async qrLoginUser(userData) {
            this.userName = userData.name;
            this.password = userData.pw;
            await this.clickLoginUser();
            return;
        },
    },
};
</script>

<style lang="scss" scoped>
.helpBtn {
    z-index: 1;
    padding: 16px;
}

.loginBtnWrapper {
    display: flex;
    justify-content: center;
    width: 450px;
    max-width: 450px;
    margin: auto;
}

.loginBtn {
    color: white;
    background-color: #8cbd46;
    width: 100%;
}

#qrLoginButton {
    background-color: #6996cd;
}

.schoolBoardBackgroundWrapper {
    // https://www.w3schools.com/howto/howto_css_center-vertical.asp
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.schoolBoardBackground {
    margin-top: 5%;
    max-height: 900px;
    height: 90%;
}

.loginFormWrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: flex-start;
    // align-content: center;
    align-items: center;
    // overflow-x: visible;
    // box-sizing: contet-box;
}

.loginFormWrapper::before {
    display: block;
    width: 0;

    content: "";
    flex: 1 1 0;
    background-color: red;
}

.loginFormWrapper::after {
    width: 0;
    content: "";
    flex: 1 1 350px;
}

.loginForm {
    max-width: 600px;
    min-width: 100px;
    margin: 0 auto;
    padding: 28px;
    background-color: transparent;
    z-index: 1;
    flex: 0 0 auto;
}

.positionLoginForm {
    margin: 0;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.loginFormInputs {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.loginFormRow {
    width: 450px;
    max-width: 450px;
    margin-bottom: 15px;
}

.stayLoggedIn {
    height: 35px;
}

#questionmark-container {
    position: fixed;
    top: 0;
    right: 0;
}

.colorSchueler {
    color: $schueler-blau;
    margin: auto;
}

.marginAuto {
    margin: auto;
}

.center {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
}

.icon {
    height: 20px;
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7458%) hue-rotate(61deg) brightness(109%) contrast(111%);
}

.questionmarkIcon {
    height: 5rem;
    width: 5rem;
}

@media only screen and (max-height: 400px) {
    .positionLoginForm {
        top: 50%;
    }
}

@media only screen and (max-width: 500px) {
    .helpBtn {
        top: 1%;
        right: 1%;
    }

    .loginForm {
        width: 300px;
    }

    .loginFormRow {
        width: 250px;
    }

    .loginBtnWrapper {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 250px;
    }

    .loginBtn {
        width: 210px;
    }

    #regularLoginBtn {
        margin-top: 12px;
    }
}

.v-snack.v-snack--top {
    bottom: initial; /* or auto */
}

.v-snack.v-snack--bottom {
    top: initial; /* or auto */
}

@media only screen and (max-width: 850px), screen and (max-height: 600px) {
    .schoolBoardBackgroundWrapper {
        display: none;
    }

    .schoolBoardBackground {
        display: none;
    }

    .questionmarkIcon {
        height: 2.5rem;
        width: 2.5rem;
    }
}

@media all and (orientation: portrait) {
    .schoolBoardBackgroundWrapper {
        display: none;
    }

    .schoolBoardBackground {
        display: none;
    }
}
</style>
