var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app-bar",
    {
      staticClass: "navBar pa-0",
      staticStyle: { "background-color": "transparent", height: "130px" },
      attrs: { flat: "" }
    },
    [
      _c("v-img", {
        staticClass: "navImg",
        attrs: { src: require("./../assets/Bilder/eStundenplan_Logo.png") }
      }),
      _c("v-spacer"),
      this.$vuetify.breakpoint.mdAndUp
        ? _c("div", [
            _c(
              "a",
              {
                staticClass: "navLink",
                staticStyle: { color: "#707070" },
                attrs: { href: "#stundenplan" }
              },
              [_vm._v("WAS IST MEIN eSTUNDENPLAN")]
            ),
            _c(
              "a",
              {
                staticClass: "navLink",
                staticStyle: { color: "#707070" },
                attrs: { href: "#funktionen" }
              },
              [_vm._v("FUNKTIONEN")]
            ),
            _c(
              "a",
              {
                staticClass: "navLink spaceRight",
                staticStyle: { color: "#707070" },
                attrs: { href: "#interesse" }
              },
              [_vm._v("INTERESSE?")]
            )
          ])
        : _c(
            "v-menu",
            {
              attrs: {
                "offset-y": "",
                "close-on-click": "",
                "close-on-content-click": "",
                tile: ""
              },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    return [
                      _c(
                        "v-btn",
                        _vm._g({ attrs: { elevation: "0" } }, on),
                        [
                          _c("v-icon", { staticStyle: { color: "#707070" } }, [
                            _vm._v("mdi-menu")
                          ])
                        ],
                        1
                      )
                    ]
                  }
                }
              ])
            },
            [
              _c(
                "v-list",
                [
                  _c("v-list-item", [
                    _c(
                      "a",
                      {
                        staticStyle: { color: "#707070", "font-size": "16px" },
                        attrs: { href: "#stundenplan" }
                      },
                      [_vm._v("WAS IST MEIN eSTUNDENPLAN")]
                    )
                  ]),
                  _c("v-list-item", [
                    _c(
                      "a",
                      {
                        staticStyle: { color: "#707070", "font-size": "16px" },
                        attrs: { href: "#funktionen" }
                      },
                      [_vm._v("FUNKTIONEN")]
                    )
                  ]),
                  _c("v-list-item", [
                    _c(
                      "a",
                      {
                        staticStyle: { color: "#707070", "font-size": "16px" },
                        attrs: { href: "#interesse" }
                      },
                      [_vm._v("INTERESSE?")]
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }