import { render, staticRenderFns } from "./Landingpage.vue?vue&type=template&id=23c26448&scoped=true&"
import script from "./Landingpage.vue?vue&type=script&lang=js&"
export * from "./Landingpage.vue?vue&type=script&lang=js&"
import style0 from "./Landingpage.vue?vue&type=style&index=0&id=23c26448&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23c26448",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCarousel } from 'vuetify/lib/components/VCarousel';
import { VCarouselItem } from 'vuetify/lib/components/VCarousel';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCarousel,VCarouselItem,VContainer,VImg,VSpacer})


/* hot reload */
if (module.hot) {
  var api = require("/home/peternauroth/homeschooling/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('23c26448')) {
      api.createRecord('23c26448', component.options)
    } else {
      api.reload('23c26448', component.options)
    }
    module.hot.accept("./Landingpage.vue?vue&type=template&id=23c26448&scoped=true&", function () {
      api.rerender('23c26448', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/Landingpage.vue"
export default component.exports