const translateToTargetLang = async (targetLang, textToTranslate) => {
    console.log('translateToTargetLang called with targetLang '+ targetLang + ' and textToTranslate '+ textToTranslate);
    try {

        var url = "https://translation.googleapis.com/language/translate/v2";
        //Strings requiring translation
        url += "?q=" + textToTranslate;
        //Target language
        url += "&target=" + targetLang;
        //Replace with your API key
        url += "&key=AIzaSyDgUVKM_f8YYIq91h7WIrFhdSWap-13AzM";

        const request = await fetch(url);

        const response = await request.json();

        let tmp = document.createElement('div');
        tmp.innerHTML = response.data.translations[0].translatedText;
        const result = tmp.textContent || tmp.innerText;

        return result;
    } catch (e) {
        console.error(e);
        return e.message;
    }
};

const getAvailableLanguages = async () => {
    console.log('getAvailableLanguages called');

    console.log(window.speechSynthesis.getVoices());

    const availableTTSLanguages = await window.speechSynthesis.getVoices();
}

export default {
    translateToTargetLang,
    getAvailableLanguages
}
