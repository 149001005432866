var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: {
        width: "90%",
        "padding-right": "3px",
        "padding-left": "-3px"
      }
    },
    [
      _c("progress-bar", {
        attrs: {
          title: "Hochladen",
          "show-progress": _vm.showTeacherUploadProgress,
          progress: _vm.teacherUploadProgress,
          "abort-progress": _vm.teacherXmlHttpRequest
            ? function() {
                _vm.teacherXmlHttpRequest.abort()
              }
            : function() {}
        }
      }),
      _c("name-file", {
        attrs: {
          "original-name": _vm.uploadFileName,
          callback: _vm.fileNameCallback
        },
        model: {
          value: _vm.showNameFile,
          callback: function($$v) {
            _vm.showNameFile = $$v
          },
          expression: "showNameFile"
        }
      }),
      _vm.thisAppointment
        ? _c(
            "div",
            {
              staticStyle: {
                width: "100%",
                display: "block",
                "padding-left": "0 !important"
              }
            },
            [
              _vm.isRunning
                ? _c(
                    "v-row",
                    {
                      staticClass: "pa-0 ma-0 vRowHeight",
                      attrs: { cols: "12" }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "pa-3 fachContainer",
                          staticStyle: { width: "calc(100% - 76px)" },
                          on: {
                            click: function($event) {
                              return _vm.readInfo()
                            }
                          }
                        },
                        [
                          _vm.getSrc()
                            ? _c("img", {
                                staticClass: "iconSelect",
                                style: _vm.isRunning
                                  ? _vm.getBackgroundColor()
                                  : "background-color: transparent",
                                attrs: {
                                  src: _vm.getSrcWhite(
                                    _vm.thisAppointment.schoolSubject.icon
                                  )
                                }
                              })
                            : _c("div", { staticClass: "iconSelect" }),
                          _c("div", { staticClass: "subjectText pl-2" }, [
                            _vm._v(
                              _vm._s(_vm.thisAppointment.schoolSubject.name)
                            )
                          ]),
                          _c("img", {
                            staticStyle: {
                              position: "absolute",
                              height: "40px",
                              top: "0",
                              right: "5%"
                            },
                            attrs: { src: _vm.getColorIcon() }
                          })
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "pa-0 kameraIcon",
                          on: { click: _vm.clickBeitreten }
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "pa-0",
                              class: { isFachRunning: _vm.isRunning },
                              staticStyle: {
                                height: "100%",
                                width: "100%",
                                "background-color": "white",
                                "border-radius": "8px"
                              }
                            },
                            [
                              _c("img", {
                                class: { isFachRunningIcon: _vm.isRunning },
                                staticStyle: { height: "48px" },
                                attrs: { src: _vm.kameraIconWeiss }
                              })
                            ]
                          )
                        ],
                        1
                      )
                    ]
                  )
                : _c(
                    "v-row",
                    {
                      staticClass: "pa-0 ma-0 vRowHeight",
                      attrs: { cols: "12" }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "pa-3 fachContainer",
                          staticStyle: { opacity: "40%" },
                          style: _vm.hasRun ? "width: calc(100% - 76px)" : "",
                          on: {
                            click: function($event) {
                              return _vm.readInfo()
                            }
                          }
                        },
                        [
                          _vm.getSrc()
                            ? _c("img", {
                                staticClass: "iconSelect",
                                staticStyle: {
                                  "background-color": "transparent"
                                },
                                attrs: { src: _vm.getSrc() }
                              })
                            : _c("div", { staticClass: "iconSelect" }),
                          _c("div", { staticClass: "subjectText  pl-2" }, [
                            _vm._v(
                              _vm._s(_vm.thisAppointment.schoolSubject.name)
                            )
                          ]),
                          _c("img", {
                            staticStyle: {
                              position: "absolute",
                              height: "40px",
                              top: "0",
                              right: "5%"
                            },
                            attrs: { src: _vm.getColorIcon() }
                          })
                        ]
                      ),
                      _vm.hasRun
                        ? _c(
                            "div",
                            {
                              staticClass: "pa-0 kameraIcon",
                              staticStyle: { opacity: "40%" },
                              on: { click: _vm.clickBeitreten }
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "pa-0",
                                  class: { isFachRunning: _vm.hasRun },
                                  staticStyle: {
                                    height: "100%",
                                    width: "100%",
                                    "background-color": "white",
                                    "border-radius": "8px"
                                  }
                                },
                                [
                                  _c("img", {
                                    class: { isFachRunningIcon: _vm.hasRun },
                                    staticStyle: { height: "48px" },
                                    attrs: { src: _vm.kameraIconWeiss }
                                  })
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ]
                  )
            ],
            1
          )
        : _c(
            "v-row",
            { staticClass: "pa-0 ma-0 vRowHeight", attrs: { cols: "12" } },
            [
              _c(
                "div",
                {
                  staticClass: "pa-3",
                  staticStyle: {
                    display: "flex",
                    "align-content": "center",
                    "font-size": "25px",
                    width: "100%"
                  },
                  style:
                    "opacity: 40%; border-radius: 10px; box-shadow: 1px 2px 3px silver; background-color: #FFF",
                  on: {
                    click: function($event) {
                      return _vm.readInfo()
                    }
                  }
                },
                [
                  _c("div", { staticStyle: { width: "40px" } }),
                  _c(
                    "div",
                    {
                      staticClass: "pl-2 d-flex align-center",
                      staticStyle: { color: "#707070" }
                    },
                    [_vm._v("Hohlstunde")]
                  )
                ]
              )
            ]
          ),
      _c(
        "v-snackbar",
        {
          attrs: { bottom: "", color: "error", timeout: "3000" },
          model: {
            value: _vm.snackbar,
            callback: function($$v) {
              _vm.snackbar = $$v
            },
            expression: "snackbar"
          }
        },
        [_vm._v(" " + _vm._s(_vm.snackbarText) + " ")]
      ),
      _c(
        "v-snackbar",
        {
          attrs: { bottom: "", color: "success", timeout: "3000" },
          model: {
            value: _vm.snackbarSave,
            callback: function($$v) {
              _vm.snackbarSave = $$v
            },
            expression: "snackbarSave"
          }
        },
        [_vm._v(" Gespeichert! ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }