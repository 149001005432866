<template>
    <div>
        <v-row class="mx-0">
            <v-col class="pa-0">
                <div class="whiteBg" style="height: auto; width: 90%; min-height: 80vh; border-radius: 15px; box-shadow: 1px 5px 5px silver; margin: auto auto 5em;">

                    <!--Header of Stundenplan widget-->
                    <div style="background-color: #6995CD; border-radius: 15px 15px 0 0; padding-top: 10px; padding-bottom: 10px; color: white;">
                        <v-row style="width: 90%; display: flex; align-items: center;" class="pr-2 ml-4">
                            <v-col cols="9" md="8" lg="9" class="pa-0 text-left d-flex align-center" style="font-size: xx-large">
                                <img :src="stundenplanIcon" style="height: 30px;" class="iconToWhite"/>
                                <p class="ma-0 pl-2">Stundenplan</p>
                            </v-col>
                        </v-row>
                    </div>

                    <!--date bar of Stundenplan-->
                    <div style="border-radius: 15px">
                        <v-row cols="12" style="width: 99%; margin: 0 auto 0.5em;">
                            <v-col cols="2" class="text-center pb-0">
                                <img :src="uhrIcon" width="40" height="40"/>
                            </v-col>
                            <v-col cols="10" class="text-center pb-0">
                                <p style="display: inline-block; color: #6995CD; font-weight: bold; font-size: x-large">
                                    {{ getDisplayDate() }}
                                </p>
                            </v-col>
                        </v-row>

                        <div v-if="showLoading" class="d-flex justify-center">
                            <v-progress-circular color="#6995CD" indeterminate size="100"/>
                        </div>

                        <div v-else>
                            <div v-if="babySlots.length === 0" class="ma-3" style="color: #707070; font-size: large">
                                Aktuell ist kein Unterricht. Du hast heute von <strong>{{ ("0" + new Date(this.slots[0].start).getHours()).slice(-2) + ":" + ("0" + new Date(slots[0].start).getMinutes()).slice(-2) }}</strong> Uhr
                                bis <strong>{{ ("0" + new Date(this.slots[this.slots.length-1].end).getHours()).slice(-2) + ":" + ("0" + new Date(slots[this.slots.length-1].end).getMinutes()).slice(-2) }}</strong> Uhr
                                Unterricht, der Unterricht hat entweder noch nicht begonnen oder ist schon vorbei.
                            </div>

                            <v-row v-else cols="12" v-for="(item, index) in babySlots" :key="index" class="pr-2" id="fachRow">
                                <v-col cols="2" style="display: flex; justify-content: center; align-items: center; font-weight: normal; font-size: larger; color: #707070" class="text-center pa-2">
                                    {{("0" + new Date(item.start).getHours()).slice(-2) + ":" + ("0" + new Date(item.start).getMinutes()).slice(-2)}}
                                    <br>
                                    {{("0" + new Date(item.end).getHours()).slice(-2) + ":" + ("0" + new Date(item.end).getMinutes()).slice(-2)}}
                                </v-col>
                                <v-col cols="10" class="pa-2" id="fachCol">
                                    <div v-if="getAppointmentsToday(item._id).length">
                                        <div v-for="el in getAppointmentsToday(item._id)" :key="JSON.stringify(el)">
                                            <Fach @pass-on-bbb-tut="receivedEmit" style="width: 100%"
                                                  :is-running="isTimeslotCurrentlyRunningToday(item)"
                                                  :has-run="hasTimeslotRunAndRoomIsStillOpen(item, index)"
                                                  :isOpen="el.open" :appointment="el" :requestAppointments="requestAppointments" :list-index="index"/>
                                        </div>
                                    </div>
                                    <div v-else>
                                        <Fach @pass-on-bbb-tut="receivedEmit" style="width: 100%"
                                              :is-running="isTimeslotCurrentlyRunningToday(item)"
                                              :requestAppointments="requestAppointments" :list-index="index"/>
                                    </div>
                                </v-col>
                            </v-row>
                        </div>
                    </div>
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import Fach from "./FachSchuelerBaby"
import uhrIcon from '../../assets/Icons/uhr-04.svg'
import teacherIcon from '../../assets/Icons/lehrer-24.svg'
import teacherPic from '../../assets/Bilder/Jugendherberge-Klassenfahrt-Lehrer-erzaehlen-Grundschule-e1510042530178.png'
import vollbildIcon from '../../assets/Icons/vollbild-09.svg'
import linksIcon from '../../assets/Icons/links-10.svg'
import rechtsIcon from '../../assets/Icons/rechts-11.svg'
import schliessenIcon from '../../assets/Icons/abbrechen-08.svg'
import stundenplanIcon from '../../assets/Icons/stundenplan-neu-neu-99.svg'

import {mapState, mapMutations, mapActions, mapGetters} from "vuex";
import {tutorials} from '@/constants/tutorial';

export default {
    name: "PlanWidget",
    components: {
        Fach,
    },
    data() {
        return {
            future: 0,
            past: 0,

            futureWeek: 0,
            pastWeek: 0,

            menu: false,
            date: new Date(),    //wird beim Ändern des Tags im Kalendar geändert
            today: new Date(),   //nicht ändern! wird als Anhaltspkt zum Einfärben des akt. Tag genutzt
            appointments: [],
            person: null,
            slots: [],
            babySlots: [], //nur aktueller und nächster Slot
            planVollbild: false,
            showLoading: false,
            Fach,
            uhrIcon,
            teacherIcon,
            teacherPic,
            vollbildIcon,
            linksIcon,
            rechtsIcon,
            schliessenIcon,
            stundenplanIcon,
            tutorials,
            account: '',
        };
    },
    props: {
        small: {required: false, default: false},
    },
    beforeCreate() {
        // Returns the ISO week of the date.
        Date.prototype.getWeek = function () {
            var date = new Date(this.getTime());
            date.setHours(0, 0, 0, 0);
            // Thursday in current week decides the year.
            date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
            // January 4 is always in week 1.
            var week1 = new Date(date.getFullYear(), 0, 4);
            // Adjust to Thursday in week 1 and count number of weeks from date to week1.
            return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000
                - 3 + (week1.getDay() + 6) % 7) / 7);
        }
    },
    async created() {
    },
    async mounted() {
        this.showLoading = true;
        await this.requestSlots();

        this.requestThreeSlots();

        this.date = await this.getDay();
        this.today = await this.getDay();

        await this.requestAppointments();

        this.showLoading = false;

        this.requestPerson();
        this.account = (await this.getMe()).account;

        this.jumpToCurrentDay();    //because somehow it jumps to a different day sometimes

        setTimeout(function(){ speechSynthesis.cancel(); }, 500);   //if you spam through tutorial this should cancel speaking
    },
    computed: {
        ...mapGetters('pupils', ['getTutorialProgress']),
        ...mapGetters('util', ['getServerTime']),
        getTutProgress() {
            if (!this.account)
                return [];
            return this.getTutorialProgress[this.account] || [];
        },
    },
    methods: {
        ...mapActions("appointments", ["getAppointments"]),
        ...mapActions("timeslots", ["getSlots"]),
        ...mapActions("teachers", ["getMe"]),
        ...mapActions("auth", ["logoutUser"]),
        ...mapActions('pupils', ['setTutorialProgress', 'createPupil', 'getMe',]),
        ...mapActions("util", ["requestSeverTime"]),

        receivedEmit(callback) {
            /*emit to PlanSchueler*/
            this.$emit('start-bbb-tut', callback);
        },

        async finishTut(progress) {
            this.setTutorialProgress({account: this.account, progress});
        },

        async checkParams() {
            let appointment = this.appointments.find((el) => {
                return this.$route.query.appointment == el._id
            })
            if (appointment) {
                this.date = new Date(appointment.day)
                appointment['open'] = true
            }
        },
        getWeek() {
            var date = new Date(this.getTime());
            date.setHours(0, 0, 0, 0);
            // Thursday in current week decides the year.
            date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
            // January 4 is always in week 1.
            var week1 = new Date(date.getFullYear(), 0, 4);
            // Adjust to Thursday in week 1 and count number of weeks from date to week1.
            return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000
                - 3 + (week1.getDay() + 6) % 7) / 7);
        },

        async requestAppointments() {
            this.appointments = await this.getAppointments();
            this.checkParams();
            this.countBadges();
            this.countBadgesWeek();
        },

        async requestSlots() {
            this.slots = await this.getSlots();
        },

        //babyView only need current, previous and next lesson
        async requestThreeSlots() {
            let temp = [];
            const currentTimeAndDay = new Date();
            let currentTimeString = currentTimeAndDay.toLocaleTimeString();
            this.slots = await this.getSlots();

            for (let i = 0; i < this.slots.length; i++) {
                let startTime = new Date(this.slots[i].start).toLocaleTimeString();
                let endTime = new Date(this.slots[i].end).toLocaleTimeString();

                if (startTime <= currentTimeString && currentTimeString <= endTime) {

                    // Get lesson before that because teachers might make breaks into timeslots because stoopid
                    if (this.slots[i - 2]) {
                        temp.push(this.slots[i - 2]);
                    }

                    //get lesson before current
                    if(this.slots[i - 1]) {
                        temp.push(this.slots[i - 1]);
                    }

                    //current time is between start and end of timeslot
                    temp.push(this.slots[i]);

                    //get lesson after current
                    if (this.slots[i + 1]) {
                        temp.push(this.slots[i + 1]);
                    }

                    //if there is only one item return it with pop()
                    // else {
                    //     temp.pop();
                    // }
                    //else save array to babySlots
                    this.babySlots = temp;
                    break;
                } else {
                    //there is no current or next lesson \_(-.-)_/
                }
            }
        },

        async requestPerson() {
            this.person = await this.getMe();
            return this.person;
        },

        clickLogoutUser() {
            this.logoutUser();
        },

        ISO8601_week_no(dt) {
            let tdt = new Date(dt.valueOf());
            let dayn = (dt.getDay() + 6) % 7;
            tdt.setDate(tdt.getDate() - dayn + 3);
            let firstThursday = tdt.valueOf();
            tdt.setMonth(0, 1);
            if (tdt.getDay() !== 4) {
                tdt.setMonth(0, 1 + ((4 - tdt.getDay()) + 7) % 7);
            }
            return 1 + Math.ceil((firstThursday - tdt) / 604800000);
        },

        jumpToCurrentDay() {
            this.date = this.today;
        },
        addWeek() {
            this.date = new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate() + 7);
            this.countBadges();
            this.countBadgesWeek();
        },
        subWeek() {
            this.date = new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate() - 7);
            this.countBadges();
            this.countBadgesWeek();
        },
        addDay() {
            do {
                this.date = new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate() + 1);
            } while (!(this.date.getDay() % 6))
            this.countBadges();
            this.countBadgesWeek();
        },
        subDay() {
            do {
                this.date = new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate() - 1);
            } while (!(this.date.getDay() % 6))
            this.countBadges();
            this.countBadgesWeek();
        },
        //vergleicht displayDate mit today, return true wenn gleich um heutigen Tag einzufärben
        isDisplayDateToday(compareDate) {
            let heute, monat, tag;
            let jahr = (this.today).toString().slice(13, 15);
            let unshortenedDay = (this.today).toString().slice(8, 10);

            //wenn Tag kleiner als 10, schneide erstes Zeichen weg
            if (unshortenedDay.slice(0, 1) === "0") {
                tag = unshortenedDay.slice(1, 2);
            } else {
                tag = unshortenedDay.slice(0, 2);
            }

            //von Monatskürzel zu Zahl (ohne führende null)
            switch ((this.today).toString().slice(4, 7)) {
                case "Jan":
                    monat = "1";
                    break;
                case "Feb":
                    monat = "2";
                    break;
                case "Mar":
                    monat = "3";
                    break;
                case "Apr":
                    monat = "4";
                    break;
                case "May":
                    monat = "5";
                    break;
                case "Jun":
                    monat = "6";
                    break;
                case "Jul":
                    monat = "7";
                    break;
                case "Aug":
                    monat = "8";
                    break;
                case "Sep":
                    monat = "9";
                    break;
                case "Oct":
                    monat = "10";
                    break;
                case "Nov":
                    monat = "11";
                    break;
                case "Dec":
                    monat = "12";
                    break;
            }

            //this.date in umgewandelter Form zusammenbauen
            heute = tag + "." + monat + "." + jahr;

            if ((compareDate.toString()).slice(5, compareDate.toString().length) === heute) {
                return true;
            } else {
                return false;
            }
        },
        //um Montasbezeichnung im Header anzuzeigen
        getDisplayMonth() {
            let monat;
            //von Monatskürzel zu kompletter Monatsbezeichnung
            switch ((this.date).toString().slice(4, 7)) {
                case "Jan":
                    monat = "Januar";
                    break;
                case "Feb":
                    monat = "Februar";
                    break;
                case "Mar":
                    monat = "März";
                    break;
                case "Apr":
                    monat = "April";
                    break;
                case "May":
                    monat = "Mai";
                    break;
                case "Jun":
                    monat = "Juni";
                    break;
                case "Jul":
                    monat = "Juli";
                    break;
                case "Aug":
                    monat = "August";
                    break;
                case "Sep":
                    monat = "September";
                    break;
                case "Oct":
                    monat = "Oktober";
                    break;
                case "Nov":
                    monat = "November";
                    break;
                case "Dec":
                    monat = "Dezember";
                    break;
            }
            return monat;
        },
        getDisplayDate() {
            let heute = new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate());
            let datum, tag;

            switch (heute.getDay()) {
                case 0:
                    tag = ("So.");
                    break;
                case 1:
                    tag = ("Mo.");
                    break;
                case 2:
                    tag = ("Di.");
                    break;
                case 3:
                    tag = ("Mi.");
                    break;
                case 4:
                    tag = ("Do.");
                    break;
                case 5:
                    tag = ("Fr.");
                    break;
                case 6:
                    tag = ("Sa.");
                    break;
                default:
                    tag = ("TEST");
            }
            datum = (tag + ", " + (heute.getDate()) + '.' + (heute.getMonth() + 1) + '.' + heute.getFullYear().toString().slice(-2));
            return datum;
        },
        getDisplayDateMonday() {
            return "Mo., " + (this.getMonday(this.date).getDate()) + '.' + (this.getMonday(this.date).getMonth() + 1) + '.' + this.getMonday(this.date).getFullYear().toString().slice(-2)
        },
        getDisplayDateTuesday() {
            return "Di., " + (this.getTuesday(this.date).getDate()) + '.' + (this.getTuesday(this.date).getMonth() + 1) + '.' + this.getTuesday(this.date).getFullYear().toString().slice(-2)
        },
        getDisplayDateWednesday() {
            return "Mi., " + (this.getWednesday(this.date).getDate()) + '.' + (this.getWednesday(this.date).getMonth() + 1) + '.' + this.getWednesday(this.date).getFullYear().toString().slice(-2)
        },
        getDisplayDateThursday() {
            return "Do., " + (this.getThursday(this.date).getDate()) + '.' + (this.getThursday(this.date).getMonth() + 1) + '.' + this.getThursday(this.date).getFullYear().toString().slice(-2)
        },
        getDisplayDateFriday() {
            return "Fr., " + (this.getFriday(this.date).getDate()) + '.' + (this.getFriday(this.date).getMonth() + 1) + '.' + this.getFriday(this.date).getFullYear().toString().slice(-2)
        },
        async getDay() {
            let date = new Date(await this.requestSeverTime());
            while (!(date .getDay() % 6)) {
                date = new Date(date .getFullYear(), date .getMonth(), date .getDate()-1);
            }
            return date;
        },
        getMonday(d) {
            d = new Date(d);
            let day = d.getDay(),
                diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
            return new Date(d.setDate(diff));
        },
        getTuesday(d) {
            d = new Date(d);
            let day = d.getDay(),
                diff = d.getDate() - day + (day == 0 ? -5 : 2); // adjust when day is sunday
            return new Date(d.setDate(diff));
        },
        getWednesday(d) {
            d = new Date(d);
            let day = d.getDay(),
                diff = d.getDate() - day + (day == 0 ? -4 : 3); // adjust when day is sunday
            return new Date(d.setDate(diff));
        },
        getThursday(d) {
            d = new Date(d);
            let day = d.getDay(),
                diff = d.getDate() - day + (day == 0 ? -3 : 4); // adjust when day is sunday
            return new Date(d.setDate(diff));
        },
        getFriday(d) {
            d = new Date(d);
            let day = d.getDay(),
                diff = d.getDate() - day + (day == 0 ? -2 : 5); // adjust when day is sunday
            return new Date(d.setDate(diff));
        },

        getAppointmentToday(slot) {
            return this.appointments.find((el) => {
                return (el.timeslot === slot && this.sameDay(new Date(el.day), this.date))
            })
        },
        getAppointmentsToday(slot) {
            return this.appointments.filter((el) => {
                return (el.timeslot === slot && this.sameDay(new Date(el.day), this.date))
            })
        },
        getAppointment(slot, day) {
            switch (day) {
                case 'mon':
                    day = this.getMonday(this.date);
                    break;
                case 'die':
                    day = this.getTuesday(this.date);
                    break;
                case 'mit':
                    day = this.getWednesday(this.date);
                    break;
                case 'don':
                    day = this.getThursday(this.date);
                    break;
                case 'fre':
                    day = this.getFriday(this.date);
                    break;
            }
            return this.appointments.find((el) => {
                return (el.timeslot === slot && this.sameDay(new Date(el.day), day))
            })
        },

        countBadges() {
            this.future = 0;
            this.past = 0;
            this.appointments.forEach((el) => {
                if (!this.sameDay(new Date(el.day), this.date)) {
                    if (new Date(el.day) > this.date) {
                        this.future += el.pupilUpload.filter((element) => !element.seenByMe).length;
                    } else {
                        this.past += el.pupilUpload.filter((element) => !element.seenByMe).length;
                    }
                }
            })
        },

        countBadgesWeek() {
            this.futureWeek = 0;
            this.pastWeek = 0;
            this.appointments.forEach((el) => {
                if (!(new Date(el.day).getWeek() == this.date.getWeek())) {
                    if (new Date(el.day) > this.date) {
                        this.futureWeek += el.pupilUpload.filter((element) => !element.seenByMe).length;
                    } else {
                        this.pastWeek += el.pupilUpload.filter((element) => !element.seenByMe).length;
                    }
                }
            })
        },

        sameDay(first, second) {
            return first.getFullYear() === second.getFullYear() &&
                first.getMonth() === second.getMonth() &&
                first.getDate() === second.getDate();
        },

        // Not the cleanest code i know :/ - Jan
        isTimeslotCurrentlyRunning(timeslot, day) {
            const currentTimeAndDay = new Date();
            const appointment = this.getAppointment(timeslot._id, day);
            if (appointment) {
                const appointmentDate = new Date(appointment.day).toDateString();
                if (appointmentDate === currentTimeAndDay.toDateString()) {
                    // If we are in here that means the appointment is today
                    if (new Date(timeslot.start).toLocaleTimeString() <= currentTimeAndDay.toLocaleTimeString()
                        && new Date(timeslot.end).toLocaleTimeString() > currentTimeAndDay.toLocaleTimeString()) {
                        // Currenttime is between start and end of timeslot
                        return true;
                    }
                }
            }
            return false;
        },

        isTimeslotCurrentlyRunningToday(timeslot) {
            const currentTimeAndDay = this.today;
            const appointment = this.getAppointmentToday(timeslot._id);
            if (appointment) {
                const appointmentDate = new Date(appointment.day).toDateString();
                if (appointmentDate === currentTimeAndDay.toDateString()) {
                    // If we are in here that means the appointment is today
                    if (new Date(timeslot.start).toLocaleTimeString() <= currentTimeAndDay.toLocaleTimeString()
                        && new Date(timeslot.end).toLocaleTimeString() > currentTimeAndDay.toLocaleTimeString()) {
                        // Currenttime is between start and end of timeslot
                        return true;
                    }
                }
            }
            return false;
        },

        /**
         * Needs check for when room isnt open anymore
         * @param timeslot
         * @param index
         * @returns {boolean}
         */
        hasTimeslotRunAndRoomIsStillOpen(timeslot, index) {
            return index < this.babySlots.length - 2 && this.isJoinable(timeslot);
        },

        isJoinable(timeslot) {
            let time = new Date(this.getServerTime);

            const localeStart = new Date(timeslot.start);
            localeStart.setMinutes(localeStart.getMinutes() - 15);

            const localeEnd = new Date(timeslot.end);
            localeEnd.setMinutes(localeEnd.getMinutes() + 15);

            return localeStart.toLocaleTimeString() <= time.toLocaleTimeString()
                && localeEnd.toLocaleTimeString() >= time.toLocaleTimeString();
        },
    },
};
</script>

<style lang="scss" scoped>
table, th, td {
    border: none;
    border-collapse: collapse;
}

tr {
    height: 40px;
}


th {
    vertical-align: top;
}


table tr th:first-child,
table tr td:first-child {
    padding-top: 10px;
    width: 10px !important;
}

table tr th:nth-child(2),
table tr td:nth-child(2) {
    width: 5px !important;
}

table tr th:last-child,
table tr td:last-child {
    width: 5px !important;
}

table tr th,
table tr td {
    width: 9em;
    border: none;
}

table, tr, td {
    border: none;
}

.fach {
    cursor: pointer;
}

#fachRow {
    width: 99%;
    margin: 0.5em auto 0.5em auto;
}

@media only screen and (max-width: 399px) {
    #fachRow {
        padding-right: 0 !important;
    }

    #fachCol {
        padding-right: 0 !important;
    }
}

//filter generated with https://codepen.io/sosuke/pen/Pjoqqp
.iconToWhite {
    filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%) hue-rotate(36deg) brightness(104%) contrast(105%);
}

.whiteBg {
    background-color: #ffffff;
}

.scroll-area {
    position: relative;
    margin: auto;
    max-height: calc(80vh - 75px);
}
</style>
