<template>
    <v-dialog
        overlay-opacity="0.7"
        v-model="showDialog"
        :persistent="true"
        width="500"
    >
        <v-card id="card" class="pa-3" >
            <v-card-title>
                <v-container class="pa-0">
                    <v-row>
                        <v-col>
                            <h1>Dateiname</h1>
                        </v-col>
                        <v-col cols="auto">
                            <v-btn @click="close" elevation="2" x-small class="ml-2 pa-0"
                                   style="width: 30px; height: 30px">
                                <img :src="schliesenIcon" style="height: 20px;"/>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-title>
            <v-card-text class="pb-0">
                <v-container class="pa-0">
                    <v-row align="center" justify="center">
                        <v-col>
                            <v-text-field v-model="newFileName"/>
                        </v-col>
                        <v-col cols="auto">
                            <v-btn
                                @click="() => {callback(newFileName, selectedUser)}"
                                elevation="2"
                                x-small
                                class="pa-0 mb-2"
                                style="width: 30px; height: 30px"
                                color="secondary"
                            >
                                <v-icon x-small dark>fas fa-check</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-row v-if="group" class="ma-0" align="center" justify="center" style="width: 100%">
                        <vue-custom-scrollbar class="scroll-area" :settings="settings">
                            <v-data-table
                                    :items="this.group.participants"
                                    disable-pagination
                                    hide-default-footer
                                    fixed-header
                                    class="ma-0"
                                    style="width: 100%"
                            >
                                <template v-slot:item="row">
                                    <tr>
                                        <td class="pl-0">{{row.item.lastName }}</td>
                                        <td> {{row.item.name}}</td>
                                        <td>
                                            <v-checkbox class="mx-2" rounded :value="row.item.account" v-model="selectedUser" small light>
                                            </v-checkbox>
                                        </td>
                                    </tr>
                                </template>
                            </v-data-table>
                        </vue-custom-scrollbar>
                    </v-row>
                </v-container>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import schliesenIcon from '../assets/Icons/abbrechen-08.svg'
import vueCustomScrollbar from "vue-custom-scrollbar";
export default {
    name: "NameFile",
    components: {
        vueCustomScrollbar,
    },
    data() {
        return {
            schliesenIcon,
            newFileName: '',
            selectedUser: [],
            settings: {
                suppressScrollY: false,
                suppressScrollX: true,
                wheelPropagation: false,
            },
        };
    },
    model: {
        prop: 'showDialog',
        event: 'showChange'
    },
    props: {
        showDialog: {type: Boolean, required: true},
        originalName: {type: String, required: false, default: ''},
        callback: {type: Function, required: true},
        group: {type: Object, required: false, default: null},
    },
    methods: {
        close() {
            document.getElementById('uploadInput').value = null;
            this.$emit('showChange', false);
        }
    },
    watch: {
        showDialog(val, prev) {
            if (val && !prev) {
                this.newFileName = this.originalName;
                if (this.group) {
                    this.selectedUser = this.group.participants.map(a => a.account);
                }
            }
        },
        group(val) {
            if(val) {
                this.selectedUser = val.participants.map(a => a.account);
            }
        }
    },
}
</script>

<style scoped>
    .scroll-area {
        position: relative;
        margin: auto;
        max-height: 300px;
        width: 100%;
    }
</style>
