<template>
  <div>
    <v-row class="mx-0">
      <div class="chat-container whiteBg">
        <!--Header of Chat-->
        <div
          style="
            background-color: #8cbd46;
            border-radius: 15px 15px 0 0;
            padding-top: 10px;
            padding-bottom: 10px;
            text-align: left;
            color: white;
          "
        >
          <v-row
            style="width: 90%; display: flex; align-items: center; height: 35px"
            class="pr-2 ml-2"
          >
            <v-col
              cols="9"
              md="8"
              lg="9"
              class="pa-0 pl-2 text-left d-flex align-center"
              style="font-size: larger"
            >
              <img
                :src="chatIcon"
                class="iconToWhite mr-1"
                style="height: 20px"
              />
              Chat
            </v-col>
          </v-row>
        </div>

        <ChatWidgetGroupList
          :rooms="rooms"
          @roomSelected="handleRoomSelected"
          @setRoomList="setRoomList"
          v-if="!viewingRoom"
          :matrixClient="matrixClient"
        ></ChatWidgetGroupList>

        <ChatWidgetGroupChat
          v-else
          :room="viewingRoom"
          :matrixClient="matrixClient"
          @exitRoom="handleExitRoom"
          @sendMessage="sendMessage"
          @deleteMessage="deleteMessage"
        ></ChatWidgetGroupChat>
      </div>
    </v-row>
  </div>
</template>

<script>
import chatIcon from "../../assets/Icons/chat-alternative-102.svg";
import ChatWidgetGroupList from "./ChatWidgetGroupList";
import ChatWidgetGroupChat from "./ChatWidgetGroupChat";
import { getMatrixAddress } from "../../util/getMatrixAddress";
import * as BackendApi from "../../api/backend";
import * as sdk from "matrix-js-sdk";

export default {
  name: "ChatWidget",
  components: { ChatWidgetGroupChat, ChatWidgetGroupList },
  data: () => ({
    chatIcon,
    rooms: [],
    matrixClient: null,
    viewingRoom: null,
    numMessagesToShow: 20,
  }),
  props: {},
  async created() {},
  async mounted() {
    const matrixUserResponse = await BackendApi.getCurrentMatrixUser();
    const matrixUser = await matrixUserResponse.json();

    // Transform name like @alice-1603048221873-estundenplan-localhost-simon:estundenplan-synapse-development
    // to alice-1603048221873-estundenplan-localhost-simon
    const loginName = matrixUser.name.slice(1).split(":")[0];

    const matrixServerUrl = getMatrixAddress();
    const loginDataResponse = await fetch(
      `${matrixServerUrl}/_matrix/client/r0/login`,
      {
        method: "POST",
        body: JSON.stringify({
          type: "m.login.password",
          user: loginName,
          password: matrixUser.password,
        }),
      }
    );

    const loginData = await loginDataResponse.json();
    const matrixClient = sdk.createClient({
      baseUrl: matrixServerUrl,
      accessToken: loginData.access_token,
      userId: loginData.user_id,
    });
    this.matrixClient = matrixClient;

    matrixClient.on("sync", this.handleMatrixClientOnSync);
    matrixClient.on("Room", this.handleMatrixClientOnRoom);
    matrixClient.on("Room.timeline", this.handleMatrixClientOnRoomTimeline);

    matrixClient.startClient(10);
  },
  computed: {},
  methods: {
    sendMessage(message) {
      const content = {
        body: message,
        msgtype: "m.text",
      };
      this.matrixClient.sendEvent(
        this.viewingRoom.roomId,
        "m.room.message",
        content,
        "",
        (err, res) => {
          console.log(err);
        }
      );
    },

    deleteMessage(message) {
      this.matrixClient.redactEvent(
        this.viewingRoom.roomId,
        message.event.event_id,
        message._txnId,
        (err, res) => {
          console.log(err);
        }
      );
      console.log(this.viewingRoom.roomId);
    },

    async handleExitRoom() {
      await this.matrixClient.sendReceipt(
        this.viewingRoom.timeline[this.viewingRoom.timeline.length - 1],
        "m.read"
      );
      this.viewingRoom = null;
    },
    async handleRoomSelected(room) {
      // console.log(room);
      await this.matrixClient.joinRoom(room.roomId);
      await this.matrixClient.sendReceipt(
        room.timeline[room.timeline.length - 1],
        "m.read"
      );

      this.viewingRoom = room;
      await this.$nextTick();
    },
    handleMatrixClientOnSync(state, prevState, data) {
      switch (state) {
        case "PREPARED":
          this.setRoomList();
          break;
      }
    },
    handleMatrixClientOnRoom() {
      this.setRoomList();
      if (!this.viewingRoom) {
        // printRoomList();
        // rl.prompt();
      }
    },
    handleMatrixClientOnRoomTimeline(event, room, toStartOfTimeline) {
      if (toStartOfTimeline) {
        return; // don't print paginated results
      }
      if (!this.viewingRoom || this.viewingRoom.roomId !== room.roomId) {
        return; // not viewing a room or viewing the wrong room.
      }
    },
    setRoomList() {
      const roomList = this.matrixClient
        .getRooms()
        .filter((room) => room._selfMembership === "join");
      roomList.sort(function (a, b) {
        // < 0 = a comes first (lower index) - we want high indexes = newer
        var aMsg = a.timeline[a.timeline.length - 1];
        if (!aMsg) {
          return -1;
        }
        var bMsg = b.timeline[b.timeline.length - 1];
        if (!bMsg) {
          return 1;
        }
        if (aMsg.getTs() > bMsg.getTs()) {
          return -1;
        } else if (aMsg.getTs() < bMsg.getTs()) {
          return 1;
        }
        return 0;
      });
      this.rooms = roomList;
    },
  },
};
</script>

<style lang="scss" scoped>
.chat-container {
  height: auto;
  width: 90%;
  min-height: 80vh;
  max-height: 80vh;
  // max-height: 80vh;
  margin: auto;
  border-radius: 15px;
  box-shadow: 1px 5px 5px silver;
  margin-bottom: 5em;
  display: flex;
  flex-direction: column;
}

.chat-header {
  background-color: #3baa69;
  border-radius: 15px 15px 0 0;
  font-size: larger;
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: left;
  color: white;
}

.chat-header-btn {
  background-color: #f8f8f880 !important;
  width: 35px;
  height: 35px !important;
}

.chat-header-img {
  height: 20px;
  filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%)
    hue-rotate(36deg) brightness(104%) contrast(105%);
}

.scroll-area {
  position: relative;
  max-height: calc(80vh - 75px);
}

//filter generated with https://codepen.io/sosuke/pen/Pjoqqp
.iconToWhite {
  filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%)
    hue-rotate(36deg) brightness(104%) contrast(105%);
}

.whiteBg {
  background-color: #ffffff;
}
</style>
