<template>
    <div>
        <div style="height: 100vh;">
            <v-row class="mx-0" style="height: 95%">
                <div style="height: auto; width: 90%; min-height: 80vh; border-radius: 15px; box-shadow: 1px 5px 5px silver; margin: auto auto 5em; margin-top: 12px">
                    <div style="display: inline-flex; width: 100%; background-color: #707070; border-radius: 15px 15px 0 0; font-size: larger; padding-top: 20px; padding-bottom: 20px; color: white;"
                         class="px-1">
                        <div class="mt-1" style="margin-left: 3%">
                        <img :src="brettIcon" class="mx-2 boardIcon" alt="Schwarzes Brett-Icon"/>
                        <p class="ma-0" style="display: inline-block;">Schwarzes Brett</p>
                        </div>
                        <v-spacer></v-spacer>
                        <v-btn @click="() => {
                            eintragPopup = true; groupBlackboard = groups[0]; brettEintrag.groupId = groups[0]._id }"
                               x-small class="RightTitleText d-flex justify-end align-center mr-2" style="background-color: #f8f8f880; width: 35px; height: 35px;">
                            <img :src="plusIcon" class="boardIcon" alt="Plus-Icon"/>
                        </v-btn>
                    </div>

                    <v-overlay absolute :value="showLoadingOverlay">
                        <v-progress-circular v-if="showLoadingOverlay" indeterminate size="100"/>
                    </v-overlay>

                    <vue-custom-scrollbar class="scroll-area" :settings="settings">
                        <div>
                            <v-card v-for="(item, index) in blackboard" :key="index" class="ma-2">
                                <v-card-title class="pa-1" style="color: #707070; background-color: #EAEAEA; max-width: 100%">
                                    <img :src="chooseIcon(item.icon)" :style="{ 'background-color': item.color}" class="mr-2 boardIconEntries" alt="Icon"/>

                                    Verwaltung
                                    <v-spacer></v-spacer>

                                    <div class="RightTitleText d-flex justify-end align-center">
                                        <div class="mr-2 timeAndDate">
                                            <div style="height: 1.25em">{{dayname[new Date(item.createdOn).getDay()] + '. ' + new Date(item.createdOn).toLocaleDateString([], {day: '2-digit', month: '2-digit', year:'2-digit'})}}</div>
                                            <div style="height: 1.25em">{{new Date(item.createdOn).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}} Uhr</div>
                                        </div>
                                        <v-btn @click="() => { bearbeiten = true; eintragPopup = true; setBrettPopup(index); }" x-small
                                               class="pa-0" style="background-color: #f8f8f880; height: 35px; width: 35px">
                                            <img :src="bearbeitenIcon" class="boardIconEntries" alt="Pin-Icon"/>
                                        </v-btn>
                                    </div>
                                </v-card-title>
                                <v-card-text class="pa-2">
                                    <div class="blackboardVCardText">{{ blackboard[index].message }}</div>
                                </v-card-text>
<!--                                <v-card-subtitle class="pa-2" style="color: #BDBDBD; font-size: small">-->
<!--                                    {{ new Date(blackboard[index].createdOn).toLocaleDateString() + ' ' + new-->
<!--                                    Date(blackboard[index].createdOn).toLocaleTimeString()}}-->
<!--                                </v-card-subtitle>-->
                            </v-card>
                            <v-card v-if="!blackboard.length" elevation="0">
                                <v-card-text style="text-align: center">Aktuell ist das Schwarze Brett leer...</v-card-text>
                            </v-card>
                        </div>
                    </vue-custom-scrollbar>
                </div>
            </v-row>
        </div>


        <!--Schwarzes Brett Popup-->
        <v-overlay :opacity="0.5" color="white" :value="eintragPopup" style="padding-top: 50px; height: 100vh;">
            <v-row class="ma-0" style="width: 95%; padding-left: calc(5% + 56px); padding-top: 5%; padding-bottom: 5%; max-height: 80% !important;">
                <v-card id="blackboardCardContainer">
                    <!--Popup-Titel-->
                    <div class="px-1 text-uppercase align-start"
                         style="display: inline-flex; width: 100%; background-color: #707070; border-radius: 15px 15px 0 0; font-size: larger; padding-top: 20px; padding-bottom: 20px; color: white;">


                        <div class="mt-3" style="margin-left: 4%">
                        <img :src="brettIcon" class="mx-2 boardIcon" alt="Schwarzes Brett-Icon"/>
                        <p v-if="bearbeiten" class="ma-0" style="display: inline-block;">Eintrag bearbeiten</p>
                        <p v-else class="ma-0" style="display: inline-block;">Neuer Eintrag</p>
                        </div>

                        <v-spacer></v-spacer>

                        <v-btn @click="() => { eintragPopup = false; bearbeiten = false; resetPopup() }" x-small class="RightTitleText d-flex justify-end align-center mr-2" style="background-color: #f8f8f880; width: 35px; height: 35px;">
                            <img :src="schliessenIcon" class="boardIcon pl-1" alt="schliessen-Icon"/>
                        </v-btn>
                    </div>

                    <!--Eintrag-->
                    <div>
                        <v-row>
                            <v-col cols="12">
                                <vue-custom-scrollbar class="scroll-area mb-2" :settings="settings">
                                <v-card light class="ma-2" style="border-radius: 15px">
                                    <!--Header-->
                                    <v-card-title
                                                  style="color: #707070; background-color: #EAEAEA; padding-top: 10px; padding-bottom: 10px">
                                        <img :src="chooseIcon(brettEintrag.icon)" :style="{ 'background-color': brettEintrag.color}" class="mx-2 boardIconEntries" alt="Icon"/>

                                        Verwaltung
                                    </v-card-title>

                                    <!--Vorschau, Text, Icon und Gruppe-->
                                    <v-card-text class="pa-2">
                                        <v-row style="width: 100%">
                                            <!--left-->
                                            <v-col cols="12" md="8" class="py-0">
                                                <v-textarea v-model="brettEintrag.message" class="pa-0 ma-0" rows="12"
                                                            no-resize hide-details autofocus></v-textarea>

                                                <v-row>
                                                    <v-col cols="11" class="pr-0">
                                                        <div></div>
                                                    </v-col>

                                                    <v-col cols="1" class="pl-0">
                                                        <v-btn v-if="bearbeiten"
                                                               @click="() => {eintragBearbeiten(); requestBlackboard(); resetPopup()}"
                                                               x-small class="mr-6"
                                                               style="background-color: #8CBD46; width: 48px; height: 48px">
                                                            <img :src="sendenIcon"
                                                                 style="height: 40px; color: white!important;"
                                                                 alt="senden-Icon"/>
                                                        </v-btn>

                                                        <v-btn v-else
                                                               @click="() => {eintragErstellen(); requestBlackboard(); resetPopup()}"
                                                               x-small class="mr-4 justify-center align-center"
                                                               style="background-color: #8CBD46; width: 35px; height: 35px">
                                                            <img :src="sendenIcon"
                                                                 style="height: 20px; color: white!important;"
                                                                 class="boardIcon"
                                                                 alt="senden-Icon"/>
                                                        </v-btn>
                                                    </v-col>
                                                </v-row>
                                            </v-col>

                                            <!--right-->
                                            <v-col cols="12" md="4">
                                                <v-row>

                                                    <v-col cols="12" md="12" style="font-size: medium" class="pb-0">
                                                        Gruppenauswahl:
                                                    </v-col>
                                                    <v-col cols="12" md="12">
                                                        <v-autocomplete
                                                                v-model="groupBlackboard"
                                                                :label="toString(groupBlackboard)"
                                                                single-line
                                                                hide-details
                                                                :attach="true"
                                                                item-text="name"
                                                                :items="groups"
                                                                :filter="customFilter"
                                                                no-data-text="Keine passende Gruppe"
                                                                class="ma-0 pa-0"
                                                        ></v-autocomplete>
                                                    </v-col>

                                                    <v-col cols="12" md="12" style="font-size: medium" class="py-0">
                                                        Farbauswahl:
                                                    </v-col>
                                                    <v-col cols="12" md="12">
<!--                                                        <v-swatches v-model="brettEintrag.color"-->
<!--                                                                    :swatches="swatches"></v-swatches>-->
                                                        <verwaltung-color-picker
                                                            :callback="(result) => { brettEintrag.color = result }"
                                                            :current-color="brettEintrag.color"
                                                        />
                                                    </v-col>

                                                    <v-col cols="12" md="12" style="font-size: medium" class="pb-0">
                                                        Iconauswahl:
                                                    </v-col>
                                                    <v-col cols="12" md="12">
                                                        <img class="iconSelect" @click="brettEintrag.icon = 'sonneIcon'"
                                                             :selected="brettEintrag.icon === 'sonneIcon'"
                                                             :src="sonneIconNormal" alt="Sonne-Icon"/>
                                                        <img class="iconSelect"
                                                             @click="brettEintrag.icon = 'erdkundeIcon'"
                                                             :selected="brettEintrag.icon === 'erdkundeIcon'"
                                                             :src="erdkundeIconNormal" alt="Erdkunde-Icon"/>
                                                        <img class="iconSelect"
                                                             @click="brettEintrag.icon = 'religionIcon'"
                                                             :selected="brettEintrag.icon === 'religionIcon'"
                                                             :src="religionIconNormal" alt="Religion-Icon"/>
                                                        <img class="iconSelect"
                                                             @click="brettEintrag.icon = 'politikIcon'"
                                                             :selected="brettEintrag.icon === 'politikIcon'"
                                                             :src="politikIconNormal" alt="Politik-Icon"/>
                                                        <img class="iconSelect" @click="brettEintrag.icon = 'bioIcon'"
                                                             :selected="brettEintrag.icon === 'bioIcon'"
                                                             :src="bioIconNormal" alt="Biologie-Icon"/>
                                                        <img class="iconSelect"
                                                             @click="brettEintrag.icon = 'englischIcon'"
                                                             :selected="brettEintrag.icon === 'englischIcon'"
                                                             :src="englischIconNormal" alt="Englisch-Icon"/>
                                                    </v-col>

                                                    <div v-if="bearbeiten">
                                                        <v-col cols="12" md="12" style="font-size: medium" class="pb-0">
                                                            Eintrag löschen:
                                                        </v-col>
                                                        <v-col cols="12" md="12">
                                                            <v-btn @click="eintragLoeschen(); resetPopup(); eintragPopup = false"
                                                                   style="color: red">Ja, Eintrag löschen
                                                            </v-btn>
                                                        </v-col>
                                                    </div>
                                                </v-row>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                                </vue-custom-scrollbar>
                            </v-col>
                        </v-row>
                    </div>
                </v-card>
            </v-row>
        </v-overlay>
    </div>
</template>

<script>
import VSwatches from 'vue-swatches'
import englischIcon from "../../assets/Icons/f-englisch-weiss-30.svg";
import sonneIcon from "../../assets/Icons/sonne-weiss-46.svg";
import politikIcon from "../../assets/Icons/f-politik-weiss-34.svg";
import erdkundeIcon from "../../assets/Icons/f-erdkunde-weiss-31.svg";
import religionIcon from "../../assets/Icons/f-religion-weiss-39.svg";
import bioIcon from "../../assets/Icons/f-biologie-weiss-35.svg";
import uhrIcon from "../../assets/Icons/uhr-04.svg";
import linksIcon from "../../assets/Icons/links-10.svg";
import rechtsIcon from "../../assets/Icons/rechts-11.svg";
import suchIcon from "../../assets/Icons/suche-26.svg";
import dropdownIcon from "../../assets/Icons/unten-dropdown-12.svg";
import brettIcon from "../../assets/Icons/sw-brett-28.svg";
import plusIcon from "../../assets/Icons/hinzufuegen-07.svg";
import schliessenIcon from "../../assets/Icons/abbrechen-08.svg";
import bearbeitenIcon from "../../assets/Icons/bearbeiten-normal-41.svg";
import sendenIcon from "../../assets/Icons/senden-weiß-23.svg";
import sonneIconNormal from "../../assets/Icons/sonne-46.svg";
import bioIconNormal from "../../assets/Icons/f-biologie-35.svg";
import englischIconNormal from "../../assets/Icons/f-englisch-30.svg";
import erdkundeIconNormal from "../../assets/Icons/f-erdkunde-31.svg";
import politikIconNormal from "../../assets/Icons/f-politik-34.svg";
import religionIconNormal from "../../assets/Icons/f-religion-39.svg";
import {mapActions, mapGetters} from "vuex";
import vueCustomScrollbar from "vue-custom-scrollbar";
import * as backend from "../../api/backend";
import VerwaltungColorPicker from "./VerwaltungColorPicker";

export default {
    name: "SchwarzesBrett",
    components: {
        vueCustomScrollbar, VerwaltungColorPicker
    },
    data() {
        return {
            showLoadingOverlay: false,
            eintragPopup: false,
            bearbeiten: false,
            brettEintrag: {message: '', icon: 'sonneIcon', color: '#fcd20a', groupId: '', blackboardId: ''},
            groupBlackboard: '',
            currentEntryId: '',
            //Icon array for dynamically picking the icon via string
            icons: [
                {icon: englischIcon, text: "englischIcon"},
                {icon: sonneIcon, text: "sonneIcon"},
                {icon: politikIcon, text: "politikIcon"},
                {icon: erdkundeIcon, text: "erdkundeIcon"},
                {icon: religionIcon, text: "religionIcon"},
                {icon: bioIcon, text: "bioIcon"},
            ],
            settings: {
                suppressScrollY: false,
                suppressScrollX: true,
                wheelPropagation: false,
            },
            dayname: [
                'So',
                'Mo',
                'Di',
                'Mi',
                'Do',
                'Fr',
                'Sa',
            ],
            blackboard: [],
            list: [],
            groups: [],
            slots: [],
            swatches: ['#fcd20a', '#f0b423',  '#ff6941', '#91c364', '#91c887', '#d7875f', '#ebaabe', '#9b91c8',  '#6ec3dc', '#9bcdc3',  '#b2b2b2', '#707070'],
            group: '',
            uhrIcon,
            linksIcon,
            rechtsIcon,
            suchIcon,
            dropdownIcon,
            brettIcon,
            plusIcon,
            schliessenIcon,
            bearbeitenIcon,
            sendenIcon,

            sonneIcon,
            bioIcon,
            englischIcon,
            erdkundeIcon,
            politikIcon,
            religionIcon,

            sonneIconNormal,
            bioIconNormal,
            englischIconNormal,
            erdkundeIconNormal,
            politikIconNormal,
            religionIconNormal,
        };
    },
    methods: {
        ...mapActions("blackboard", ["getBlackboard", "createBlackboardEntry", "updateBlackboardEntry", "deleteBlackboardEntry"]),
        ...mapActions("groups", ['getGroups', 'getGroup', 'setGroupInvalidStatus']),
        async requestBlackboard() {
            this.showLoadingOverlay = true;
            this.blackboard = await this.getBlackboard();
            this.blackboard.reverse();
            this.showLoadingOverlay = false;
        },
        async requestGroups() { //this is a hotfix wich works and i have no clue why so please dont break it
            this.showLoadingOverlay = true;
            const response = await backend.getGroups();
            this.groups = await response.json();
            this.setGroupInvalidStatus(false);
            this.reload = !this.reload;


            let temp = JSON.parse(JSON.stringify(this.group));
            this.group = '';


            setTimeout(() => {
                this.group= temp;
            }, 500);
            this.showLoadingOverlay = false;
        },
        async eintragErstellen() {
            try {
                this.showLoadingOverlay = true;
                let data = {
                    message: this.brettEintrag.message,
                    icon: this.brettEintrag.icon,
                    color: this.brettEintrag.color,
                    group: this.brettEintrag.groupId,
                };
                await this.createBlackboardEntry(data);
                this.eintragPopup = false;
                this.showLoadingOverlay = false;
            } catch (e) {
                console.log(e);
                this.showLoadingOverlay = false;
            }
        },

        async setBrettPopup(i) {
            this.currentEntryId = this.blackboard[i]._id;
            this.brettEintrag.color = this.blackboard[i].color;
            this.brettEintrag.icon = this.blackboard[i].icon;
            this.brettEintrag.message = this.blackboard[i].message;
            this.brettEintrag.groupId = this.blackboard[i].blackboardId;
            let gruppenObj = await this.getGroup(this.blackboard[i].group);
            this.groupBlackboard = gruppenObj.name;
        },

        resetPopup() {
            this.currentEntryId = '';
            this.brettEintrag.message = '';
            this.brettEintrag.icon = 'sonneIcon';
            this.brettEintrag.color = '#fcd20a';
            this.brettEintrag.groupId = '';
            this.brettEintrag.blackboardId = '';
            this.bearbeiten = false;
        },

        async eintragBearbeiten() {
            try {
                this.showLoadingOverlay = true;
                let data = {
                    _id: this.currentEntryId,
                    message: this.brettEintrag.message,
                    icon: this.brettEintrag.icon,
                    color: this.brettEintrag.color,
                    group: this.brettEintrag.groupId,
                };
                await this.updateBlackboardEntry(data);
                this.eintragPopup = false;
                this.showLoadingOverlay = false;
            } catch (e) {
                console.log(e);
                this.showLoadingOverlay = false;
            }
        },

        async eintragLoeschen() {
            await this.deleteBlackboardEntry(this.currentEntryId);
            this.blackboard = await this.getBlackboard();
        },
        chooseIcon(iconName) {
            for(let i of this.icons){
                if(i.text === iconName){
                    return i.icon;
                }
            }
        },
        customFilter (item, queryText, itemText) {
            const textOne = item.name.toLowerCase();
            const searchText = queryText.toLowerCase();

            return textOne.indexOf(searchText) > -1
        },
    },
    computed: {
        ...mapGetters("groups", ['getGroupStatus']),
    },
    watch: {
        groupBlackboard(newValue) {
            this.groups.forEach((el) => {
                if(el.name === newValue) {
                    this.brettEintrag.groupId = el._id;
                }
            });
        },
        getGroupStatus(newVal, oldVal) {
            if (newVal && !oldVal) {
                this.requestGroups();
            }
        },
    },
    async mounted() {
        this.showLoadingOverlay = true;
        await this.requestGroups();
        await this.requestBlackboard();
    },
}
</script>

<style lang="scss" scoped>
    table, th, td {
        border: 1px solid black;
        border-collapse: collapse;
    }

    tr {
        height: 40px;
    }

    table tr th:first-child,
    table tr td:first-child {
        width: 10px !important;
    }

    table tr th:nth-child(2),
    table tr td:nth-child(2) {
        width: 5px !important;
    }

    table tr th:last-child,
    table tr td:last-child {
        width: 5px !important;
    }

    table tr th,
    table tr td {
        width: 9em;
        border: none;
    }
    table, tr, td {
        border: none;
    }

    input[type="date"] {
        -webkit-appearance: textfield;
        -moz-appearance: textfield;
        min-height: 1.6rem;
    }

    .fach {
        cursor: pointer;
    }

    .iconSelect {
        width: 42px;
        padding: 2px;
        border: solid 2px #EAEAEA;
        border-radius: 10px;
        margin-right: 2px;
    }

    .iconSelect[selected] {
        width: 42px;
        padding: 2px;
        border: solid 2px #737373;
        border-radius: 10px;
    }

    .boardIcon {
        height: 20px;
        filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%) hue-rotate(36deg) brightness(104%) contrast(105%);
    }
    .boardIconEntries {
        height: 30px;
        color: white !important;
        border-radius: 4px;
        padding: 5px;
    }
    .timeAndDate {
        white-space: nowrap;
        font-size: small;
        line-height: 1rem !important;
        text-align: right;
        opacity: 47%;
    }

    @media only screen and (min-width: 901px) and (max-width: 1110px){
        .timeAndDate {
            display: none;
        }
    }

    .textGrau {
        color: #707070;
    }

    .blur {
        z-index: 8;
        background-color: grey;
    }

    //make text respect line breaks
    .blackboardVCardText {
        white-space: pre-line;
    }

    .scroll-area {
        position: relative;
        margin: auto;
        max-height: calc(65vh);
    }

    .blackboard-scroll-area {
        position: relative;
        margin: auto;
        max-height: 100%;
    }

    #blackboardCardContainer {
        height: auto;
        max-height: 80vh !important;
        width: 100%;
        margin: auto auto 5em;
        border-radius: 15px;
        background: white;
    }
</style>
