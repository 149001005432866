import { render, staticRenderFns } from "./ChatVerwaltung.vue?vue&type=template&id=8bb4c232&scoped=true&"
import script from "./ChatVerwaltung.vue?vue&type=script&lang=js&"
export * from "./ChatVerwaltung.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8bb4c232",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/peternauroth/homeschooling/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('8bb4c232')) {
      api.createRecord('8bb4c232', component.options)
    } else {
      api.reload('8bb4c232', component.options)
    }
    module.hot.accept("./ChatVerwaltung.vue?vue&type=template&id=8bb4c232&scoped=true&", function () {
      api.rerender('8bb4c232', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Verwaltung/ChatVerwaltung.vue"
export default component.exports