<template>
  <v-form>
    <v-container>
      <!-- <ChatWidget></ChatWidget> -->
      <!-- 
      <Login></Login>
      <Timeslot></Timeslot> -->
      <QrLoginList></QrLoginList>
    </v-container>
  </v-form>
</template>

<script>
// import Login from './Login';
import ChatWidget from "./ChatWidget";
import QrLoginList from "./QrLoginList";
import QrcodeVue from "qrcode.vue";
import { compress } from "shrink-string";

const compressIt = async (name) => {
  const compressedName = await compress(name);
  const compressedPW = await compress(name);

  const compressedString = JSON.stringify({
    name: compressedName,
    pw: compressedPW,
  });
  return compressedString;
};

export default {
  name: "BackendAPI",
  components: {
    // Login,
    // ChatWidget,
    QrLoginList,
  },
  mounted() {
  },
  data: () => ({
  }),
  props: {},
  computed: {},
  methods: {
  },
};
</script>