var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { "padding-right": "3px", "padding-left": "-3px" } },
    [
      _c("progress-bar", {
        attrs: {
          title: "Hochladen",
          "show-progress": _vm.showPupilUploadProgress,
          progress: _vm.pupilUploadProgress,
          "abort-progress": _vm.pupilXmlHttpRequest
            ? function() {
                _vm.pupilXmlHttpRequest.abort()
              }
            : function() {}
        }
      }),
      _c("name-file", {
        attrs: {
          "original-name": _vm.uploadFileName,
          callback: _vm.fileNameCallback
        },
        model: {
          value: _vm.showNameFile,
          callback: function($$v) {
            _vm.showNameFile = $$v
          },
          expression: "showNameFile"
        }
      }),
      _vm.thisAppointment
        ? _c(
            "v-dialog",
            {
              staticClass: "dialog",
              attrs: {
                "max-width": "400",
                scrollable: "",
                "overlay-color": "white"
              },
              on: {
                "click:outside": function($event) {
                  return _vm.resetDialogContent()
                }
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function(ref) {
                      var on = ref.on
                      var attrs = ref.attrs
                      return [
                        _c(
                          "div",
                          _vm._b(
                            {
                              staticClass: "fach pa-2 white--text",
                              staticStyle: {
                                width: "90%",
                                display: "block",
                                "padding-left": "0 !important"
                              }
                            },
                            "div",
                            attrs,
                            false
                          ),
                          [
                            _c(
                              "v-row",
                              {
                                staticClass: "ml-2 font-weight-medium",
                                attrs: { cols: "12", justify: "center" }
                              },
                              [
                                _c(
                                  "v-col",
                                  _vm._g(
                                    {
                                      staticClass: "pa-0 ma-0",
                                      attrs: { cols: _vm.isRunning ? 9 : 12 }
                                    },
                                    on
                                  ),
                                  [
                                    _c(
                                      "v-badge",
                                      {
                                        staticStyle: {
                                          "z-index": "8",
                                          width: "100%",
                                          height: "48px !important"
                                        },
                                        attrs: {
                                          color: "red",
                                          content: _vm.thisAppointment.teacherUploads.filter(
                                            function(el) {
                                              return !el.seenByMe
                                            }
                                          ).length,
                                          value: _vm.thisAppointment.teacherUploads.filter(
                                            function(el) {
                                              return !el.seenByMe
                                            }
                                          ).length,
                                          overlap: "",
                                          left: "",
                                          "offset-x": "5"
                                        }
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "pa-3 ma-0 appointmentItem",
                                            class: {
                                              inactiveAppointment: !_vm.isRunning
                                            },
                                            style: _vm.isRunning
                                              ? "width: calc(100% - 3px) !important"
                                              : ""
                                          },
                                          [
                                            _vm.getSrc()
                                              ? _c("img", {
                                                  staticClass: "iconSelect",
                                                  style: _vm.isRunning
                                                    ? _vm.getBackgroundColor()
                                                    : "background-color: transparent",
                                                  attrs: {
                                                    src: _vm.isRunning
                                                      ? _vm.getSrcWhite()
                                                      : _vm.getSrc()
                                                  }
                                                })
                                              : _c("div", {
                                                  staticClass: "iconSelect"
                                                }),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.thisAppointment
                                                    .schoolSubject.name
                                                ) +
                                                " "
                                            ),
                                            _c("img", {
                                              staticStyle: {
                                                position: "absolute",
                                                height: "25px",
                                                top: "0",
                                                right: "10px"
                                              },
                                              attrs: { src: _vm.getColorIcon() }
                                            })
                                          ]
                                        ),
                                        _vm.thisAppointment.teacherUploads
                                          .length > 0
                                          ? _c("img", {
                                              staticStyle: {
                                                opacity: "60%",
                                                position: "absolute",
                                                height: "21px",
                                                top: "-9px"
                                              },
                                              style: _vm.isRunning
                                                ? "right: 30px"
                                                : "right: 27px",
                                              attrs: { src: _vm.paperclipIcon }
                                            })
                                          : _vm._e()
                                      ]
                                    )
                                  ],
                                  1
                                ),
                                _vm.isRunning
                                  ? _c(
                                      "v-col",
                                      {
                                        staticClass: "pa-0 mr-0",
                                        staticStyle: { "min-height": "100%" },
                                        attrs: { cols: 3 },
                                        on: { click: _vm.clickBeitreten }
                                      },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass: "pa-0",
                                            class: {
                                              isFachRunning: _vm.isRunning
                                            },
                                            staticStyle: {
                                              height: "48px",
                                              width: "100%",
                                              "background-color": "white",
                                              "border-radius": "8px"
                                            },
                                            attrs: { small: "" }
                                          },
                                          [
                                            _c("img", {
                                              staticClass: "pa-0",
                                              class: {
                                                isFachRunningIcon: _vm.isRunning
                                              },
                                              staticStyle: {
                                                height: "35px !important",
                                                width: "35px !important"
                                              },
                                              attrs: {
                                                src: _vm.beitretenKameraIcon
                                              }
                                            })
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ],
                null,
                false,
                1386934713
              ),
              model: {
                value: _vm.menu,
                callback: function($$v) {
                  _vm.menu = $$v
                },
                expression: "menu"
              }
            },
            [
              _c(
                "v-card",
                {
                  staticStyle: { color: "#707070" },
                  attrs: { tile: "", id: "fachPopup" }
                },
                [
                  _c("v-card-title", { staticClass: "pa-0 ma-0" }, [
                    _c(
                      "div",
                      {
                        staticClass: "fach d-flex",
                        staticStyle: {
                          "box-shadow": "0 5px 5px 0 rgba(0,0,0,0.3)",
                          "font-size": "large",
                          "align-items": "center",
                          padding: "0 20px",
                          height: "59px",
                          width: "100%"
                        }
                      },
                      [
                        _c(
                          "v-col",
                          {
                            staticClass: "d-flex align-center pl-0",
                            attrs: { cols: "6" }
                          },
                          [
                            _c("img", {
                              staticStyle: {
                                position: "absolute",
                                right: "20%",
                                top: "0",
                                height: "25px"
                              },
                              attrs: { src: _vm.getColorIcon() }
                            }),
                            _vm.getSrc()
                              ? _c("img", {
                                  staticClass: "iconSelect",
                                  staticStyle: { "margin-bottom": "0" },
                                  style: _vm.getBackgroundColor(),
                                  attrs: { src: _vm.getSrcWhite() }
                                })
                              : _vm._e(),
                            _c("div", { staticClass: "pl-2" }, [
                              _vm._v(
                                _vm._s(_vm.thisAppointment.schoolSubject.name)
                              )
                            ])
                          ]
                        ),
                        _c(
                          "v-col",
                          {
                            staticClass:
                              "pa-0 pr-6 d-flex flex-column justify-start align-end",
                            style:
                              "font-size: " +
                              (_vm.windowWidth < 400 ? "small" : "medium"),
                            attrs: { cols: "4" }
                          },
                          [
                            _c("div", [
                              _vm._v(
                                _vm._s(_vm.getAppropriateTeacherDisplayName)
                              )
                            ])
                          ]
                        ),
                        _c(
                          "v-col",
                          { attrs: { cols: "2", "align-self": "end" } },
                          [
                            _c(
                              "v-btn",
                              {
                                staticClass: "ml-2 pa-0 btnStyle",
                                staticStyle: { width: "30px", height: "30px" },
                                attrs: { elevation: "2", "x-small": "" },
                                on: {
                                  click: function($event) {
                                    _vm.menu = false
                                    _vm.resetDialogContent()
                                  }
                                }
                              },
                              [
                                _c("img", {
                                  staticStyle: { height: "20px" },
                                  attrs: { src: _vm.schliesenIcon }
                                })
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]),
                  _c(
                    "v-card-text",
                    {
                      staticClass: "pa-0 ma-0",
                      staticStyle: { overflow: "hidden" }
                    },
                    [
                      _c(
                        "vue-custom-scrollbar",
                        {
                          staticClass: "scroll-area-fachPopup mb-2",
                          attrs: { settings: _vm.settings }
                        },
                        [
                          _c("div", { staticClass: "mx-5" }, [
                            _c(
                              "div",
                              { staticClass: "mt-2" },
                              [
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        staticClass:
                                          "font-weight-bold py-2 d-flex align-center"
                                      },
                                      [
                                        _c("img", {
                                          staticStyle: { height: "20px" },
                                          attrs: { src: _vm.infoIcon }
                                        }),
                                        _c(
                                          "p",
                                          {
                                            staticClass: "pl-2 ma-0",
                                            staticStyle: {
                                              "font-size": "medium"
                                            }
                                          },
                                          [_vm._v("Info")]
                                        )
                                      ]
                                    ),
                                    _c(
                                      "v-col",
                                      { staticClass: "text-right py-1" },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass: "btnStyle",
                                            attrs: {
                                              elevation: "0",
                                              "x-small": ""
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.greet($event)
                                              }
                                            }
                                          },
                                          [
                                            _c("img", {
                                              staticStyle: { height: "20px" },
                                              attrs: {
                                                src: _vm.lautsprecherIcon
                                              }
                                            })
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _vm.thisAppointment.note
                                  ? _c(
                                      "vue-custom-scrollbar",
                                      {
                                        staticClass: "mb-4 mt-1 scroll-area",
                                        staticStyle: {
                                          "font-size": "medium",
                                          "font-weight": "normal",
                                          "line-height": "25px"
                                        },
                                        attrs: { settings: _vm.settings }
                                      },
                                      [
                                        _c("span", {
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.urlify(
                                                _vm.thisAppointment.note
                                              )
                                            )
                                          }
                                        })
                                      ]
                                    )
                                  : _c("div", { staticClass: "pb-1" }, [
                                      _vm._v(
                                        " Bitte bereitet schonmal eure Unterlagen vor! Und ladet gegebenfalls vorhandene Materialien herunter "
                                      )
                                    ])
                              ],
                              1
                            )
                          ]),
                          _c("hr", { staticClass: "eHr" }),
                          _c("div", { staticClass: "mx-0 mb-4" }, [
                            _c(
                              "div",
                              { staticClass: "mt-2" },
                              [
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        staticClass:
                                          "font-weight-bold py-2 mx-5 pt-0 d-flex align-center"
                                      },
                                      [
                                        _c("img", {
                                          staticStyle: { height: "20px" },
                                          attrs: { src: _vm.materialIcon }
                                        }),
                                        _c(
                                          "p",
                                          {
                                            staticClass: "pl-2 ma-0",
                                            staticStyle: {
                                              "font-size": "medium"
                                            }
                                          },
                                          [_vm._v("Material")]
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                ),
                                _vm.thisAppointment.teacherUploads.length === 0
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "mx-5 pb-1",
                                        staticStyle: { "font-size": "medium" }
                                      },
                                      [
                                        _vm._v(
                                          " Bis jetzt wurden zu diesem Fach noch keine Materialien hochgeladen. "
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _c(
                                  "div",
                                  { staticClass: "matShort" },
                                  _vm._l(
                                    _vm.thisAppointment.teacherUploads
                                      .filter(function(item) {
                                        return !item.editParent
                                      })
                                      .slice(0, 2),
                                    function(el, index) {
                                      return _c(
                                        "div",
                                        {
                                          key: el + index,
                                          staticClass:
                                            "py-2 px-5 d-flex justify-space-between align-center",
                                          class: {
                                            brighterFileRow: index % 2 === 1
                                          },
                                          staticStyle: {
                                            "font-size": "medium",
                                            "font-weight": "normal",
                                            "line-height": "25px",
                                            width: "100%",
                                            "background-color":
                                              "rgba(112,112,112, 0.15)"
                                          }
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "fileName",
                                              staticStyle: {
                                                "min-width": "70%",
                                                width: "70%"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(el.title) + " "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                display: "inline-block"
                                              }
                                            },
                                            [
                                              _c(
                                                "v-badge",
                                                {
                                                  staticStyle: {
                                                    "z-index": "9"
                                                  },
                                                  attrs: {
                                                    color: "red",
                                                    content: "1",
                                                    value: !el.seenByMe,
                                                    overlap: "",
                                                    "offset-x": "15"
                                                  }
                                                },
                                                [
                                                  [
                                                    "mp4",
                                                    "wmv",
                                                    "flv",
                                                    "webm",
                                                    "mpg",
                                                    "m4v",
                                                    "mov",
                                                    "mkv"
                                                  ].includes(
                                                    el.title
                                                      .split(".")
                                                      .pop()
                                                      .toLowerCase()
                                                  )
                                                    ? _c(
                                                        "v-btn",
                                                        {
                                                          staticClass:
                                                            "btnStyle",
                                                          staticStyle: {
                                                            "background-color":
                                                              "#8CBD46"
                                                          },
                                                          attrs: {
                                                            dark: "",
                                                            loading:
                                                              _vm.videoLoadings[
                                                                index
                                                              ],
                                                            "x-small": "",
                                                            elevation: "0"
                                                          },
                                                          on: {
                                                            click: function() {
                                                              _vm.play(
                                                                el,
                                                                index
                                                              )
                                                              _vm.menu = false
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c("img", {
                                                            staticStyle: {
                                                              height: "20px",
                                                              filter:
                                                                "brightness(1000%)"
                                                            },
                                                            attrs: {
                                                              src:
                                                                _vm.rechtsIcon
                                                            }
                                                          })
                                                        ]
                                                      )
                                                    : [
                                                        "doc",
                                                        "docx",
                                                        "ppt",
                                                        "pptx",
                                                        "xls",
                                                        "xlsx"
                                                      ].includes(
                                                        el.title
                                                          .split(".")
                                                          .pop()
                                                          .toLowerCase()
                                                      )
                                                    ? _c(
                                                        "v-btn",
                                                        {
                                                          staticClass:
                                                            "btnStyle",
                                                          staticStyle: {
                                                            "background-color":
                                                              "#8CBD46"
                                                          },
                                                          attrs: {
                                                            dark: "",
                                                            "x-small": "",
                                                            elevation: "0"
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.fileNotSupported(
                                                                el
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c("img", {
                                                            staticStyle: {
                                                              height: "20px",
                                                              filter:
                                                                "brightness(1000%)"
                                                            },
                                                            attrs: {
                                                              src: _vm.infoIcon
                                                            }
                                                          })
                                                        ]
                                                      )
                                                    : !el.seenByMe
                                                    ? _c(
                                                        "v-btn",
                                                        {
                                                          staticClass:
                                                            "btnStyle",
                                                          staticStyle: {
                                                            "background-color":
                                                              "#8CBD46"
                                                          },
                                                          attrs: {
                                                            "x-small": "",
                                                            elevation: "0"
                                                          },
                                                          on: {
                                                            click: function() {
                                                              return _vm.openEdit(
                                                                el.title,
                                                                el.file,
                                                                el._id
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c("img", {
                                                            staticStyle: {
                                                              height: "20px"
                                                            },
                                                            attrs: {
                                                              src: _vm.stiftIcon
                                                            }
                                                          })
                                                        ]
                                                      )
                                                    : _c(
                                                        "v-btn",
                                                        {
                                                          staticClass:
                                                            "btnStyle",
                                                          attrs: {
                                                            "x-small": "",
                                                            elevation: "0"
                                                          },
                                                          on: {
                                                            click: function() {
                                                              return _vm.openEdit(
                                                                el.title,
                                                                el.file,
                                                                el._id
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c("img", {
                                                            staticStyle: {
                                                              height: "20px"
                                                            },
                                                            attrs: {
                                                              src:
                                                                _vm.bearbeitenIcon
                                                            }
                                                          })
                                                        ]
                                                      )
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass: "btnStyle ml-2",
                                                  staticStyle: {
                                                    width: "20px",
                                                    height: "20px"
                                                  },
                                                  attrs: {
                                                    "x-small": "",
                                                    elevation: "0"
                                                  },
                                                  on: {
                                                    click: function() {
                                                      return _vm.clickDownloadTeacherUpload(
                                                        el
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("img", {
                                                    staticStyle: {
                                                      height: "20px"
                                                    },
                                                    attrs: {
                                                      src: _vm.runterladenIcon
                                                    }
                                                  })
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                                _c(
                                  "div",
                                  { staticClass: "matLong" },
                                  _vm._l(
                                    _vm.thisAppointment.teacherUploads.filter(
                                      function(item) {
                                        return !item.editParent
                                      }
                                    ),
                                    function(el, index) {
                                      return _c(
                                        "div",
                                        {
                                          key: el + index,
                                          staticClass:
                                            "py-2 px-5 d-flex justify-space-between align-center",
                                          class: {
                                            brighterFileRow: index % 2 === 1
                                          },
                                          staticStyle: {
                                            "font-size": "medium",
                                            "font-weight": "normal",
                                            "line-height": "25px",
                                            width: "100%",
                                            "background-color":
                                              "rgba(112,112,112, 0.15)"
                                          }
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "fileName",
                                              staticStyle: {
                                                "min-width": "70%",
                                                width: "70%"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(el.title) + " "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                display: "inline-block"
                                              }
                                            },
                                            [
                                              _c(
                                                "v-badge",
                                                {
                                                  staticStyle: {
                                                    "z-index": "9"
                                                  },
                                                  attrs: {
                                                    color: "red",
                                                    content: "1",
                                                    value: !el.seenByMe,
                                                    overlap: "",
                                                    "offset-x": "15"
                                                  }
                                                },
                                                [
                                                  [
                                                    "mp4",
                                                    "wmv",
                                                    "flv",
                                                    "webm",
                                                    "mpg",
                                                    "m4v",
                                                    "mov",
                                                    "mkv"
                                                  ].includes(
                                                    el.title
                                                      .split(".")
                                                      .pop()
                                                      .toLowerCase()
                                                  )
                                                    ? _c(
                                                        "v-btn",
                                                        {
                                                          staticClass:
                                                            "btnStyle",
                                                          staticStyle: {
                                                            "background-color":
                                                              "#8CBD46"
                                                          },
                                                          attrs: {
                                                            dark: "",
                                                            loading:
                                                              _vm.videoLoadings[
                                                                index
                                                              ],
                                                            "x-small": "",
                                                            elevation: "0"
                                                          },
                                                          on: {
                                                            click: function() {
                                                              _vm.play(
                                                                el,
                                                                index
                                                              )
                                                              _vm.menu = false
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c("img", {
                                                            staticStyle: {
                                                              height: "20px",
                                                              filter:
                                                                "brightness(1000%)"
                                                            },
                                                            attrs: {
                                                              src:
                                                                _vm.rechtsIcon
                                                            }
                                                          })
                                                        ]
                                                      )
                                                    : [
                                                        "doc",
                                                        "docx",
                                                        "ppt",
                                                        "pptx",
                                                        "xls",
                                                        "xlsx"
                                                      ].includes(
                                                        el.title
                                                          .split(".")
                                                          .pop()
                                                          .toLowerCase()
                                                      )
                                                    ? _c(
                                                        "v-btn",
                                                        {
                                                          staticClass:
                                                            "btnStyle",
                                                          staticStyle: {
                                                            "background-color":
                                                              "#8CBD46"
                                                          },
                                                          attrs: {
                                                            dark: "",
                                                            "x-small": "",
                                                            elevation: "0"
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.fileNotSupported(
                                                                el
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c("img", {
                                                            staticStyle: {
                                                              height: "20px",
                                                              filter:
                                                                "brightness(1000%)"
                                                            },
                                                            attrs: {
                                                              src: _vm.infoIcon
                                                            }
                                                          })
                                                        ]
                                                      )
                                                    : !el.seenByMe
                                                    ? _c(
                                                        "v-btn",
                                                        {
                                                          staticClass:
                                                            "btnStyle",
                                                          staticStyle: {
                                                            "background-color":
                                                              "#8CBD46"
                                                          },
                                                          attrs: {
                                                            "x-small": "",
                                                            elevation: "0"
                                                          },
                                                          on: {
                                                            click: function() {
                                                              return _vm.openEdit(
                                                                el.title,
                                                                el.file,
                                                                el._id
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c("img", {
                                                            staticStyle: {
                                                              height: "20px"
                                                            },
                                                            attrs: {
                                                              src: _vm.stiftIcon
                                                            }
                                                          })
                                                        ]
                                                      )
                                                    : _c(
                                                        "v-btn",
                                                        {
                                                          staticClass:
                                                            "btnStyle",
                                                          attrs: {
                                                            "x-small": "",
                                                            elevation: "0"
                                                          },
                                                          on: {
                                                            click: function() {
                                                              return _vm.openEdit(
                                                                el.title,
                                                                el.file,
                                                                el._id
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c("img", {
                                                            staticStyle: {
                                                              height: "20px"
                                                            },
                                                            attrs: {
                                                              src:
                                                                _vm.bearbeitenIcon
                                                            }
                                                          })
                                                        ]
                                                      )
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass: "btnStyle ml-2",
                                                  staticStyle: {
                                                    width: "20px",
                                                    height: "20px"
                                                  },
                                                  attrs: {
                                                    "x-small": "",
                                                    elevation: "0"
                                                  },
                                                  on: {
                                                    click: function() {
                                                      return _vm.clickDownloadTeacherUpload(
                                                        el
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("img", {
                                                    staticStyle: {
                                                      height: "20px"
                                                    },
                                                    attrs: {
                                                      src: _vm.runterladenIcon
                                                    }
                                                  })
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                                _vm.thisAppointment.teacherUploads.filter(
                                  function(item) {
                                    return !item.editParent
                                  }
                                ).length > 2
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex justify-center mt-2"
                                      },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass: "matShort",
                                            attrs: {
                                              small: "",
                                              elevation: "0"
                                            },
                                            on: {
                                              click: function($event) {
                                                _vm.hideEl("matShort")
                                                _vm.showEl("matLong")
                                              }
                                            }
                                          },
                                          [
                                            _c("img", {
                                              staticStyle: { height: "20px" },
                                              attrs: { src: _vm.arrowDown }
                                            })
                                          ]
                                        ),
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass: "matLong",
                                            attrs: {
                                              small: "",
                                              elevation: "0"
                                            },
                                            on: {
                                              click: function($event) {
                                                _vm.hideEl("matLong")
                                                _vm.showEl("matShort")
                                              }
                                            }
                                          },
                                          [
                                            _c("img", {
                                              staticStyle: {
                                                height: "20px",
                                                transform: "rotate(180deg)"
                                              },
                                              attrs: { src: _vm.arrowDown }
                                            })
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          ]),
                          _c("hr", { staticClass: "eHr" }),
                          _c("div", { staticClass: "mx-0" }, [
                            _c(
                              "div",
                              { staticClass: "mt-2" },
                              [
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        staticClass:
                                          "font-weight-bold pb-2 mx-5 pt-0 d-flex align-center"
                                      },
                                      [
                                        _c("img", {
                                          staticStyle: { height: "20px" },
                                          attrs: { src: _vm.abgabenIcon }
                                        }),
                                        _c(
                                          "p",
                                          {
                                            staticClass: "pl-2 ma-0",
                                            staticStyle: {
                                              "font-size": "medium"
                                            }
                                          },
                                          [_vm._v("Abgaben")]
                                        )
                                      ]
                                    ),
                                    _c(
                                      "v-col",
                                      { staticClass: "text-right py-1" },
                                      [
                                        _c("input", {
                                          ref: "pupilUploadInput",
                                          attrs: {
                                            id: "pupilUploadInput",
                                            type: "file",
                                            hidden: ""
                                          },
                                          on: {
                                            change: function() {
                                              return _vm.pupilUploadInputChange(
                                                _vm.thisAppointment._id
                                              )
                                            }
                                          }
                                        })
                                      ]
                                    )
                                  ],
                                  1
                                ),
                                _vm.thisAppointment.pupilUpload.length === 0
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "mx-5",
                                        staticStyle: { "font-size": "medium" }
                                      },
                                      [
                                        _vm._v(
                                          " Du hast noch nichts abgegeben. "
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _c(
                                  "div",
                                  { staticClass: "uploadsShort" },
                                  _vm._l(
                                    _vm.thisAppointment.pupilUpload.slice(0, 2),
                                    function(el, index) {
                                      return _c(
                                        "v-row",
                                        {
                                          key: el + index,
                                          staticClass:
                                            "py-2 px-5 ma-0 d-flex justify-space-between align-center",
                                          class: {
                                            brighterFileRow: index % 2 === 1,
                                            correctionAvailableClass: _vm.isCorrectionAvailable(
                                              el
                                            )
                                          },
                                          staticStyle: {
                                            "font-size": "medium",
                                            "font-weight": "normal",
                                            "line-height": "25px",
                                            width: "100%",
                                            "background-color":
                                              "rgba(112,112,112, 0.15)"
                                          }
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "fileName",
                                              staticStyle: {
                                                "max-width": "75% !important",
                                                "min-width": "75% !important"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(el.title) + " "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                display: "inline-block"
                                              }
                                            },
                                            [
                                              true
                                                ? _c(
                                                    "v-btn",
                                                    {
                                                      staticClass:
                                                        "btnStyle mx-1",
                                                      attrs: {
                                                        "x-small": "",
                                                        elevation: "0"
                                                      },
                                                      on: {
                                                        click: function() {
                                                          return _vm.handleOpenAnsichtClick(
                                                            el
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          staticStyle: {
                                                            width: "20px",
                                                            color: "#939393"
                                                          }
                                                        },
                                                        [_vm._v("fas fa-eye")]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm.isCorrectionAvailable(el)
                                                ? _c(
                                                    "v-btn",
                                                    {
                                                      staticClass: "btnStyle",
                                                      attrs: {
                                                        elevation: "0",
                                                        "x-small": ""
                                                      },
                                                      on: {
                                                        click: function() {
                                                          return _vm.clickDownloadPupilCorrection(
                                                            el
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c("img", {
                                                        staticStyle: {
                                                          height: "20px"
                                                        },
                                                        attrs: {
                                                          src:
                                                            _vm.bearbeitetIcon
                                                        }
                                                      })
                                                    ]
                                                  )
                                                : _c(
                                                    "v-btn",
                                                    {
                                                      staticClass: "btnStyle",
                                                      attrs: {
                                                        elevation: "0",
                                                        "x-small": ""
                                                      },
                                                      on: {
                                                        click: function() {
                                                          return _vm.clickDownloadPupilUpload(
                                                            el
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c("img", {
                                                        staticStyle: {
                                                          height: "20px"
                                                        },
                                                        attrs: {
                                                          src: _vm.offenIcon
                                                        }
                                                      })
                                                    ]
                                                  )
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    }
                                  ),
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "uploadsLong" },
                                  _vm._l(
                                    _vm.thisAppointment.pupilUpload,
                                    function(el, index) {
                                      return _c(
                                        "v-row",
                                        {
                                          key: el + index,
                                          staticClass:
                                            "py-2 px-5 ma-0 d-flex justify-space-between align-center",
                                          class: {
                                            brighterFileRow: index % 2 === 1,
                                            correctionAvailableClass: _vm.isCorrectionAvailable(
                                              el
                                            )
                                          },
                                          staticStyle: {
                                            "font-size": "medium",
                                            "font-weight": "normal",
                                            "line-height": "25px",
                                            width: "100%",
                                            "min-height": "49px",
                                            "background-color":
                                              "rgba(112,112,112, 0.15)"
                                          }
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "fileName",
                                              staticStyle: {
                                                "max-width": "75% !important",
                                                "min-width": "75% !important"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(el.title) + " "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "pa-0 ml-0" },
                                            [
                                              true
                                                ? _c(
                                                    "v-btn",
                                                    {
                                                      staticClass:
                                                        "btnStyle mx-1",
                                                      attrs: {
                                                        "x-small": "",
                                                        elevation: "0"
                                                      },
                                                      on: {
                                                        click: function() {
                                                          return _vm.handleOpenAnsichtClick(
                                                            el
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          staticStyle: {
                                                            width: "20px",
                                                            color: "#939393"
                                                          }
                                                        },
                                                        [_vm._v("fas fa-eye")]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm.isCorrectionAvailable(el)
                                                ? _c(
                                                    "v-btn",
                                                    {
                                                      staticClass: "ml-2 pa-0",
                                                      staticStyle: {
                                                        width: "20px",
                                                        height: "20px"
                                                      },
                                                      attrs: {
                                                        elevation: "0",
                                                        "x-small": ""
                                                      },
                                                      on: {
                                                        click: function() {
                                                          return _vm.clickDownloadPupilCorrection(
                                                            el
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c("img", {
                                                        staticStyle: {
                                                          height: "20px"
                                                        },
                                                        attrs: {
                                                          src:
                                                            _vm.bearbeitetIcon
                                                        }
                                                      })
                                                    ]
                                                  )
                                                : _c(
                                                    "v-btn",
                                                    {
                                                      staticClass: "btnStyle",
                                                      attrs: {
                                                        elevation: "0",
                                                        "x-small": ""
                                                      },
                                                      on: {
                                                        click: function() {
                                                          return _vm.clickDownloadPupilUpload(
                                                            el
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c("img", {
                                                        staticStyle: {
                                                          height: "20px"
                                                        },
                                                        attrs: {
                                                          src: _vm.offenIcon
                                                        }
                                                      })
                                                    ]
                                                  )
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    }
                                  ),
                                  1
                                ),
                                _vm.thisAppointment.pupilUpload.length > 2
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex justify-center mt-2"
                                      },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass: "uploadsShort",
                                            attrs: {
                                              small: "",
                                              elevation: "0"
                                            },
                                            on: {
                                              click: function($event) {
                                                _vm.hideEl("uploadsShort")
                                                _vm.showEl("uploadsLong")
                                              }
                                            }
                                          },
                                          [
                                            _c("img", {
                                              staticStyle: { height: "20px" },
                                              attrs: { src: _vm.arrowDown }
                                            })
                                          ]
                                        ),
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass: "uploadsLong",
                                            attrs: {
                                              small: "",
                                              elevation: "0"
                                            },
                                            on: {
                                              click: function($event) {
                                                _vm.hideEl("uploadsLong")
                                                _vm.showEl("uploadsShort")
                                              }
                                            }
                                          },
                                          [
                                            _c("img", {
                                              staticStyle: {
                                                height: "20px",
                                                transform: "rotate(180deg)"
                                              },
                                              attrs: { src: _vm.arrowDown }
                                            })
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c("div", { staticClass: "my-4" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "d-flex",
                                      staticStyle: {
                                        "justify-content": "center"
                                      }
                                    },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "text-capitalize",
                                          staticStyle: {
                                            "padding-left": "30px",
                                            "padding-right": "30px",
                                            color: "#707070"
                                          },
                                          attrs: { elevation: "0" },
                                          on: {
                                            click: _vm.clickPupilUploadButton
                                          }
                                        },
                                        [
                                          _c("img", {
                                            staticClass: "mr-2",
                                            staticStyle: { height: "20px" },
                                            attrs: { src: _vm.hochladenIcon }
                                          }),
                                          _vm._v(" Hochladen ")
                                        ]
                                      ),
                                      true
                                        ? _c(
                                            "v-menu",
                                            {
                                              attrs: { "offset-x": "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function(ref) {
                                                      var on = ref.on
                                                      var attrs = ref.attrs
                                                      return [
                                                        _c(
                                                          "v-btn",
                                                          _vm._g(
                                                            _vm._b(
                                                              {
                                                                staticClass:
                                                                  "ml-1 pa-0",
                                                                staticStyle: {
                                                                  color:
                                                                    "#707070",
                                                                  height:
                                                                    "36px",
                                                                  width:
                                                                    "30px !important"
                                                                },
                                                                attrs: {
                                                                  elevation:
                                                                    "0",
                                                                  small: ""
                                                                },
                                                                on: {
                                                                  click: function() {
                                                                    _vm.emptyEditorPagesOpen = !_vm.emptyEditorPagesOpen
                                                                  }
                                                                }
                                                              },
                                                              "v-btn",
                                                              attrs,
                                                              false
                                                            ),
                                                            on
                                                          ),
                                                          [
                                                            _c("img", {
                                                              style:
                                                                "height: 20px; transform: rotate(" +
                                                                (_vm.emptyEditorPagesOpen
                                                                  ? "180"
                                                                  : "0") +
                                                                "deg)",
                                                              attrs: {
                                                                src:
                                                                  _vm.arrowDown
                                                              }
                                                            })
                                                          ]
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                false,
                                                4065998
                                              )
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  attrs: { id: "emptyPageMenu" }
                                                },
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      staticClass:
                                                        "d-flex justify-start",
                                                      staticStyle: {
                                                        "text-transform":
                                                          "inherit",
                                                        color: "#707070"
                                                      },
                                                      attrs: { text: "" },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.openEmptyEdit(
                                                            "blank"
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c("img", {
                                                        staticClass: "mr-2",
                                                        staticStyle: {
                                                          height: "20px"
                                                        },
                                                        attrs: {
                                                          src: _vm.leeresBlatt
                                                        }
                                                      }),
                                                      _vm._v(" Leeres Blatt ")
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      staticClass:
                                                        "d-flex justify-start",
                                                      staticStyle: {
                                                        "text-transform":
                                                          "inherit",
                                                        color: "#707070"
                                                      },
                                                      attrs: { text: "" },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.openEmptyEdit(
                                                            "kariert"
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c("img", {
                                                        staticClass: "mr-2",
                                                        staticStyle: {
                                                          height: "20px"
                                                        },
                                                        attrs: {
                                                          src:
                                                            _vm.kariertesBlatt
                                                        }
                                                      }),
                                                      _vm._v(
                                                        " Kariertes Blatt "
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      staticClass:
                                                        "d-flex justify-start",
                                                      staticStyle: {
                                                        "text-transform":
                                                          "inherit",
                                                        color: "#707070"
                                                      },
                                                      attrs: { text: "" },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.openEmptyEdit(
                                                            "liniert"
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c("img", {
                                                        staticClass: "mr-2",
                                                        staticStyle: {
                                                          height: "20px"
                                                        },
                                                        attrs: {
                                                          src:
                                                            _vm.liniertesBlatt
                                                        }
                                                      }),
                                                      _vm._v(
                                                        " Liniertes Blatt "
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ])
                              ],
                              1
                            )
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    {
                      staticClass: "pa-0 ma-0",
                      staticStyle: { overflow: "hidden" }
                    },
                    [
                      _c(
                        "v-row",
                        [
                          _c("hr", { staticClass: "eHr mt-2" }),
                          _c(
                            "v-col",
                            { staticClass: "text-center" },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "text-capitalize",
                                  staticStyle: {
                                    color: "white",
                                    "padding-left": "50px",
                                    "padding-right": "50px"
                                  },
                                  style: _vm.isJoinable
                                    ? "background-color: #8CBD46"
                                    : "background-color: grey",
                                  attrs: { elevation: "0" },
                                  on: {
                                    click: function() {
                                      _vm.isJoinable
                                        ? _vm.clickBeitreten()
                                        : (_vm.snackbarRoomClosed = true)
                                    }
                                  }
                                },
                                [
                                  _c("img", {
                                    staticClass: "mr-5",
                                    staticStyle: { height: "20px" },
                                    attrs: { src: _vm.beitretenKameraIcon }
                                  }),
                                  _vm._v(" Beitreten ")
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _c(
            "div",
            {
              staticClass: "fach pa-2 white--text",
              staticStyle: {
                width: "90%",
                height: "70.5px !important",
                display: "block",
                "padding-left": "0 !important"
              }
            },
            [
              _c(
                "v-row",
                { staticClass: "ml-2 font-weight-medium" },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "pa-0 ma-0",
                      staticStyle: { height: "48px !important" },
                      attrs: { cols: "12" }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "pa-3 ma-0 appointmentItem inactiveAppointment"
                        },
                        [
                          _c("img", { staticClass: "iconSelectFrei" }),
                          _vm._v(" Hohlstunde ")
                        ]
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
      _c(
        "v-snackbar",
        {
          attrs: { bottom: "", color: "error", timeout: "3000" },
          model: {
            value: _vm.snackbar,
            callback: function($$v) {
              _vm.snackbar = $$v
            },
            expression: "snackbar"
          }
        },
        [_vm._v(" " + _vm._s(_vm.snackbarText) + " ")]
      ),
      _c(
        "v-snackbar",
        {
          attrs: { bottom: "", color: "success", timeout: "3000" },
          model: {
            value: _vm.snackbarSave,
            callback: function($$v) {
              _vm.snackbarSave = $$v
            },
            expression: "snackbarSave"
          }
        },
        [_vm._v(" Gespeichert! ")]
      ),
      _c(
        "v-snackbar",
        {
          staticStyle: { "z-index": "9999 !important" },
          attrs: { bottom: "", color: "grey", timeout: "5000" },
          model: {
            value: _vm.snackbarRoomClosed,
            callback: function($$v) {
              _vm.snackbarRoomClosed = $$v
            },
            expression: "snackbarRoomClosed"
          }
        },
        [_vm._v(" Dieser Raum ist abgeschlossen. ")]
      ),
      _c(
        "v-snackbar",
        {
          staticStyle: { "z-index": "9999 !important" },
          attrs: { bottom: "", color: "grey", timeout: "5000" },
          model: {
            value: _vm.snackbarFileNotSupported,
            callback: function($$v) {
              _vm.snackbarFileNotSupported = $$v
            },
            expression: "snackbarFileNotSupported"
          }
        },
        [
          _vm._v(
            " Diese Datei wird vom Editor nicht unterstützt. Bitte lade sie herunter. "
          )
        ]
      ),
      _vm.videoUrl
        ? _c(
            "v-overlay",
            { attrs: { opacity: "0.95", "z-index": "95" } },
            [
              _c(
                "v-icon",
                {
                  staticStyle: {
                    position: "fixed",
                    top: "50px",
                    right: "50px",
                    "z-index": "99"
                  },
                  attrs: { large: "", dark: "" },
                  on: {
                    click: function($event) {
                      _vm.videoUrl = false
                    }
                  }
                },
                [_vm._v(" fas fa-times ")]
              ),
              _c(
                "video",
                {
                  style: { marginLeft: _vm.windowWidth > 900 ? "10%" : "10px" },
                  attrs: {
                    src: _vm.videoUrl,
                    controls: "",
                    width: _vm.windowWidth > 900 ? "80%" : "40%%"
                  }
                },
                [_vm._v(" Your browser does not support the video tag. ")]
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }