import { render, staticRenderFns } from "./HausiWidgetBaby.vue?vue&type=template&id=3dbecf84&scoped=true&"
import script from "./HausiWidgetBaby.vue?vue&type=script&lang=js&"
export * from "./HausiWidgetBaby.vue?vue&type=script&lang=js&"
import style0 from "./HausiWidgetBaby.vue?vue&type=style&index=0&id=3dbecf84&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3dbecf84",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
installComponents(component, {VBtn,VCol,VIcon,VOverlay,VProgressCircular,VRow,VSnackbar})


/* hot reload */
if (module.hot) {
  var api = require("/home/peternauroth/homeschooling/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3dbecf84')) {
      api.createRecord('3dbecf84', component.options)
    } else {
      api.reload('3dbecf84', component.options)
    }
    module.hot.accept("./HausiWidgetBaby.vue?vue&type=template&id=3dbecf84&scoped=true&", function () {
      api.rerender('3dbecf84', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Schueler/HausiWidgetBaby.vue"
export default component.exports