<template>
    <div>
        <div class="group-chat-header pa-4">
            <span class="group-chat-header-text">
                <div v-if="addNew" style="display: flex; width: 100%;">
                    <img :src="suchIcon" style="height: 20px; margin-left: 0; margin-top: 2px;" class="iconToWhite"/>
                    <v-autocomplete
                        v-model="contact"
                        label="Nach Kontakt suchen"
                        single-line
                        dark
                        hide-details
                        :item-text="(el) => {return el.name + ' ' + el.lastName}"
                        item-value="account"
                        class="pl-2 pt-0 mt-0"
                        item-color="gruen"
                        height="24"
                        style="z-index: 10"
                        :items="matrixUsers"
                        :filter="customFilter"
                        no-data-text="Keine passender Kontakt"
                    >
                    </v-autocomplete>
                    <img :src="abbrechenIcon" @click="(addNew=false) && (contact='')" style="height: 20px; margin-top: 2px;" class="iconToWhite pl-2"/>
                    <img :src="erledigtIcon" @click="startPrivateRoom()" style="height: 20px; margin-top: 2px;" class="iconToWhite pl-2"/>
                </div>
                <div v-else style="width: 100%; display: flex;">
                    Gruppen
                    <img  @click="(addNew=true) && (contact='')" :src="hinzufuegenIcon" class="iconToWhite" style="display: block; height: 20px; margin-left: auto; margin-right: 0; cursor: pointer;"/>
                </div>
            </span>
        </div>
        <vue-custom-scrollbar
            class="scroll-area"
            :settings="settings"
            v-if="rooms.length > 0"
        >
            <v-list class="chat-body pa-0">
                <v-list-item-group :key="index" v-for="(room, index) in rooms">
                    <template>
                        <v-list-item @click="() => clickSelectRoom(room)" class="group-item">
                            <v-list-item-icon class="my-3">
                                <v-badge
                                    color="red"
                                    :content="notificationArray[index].newMessagesCount"
                                    :value="notificationArray[index].newMessagesAvailable > 10 ? '10+': notificationArray[index].newMessagesAvailable"
                                    overlap
                                    offset-x="15"
                                    style="z-index: 9"
                                >
                                   <v-btn medium fab color="#6994cc">
                                       <img v-if="isTeacher(room)" :src="hasProfilePic(room) ? getTeachPic() : lehrerIcon" :height="hasProfilePic(room) ? 56 : 25" :class="hasProfilePic(room) ? 'roundImage' : 'iconToWhite'">
                                       <img v-else-if="isPupil(room)" :src="schuelerIcon" height="25" class="iconToWhite">
                                       <img v-else :src="chatIcon" height="25" class="iconToWhite">
                                    </v-btn>
                                </v-badge>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title
                                    v-text="room.name.includes('private Room')? checkRoomName(room.name) : room.name"
                                    class="greyText"
                                >
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                        <v-divider></v-divider>
                    </template>
                </v-list-item-group>
            </v-list>
        </vue-custom-scrollbar>

        <div v-else class="pa-4" style="color: #707070">Keine Gruppen</div>

        <v-snackbar bottom v-model="snackbar" color="error" timeout="3000">
            {{snackbarText}}
        </v-snackbar>
    </div>
</template>

<script>
    import {mapActions, mapState} from "vuex";
    import vueCustomScrollbar from "vue-custom-scrollbar";
    import suchIcon from '../../assets/Icons/suche-26.svg';
    import abbrechenIcon from '../../assets/Icons/abbrechen-08.svg';
    import erledigtIcon from '../../assets/Icons/fertig-74.svg';
    import hinzufuegenIcon from '../../assets/Icons/hinzufuegen-07.svg';
    import chatIcon from '../../assets/Icons/chat-alternative-102.svg';
    import pfeilIcon from '../../assets/Icons/unten-dropdown-12.svg';
    import lehrerIcon from '../../assets/Icons/lehrer-24.svg';
    import schuelerIcon from '../../assets/Icons/profil-schueler.svg'
    import * as backend from "../../api/backend";

    export default {
        name: "ChatWidget",
        components: {vueCustomScrollbar},
        data: () => ({
            matrixClient: null,
            viewingRoom: null,
            numMessagesToShow: 20,
            contact: '',
            matrixUsers: [],
            maintainers: [],
            notificationArray: [],
            addNew: false,
            settings: {
                suppressScrollY: false,
                suppressScrollX: true,
                wheelPropagation: false,
            },
            chatIds: [],
            snackbar: false,
            snackbarText: '',

            teachers: [],
            teacherPics: [], //collection of teacher profile pictures

            suchIcon,
            abbrechenIcon,
            erledigtIcon,
            hinzufuegenIcon,
            chatIcon,
            pfeilIcon,
            lehrerIcon,
            schuelerIcon,
        }),
        props: {
            rooms: {required: true},
            matrixClient: {required: true}
        },
        async created() {
            this.notificationArray = [];
            for (let i = 0; i < this.rooms.length; i++) {
                this.notificationArray.push({
                    newMessagesCount: 0,
                    newMessagesAvailable: false
                })
                await this.checkForNewMessages(this.rooms[i], i);
            }
        },
        async mounted() {
            this.requestAppointments();
            this.requestPupil();
            this.requestTeachers();
            this.requestMaintainers();

        },
        computed: {
            ...mapState("auth", ["account"]),
        },
        watch: {
            rooms: function () {
                this.notificationArray = [];
                for (let i = 0; i < this.rooms.length; i++) {
                    this.notificationArray.push({
                        newMessagesCount: 0,
                        newMessagesAvailable: false
                    })
                    this.checkForNewMessages(this.rooms[i], i);
                }
            }
        },
        methods: {
            ...mapActions("appointments", ["getAppointments"]),
            ...mapActions("pupils", ["getPupils"]),
            ...mapActions("matrix", ["createPrivateRoom"]),
            ...mapActions("teachers", ["getProfilePicture", "getTeachers"]),
            ...mapActions("maintainers", ["getMaintainers"]),
            async startPrivateRoom() {
                if(this.contact) {
                    if(!this.chatIds.includes(this.contact)) {
                        await this.createPrivateRoom({second: this.contact});
                        this.$emit("setRoomList");
                        this.addNew = false;
                        this.contact = '';
                    } else {
                        this.snackbarText = 'Sie haben schon einen Chat mit diesem Nutzer';
                        this.snackbar = true;
                    }
                }
            },
            async requestAppointments() {
                const appointments = await this.getAppointments();
                if(this.account.role === 'pupil') {
                    appointments.forEach((el) => {
                        const index = this.matrixUsers.indexOf(el.teacher);
                        if (index === -1) {
                            this.matrixUsers.push(el.teacher);
                        }
                    })
                }
            },
            async requestPupil() {
                if(this.account.role === 'teacher') {
                    this.matrixUsers = await this.getPupils();
                    this.matrixUsers.forEach((el) => el.account = el.account._id);

                    this.matrixUsers.sort((a, b) => a.name.localeCompare(b.name));
                }
            },
            async requestTeachers() {
                this.teachers = await this.getTeachers();
                if(this.account.role === 'maintainer') {
                    this.matrixUsers = await JSON.parse(JSON.stringify(this.teachers));

                    this.matrixUsers.sort((a, b) => a.name.localeCompare(b.name));
                }
                await this.setTeachProfilePics();
            },
            async requestMaintainers() {
                if(this.account.role === 'teacher') {
                    this.maintainers = await this.getMaintainers();
                }
            },
            checkRoomName(name) {
                const id = name.replace(this.account._id, "").replace("private Room", "").replace("<", "").replace(">", "").replace(",", "").replace(" ", "");
                let secondUser = this.matrixUsers.find((el) => {
                    return el.account === id;
                });
                if(secondUser) {
                    return secondUser.lastName +  ' ' + secondUser.name;
                } else {
                    if(this.account.role === 'teacher') {
                        secondUser = this.maintainers.find((el) => {
                            return el.account._id === id;
                        });
                        if(secondUser) {
                            return 'Verwaltung';
                        } else {
                            return 'Unbekannter Nutzer';
                        }
                    }
                    return 'Unbekannter Nutzer';
                }
            },
            clickSelectRoom(room) {
                this.$emit("roomSelected", room);
            },
            async checkForNewMessages(room, index) {
                const lastReadId = await room.getEventReadUpTo(room.myUserId, false);
                let unreadEventsCount = 0;
                let unreadMessageCount = 0;

                for (let i = 0; i < room.timeline.length; i++) {
                    if (lastReadId === room.timeline[i].event.event_id) {
                        unreadEventsCount = room.timeline.length - i - 1;
                        break;
                    }
                    unreadEventsCount = room.timeline.length;
                }

                for (let i = 0; i < unreadEventsCount; i++) {
                    if(room.timeline[room.timeline.length - 1 - i].event.type === "m.room.message") {
                        unreadMessageCount++;
                    }
                }

                this.notificationArray[index].newMessagesCount = unreadMessageCount;
                if (this.notificationArray[index].newMessagesCount > 0) {
                    this.notificationArray[index].newMessagesAvailable = true;
                }
            },
            customFilter(item, queryText, itemText) {
                const textOne = item.name.toLowerCase();
                const searchText = queryText.toLowerCase();

                return textOne.indexOf(searchText) > -1
            },

            //get profile pics from all teachers and save them in teacherPics array
            async setTeachProfilePics() {
                for (let i = 0; i < this.teachers.length; i++) {
                    let teacher = this.matrixUsers.find((el) => {
                        return el.account === this.teachers[i].account;
                    });
                    if (teacher) {
                        if (teacher.profilePicture) {
                            let profilePictureBlob = await this.getProfilePicture(teacher._id);
                            const objectURL = URL.createObjectURL(profilePictureBlob);

                            this.teacherPics.push([teacher.name, teacher.lastName, objectURL]);
                        }
                    }
                }
            },
            //get teacher profile pic from teacherPics array
            getTeachPic() {
                for (let i = 0; i < this.teachers.length; i++) {
                    let teacher = this.matrixUsers.find((el) => {
                        return el.account === this.teachers[i].account;
                    });

                    if (teacher) {
                        if (teacher.profilePicture) {
                            for (let j = 0; j < this.teachers.length; j++) {
                                let currTeacher = this.teacherPics[j];
                                if (currTeacher === undefined) {
                                    continue;
                                } else if (currTeacher[0] === teacher.name && currTeacher[1] === teacher.lastName) {
                                    return currTeacher[2];
                                }
                            }
                        }
                    }
                }
                return lehrerIcon;
            },
            //checks if chat opponent has a profile pic
            hasProfilePic(room) {
                let name = this.checkRoomName(room.name)
                let names = [];
                if (name !== "Unbekannter Nutzer") {
                    names = name.split(" ");

                    for (let j = 0; j < this.teacherPics.length; j++) {
                        let currTeacher = this.teacherPics[j];
                        if (currTeacher === undefined) {
                            continue;
                        } else if (currTeacher[0] === names[1] && currTeacher[1] === names[0]) {
                            return true;
                        }
                    }
                }
                return false;
            },
            //checks if chat opponent a teacher
            isTeacher(room) {
                let name = this.checkRoomName(room.name)
                let names = []; //lastname, firstname
                if (name !== "Unbekannter Nutzer") {
                    names = name.split(" ");

                    for (let j = 0; j < this.teachers.length; j++) {
                        let currTeacher = this.teachers[j];
                        if (currTeacher === undefined) {
                            continue;
                        } else if (currTeacher.name === names[1] && currTeacher.lastName === names[0]) {
                            return true;
                        }
                    }
                }
                return false;
            },
            // check if chat opponent is a pupil
            isPupil(room) {
                let name = this.checkRoomName(room.name)
                let names = []; //lastname, firstname
                if (name !== "Unbekannter Nutzer") {
                    names = name.split(" ");

                    for (let j = 0; j < this.matrixUsers.length; j++) {
                        let currentPupil = this.matrixUsers[j];
                        if (currentPupil && currentPupil.name === names[1] && currentPupil.lastName === names[0]) {
                            return true;
                        }
                    }
                }
                return false;
            }
        }
    };
</script>

<style lang="scss" scoped>
    .group-chat-header-btn {
        padding: 0 !important;
        min-width: 0 !important;
    }

    .group-chat-header-text {
        color: white;
    }

    .group-chat-header {
        background-color: #95c94b; // 8CBD46 - 5% Luminosity
    }

    .chat-container {
        height: auto;
        width: 90%;
        min-height: 80vh;
        border-radius: 15px;
        box-shadow: 1px 5px 5px silver;
        margin: auto auto 5em;
    }

    .chat-header {
        background-color: #8ab450;
        border-radius: 15px 15px 0 0;
        font-size: larger;
        padding-top: 20px;
        padding-bottom: 20px;
        text-align: center;
        color: white;
    }

    .chat-header-btn {
        background-color: #f8f8f880 !important;
        width: 35px;
        height: 35px !important;
    }

    .chat-header-img {
        height: 20px;
        filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%) hue-rotate(36deg) brightness(104%) contrast(105%);
    }

    .scroll-area {
        position: relative;
        margin: auto;

        max-height: calc(80vh - 120px);
    }

    .greyText{
        color: #707070;
    }

    .iconToWhite {
        filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%) hue-rotate(36deg) brightness(104%) contrast(105%);
    }

    .roundImage {
        border-radius: 50%;
        height: 56px;
        width: 56px;
    }
</style>
