var render = function() {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-row", { staticClass: "mx-0 mt-3 mb-4" }, [
        _c(
          "div",
          {
            staticStyle: {
              height: "auto",
              width: "90%",
              margin: "auto",
              "border-radius": "15px",
              "box-shadow": "1px 5px 5px silver"
            }
          },
          [
            _c(
              "div",
              {
                staticStyle: {
                  "background-color": "#6995CD",
                  "border-radius": "15px 15px 0 0",
                  "font-size": "larger",
                  "padding-top": "5px",
                  "padding-bottom": "20px",
                  "text-align": "center",
                  color: "white"
                }
              },
              [
                _c(
                  "div",
                  { staticStyle: { display: "flex", width: "50%" } },
                  [
                    _c("img", {
                      staticStyle: {
                        height: "24px",
                        "margin-left": "24px",
                        "margin-top": "20px",
                        filter:
                          "brightness(0) saturate(100%) invert(100%) sepia(5%) saturate(19%) hue-rotate(65deg) brightness(104%) contrast(105%)"
                      },
                      attrs: { src: _vm.suchIcon }
                    }),
                    _c("v-autocomplete", {
                      staticClass: "pl-6",
                      staticStyle: { width: "50%" },
                      attrs: {
                        items: _vm.groups,
                        "item-text": "name",
                        label: "Gruppe suchen",
                        "single-line": "",
                        dark: "",
                        "hide-details": "",
                        "no-results-text":
                          "Keine übereinstimmenden Gruppen gefunden",
                        "no-data-text": "Keine Gruppen gefunden"
                      },
                      model: {
                        value: _vm.search,
                        callback: function($$v) {
                          _vm.search = $$v
                        },
                        expression: "search"
                      }
                    })
                  ],
                  1
                )
              ]
            ),
            _c(
              "div",
              {
                staticStyle: {
                  display: "flex",
                  "font-size": "larger",
                  "font-family": "Roboto",
                  "margin-top": "24px",
                  "margin-left": "26px"
                }
              },
              [
                _c("img", {
                  staticStyle: { height: "24px", "margin-right": "10px" },
                  attrs: { src: _vm.gruppenIcon }
                }),
                _vm._v(" Gruppen ")
              ]
            ),
            _c("v-data-table", {
              staticClass: "ma-3",
              attrs: {
                headers: _vm.headers,
                items: _vm.groups,
                search: _vm.search,
                "disable-pagination": "",
                "hide-default-footer": "",
                "fixed-header": "",
                "sort-by": "name",
                height: _vm.height,
                locale: "de-DE",
                "no-data-text": "Keine Daten verfügbar",
                "no-results-text":
                  "Keine übereinstimmenden Aufzeichnungen gefunden"
              },
              scopedSlots: _vm._u([
                {
                  key: "item",
                  fn: function(row) {
                    return [
                      _c("tr", [
                        _c("td", [_vm._v(_vm._s(row.item.name))]),
                        _c(
                          "td",
                          [
                            _c(
                              "v-btn",
                              {
                                staticClass: "mx-2",
                                staticStyle: {
                                  "border-radius": "5px",
                                  "text-transform": "inherit"
                                },
                                attrs: {
                                  small: "",
                                  color: "#EAEAEA",
                                  elevation: "1",
                                  light: ""
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.edit(row.item)
                                  }
                                }
                              },
                              [_vm._v(" Gruppe bearbeiten ")]
                            )
                          ],
                          1
                        )
                      ])
                    ]
                  }
                }
              ])
            }),
            _c(
              "v-overlay",
              {
                staticStyle: { width: "100% !important" },
                attrs: { absolute: "", value: _vm.showLoadingOverlay }
              },
              [
                _vm.showLoadingOverlay
                  ? _c("v-progress-circular", {
                      attrs: { indeterminate: "", size: "100" }
                    })
                  : _vm._e()
              ],
              1
            )
          ],
          1
        )
      ]),
      _c("v-row", { staticClass: "mx-0 my-3", attrs: { id: "edit" } }, [
        _c(
          "div",
          { staticClass: "editContainer" },
          [
            _c(
              "v-row",
              {
                staticClass: "mx-0",
                staticStyle: {
                  "background-color": "#6995CD",
                  "border-radius": "15px 15px 0 0"
                }
              },
              [
                _c(
                  "v-col",
                  {
                    staticClass: "ma-1 ml-5",
                    staticStyle: { color: "white", "font-size": "larger" }
                  },
                  [
                    _c(
                      "v-avatar",
                      {
                        staticStyle: { width: "24px" },
                        attrs: { color: "rgba(255,255,255,0.8)" }
                      },
                      [_c("img", { attrs: { src: _vm.gruppenIcon } })]
                    ),
                    _vm._v(
                      " Gruppe " +
                        _vm._s(_vm.oldGroup ? "bearbeiten" : "anlegen") +
                        " "
                    )
                  ],
                  1
                ),
                _c(
                  "v-col",
                  {
                    staticClass: "mr-6",
                    staticStyle: {
                      display: "flex",
                      "justify-content": "flex-end"
                    }
                  },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "mainFunctionButtons ml-3 my-auto",
                        attrs: {
                          color: "#FFFFFF40",
                          "x-small": "",
                          disabled: !_vm.oldGroup
                        },
                        on: {
                          click: function($event) {
                            _vm.deleteDialog = true
                          }
                        }
                      },
                      [_c("img", { attrs: { src: _vm.loeschIcon } })]
                    ),
                    _c(
                      "v-tooltip",
                      {
                        attrs: {
                          top: "",
                          "nudge-right": "100",
                          "open-on-click": true,
                          "open-on-hover": false,
                          "content-class": "infoTextToolTip"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        staticClass:
                                          "mainFunctionButtons ml-3 my-auto",
                                        attrs: {
                                          color: "#FFFFFF40",
                                          "x-small": ""
                                        }
                                      },
                                      "v-btn",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [_c("img", { attrs: { src: _vm.infoIcon } })]
                                )
                              ]
                            }
                          }
                        ])
                      },
                      [
                        _c("span", [
                          _vm._v(
                            'Hier können Sie Klassen, Gruppen und vieles mehr anlegen. Eine Klasse kann z.B. die 1a, eine Gruppe die Gruppe "Verwaltung", "Pfadfinder" oder ähnliches sein.'
                          )
                        ])
                      ]
                    ),
                    _c(
                      "v-btn",
                      {
                        staticClass: "mainControlButtons ml-3 my-auto pa-5",
                        attrs: { color: "#BDBDBD50", dark: "", elevation: "0" },
                        on: { click: _vm.stopEdit }
                      },
                      [_c("span", [_vm._v("Abbrechen")])]
                    ),
                    _vm.oldGroup
                      ? _c(
                          "v-btn",
                          {
                            staticClass: "mainControlButtons ml-3 my-auto pa-5",
                            attrs: {
                              color: "#69CD70",
                              dark: "",
                              elevation: "4"
                            },
                            on: { click: _vm.editThisGroup }
                          },
                          [_c("span", [_vm._v("Speichern")])]
                        )
                      : _c(
                          "v-btn",
                          {
                            staticClass: "mainControlButtons ml-3 my-auto pa-5",
                            attrs: {
                              color: "#69CD70",
                              dark: "",
                              elevation: "4"
                            },
                            on: { click: _vm.saveGroup }
                          },
                          [_c("span", [_vm._v("Speichern")])]
                        )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "mx-2 mb-2" },
              [
                _c(
                  "v-row",
                  [
                    _c("div", {
                      staticStyle: { "min-width": "25px!important" }
                    }),
                    _c(
                      "v-col",
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "font-weight": "bold",
                              "margin-bottom": "10px"
                            }
                          },
                          [_vm._v("Gruppendaten")]
                        ),
                        _c(
                          "v-row",
                          [
                            _c("v-col", { attrs: { cols: "2" } }, [
                              _vm._v(" Name: ")
                            ]),
                            _c("v-col", { attrs: { cols: "7" } }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.name,
                                    expression: "name"
                                  }
                                ],
                                staticClass: "input",
                                class: { errorInput: _vm.fields.name },
                                attrs: { disabled: _vm.oldGroup, type: "text" },
                                domProps: { value: _vm.name },
                                on: {
                                  change: _vm.validateInput,
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.name = $event.target.value
                                  }
                                }
                              })
                            ])
                          ],
                          1
                        ),
                        _vm.fields.name
                          ? _c(
                              "v-row",
                              { attrs: { dense: "" } },
                              [
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "pl-2; pt-0",
                                    staticStyle: { "margin-top": "-10px" }
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          color: "red",
                                          "font-size": "12px"
                                        }
                                      },
                                      [_vm._v("Pflichtfeld")]
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "v-row",
                          [
                            _c("v-col", { attrs: { cols: "2" } }, [
                              _vm._v(" Leiter: ")
                            ]),
                            _c("v-col", { attrs: { cols: "7" } }, [
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.leader,
                                      expression: "leader"
                                    }
                                  ],
                                  staticClass: "input",
                                  class: { errorInput: _vm.fields.leader },
                                  on: {
                                    change: [
                                      function($event) {
                                        var $$selectedVal = Array.prototype.filter
                                          .call($event.target.options, function(
                                            o
                                          ) {
                                            return o.selected
                                          })
                                          .map(function(o) {
                                            var val =
                                              "_value" in o ? o._value : o.value
                                            return val
                                          })
                                        _vm.leader = $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      },
                                      _vm.validateInput
                                    ]
                                  }
                                },
                                [
                                  _c(
                                    "option",
                                    {
                                      attrs: {
                                        disabled: "",
                                        selected: "",
                                        value: ""
                                      }
                                    },
                                    [_vm._v(" Auswählen")]
                                  ),
                                  _vm._l(_vm.teachers, function(item, index) {
                                    return _c(
                                      "option",
                                      {
                                        key: index,
                                        attrs: { selected: "" },
                                        domProps: { value: item }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              item.name + " " + item.lastName
                                            )
                                        )
                                      ]
                                    )
                                  })
                                ],
                                2
                              )
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", md: "6" } },
                      [
                        _c("div", {
                          staticStyle: {
                            "font-weight": "bold",
                            "margin-bottom": "33px"
                          }
                        }),
                        _c(
                          "v-dialog",
                          {
                            key: JSON.stringify(_vm.oldGroup),
                            attrs: {
                              fullscreen: "",
                              "hide-overlay": "",
                              transition: "dialog-bottom-transition"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      "v-row",
                                      [
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "3" } },
                                          [
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "3" } },
                                              [_vm._v(" Gruppenmitglieder: ")]
                                            ),
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "1" } },
                                              [
                                                _c(
                                                  "v-btn",
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        staticClass: "mx-0 ",
                                                        staticStyle: {
                                                          "margin-top": "-18px",
                                                          "max-height": "25px",
                                                          color: "black"
                                                        },
                                                        attrs: {
                                                          dark: "",
                                                          small: "",
                                                          color: "#EAEAEA"
                                                        },
                                                        on: {
                                                          click: function() {
                                                            _vm.oldMembers = JSON.parse(
                                                              JSON.stringify(
                                                                _vm.members
                                                              )
                                                            )
                                                          }
                                                        }
                                                      },
                                                      "v-btn",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [_vm._v(" Auswählen ")]
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "9" } },
                                          [
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "2" } },
                                              _vm._l(
                                                _vm.members.filter(function(
                                                  el
                                                ) {
                                                  return el.selected
                                                }),
                                                function(item, index) {
                                                  return _c(
                                                    "v-row",
                                                    {
                                                      key: index,
                                                      staticStyle: {}
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "px-3 py-1 mb-1",
                                                          staticStyle: {
                                                            "min-width":
                                                              "200px",
                                                            "background-color":
                                                              "#EAEAEA",
                                                            "border-radius":
                                                              "8px"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              item.name +
                                                                " " +
                                                                item.lastName
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                }
                                              ),
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ]),
                            model: {
                              value: _vm.dialog,
                              callback: function($$v) {
                                _vm.dialog = $$v
                              },
                              expression: "dialog"
                            }
                          },
                          [
                            _c(
                              "v-card",
                              [
                                _c(
                                  "v-toolbar",
                                  { attrs: { dark: "", color: "#6995CD" } },
                                  [
                                    _c(
                                      "v-toolbar-title",
                                      [
                                        _c("v-text-field", {
                                          staticClass: "pl-6",
                                          staticStyle: { "min-width": "300px" },
                                          attrs: {
                                            "prepend-icon": "mdi-magnify",
                                            label: "Nach Personen suchen",
                                            "single-line": "",
                                            dark: "",
                                            "hide-details": ""
                                          },
                                          model: {
                                            value: _vm.search2,
                                            callback: function($$v) {
                                              _vm.search2 = $$v
                                            },
                                            expression: "search2"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c("v-spacer"),
                                    _c(
                                      "v-toolbar-items",
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            staticStyle: {
                                              "background-color": "#3BAA69",
                                              color: "white",
                                              "max-height": "40px",
                                              "margin-top": "10px",
                                              "border-radius": "8px"
                                            },
                                            attrs: {
                                              dark: "",
                                              elevation: "3",
                                              text: ""
                                            },
                                            on: {
                                              click: function() {
                                                _vm.dialog = false
                                              }
                                            }
                                          },
                                          [_vm._v("Speichern")]
                                        ),
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass: "mx-4",
                                            staticStyle: {
                                              "background-color": "#e6221e80",
                                              "border-radius": "8px",
                                              "margin-top": "10px",
                                              width: "40px",
                                              "max-height": "40px"
                                            },
                                            attrs: {
                                              "x-small": "",
                                              elevation: "3"
                                            },
                                            on: {
                                              click: function() {
                                                _vm.members = JSON.parse(
                                                  JSON.stringify(_vm.oldMembers)
                                                )
                                                _vm.dialog = false
                                              }
                                            }
                                          },
                                          [
                                            _c("img", {
                                              staticStyle: {
                                                height: "40px",
                                                filter: "brightness(1000%)"
                                              },
                                              attrs: { src: _vm.abbrechenIcon }
                                            })
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c("v-data-table", {
                                  staticClass: "ma-3",
                                  attrs: {
                                    headers: _vm.headers2,
                                    items: _vm.members,
                                    search: _vm.search2,
                                    "disable-pagination": "",
                                    "hide-default-footer": "",
                                    "fixed-header": "",
                                    locale: "de-DE",
                                    "no-data-text": "Keine Daten verfügbar",
                                    "no-results-text":
                                      "Keine übereinstimmenden Aufzeichnungen gefunden"
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "item",
                                      fn: function(row) {
                                        return [
                                          _c("tr", [
                                            _c("td", [
                                              _vm._v(_vm._s(row.item.lastName))
                                            ]),
                                            _c("td", [
                                              _vm._v(
                                                " " + _vm._s(row.item.name)
                                              )
                                            ]),
                                            _c("td", [
                                              _vm._v(_vm._s(row.item.gender))
                                            ]),
                                            _c(
                                              "td",
                                              [
                                                _c("v-checkbox", {
                                                  staticClass: "mx-2",
                                                  attrs: {
                                                    rounded: "",
                                                    small: "",
                                                    light: ""
                                                  },
                                                  model: {
                                                    value: row.item.selected,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        row.item,
                                                        "selected",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "row.item.selected"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ])
                                        ]
                                      }
                                    }
                                  ])
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "400" },
          model: {
            value: _vm.deleteDialog,
            callback: function($$v) {
              _vm.deleteDialog = $$v
            },
            expression: "deleteDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "headline" }, [
                _vm._v("Gruppe löschen?")
              ]),
              _c("v-card-text", [
                _vm._v(
                  " Sind sie sicher, dass sie die Gruppe löschen möchten? "
                )
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "", text: "" },
                      on: {
                        click: function($event) {
                          _vm.deleteDialog = false
                        }
                      }
                    },
                    [_vm._v("Abbrechen")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "red red-1", text: "" },
                      on: {
                        click: function() {
                          this$1.deleteThisGroup()
                          this$1.deleteDialog = false
                        }
                      }
                    },
                    [_vm._v("Löschen")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-snackbar",
        {
          attrs: { bottom: "", color: "error", timeout: "3000" },
          model: {
            value: _vm.snackbar,
            callback: function($$v) {
              _vm.snackbar = $$v
            },
            expression: "snackbar"
          }
        },
        [_vm._v(" " + _vm._s(_vm.snackbarText) + " ")]
      ),
      _c(
        "v-snackbar",
        {
          attrs: { bottom: "", color: "success", timeout: "3000" },
          model: {
            value: _vm.snackbarSave,
            callback: function($$v) {
              _vm.snackbarSave = $$v
            },
            expression: "snackbarSave"
          }
        },
        [_vm._v(" Gespeichert! ")]
      ),
      _c(
        "v-snackbar",
        {
          attrs: { bottom: "", color: "success", timeout: "3000" },
          model: {
            value: _vm.snackbarQuickSave,
            callback: function($$v) {
              _vm.snackbarQuickSave = $$v
            },
            expression: "snackbarQuickSave"
          }
        },
        [_vm._v(" " + _vm._s(_vm.snackbarText) + " ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }