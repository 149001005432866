var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { staticClass: "actionbar hidden-print" },
    [
      _c(
        "v-btn",
        {
          staticClass: "ml-2",
          attrs: { color: "primary", small: "" },
          on: { click: _vm.clickLoginSimple }
        },
        [_vm._v("Alice")]
      ),
      _c(
        "v-btn",
        {
          staticClass: "ml-2",
          attrs: { color: "primary", small: "" },
          on: { click: _vm.clickLoginPupil }
        },
        [_vm._v("Monika")]
      ),
      _c(
        "v-btn",
        {
          staticClass: "ml-2",
          attrs: { color: "primary", small: "" },
          on: { click: _vm.clickLoginTeacher }
        },
        [_vm._v("Kim")]
      ),
      _c(
        "v-btn",
        {
          staticClass: "ml-2",
          attrs: { color: "primary", small: "" },
          on: { click: _vm.clickLoginMaintainer }
        },
        [_vm._v("Verwaltung")]
      ),
      _c(
        "v-btn",
        {
          staticClass: "ml-2",
          attrs: { color: "primary", small: "" },
          on: { click: _vm.clickLogoutUser }
        },
        [_vm._v("Logout")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }