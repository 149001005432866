<template>
    <v-container fluid class="pt-0">
        <Navbar style="z-index: 9"></Navbar>

        <div class="grid-container">
            <div class="pic">
                <v-img src="./../assets/Bilder/kids_on_laptop.svg"/>
            </div>

            <div class="demo" >
                <v-img  contain  src="./../assets/Bilder/Demo.png"/>
            </div>

            <div class="badge">
                <v-img contain src="./../assets/Bilder/badges.png"/>
            </div>
        </div>

    <div>
      <br />
      <br />
      <br />
    </div>

        <div style="display: flex; justify-content: center" id="stundenplan">
            <v-spacer/>
                <div style="display: flex; flex-direction: column; justify-content: center">
                    <p class="flowerIndie blueText text-center" style="font-size: 40px; line-height: 50px">
                        Digitalisieren Sie Ihre Schule mit der Schulplattform von eStundenplan.de
                    </p>
                    <p class="someText segoe greyText text-center">
                        eStundenplan bietet eine einfache, sichere auf <strong>Ihre Schulbedürfnisse personalisierbare <br>
                        Plattform</strong> für den digitalen Schulunterricht ab der ersten Klasse.
                    </p>
                    <p class="someText segoe greyText text-center">
                        Entwickelt wird die Plattform mit und für Schulen und eignet sich daher für <strong>viele Schultypen</strong>.
                    </p>
                    <div style="display: flex; justify-content: center">
                        <v-img src="./../assets/Bilder/Papagei.svg" max-width="180px"
                        style="justify-self: center"/>
                    </div>
                </div>
            <v-spacer/>
        </div>

    <div>
      <br />
      <br />
      <br />
    </div>

        <div style="display: flex; flex-direction: row; justify-content: center" id="funktionen">
            <v-spacer/>
            <v-carousel cycle progress hide-delimiters interval="7000" class="carousel" height="600" style="width: 1500px;">
                <!--first slide-->
                <v-carousel-item>
                    <div class="carouselContainer">
                        <v-img contain src="./../assets/Bilder/1-bigbluebutton.png" class="carouselImg"/>

                        <div class="carouselText">
                            <p class="flowerIndie carouselTextTitle blueHeader shadow">
                                Live-Video-Unterricht</p>
                            <p class="segoe carouselTextBody greyText">
                                Unterricht digital - Schüler und Lehrer <br>
                                im direkten Kontakt über BigBlueButton. <br>
                                Whiteboard als Unterstützung inklusive!
                            </p>
                        </div>
                    </div>
                </v-carousel-item>

                <!--second slide-->
                <v-carousel-item>
                    <div class="carouselContainer">
                        <v-img contain src="./../assets/Bilder/2-Stunde-bearbeiten.png" class="carouselImg"/>

                        <div class="carouselText">
                            <p class="flowerIndie carouselTextTitle blueHeader shadow">
                                Stundenplanansicht & Bearbeitung</p>
                            <p class="segoe carouselTextBody greyText">
                                Ihre Schulverwaltung kann kinderleicht <br>
                                Stundenpläne für alle Klassenstufen <br>
                                erstellen und jederzeit bearbeiten.
                            </p>
                        </div>
                    </div>
                </v-carousel-item>

                <!--third slide-->
                <v-carousel-item>
                    <div class="carouselContainer">
                        <v-img contain src="./../assets/Bilder/3-profile-anlegen.png" class="carouselImg"/>

                        <div class="carouselText">
                            <p class="flowerIndie carouselTextTitle blueHeader shadow">
                                Profile anlegen</p>
                            <p class="segoe carouselTextBody greyText">
                                Schüler und Lehrer haben ihr eigenes <br>
                                Profil - sicher und digital bearbeitbar.
                            </p>
                        </div>
                    </div>
                </v-carousel-item>

                <!--fourth slide-->
                <v-carousel-item>
                    <div class="carouselContainer">
                        <v-img contain src="./../assets/Bilder/4-Fächer-anlegen.png" class="carouselImg"/>

                        <div class="carouselText">
                            <p class="flowerIndie carouselTextTitle blueHeader shadow">
                                Fächer anlegen</p>
                            <p class="segoe carouselTextBody greyText">
                                Sie können Ihre Schulfächer ganz einfach <br>
                                anlegen. Fachname, Farbe und Lehrer <br>
                                hinzufügen, fertig!
                            </p>
                        </div>
                    </div>
                </v-carousel-item>

                <!--fifth slide-->
                <v-carousel-item>
                    <div class="carouselContainer">
                        <v-img contain src="./../assets/Bilder/5-vorlesen.png" class="carouselImg"/>

                        <div class="carouselText">
                            <p class="flowerIndie carouselTextTitle blueHeader shadow">
                                Vorlese-Funktion bei Leseschwäche</p>
                            <p class="segoe carouselTextBody greyText">
                                Für alle Profilbesitzer ist eine Vorlese-<br>
                                Funktion bereitgestellt.
                            </p>
                        </div>
                    </div>
                </v-carousel-item>

                <!--sixth slide-->
                <v-carousel-item>
                    <div class="carouselContainer">
                        <v-img contain src="./../assets/Bilder/6-Tutorial.png" class="carouselImg"/>

                        <div class="carouselText">
                            <p class="flowerIndie carouselTextTitle blueHeader shadow">
                                Tutorialeinstieg & Support</p>
                            <p class="segoe carouselTextBody greyText">
                                Zum Einstieg gibt es ein begleitendes <br>
                                Tutorial. Aber auch danach sind wir <br>
                                immer für Sie und Ihre Fragen offen.
                            </p>
                        </div>
                    </div>
                </v-carousel-item>
            </v-carousel>
            <v-spacer/>
        </div>

    <div>
      <br />
      <br />
      <br />
    </div>

        <div style="display: flex; flex-direction: column; justify-content: center">
            <p class="moreTitle flowerIndie blueText">& mehr!</p>
            <p class="moreText flowerIndie blueText">(Hausaufgaben-Editor, Eigener Chat, …)</p>
        </div>

    <div>
      <br />
      <br />
    </div>

        <div style="display: flex; flex-direction: row; justify-content: center">
            <div class="blueHeader blueBox white--text py-4 px-6 borders shadow" style="display: flex; flex-direction: column; justify-content: center">
                <p class="segoe blueBoxText" style="align-self: center"><strong>Wir sind noch in der Entwicklung</strong> - für <strong>Wünsche, Anregungen oder Kritik</strong> haben wir immer ein offenes Ohr.</p>
                <p></p>
                <p class="segoe ma-0 blueBoxText" style="align-self: center">Fehlen Ihnen nach Ihrer kostenlosen Demo noch Funktionen? <strong>Melden Sie sich bei uns!</strong></p>
                <p class="segoe ma-0 blueBoxText" style="align-self: center">Durch unsere erweiterbaren Einstellungen können wir nahezu perfekt auf Ihre Bedürfnisse eingehen.</p>
            </div>
        </div>

    <div>
      <br />
      <br />
      <br />
    </div>

        <div id="interesse" class="mb-12" style="display: flex; flex-direction: row; justify-content: center; height: 200px">
            <p class="flowerIndie blueText text-right ma-0" style="font-size: 200px">1.</p>
        </div>
        <div style="display: flex; justify-content: center">
            <div class="stepsLeftSpacer"></div>

            <div class="stepsContainer">
                <div class="stepsImgContainer" style="display: flex; flex-direction: column;">
                    <v-img contain class="mr-8" width="500px" src="./../assets/Bilder/1-beratung.png"/>
                </div>

                <div class="stepsTextContainer" style="display: flex; flex-direction: column; justify-content: center;">
                    <p class="flowerIndie blueText fourty" style="line-height: 50px">
                        Erstgespräch
                    </p>
                    <p class="stepsText segoe greyText">
                        Wir beraten Sie gerne erstmal in Ruhe, wenn gewünscht <br>
                        auch vor <strong>Ort oder via Telefon</strong>. Dann können Sie schauen, <br>
                        ob unsere Anwendung zu Ihnen und Ihrer Schule passt.
                    </p>
                    <p class="stepsText segoe greyText">
                        Alternativ können Sie auch direkt eine <strong>Demo anfordern:</strong>
                    </p>

                    <div style="display: flex; flex-direction: row; justify-content: left">
                        <v-img contain class="stepsSmallImg" src="./../assets/Bilder/Demo.png"/>
                    </div>
                </div>
            </div>

            <div class="stepsRightSpacer"></div>
        </div>

    <div>
      <br />
      <br />
    </div>

        <div class="mb-12" style="display: flex; flex-direction: row; justify-content: center; height: 200px">
            <p class="flowerIndie blueText text-right ma-0" style="font-size: 200px">2.</p>
        </div>
        <div style="display: flex; justify-content: center; margin-bottom: 20%">
            <div class="stepsLeftSpacer"></div>

            <div class="stepsContainer">
                <div class="stepsImgContainer" style="display: flex; flex-direction: column;">
                    <v-img contain class="mr-8" width="500px" src="./../assets/Bilder/2-allinpaket.png"/>
                </div>

                <div class="stepsTextContainer" style="display: flex; flex-direction: column; justify-content: center;">
                    <p class="flowerIndie blueText fourty" style="line-height: 50px">
                        Buchung
                    </p>
                    <p class="stepsText segoe greyText">
                        Gerne helfen wir Ihnen während Ihres Abos mithilfe <br>
                        unseres <strong>All-Inklusive-Paket</strong> (DE-Server & Hosting, <br>
                        Einrichtung, Schulungen, Support, Wartung & Pflege, etc.).
                    </p>

                    <div style="display: flex; flex-direction: row; justify-content: left">
                        <v-img contain class="stepsSmallImg" src="./../assets/Bilder/badges.png"/>
                    </div>
                </div>
            </div>

            <div class="stepsRightSpacer"></div>
        </div>

    <div>
      <br />
      <br />
      <br />
    </div>

        <Footer></Footer>
    </v-container>
</template>

<script>
import Navbar from "@/components/Navbar";
import Footer from "@/components/Footer";

export default {
  name: "Landingpage",
  components: { Navbar, Footer },
  data: () => ({}),
};
</script>

<style lang="scss" scoped>

.twentyfour {
  font-size: 24px;
}

.thirty {
  font-size: 30px;
  line-height: 36px;
}

.fourty {
  font-size: 40px;
  line-height: 50px;
}

.borders {
  border-radius: 10px;
}

.blueText {
  color: #6996cd;
}

.greyText {
  color: #5d5c5c;
}

.blueHeader {
  background-color: #6996cd;
}

.shadow {
    box-shadow: 15px 15px 15px -10px rgba(130,130,130,0.75);
}

.someText {
    font-size: 30px;
    line-height: 50px;
}

.carouselContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.carouselImg {
    margin-left: 50px;
    height: 600px;
    width: 50%;
}

.carouselText {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 50px;
}

.carouselTextTitle {
    font-size: 40px;
    border-radius: 8px;
    padding: 20px;
    margin: 10px 20px 20px;
    display: flex;
    justify-content: center;
}

.carouselTextBody {
    font-size: 30px;
    background-color: white;
    padding: 20px;
    margin-left: 20px;
}

.moreTitle {
    font-size: 80px;
    align-self: center;
}

.moreText {
    font-size: 50px;
    align-self: center;
}

.blueBoxText {
    font-size: 29px;
}

.stepsContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 70%;
}

.stepsTextContainer {
    width: 60%;
}

.stepsText {
    font-size: 30px;
    line-height: 50px;
}

.stepsSmallImg {
    max-height: 200px;
}

.stepsSecondImg {
    height: 700px;
}

.stepsLeftSpacer {
    width: 20%;
}

.stepsRightSpacer {
    width: 10%;
}

.grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    gap: 1px 1px;
    grid-template-areas:
        ". pic pic demo ."
        ". pic pic . ."
        ". pic pic badge .";
}

.pic { grid-area: pic; }

.demo {
    grid-area: demo;
    width: 300px;
}

.badge {
    grid-area: badge;
    width: 450px;
}

@media (max-width: 1500px) {
    .grid-container {
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr 1fr;
        grid-template-areas:
            "pic pic demo"
            "pic pic ."
            "pic pic badge";
    }

    .pic {
        min-width: 300px;
    }

    .demo {
        width: 300px;
    }

    .badge {
        width: 300px;
    }

    .moreTitle {
        font-size: 40px;
    }

    .moreText {
        font-size: 24px;
    }

    .blueBox {
        padding: 16px;
    }

    .blueBoxText {
        font-size: 16px;
    }
}

@media (max-width: 900px) {
    .someText {
        font-size: 16px;
        line-height: 20px;
    }

    .carouselContainer {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }

    .carouselImg {
        margin: 0;
        height: 300px;
        width: 100%;
    }

    .carouselText {
        height: 33%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 0;
    }

    .carouselTextTitle {
        font-size: 24px;
        padding: 16px;
    }

    .carouselTextBody {
        font-size: 16px;
        padding-top: 0;
        margin: 0;
    }
}


@media (max-width: 600px) {
    .stepsContainer {
        flex-direction: column;
        width: 100%;
    }

    .stepsTextContainer {
        width: 100%;
    }

    .stepsText {
        font-size: 16px;
        line-height: 20px;
    }

    .stepsImgContainer {
        width: 100%;
    }

    .stepsImg {
        max-height: 100px;
    }

    .stepsSecondImg {
        height: 500px;
    }

    .stepsSmallImg {
        max-height: 100px;
    }

    .stepsLeftSpacer {
        width: 16px;
    }

    .stepsRightSpacer {
        width: 16px;
    }

    .grid-container {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr 150px;
        grid-template-areas:
        "pic pic"
        "pic pic"
        "demo badge";
    }

    .demo {
        width: 150px;
    }

    .badge {
        width: 150px;
    }
}

</style>