var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: {
        "border-radius": "5px !important",
        "background-color": "#F8F8F8"
      }
    },
    [
      _c(
        "v-row",
        {
          staticClass: "ma-auto mb-1 pa-2",
          style: { backgroundColor: _vm.groupColor, height: "80px" },
          attrs: { align: "center" }
        },
        [
          _c(
            "v-col",
            {
              staticStyle: {
                display: "inline-flex",
                "justify-content": "flex-end"
              }
            },
            [
              _c("p", { staticClass: "ml-1", attrs: { id: "headerText" } }, [
                _vm._v(_vm._s(_vm.assignmentName))
              ]),
              _c(
                "v-btn",
                {
                  staticClass: "mr-4",
                  attrs: { id: "closeBtn", "x-small": "", elevation: "3" },
                  on: { click: _vm.close }
                },
                [
                  _c("img", {
                    staticStyle: {
                      height: "40px",
                      filter: "brightness(1000%)",
                      "justify-self": "flex-end"
                    },
                    attrs: { src: _vm.closeIcon }
                  })
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "vuescroll",
        {
          ref: "scrollbar",
          staticStyle: {
            height: "calc(100vh - 85px)",
            "background-color": "#EAEAEA"
          },
          attrs: { ops: _vm.ops }
        },
        [
          _c(
            "v-row",
            {
              staticClass: "ma-0",
              attrs: { align: "center", justify: "center" }
            },
            [
              _c(
                "v-btn",
                {
                  staticClass: "mr-3 zoomControl",
                  attrs: { elevation: "3", "x-small": "" },
                  on: {
                    click: function($event) {
                      return _vm.changeZoom("minus")
                    }
                  }
                },
                [_c("p", [_vm._v("-")])]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "mr-3 zoomControl",
                  staticStyle: { "margin-left": "63px" },
                  attrs: { elevation: "3", "x-small": "" },
                  on: {
                    click: function($event) {
                      return _vm.changeZoom("plus")
                    }
                  }
                },
                [_c("p", [_vm._v("+")])]
              ),
              _c("EditingCanvas", {
                ref: "editingcanvas",
                attrs: {
                  "canvas-height": _vm.canvasHeight,
                  "canvas-width": _vm.canvasWidth,
                  "loading-function": _vm.setLoading
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "hide-overlay": "", persistent: "", width: "150" },
          model: {
            value: _vm.loadingActive,
            callback: function($$v) {
              _vm.loadingActive = $$v
            },
            expression: "loadingActive"
          }
        },
        [
          _c(
            "v-card",
            { attrs: { color: _vm.groupColor } },
            [
              _c(
                "v-card-text",
                { staticClass: "pa-2" },
                [
                  _c("v-progress-circular", {
                    staticClass: "ma-auto",
                    staticStyle: { display: "block" },
                    attrs: { indeterminate: "" }
                  }),
                  _c("br"),
                  _c(
                    "p",
                    {
                      staticClass: "ma-auto",
                      staticStyle: { "text-align": "center" }
                    },
                    [_vm._v(_vm._s(_vm.loadingMessage))]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: "5000", color: "error" },
          model: {
            value: _vm.snackbars.fileNotAllowed,
            callback: function($$v) {
              _vm.$set(_vm.snackbars, "fileNotAllowed", $$v)
            },
            expression: "snackbars.fileNotAllowed"
          }
        },
        [_vm._v(" Zugriff auf diese Datei wurde verweigert. ")]
      ),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: "5000", color: "error" },
          model: {
            value: _vm.snackbars.fileNotAvailable,
            callback: function($$v) {
              _vm.$set(_vm.snackbars, "fileNotAvailable", $$v)
            },
            expression: "snackbars.fileNotAvailable"
          }
        },
        [_vm._v(" Die Datei konnte nicht geladen werden. ")]
      ),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: "5000", color: "error" },
          model: {
            value: _vm.snackbars.genericError,
            callback: function($$v) {
              _vm.$set(_vm.snackbars, "genericError", $$v)
            },
            expression: "snackbars.genericError"
          }
        },
        [_vm._v(" Ein Fehler ist aufgetreten. ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }