var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: {
        width: "90%",
        "padding-right": "3px",
        "padding-left": "-3px"
      }
    },
    [
      _c("progress-bar", {
        attrs: {
          title: "Hochladen",
          "show-progress": _vm.showTeacherUploadProgress,
          progress: _vm.teacherUploadProgress,
          "abort-progress": _vm.teacherXmlHttpRequest
            ? function() {
                _vm.teacherXmlHttpRequest.abort()
              }
            : function() {}
        }
      }),
      _vm.thisAppointment
        ? _c(
            "v-dialog",
            {
              staticClass: "dialog",
              attrs: {
                "max-width": "400",
                scrollable: "",
                "overlay-color": "white"
              },
              on: {
                "click:outside": function($event) {
                  return _vm.resetDialogContent()
                }
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function(ref) {
                      var on = ref.on
                      var attrs = ref.attrs
                      return [
                        _c(
                          "div",
                          _vm._b(
                            {
                              staticClass: "fach pa-2 white--text",
                              staticStyle: {
                                width: "100%",
                                display: "block",
                                "padding-left": "0 !important"
                              }
                            },
                            "div",
                            attrs,
                            false
                          ),
                          [
                            _c(
                              "v-row",
                              _vm._g(
                                {
                                  staticClass: "ml-2 font-weight-medium",
                                  attrs: { cols: "12", justify: "center" }
                                },
                                on
                              ),
                              [
                                _c(
                                  "v-col",
                                  _vm._g(
                                    {
                                      staticClass: "pa-0 ma-0",
                                      attrs: { cols: _vm.isRunning ? 9 : 12 }
                                    },
                                    on
                                  ),
                                  [
                                    _c(
                                      "v-badge",
                                      {
                                        staticStyle: {
                                          "z-index": "8",
                                          width: "100%",
                                          height: "48px !important"
                                        },
                                        attrs: {
                                          color: "red",
                                          content: _vm.thisAppointment.pupilUpload.filter(
                                            function(el) {
                                              return !el.seenByMe
                                            }
                                          ).length,
                                          value: _vm.thisAppointment.pupilUpload.filter(
                                            function(el) {
                                              return !el.seenByMe
                                            }
                                          ).length,
                                          overlap: "",
                                          left: "",
                                          "offset-x": "5"
                                        }
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "pa-3 ma-0 appointmentItem",
                                            class: {
                                              inactiveAppointment: !_vm.isRunning
                                            },
                                            style: _vm.isRunning
                                              ? "width: calc(100% - 3px) !important"
                                              : "",
                                            on: { click: _vm.getMissingMembers }
                                          },
                                          [
                                            _vm.getSrc()
                                              ? _c("img", {
                                                  staticClass: "iconSelect",
                                                  style: _vm.isRunning
                                                    ? _vm.getBackgroundColor()
                                                    : "background-color: transparent",
                                                  attrs: {
                                                    src: _vm.isRunning
                                                      ? _vm.getSrcWhite()
                                                      : _vm.getSrc()
                                                  }
                                                })
                                              : _c("div", {
                                                  staticClass: "iconSelect"
                                                }),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.thisAppointment
                                                    .schoolSubject.name
                                                ) +
                                                " "
                                            ),
                                            _c("img", {
                                              staticStyle: {
                                                position: "absolute",
                                                height: "25px",
                                                top: "0",
                                                right: "10px"
                                              },
                                              attrs: { src: _vm.getColorIcon() }
                                            })
                                          ]
                                        ),
                                        _vm.thisAppointment.pupilUpload.length >
                                        0
                                          ? _c("img", {
                                              staticStyle: {
                                                opacity: "60%",
                                                position: "absolute",
                                                height: "21px",
                                                top: "-9px"
                                              },
                                              style: _vm.isRunning
                                                ? "right: 30px"
                                                : "right: 27px",
                                              attrs: { src: _vm.paperclipIcon }
                                            })
                                          : _vm._e()
                                      ]
                                    )
                                  ],
                                  1
                                ),
                                _vm.isRunning
                                  ? _c(
                                      "v-col",
                                      {
                                        staticClass: "pa-0 mr-0",
                                        staticStyle: { "min-height": "100%" },
                                        attrs: { cols: 3 },
                                        on: { click: _vm.clickBeitreten }
                                      },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass: "pa-0",
                                            class: {
                                              isFachRunning: _vm.isRunning
                                            },
                                            staticStyle: {
                                              height: "48px",
                                              width: "100%",
                                              "background-color": "white",
                                              "border-radius": "8px"
                                            },
                                            attrs: { small: "" }
                                          },
                                          [
                                            _c("img", {
                                              staticClass: "pa-0",
                                              class: {
                                                isFachRunningIcon: _vm.isRunning
                                              },
                                              staticStyle: {
                                                height: "35px !important",
                                                width: "35px !important"
                                              },
                                              attrs: {
                                                src: _vm.beitretenKameraIcon
                                              }
                                            })
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ],
                null,
                false,
                1511651502
              ),
              model: {
                value: _vm.menu,
                callback: function($$v) {
                  _vm.menu = $$v
                },
                expression: "menu"
              }
            },
            [
              _c(
                "v-card",
                {
                  staticStyle: { color: "#707070" },
                  attrs: { tile: "", id: "fachPopup" }
                },
                [
                  _c("v-card-title", { staticClass: "pa-0 ma-0" }, [
                    _c(
                      "div",
                      {
                        staticClass: "fach d-flex",
                        staticStyle: {
                          "box-shadow": "0 5px 5px 0 rgba(0, 0, 0, 0.3)",
                          "font-size": "large",
                          "align-items": "center",
                          padding: "0 20px",
                          height: "59px",
                          width: "100%"
                        }
                      },
                      [
                        _c(
                          "v-col",
                          {
                            staticClass: "d-flex align-center pl-0",
                            attrs: { cols: "6" }
                          },
                          [
                            _c("img", {
                              staticStyle: {
                                position: "absolute",
                                right: "20%",
                                top: "0",
                                height: "25px"
                              },
                              attrs: { src: _vm.getColorIcon() }
                            }),
                            _vm.getSrc()
                              ? _c("img", {
                                  staticClass: "iconSelect mr-2",
                                  staticStyle: { "margin-bottom": "0" },
                                  style: _vm.getBackgroundColor(),
                                  attrs: { src: _vm.getSrcWhite() }
                                })
                              : _vm._e(),
                            _c("div", { staticStyle: { flex: "auto" } }, [
                              _vm._v(
                                _vm._s(_vm.thisAppointment.schoolSubject.name)
                              )
                            ])
                          ]
                        ),
                        _c(
                          "v-col",
                          {
                            staticClass:
                              "pa-0 pr-6 d-flex flex-column justify-start align-end",
                            style:
                              "font-size: " +
                              (_vm.windowWidth < 400 ? "small" : "medium"),
                            attrs: { cols: "4" }
                          },
                          [_c("div", [_vm._v(_vm._s(_vm.groupName))])]
                        ),
                        _c(
                          "v-col",
                          { attrs: { cols: "2", "align-self": "end" } },
                          [
                            _c(
                              "v-btn",
                              {
                                staticClass: "ml-2 pa-0 btnStyle",
                                staticStyle: { width: "30px", height: "30px" },
                                attrs: { elevation: "2", "x-small": "" },
                                on: {
                                  click: function($event) {
                                    _vm.menu = false
                                    _vm.resetDialogContent()
                                  }
                                }
                              },
                              [
                                _c("img", {
                                  staticStyle: { height: "20px" },
                                  attrs: { src: _vm.schliesenIcon }
                                })
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]),
                  _c(
                    "v-card-text",
                    {
                      staticClass: "pa-0 ma-0",
                      staticStyle: { overflow: "hidden" }
                    },
                    [
                      _c(
                        "vue-custom-scrollbar",
                        {
                          staticClass: "scroll-area-fachPopup mb-2",
                          attrs: { settings: _vm.settings }
                        },
                        [
                          _c("div", { staticClass: "mx-5" }, [
                            _c(
                              "div",
                              { staticClass: "mt-2" },
                              [
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        staticClass:
                                          "font-weight-bold py-2 d-flex align-center"
                                      },
                                      [
                                        _c("img", {
                                          staticStyle: { height: "20px" },
                                          attrs: { src: _vm.infoIcon }
                                        }),
                                        _c("p", { staticClass: "pl-2 ma-0" }, [
                                          _vm._v("Info")
                                        ])
                                      ]
                                    ),
                                    _c(
                                      "v-col",
                                      { staticClass: "text-right py-1" },
                                      [
                                        _vm.update
                                          ? _c(
                                              "v-btn",
                                              {
                                                staticClass: "btnStyle",
                                                attrs: {
                                                  "x-small": "",
                                                  elevation: "0"
                                                },
                                                on: { click: _vm.saveNote }
                                              },
                                              [
                                                _c("img", {
                                                  staticStyle: {
                                                    height: "20px"
                                                  },
                                                  attrs: {
                                                    src: _vm.speichernIcon
                                                  }
                                                })
                                              ]
                                            )
                                          : _c(
                                              "v-btn",
                                              {
                                                staticClass: "btnStyle",
                                                attrs: {
                                                  "x-small": "",
                                                  elevation: "0"
                                                },
                                                on: { click: _vm.updateNote }
                                              },
                                              [
                                                _c("img", {
                                                  staticStyle: {
                                                    height: "20px"
                                                  },
                                                  attrs: {
                                                    src: _vm.bearbeitenIcon
                                                  }
                                                })
                                              ]
                                            )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _vm.update
                                  ? _c("v-textarea", {
                                      attrs: { autofocus: "" },
                                      model: {
                                        value: _vm.thisAppointment.note,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.thisAppointment,
                                            "note",
                                            $$v
                                          )
                                        },
                                        expression: "thisAppointment.note"
                                      }
                                    })
                                  : _vm.thisAppointment.note
                                  ? _c(
                                      "vue-custom-scrollbar",
                                      {
                                        staticClass: "mb-4 mt-1 scroll-area",
                                        staticStyle: {
                                          "font-size": "medium",
                                          "font-weight": "normal",
                                          "line-height": "25px"
                                        },
                                        attrs: { settings: _vm.settings }
                                      },
                                      [
                                        _c("span", {
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.urlify(
                                                _vm.thisAppointment.note
                                              )
                                            )
                                          }
                                        })
                                      ]
                                    )
                                  : _c(
                                      "div",
                                      {
                                        staticClass: "pb-1",
                                        staticStyle: { color: "#BDBDBD" }
                                      },
                                      [
                                        _vm._v(
                                          " Tragen Sie hier die Info zum Fach ein "
                                        )
                                      ]
                                    )
                              ],
                              1
                            )
                          ]),
                          _c("hr", { staticClass: "eHr" }),
                          _c("div", { staticClass: "mx-0" }, [
                            _c(
                              "div",
                              { staticClass: "mt-2" },
                              [
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        staticClass:
                                          "font-weight-bold py-2 mx-5 pt-0 d-flex align-center"
                                      },
                                      [
                                        _c("img", {
                                          staticStyle: { height: "20px" },
                                          attrs: { src: _vm.materialIcon }
                                        }),
                                        _c("p", { staticClass: "pl-2 ma-0" }, [
                                          _vm._v("Material")
                                        ])
                                      ]
                                    )
                                  ],
                                  1
                                ),
                                !_vm.teacherUpload()
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "mx-5 pb-1",
                                        staticStyle: {
                                          color: "#BDBDBD",
                                          "font-size": "medium"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " Laden Sie hier Ihre Materialien hoch. "
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _c(
                                  "div",
                                  { staticClass: "matShort" },
                                  _vm._l(
                                    _vm.thisAppointment.teacherUploads
                                      .filter(function(item) {
                                        return !item.editParent
                                      })
                                      .slice(0, 2),
                                    function(el, index) {
                                      return _c(
                                        "div",
                                        {
                                          key: el + index,
                                          staticClass:
                                            "py-2 px-5 d-flex justify-space-between align-center",
                                          class: {
                                            brighterFileRow: index % 2 === 1
                                          },
                                          staticStyle: {
                                            "font-size": "medium",
                                            "font-weight": "normal",
                                            "line-height": "25px",
                                            width: "100%",
                                            "background-color":
                                              "rgba(112,112,112, 0.15)"
                                          }
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "fileName",
                                              staticStyle: {
                                                "min-width": "57%",
                                                width: "57%"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(el.title) + " "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "pa-0 ml-2" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    display: "inline-block"
                                                  }
                                                },
                                                [
                                                  [
                                                    "doc",
                                                    "docx",
                                                    "ppt",
                                                    "pptx",
                                                    "xls",
                                                    "xlsx"
                                                  ].includes(
                                                    el.title
                                                      .split(".")
                                                      .pop()
                                                      .toLowerCase()
                                                  )
                                                    ? _c(
                                                        "v-btn",
                                                        {
                                                          staticClass:
                                                            "btnStyle",
                                                          staticStyle: {
                                                            "background-color":
                                                              "#8CBD46"
                                                          },
                                                          attrs: {
                                                            dark: "",
                                                            "x-small": "",
                                                            elevation: "0"
                                                          },
                                                          on: {
                                                            click: function() {
                                                              _vm.snackbarFileNotSupported = true
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c("img", {
                                                            staticStyle: {
                                                              height: "20px",
                                                              filter:
                                                                "brightness(1000%)"
                                                            },
                                                            attrs: {
                                                              src: _vm.infoIcon
                                                            }
                                                          })
                                                        ]
                                                      )
                                                    : _c(
                                                        "v-btn",
                                                        {
                                                          staticClass:
                                                            "btnStyle",
                                                          attrs: {
                                                            "x-small": "",
                                                            elevation: "0"
                                                          },
                                                          on: {
                                                            click: function() {
                                                              return _vm.openAnsicht(
                                                                el.title,
                                                                el.file,
                                                                el._id,
                                                                "pupil"
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              staticStyle: {
                                                                width: "20px",
                                                                color: "#939393"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "fas fa-eye"
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    display: "inline-block"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      staticClass:
                                                        "btnStyle ml-2",
                                                      attrs: {
                                                        "x-small": "",
                                                        elevation: "0"
                                                      },
                                                      on: {
                                                        click: function() {
                                                          return _vm.clickDeleteTeacherUpload(
                                                            el
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c("img", {
                                                        staticStyle: {
                                                          height: "20px"
                                                        },
                                                        attrs: {
                                                          src:
                                                            _vm.papierkorbIcon
                                                        }
                                                      })
                                                    ]
                                                  )
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    display: "inline-block"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      staticClass:
                                                        "btnStyle ml-2",
                                                      attrs: {
                                                        "x-small": "",
                                                        elevation: "0"
                                                      },
                                                      on: {
                                                        click: function() {
                                                          return _vm.clickDownloadTeacherUpload(
                                                            el
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c("img", {
                                                        staticStyle: {
                                                          height: "20px"
                                                        },
                                                        attrs: {
                                                          src:
                                                            _vm.runterladenIcon
                                                        }
                                                      })
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                                _c(
                                  "div",
                                  { staticClass: "matLong" },
                                  _vm._l(
                                    _vm.thisAppointment.teacherUploads.filter(
                                      function(item) {
                                        return !item.editParent
                                      }
                                    ),
                                    function(el, index) {
                                      return _c(
                                        "div",
                                        {
                                          key: el + index,
                                          staticClass:
                                            "py-2 px-5 d-flex justify-space-between align-center",
                                          class: {
                                            brighterFileRow: index % 2 === 1,
                                            disabledFileRow: false
                                          },
                                          staticStyle: {
                                            "font-size": "medium",
                                            "font-weight": "normal",
                                            "line-height": "25px",
                                            width: "100%",
                                            "background-color":
                                              "rgba(112,112,112, 0.15)"
                                          }
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "fileName",
                                              staticStyle: {
                                                "min-width": "57%",
                                                width: "57%"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(el.title) + " "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "pa-0 ml-2" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    display: "inline-block"
                                                  }
                                                },
                                                [
                                                  [
                                                    "doc",
                                                    "docx",
                                                    "ppt",
                                                    "pptx",
                                                    "xls",
                                                    "xlsx"
                                                  ].includes(
                                                    el.title
                                                      .split(".")
                                                      .pop()
                                                      .toLowerCase()
                                                  )
                                                    ? _c(
                                                        "v-btn",
                                                        {
                                                          staticClass:
                                                            "btnStyle",
                                                          staticStyle: {
                                                            "background-color":
                                                              "#8CBD46"
                                                          },
                                                          attrs: {
                                                            dark: "",
                                                            "x-small": "",
                                                            elevation: "0"
                                                          },
                                                          on: {
                                                            click: function() {
                                                              _vm.snackbarFileNotSupported = true
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c("img", {
                                                            staticStyle: {
                                                              height: "20px",
                                                              filter:
                                                                "brightness(1000%)"
                                                            },
                                                            attrs: {
                                                              src: _vm.infoIcon
                                                            }
                                                          })
                                                        ]
                                                      )
                                                    : _c(
                                                        "v-btn",
                                                        {
                                                          staticClass:
                                                            "btnStyle",
                                                          attrs: {
                                                            "x-small": "",
                                                            elevation: "0"
                                                          },
                                                          on: {
                                                            click: function() {
                                                              return _vm.openAnsicht(
                                                                el.title,
                                                                el.file,
                                                                el._id,
                                                                "pupil"
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              staticStyle: {
                                                                width: "20px",
                                                                color: "#939393"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "fas fa-eye"
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    display: "inline-block"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      staticClass:
                                                        "btnStyle ml-2",
                                                      attrs: {
                                                        "x-small": "",
                                                        elevation: "0"
                                                      },
                                                      on: {
                                                        click: function() {
                                                          return _vm.clickDeleteTeacherUpload(
                                                            el
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c("img", {
                                                        staticStyle: {
                                                          height: "20px"
                                                        },
                                                        attrs: {
                                                          src:
                                                            _vm.papierkorbIcon
                                                        }
                                                      })
                                                    ]
                                                  )
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    display: "inline-block"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      staticClass:
                                                        "btnStyle ml-2",
                                                      attrs: {
                                                        "x-small": "",
                                                        elevation: "0"
                                                      },
                                                      on: {
                                                        click: function() {
                                                          return _vm.clickDownloadTeacherUpload(
                                                            el
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c("img", {
                                                        staticStyle: {
                                                          height: "20px"
                                                        },
                                                        attrs: {
                                                          src:
                                                            _vm.runterladenIcon
                                                        }
                                                      })
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                                _vm.thisAppointment.teacherUploads.filter(
                                  function(item) {
                                    return !item.editParent
                                  }
                                ).length > 2
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex justify-center mt-2"
                                      },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass: "matShort",
                                            attrs: {
                                              small: "",
                                              elevation: "0"
                                            },
                                            on: {
                                              click: function($event) {
                                                _vm.hideEl("matShort")
                                                _vm.showEl("matLong")
                                              }
                                            }
                                          },
                                          [
                                            _c("img", {
                                              staticStyle: { height: "20px" },
                                              attrs: { src: _vm.arrowDown }
                                            })
                                          ]
                                        ),
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass: "matLong",
                                            attrs: {
                                              small: "",
                                              elevation: "0"
                                            },
                                            on: {
                                              click: function($event) {
                                                _vm.hideEl("matLong")
                                                _vm.showEl("matShort")
                                              }
                                            }
                                          },
                                          [
                                            _c("img", {
                                              staticStyle: {
                                                height: "20px",
                                                transform: "rotate(180deg)"
                                              },
                                              attrs: { src: _vm.arrowDown }
                                            })
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          ]),
                          _c("div", { staticClass: "my-4" }, [
                            _c("input", {
                              ref: "teacherUploadInput",
                              attrs: {
                                id: "teacherUploadInput",
                                type: "file",
                                hidden: ""
                              },
                              on: {
                                change: function() {
                                  return _vm.teacherUploadInputChange(
                                    _vm.thisAppointment._id
                                  )
                                }
                              }
                            }),
                            _c(
                              "div",
                              {
                                staticClass: "d-flex",
                                staticStyle: { "justify-content": "center" }
                              },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "text-capitalize",
                                    staticStyle: {
                                      "padding-left": "50px",
                                      "padding-right": "50px",
                                      color: "#707070"
                                    },
                                    attrs: { elevation: "0" },
                                    on: { click: _vm.clickTeacherUploadButton }
                                  },
                                  [
                                    _c("img", {
                                      staticClass: "mr-2",
                                      staticStyle: { height: "20px" },
                                      attrs: { src: _vm.hochladenIcon }
                                    }),
                                    _vm._v(" Hochladen ")
                                  ]
                                )
                              ],
                              1
                            )
                          ]),
                          _c("hr", { staticClass: "eHr" }),
                          _c("div", { staticClass: "mx-0" }, [
                            _c(
                              "div",
                              { staticClass: "mt-2" },
                              [
                                _c(
                                  "v-row",
                                  { staticClass: "mb-2" },
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        staticClass:
                                          "font-weight-bold pb-0 mx-5 pt-0 d-flex align-center"
                                      },
                                      [
                                        _c("img", {
                                          staticStyle: { height: "20px" },
                                          attrs: { src: _vm.abgabenIcon }
                                        }),
                                        _c("p", { staticClass: "pl-2 ma-0" }, [
                                          _vm._v("Abgaben")
                                        ])
                                      ]
                                    )
                                  ],
                                  1
                                ),
                                _c("div", [
                                  _vm.thisAppointment.pupilUpload.length > 0 ||
                                  _vm.isCorrectionAvailable(
                                    _vm.thisAppointment.pupilUpload
                                  )
                                    ? _c(
                                        "div",
                                        [
                                          _c(
                                            "v-row",
                                            { staticClass: "mb-2" },
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass:
                                                    "font-weight-bold pb-0 mx-5 pt-0 d-flex align-center"
                                                },
                                                [
                                                  _c("img", {
                                                    staticStyle: {
                                                      height: "20px"
                                                    },
                                                    attrs: {
                                                      src: _vm.offenIcon
                                                    }
                                                  }),
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass: "pl-2 ma-0"
                                                    },
                                                    [_vm._v("Zu korrigieren")]
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          ),
                                          !_vm.pupilUploadsToCorrect.length
                                            ? _c(
                                                "v-row",
                                                {
                                                  staticClass:
                                                    "ma-0 fileUploadPlaceholderText",
                                                  attrs: {
                                                    dense: "",
                                                    "align-content": "center"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "p",
                                                    { staticClass: "mb-0" },
                                                    [
                                                      _vm._v(
                                                        "Aktuell sind keine Aufgaben bereit zum korrigieren."
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            : _vm._e(),
                                          _c(
                                            "div",
                                            { staticClass: "toCorrectShort" },
                                            _vm._l(
                                              _vm.pupilUploadsToCorrect.slice(
                                                0,
                                                2
                                              ),
                                              function(el, index) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: el + index,
                                                    staticClass:
                                                      "py-2 px-5 d-flex justify-space-between align-center",
                                                    class: {
                                                      brighterToCorrectRow:
                                                        index % 2 === 1
                                                    },
                                                    staticStyle: {
                                                      "font-size": "medium",
                                                      "font-weight": "normal",
                                                      "line-height": "25px",
                                                      width: "100%",
                                                      "background-color":
                                                        "rgba(128, 128, 128, 0.25)"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "fileName",
                                                        staticStyle: {
                                                          "min-width": "70%",
                                                          width: "70%"
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.clickDownloadPupilUpload(
                                                              el
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(el.title) +
                                                            " "
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      { staticClass: "d-flex" },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass: "pa-0"
                                                          },
                                                          [
                                                            _c(
                                                              "v-badge",
                                                              {
                                                                staticStyle: {
                                                                  "z-index": "9"
                                                                },
                                                                attrs: {
                                                                  color: "red",
                                                                  content: "1",
                                                                  value: !el.seenByMe,
                                                                  overlap: "",
                                                                  "offset-x":
                                                                    "45"
                                                                }
                                                              },
                                                              [
                                                                [
                                                                  "doc",
                                                                  "docx",
                                                                  "ppt",
                                                                  "pptx",
                                                                  "xls",
                                                                  "xlsx"
                                                                ].includes(
                                                                  el.title
                                                                    .split(".")
                                                                    .pop()
                                                                    .toLowerCase()
                                                                )
                                                                  ? _c(
                                                                      "v-btn",
                                                                      {
                                                                        staticClass:
                                                                          "btnStyle",
                                                                        staticStyle: {
                                                                          "background-color":
                                                                            "#8CBD46"
                                                                        },
                                                                        attrs: {
                                                                          dark:
                                                                            "",
                                                                          "x-small":
                                                                            "",
                                                                          elevation:
                                                                            "0"
                                                                        },
                                                                        on: {
                                                                          click: function(
                                                                            $event
                                                                          ) {
                                                                            return _vm.fileNotSupported(
                                                                              el
                                                                            )
                                                                          }
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "img",
                                                                          {
                                                                            staticStyle: {
                                                                              height:
                                                                                "20px",
                                                                              filter:
                                                                                "brightness(1000%)"
                                                                            },
                                                                            attrs: {
                                                                              src:
                                                                                _vm.infoIcon
                                                                            }
                                                                          }
                                                                        )
                                                                      ]
                                                                    )
                                                                  : _c(
                                                                      "v-btn",
                                                                      {
                                                                        staticClass:
                                                                          "ma-0 pa-0 btnStyle",
                                                                        staticStyle: {
                                                                          "background-color":
                                                                            "#8CBD46"
                                                                        },
                                                                        attrs: {
                                                                          "x-small":
                                                                            "",
                                                                          elevation:
                                                                            "0"
                                                                        },
                                                                        on: {
                                                                          click: function() {
                                                                            return _vm.openEdit(
                                                                              el.title,
                                                                              el.file,
                                                                              el._id
                                                                            )
                                                                          }
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "img",
                                                                          {
                                                                            staticStyle: {
                                                                              height:
                                                                                "20px"
                                                                            },
                                                                            attrs: {
                                                                              src:
                                                                                _vm.stiftIcon
                                                                            }
                                                                          }
                                                                        )
                                                                      ]
                                                                    )
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "pa-0 ml-2"
                                                          },
                                                          [
                                                            _vm.isCorrectionAvailable(
                                                              el
                                                            )
                                                              ? _c(
                                                                  "v-btn",
                                                                  {
                                                                    staticClass:
                                                                      "pa-0 ma-0 btnStyle",
                                                                    staticStyle: {
                                                                      "background-color":
                                                                        "white"
                                                                    },
                                                                    attrs: {
                                                                      "x-small":
                                                                        "",
                                                                      elevation:
                                                                        "0"
                                                                    },
                                                                    on: {
                                                                      click: function() {
                                                                        return _vm.clickDownloadPupilCorrection(
                                                                          el
                                                                        )
                                                                      }
                                                                    }
                                                                  },
                                                                  [
                                                                    _c("img", {
                                                                      staticStyle: {
                                                                        height:
                                                                          "20px"
                                                                      },
                                                                      attrs: {
                                                                        src:
                                                                          _vm.bearbeitetIcon
                                                                      }
                                                                    })
                                                                  ]
                                                                )
                                                              : _c(
                                                                  "v-btn",
                                                                  {
                                                                    staticClass:
                                                                      "ma-0 pa-0 btnStyle",
                                                                    staticStyle: {
                                                                      "background-color":
                                                                        "white"
                                                                    },
                                                                    attrs: {
                                                                      elevation:
                                                                        "0",
                                                                      "x-small":
                                                                        ""
                                                                    },
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.clickDownloadPupilUpload(
                                                                          el
                                                                        )
                                                                      }
                                                                    }
                                                                  },
                                                                  [
                                                                    _c("img", {
                                                                      staticStyle: {
                                                                        height:
                                                                          "20px"
                                                                      },
                                                                      attrs: {
                                                                        src:
                                                                          _vm.offenIcon
                                                                      }
                                                                    })
                                                                  ]
                                                                )
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "toCorrectLong" },
                                            _vm._l(
                                              _vm.pupilUploadsToCorrect,
                                              function(el, index) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: el + index,
                                                    staticClass:
                                                      "py-2 px-5 d-flex justify-space-between align-center",
                                                    class: {
                                                      brighterToCorrectRow:
                                                        index % 2 === 1
                                                    },
                                                    staticStyle: {
                                                      "font-size": "medium",
                                                      "font-weight": "normal",
                                                      "line-height": "25px",
                                                      width: "100%",
                                                      "background-color":
                                                        "rgba(128, 128, 128, 0.25)"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "fileName",
                                                        staticStyle: {
                                                          "min-width": "70%",
                                                          width: "70%"
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.clickDownloadPupilUpload(
                                                              el
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(el.title) +
                                                            " "
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      { staticClass: "d-flex" },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass: "pa-0"
                                                          },
                                                          [
                                                            _c(
                                                              "v-badge",
                                                              {
                                                                staticStyle: {
                                                                  "z-index": "9"
                                                                },
                                                                attrs: {
                                                                  color: "red",
                                                                  content: "1",
                                                                  value: !el.seenByMe,
                                                                  overlap: "",
                                                                  "offset-x":
                                                                    "45"
                                                                }
                                                              },
                                                              [
                                                                [
                                                                  "doc",
                                                                  "docx",
                                                                  "ppt",
                                                                  "pptx",
                                                                  "xls",
                                                                  "xlsx"
                                                                ].includes(
                                                                  el.title
                                                                    .split(".")
                                                                    .pop()
                                                                    .toLowerCase()
                                                                )
                                                                  ? _c(
                                                                      "v-btn",
                                                                      {
                                                                        staticClass:
                                                                          "btnStyle",
                                                                        staticStyle: {
                                                                          "background-color":
                                                                            "#8CBD46"
                                                                        },
                                                                        attrs: {
                                                                          dark:
                                                                            "",
                                                                          "x-small":
                                                                            "",
                                                                          elevation:
                                                                            "0"
                                                                        },
                                                                        on: {
                                                                          click: function(
                                                                            $event
                                                                          ) {
                                                                            return _vm.fileNotSupported(
                                                                              el
                                                                            )
                                                                          }
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "img",
                                                                          {
                                                                            staticStyle: {
                                                                              height:
                                                                                "20px",
                                                                              filter:
                                                                                "brightness(1000%)"
                                                                            },
                                                                            attrs: {
                                                                              src:
                                                                                _vm.infoIcon
                                                                            }
                                                                          }
                                                                        )
                                                                      ]
                                                                    )
                                                                  : _c(
                                                                      "v-btn",
                                                                      {
                                                                        staticClass:
                                                                          "ma-0 pa-0 btnStyle",
                                                                        staticStyle: {
                                                                          "background-color":
                                                                            "#8CBD46"
                                                                        },
                                                                        attrs: {
                                                                          "x-small":
                                                                            "",
                                                                          elevation:
                                                                            "0"
                                                                        },
                                                                        on: {
                                                                          click: function() {
                                                                            return _vm.openEdit(
                                                                              el.title,
                                                                              el.file,
                                                                              el._id
                                                                            )
                                                                          }
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "img",
                                                                          {
                                                                            staticStyle: {
                                                                              height:
                                                                                "20px"
                                                                            },
                                                                            attrs: {
                                                                              src:
                                                                                _vm.stiftIcon
                                                                            }
                                                                          }
                                                                        )
                                                                      ]
                                                                    )
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "pa-0 ml-2"
                                                          },
                                                          [
                                                            _vm.isCorrectionAvailable(
                                                              el
                                                            )
                                                              ? _c(
                                                                  "v-btn",
                                                                  {
                                                                    staticClass:
                                                                      "pa-0 ma-0 btnStyle",
                                                                    staticStyle: {
                                                                      "background-color":
                                                                        "white"
                                                                    },
                                                                    attrs: {
                                                                      "x-small":
                                                                        "",
                                                                      elevation:
                                                                        "0"
                                                                    },
                                                                    on: {
                                                                      click: function() {
                                                                        return _vm.clickDownloadPupilCorrection(
                                                                          el
                                                                        )
                                                                      }
                                                                    }
                                                                  },
                                                                  [
                                                                    _c("img", {
                                                                      staticStyle: {
                                                                        height:
                                                                          "20px"
                                                                      },
                                                                      attrs: {
                                                                        src:
                                                                          _vm.bearbeitetIcon
                                                                      }
                                                                    })
                                                                  ]
                                                                )
                                                              : _c(
                                                                  "v-btn",
                                                                  {
                                                                    staticClass:
                                                                      "ma-0 pa-0 btnStyle",
                                                                    staticStyle: {
                                                                      "background-color":
                                                                        "white"
                                                                    },
                                                                    attrs: {
                                                                      elevation:
                                                                        "0",
                                                                      "x-small":
                                                                        ""
                                                                    },
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.clickDownloadPupilUpload(
                                                                          el
                                                                        )
                                                                      }
                                                                    }
                                                                  },
                                                                  [
                                                                    _c("img", {
                                                                      staticStyle: {
                                                                        height:
                                                                          "20px"
                                                                      },
                                                                      attrs: {
                                                                        src:
                                                                          _vm.offenIcon
                                                                      }
                                                                    })
                                                                  ]
                                                                )
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          ),
                                          _vm.thisAppointment.pupilUpload
                                            .length > 2
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex justify-center mt-2"
                                                },
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      staticClass:
                                                        "toCorrectShort",
                                                      attrs: {
                                                        small: "",
                                                        elevation: "0"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          _vm.hideEl(
                                                            "toCorrectShort"
                                                          )
                                                          _vm.showEl(
                                                            "toCorrectLong"
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c("img", {
                                                        staticStyle: {
                                                          height: "20px"
                                                        },
                                                        attrs: {
                                                          src: _vm.arrowDown
                                                        }
                                                      })
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      staticClass:
                                                        "toCorrectLong",
                                                      attrs: {
                                                        small: "",
                                                        elevation: "0"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          _vm.hideEl(
                                                            "toCorrectLong"
                                                          )
                                                          _vm.showEl(
                                                            "toCorrectShort"
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c("img", {
                                                        staticStyle: {
                                                          height: "20px",
                                                          transform:
                                                            "rotate(180deg)"
                                                        },
                                                        attrs: {
                                                          src: _vm.arrowDown
                                                        }
                                                      })
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _c(
                                            "v-row",
                                            { staticClass: "mb-2" },
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass:
                                                    "font-weight-bold pb-0 mx-5 d-flex align-center"
                                                },
                                                [
                                                  _c("img", {
                                                    staticStyle: {
                                                      height: "20px"
                                                    },
                                                    attrs: {
                                                      src: _vm.keinDocument
                                                    }
                                                  }),
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass: "pl-2 ma-0"
                                                    },
                                                    [_vm._v("Nicht abgegeben")]
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "toDoShort" },
                                            _vm._l(
                                              _vm.missingAppointments.slice(
                                                0,
                                                2
                                              ),
                                              function(el, index) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: el + index,
                                                    staticClass:
                                                      "py-2 px-5 d-flex justify-space-between align-center",
                                                    class: {
                                                      brighterToDoRow:
                                                        index % 2 === 1
                                                    },
                                                    staticStyle: {
                                                      "font-size": "medium",
                                                      "font-weight": "normal",
                                                      "line-height": "25px",
                                                      width: "100%",
                                                      "background-color":
                                                        "rgba(230, 35, 30, 0.25)"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "fileName"
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(el.name) +
                                                            " " +
                                                            _vm._s(
                                                              el.lastName
                                                            ) +
                                                            " "
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          display:
                                                            "inline-block"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "v-btn",
                                                          {
                                                            staticClass:
                                                              "btnStyle",
                                                            attrs: {
                                                              elevation: "0",
                                                              "x-small": "",
                                                              color:
                                                                "rgba(0,0,0,0)"
                                                            }
                                                          },
                                                          [
                                                            _c("img", {
                                                              staticStyle: {
                                                                height: "20px"
                                                              },
                                                              attrs: {
                                                                src:
                                                                  _vm.keinDocument
                                                              }
                                                            })
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "toDoLong" },
                                            _vm._l(
                                              _vm.missingAppointments,
                                              function(el, index) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: el + index,
                                                    staticClass:
                                                      "py-2 px-5 d-flex justify-space-between align-center",
                                                    class: {
                                                      brighterToDoRow:
                                                        index % 2 === 1
                                                    },
                                                    staticStyle: {
                                                      "font-size": "medium",
                                                      "font-weight": "normal",
                                                      "line-height": "25px",
                                                      width: "100%",
                                                      "background-color":
                                                        "rgba(230, 35, 30, 0.25)"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "fileName"
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(el.name) +
                                                            " " +
                                                            _vm._s(
                                                              el.lastName
                                                            ) +
                                                            " "
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          display:
                                                            "inline-block"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "v-btn",
                                                          {
                                                            staticClass:
                                                              "btnStyle",
                                                            attrs: {
                                                              elevation: "0",
                                                              "x-small": "",
                                                              color:
                                                                "rgba(0,0,0,0)"
                                                            }
                                                          },
                                                          [
                                                            _c("img", {
                                                              staticStyle: {
                                                                height: "20px"
                                                              },
                                                              attrs: {
                                                                src:
                                                                  _vm.keinDocument
                                                              }
                                                            })
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          ),
                                          _vm.missingAppointments.length > 2
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex justify-center mt-2"
                                                },
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      staticClass: "toDoShort",
                                                      attrs: {
                                                        small: "",
                                                        elevation: "0"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          _vm.hideEl(
                                                            "toDoShort"
                                                          )
                                                          _vm.showEl("toDoLong")
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c("img", {
                                                        staticStyle: {
                                                          height: "20px"
                                                        },
                                                        attrs: {
                                                          src: _vm.arrowDown
                                                        }
                                                      })
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      staticClass: "toDoLong",
                                                      attrs: {
                                                        small: "",
                                                        elevation: "0"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          _vm.hideEl("toDoLong")
                                                          _vm.showEl(
                                                            "toDoShort"
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c("img", {
                                                        staticStyle: {
                                                          height: "20px",
                                                          transform:
                                                            "rotate(180deg)"
                                                        },
                                                        attrs: {
                                                          src: _vm.arrowDown
                                                        }
                                                      })
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _c(
                                            "v-row",
                                            { staticClass: "mb-2" },
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass:
                                                    "font-weight-bold pb-0 mx-5 d-flex align-center"
                                                },
                                                [
                                                  _c("img", {
                                                    staticStyle: {
                                                      height: "20px"
                                                    },
                                                    attrs: {
                                                      src: _vm.bearbeitetIcon
                                                    }
                                                  }),
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass: "pl-2 ma-0"
                                                    },
                                                    [_vm._v("Korrigiert")]
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          ),
                                          !_vm.pupilUploadsCorrected.length
                                            ? _c(
                                                "v-row",
                                                {
                                                  staticClass:
                                                    "ma-0 fileUploadPlaceholderText",
                                                  attrs: {
                                                    dense: "",
                                                    "align-content": "center"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "p",
                                                    { staticClass: "mb-0" },
                                                    [
                                                      _vm._v(
                                                        "Noch ist nichts korrigiert."
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            : _vm._e(),
                                          _c(
                                            "div",
                                            { staticClass: "correctedShort" },
                                            _vm._l(
                                              _vm.pupilUploadsCorrected.slice(
                                                0,
                                                2
                                              ),
                                              function(el, index) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: el + index,
                                                    staticClass:
                                                      "py-2 px-5 d-flex justify-space-between align-center",
                                                    class: {
                                                      brighterCorrectedRow:
                                                        index % 2 === 1
                                                    },
                                                    staticStyle: {
                                                      "font-size": "medium",
                                                      "font-weight": "normal",
                                                      "line-height": "25px",
                                                      width: "100%",
                                                      "background-color":
                                                        "rgba(60, 170, 105, 0.25)"
                                                    },
                                                    on: {
                                                      click: function() {
                                                        return _vm.clickDownloadPupilCorrection(
                                                          el
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "fileName",
                                                        staticStyle: {
                                                          width: "75%",
                                                          "min-width": "75%"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(el.title) +
                                                            " "
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "pa-0 ml-2"
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticStyle: {
                                                              display:
                                                                "inline-block"
                                                            }
                                                          },
                                                          [
                                                            [
                                                              "doc",
                                                              "docx",
                                                              "ppt",
                                                              "pptx",
                                                              "xls",
                                                              "xlsx"
                                                            ].includes(
                                                              el.title
                                                                .split(".")
                                                                .pop()
                                                                .toLowerCase()
                                                            )
                                                              ? _c(
                                                                  "v-btn",
                                                                  {
                                                                    staticClass:
                                                                      "btnStyle",
                                                                    staticStyle: {
                                                                      "background-color":
                                                                        "#8CBD46"
                                                                    },
                                                                    attrs: {
                                                                      dark: "",
                                                                      "x-small":
                                                                        "",
                                                                      elevation:
                                                                        "0"
                                                                    },
                                                                    on: {
                                                                      click: function() {
                                                                        _vm.snackbarFileNotSupported = true
                                                                      }
                                                                    }
                                                                  },
                                                                  [
                                                                    _c("img", {
                                                                      staticStyle: {
                                                                        height:
                                                                          "20px",
                                                                        filter:
                                                                          "brightness(1000%)"
                                                                      },
                                                                      attrs: {
                                                                        src:
                                                                          _vm.infoIcon
                                                                      }
                                                                    })
                                                                  ]
                                                                )
                                                              : _c(
                                                                  "v-btn",
                                                                  {
                                                                    staticClass:
                                                                      "btnStyle",
                                                                    attrs: {
                                                                      "x-small":
                                                                        "",
                                                                      elevation:
                                                                        "0",
                                                                      color:
                                                                        "rgba(0,0,0,0)"
                                                                    },
                                                                    on: {
                                                                      click: function(
                                                                        event
                                                                      ) {
                                                                        event.stopPropagation()
                                                                        _vm.handleOpenAnsichtClick(
                                                                          el
                                                                        )
                                                                      }
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      {
                                                                        staticStyle: {
                                                                          width:
                                                                            "20px",
                                                                          color:
                                                                            "#939393"
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "fas fa-eye"
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticStyle: {
                                                              display:
                                                                "inline-block"
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "v-btn",
                                                              {
                                                                staticClass:
                                                                  "btnStyle",
                                                                attrs: {
                                                                  elevation:
                                                                    "0",
                                                                  "x-small": "",
                                                                  color:
                                                                    "rgba(0,0,0,0)"
                                                                },
                                                                on: {
                                                                  click: function() {
                                                                    return _vm.clickDownloadPupilCorrection(
                                                                      el
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                _c("img", {
                                                                  staticStyle: {
                                                                    height:
                                                                      "20px"
                                                                  },
                                                                  attrs: {
                                                                    src:
                                                                      _vm.bearbeitetIcon
                                                                  }
                                                                })
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "correctedLong" },
                                            _vm._l(
                                              _vm.pupilUploadsCorrected,
                                              function(el, index) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: el + index,
                                                    staticClass:
                                                      "py-2 px-5 d-flex justify-space-between align-center",
                                                    class: {
                                                      brighterCorrectedRow:
                                                        index % 2 === 1
                                                    },
                                                    staticStyle: {
                                                      "font-size": "medium",
                                                      "font-weight": "normal",
                                                      "line-height": "25px",
                                                      width: "100%",
                                                      "background-color":
                                                        "rgba(60, 170, 105, 0.25)"
                                                    },
                                                    on: {
                                                      click: function() {
                                                        return _vm.clickDownloadPupilCorrection(
                                                          el
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "fileName",
                                                        staticStyle: {
                                                          width: "75%"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(el.title) +
                                                            " "
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "pa-0 ml-2"
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticStyle: {
                                                              display:
                                                                "inline-block"
                                                            }
                                                          },
                                                          [
                                                            [
                                                              "doc",
                                                              "docx",
                                                              "ppt",
                                                              "pptx",
                                                              "xls",
                                                              "xlsx"
                                                            ].includes(
                                                              el.title
                                                                .split(".")
                                                                .pop()
                                                                .toLowerCase()
                                                            )
                                                              ? _c(
                                                                  "v-btn",
                                                                  {
                                                                    staticClass:
                                                                      "btnStyle",
                                                                    staticStyle: {
                                                                      "background-color":
                                                                        "#8CBD46"
                                                                    },
                                                                    attrs: {
                                                                      dark: "",
                                                                      "x-small":
                                                                        "",
                                                                      elevation:
                                                                        "0"
                                                                    },
                                                                    on: {
                                                                      click: function() {
                                                                        _vm.snackbarFileNotSupported = true
                                                                      }
                                                                    }
                                                                  },
                                                                  [
                                                                    _c("img", {
                                                                      staticStyle: {
                                                                        height:
                                                                          "20px",
                                                                        filter:
                                                                          "brightness(1000%)"
                                                                      },
                                                                      attrs: {
                                                                        src:
                                                                          _vm.infoIcon
                                                                      }
                                                                    })
                                                                  ]
                                                                )
                                                              : _c(
                                                                  "v-btn",
                                                                  {
                                                                    staticClass:
                                                                      "btnStyle",
                                                                    attrs: {
                                                                      "x-small":
                                                                        "",
                                                                      elevation:
                                                                        "0",
                                                                      color:
                                                                        "rgba(0,0,0,0)"
                                                                    },
                                                                    on: {
                                                                      click: function(
                                                                        event
                                                                      ) {
                                                                        event.stopPropagation()
                                                                        _vm.handleOpenAnsichtClick(
                                                                          el
                                                                        )
                                                                      }
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      {
                                                                        staticStyle: {
                                                                          width:
                                                                            "20px",
                                                                          color:
                                                                            "#939393"
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "fas fa-eye"
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticStyle: {
                                                              display:
                                                                "inline-block"
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "v-btn",
                                                              {
                                                                staticClass:
                                                                  "btnStyle",
                                                                attrs: {
                                                                  elevation:
                                                                    "0",
                                                                  "x-small": "",
                                                                  color:
                                                                    "rgba(0,0,0,0)"
                                                                },
                                                                on: {
                                                                  click: function() {
                                                                    return _vm.clickDownloadPupilCorrection(
                                                                      el
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                _c("img", {
                                                                  staticStyle: {
                                                                    height:
                                                                      "20px"
                                                                  },
                                                                  attrs: {
                                                                    src:
                                                                      _vm.bearbeitetIcon
                                                                  }
                                                                })
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          ),
                                          _vm.pupilUploadsCorrected.length > 2
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex justify-center mt-2"
                                                },
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      staticClass:
                                                        "correctedShort",
                                                      attrs: {
                                                        small: "",
                                                        elevation: "0"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          _vm.hideEl(
                                                            "correctedShort"
                                                          )
                                                          _vm.showEl(
                                                            "correctedLong"
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c("img", {
                                                        staticStyle: {
                                                          height: "20px"
                                                        },
                                                        attrs: {
                                                          src: _vm.arrowDown
                                                        }
                                                      })
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      staticClass:
                                                        "correctedLong",
                                                      attrs: {
                                                        small: "",
                                                        elevation: "0"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          _vm.hideEl(
                                                            "correctedLong"
                                                          )
                                                          _vm.showEl(
                                                            "correctedShort"
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c("img", {
                                                        staticStyle: {
                                                          height: "20px",
                                                          transform:
                                                            "rotate(180deg)"
                                                        },
                                                        attrs: {
                                                          src: _vm.arrowDown
                                                        }
                                                      })
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    : _c(
                                        "div",
                                        {
                                          staticClass: "mx-5",
                                          staticStyle: {
                                            color: "#BDBDBD",
                                            "font-size": "medium"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " Es hat noch kein Schüler eine Abgabe getätigt. "
                                          )
                                        ]
                                      )
                                ])
                              ],
                              1
                            )
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    {
                      staticClass: "pa-0 ma-0",
                      staticStyle: { overflow: "hidden" }
                    },
                    [
                      _c(
                        "v-row",
                        [
                          _c("hr", { staticClass: "eHr mt-2" }),
                          _c(
                            "v-col",
                            { staticClass: "text-center" },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "text-capitalize",
                                  staticStyle: {
                                    color: "white",
                                    "background-color": "#8CBD46",
                                    "padding-left": "50px",
                                    "padding-right": "50px"
                                  },
                                  attrs: { elevation: "0" },
                                  on: { click: _vm.clickBeitreten }
                                },
                                [
                                  _c("img", {
                                    staticClass: "mr-5",
                                    staticStyle: { height: "20px" },
                                    attrs: { src: _vm.beitretenKameraIcon }
                                  }),
                                  _vm._v(" Beitreten ")
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _c(
            "div",
            {
              staticClass: "fach pa-2 white--text",
              staticStyle: {
                width: "100%",
                height: "70.5px !important",
                display: "block",
                "padding-left": "0 !important"
              }
            },
            [
              _c(
                "v-row",
                { staticClass: "ml-2 font-weight-medium" },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "pa-0 ma-0",
                      staticStyle: { height: "48px !important" },
                      attrs: { cols: "12" }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "pa-3 ma-0 appointmentItem inactiveAppointment"
                        },
                        [
                          _c("img", { staticClass: "iconSelectFrei" }),
                          _vm._v(" Hohlstunde ")
                        ]
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
      _c(
        "v-snackbar",
        {
          attrs: { bottom: "", color: "error", timeout: "3000" },
          model: {
            value: _vm.snackbar,
            callback: function($$v) {
              _vm.snackbar = $$v
            },
            expression: "snackbar"
          }
        },
        [_vm._v(" " + _vm._s(_vm.snackbarText) + " ")]
      ),
      _c(
        "v-snackbar",
        {
          attrs: { bottom: "", color: "success", timeout: "3000" },
          model: {
            value: _vm.snackbarSave,
            callback: function($$v) {
              _vm.snackbarSave = $$v
            },
            expression: "snackbarSave"
          }
        },
        [_vm._v(" Gespeichert! ")]
      ),
      _c(
        "v-snackbar",
        {
          attrs: {
            bottom: "",
            color: _vm.snackbarDeleteColor,
            timeout: "3000"
          },
          model: {
            value: _vm.snackbarDelete,
            callback: function($$v) {
              _vm.snackbarDelete = $$v
            },
            expression: "snackbarDelete"
          }
        },
        [_vm._v(" " + _vm._s(_vm.snackbarDeleteText) + " ")]
      ),
      _c(
        "v-snackbar",
        {
          staticStyle: { "z-index": "9999 !important" },
          attrs: { bottom: "", color: "grey", timeout: "5000" },
          model: {
            value: _vm.snackbarFileNotSupported,
            callback: function($$v) {
              _vm.snackbarFileNotSupported = $$v
            },
            expression: "snackbarFileNotSupported"
          }
        },
        [
          _vm._v(
            " Diese Datei wird vom Editor nicht unterstützt. Bitte lade sie herunter. "
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }