var render = function() {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-row", { staticClass: "mx-0" }, [
        _c(
          "div",
          {
            staticClass: "whiteBg",
            staticStyle: {
              "min-height": "80vh",
              height: "auto",
              "max-width": "90vw",
              width: "400px !important",
              "border-radius": "15px",
              "box-shadow": "1px 5px 5px silver",
              margin: "auto auto 5em"
            }
          },
          [
            _c(
              "div",
              {
                staticStyle: {
                  "background-color": "#8cbd46",
                  "border-radius": "15px 15px 0 0",
                  "padding-top": "10px",
                  "padding-bottom": "10px",
                  "text-align": "center",
                  color: "white"
                }
              },
              [
                _c(
                  "v-row",
                  {
                    staticClass: "pr-2 ml-2",
                    staticStyle: {
                      width: "90%",
                      display: "flex",
                      "align-items": "center"
                    }
                  },
                  [
                    _c(
                      "v-col",
                      {
                        staticClass: "pa-0 pl-2 text-left d-flex align-center",
                        staticStyle: { "font-size": "xx-large" },
                        attrs: { cols: "9", md: "8", lg: "9" }
                      },
                      [
                        _c("img", {
                          staticClass: "iconToWhite mr-1",
                          staticStyle: {
                            height: "30px",
                            "margin-bottom": "-3px"
                          },
                          attrs: { src: _vm.chatIcon }
                        }),
                        _c("p", { staticClass: "ma-0 pl-2" }, [_vm._v("Chat")])
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "chat-body" },
              [
                _vm.rooms.length > 0
                  ? _c(
                      "v-row",
                      { staticClass: "mx-1" },
                      [
                        _vm.selected && _vm.page > 0
                          ? _c(
                              "v-col",
                              { attrs: { cols: "3" } },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "pageButton",
                                    attrs: {
                                      elevation: "0",
                                      tile: "",
                                      color: "#737373"
                                    },
                                    on: {
                                      click: function($event) {
                                        _vm.page === 2
                                          ? (_vm.page -= 2)
                                          : _vm.page--
                                      }
                                    }
                                  },
                                  [
                                    _c("img", {
                                      staticClass: "iconToWhite",
                                      attrs: { src: _vm.linksIcon, height: 30 }
                                    })
                                  ]
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._l(
                          _vm.selected
                            ? _vm.matrixUsers.slice(
                                _vm.page,
                                _vm.matrixUsers.length > 3 &&
                                  (_vm.page === 0 ||
                                    _vm.page === _vm.matrixUsers.length - 3)
                                  ? _vm.page + 3
                                  : _vm.matrixUsers.length === 3
                                  ? _vm.page + 3
                                  : _vm.page + 2
                              )
                            : _vm.matrixUsers,
                          function(item) {
                            return _c(
                              "v-col",
                              {
                                key: JSON.stringify(item),
                                staticClass: "ellipsis",
                                staticStyle: {
                                  "text-align": "center",
                                  cursor: "pointer"
                                },
                                attrs: { cols: "3" },
                                on: {
                                  click: function($event) {
                                    return _vm.openPrivateRoom(item)
                                  }
                                }
                              },
                              [
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "mx-auto",
                                        attrs: {
                                          selected: _vm.selected === item._id,
                                          medium: "",
                                          elevation: "0",
                                          fab: "",
                                          color: "#6994cc"
                                        }
                                      },
                                      [
                                        _c("img", {
                                          class: _vm.hasProfilePic(item)
                                            ? "roundImage"
                                            : "iconToWhite",
                                          attrs: {
                                            selected:
                                              _vm.selected === item._id &&
                                              _vm.hasProfilePic(item),
                                            src: _vm.getTeachPic(item),
                                            height: _vm.hasProfilePic(item)
                                              ? 56
                                              : 25
                                          }
                                        })
                                      ]
                                    )
                                  ],
                                  1
                                ),
                                _vm._v(" " + _vm._s(item.lastName) + " ")
                              ],
                              1
                            )
                          }
                        ),
                        _vm.selected &&
                        _vm.matrixUsers.length > 3 &&
                        _vm.page < _vm.matrixUsers.length - 3
                          ? _c(
                              "v-col",
                              { attrs: { cols: "3" } },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "pageButton",
                                    attrs: {
                                      elevation: "0",
                                      tile: "",
                                      color: "#737373"
                                    },
                                    on: {
                                      click: function($event) {
                                        _vm.page === 0
                                          ? (_vm.page += 2)
                                          : _vm.page++
                                      }
                                    }
                                  },
                                  [
                                    _c("img", {
                                      staticClass: "iconToWhite",
                                      attrs: { src: _vm.rechtsIcon, height: 30 }
                                    })
                                  ]
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      2
                    )
                  : _vm._e(),
                _vm.selected
                  ? _c("hr", {
                      staticClass: "mx-6 mb-1",
                      staticStyle: { color: "#737373" }
                    })
                  : _vm._e(),
                _vm.selected === "0"
                  ? _c(
                      "vue-custom-scrollbar",
                      {
                        staticClass: "scroll-area",
                        attrs: { settings: _vm.settings }
                      },
                      _vm._l(_vm.blackboard, function(item) {
                        return _c(
                          "div",
                          {
                            key: JSON.stringify(item),
                            staticClass: "mx-6 mt-2"
                          },
                          [
                            _c(
                              "v-row",
                              {
                                staticStyle: {
                                  color: "#737373",
                                  "line-height": "56px"
                                }
                              },
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "2" } },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "mx-auto",
                                        attrs: {
                                          medium: "",
                                          elevation: "0",
                                          fab: "",
                                          color: item.color
                                        }
                                      },
                                      [
                                        _c("img", {
                                          attrs: {
                                            src: _vm.chooseIcon(item.icon),
                                            height: 25
                                          }
                                        })
                                      ]
                                    )
                                  ],
                                  1
                                ),
                                _c("v-col", [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "ellipsis",
                                      staticStyle: {
                                        "font-weight": "bold",
                                        "font-size": "larger"
                                      }
                                    },
                                    [_vm._v("Verwaltung")]
                                  )
                                ]),
                                _c(
                                  "v-col",
                                  { staticStyle: { "text-align": "right" } },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          new Date(
                                            item.createdOn
                                          ).toLocaleDateString([], {
                                            day: "2-digit",
                                            month: "2-digit"
                                          }) +
                                            " " +
                                            new Date(
                                              item.createdOn
                                            ).toLocaleTimeString([], {
                                              hour: "2-digit",
                                              minute: "2-digit"
                                            })
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ],
                              1
                            ),
                            _c("div", { staticStyle: { display: "flex" } }, [
                              _c(
                                "div",
                                { staticClass: "pa-3 chat-message-content" },
                                [_vm._v(" " + _vm._s(item.message) + " ")]
                              ),
                              _c(
                                "div",
                                { staticStyle: { width: "15%" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "speakerButton",
                                      attrs: {
                                        elevation: "0",
                                        tile: "",
                                        color: "#737373"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.readText(
                                            item.message,
                                            $event
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: _vm.lautsprecherIcon,
                                          height: 30
                                        }
                                      })
                                    ]
                                  )
                                ],
                                1
                              )
                            ])
                          ],
                          1
                        )
                      }),
                      0
                    )
                  : _vm._e(),
                _vm.viewingRoom
                  ? _c(
                      "div",
                      {
                        staticClass: "chat-body",
                        staticStyle: { overflow: "hidden !important" }
                      },
                      [
                        _c(
                          "vue-custom-scrollbar",
                          {
                            ref: "scroll",
                            staticClass: "scroll-area2",
                            attrs: { settings: _vm.settings },
                            on: {
                              "ps-y-reach-start": function($event) {
                                return _vm.loadMore()
                              }
                            }
                          },
                          _vm._l(
                            _vm.events.filter(function(el) {
                              return el.getType() === "m.room.message"
                            }),
                            function(event, index) {
                              return _c(
                                "div",
                                { key: index, staticClass: "mx-6 mt-2" },
                                [
                                  !_vm.isMe(event)
                                    ? _c(
                                        "div",
                                        [
                                          _c(
                                            "v-row",
                                            {
                                              staticStyle: {
                                                color: "#737373",
                                                "line-height": "56px"
                                              }
                                            },
                                            [
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "2" } },
                                                [
                                                  !_vm.hasProfilePic(
                                                    _vm.matrixUsers.find(
                                                      function(el) {
                                                        return (
                                                          el._id == _vm.selected
                                                        )
                                                      }
                                                    )
                                                  )
                                                    ? _c(
                                                        "v-btn",
                                                        {
                                                          staticClass:
                                                            "mx-auto",
                                                          attrs: {
                                                            medium: "",
                                                            elevation: "0",
                                                            fab: "",
                                                            color: "#6994cc"
                                                          }
                                                        },
                                                        [
                                                          _c("img", {
                                                            staticClass:
                                                              "iconToWhite",
                                                            attrs: {
                                                              src: _vm.getTeachPic(
                                                                _vm.matrixUsers.find(
                                                                  function(el) {
                                                                    return (
                                                                      el._id ==
                                                                      _vm.selected
                                                                    )
                                                                  }
                                                                )
                                                              ),
                                                              height: 25
                                                            }
                                                          })
                                                        ]
                                                      )
                                                    : _c(
                                                        "v-btn",
                                                        {
                                                          staticClass:
                                                            "mx-auto",
                                                          attrs: {
                                                            medium: "",
                                                            elevation: "0",
                                                            fab: "",
                                                            color: "#6994cc"
                                                          }
                                                        },
                                                        [
                                                          _c("img", {
                                                            staticClass:
                                                              "roundImage",
                                                            attrs: {
                                                              src: _vm.getTeachPic(
                                                                _vm.matrixUsers.find(
                                                                  function(el) {
                                                                    return (
                                                                      el._id ==
                                                                      _vm.selected
                                                                    )
                                                                  }
                                                                )
                                                              ),
                                                              height: 56
                                                            }
                                                          })
                                                        ]
                                                      )
                                                ],
                                                1
                                              ),
                                              _c("v-col", [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "ellipsis",
                                                    staticStyle: {
                                                      "font-weight": "bold",
                                                      "font-size": "larger"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.matrixUsers.find(
                                                          function(el) {
                                                            return (
                                                              el._id ==
                                                              _vm.selected
                                                            )
                                                          }
                                                        ).lastName
                                                      )
                                                    )
                                                  ]
                                                )
                                              ]),
                                              _c(
                                                "v-col",
                                                {
                                                  staticStyle: {
                                                    "text-align": "right"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        new Date(
                                                          _vm.getTime(event)
                                                        ).toLocaleDateString(
                                                          [],
                                                          {
                                                            day: "2-digit",
                                                            month: "2-digit"
                                                          }
                                                        ) +
                                                          " " +
                                                          new Date(
                                                            _vm.getTime(event)
                                                          ).toLocaleTimeString(
                                                            [],
                                                            {
                                                              hour: "2-digit",
                                                              minute: "2-digit"
                                                            }
                                                          )
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticStyle: { display: "flex" }
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "pa-3 chat-message-content"
                                                },
                                                [
                                                  event.getContent().body
                                                    ? _c("div", [
                                                        event
                                                          .getContent()
                                                          .body.startsWith(
                                                            "$fileId:"
                                                          )
                                                          ? _c("div", [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticStyle: {
                                                                    "line-height":
                                                                      "20px"
                                                                  },
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      _vm.loadedAudios.filter(
                                                                        function(
                                                                          el
                                                                        ) {
                                                                          return (
                                                                            el.file ===
                                                                            event
                                                                              .getContent()
                                                                              .body.replace(
                                                                                "$fileId:",
                                                                                ""
                                                                              )
                                                                          )
                                                                        }
                                                                      ).length >
                                                                      0
                                                                        ? _vm.playPause(
                                                                            event
                                                                              .getContent()
                                                                              .body.replace(
                                                                                "$fileId:",
                                                                                ""
                                                                              )
                                                                          )
                                                                        : _vm.loadFile(
                                                                            event
                                                                              .getContent()
                                                                              .body.replace(
                                                                                "$fileId:",
                                                                                ""
                                                                              )
                                                                          )
                                                                    }
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "d-flex align-center",
                                                                      staticStyle: {
                                                                        "min-height":
                                                                          "24px"
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "p",
                                                                        {
                                                                          staticClass:
                                                                            "mb-0 mr-1"
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "Sprachnachricht"
                                                                          )
                                                                        ]
                                                                      ),
                                                                      !_vm.loadedAudios.filter(
                                                                        function(
                                                                          el
                                                                        ) {
                                                                          return (
                                                                            el.file ===
                                                                            event
                                                                              .getContent()
                                                                              .body.replace(
                                                                                "$fileId:",
                                                                                ""
                                                                              )
                                                                          )
                                                                        }
                                                                      ).length >
                                                                      0
                                                                        ? _c(
                                                                            "img",
                                                                            {
                                                                              staticClass:
                                                                                "icon iconToWhite",
                                                                              attrs: {
                                                                                src:
                                                                                  _vm.downloadIcon
                                                                              }
                                                                            }
                                                                          )
                                                                        : _vm.audioSrc !==
                                                                          event
                                                                            .getContent()
                                                                            .body.replace(
                                                                              "$fileId:",
                                                                              ""
                                                                            )
                                                                        ? _c(
                                                                            "img",
                                                                            {
                                                                              staticClass:
                                                                                "icon iconToWhite",
                                                                              attrs: {
                                                                                src:
                                                                                  _vm.playIcon
                                                                              }
                                                                            }
                                                                          )
                                                                        : _c(
                                                                            "img",
                                                                            {
                                                                              staticClass:
                                                                                "icon iconToWhite",
                                                                              attrs: {
                                                                                src:
                                                                                  _vm.pauseIcon
                                                                              }
                                                                            }
                                                                          )
                                                                    ]
                                                                  )
                                                                ]
                                                              )
                                                            ])
                                                          : _c("div", [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    event.getContent()
                                                                      .body
                                                                  ) +
                                                                  " "
                                                              )
                                                            ])
                                                      ])
                                                    : _c(
                                                        "div",
                                                        {
                                                          staticStyle: {
                                                            "font-style":
                                                              "italic"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            " Diese Nachricht wurde gelöscht. "
                                                          )
                                                        ]
                                                      )
                                                ]
                                              ),
                                              !event.getContent().body ||
                                              !event
                                                .getContent()
                                                .body.startsWith("$fileId:")
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        width: "15%"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          staticClass:
                                                            "speakerButton",
                                                          attrs: {
                                                            elevation: "0",
                                                            tile: "",
                                                            color: "#737373"
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              _vm.readText(
                                                                event.getContent()
                                                                  .body ||
                                                                  "Diese Nachricht wurde gelöscht.",
                                                                $event
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c("img", {
                                                            attrs: {
                                                              src:
                                                                _vm.lautsprecherIcon,
                                                              height: 30
                                                            }
                                                          })
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    : _c(
                                        "div",
                                        [
                                          _c(
                                            "v-row",
                                            {
                                              staticStyle: { color: "#737373" }
                                            },
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  staticStyle: {
                                                    "text-align": "right"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        new Date(
                                                          _vm.getTime(event)
                                                        ).toLocaleDateString(
                                                          [],
                                                          {
                                                            day: "2-digit",
                                                            month: "2-digit"
                                                          }
                                                        ) +
                                                          " " +
                                                          new Date(
                                                            _vm.getTime(event)
                                                          ).toLocaleTimeString(
                                                            [],
                                                            {
                                                              hour: "2-digit",
                                                              minute: "2-digit"
                                                            }
                                                          )
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticStyle: { display: "flex" }
                                            },
                                            [
                                              event.getContent().body
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        width: "15%"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          staticClass:
                                                            "deleteButton",
                                                          attrs: {
                                                            elevation: "0",
                                                            tile: "",
                                                            color: "#737373"
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              _vm.isMe(event) &&
                                                              event.getContent()
                                                                .body
                                                                ? (_vm.selectedEvent = event) &&
                                                                  (_vm.deleteDialog = true)
                                                                : null
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c("img", {
                                                            staticClass:
                                                              "iconToWhite",
                                                            attrs: {
                                                              src:
                                                                _vm.papierkorbIcon,
                                                              height: 30
                                                            }
                                                          })
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "pa-3 my-chat-message-content"
                                                },
                                                [
                                                  event.getContent().body
                                                    ? _c("div", [
                                                        event
                                                          .getContent()
                                                          .body.startsWith(
                                                            "$fileId:"
                                                          )
                                                          ? _c("div", [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticStyle: {
                                                                    "line-height":
                                                                      "20px"
                                                                  },
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      _vm.loadedAudios.filter(
                                                                        function(
                                                                          el
                                                                        ) {
                                                                          return (
                                                                            el.file ===
                                                                            event
                                                                              .getContent()
                                                                              .body.replace(
                                                                                "$fileId:",
                                                                                ""
                                                                              )
                                                                          )
                                                                        }
                                                                      ).length >
                                                                      0
                                                                        ? _vm.playPause(
                                                                            event
                                                                              .getContent()
                                                                              .body.replace(
                                                                                "$fileId:",
                                                                                ""
                                                                              )
                                                                          )
                                                                        : _vm.loadFile(
                                                                            event
                                                                              .getContent()
                                                                              .body.replace(
                                                                                "$fileId:",
                                                                                ""
                                                                              )
                                                                          )
                                                                    }
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "d-flex align-center",
                                                                      staticStyle: {
                                                                        "min-height":
                                                                          "24px"
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "p",
                                                                        {
                                                                          staticClass:
                                                                            "mb-0 mr-1"
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "Sprachnachricht"
                                                                          )
                                                                        ]
                                                                      ),
                                                                      !_vm.loadedAudios.filter(
                                                                        function(
                                                                          el
                                                                        ) {
                                                                          return (
                                                                            el.file ===
                                                                            event
                                                                              .getContent()
                                                                              .body.replace(
                                                                                "$fileId:",
                                                                                ""
                                                                              )
                                                                          )
                                                                        }
                                                                      ).length >
                                                                      0
                                                                        ? _c(
                                                                            "img",
                                                                            {
                                                                              staticClass:
                                                                                "icon iconToWhite",
                                                                              attrs: {
                                                                                src:
                                                                                  _vm.downloadIcon
                                                                              }
                                                                            }
                                                                          )
                                                                        : _vm.audioSrc !==
                                                                          event
                                                                            .getContent()
                                                                            .body.replace(
                                                                              "$fileId:",
                                                                              ""
                                                                            )
                                                                        ? _c(
                                                                            "img",
                                                                            {
                                                                              staticClass:
                                                                                "icon iconToWhite",
                                                                              attrs: {
                                                                                src:
                                                                                  _vm.playIcon
                                                                              }
                                                                            }
                                                                          )
                                                                        : _c(
                                                                            "img",
                                                                            {
                                                                              staticClass:
                                                                                "icon iconToWhite",
                                                                              attrs: {
                                                                                src:
                                                                                  _vm.pauseIcon
                                                                              }
                                                                            }
                                                                          )
                                                                    ]
                                                                  )
                                                                ]
                                                              )
                                                            ])
                                                          : _c("div", [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    event.getContent()
                                                                      .body
                                                                  ) +
                                                                  " "
                                                              )
                                                            ])
                                                      ])
                                                    : _c(
                                                        "div",
                                                        {
                                                          staticStyle: {
                                                            "font-style":
                                                              "italic"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            " Diese Nachricht wurde gelöscht. "
                                                          )
                                                        ]
                                                      )
                                                ]
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                ]
                              )
                            }
                          ),
                          0
                        ),
                        _vm.selected && _vm.viewingRoom
                          ? _c("hr", {
                              staticClass: "mx-6 mb-1",
                              staticStyle: { color: "#737373" }
                            })
                          : _vm._e(),
                        _vm.selected && _vm.viewingRoom
                          ? _c("div", { staticClass: "mx-6" }, [
                              _c("div", { staticStyle: { display: "flex" } }, [
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "long-press",
                                        rawName: "v-long-press",
                                        value: 100,
                                        expression: "100"
                                      }
                                    ],
                                    staticClass: "pa-2 chatButton",
                                    style: _vm.audioCheck
                                      ? _vm.toggle
                                        ? "background-color: red;"
                                        : "background-color: #8cbd46;"
                                      : "background-color: #5c5c5c;",
                                    on: {
                                      "long-press-start": _vm.startRecording,
                                      "long-press-stop": _vm.stopRecording
                                    }
                                  },
                                  [
                                    _vm._v(" Sprachnachricht "),
                                    _c("img", {
                                      staticClass: "iconToWhite",
                                      staticStyle: { "margin-bottom": "-2px" },
                                      attrs: {
                                        src: _vm.mikrofonIcon,
                                        height: 20
                                      }
                                    })
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "pt-1",
                                    staticStyle: { width: "15%" }
                                  },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "speakerButton",
                                        staticStyle: { "margin-top": "0" },
                                        attrs: {
                                          elevation: "0",
                                          tile: "",
                                          color: "#737373"
                                        },
                                        on: {
                                          click: function($event) {
                                            _vm.audioCheck
                                              ? _vm.readText(
                                                  "Halte das Mikrofon gedrückt und rede. Wenn du das Mikrofon loslässt, schickst du deine Nachricht ab.",
                                                  $event
                                                )
                                              : _vm.readText(
                                                  "Leider können wir dein Mikrofon nicht benutzen.",
                                                  $event
                                                )
                                          }
                                        }
                                      },
                                      [
                                        _c("img", {
                                          attrs: {
                                            src: _vm.lautsprecherIcon,
                                            height: 30
                                          }
                                        })
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass: "pt-1",
                                  staticStyle: { display: "flex" }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "pa-2 chatButton",
                                      on: {
                                        click: function($event) {
                                          return _vm.sendMessage(
                                            "Ich habe ein Problem 😖"
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v(" Ich habe ein Problem 😖 ")]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "pt-1",
                                      staticStyle: { width: "15%" }
                                    },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "speakerButton",
                                          staticStyle: { "margin-top": "0" },
                                          attrs: {
                                            elevation: "0",
                                            tile: "",
                                            color: "#737373"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.readText(
                                                "Ich habe ein Problem",
                                                $event
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("img", {
                                            attrs: {
                                              src: _vm.lautsprecherIcon,
                                              height: 30
                                            }
                                          })
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "pt-1",
                                  staticStyle: { display: "flex" }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "chatButton",
                                      staticStyle: {
                                        "background-color": "white",
                                        display: "inline-flex"
                                      }
                                    },
                                    [
                                      _c("twemoji-picker", {
                                        staticStyle: {
                                          height: "43px",
                                          "margin-top": "-10px"
                                        },
                                        attrs: {
                                          emojiData: _vm.emojiDataAll,
                                          emojiGroups: _vm.emojiGroups,
                                          pickerWidth: 300,
                                          skinsSelection: true,
                                          searchEmojisFeat: true,
                                          searchEmojiPlaceholder: "Suchen...",
                                          searchEmojiNotFound:
                                            "Kein Emoji gefunden",
                                          isLoadingLabel: "Lädt..."
                                        },
                                        on: {
                                          emojiUnicodeAdded: function(el) {
                                            return (_vm.inputMessage += el)
                                          }
                                        }
                                      }),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.inputMessage,
                                            expression: "inputMessage"
                                          }
                                        ],
                                        staticClass: "chat-send-input pa-1",
                                        attrs: {
                                          oncontextmenu: "return false;",
                                          placeholder: "Nachricht eingeben...",
                                          type: "text"
                                        },
                                        domProps: { value: _vm.inputMessage },
                                        on: {
                                          keyup: function($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "enter",
                                                13,
                                                $event.key,
                                                "Enter"
                                              )
                                            ) {
                                              return null
                                            }
                                            return _vm.sendMessage($event)
                                          },
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.inputMessage =
                                              $event.target.value
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "pt-1",
                                      staticStyle: { width: "15%" }
                                    },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "speakerButton",
                                          staticStyle: { "margin-top": "0" },
                                          attrs: {
                                            elevation: "0",
                                            tile: "",
                                            color: "#737373"
                                          },
                                          on: {
                                            click: function($event) {
                                              _vm.inputMessage.trim()
                                                ? _vm.sendInput()
                                                : _vm.readText(
                                                    "Tippe eine Nachricht ein um diese an deinen Lehrer zu senden.",
                                                    $event
                                                  )
                                            }
                                          }
                                        },
                                        [
                                          _c("img", {
                                            staticClass: "iconToWhite",
                                            attrs: {
                                              src: _vm.inputMessage.trim()
                                                ? _vm.sendenIcon
                                                : _vm.lautsprecherIcon,
                                              height: 30
                                            }
                                          })
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              )
                            ])
                          : _vm._e()
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            )
          ]
        )
      ]),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "400" },
          model: {
            value: _vm.deleteDialog,
            callback: function($$v) {
              _vm.deleteDialog = $$v
            },
            expression: "deleteDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "headline" }, [
                _vm._v("Nachricht löschen?")
              ]),
              _c("v-card-text", [
                _vm._v(" Wollen Sie diese wirklich Nachricht löschen? ")
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      staticStyle: { color: "white" },
                      attrs: { color: "#bdbdbd" },
                      on: {
                        click: function($event) {
                          _vm.deleteDialog = false
                        }
                      }
                    },
                    [_vm._v("Abbrechen")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticStyle: { color: "white" },
                      attrs: { color: "red red-1" },
                      on: {
                        click: function() {
                          this$1.deleteMessage(_vm.selectedEvent)
                          this$1.deleteDialog = false
                        }
                      }
                    },
                    [_vm._v("Löschen")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }