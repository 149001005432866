<template>
    <div style="border-radius: 5px !important; background-color: #F8F8F8">
        <v-row :style="{ backgroundColor: groupColor, height: '80px' }" class="ma-auto mb-1 pa-2" align="center">
            <v-col style="display: inline-flex; justify-content: flex-end">
                <p id="headerText" class="ml-1">{{ assignmentName }}</p>
                <v-btn id="closeBtn" @click="close" x-small class="mr-4" elevation="3">
                    <img :src="closeIcon" style="height: 40px; filter: brightness(1000%); justify-self: flex-end"/>
                </v-btn>
            </v-col>
        </v-row>
        <vuescroll :ops="ops" style="height: calc(100vh - 85px); background-color: #EAEAEA" ref="scrollbar">

            <!-- Editing row -->
            <v-row class="ma-0" align="center" justify="center">
                <v-btn elevation="3" x-small class="mr-3 zoomControl" @click="changeZoom('minus')">
                    <p>-</p>
                </v-btn>

                <v-btn elevation="3" x-small class="mr-3 zoomControl" style="margin-left: 63px" @click="changeZoom('plus')">
                    <p>+</p>
                </v-btn>
                <EditingCanvas
                        :canvas-height="canvasHeight"
                        :canvas-width="canvasWidth"
                        :loading-function="setLoading"
                        ref="editingcanvas"/>
            </v-row>
        </vuescroll>

        <!-- Popup land -->
        <v-dialog v-model="loadingActive" hide-overlay persistent width="150">
            <v-card :color="groupColor">
                <v-card-text class="pa-2">
                    <v-progress-circular indeterminate class="ma-auto" style="display: block"/>
                    <br/>
                    <p class="ma-auto" style="text-align: center">{{ loadingMessage }}</p>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-snackbar
                v-model="snackbars.fileNotAllowed"
                timeout="5000"
                color="error"
        >
            Zugriff auf diese Datei wurde verweigert.
        </v-snackbar>
        <v-snackbar
                v-model="snackbars.fileNotAvailable"
                timeout="5000"
                color="error"
        >
            Die Datei konnte nicht geladen werden.
        </v-snackbar>
        <v-snackbar
                v-model="snackbars.genericError"
                timeout="5000"
                color="error"
        >
            Ein Fehler ist aufgetreten.
        </v-snackbar>
    </div>
</template>

<script>
import EditingCanvas from './EditingCanvas';
import { mapActions, mapGetters } from 'vuex';
import closeIcon from '../assets/Icons/abbrechen-08.svg'
import vuescroll from 'vuescroll';
import scrollbarStyling from '../util/scrollbarStyling';

export default {
    name: 'FilePreview',
    components: { EditingCanvas, vuescroll },
    data() {
        return {
            canvasHeight: 200,
            canvasWidth: 200,
            assignmentName: '',
            appointmentId: null,
            fileId: null,
            uploadId: null,
            currentFile: null,
            snackbars: {
                fileNotAllowed: false,
                fileNotAvailable: false,
                genericError: false,
            },
            groupColor: '#FFF',
            currentZoom: 0,
            loadingActive: false,
            loadingMessage: false,
            ops: {
                rail: {
                    opacity: '0',
                    background: undefined,
                    border: 'none',
                    size: '60px'
                },
                bar: {
                    background: '#55555580',
                    keepShow: true,
                    size: '45px',
                    minSize: 0.1
                },
                scrollButton: {
                    enable: true,
                    background: '#cecece'
                },
                scrollPanel: {
                    easing: 'easeInQuad',
                    speed: 800
                },
                vuescroll: {
                    wheelScrollDuration: 0,
                    wheelDirectionReverse: false,
                    sizeStrategy: 'percent',
                    detectResize: true
                }
            },
            // Icons
            closeIcon,
            // Imports
            scrollbarStyling,
        };
    },
    async mounted() {
        let fileType = '';
        let localUploadId = '';
        let localAppointmentId = '';

        if (this.$route.query.color) {
            this.groupColor = this.$route.query.color;
        }
        if (this.$route.query.aId) {
            this.appointmentId = this.$route.query.aId;
            localAppointmentId = this.$route.query.aId;
        }
        if (this.$route.query.fId) {
            this.fileId = this.$route.query.fId;
        }
        if (this.$route.query.title) {
            this.assignmentName = this.$route.query.title;

            fileType = this.assignmentName.split('.')[1];
        }
        if (this.$route.query.uploadid) {
            this.uploadId = this.$route.query.uploadid;
            localUploadId = this.$route.query.uploadid;
        }

        let localUserGroup = 'pupil';
        if (this.$route.query.ug) {
            localUserGroup = this.$route.query.ug;
        }

        console.log(localUserGroup);

        this.setLoading(true);

        await this.requestFile(localUserGroup, fileType);

        this.checkForScrollbarStylingUpdates(document.getElementsByClassName('__vuescroll')[0]);

        const observer = new MutationObserver((mutationsList, observer) => {
            for(const mutation of mutationsList) {
                if (mutation.type === 'childList') {
                    this.checkForScrollbarStylingUpdates(document.getElementsByClassName('__vuescroll')[0]);
                }
            }
        });

        // Start observing the target node for configured mutations
        observer.observe(
            document.getElementsByClassName('__vuescroll')[0],
            {attributes: false, childList: true, subtree: false }
        );
    },
    computed: {
      ...mapGetters('files', [ 'getCurrentFileType' ]),
    },
    methods: {
        ...mapActions('files', [ 'pullCurrentFile' ]),

        /**
         *  UI Functions
         */
        setLoading(status, msg) {
            this.loadingMessage = msg ? msg : 'Dokument wird geladen...';
            this.loadingActive = status;
        },

        changeZoom(direction) {
            this.$refs.editingcanvas.$el.style.transform = '';

            if (direction === 'minus' && this.currentZoom > 0) {
                this.currentZoom--;
            } else if (direction === 'plus' && this.currentZoom < 10) {
                this.currentZoom++;
            }

            setTimeout(() => {

                this.$refs.editingcanvas.$el.style.transform = `scale(${1 + (this.currentZoom / 5)}, ${1 + (this.currentZoom / 5)})`;
                this.$refs.editingcanvas.$el.style.transformOrigin = `left top`;

                this.$nextTick(this.$refs.scrollbar.refresh());
                this.$nextTick(this.$refs.scrollbar.refreshInternalStatus());
            }, 150);
        },
        checkForScrollbarStylingUpdates(scrollbarParent) {
            if (scrollbarParent.classList.contains('hasVBar') ) {
                if (!this.isVerticalScrollbarStyled) {
                    this.scrollbarStyling.styleVerticalScrollbar();
                    this.isVerticalScrollbarStyled = true;
                }
            } else {
                this.isVerticalScrollbarStyled = false;
            }

            if (scrollbarParent.classList.contains('hasHBar') ) {
                if (!this.isHorizontalScrollbarStyled) {
                    this.scrollbarStyling.styleHorizontalScrollbar();
                    this.isHorizontalScrollbarStyled = true;
                }
            } else {
                this.isHorizontalScrollbarStyled = false;
            }
        },

        /**
         * File Functions
         */
        async requestFile(userGroup, fileType) {
            const response = await this.pullCurrentFile({
                userGroup,
                fileType,
                fileName: this.assignmentName,
                appointmentId: this.appointmentId,
                fileId: this.fileId
            });

            if (response && (response.status === 200
                || response.status === 201
                || response.status === 204)) {
                if (response.file) {
                    this.currentFile = response.file;
                    if (this.getCurrentFileType === 'pdf') {
                        await this.$refs.editingcanvas.setBackgroundPDF(this.currentFile);
                    } else {
                        await this.$refs.editingcanvas.setBackgroundImg(this.currentFile);
                    }
                } else {
                    if (response.error) {
                        this.snackbars.genericError = true;
                    }
                    this.setLoading(false);
                }
            } else {
                if (response.status === 401 || response.status === 403 ) {
                    this.snackbars.fileNotAllowed = true;
                } else if (response.status === 404) {
                    this.snackbars.fileNotAvailable = true;
                } else {
                    this.snackbars.genericError = true;
                }
                this.setLoading(false);
            }
        },

        /**
         * General
         */
        close() {
          this.$router.back();
        },
    }
}
</script>

<style lang="scss" scoped>
    @import "~@/styles/globals.scss";

    #headerText {
        color: white;
        font-size: x-large;
        font-weight: bold;

        max-width: calc(100% - 70px);
        min-width: calc(100% - 70px);
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

    #closeBtn {
        background-color: #e6221e80;
        width: 49px;
        height: 49px;

        @media (max-width: 380px) {
            width: 40px !important;
            height: 40px !important;
            margin-right: 0 !important;
        }
    }

    .toolSelector {
        background-color: #f8f8f880;
        width: 49px !important;
        height: 49px !important;

        @media (max-width: 380px) {
            width: 40px !important;
            height: 40px !important;
        }
    }

    .zoomControl {
        opacity: 0.8 !important;
        position: fixed;
        z-index: 8 !important;
        top: 95px;
        left: 20px;
        background-color: #f8f8f8 !important;
        width: 49px !important;
        height: 49px !important;
        border-radius: 8px;

        p {
            line-height: 0;
            font-size: 60px;
            margin-bottom: 0;
            font-weight: 300;
            color: #707070;
        }

        @media (max-width: 380px) {
            width: 40px !important;
            height: 40px !important;

            p {
                font-size: 50px;
            }
        }
    }

    .v-snack.v-snack--top {
        bottom: initial; /* or auto */
    }

    .v-snack.v-snack--bottom {
        top: initial; /* or auto */
    }
</style>
