<template>
    <div>
        <progress-bar
            title="Hochladen"
            :show-progress="showPupilUploadProgress"
            :progress="pupilUploadProgress"
            :abort-progress="pupilXmlHttpRequest ? () => { pupilXmlHttpRequest.abort() }: () => {}"
        />
        <input
            @change="() => pupilUploadInputChange(item.appointmentId, item._id)"
            id="hausiPupilUploadInput"
            ref="hausiPupilUploadInput"
            type="file"
            hidden
        />
        <v-row class="mx-0">
            <v-col class="pa-0">
                <div id="hausiWidgetBabyContainer">

                    <!--Header of Hausaufgaben widget-->
                    <div id="hausiWidgetHeader">
                        <v-row style="width: 90%; display: flex; align-items: center;" class="ml-4">
                            <v-col cols="9" md="8" lg="9" class="pa-0 text-left d-flex align-center" style="font-size: xx-large">
                                <img :src="hausaufgabenIconLeer"
                                     style="height: 30px; filter: brightness(1000%); margin-bottom: -3px;"
                                />
                                <p class="ma-0 pl-2">Hausaufgaben</p>
                            </v-col>
                        </v-row>
                    </div>

                    <div v-if="showLoading" class="d-flex justify-center mt-4">
                        <v-progress-circular color="#6995CD" indeterminate size="100"/>
                    </div>

                    <!--scrollable content-->
                    <vue-custom-scrollbar v-else class="scroll-area" :settings="settings">
                        <div>
                            <v-row cols="12" style="width: 99%; margin: 0.5em auto 0.5em auto;">
                                <v-col style="display: flex; align-items: center" class="sectionHeaderCol">
                                    <img :src="toDoIcon" style="height: 30px" class="sectionHeaderImg"/>
                                    <p class="pl-2 sectionHeaderText">Noch zu machen:</p>
                                </v-col>
                            </v-row>

                            <div v-for="(item, index) in babyFilesToDoDisplay" :key="index" class="fileRow ma-2 d-flex align-center"
                                   :class="{ brighterFileRow: index % 2 === 1, disabledFileRow: false }">

                                <!--Fach icon-->
                                <v-btn small class="ml-2 smallBabyViewActionBtn fachLogo" disabled
                                       :style="`background-color: ${item.fachColor} !important`">
                                    <img :src="getSrc(item.fachIcon)" class="smallBabyViewActionBtnImg"/>
                                </v-btn>

                                <!--colored line instead of icon if width < 600-->
                                <div class="fachLine ma-0 pa-0" :style="`background-color: ${item.fachColor} !important`">
                                </div>

                                <div class="fileTitle mx-2" @click="readExerciseName(item)">
                                    {{ item.title }}
                                </div>

                                <div class="ma-0 mr-2" style="display: flex">
                                    <v-btn small class="babyViewActionBtn" v-if="false">
                                        <img :src="lautsprecherIcon" class="babyViewActionBtnImg"/>
                                    </v-btn>

                                    <v-btn small class="babyViewActionBtn ml-3"
                                           @click="clickDownloadTeacherUpload(item)">
                                        <img :src="runterladenIcon" class="babyViewActionBtnImg"/>
                                    </v-btn>

                                    <v-btn small class="babyViewActionBtn ml-3" @click="pupilUploadFile">
                                        <img :src="kameraIcon" class="babyViewActionBtnImg"/>
                                    </v-btn>
                                    <v-btn
                                            @click="play(item)" small class="babyViewActionBtn edit ml-3" dark :loading="videoLoading"
                                            v-if="['mp4', 'wmv', 'flv', 'webm', 'mpg', 'm4v'].includes(item.title.split('.').pop())"
                                    >
                                        <img :src="rechtsIcon" class="babyViewActionBtnImg editImg" />
                                    </v-btn>
                                    <v-btn v-else-if="['doc', 'docx', 'ppt', 'pptx', 'xls', 'xlsx'].includes(item.title.split('.').pop().toLowerCase())"
                                            dark @click="fileNotSupported(item)" x-small class="babyViewActionBtn edit ml-3" elevation="0" style="background-color: #8CBD46;" >
                                        <img :src="infoIcon" class="babyViewActionBtnImg editImg"/>
                                    </v-btn>
                                    <v-btn v-else small class="babyViewActionBtn edit ml-3" @click="openEdit(item)">
                                        <img :src="bearbeitenIcon" class="babyViewActionBtnImg editImg"/>
                                    </v-btn>
                                </div>
                            </div>

                            <!--expand and collapse btns-->
                            <div v-if="babyFilesToDo.length > 3" class="d-flex justify-center my-2">
                                <v-btn v-if="!this.toDoLong" @click="unsliceList('toDo')" small elevation="0">
                                    <img :src="arrowDown" style="height: 20px;"/>
                                </v-btn>

                                <v-btn v-else @click="sliceList('toDo')" small elevation="0">
                                    <img :src="arrowDown" style="height: 20px; transform: rotate(180deg)"/>
                                </v-btn>
                            </div>

                            <v-row
                                v-if="babyFilesToDo && babyFilesToDo.length === 0"
                                class="emptyRow ma-2"
                                align="center" justify="center"
                            >
                                <v-col class="pa-0">
                                    <p>Aktuell gibt es nichts zu erledigen</p>
                                </v-col>
                            </v-row>
                        </div>

                        <hr style="width: 95%; margin: auto">

                        <div>
                            <v-row cols="12" style="width: 99%; margin: 0.5em auto 0.5em auto;">
                                <v-col style="display: flex; align-items: center" class="sectionHeaderCol">
                                    <img :src="doneIcon" style="height: 30px" class="sectionHeaderImg"/>
                                    <p class="pl-2 sectionHeaderText">Erledigt:</p>
                                </v-col>
                            </v-row>

                            <div v-for="(item, index) in babyFilesDoneDisplay" :key="index"
                                   class="fileRow ma-2 disabledFileRow d-flex align-center" style="flex-direction: row" :class="{ brighterFileRow: index % 2 === 1 }">

                                <!-- Fach icon -->
                                <v-btn small class="ml-2 smallBabyViewActionBtn fachLogo" disabled
                                       :style="`background-color: ${item.fachColor} !important`">
                                    <img :src="getSrc(item.fachIcon)" class="smallBabyViewActionBtnImg"/>
                                </v-btn>

                                <!--colored line instead of icon if width < 600-->
                                <div class="fachLine ma-0 pa-0" :style="`background-color: ${item.fachColor} !important`">
                                </div>

                                <div class="fileTitle mx-2" @click="readExerciseName(item)">
                                    {{ item.title }}
                                </div>

                                <div class="ma-0 mr-2" style="display: flex">
                                    <v-btn small class="babyViewActionBtn" v-if="false">
                                        <img :src="lautsprecherIcon" class="babyViewActionBtnImg"/>
                                    </v-btn>

                                    <v-btn small class="babyViewActionBtn ml-3"
                                           @click="downloadCorrectFile(item)">
                                        <img :src="runterladenIcon" class="babyViewActionBtnImg"/>
                                    </v-btn>
                                </div>
                            </div>

                            <!--expand and collapse btns-->
                            <div v-if="babyFilesDone.length > 3" class="d-flex justify-center my-2">
                                <v-btn v-if="!this.doneLong" @click="unsliceList('done')" small elevation="0">
                                    <img :src="arrowDown" style="height: 20px;"/>
                                </v-btn>

                                <v-btn v-else @click="sliceList('done')" small elevation="0">
                                    <img :src="arrowDown" style="height: 20px; transform: rotate(180deg)"/>
                                </v-btn>
                            </div>

                            <v-row
                                v-if="babyFilesDone && babyFilesDone.length === 0"
                                class="emptyRow ma-2"
                                align="center" justify="center"
                            >
                                <v-col class="pa-0">
                                    <p>Aktuell gibt es noch nichts Erledigtes</p>
                                </v-col>
                            </v-row>
                        </div>

                        <hr style="width: 95%; margin: auto">

                        <div>
                            <v-row cols="12" style="width: 99%; margin: 0.5em auto 0.5em auto;">
                                <v-col style="display: flex; align-items: center" class="sectionHeaderCol">
                                    <img :src="correctedIcon" style="height: 30px" class="sectionHeaderImg"/>
                                    <p class="pl-2 sectionHeaderText">Korrigiert:</p>
                                </v-col>
                            </v-row>

                            <div v-for="(item, index) in babyFilesCorrectedDisplay" :key="index" class="fileRow ma-2 d-flex align-center"
                                   :class="{ brighterFileRow: index % 2 === 1, disabledFileRow: false }">

                                <!--Fach icon-->
                                <v-btn small class="ml-2 smallBabyViewActionBtn fachLogo" disabled
                                       :style="`background-color: ${item.fachColor} !important`">
                                    <img :src="getSrc(item.fachIcon)" class="smallBabyViewActionBtnImg"/>
                                </v-btn>

                                <!--colored line instead of icon if width < 600-->
                                <div class="fachLine ma-0 pa-0" :style="`background-color: ${item.fachColor} !important`">
                                </div>

                                <div class="fileTitle mx-2" @click="readExerciseName(item)">
                                    {{ item.title }}
                                </div>

                                <div class="ma-0 mr-2" style="display: flex">
                                    <v-btn small class="babyViewActionBtn" v-if="false">
                                        <img :src="lautsprecherIcon" class="babyViewActionBtnImg"/>
                                    </v-btn>

                                    <v-btn small class="babyViewActionBtn ml-3"
                                           @click="clickDownloadTeacherUpload(item)">
                                        <img :src="runterladenIcon" class="babyViewActionBtnImg"/>
                                    </v-btn>
                                </div>
                            </div>

                            <!--expand and collapse btns-->
                            <div v-if="babyFilesCorrected.length > 3" class="d-flex justify-center my-2">
                                <v-btn v-if="!this.correctedLong" @click="unsliceList('corrected')" small elevation="0">
                                    <img :src="arrowDown" style="height: 20px;"/>
                                </v-btn>

                                <v-btn v-else @click="sliceList('corrected')" small elevation="0">
                                    <img :src="arrowDown" style="height: 20px; transform: rotate(180deg)"/>
                                </v-btn>
                            </div>

                            <v-row
                                v-if="babyFilesCorrected && babyFilesCorrected.length === 0"
                                class="emptyRow ma-2"
                                align="center" justify="center"
                            >
                                <v-col class="pa-0">
                                    <p>Aktuell gibt es nichts Korrigiertes</p>
                                </v-col>
                            </v-row>
                        </div>
                    </vue-custom-scrollbar>
                </div>
            </v-col>
        </v-row>

        <v-overlay opacity="0.95" v-if="videoUrl" z-index="95">
            <v-icon large style="position: fixed; top: 50px; right: 50px; z-index: 99" @click="videoUrl = false" dark>
                fas fa-times
            </v-icon>
            <video :src="videoUrl" controls :width="windowWidth > 900 ? '80%' : '40%'"
                   :style="{ marginLeft: windowWidth > 900 ? '10%' : '55%' }">
                Your browser does not support the video tag.
            </video>
        </v-overlay>

        <v-snackbar bottom v-model="snackbarFileNotSupported" color="grey" timeout="5000" style="z-index: 9999 !important">
            Diese Datei wird vom Editor nicht unterstützt. Bitte lade sie herunter.
        </v-snackbar>
    </div>
</template>

<script>
import vueCustomScrollbar from "vue-custom-scrollbar";
import teacherIcon from '../../assets/Icons/lehrer-24.svg'
import teacherPic from '../../assets/Bilder/Jugendherberge-Klassenfahrt-Lehrer-erzaehlen-Grundschule-e1510042530178.png'
import bearbeitenIcon from '../../assets/Icons/bearbeiten-komplimentär-weiß-88.svg'
import linksIcon from '../../assets/Icons/links-10.svg'
import rechtsIcon from '../../assets/Icons/rechts-11.svg'
import schliessenIcon from '../../assets/Icons/abbrechen-08.svg'
import hausaufgabenIconLeer from '../../assets/Icons/leere-korrektur-78.svg'
import runterladenIcon from '../../assets/Icons/runterladen-14.svg'
import lautsprecherIcon from '../../assets/Icons/lautsprecher-13.svg'
import kameraIcon from '../../assets/Icons/kamera-21.svg'
import toDoIcon from '../../assets/Icons/noch-zu-machen-orange-89.svg'
import doneIcon from '../../assets/Icons/erledigt-orange-90.svg'
import correctedIcon from '../../assets/Icons/korrigiert-orange-91.svg'
import arrowDown from '../../assets/Icons/unten-dropdown-12.svg'
import infoIcon from "../../assets/Icons/info-45.svg";

import bioIcon from '../../assets/Icons/f-biologie-35.svg'
import chemieIcon from '../../assets/Icons/f-chemie-37.svg'
import deutschIcon from '../../assets/Icons/f-deutsch-29.svg'
import englischIcon from '../../assets/Icons/f-englisch-30.svg'
import erdkundeIcon from '../../assets/Icons/f-erdkunde-31.svg'
import geschichteIcon from '../../assets/Icons/f-geschichte-40.svg'
import matheIcon from '../../assets/Icons/f-mathe-32.svg'
import musikIcon from '../../assets/Icons/f-musik-84.svg'
import physikIcon from '../../assets/Icons/f-physik-36.svg'
import politikIcon from '../../assets/Icons/f-politik-34.svg'
import religionIcon from '../../assets/Icons/f-religion-39.svg'
import sportIcon from '../../assets/Icons/f-sport-38.svg'
import wirtschaftIcon from '../../assets/Icons/f-wirtschaft-33.svg'

import {mapActions, mapGetters, mapState} from "vuex";
import * as backend from "../../api/backend";
import {tutorials} from '@/constants/tutorial';
import ProgressBar from "../ProgressBar";
import translation from "../../util/translate";

export default {
    name: "HausiWidgetBaby",
    components: {
        vueCustomScrollbar,
        ProgressBar,
    },
    data() {
        return {
            windowWidth: window.innerWidth,
            settings: {
                suppressScrollY: false,
                suppressScrollX: true,
                wheelPropagation: false,
            },
            showPupilUploadProgress: false,
            pupilUploadProgress: 0.0,
            pupilXmlHttpRequest: null,
            appointments: [],

            /*babyFiles: null,*/ /*seems to be useless..?*/

            babyFilesToDo: null,
            babyFilesCorrected: null,
            babyFilesDone: null,

            /*to toggle btwn showin 3 items and whole list*/
            babyFilesToDoDisplay: null,
            babyFilesCorrectedDisplay: null,
            babyFilesDoneDisplay: null,
            toDoLong: false,
            doneLong: false,
            correctedLong: false,
            snackbarFileNotSupported: false,
            showLoading: false,
            teacherIcon,
            infoIcon,
            teacherPic,
            bearbeitenIcon,
            linksIcon,
            rechtsIcon,
            schliessenIcon,
            hausaufgabenIconLeer,
            runterladenIcon,
            lautsprecherIcon,
            kameraIcon,
            toDoIcon,
            doneIcon,
            correctedIcon,
            arrowDown,
            fachIcons: [
                {name: 'bioIcon', icon: bioIcon},
                {name: 'chemieIcon', icon: chemieIcon},
                {name: 'deutschIcon', icon: deutschIcon},
                {name: 'englischIcon', icon: englischIcon},
                {name: 'erdkundeIcon', icon: erdkundeIcon},
                {name: 'geschichteIcon', icon: geschichteIcon},
                {name: 'matheIcon', icon: matheIcon},
                {name: 'musikIcon', icon: musikIcon},
                {name: 'physikIcon', icon: physikIcon},
                {name: 'politikIcon', icon: politikIcon},
                {name: 'religionIcon', icon: religionIcon},
                {name: 'sportIcon', icon: sportIcon},
                {name: 'wirtschaftIcon', icon: wirtschaftIcon},
            ],
            tutorials,
            account: '',
            videoUrl: null,
            videoLoading: false,
        };
    },
    props: {
        small: {required: false, default: false},
    },
    async created() {
        this.account = (await this.getMe()).account;
        await this.getAllTeacherFiles();
        this.sliceLists();
    },
    computed: {
        ...mapState("auth", ["token"]),
        ...mapState('translation', ['targetLang']),
        ...mapGetters('pupils', ['getTutorialProgress']),
        getTutProgress() {
            if (!this.account)
                return [];
            return this.getTutorialProgress[this.account] || [];
        },
    },
    async beforeMount() {
        this.account = (await this.getMe()).account;
    },
    async mounted() {
        this.account = (await this.getMe()).account;
        this.requestAppointments();
    },
    methods: {
        ...mapActions("appointments", ["getAppointments"]),
        ...mapActions('pupils', [
            'setTutorialProgress',
            'createPupil',
            'getMe',
        ]),
        ...mapActions('translation', [ 'setTranslatedText', 'showTranslation' ]),

        async finishTut(progress) {
            this.setTutorialProgress({account: this.account, progress});
        },

        async clickDownloadTeacherUpload(file) {
            file.seenByMe = true;
            const res = await backend.getTeacherUpload(file.appointmentId, file.file);
            fetch(res.url, {
                method: 'GET',
                headers: new Headers({
                    "Authorization": "Bearer " + this.token
                })
            })
                .then(response => response.blob())
                .then(blob => {
                    var url = window.URL.createObjectURL(blob);
                    var a = document.createElement('a');
                    a.href = url;
                    a.download = file.title;
                    document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                    a.click();
                    a.remove();  //afterwards we remove the element again
                });
        },

        getSrc(icon) {
            const foundItem = this.fachIcons.find(item => item.name === icon);
            return foundItem.icon;
        },

        async requestAppointments() {
            this.appointments = await this.getAppointments();
        },

        getAllTeacherFiles() {
            this.showLoading = true;

            this.babyFilesToDo = [];
            this.babyFilesCorrected = [];
            this.babyFilesDone = [];
            this.getAppointments().then((appointments) => {
                for (let i = 0; i < appointments.length; i++) {
                    const appointment = appointments[i];
                    if (appointment.teacherUploads.length) {
                        for (let j = 0; j < appointment.teacherUploads.length; j++) {
                            const element = appointment.teacherUploads[j];
                            element.fachIcon = appointment.schoolSubject.icon;
                            element.fachColor = appointment.schoolSubject.color;
                            element.groupName = appointment.schoolSubject.name;
                            element.appointmentId = appointment._id;
                            element.isDone = appointment.teacherUploads[j].editLeafs.length > 0;

                            if (this.isOpen(appointment, element)) {
                                this.babyFilesToDo.push(element);
                            } else if (element.editParent && !element.seenByMe) {
                                this.babyFilesCorrected.push(element);
                            } else {
                                this.babyFilesDone.push(element);
                            }
                        }
                    }
                }
                this.sliceLists();
                this.showLoading = false;
            });
        },
        isOpen(appointment, element) {
            const checkingLeafs = appointment.pupilUpload.filter(
                upload => upload.uploader === this.account && element.editLeafs.includes(upload._id)
            );

            return checkingLeafs.length === 0 && !element.editParent;

        },
        isDone(appointment, icon) {
            //check if file belongs to user by comparing uploaderId and accountId
            const newArray = appointment.pupilUpload.filter(upload => upload.uploader === this.account);

            for(let i = 0; i < newArray.length; i++) {
                if (!this.babyFilesDone.includes(newArray[i])) {
                    newArray[i].appointment = appointment._id;
                    newArray[i].fachIcon = icon;

                    this.babyFilesDone.push(newArray[i]);
                }
                return true;
            }
            return false;
        },
        isCorrectionAvailable(appointment) {
            const newArray = appointment.pupilUpload.filter(upload => upload.uploader === this.account);

            for(let i = 0; i < newArray.length; i++) {
                for (let j = 0; j < appointment.teacherUploads.length; j++) {
                    //is teacherUploadId (correction) same as pupilUploads editLeaf
                    if ((appointment.teacherUploads[j]._id).toString() === (newArray[i].editLeafs).toString()) {
                        if (!this.babyFilesCorrected.includes(appointment.teacherUploads[j])) {
                            this.babyFilesCorrected.push(appointment.teacherUploads[j]);
                        }
                            return true;
                    }
                }
            }
            return false;
        },
        async downloadCorrectFile(file) {

            if (file.editParent && !file.editLeafs.length) {
            // File is Correction and seen by user
                await this.clickDownloadTeacherUpload(file);
            } else {
            // File is pupil upload that is not corrected
                await this.clickDownloadPupilUpload(file);

            }
        },

        async clickDownloadPupilUpload(file) {
            // find pupil upload
            const neededAppointment = this.appointments.filter(app => app._id === file.appointmentId)[0];

            const neededPupilUpload = neededAppointment.pupilUpload.filter(
                upload => upload.uploader === this.account && file.editLeafs.includes(upload._id)
            )[0];

            const res = await backend.getPupilUpload(file.appointmentId, neededPupilUpload.file);
            fetch(res.url, {
                method: 'GET',
                headers: new Headers({
                    "Authorization": "Bearer " + this.token
                })
            })
                .then(response => response.blob())
                .then(blob => {
                    var url = window.URL.createObjectURL(blob);
                    console.log(url);
                    var a = document.createElement('a');
                    a.href = url;
                    a.download = neededPupilUpload.title;
                    document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                    a.click();
                    a.remove();  //afterwards we remove the element again
                });
        },

        async play(file) {
            file.seenByMe = true;
            this.videoLoading = true;
            const res = await backend.getTeacherUpload(file.appointmentId, file.file);
            fetch(res.url, {
                method: 'GET',
                headers: new Headers({
                    "Authorization": "Bearer " + this.token
                })
            })
                .then(response => response.blob())
                .then(blob => {
                    console.log(blob);
                    var url = window.URL.createObjectURL(blob);
                    console.log(url);
                    this.videoUrl = url;
                    this.videoLoading = false;
                });

        },

        openEdit(item) {
            const title = item.title;
            const file = item.file;
            const id = item._id;
            const group = item.groupName;
            const color = item.fachColor;
            const appointmentId = item.appointmentId;

            this.$router.push({
                name: 'schueler.edit',
                query:
                    {
                        group: group,
                        color: color,
                        aId: appointmentId,
                        title: title,
                        fId: file,
                        uploadid: id
                    }
            });
        },

        getAppointment(slot, day) {
            switch (day) {
                case 'mon':
                    day = this.getMonday(this.date);
                    break;
                case 'die':
                    day = this.getTuesday(this.date);
                    break;
                case 'mit':
                    day = this.getWednesday(this.date);
                    break;
                case 'don':
                    day = this.getThursday(this.date);
                    break;
                case 'fre':
                    day = this.getFriday(this.date);
                    break;
            }
            return this.appointments.find((el) => {
                return (el.timeslot === slot && this.sameDay(new Date(el.day), day))
            })
        },

        countBadges() {
            this.future = 0;
            this.past = 0;
            this.appointments.forEach((el) => {
                if (!this.sameDay(new Date(el.day), this.date)) {
                    if (new Date(el.day) > this.date) {
                        this.future += el.pupilUpload.filter((element) => !element.seenByMe).length;
                    } else {
                        this.past += el.pupilUpload.filter((element) => !element.seenByMe).length;
                    }
                }
            })
        },

        async readExerciseName(item) {
            if ('speechSynthesis' in window) {
                // Speech Synthesis supported 🎉
            } else {
                // Speech Synthesis Not Supported 😣
                alert("Sorry, your browser doesn't support text to speech!");
            }

            let text = item.title;

            if (this.targetLang !== 'de') {
                text = await translation.translateToTargetLang(this.targetLang, text);

                this.setTranslatedText(text);
                this.showTranslation(true);

                if (this.showTextTimeout) {
                    clearTimeout(this.showTextTimeout);
                    this.showTextTimeout = null;
                }

                this.showTextTimeout = setTimeout(() => {
                    this.showTranslation(false);
                    this.showTextTimeout = null;
                }, 15000)
            }

            if(window.speechSynthesis.speaking) {
                window.speechSynthesis.cancel()
            } else {
                let msg = new SpeechSynthesisUtterance();
                msg.text = text;
                if (this.isLangPackageAvailable()) {
                    msg.lang = this.targetLang;
                    window.speechSynthesis.speak(msg);
                }

            }
        },
        isLangPackageAvailable() {
            for (let i = 0; i < window.speechSynthesis.getVoices().length; i++) {
                if (window.speechSynthesis.getVoices()[i].lang.includes(this.targetLang)) {
                    return true;
                }
            }

            return false;
        },
        pupilUploadFile() {
            document.getElementById('hausiPupilUploadInput').click();
        },

        async pupilUploadInputChange(appointmentId, teacherFileId) {
            const input = this.$refs.hausiPupilUploadInput;
            const file = input.files[0];
            if (file) {
                const name = file.name;
                const lastDot = name.lastIndexOf('.');
                const fileName = name.substring(0, lastDot);
                const fileExtension = name.substring(lastDot + 1);
                // this.uploadFileName = fileName;
                // this.fileNameCallback = (newFileName) => {
                //     this.showNameFile = false;
                // };
                // this.showNameFile = true;
                await this.uploadFile(appointmentId, teacherFileId, fileName, fileExtension);
            }
        },

        async uploadFile(appointmentId, teacherFileId, fileName, fileExtension) {
            const input = this.$refs.hausiPupilUploadInput;
            const file = input.files[0];
            if (file) {
                const formData = new FormData();
                formData.append('file', file, `${fileName}.${fileExtension}`);
                this.pupilXmlHttpRequest = backend.postTeacherUploadEdit(appointmentId, teacherFileId, file);

                this.pupilXmlHttpRequest.onerror = (e) => {
                    console.error('Pupil upload error:', e)
                    this.showPupilUploadProgress = false;
                    this.pupilXmlHttpRequest = null;
                    this.$refs.hausiPupilUploadInput.value = '';
                };

                this.pupilXmlHttpRequest.onabort = (e) => {
                    console.warn('Pupil upload aborted');
                    this.showPupilUploadProgress = false;
                    this.pupilXmlHttpRequest = null;
                    this.$refs.hausiPupilUploadInput.value = '';
                }

                this.pupilXmlHttpRequest.upload.addEventListener('progress', (e) => {
                    this.pupilUploadProgress = (e.loaded / e.total) * 100;
                });

                this.pupilXmlHttpRequest.addEventListener('load', (e) => {
                    if (this.pupilXmlHttpRequest.status !== 201)
                        console.error('Pupil upload failed:', this.pupilXmlHttpRequest.response);
                    this.showPupilUploadProgress = false;
                    this.snackbarSave = true;
                    this.requestAppointments();
                    this.pupilXmlHttpRequest = null;
                    this.$refs.hausiPupilUploadInput.value = '';
                });
                this.pupilUploadProgress = 0.0;
                this.showPupilUploadProgress = true;
                this.pupilXmlHttpRequest.send(formData);
            }
        },

        sliceLists() {
          this.babyFilesToDoDisplay = this.babyFilesToDo.slice(0,3);
          this.babyFilesDoneDisplay = this.babyFilesDone.slice(0,3);
          this.babyFilesCorrectedDisplay = this.babyFilesCorrected.slice(0,3);
        },

        unsliceList(list) {
            if (list === 'toDo') {
                this.babyFilesToDoDisplay = this.babyFilesToDo;
                this.toDoLong = true;
            }
            else if (list === 'done') {
                this.babyFilesDoneDisplay = this.babyFilesDone;
                this.doneLong = true;
            }
            else {
                this.babyFilesCorrectedDisplay = this.babyFilesCorrected;
                this.correctedLong = true;
            }
        },
        fileNotSupported(el) {
            this.snackbarFileNotSupported = true;
        },
        sliceList(list) {
            if (list === 'toDo') {
                this.babyFilesToDoDisplay = this.babyFilesToDo.slice(0,3);
                this.toDoLong = false;
            }
            else if (list === 'done') {
                this.babyFilesDoneDisplay = this.babyFilesDone.slice(0,3);
                this.doneLong = false;
            }
            else {
                this.babyFilesCorrectedDisplay = this.babyFilesCorrected.slice(0,3);
                this.correctedLong = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
table, th, td {
    border: none;
    border-collapse: collapse;
}

tr {
    height: 40px;
}


th {
    vertical-align: top;
}

table tr th:first-child,
table tr td:first-child {
    padding-top: 10px;
    width: 10px !important;
}

table tr th:nth-child(2),
table tr td:nth-child(2) {
    width: 5px !important;
}

table tr th:last-child,
table tr td:last-child {
    width: 5px !important;
}

table tr th,
table tr td {
    width: 9em;
    border: none;
}

table, tr, td {
    border: none;
}

#hausiWidgetBabyContainer {
    height: auto;
    width: 90%;
    min-height: 80vh;
    border-radius: 15px;
    box-shadow: 1px 5px 5px silver;
    margin: auto auto 5em;
    background-color: white;
}

#hausiWidgetHeader {
    background-color: #F49F31;
    border-radius: 15px 15px 0 0;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
    color: white;
}

.fach {
    cursor: pointer;
}

.scroll-area {
    position: relative;
    margin: auto;
    max-height: calc(80vh - 75px);
    overflow: hidden;
}

.fileRow {
    /*margin: 0.5em auto 0.5em auto;*/
    background-color: rgb(112 112 112 / 15%);
    border-radius: 5px;
    height: 76px !important;
}

.emptyRow {
    background-color: transparent;
    border-radius: 5px;
    height: 35px !important;

    & p {
        text-align: center;
        width: 100%;
        color: #70707090;
        margin-bottom: 0;
    }
}

.brighterFileRow {
    background-color: rgb(214 214 214 / 15%);
}

.disabledFileRow {
    opacity: 0.5;
}

.fileTitle {
    margin-bottom: 0 !important;
    font-size: 25px;
    color: #707070;
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.babyViewActionBtn {
    min-width: 50px !important;
    width: 50px !important;
    height: 50px !important;
}

.fachLine {
    display: none;
}

.sectionHeaderText {
    color: #F49F31;
    font-weight: 500;
    font-size: 28px;
    margin-bottom: 0;
    display: inline-block;
}

.sectionHeaderCol {
    padding-top: 0;
    padding-bottom: 0;
}

@media screen and (max-width: 600px) {
    .sectionHeaderText {
        font-size: 24px;
    }

    .sectionHeaderImg {
        height: 24px !important;
    }

    .babyViewActionBtn {
        margin-left: 4px !important;
        min-width: 30px !important;
        width: 30px !important;
        height: 30px !important;
    }

    .babyViewActionBtnImg {
        max-width: 24px !important;
        max-height: 24px !important;
        width: 24px !important;
        height: 24px !important;
    }

    .fachLogo {
        display: none;
    }

    .fachLine {
        display: block;
        min-width: 10px;
        max-width: 10px;
        height: 100%;
        border-radius: 5px 0 0 5px;
    }

    .fileRow {
        height: 40px !important;
    }

    .fileTitle {
        font-size: 16px;
    }
}


.smallBabyViewActionBtn {
    min-width: 40px !important;
    width: 40px !important;
    height: 40px !important;
}

.babyViewActionBtnImg {
    width: 35px !important;
    height: 35px !important;
}

.smallBabyViewActionBtnImg {
    width: 28px !important;
    height: 28px !important;
    filter: brightness(1000%);
}

.editImg {
    filter: brightness(1000%);
}

.edit {
    background-color: #8CBD46 !important;
}
</style>
