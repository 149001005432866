import { render, staticRenderFns } from "./FachVerwaltung.vue?vue&type=template&id=6010f3ef&scoped=true&"
import script from "./FachVerwaltung.vue?vue&type=script&lang=js&"
export * from "./FachVerwaltung.vue?vue&type=script&lang=js&"
import style0 from "./FachVerwaltung.vue?vue&type=style&index=0&id=6010f3ef&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6010f3ef",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCard,VCardActions,VCardText,VCardTitle,VCheckbox,VCol,VDialog,VIcon,VMenu,VRadio,VRadioGroup,VRow,VSnackbar,VSpacer})


/* hot reload */
if (module.hot) {
  var api = require("/home/peternauroth/homeschooling/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6010f3ef')) {
      api.createRecord('6010f3ef', component.options)
    } else {
      api.reload('6010f3ef', component.options)
    }
    module.hot.accept("./FachVerwaltung.vue?vue&type=template&id=6010f3ef&scoped=true&", function () {
      api.rerender('6010f3ef', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Verwaltung/FachVerwaltung.vue"
export default component.exports