var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { "max-height": "100vh" } },
    [
      _c(
        "div",
        { attrs: { id: "qrCodeUI" } },
        [
          _c(
            "v-btn",
            {
              staticClass: "mr-0",
              attrs: { "x-small": "", id: "qrCodeClose" },
              on: { click: _vm.closeFunction }
            },
            [
              _c("img", {
                staticStyle: { height: "40px", filter: "brightness(1000%)" },
                attrs: { src: _vm.closeIcon }
              })
            ]
          ),
          _vm._m(0)
        ],
        1
      ),
      _vm.codeScanActive
        ? _c(
            "qrcode-stream",
            {
              ref: "qrCodeScanner",
              staticClass: "qrCodeScanner",
              staticStyle: {
                "max-height": "100vh !important",
                overflow: "hidden"
              },
              on: { decode: _vm.onDecode, init: _vm.onInit }
            },
            [
              _c("div", { staticClass: "qrCodeVisorContainer" }, [
                _c("div", { staticClass: "qrCodeVisor" }, [
                  _c("div", [
                    _c("img", {
                      attrs: {
                        src: require("../assets/Bilder/sample_qrvisor.png")
                      }
                    })
                  ])
                ])
              ])
            ]
          )
        : _vm._e(),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: "5000", color: "error" },
          model: {
            value: _vm.snackbars.codeNotValid,
            callback: function($$v) {
              _vm.$set(_vm.snackbars, "codeNotValid", $$v)
            },
            expression: "snackbars.codeNotValid"
          }
        },
        [_vm._v(" QRCode nicht gültig! ")]
      ),
      _c(
        "v-snackbar",
        {
          attrs: {
            timeout: "-1",
            color: "error",
            "min-width": "80%",
            "max-width": "80%"
          },
          scopedSlots: _vm._u([
            {
              key: "action",
              fn: function(ref) {
                var attrs = ref.attrs
                return [
                  _c(
                    "v-btn",
                    _vm._b(
                      {
                        staticStyle: { "text-transform": "inherit" },
                        attrs: { color: "white", text: "", outlined: "" },
                        on: { click: _vm.restartScan }
                      },
                      "v-btn",
                      attrs,
                      false
                    ),
                    [_vm._v(" Nochmal versuchen ")]
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.snackbars.cameraInUse,
            callback: function($$v) {
              _vm.$set(_vm.snackbars, "cameraInUse", $$v)
            },
            expression: "snackbars.cameraInUse"
          }
        },
        [
          _vm._v(
            " Fehler beim Kamerazugriff! Wird die Kamera aktuell noch in einer anderen App verwendet? "
          )
        ]
      ),
      _c(
        "v-snackbar",
        {
          attrs: {
            timeout: "-1",
            color: "error",
            "min-width": "80%",
            "max-width": "80%"
          },
          scopedSlots: _vm._u([
            {
              key: "action",
              fn: function(ref) {
                var attrs = ref.attrs
                return [
                  _c(
                    "v-btn",
                    _vm._b(
                      {
                        staticStyle: { "text-transform": "inherit" },
                        attrs: { color: "white", text: "", outlined: "" },
                        on: { click: _vm.restartScan }
                      },
                      "v-btn",
                      attrs,
                      false
                    ),
                    [_vm._v(" Nochmal versuchen ")]
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.snackbars.cameraNotFound,
            callback: function($$v) {
              _vm.$set(_vm.snackbars, "cameraNotFound", $$v)
            },
            expression: "snackbars.cameraNotFound"
          }
        },
        [
          _vm._v(
            " Das Gerät scheint keine nutzbare Kamera zu besitzen. Bitte schließe eine an oder wechsel das Gerät. "
          )
        ]
      ),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: "-1", color: "error" },
          model: {
            value: _vm.snackbars.genericError,
            callback: function($$v) {
              _vm.$set(_vm.snackbars, "genericError", $$v)
            },
            expression: "snackbars.genericError"
          }
        },
        [_vm._v(" Fehler! " + _vm._s(_vm.errorName) + "! ")]
      ),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: "-1", color: "error" },
          model: {
            value: _vm.snackbars.cameraPermission,
            callback: function($$v) {
              _vm.$set(_vm.snackbars, "cameraPermission", $$v)
            },
            expression: "snackbars.cameraPermission"
          }
        },
        [_vm._v(" Fehler! " + _vm._s(_vm.errorName) + "! ")]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "qrCodeVisorContainer" }, [
      _c("div", { staticClass: "qrCodeVisor" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }