const state = {
  // # Axios ###################
  axiosInstance: null,
  // #################### Axios #


  serverAddress: 'http://localhost:8092',
  //serverAddress: 'https://backend.concept-hero.de',
};


export default state;
