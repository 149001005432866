import * as backend from '../../api/backend';
import { checkResponseStatus } from '../../util/check';


const defaultState = {
};

const mutations = {
};

const actions = {
  async createPrivateRoom ({ commit, dispatch }, params) {
    try {
      const res = await backend.postPrivateRoom(params);
      await checkResponseStatus(201, res);
    }
    catch(err) {
      return err.response.status;
    }
  },
};

export default {
  namespaced: true,
  state: defaultState,
  mutations,
  actions,
};
