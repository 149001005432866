const mutations = {
  // # Axios ###################
  SET_AXIOS_INSTANCE: (state, axiosInstance) => {
    state.axiosInstance = axiosInstance;
  },
  // #################### Axios #


};

export default mutations;
