var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-row", { staticClass: "mx-0" }, [
        _c(
          "div",
          {
            staticClass: "whiteBg",
            staticStyle: {
              height: "auto",
              "min-width": "100px !important",
              width: "90%",
              "min-height": "80vh",
              "border-radius": "15px",
              "box-shadow": "1px 5px 5px silver",
              margin: "auto auto 5em"
            }
          },
          [
            _c(
              "div",
              {
                staticStyle: {
                  "background-color": "#707070",
                  "border-radius": "15px 15px 0 0",
                  "padding-top": "10px",
                  "padding-bottom": "10px",
                  color: "white"
                }
              },
              [
                _c(
                  "v-row",
                  {
                    staticClass: "pr-2 ml-2",
                    staticStyle: {
                      width: "90%",
                      display: "flex",
                      "align-items": "center",
                      height: "35px"
                    }
                  },
                  [
                    _c(
                      "v-col",
                      {
                        staticClass: "pa-0 pl-2 font-style d-flex align-center",
                        staticStyle: { "white-space": "nowrap" },
                        attrs: { cols: "9", md: "8", lg: "9" }
                      },
                      [
                        _c("img", {
                          staticClass: "iconToWhite mr-1",
                          staticStyle: { height: "20px" },
                          attrs: { src: _vm.brettIcon }
                        }),
                        _vm._v(" Schwarzes Brett ")
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "vue-custom-scrollbar",
              { staticClass: "scroll-area", attrs: { settings: _vm.settings } },
              [
                _c(
                  "div",
                  { key: _vm.updateBlackboard },
                  [
                    _vm.blackboard.length === 0
                      ? _c(
                          "div",
                          {
                            staticClass: "ma-4",
                            staticStyle: { "z-index": "0" }
                          },
                          [
                            _vm._v(
                              " Die Schule hat noch keine Einträge verfasst "
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm._l(
                      _vm.blackboard.filter(function(el) {
                        return el.pin
                      }),
                      function(item) {
                        return _c(
                          "v-card",
                          {
                            key: JSON.stringify(item),
                            staticClass: "ma-2",
                            staticStyle: { "z-index": "0" }
                          },
                          [
                            _c(
                              "v-badge",
                              {
                                staticStyle: { "z-index": "9", width: "100%" },
                                attrs: {
                                  color: "red",
                                  content: "1",
                                  value: !item.seenByMe,
                                  overlap: "",
                                  "offset-x": "15"
                                }
                              },
                              [
                                _c(
                                  "v-card-title",
                                  {
                                    staticClass: "pa-1",
                                    staticStyle: {
                                      color: "#707070",
                                      "background-color": "#EAEAEA",
                                      "max-width": "100%"
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex justify-space-between",
                                        staticStyle: { width: "100%" }
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "leftTitleText d-flex align-center"
                                          },
                                          [
                                            _c("img", {
                                              staticClass: "boardIcon mr-2",
                                              style: {
                                                "background-color": item.color
                                              },
                                              attrs: {
                                                src: _vm.chooseIcon(item.icon)
                                              }
                                            }),
                                            _c(
                                              "div",
                                              { staticClass: "ellipsis" },
                                              [_vm._v("Verwaltung")]
                                            )
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "RightTitleText d-flex justify-end align-center"
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "mr-2 timeAndDate"
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      height: "1.25em"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        new Date(
                                                          item.createdOn
                                                        ).toLocaleTimeString(
                                                          [],
                                                          {
                                                            hour: "2-digit",
                                                            minute: "2-digit"
                                                          }
                                                        )
                                                      )
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      height: "1.25em"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.dayname[
                                                          new Date(
                                                            item.createdOn
                                                          ).getDay()
                                                        ] +
                                                          ". " +
                                                          new Date(
                                                            item.createdOn
                                                          ).toLocaleDateString(
                                                            [],
                                                            {
                                                              day: "2-digit",
                                                              month: "2-digit"
                                                            }
                                                          )
                                                      )
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _vm.account.role === "pupil"
                                              ? _c(
                                                  "v-btn",
                                                  {
                                                    staticClass: "pa-0 mr-2",
                                                    staticStyle: {
                                                      "background-color":
                                                        "#F8F8F880 !important",
                                                      height: "35px"
                                                    },
                                                    attrs: { "x-small": "" },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.readText(
                                                          item.message,
                                                          $event
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c("img", {
                                                      staticStyle: {
                                                        height: "20px",
                                                        color: "#707070"
                                                      },
                                                      attrs: {
                                                        src:
                                                          _vm.lautsprecherIcon
                                                      }
                                                    })
                                                  ]
                                                )
                                              : _vm._e(),
                                            _c(
                                              "v-btn",
                                              {
                                                staticClass: "pa-0",
                                                staticStyle: {
                                                  height: "35px",
                                                  width: "35px"
                                                },
                                                style: {
                                                  "background-color": item.color
                                                },
                                                attrs: { "x-small": "" },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.pinBrettEintrag(
                                                      item._id
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c("img", {
                                                  staticClass: "iconToWhite",
                                                  staticStyle: {
                                                    height: "20px"
                                                  },
                                                  attrs: { src: _vm.pinIcon }
                                                })
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    )
                                  ]
                                ),
                                _c(
                                  "v-card-text",
                                  {
                                    staticClass: "pa-2",
                                    staticStyle: { "font-size": "medium" }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "fade blackboardVCardText",
                                        on: {
                                          click: function($event) {
                                            return _vm.expandText($event)
                                          }
                                        }
                                      },
                                      [_vm._v(" " + _vm._s(item.message) + " ")]
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      }
                    ),
                    _vm._l(
                      _vm.blackboard.filter(function(el) {
                        return !el.pin
                      }),
                      function(item) {
                        return _c(
                          "v-card",
                          {
                            key: JSON.stringify(item),
                            staticClass: "ma-2",
                            staticStyle: { "z-index": "0" }
                          },
                          [
                            _c(
                              "v-badge",
                              {
                                staticStyle: { "z-index": "9", width: "100%" },
                                attrs: {
                                  color: "red",
                                  content: "1",
                                  value: !item.seenByMe,
                                  overlap: "",
                                  "offset-x": "15"
                                }
                              },
                              [
                                _c(
                                  "v-card-title",
                                  {
                                    staticClass: "pa-1",
                                    staticStyle: {
                                      color: "#707070",
                                      "background-color": "#EAEAEA",
                                      "max-width": "100%"
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex justify-space-between",
                                        staticStyle: { width: "100%" }
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "leftTitleText d-flex align-center"
                                          },
                                          [
                                            _c("img", {
                                              staticClass: "boardIcon mr-2",
                                              style: {
                                                "background-color": item.color
                                              },
                                              attrs: {
                                                src: _vm.chooseIcon(item.icon)
                                              }
                                            }),
                                            _c(
                                              "div",
                                              { staticClass: "ellipsis" },
                                              [_vm._v("Verwaltung")]
                                            )
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "RightTitleText d-flex justify-end align-center"
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "mr-2 timeAndDate"
                                              },
                                              [
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass: "mb-0",
                                                    staticStyle: {
                                                      height: "1.25em"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        new Date(
                                                          item.createdOn
                                                        ).toLocaleTimeString(
                                                          [],
                                                          {
                                                            hour: "2-digit",
                                                            minute: "2-digit"
                                                          }
                                                        )
                                                      )
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass: "mb-0",
                                                    staticStyle: {
                                                      height: "1.25em"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.dayname[
                                                          new Date(
                                                            item.createdOn
                                                          ).getDay()
                                                        ] +
                                                          ". " +
                                                          new Date(
                                                            item.createdOn
                                                          ).toLocaleDateString(
                                                            [],
                                                            {
                                                              day: "2-digit",
                                                              month: "2-digit"
                                                            }
                                                          )
                                                      )
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _vm.account.role === "pupil"
                                              ? _c(
                                                  "v-btn",
                                                  {
                                                    staticClass: "pa-0 mr-2",
                                                    staticStyle: {
                                                      "background-color":
                                                        "#F8F8F880 !important",
                                                      height: "35px"
                                                    },
                                                    attrs: { "x-small": "" },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.readText(
                                                          item.message,
                                                          $event
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c("img", {
                                                      staticStyle: {
                                                        height: "20px",
                                                        color: "#707070"
                                                      },
                                                      attrs: {
                                                        src:
                                                          _vm.lautsprecherIcon
                                                      }
                                                    })
                                                  ]
                                                )
                                              : _vm._e(),
                                            _c(
                                              "v-btn",
                                              {
                                                staticClass: "pa-0",
                                                staticStyle: {
                                                  "background-color":
                                                    "#F8F8F880 !important",
                                                  height: "35px",
                                                  width: "35px"
                                                },
                                                attrs: { "x-small": "" },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.pinBrettEintrag(
                                                      item._id
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c("img", {
                                                  staticStyle: {
                                                    height: "20px",
                                                    color: "#707070"
                                                  },
                                                  attrs: { src: _vm.pinIcon }
                                                })
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    )
                                  ]
                                ),
                                _c("v-card-text", { staticClass: "pa-2" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "blackboardVCardText",
                                      staticStyle: {
                                        "font-size": "medium",
                                        color: "#444444"
                                      }
                                    },
                                    [_vm._v(_vm._s(item.message))]
                                  )
                                ])
                              ],
                              1
                            )
                          ],
                          1
                        )
                      }
                    )
                  ],
                  2
                )
              ]
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }