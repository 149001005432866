var render = function() {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-row", { staticClass: "mx-0 mt-3 mb-4" }, [
        _c(
          "div",
          {
            staticStyle: {
              height: "auto",
              width: "90%",
              margin: "auto",
              "border-radius": "15px",
              "box-shadow": "1px 5px 5px silver"
            }
          },
          [
            _c(
              "div",
              {
                staticStyle: {
                  "background-color": "#6995CD",
                  "border-radius": "15px 15px 0 0",
                  "font-size": "x-large",
                  "padding-top": "20px",
                  "padding-bottom": "13px",
                  "text-align": "center",
                  color: "white"
                }
              },
              [
                _c("div", { staticStyle: { display: "flex", width: "50%" } }, [
                  _c(
                    "div",
                    {
                      staticClass: "mt-1",
                      staticStyle: { "margin-left": "3%" }
                    },
                    [
                      _c(
                        "p",
                        {
                          staticClass: "ma-0",
                          staticStyle: { display: "inline-block" }
                        },
                        [_vm._v("Angelegte Schulzeiten")]
                      )
                    ]
                  )
                ])
              ]
            ),
            _c(
              "div",
              {
                staticStyle: {
                  display: "flex",
                  "font-size": "larger",
                  "font-family": "Roboto",
                  "margin-top": "24px",
                  "margin-left": "26px"
                }
              },
              [
                _c("img", {
                  staticStyle: { height: "24px", "margin-right": "10px" },
                  attrs: { src: _vm.schulStundenIcon }
                }),
                _vm._v(" Schulzeiten ")
              ]
            ),
            _c("v-data-table", {
              staticClass: "ma-3",
              attrs: {
                headers: _vm.headers,
                items: _vm.slots,
                search: _vm.search,
                "sort-by": "start",
                "disable-pagination": "",
                "hide-default-footer": "",
                "fixed-header": "",
                height: _vm.height,
                locale: "de-DE",
                "no-data-text": "Keine Daten verfügbar",
                "no-results-text":
                  "Keine übereinstimmenden Aufzeichnungen gefunden"
              },
              scopedSlots: _vm._u([
                {
                  key: "item",
                  fn: function(row) {
                    return [
                      _c("tr", [
                        _c("td", [
                          _vm._v(
                            _vm._s(
                              ("0" + new Date(row.item.start).getHours()).slice(
                                -2
                              ) +
                                ":" +
                                (
                                  "0" + new Date(row.item.start).getMinutes()
                                ).slice(-2)
                            )
                          )
                        ]),
                        _c("td", [
                          _vm._v(
                            _vm._s(
                              ("0" + new Date(row.item.end).getHours()).slice(
                                -2
                              ) +
                                ":" +
                                (
                                  "0" + new Date(row.item.end).getMinutes()
                                ).slice(-2)
                            )
                          )
                        ]),
                        _c(
                          "td",
                          [
                            _c(
                              "v-btn",
                              {
                                staticClass: "mx-2",
                                staticStyle: {
                                  "border-radius": "5px",
                                  "text-transform": "inherit"
                                },
                                attrs: {
                                  color: "#EAEAEA",
                                  elevation: "1",
                                  small: "",
                                  light: ""
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.edit(row.item)
                                  }
                                }
                              },
                              [_vm._v(" Slot bearbeiten ")]
                            )
                          ],
                          1
                        )
                      ])
                    ]
                  }
                }
              ])
            }),
            _c(
              "v-overlay",
              {
                staticStyle: { width: "100% !important" },
                attrs: { absolute: "", value: _vm.showLoadingOverlay }
              },
              [
                _vm.showLoadingOverlay
                  ? _c("v-progress-circular", {
                      attrs: { indeterminate: "", size: "100" }
                    })
                  : _vm._e()
              ],
              1
            )
          ],
          1
        )
      ]),
      _c("v-row", { staticClass: "mx-0 my-3", attrs: { id: "edit" } }, [
        _c(
          "div",
          { staticClass: "editContainer" },
          [
            _c(
              "v-row",
              {
                staticClass: "mx-0",
                staticStyle: {
                  "background-color": "#6995CD",
                  "border-radius": "15px 15px 0 0"
                }
              },
              [
                _c(
                  "v-col",
                  {
                    staticClass: "ma-1 ml-5",
                    staticStyle: { color: "white", "font-size": "larger" }
                  },
                  [
                    _c(
                      "v-avatar",
                      {
                        staticStyle: { width: "24px" },
                        attrs: { color: "rgba(255,255,255,0.8)" }
                      },
                      [_c("img", { attrs: { src: _vm.schulStundenIcon } })]
                    ),
                    _vm._v(
                      " Schulzeiten " +
                        _vm._s(_vm.oldSlot ? "bearbeiten" : "anlegen") +
                        " "
                    )
                  ],
                  1
                ),
                _c(
                  "v-col",
                  {
                    staticClass: "mr-6",
                    staticStyle: {
                      display: "flex",
                      "justify-content": "flex-end"
                    }
                  },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "mainFunctionButtons ml-3 my-auto",
                        attrs: {
                          color: "#FFFFFF40",
                          "x-small": "",
                          disabled: !_vm.oldSlot
                        },
                        on: {
                          click: function($event) {
                            _vm.deleteDialog = true
                          }
                        }
                      },
                      [_c("img", { attrs: { src: _vm.loeschIcon } })]
                    ),
                    _c(
                      "v-tooltip",
                      {
                        attrs: {
                          top: "",
                          "nudge-right": "100",
                          "open-on-click": true,
                          "open-on-hover": false,
                          "content-class": "infoTextToolTip"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        staticClass:
                                          "mainFunctionButtons ml-3 my-auto",
                                        attrs: {
                                          color: "#FFFFFF40",
                                          "x-small": ""
                                        }
                                      },
                                      "v-btn",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [_c("img", { attrs: { src: _vm.infoIcon } })]
                                )
                              ]
                            }
                          }
                        ])
                      },
                      [
                        _c("span", [
                          _vm._v(
                            "Hier können Sie Schulstunden für Ihre Schule anlegen. Geben Sie der Stunde einen Namen und legen Sie fest von wann bis wann die Stunde gehen soll. Die Stunde wird in allen Stundenplänen als festgelegte Uhrzeit angezeigt."
                          )
                        ])
                      ]
                    ),
                    _c(
                      "v-btn",
                      {
                        staticClass: "mainControlButtons ml-3 my-auto pa-5",
                        attrs: { color: "#BDBDBD50", dark: "", elevation: "0" },
                        on: { click: _vm.stopEdit }
                      },
                      [_c("span", [_vm._v("Abbrechen")])]
                    ),
                    _vm.oldSlot
                      ? _c(
                          "v-btn",
                          {
                            staticClass: "mainControlButtons ml-3 my-auto pa-5",
                            attrs: {
                              color: "#69CD70",
                              dark: "",
                              elevation: "4"
                            },
                            on: { click: _vm.editThisSlot }
                          },
                          [_c("span", [_vm._v("Speichern")])]
                        )
                      : _c(
                          "v-btn",
                          {
                            staticClass: "mainControlButtons ml-3 my-auto pa-5",
                            attrs: {
                              color: "#69CD70",
                              dark: "",
                              elevation: "4"
                            },
                            on: { click: _vm.saveSlot }
                          },
                          [_c("span", [_vm._v("Speichern")])]
                        )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "mx-2 mb-2" },
              [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { staticClass: "mx-5", attrs: { md: "" } },
                      [
                        _c(
                          "v-row",
                          [
                            _c("v-col", { attrs: { cols: "2" } }, [
                              _vm._v(" Von: ")
                            ]),
                            _c("v-col", { attrs: { cols: "4" } }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.from,
                                    expression: "from"
                                  }
                                ],
                                staticClass: "input",
                                class: { errorInput: _vm.fields.from },
                                staticStyle: { "margin-left": "-12px" },
                                attrs: { type: "time" },
                                domProps: { value: _vm.from },
                                on: {
                                  change: _vm.validateInput,
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.from = $event.target.value
                                  }
                                }
                              })
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { staticClass: "mx-5" },
                      [
                        _c(
                          "v-row",
                          [
                            _c("v-col", { attrs: { cols: "2" } }, [
                              _vm._v(" Bis: ")
                            ]),
                            _c("v-col", { attrs: { cols: "4" } }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.till,
                                    expression: "till"
                                  }
                                ],
                                staticClass: "input",
                                class: { errorInput: _vm.fields.till },
                                attrs: { type: "time" },
                                domProps: { value: _vm.till },
                                on: {
                                  change: _vm.validateInput,
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.till = $event.target.value
                                  }
                                }
                              })
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "400" },
          model: {
            value: _vm.deleteDialog,
            callback: function($$v) {
              _vm.deleteDialog = $$v
            },
            expression: "deleteDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "headline" }, [
                _vm._v("Zeitslot löschen?")
              ]),
              _c("v-card-text", [
                _vm._v(
                  " Sind sie sicher, dass sie den Zeitslot löschen möchten? "
                )
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "", text: "" },
                      on: {
                        click: function($event) {
                          _vm.deleteDialog = false
                        }
                      }
                    },
                    [_vm._v("Abbrechen")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "red red-1", text: "" },
                      on: {
                        click: function() {
                          this$1.deleteThisSlot()
                          this$1.deleteDialog = false
                        }
                      }
                    },
                    [_vm._v("Löschen")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-snackbar",
        {
          attrs: { bottom: "", color: "error", timeout: "3000" },
          model: {
            value: _vm.snackbar,
            callback: function($$v) {
              _vm.snackbar = $$v
            },
            expression: "snackbar"
          }
        },
        [_vm._v(" " + _vm._s(_vm.snackbarText) + " ")]
      ),
      _c(
        "v-snackbar",
        {
          attrs: { bottom: "", color: "success", timeout: "3000" },
          model: {
            value: _vm.snackbarSave,
            callback: function($$v) {
              _vm.snackbarSave = $$v
            },
            expression: "snackbarSave"
          }
        },
        [_vm._v(" Gespeichert! ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }