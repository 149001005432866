<template>
    <div style="width: 90%; padding-right: 3px; padding-left: -3px">
        <progress-bar
            title="Hochladen"
            :show-progress="showTeacherUploadProgress"
            :progress="teacherUploadProgress"
            :abort-progress="teacherXmlHttpRequest ? () => { teacherXmlHttpRequest.abort() }: () => {}"
        />


        <v-dialog
            v-if="thisAppointment"
            v-model="menu"
            @click:outside="resetDialogContent()"
            max-width="400"
            scrollable
            class="dialog"
            overlay-color="white"
        >

            <template v-slot:activator="{ on, attrs }">
                <!--fach with text and image-->
                <div v-bind="attrs" class="fach pa-2 white--text"
                     style="width: 100%; display: block; padding-left: 0 !important;">
                    <v-row cols="12" v-on="on" class="ml-2 font-weight-medium" justify="center">
                        <v-col
                            :cols="isRunning ? 9 : 12"
                            v-on="on"
                            class="pa-0 ma-0"
                        >
                            <v-badge
                                color="red"
                                :content="thisAppointment.pupilUpload.filter((el) => !el.seenByMe).length"
                                :value="thisAppointment.pupilUpload.filter((el) => !el.seenByMe).length"
                                overlap
                                left
                                offset-x="5"
                                style="z-index: 8; width: 100%; height: 48px !important;"
                            >
                                <div @click="getMissingMembers" class="pa-3 ma-0 appointmentItem"
                                     :class="{ inactiveAppointment: !isRunning }"
                                     :style="isRunning ? 'width: calc(100% - 3px) !important' : ''"
                                >
                                    <img
                                        v-if="getSrc()"
                                        class="iconSelect"
                                        :src="isRunning ? getSrcWhite() : getSrc()"
                                        :style="isRunning ? getBackgroundColor() : 'background-color: transparent'"
                                    />

                                    <div v-else class="iconSelect"></div>
                                    {{ thisAppointment.schoolSubject.name }}
                                    <!--bookmark-->
                                    <img :src="getColorIcon()" style="position: absolute; height: 25px; top: 0; right: 10px;"/>
                                </div>
                                <!--paperclip-->
                                <img v-if="thisAppointment.pupilUpload.length > 0" :src="paperclipIcon" :style="isRunning ? 'right: 30px' : 'right: 27px'" style="opacity: 60%; position: absolute; height: 21px; top: -9px;"/>
                            </v-badge>
                        </v-col>

                        <!--Kamera-Icon neben Fach-->
                        <v-col
                            v-if="isRunning"
                            :cols="3"
                            class="pa-0 mr-0"
                            @click="clickBeitreten"
                            style="min-height: 100%"
                        >
                            <v-btn
                                class="pa-0" small
                                :class="{ isFachRunning: isRunning }"
                                style="height: 48px; width: 100%; background-color: white; border-radius: 8px;"
                            >
                                <img
                                    :src="beitretenKameraIcon"
                                    class="pa-0"
                                    :class="{ isFachRunningIcon: isRunning }"
                                    style="height: 35px !important; width: 35px !important;"
                                />
                            </v-btn>
                        </v-col>
                    </v-row>
                </div>
            </template>

            <!--menu that opens on click on fach in table-->
            <v-card tile style="color: #707070;" id="fachPopup">
                <v-card-title class="pa-0 ma-0">
                    <div class="fach d-flex" style="box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.3); font-size: large; align-items: center; padding: 0 20px; height: 59px; width: 100%;">
                        <v-col cols="6" class="d-flex align-center pl-0">
                            <img :src="getColorIcon()" style="position: absolute; right: 20%; top: 0; height: 25px"/>
                            <img v-if="getSrc()" class="iconSelect mr-2" :src="getSrcWhite()" style="margin-bottom: 0" :style="getBackgroundColor()"/>
                            <div style="flex: auto">{{ thisAppointment.schoolSubject.name }}</div>
                        </v-col>

                        <v-col
                            cols="4" class="pa-0 pr-6 d-flex flex-column justify-start align-end"
                            :style="`font-size: ${windowWidth < 400 ? 'small': 'medium'}`"
                        >
                            <div>{{ groupName }}</div>
                        </v-col>

                        <v-col cols="2" align-self="end">
                            <v-btn @click="menu = false; resetDialogContent()" elevation="2" x-small class="ml-2 pa-0 btnStyle" style="width: 30px; height: 30px">
                                <img :src="schliesenIcon" style="height: 20px;"/>
                            </v-btn>
                        </v-col>
                    </div>
                </v-card-title>

                <v-card-text class="pa-0 ma-0" style="overflow: hidden;">
                    <vue-custom-scrollbar class="scroll-area-fachPopup mb-2" :settings="settings">
                        <div class="mx-5">
                            <div class="mt-2">
                                <v-row>
                                    <v-col class="font-weight-bold py-2 d-flex align-center">
                                        <img :src="infoIcon"
                                             style="height: 20px;"
                                        />
                                        <p class="pl-2 ma-0">Info</p>
                                    </v-col>
                                    <v-col class="text-right py-1">
                                        <v-btn v-if="update" @click="saveNote" x-small elevation="0" class="btnStyle">
                                            <img :src="speichernIcon" style="height: 20px;"/>
                                        </v-btn>
                                        <v-btn v-else @click="updateNote" x-small elevation="0" class="btnStyle">
                                            <img :src="bearbeitenIcon" style="height: 20px;"/>
                                        </v-btn>
                                    </v-col>
                                </v-row>
                                <v-textarea v-if="update" v-model="thisAppointment.note" autofocus></v-textarea>
                                <vue-custom-scrollbar
                                    style="font-size: medium; font-weight: normal; line-height: 25px"
                                    class="mb-4 mt-1 scroll-area"
                                    :settings="settings"
                                    v-else-if="thisAppointment.note"
                                >
                                    <span v-html="urlify(thisAppointment.note)"></span>
                                </vue-custom-scrollbar>
                                <div v-else class="pb-1" style="color: #BDBDBD">
                                    Tragen Sie hier die Info zum Fach ein
                                </div>
                            </div>
                        </div>

                        <hr class="eHr"/>

                        <div class="mx-0">
                            <div class="mt-2">
                                <v-row>
                                    <v-col class="font-weight-bold py-2 mx-5 pt-0 d-flex align-center">
                                        <img :src="materialIcon"
                                             style="height: 20px;"
                                        />
                                        <p class="pl-2 ma-0">Material</p>
                                    </v-col>
                                </v-row>
                                <div v-if="!teacherUpload()" class="mx-5 pb-1" style="color: #BDBDBD; font-size: medium">
                                    Laden Sie hier Ihre Materialien hoch.
                                </div>

                                <!--up to two items if collapsed-->
                                <div class="matShort">
                                    <div
                                        v-for="(el, index) in thisAppointment.teacherUploads.filter(item => !item.editParent).slice(0,2)"
                                        :class="{ brighterFileRow: index % 2 === 1}"
                                        :key="el + index"
                                        class="py-2 px-5 d-flex justify-space-between align-center"
                                        style="font-size: medium; font-weight: normal; line-height: 25px; width: 100%; background-color: rgba(112,112,112, 0.15);"
                                    >
                                        <div class="fileName" style="min-width: 57%; width: 57%">
                                            {{el.title}}
                                        </div>
                                        <div class="pa-0 ml-2">
                                            <div style="display: inline-block">
                                                <v-btn v-if="['doc', 'docx', 'ppt', 'pptx', 'xls', 'xlsx'].includes(el.title.split('.').pop().toLowerCase())"
                                                       dark @click="() => { snackbarFileNotSupported = true; }" x-small class="btnStyle" elevation="0" style="background-color: #8CBD46;" >
                                                    <img :src="infoIcon" style="height: 20px; filter: brightness(1000%)"/>
                                                </v-btn>
                                                <v-btn v-else @click="() => openAnsicht(el.title, el.file, el._id, 'pupil')" class="btnStyle" x-small elevation="0">
<!--                                                    <img :src="papierkorbIcon" style="height: 20px;"/>-->
                                                    <v-icon style="width: 20px; color: #939393">fas fa-eye</v-icon>
                                                </v-btn>
                                            </div>
                                            <div style="display: inline-block">
                                                <v-btn @click="() => clickDeleteTeacherUpload(el)" class="btnStyle ml-2" x-small elevation="0">
                                                    <img :src="papierkorbIcon" style="height: 20px;"/>
                                                </v-btn>
                                            </div>
                                            <div style="display: inline-block">
                                                <v-btn @click="() => clickDownloadTeacherUpload(el)" class="btnStyle ml-2" x-small elevation="0">
                                                    <img :src="runterladenIcon" style="height: 20px;"/>
                                                </v-btn>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!--all items if expanded-->
                                <div class="matLong">
                                    <div
                                        v-for="(el, index) in thisAppointment.teacherUploads.filter(item => !item.editParent)"
                                        :class="{ brighterFileRow: index % 2 === 1, disabledFileRow: false }"
                                        :key="el + index"
                                        class="py-2 px-5 d-flex justify-space-between align-center"
                                        style="font-size: medium; font-weight: normal; line-height: 25px; width: 100%; background-color: rgba(112,112,112, 0.15);"
                                    >
                                        <div class="fileName" style="min-width: 57%; width: 57%">
                                            {{el.title}}
                                        </div>
                                        <div class="pa-0 ml-2">
                                            <div style="display: inline-block">
                                                <v-btn v-if="['doc', 'docx', 'ppt', 'pptx', 'xls', 'xlsx'].includes(el.title.split('.').pop().toLowerCase())"
                                                       dark @click="() => { snackbarFileNotSupported = true; }" x-small class="btnStyle" elevation="0" style="background-color: #8CBD46;" >
                                                    <img :src="infoIcon" style="height: 20px; filter: brightness(1000%)"/>
                                                </v-btn>
                                                <v-btn v-else @click="() => openAnsicht(el.title, el.file, el._id, 'pupil')" class="btnStyle" x-small elevation="0">
<!--                                                    <img :src="papierkorbIcon" style="height: 20px;"/>-->
                                                    <v-icon style="width: 20px; color: #939393">fas fa-eye</v-icon>
                                                </v-btn>
                                            </div>
                                            <div style="display: inline-block">
                                                <v-btn @click="() => clickDeleteTeacherUpload(el)" class="btnStyle ml-2" x-small elevation="0">
                                                    <img :src="papierkorbIcon" style="height: 20px;"/>
                                                </v-btn>
                                            </div>
                                            <div style="display: inline-block">
                                                <v-btn @click="() => clickDownloadTeacherUpload(el)" class="btnStyle ml-2" x-small elevation="0">
                                                    <img :src="runterladenIcon" style="height: 20px;"/>
                                                </v-btn>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!--expand and collapse btns-->
                                <div v-if="thisAppointment.teacherUploads.filter(item => !item.editParent).length > 2" class="d-flex justify-center mt-2">
                                    <v-btn @click="hideEl('matShort'); showEl('matLong')" class="matShort" small elevation="0">
                                        <img :src="arrowDown" style="height: 20px;"/>
                                    </v-btn>

                                    <v-btn @click="hideEl('matLong'); showEl('matShort')" class="matLong" small elevation="0">
                                        <img :src="arrowDown" style="height: 20px; transform: rotate(180deg)"/>
                                    </v-btn>
                                </div>
                            </div>
                        </div>

                        <div class="my-4">
                            <input
                                @change="() => teacherUploadInputChange(thisAppointment._id)"
                                id="teacherUploadInput"
                                type="file"
                                hidden
                                ref="teacherUploadInput"
                            />
                            <div class="d-flex" style="justify-content: center">
                                <v-btn @click="clickTeacherUploadButton" style="padding-left: 50px; padding-right: 50px; color: #707070" class="text-capitalize" elevation="0">
                                    <img :src="hochladenIcon" style="height: 20px;" class="mr-2"/>
                                    Hochladen
                                </v-btn>
                            </div>
                        </div>

                        <hr class="eHr"/>

                        <div class="mx-0">
                            <div class="mt-2">
                                <v-row class="mb-2">
                                    <v-col class="font-weight-bold pb-0 mx-5 pt-0 d-flex align-center">
                                        <img :src="abgabenIcon"
                                             style="height: 20px;"/>
                                        <p class="pl-2 ma-0">Abgaben</p>
                                    </v-col>
                                    <!--TODO implement deadline for homework-->
                                    <!--<v-btn class="mr-5 text-capitalize" style="font-size: xx-small; margin-top: 4px">
                                           <img :src="uhrIcon" style="height: 20px" class="mx-2">
                                           Termin festlegen
                                    </v-btn>-->
                                </v-row>

                                <div>
                                    <!--if atleast one pupil has uploaded homework-->
                                    <div v-if="thisAppointment.pupilUpload.length > 0 || isCorrectionAvailable(thisAppointment.pupilUpload)">

                                        <!--Zu korrigieren-section-->
                                        <v-row class="mb-2">
                                            <v-col class="font-weight-bold pb-0 mx-5 pt-0 d-flex align-center">
                                                <img :src="offenIcon"
                                                     style="height: 20px;"
                                                />
                                                <p class="pl-2 ma-0">Zu korrigieren</p>
                                            </v-col>
                                        </v-row>

                                        <v-row dense class="ma-0 fileUploadPlaceholderText"
                                               align-content="center"
                                               v-if="!pupilUploadsToCorrect.length">
                                            <p class="mb-0">Aktuell sind keine Aufgaben bereit zum korrigieren.</p>
                                        </v-row>

                                        <!--up to two items if collapsed-->
                                        <div class="toCorrectShort">
                                            <div
                                                v-for="(el, index) in pupilUploadsToCorrect.slice(0,2)"
                                                :key="el + index"
                                                :class="{ brighterToCorrectRow: index % 2 === 1}"
                                                class="py-2 px-5 d-flex justify-space-between align-center"
                                                style="font-size: medium; font-weight: normal; line-height: 25px; width: 100%; background-color: rgba(128, 128, 128, 0.25)"
                                            >
                                                <div class="fileName" style="min-width: 70%; width: 70%;" @click="clickDownloadPupilUpload(el)">
                                                    {{el.title}}
                                                </div>
                                                <div class="d-flex">
                                                    <div class="pa-0">
                                                        <v-badge
                                                            color="red"
                                                            content="1"
                                                            :value="!el.seenByMe"
                                                            overlap
                                                            offset-x="45"
                                                            style="z-index: 9"
                                                        >
                                                            <v-btn v-if="['doc', 'docx', 'ppt', 'pptx', 'xls', 'xlsx'].includes(el.title.split('.').pop().toLowerCase())"
                                                                    dark @click="fileNotSupported(el)" x-small class="btnStyle" elevation="0" style="background-color: #8CBD46;" >
                                                                <img :src="infoIcon" style="height: 20px; filter: brightness(1000%)"/>
                                                            </v-btn>
                                                            <!--Hausaufgabe von Schüler korrigieren-->
                                                            <v-btn v-else @click="() => openEdit(el.title, el.file, el._id)" x-small class="ma-0 pa-0 btnStyle" elevation="0" style="background-color: #8CBD46;">
                                                                <img :src="stiftIcon" style="height: 20px;"/>
                                                            </v-btn>
                                                        </v-badge>
                                                    </div>
                                                    <div class="pa-0 ml-2">
                                                        <!--Lehrer hat Korrektur gemacht, die er herunterladen kann-->
                                                        <v-btn v-if="isCorrectionAvailable(el)" @click="() => clickDownloadPupilCorrection(el)" x-small class="pa-0 ma-0 btnStyle" elevation="0" style="background-color: white;">
                                                            <img :src="bearbeitetIcon" style="height: 20px;"/>
                                                        </v-btn>
                                                        <!--Lehrer hat noch keine Korrektur gemacht-->
                                                        <v-btn v-else @click="clickDownloadPupilUpload(el)" elevation="0" x-small class="ma-0 pa-0 btnStyle" style="background-color: white;" >
                                                            <img :src="offenIcon" style="height: 20px;"/>
                                                        </v-btn>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <!--all items if expanded-->
                                        <div class="toCorrectLong">
                                            <div
                                                v-for="(el, index) in pupilUploadsToCorrect"
                                                :key="el + index"
                                                :class="{ brighterToCorrectRow: index % 2 === 1}"
                                                class="py-2 px-5 d-flex justify-space-between align-center"
                                                style="font-size: medium; font-weight: normal; line-height: 25px; width: 100%; background-color: rgba(128, 128, 128, 0.25)"
                                            >
                                                <div class="fileName" style="min-width: 70%; width: 70%;" @click="clickDownloadPupilUpload(el)">
                                                    {{el.title}}
                                                </div>
                                                <div class="d-flex">
                                                    <div class="pa-0">
                                                        <v-badge
                                                            color="red"
                                                            content="1"
                                                            :value="!el.seenByMe"
                                                            overlap
                                                            offset-x="45"
                                                            style="z-index: 9"
                                                        >
                                                            <v-btn v-if="['doc', 'docx', 'ppt', 'pptx', 'xls', 'xlsx'].includes(el.title.split('.').pop().toLowerCase())"
                                                                    dark @click="fileNotSupported(el)" x-small class="btnStyle" elevation="0" style="background-color: #8CBD46;" >
                                                                <img :src="infoIcon" style="height: 20px; filter: brightness(1000%)"/>
                                                            </v-btn>
                                                            <!--Hausaufgabe von Schüler korrigieren-->
                                                            <v-btn v-else @click="() => openEdit(el.title, el.file, el._id)" x-small class="ma-0 pa-0 btnStyle" elevation="0" style="background-color: #8CBD46;">
                                                                <img :src="stiftIcon" style="height: 20px;"/>
                                                            </v-btn>
                                                        </v-badge>
                                                    </div>
                                                    <div class="pa-0 ml-2">
                                                        <!--Lehrer hat Korrektur gemacht, die er herunterladen kann-->
                                                        <v-btn v-if="isCorrectionAvailable(el)" @click="() => clickDownloadPupilCorrection(el)" x-small class="pa-0 ma-0 btnStyle" elevation="0" style="background-color: white;">
                                                            <img :src="bearbeitetIcon" style="height: 20px;"/>
                                                        </v-btn>
                                                        <!--Lehrer hat noch keine Korrektur gemacht-->
                                                        <v-btn v-else elevation="0" @click="clickDownloadPupilUpload(el)" x-small class="ma-0 pa-0 btnStyle" style="background-color: white;" >
                                                            <img :src="offenIcon" style="height: 20px;"/>
                                                        </v-btn>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <!--expand and collapse btns-->
                                        <div v-if="thisAppointment.pupilUpload.length > 2" class="d-flex justify-center mt-2">
                                            <v-btn @click="hideEl('toCorrectShort'); showEl('toCorrectLong')" class="toCorrectShort" small elevation="0">
                                                <img :src="arrowDown" style="height: 20px;"/>
                                            </v-btn>

                                            <v-btn @click="hideEl('toCorrectLong'); showEl('toCorrectShort')" class="toCorrectLong" small elevation="0">
                                                <img :src="arrowDown" style="height: 20px; transform: rotate(180deg)"/>
                                            </v-btn>
                                        </div>

                                        <!--Nicht abgegeben-section-->
                                        <v-row class="mb-2">
                                            <v-col class="font-weight-bold pb-0 mx-5 d-flex align-center">
                                                <img :src="keinDocument"
                                                     style="height: 20px;"
                                                />
                                                <p class="pl-2 ma-0">Nicht abgegeben</p>
                                            </v-col>
                                        </v-row>

                                        <!--up to two items if collapsed-->
                                        <div class="toDoShort">
                                            <div
                                                v-for="(el, index) in missingAppointments.slice(0,2)"
                                                :key="el + index"
                                                :class="{ brighterToDoRow: index % 2 === 1}"
                                                class="py-2 px-5 d-flex justify-space-between align-center"
                                                style="font-size: medium; font-weight: normal; line-height: 25px; width: 100%; background-color: rgba(230, 35, 30, 0.25)"
                                            >
                                                <div class="fileName">
                                                    {{el.name}} {{el.lastName}}
                                                </div>
                                                <!--Schüler haben noch keine Abgabe abgegeben-->
                                                <div style="display: inline-block">
                                                    <v-btn elevation="0" x-small class="btnStyle" color="rgba(0,0,0,0)">
                                                        <img :src="keinDocument" style="height: 20px;"/>
                                                    </v-btn>
                                                </div>
                                            </div>
                                        </div>

                                        <!--all items if expanded-->
                                        <div class="toDoLong">
                                            <div
                                                v-for="(el, index) in missingAppointments"
                                                :key="el + index"
                                                :class="{ brighterToDoRow: index % 2 === 1}"
                                                class="py-2 px-5 d-flex justify-space-between align-center"
                                                style="font-size: medium; font-weight: normal; line-height: 25px; width: 100%; background-color: rgba(230, 35, 30, 0.25)"
                                            >
                                                <div class="fileName">
                                                    {{el.name}} {{el.lastName}}
                                                </div>
                                                <!--Schüler haben noch keine Abgabe abgegeben-->
                                                <div style="display: inline-block">
                                                    <v-btn elevation="0" x-small class="btnStyle" color="rgba(0,0,0,0)">
                                                        <img :src="keinDocument" style="height: 20px;"/>
                                                    </v-btn>
                                                </div>
                                            </div>
                                        </div>

                                        <!--expand and collapse btns-->
                                        <div v-if="missingAppointments.length > 2" class="d-flex justify-center mt-2">
                                            <v-btn @click="hideEl('toDoShort'); showEl('toDoLong')" class="toDoShort" small elevation="0">
                                                <img :src="arrowDown" style="height: 20px;"/>
                                            </v-btn>

                                            <v-btn @click="hideEl('toDoLong'); showEl('toDoShort')" class="toDoLong" small elevation="0">
                                                <img :src="arrowDown" style="height: 20px; transform: rotate(180deg)"/>
                                            </v-btn>
                                        </div>

                                        <!--Korrigiert-section-->
                                        <v-row class="mb-2">
                                            <v-col class="font-weight-bold pb-0 mx-5 d-flex align-center">
                                                <img :src="bearbeitetIcon"
                                                     style="height: 20px;"
                                                />
                                                <p class="pl-2 ma-0">Korrigiert</p>
                                            </v-col>
                                        </v-row>

                                        <v-row dense class="ma-0 fileUploadPlaceholderText"
                                               align-content="center"
                                               v-if="!pupilUploadsCorrected.length">
                                            <p class="mb-0">Noch ist nichts korrigiert.</p>
                                        </v-row>

                                        <div class="correctedShort">
                                            <div
                                                v-for="(el, index) in pupilUploadsCorrected.slice(0,2)"
                                                :key="el + index"
                                                :class="{ brighterCorrectedRow: index % 2 === 1}"
                                                class="py-2 px-5 d-flex justify-space-between align-center"
                                                @click="() => clickDownloadPupilCorrection(el)"
                                                style="font-size: medium; font-weight: normal; line-height: 25px; width: 100%; background-color: rgba(60, 170, 105, 0.25)"
                                            >
                                                <div class="fileName" style="width: 75%; min-width: 75%">
                                                    {{el.title}}
                                                </div>
                                                <div class="pa-0 ml-2">
                                                    <div style="display: inline-block">
                                                        <v-btn v-if="['doc', 'docx', 'ppt', 'pptx', 'xls', 'xlsx'].includes(el.title.split('.').pop().toLowerCase())"
                                                               dark @click="() => { snackbarFileNotSupported = true; }" x-small class="btnStyle" elevation="0" style="background-color: #8CBD46;" >
                                                            <img :src="infoIcon" style="height: 20px; filter: brightness(1000%)"/>
                                                        </v-btn>
                                                        <v-btn v-else @click="(event) => {event.stopPropagation(); handleOpenAnsichtClick(el)}" class="btnStyle" x-small elevation="0" color="rgba(0,0,0,0)">
<!--                                                            <img :src="papierkorbIcon" style="height: 20px;"/>-->
                                                            <v-icon style="width: 20px; color: #939393">fas fa-eye</v-icon>
                                                        </v-btn>
                                                    </div>
                                                    <div style="display: inline-block">
                                                        <v-btn
                                                            @click="() => clickDownloadPupilCorrection(el)"
                                                            elevation="0" x-small class="btnStyle" color="rgba(0,0,0,0)"
                                                        >
                                                            <img :src="bearbeitetIcon" style="height: 20px;"/>
                                                        </v-btn>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="correctedLong">
                                            <div
                                                v-for="(el, index) in pupilUploadsCorrected"
                                                :key="el + index"
                                                :class="{ brighterCorrectedRow: index % 2 === 1}"
                                                class="py-2 px-5 d-flex justify-space-between align-center"
                                                @click="() => clickDownloadPupilCorrection(el)"
                                                style="font-size: medium; font-weight: normal; line-height: 25px; width: 100%; background-color: rgba(60, 170, 105, 0.25)"
                                            >
                                                <div class="fileName" style="width: 75%">
                                                    {{el.title}}
                                                </div>
                                                <div class="pa-0 ml-2">
                                                    <div style="display: inline-block">
                                                        <v-btn v-if="['doc', 'docx', 'ppt', 'pptx', 'xls', 'xlsx'].includes(el.title.split('.').pop().toLowerCase())"
                                                                dark @click="() => { snackbarFileNotSupported = true; }" x-small class="btnStyle" elevation="0" style="background-color: #8CBD46;" >
                                                            <img :src="infoIcon" style="height: 20px; filter: brightness(1000%)"/>
                                                        </v-btn>
                                                        <v-btn v-else @click="(event) => {event.stopPropagation(); handleOpenAnsichtClick(el)}" class="btnStyle" x-small elevation="0" color="rgba(0,0,0,0)">
<!--                                                            <img :src="papierkorbIcon" style="height: 20px;"/>-->
                                                            <v-icon style="width: 20px; color: #939393">fas fa-eye</v-icon>
                                                        </v-btn>
                                                    </div>
                                                    <div style="display: inline-block">
                                                        <v-btn
                                                            @click="() => clickDownloadPupilCorrection(el)"
                                                            elevation="0" x-small class="btnStyle" color="rgba(0,0,0,0)"
                                                        >
                                                            <img :src="bearbeitetIcon" style="height: 20px;"/>
                                                        </v-btn>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <!--expand and collapse btns-->
                                        <div v-if="pupilUploadsCorrected.length > 2" class="d-flex justify-center mt-2">
                                            <v-btn @click="hideEl('correctedShort'); showEl('correctedLong')" class="correctedShort" small elevation="0">
                                                <img :src="arrowDown" style="height: 20px;"/>
                                            </v-btn>

                                            <v-btn @click="hideEl('correctedLong'); showEl('correctedShort')" class="correctedLong" small elevation="0">
                                                <img :src="arrowDown" style="height: 20px; transform: rotate(180deg)"/>
                                            </v-btn>
                                        </div>
                                    </div>

                                    <!--there are no pupil uploads to view or correct-->
                                    <div v-else class="mx-5" style="color: #BDBDBD; font-size: medium">
                                        Es hat noch kein Schüler eine Abgabe getätigt.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </vue-custom-scrollbar>
                </v-card-text>

                <v-card-actions class="pa-0 ma-0" style="overflow: hidden;">
                    <!--Beitreten-Button in Fach-Popup-->
                    <v-row>
                        <hr class="eHr mt-2"/>
                        <v-col class="text-center">
                            <v-btn @click="clickBeitreten" elevation="0" class="text-capitalize"
                                   style="color: white; background-color: #8CBD46; padding-left: 50px; padding-right: 50px"
                            >
                                <img :src="beitretenKameraIcon" class="mr-5" style="height: 20px;"/>
                                Beitreten
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!--fach with no text and image-->
        <div v-else class="fach pa-2 white--text" style="width: 100%; height: 70.5px !important; display: block; padding-left: 0 !important;">
            <v-row class="ml-2 font-weight-medium">
                <v-col cols="12" class="pa-0 ma-0" style="height: 48px !important;">
                    <div class="pa-3 ma-0 appointmentItem inactiveAppointment">
                        <img class="iconSelectFrei"/>
                        Hohlstunde
                    </div>
                </v-col>
            </v-row>
        </div>

        <v-snackbar bottom v-model="snackbar" color="error" timeout="3000">
            {{ snackbarText }}
        </v-snackbar>

        <v-snackbar bottom v-model="snackbarSave" color="success" timeout="3000">
            Gespeichert!
        </v-snackbar>

        <v-snackbar bottom v-model="snackbarDelete" :color="snackbarDeleteColor" timeout="3000">
            {{ snackbarDeleteText }}
        </v-snackbar>


        <v-snackbar bottom v-model="snackbarFileNotSupported" color="grey" timeout="5000" style="z-index: 9999 !important">
            Diese Datei wird vom Editor nicht unterstützt. Bitte lade sie herunter.
        </v-snackbar>
    </div>
</template>

<script>
import vueCustomScrollbar from "vue-custom-scrollbar";
import * as backend from "../../api/backend";

import beitretenKameraIcon from "../../assets/Icons/kamera-video-weiss-72.svg";
import schliesenIcon from "../../assets/Icons/abbrechen-08.svg";
import buchzeichenIcon from "../../assets/Icons/lesezeichen-01-43.svg";
import speichernIcon from "../../assets/Icons/speichern-64.svg";
import lehrerIcon from "../../assets/Icons/lehrer-24.svg";
import abgabenIcon from "../../assets/Icons/bearbeiten-16.svg";
import stiftIcon from "../../assets/Icons/bearbeiten-komplimentär-weiß-88.svg";
import bearbeitenIcon from "../../assets/Icons/bearbeiten-normal-41.svg";
import bearbeitetIcon from "../../assets/Icons/bearbeitet-17.svg";
import runterladenIcon from "../../assets/Icons/runterladen-14.svg";
import hochladenIcon from "../../assets/Icons/hochladen-15.svg";
import rechtsIcon from "../../assets/Icons/rechts-11.svg";
import lesezeichenIcon from "../../assets/Icons/lesezeichen-01-43.svg";
import offenIcon from "../../assets/Icons/offen-18.svg";
import keinDocument from '../../assets/Icons/kein-dokument-68.svg'
import uhrIcon from '../../assets/Icons/uhr-04.svg'
import toDoIcon from '../../assets/Icons/noch-zu-machen-86.svg'
import doneIcon from '../../assets/Icons/erledigt-87.svg'
import correctedIcon from '../../assets/Icons/korrigiert-88.svg'
import materialIcon from '../../assets/Icons/material-93.svg'
import arrowDown from '../../assets/Icons/unten-dropdown-12.svg'
import paperclipIcon from '../../assets/Icons/Bueroklammer-94.svg'
import papierkorbIcon from '../../assets/Icons/papierkorb-109.svg'
import infoIcon from '../../assets/Icons/info-45.svg'

import bioIcon from "../../assets/Icons/f-biologie-35.svg";
import chemieIcon from "../../assets/Icons/f-chemie-37.svg";
import deutschIcon from "../../assets/Icons/f-deutsch-29.svg";
import englischIcon from "../../assets/Icons/f-englisch-30.svg";
import erdkundeIcon from "../../assets/Icons/f-erdkunde-31.svg";
import geschichteIcon from "../../assets/Icons/f-geschichte-40.svg";
import matheIcon from "../../assets/Icons/f-mathe-32.svg";
import musikIcon from '../../assets/Icons/f-musik-84.svg'
import physikIcon from "../../assets/Icons/f-physik-36.svg";
import politikIcon from "../../assets/Icons/f-politik-34.svg";
import religionIcon from "../../assets/Icons/f-religion-39.svg";
import sportIcon from "../../assets/Icons/f-sport-38.svg";
import wirtschaftIcon from "../../assets/Icons/f-wirtschaft-33.svg";

import bioIconWeiss from "../../assets/Icons/f-biologie-weiss-35.svg";
import chemieIconWeiss from "../../assets/Icons/f-chemie-weiss-37.svg";
import deutschIconWeiss from "../../assets/Icons/f-deutsch-weiss-29.svg";
import englischIconWeiss from "../../assets/Icons/f-englisch-weiss-30.svg";
import erdkundeIconWeiss from "../../assets/Icons/f-erdkunde-weiss-31.svg";
import geschichteIconWeiss from "../../assets/Icons/f-geschichte-weiss-40.svg";
import matheIconWeiss from "../../assets/Icons/f-mathe-weiss-32.svg";
import musikIconWeiss from "@/assets/Icons/f-musik-weiss-82.svg";
import physikIconWeiss from "../../assets/Icons/f-physik-weiss-36.svg";
import politikIconWeiss from "../../assets/Icons/f-politik-weiss-34.svg";
import religionIconWeiss from "../../assets/Icons/f-religion-weiss-39.svg";
import sportIconWeiss from "../../assets/Icons/f-sport-weiss-38.svg";
import wirtschaftIconWeiss from "../../assets/Icons/f-wirtschaft-weiss-33.svg";

import colorfcd20a from "../../assets/Icons/lesezeichen-gelb-47.svg";
import colorf0b423 from "../../assets/Icons/lesezeichen-orange-48.svg";
import colorff6941 from "../../assets/Icons/lesezeichen-rot-49.svg";
import color91c364 from "../../assets/Icons/lesezeichen-hellgrün-50.svg";
import color91c887 from "../../assets/Icons/lesezeichen-mintgrün-51.svg";
import colord7875f from "../../assets/Icons/lesezeichen-braun-52.svg";
import colorebaabe from "../../assets/Icons/lesezeichen-rosa-53.svg";
import color9b91c8 from "../../assets/Icons/lesezeichen-lila-54.svg";
import color6ec3dc from "../../assets/Icons/lesezeichen-blau-55.svg";
import color9bcdc3 from "../../assets/Icons/lesezeichen-minze-56.svg";
import colorb2b2b2 from "../../assets/Icons/lesezeichen-hellgrau-43.svg";
import color707070 from "../../assets/Icons/lesezeichen-dunkelgrau-44.svg";

import {mapState, mapGetters, mapActions} from "vuex";
import ProgressBar from "@/components/ProgressBar";

export default {
    name: "Fach",
    components: {
        ProgressBar,
        vueCustomScrollbar,
    },
    data() {
        return {
            windowWidth: window.innerWidth,
            missingAppointments: [],
            menu: false,
            thisAppointment: null,
            snackbar: false,
            snackbarText: "",
            snackbarSave: false,
            snackbarDelete: false,
            snackbarDeleteText: '',
            snackbarDeleteColor: 'success',
            snackbarFileNotSupported: false,
            update: false,
            settings: {
                suppressScrollY: false,
                suppressScrollX: true,
                wheelPropagation: false,
            },
            showTeacherUploadProgress: false,
            teacherUploadProgress: 0.0,
            teacherXmlHttpRequest: null,
            showNameFile: false,
            uploadFileName: "",
            fileNameCallback: () => {},
            group: null,
            bbbTutState: null,
            bbbTut: false,

            teacherMaterials: 0,

            groupName: '',

            beitretenKameraIcon,
            schliesenIcon,
            buchzeichenIcon,
            speichernIcon,
            lehrerIcon,
            abgabenIcon,
            stiftIcon,
            bearbeitenIcon,
            bearbeitetIcon,
            runterladenIcon,
            hochladenIcon,
            rechtsIcon,
            lesezeichenIcon,
            offenIcon,
            keinDocument,
            uhrIcon,
            toDoIcon,
            doneIcon,
            correctedIcon,
            materialIcon,
            arrowDown,
            paperclipIcon,
            papierkorbIcon,
            infoIcon,

            bioIcon,
            chemieIcon,
            deutschIcon,
            englischIcon,
            erdkundeIcon,
            geschichteIcon,
            matheIcon,
            musikIcon,
            physikIcon,
            politikIcon,
            religionIcon,
            sportIcon,
            wirtschaftIcon,

            bioIconWeiss,
            chemieIconWeiss,
            deutschIconWeiss,
            englischIconWeiss,
            erdkundeIconWeiss,
            geschichteIconWeiss,
            matheIconWeiss,
            musikIconWeiss,
            physikIconWeiss,
            politikIconWeiss,
            religionIconWeiss,
            sportIconWeiss,
            wirtschaftIconWeiss,

            colorfcd20a,
            colorf0b423,
            colorff6941,
            color91c364,
            color91c887,
            colord7875f,
            colorebaabe,
            color9b91c8,
            color6ec3dc,
            color9bcdc3,
            colorb2b2b2,
            color707070,
        };
    },
    props: {
        appointment: {required: false, default: null},
        requestAppointments: {required: true},
        isRunning: {type: Boolean, required: false, default: false},
        isOpen: {type: Boolean, required: false, default: false},
    },
    watch: {
        appointment() {
            console.log(this.thisAppointment)
            this.thisAppointment = JSON.parse(JSON.stringify(this.appointment));

        },
        isOpen() {
            console.log("is open");
            if (!this.menu) {
                this.menu = this.isOpen;
                this.getMissingMembers();
                this.$router.replace({
                    query: {
                        appointment: undefined,
                    },
                });
            }
        },
        // currentlyOpenAppointment(newVal, oldVal) {
        //     if (!newVal && oldVal && this.thisAppointment && oldVal._id === this.thisAppointment._id) {
        //         this.requestAppointments();
        //     }
        // }
    },
    mounted() {
        this.checkTutorialState();
        this.thisAppointment = JSON.parse(JSON.stringify(this.appointment));

        this.reloadDontLogout(false);
        this.getGroupName();
    },
    computed: {
        ...mapState("auth", ["token"]),
        ...mapGetters('util', ['currentlyOpenAppointment']),
        pupilUploadsToCorrect() {
            return this.thisAppointment.pupilUpload.filter(item => !item.editLeafs || item.editLeafs.length === 0);
        },
        pupilUploadsCorrected() {
            return this.thisAppointment.pupilUpload.filter(item => item.editLeafs && item.editLeafs.length > 0);
        },
    },
    methods: {
        ...mapActions("appointments", ["editAppointment"]),
        ...mapActions("auth", ["reloadDontLogout"]),
        ...mapActions("teachers", ["deleteTeacherUpload"]),
        ...mapActions("util", ['toggleLoading', 'toggleOpenAppointment', 'toggleCurrentUploadGroup']),
        ...mapActions('files', [ 'setSnackbar' ]),
        ...mapActions("groups", ["getGroupByAppointment"]),

        urlify(text) {
            // eslint-disable-next-line no-useless-escape
            var urlRegex = /(([a-z]+:\/\/)?(([a-z0-9\-]+\.)+([a-z]{2}|aero|arpa|biz|com|coop|edu|gov|info|int|jobs|software|mil|museum|name|nato|net|org|pro|travel|local|internal|de))(:[0-9]{1,5})?(\/[a-z0-9_\-\.~]+)*(\/([a-z0-9_\-\.]*)(\?[a-z0-9+_\-\.%=&amp;]*)?)?(#[a-zA-Z0-9!$&'()*+.=-_~:@/?]*)?)(\s+|$)/gi;
            return text.toString().replace(urlRegex, function(url) {
                url = url.includes('http') ? url : 'http://' + url;
                return '<a href="' + url + '" target="_blank" rel="noopener noreferrer">' + url + '</a>';
            })
            // or alternatively
            // return text.replace(urlRegex, '<a href="$1">$1</a>')
        },
        checkTutorialState() {
            this.bbbTutState = JSON.parse(localStorage.getItem('bbbTut'));
            if(this.bbbTutState) {
                this.bbbTut = false;
            }
        },
        getSrc() {
            return this.thisSubject
                ? this[this.thisSubject.icon]
                : this.thisAppointment
                    ? this[this.thisAppointment.schoolSubject.icon]
                    : null;
        },
        getSrcWhite() {
            /**
             * Cant be done like the top one because the second part of string doesnt mathc with the normal icon, so a search is probably needed
             */
            let temp = this.thisSubject
                ? this[this.thisSubject.icon]
                : this.thisAppointment
                    ? this[this.thisAppointment.schoolSubject.icon]
                    : null;
            let searchString = temp.slice(0, temp.length - 16) + "-weiss";
            let returnString = '';

            for (let i = 0; i < Object.keys(this.$data).length; i++) {
                let key = Object.keys(this.$data)[i];
                if (this.$data[key] && this.$data[key].includes && this.$data[key].includes(searchString)) {
                    returnString = this.$data[key];
                    break;
                }
            }

            return returnString.length > 0 ? returnString : temp;
        },

        getColorIcon() {
            return this.thisSubject
                ? this["color" + this.thisSubject.color.substring(1)]
                : this.thisAppointment
                    ? this["color" + this.thisAppointment.schoolSubject.color.substring(1)]
                    : this.colorb2b2b2;
        },

        getBackgroundColor() {
            let returnString = "background-color: ";
            return returnString + this.thisAppointment.schoolSubject.color;
        },

        getTimeslotTimes() {
            const timeslot = this.$parent.$parent.slots.find(
                (item) => item._id === this.thisAppointment.timeslot
            );
            if (timeslot) {
                return (
                    ("0" + new Date(timeslot.start).getHours()).slice(-2) +
                    ":" +
                    ("0" + new Date(timeslot.start).getMinutes()).slice(-2) +
                    " - " +
                    ("0" + new Date(timeslot.end).getHours()).slice(-2) +
                    ":" +
                    ("0" + new Date(timeslot.end).getMinutes()).slice(-2)
                );
            }
        },

        async teacherUploadInputChange(appointmentId) {
            const input = this.$refs.teacherUploadInput;
            const file = input.files[0];
            if (file) {
                const name = file.name;
                const lastDot = name.lastIndexOf(".");
                const fileName = name.substring(0, lastDot);
                const fileExtension = name.substring(lastDot + 1);
                this.uploadFileName = fileName;
                this.fileNameCallback = (newFileName) => {
                    this.showNameFile = false;
                    this.uploadFile(appointmentId, newFileName, fileExtension);
                };
                this.showNameFile = true;
            }
        },

        fileNotSupported(el) {
            this.snackbarFileNotSupported = true;
        },
        async uploadFile(appointmentId, newFileName, fileExtension) {
            const input = this.$refs.teacherUploadInput;
            const file = input.files[0];
            if (file) {

                if (file.size > 1000000000) {
                    this.setSnackbar({ type: 'bigFileToBig', value: 'true' });
                    return;
                }

                const formData = new FormData();
                formData.append("file", file, `${newFileName}.${fileExtension}`);

                this.teacherXmlHttpRequest = backend.postTeacherUpload(
                    appointmentId,
                    file
                );

                this.teacherXmlHttpRequest.onerror = (e) => {
                    console.error("Teacher upload error:", e);
                    this.showTeacherUploadProgress = false;
                    this.teacherXmlHttpRequest = null;
                    this.$refs.teacherUploadInput.value = "";
                };

                this.teacherXmlHttpRequest.onabort = (e) => {
                    console.warn("Teacher upload aborted");
                    this.showTeacherUploadProgress = false;
                    this.teacherXmlHttpRequest = null;
                    this.$refs.teacherUploadInput.value = "";
                };

                this.teacherXmlHttpRequest.upload.addEventListener("progress", (e) => {
                    this.teacherUploadProgress = (e.loaded / e.total) * 100;
                });

                this.teacherXmlHttpRequest.addEventListener("load", (e) => {
                    if (this.teacherXmlHttpRequest.status !== 201)
                        console.error(
                            "Teacher upload failed:",
                            this.teacherXmlHttpRequest.response
                        );
                    this.showTeacherUploadProgress = false;
                    this.snackbarSave = true;
                    this.requestAppointments();
                    this.teacherXmlHttpRequest = null;
                    this.$refs.teacherUploadInput.value = "";
                });
                this.teacherUploadProgress = 0.0;
                this.showTeacherUploadProgress = true;
                this.teacherXmlHttpRequest.send(formData);
            }
        },

        async clickDownloadTeacherUpload(file) {
            const res = await backend.getTeacherUpload(
                this.thisAppointment._id,
                file.file
            );
            fetch(res.url, {
                method: "GET",
                headers: new Headers({
                    Authorization: "Bearer " + this.token,
                }),
            })
                .then((response) => response.blob())
                .then((blob) => {
                    var url = window.URL.createObjectURL(blob);
                    var a = document.createElement("a");
                    a.href = url;
                    a.download = file.title;
                    document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                    a.click();
                    a.remove(); //afterwards we remove the element again
                });
        },

        async clickDeleteTeacherUpload(file) {
            const res = await backend.deleteTeacherUpload((file._id).toString());

            if (res.status === 204) {
                this.snackbarDeleteText = "Datei " + file.title + " erfolgreich gelöscht"
                this.snackbarDelete = true;
            } else {
                this.snackbarDeleteText = "Beim Löschen ist ein Fehler aufgetreten"
                this.snackbarDeleteColor = 'error';
                this.snackbarDelete = true;
            }

            setTimeout(() => {
                /*emit to PlanWidgetLehrer*/
                this.$emit('update-me');
            }, 3000)
        },

        async clickDownloadPupilUpload(file) {
            file.seenByMe = true;
            const res = await backend.getPupilUpload(
                this.thisAppointment._id,
                file.file
            );
            fetch(res.url, {
                method: "GET",
                headers: new Headers({
                    Authorization: "Bearer " + this.token,
                }),
            })
                .then((response) => response.blob())
                .then((blob) => {
                    var url = window.URL.createObjectURL(blob);
                    var a = document.createElement("a");
                    a.href = url;
                    a.download = file.title;
                    document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                    a.click();
                    a.remove(); //afterwards we remove the element again
                });
        },
        updateNote() {
            this.update = true;
        },
        async saveNote() {
            let data = {
                _id: this.thisAppointment._id,
                note: this.thisAppointment.note,
            };
            let response = await this.editAppointment(data);
            if (Number.isInteger(response)) {
                if (response === 409) {
                    this.snackbarText =
                        "Bitte eine andere Namen eingeben! (Bereits verwendet)";
                } else {
                    this.snackbarText = "Ein unerwarteter Fehler ist aufgetretten!";
                }
                this.snackbar = true;
            } else {
                this.snackbarSave = true;
                this.update = false;
            }
        },
        clickTeacherUploadButton() {
            this.toggleOpenAppointment(this.thisAppointment);
            this.toggleCurrentUploadGroup('teacher');
            document.getElementById("uploadInput").click();
        },

        openEdit(name, id, uploadId) {

            const group = this.appointment.schoolSubject.name;
            const color = this.appointment.schoolSubject.color;
            const appointmentId = this.appointment._id;
            const fileTitle = name;
            const fileId = id;

            this.$router.push({
                name: "lehrer.edit",
                query: {
                    group: group,
                    color: color,
                    aId: appointmentId,
                    title: fileTitle,
                    fId: fileId,
                    uploadid: uploadId,
                },
            });
        },

        handleOpenAnsichtClick(element) {
            if (this.isCorrectionAvailable(element)) {
                const fileToOpen = this.getCurrentCorrection(element);

                if (fileToOpen) {
                    this.openAnsicht(fileToOpen.title, fileToOpen.file, fileToOpen._id, 'pupil');
                }
            } else {
                this.openAnsicht(element.title, element.file, element._id, 'teacher')
            }
        },

        openAnsicht(name, id, uploadId, userGroup) {

            const group = this.appointment.schoolSubject.name;
            const color = this.appointment.schoolSubject.color;
            const appointmentId = this.appointment._id;
            const fileTitle = name;
            const fileId = id;

            this.$router.push({
                name: "lehrer.view",
                query: {
                    group: group,
                    color: color,
                    aId: appointmentId,
                    title: fileTitle,
                    fId: fileId,
                    uploadid: uploadId,
                    ug: userGroup
                },
            });
        },

        //startBBBTut
        startBBB() {
            /*emit to PlanWidgetLehrer*/
            this.$emit('pass-on-bbb-tut', this.joinBBB);
        },

        async clickBeitreten() {
            this.menu = false;

            if(!this.bbbTutState) {
                this.startBBB();
            }
            else if (this.bbbTutState) {
               await this.joinBBB();
            }
        },

        async joinBBB() {
            const res = await backend.joinSchoolSubjectAppointmentBbbMeetingUrl(
                this.thisAppointment._id
            );
            this.toggleLoading(true);
            this.reloadDontLogout(true);
            const {redirectUrl} = await res.json();
            if (res.status === 422) {
                this.snackbarText = "Aktuell ist der Raum geschlossen!";
                this.snackbar = true;
            } else {
                window.location.href = redirectUrl;
                //  window.open(redirectUrl);
            }
            this.toggleLoading(false);
        },

        isCorrectionAvailable(pupilFile) {
            for (let i = 0; i < this.thisAppointment.teacherUploads.length; i++) {
                if (this.thisAppointment.teacherUploads[i].editParent) {
                    const index = pupilFile.editLeafs.indexOf(
                        this.thisAppointment.teacherUploads[i]._id
                    );
                    if (index !== -1) {
                        return true;
                    }
                }
            }
            return false;
        },

        getCurrentCorrection(pupilFile) {
            for (let i = 0; i < this.thisAppointment.teacherUploads.length; i++) {
                if (this.thisAppointment.teacherUploads[i].editParent) {
                    const index = pupilFile.editLeafs.indexOf(this.thisAppointment.teacherUploads[i]._id);
                    if (index !== -1) {
                        return this.thisAppointment.teacherUploads[i];
                    }
                }
            }
            return null;
        },

        async clickDownloadPupilCorrection(file) {
            let teacherCorrection = null;

            for (let i = 0; i < this.thisAppointment.teacherUploads.length; i++) {
                if (this.thisAppointment.teacherUploads[i].editParent) {
                    const index = file.editLeafs.indexOf(
                        this.thisAppointment.teacherUploads[i]._id
                    );
                    if (index !== -1) {
                        teacherCorrection = this.thisAppointment.teacherUploads[i];
                    }
                }
            }

            if (teacherCorrection) {
                teacherCorrection.seenByMe = true;

                const res = await backend.getTeacherUpload(
                    this.thisAppointment._id,
                    teacherCorrection.file
                );

                fetch(res.url, {
                    method: "GET",
                    headers: new Headers({
                        "Authorization": "Bearer " + this.token,
                    }),
                })
                    .then((response) => response.blob())
                    .then((blob) => {
                        var url = window.URL.createObjectURL(blob);
                        var a = document.createElement("a");
                        a.href = url;
                        a.download = teacherCorrection.title;
                        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                        a.click();
                        a.remove(); //afterwards we remove the element again
                    });
            }
        },

        async getMissingMembers(){
            this.missingAppointments = [];
            let pupilUploadData = this.appointment.pupilUpload;
            let idListUploads = [];

            for(let k = 0; k < pupilUploadData.length; k++){
                idListUploads.push(pupilUploadData[k].uploader);
            }

            const groupMemberAppointment = await backend.getGroupAppointment(this.appointment._id);
            let groupMemberAppointmentData = groupMemberAppointment.json().then(data => ({
                data: data,
                status: groupMemberAppointment.status
            }));

            let groupMembers = (await groupMemberAppointmentData).data[0].participants;

            for(let i = 0; i < groupMembers.length; i++){
                if (!idListUploads.includes(groupMembers[i].account)) {
                    this.missingAppointments.push(groupMembers[i]);
                }

            }
        },

        //checks if there is a teacherUpload to set placeholder text in Fach-Popup, if there is no upload
        teacherUpload() {
            let t = this.thisAppointment.teacherUploads.filter((item) => !item.editParent);
            return t.length !== 0;
        },

        hideEl(element) {
            let x = [];
            x = document.getElementsByClassName(element);
            for (let i = 0; i < x.length; i++) {
                x[i].style.display = "none";
            }
        },

        showEl(element) {
            let x = [];
            x = document.getElementsByClassName(element);
            for (let i = 0; i < x.length; i++) {
                x[i].style.display = "block";
            }
        },

        resetDialogContent() {
            this.hideEl('matLong'); this.showEl('matShort');
            this.hideEl('toCorrectLong'); this.showEl('toCorrectShort');
            this.hideEl('toDoLong'); this.showEl('toDoShort');
            this.hideEl('correctedLong'); this.showEl('correctedShort');
        },

        async getGroupName() {
          let group = await this.getGroupByAppointment(this.thisAppointment._id);
          this.groupName = group[0].name;
        },
    },
};
</script>


<style lang="scss" scoped>
table,
th,
td {
    border: 1px solid black;
    border-collapse: collapse;
}

tr {
    height: 40px;
}

table tr th:first-child,
table tr td:first-child {
    width: 6em !important;
}

table tr th,
table tr td {
    width: 9em;
}

table,
tr,
td {
    border: none;
}

.fach {
    cursor: pointer;
}

.appointmentItem {
    position: relative;
    color: #707070;
    border-radius: 10px;
    box-shadow: 1px 2px 3px silver;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background-color: #FFF;
    width: 100%;
    height: 100%;
}

.inactiveAppointment {
    opacity: 0.4 !important;
}

.scroll-area {
    position: relative;
    margin: auto;
    max-height: 100px;
}

.scroll-area2 {
    position: relative;
    margin: auto;
    max-height: 80px;
}

.scroll-area3 {
    position: relative;
    margin: auto;
    max-height: 50px;
}

.scroll-area-fachPopup {
    position: relative;
    margin: auto;
    height: 100%;
}

.buttonIcon {
    align-self: start;
    background-color: #eaeaeacc;
    padding: 2px;
    border-radius: 3px;
    margin-right: 1em;
}

//border-radius of v-menu
.v-menu__content {
    border-radius: 12px;
}

.iconSelect {
    display: inline-flex;
    width: 24px;
    height: 24px;
    padding: 2px;
    margin-bottom: -6px;
    border-radius: 5px;
    margin-right: 2px;
    background-color: rgba(234, 234, 234, 0.7);
}

.iconSelectFrei {
    display: inline-flex;
    width: 24px;
    padding: 2px;
    margin-bottom: -6px;
    border-radius: 5px;
    margin-right: 2px;
    background-color: transparent;
}

.smallFach {
    position: relative;
    color: #707070;
    border-radius: 10px;
    box-shadow: 1px 2px 3px silver;
    white-space: nowrap;
    width: 70%;
    overflow: hidden;
    text-overflow: ellipsis;
    background-color: #ffffff;
}

.isFachRunning {
    color: white;
    background-color: #8cbd46 !important;
}

.isFachRunningIcon {
    filter: brightness(1000%);
}

.fileUploadPlaceholderText {

    p {
        color: #BBBBBB;
        width: 100%;
        text-align: center;
    }
}

.eHr {
    width: 90%;
    margin: auto;
    border-width: 0;
    height: 2px;
    color: #eaeaea;
    background-color: #eaeaea;
}

.eButton {
    width: 33px !important;
    min-width: 33px !important;
    height: 33px !important;
    min-height: 33px !important;
    border-radius: 4px !important;
    box-shadow: 0 5px 10px #00000025 !important;
}

.btnStyle {
    min-width: 36px;
    min-height: 36px;
    margin-top: -3px;
}

.fileName {
    max-width: 90%;
    min-width: 90%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
}

.matLong, .toCorrectLong, .toDoLong, .correctedLong {
    display: none;
}

.brighterFileRow {
    background-color: rgba(224,224,224, 0.15) !important;
}

.brighterToCorrectRow {
    background-color: rgba(128, 128, 128, 0.15) !important;
}

.brighterToDoRow {
    background-color: rgba(230, 35, 30, 0.15) !important;
}

.brighterCorrectedRow {
    background-color: rgba(60, 170, 105, 0.15) !important;
}


@media only screen and (max-width: 399px) {
    #fachPopup {
        max-width: 100vw !important;
    }

    #dialog {
        margin: 0;
    }
}

@media only screen and (max-width: 500px), (max-width: 900px) and (orientation: portrait) {
    .scroll-area-fachPopup {
        /*250px = 59px header, 48px join btn, 70px btmNavBar and paddings,margins*/
        max-height: calc(100vh -  250px);
    }
}

.v-snack.v-snack--top {
    bottom: initial; /* or auto */
}

.v-snack.v-snack--bottom {
    top: initial; /* or auto */
}
</style>
