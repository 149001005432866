const state = {
  targetLang: 'de',
  translatedText: '',
  show: false,
};

const actions = {
    setTargetLang ({commit}, lang){
        commit('SET_TARGET_LANG', lang);
    },
    setTranslatedText ({commit}, text){
        console.log('setTranslatedText called with text', text);
        commit('SET_TRANSLATED_TEXT', text);
    },
    showTranslation ({commit}, show) {
      commit('SET_SHOW', show);
    }
};

const getters = {
    getTargetLang: state => state.targetLang,
    getTranslatedText: state => state.translatedText,
    isTranslationShown: state => state.show,
};

const mutations = {
    SET_TARGET_LANG: (state, lang) => {
      state.targetLang = lang;
    },
    SET_TRANSLATED_TEXT: (state, text) => {
      state.translatedText = text;
    },
    SET_SHOW: (state, show) => {
        state.show = show;
    }
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
}
