<template>
  <div>
    <v-row class="mx-0 mt-3 mb-4">
      <div
        style="
          height: auto;
          width: 90%;
          margin: auto;
          border-radius: 15px;
          box-shadow: 1px 5px 5px silver;
        "
      >
        <div
          style="
            background-color: #6995cd;
            border-radius: 15px 15px 0 0;
            font-size: larger;
            padding-top: 5px;
            padding-bottom: 20px;
            color: white;
            display: flex;
          "
        >
          <div style="display: flex; width: 50%">
            <img
              :src="suchIcon"
              style="
                height: 24px;
                margin-left: 24px;
                margin-top: 20px;
                filter: brightness(0) saturate(100%) invert(100%) sepia(5%)
                  saturate(19%) hue-rotate(65deg) brightness(104%)
                  contrast(105%);
              "
              alt="Lupen-Icon"
            />
            <v-autocomplete
              v-model="group"
              label="Nach Gruppe suchen"
              single-line
              dark
              hide-details
              item-text="name"
              class="pl-6"
              :items="groups"
              :filter="customFilter"
              no-data-text="Keine passende Gruppe"
            ></v-autocomplete>
          </div>
          <div style="display: flex; width: 49%; justify-content: flex-end; padding-bottom: -15px !important;">
            <join-lehrerzimmer-button style="margin: auto 0" />
          </div>
        </div>
        <v-row>
          <v-overlay absolute :value="showLoadingOverlay">
            <v-progress-circular
              v-if="showLoadingOverlay"
              indeterminate
              size="100"
            />
          </v-overlay>

          <v-col
            cols="12"
            class="textGrau"
            style="text-align: left; margin-left: 105px"
          >
            <v-btn
              @click="jumpToCurrentDay()"
              class="text-capitalize"
              style="
                background-color: #d6d6d6;
                filter: opacity(40%);
                color: black;
              "
              elevation="0"
            >
              Heute
            </v-btn>
            <v-btn
              @click="subWeek"
              class="mx-2"
              style="
                background-color: #d6d6d6;
                filter: opacity(40%);
                color: black;
              "
              icon
              small
              color="black"
            >
              <img
                :src="linksIcon"
                class="boardIcon"
                alt="links-Icon"
                style="color: black !important"
              />
            </v-btn>
            <v-btn
              @click="addWeek"
              class="mx-2"
              style="
                background-color: #d6d6d6;
                filter: opacity(40%);
                color: black !important;
              "
              icon
              small
              color="black"
            >
              <img
                :src="rechtsIcon"
                class="boardIcon"
                alt="rechts-Icon"
                style="color: black"
              />
            </v-btn>
            <div style="display: inline-grid">
              {{
                getMonthText(getMonday(date).getMonth()) +
                " " +
                getMonday(date).getFullYear()
              }}
            </div>
            <div style="display: inline-grid; width: 20px"></div>
            <v-btn
              style="
                display: inline-grid;
                background-color: #ffffff !important;
                filter: opacity(40%);
                color: black !important;
              "
              disabled
              class="diableHoverButton"
              elevation="0"
              >KW {{ getMonday(date).getWeek() }}</v-btn
            >
          </v-col>
        </v-row>
        <table style="width: 99%; margin: 0.5em auto 5em auto" :key="reload">
          <tr>
            <th>
              <img
                :src="uhrIcon"
                style="width: 24px; height: 24px; margin-top: 5px"
                alt="Uhr"
              />
            </th>
            <th>
              <!--                            <v-btn @click="subWeek" class="mx-2" style="background-color: #6995CD" icon small color="white">-->
              <!--                                <img :src="linksIcon" class="boardIcon" alt="links-Icon"/>-->
              <!--                            </v-btn>-->
            </th>

            <th
              v-if="isDisplayDateToday(getDisplayDateMonday())"
              style="color: #6995cd"
            >
              Mo.,
              {{
                getMonday(date).getDate() +
                "." +
                (getMonday(date).getMonth() + 1) +
                "." +
                getMonday(date).getFullYear().toString().slice(-2)
              }}
            </th>
            <th v-else style="color: #707070">
              Mo.,
              {{
                getMonday(date).getDate() +
                "." +
                (getMonday(date).getMonth() + 1) +
                "." +
                getMonday(date).getFullYear().toString().slice(-2)
              }}
            </th>

            <th
              v-if="isDisplayDateToday(getDisplayDateTuesday())"
              style="
                color: #6995cd;
                background-color: #eaeaea;
                border-collapse: initial;
                border-radius: 10px 10px 0 0;
              "
            >
              Di.,
              {{
                getTuesday(date).getDate() +
                "." +
                (getTuesday(date).getMonth() + 1) +
                "." +
                getTuesday(date).getFullYear().toString().slice(-2)
              }}
            </th>
            <th
              v-else
              style="
                color: #707070;
                background-color: #eaeaea;
                border-radius: 10px 10px 0 0;
              "
            >
              Di.,
              {{
                getTuesday(date).getDate() +
                "." +
                (getTuesday(date).getMonth() + 1) +
                "." +
                getTuesday(date).getFullYear().toString().slice(-2)
              }}
            </th>

            <th
              v-if="isDisplayDateToday(getDisplayDateWednesday())"
              style="color: #6995cd"
            >
              Mi.,
              {{
                getWednesday(date).getDate() +
                "." +
                (getWednesday(date).getMonth() + 1) +
                "." +
                getWednesday(date).getFullYear().toString().slice(-2)
              }}
            </th>
            <th v-else style="color: #707070">
              Mi.,
              {{
                getWednesday(date).getDate() +
                "." +
                (getWednesday(date).getMonth() + 1) +
                "." +
                getWednesday(date).getFullYear().toString().slice(-2)
              }}
            </th>

            <th
              v-if="isDisplayDateToday(getDisplayDateThursday())"
              style="
                color: #6995cd;
                background-color: #eaeaea;
                border-radius: 10px 10px 0 0;
              "
            >
              Do.,
              {{
                getThursday(date).getDate() +
                "." +
                (getThursday(date).getMonth() + 1) +
                "." +
                getThursday(date).getFullYear().toString().slice(-2)
              }}
            </th>
            <th
              v-else
              style="
                color: #707070;
                background-color: #eaeaea;
                border-radius: 10px 10px 0 0;
              "
            >
              Do.,
              {{
                getThursday(date).getDate() +
                "." +
                (getThursday(date).getMonth() + 1) +
                "." +
                getThursday(date).getFullYear().toString().slice(-2)
              }}
            </th>

            <th
              v-if="isDisplayDateToday(getDisplayDateFriday())"
              style="color: #6995cd"
            >
              Fr.,
              {{
                getFriday(date).getDate() +
                "." +
                (getFriday(date).getMonth() + 1) +
                "." +
                getFriday(date).getFullYear().toString().slice(-2)
              }}
            </th>
            <th v-else style="color: #707070">
              Fr.,
              {{
                getFriday(date).getDate() +
                "." +
                (getFriday(date).getMonth() + 1) +
                "." +
                getFriday(date).getFullYear().toString().slice(-2)
              }}
            </th>

            <th>
              <!--                            <v-btn @click="addWeek" class="mx-2" style="background-color: #6995CD" icon small color="white">-->
              <!--                                <img :src="rechtsIcon" class="boardIcon" alt="rechts-Icon"/>-->
              <!--                            </v-btn>-->
            </th>
          </tr>
          <tr v-for="(item, index) in slots" :key="index">
            <th class="textGrau" style="font-weight: normal">
              {{
                ("0" + new Date(item.start).getHours()).slice(-2) +
                ":" +
                ("0" + new Date(item.start).getMinutes()).slice(-2)
              }}
              <br />
              {{
                ("0" + new Date(item.end).getHours()).slice(-2) +
                ":" +
                ("0" + new Date(item.end).getMinutes()).slice(-2)
              }}
            </th>
            <th></th>
            <td style="background-color: white">
              <fach
                v-for="appointment in getAppointments(item._id, 'mon')"
                :key="appointment._id"
                :group="
                  groups.find((el) => {
                    return el.name === group;
                  })
                "
                :requestGroup="requestGroups"
                :timeslot="item"
                :day="getMonday(date)"
                :appointment="appointment"
              ></fach>
              <fach
                v-if="getAppointments(item._id, 'mon').length === 0"
                :group="
                  groups.find((el) => {
                    return el.name === group;
                  })
                "
                :requestGroup="requestGroups"
                :timeslot="item"
                :day="getMonday(date)"
                :appointment="null"
              ></fach>
            </td>
            <td
              v-if="index === slots.length - 1"
              style="background-color: #eaeaea; border-radius: 0 0 10px 10px"
            >
              <fach
                v-for="appointment in getAppointments(item._id, 'die')"
                :key="appointment._id"
                :group="
                  groups.find((el) => {
                    return el.name === group;
                  })
                "
                :requestGroup="requestGroups"
                :timeslot="item"
                :day="getTuesday(date)"
                :appointment="appointment"
              ></fach>
              <fach
                v-if="getAppointments(item._id, 'die').length === 0"
                :group="
                  groups.find((el) => {
                    return el.name === group;
                  })
                "
                :requestGroup="requestGroups"
                :timeslot="item"
                :day="getTuesday(date)"
                :appointment="null"
              ></fach>
            </td>
            <td v-else style="background-color: #eaeaea">
              <fach
                v-for="appointment in getAppointments(item._id, 'die')"
                :key="appointment._id"
                :group="
                  groups.find((el) => {
                    return el.name === group;
                  })
                "
                :requestGroup="requestGroups"
                :timeslot="item"
                :day="getTuesday(date)"
                :appointment="appointment"
              ></fach>
              <fach
                v-if="getAppointments(item._id, 'die').length === 0"
                :group="
                  groups.find((el) => {
                    return el.name === group;
                  })
                "
                :requestGroup="requestGroups"
                :timeslot="item"
                :day="getTuesday(date)"
                :appointment="null"
              ></fach>
            </td>
            <td style="background-color: white">
              <fach
                v-for="appointment in getAppointments(item._id, 'mit')"
                :key="appointment._id"
                :group="
                  groups.find((el) => {
                    return el.name === group;
                  })
                "
                :requestGroup="requestGroups"
                :timeslot="item"
                :day="getWednesday(date)"
                :appointment="appointment"
              ></fach>
              <fach
                v-if="getAppointments(item._id, 'mit').length === 0"
                :group="
                  groups.find((el) => {
                    return el.name === group;
                  })
                "
                :requestGroup="requestGroups"
                :timeslot="item"
                :day="getWednesday(date)"
                :appointment="null"
              ></fach>
            </td>
            <td
              v-if="index === slots.length - 1"
              style="background-color: #eaeaea; border-radius: 0 0 10px 10px"
            >
              <fach
                v-for="appointment in getAppointments(item._id, 'don')"
                :key="appointment._id"
                :group="
                  groups.find((el) => {
                    return el.name === group;
                  })
                "
                :requestGroup="requestGroups"
                :timeslot="item"
                :day="getThursday(date)"
                :appointment="appointment"
              ></fach>
              <fach
                v-if="getAppointments(item._id, 'don').length === 0"
                :group="
                  groups.find((el) => {
                    return el.name === group;
                  })
                "
                :requestGroup="requestGroups"
                :timeslot="item"
                :day="getThursday(date)"
                :appointment="null"
              ></fach>
            </td>
            <td v-else style="background-color: #eaeaea">
              <fach
                v-for="appointment in getAppointments(item._id, 'don')"
                :key="appointment._id"
                :group="
                  groups.find((el) => {
                    return el.name === group;
                  })
                "
                :requestGroup="requestGroups"
                :timeslot="item"
                :day="getThursday(date)"
                :appointment="appointment"
              ></fach>
              <fach
                v-if="getAppointments(item._id, 'don').length === 0"
                :group="
                  groups.find((el) => {
                    return el.name === group;
                  })
                "
                :requestGroup="requestGroups"
                :timeslot="item"
                :day="getThursday(date)"
                :appointment="null"
              ></fach>
            </td>
            <td style="background-color: white">
              <fach
                v-for="appointment in getAppointments(item._id, 'fre')"
                :key="appointment._id"
                :group="
                  groups.find((el) => {
                    return el.name === group;
                  })
                "
                :requestGroup="requestGroups"
                :timeslot="item"
                :day="getFriday(date)"
                :appointment="appointment"
              ></fach>
              <fach
                v-if="getAppointments(item._id, 'fre').length === 0"
                :group="
                  groups.find((el) => {
                    return el.name === group;
                  })
                "
                :requestGroup="requestGroups"
                :timeslot="item"
                :day="getFriday(date)"
                :appointment="null"
              ></fach>
            </td>
            <th></th>
          </tr>
        </table>
      </div>
    </v-row>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import Fach from "./FachVerwaltung";

import uhrIcon from "../../assets/Icons/uhr-04.svg";
import linksIcon from "../../assets/Icons/links-10.svg";
import rechtsIcon from "../../assets/Icons/rechts-11.svg";
import suchIcon from "../../assets/Icons/suche-26.svg";
import dropdownIcon from "../../assets/Icons/unten-dropdown-12.svg";
import brettIcon from "../../assets/Icons/sw-brett-28.svg";
import plusIcon from "../../assets/Icons/hinzufuegen-07.svg";
import schliessenIcon from "../../assets/Icons/abbrechen-08.svg";
import bearbeitenIcon from "../../assets/Icons/bearbeiten-normal-41.svg";
import sendenIcon from "../../assets/Icons/senden-23.svg";

import sonneIcon from "../../assets/Icons/sonne-46.svg";
import erdkundeIcon from "../../assets/Icons/f-erdkunde-weiss-31.svg";
import religionIcon from "../../assets/Icons/f-religion-weiss-39.svg";
import politikIcon from "../../assets/Icons/f-politik-weiss-34.svg";
import bioIcon from "../../assets/Icons/f-biologie-weiss-35.svg";
import englischIcon from "../../assets/Icons/f-englisch-weiss-30.svg";
import erdkundeIconNormal from "../../assets/Icons/f-erdkunde-31.svg";
import religionIconNormal from "../../assets/Icons/f-religion-39.svg";
import politikIconNormal from "../../assets/Icons/f-politik-34.svg";
import bioIconNormal from "../../assets/Icons/f-biologie-35.svg";
import englischIconNormal from "../../assets/Icons/f-englisch-30.svg";
import * as backend from "../../api/backend";
import JoinLehrerzimmerButton from "../Lehrer/JoinLehrerzimmerButton";

export default {
  name: "Plan",
  components: {
    JoinLehrerzimmerButton,
    Fach,
  },
  data() {
    return {
      showLoadingOverlay: false,
      menu: false,
      date: new Date(), //being changed when calendar day/week is changed
      today: this.getDay(), //reference to color current day

      height: 0,
      search: "",

      eintragPopup: false,
      bearbeiten: false,
      brettEintrag: {
        message: "",
        icon: "sonneIcon",
        color: "#fcd20a",
        groupId: "",
        blackboardId: "",
      },
      groupBlackboard: "",
      currentEntryId: "",

      select: {},
      items: [],

      swatches: [
        "#fcd20a",
        "#f0b423",
        "#ff6941",
        "#91c364",
        "#91c887",
        "#d7875f",
        "#ebaabe",
        "#9b91c8",
        "#6ec3dc",
        "#9bcdc3",
        "#b2b2b2",
        "#707070",
      ],

      timestamp: "Datum-Platzhalter",

      blackboard: [],

      headers: [
        { text: "Name", value: "lastName" },
        { text: "Vorname", value: "name" },
        { text: "Geburtstag", value: "birthday" },
        { text: "Geschlecht", value: "gender" },
      ],
      list: [],
      groups: [],
      slots: [],
      group: "",
      reload: false,
      //Icon array for dynamically picking the icon via string
      icons: [
        { icon: englischIcon, text: "englischIcon" },
        { icon: sonneIcon, text: "sonneIcon" },
        { icon: politikIcon, text: "politikIcon" },
        { icon: erdkundeIcon, text: "erdkundeIcon" },
        { icon: religionIcon, text: "religionIcon" },
        { icon: bioIcon, text: "bioIcon" },
      ],

      uhrIcon,
      linksIcon,
      rechtsIcon,
      suchIcon,
      dropdownIcon,
      brettIcon,
      plusIcon,
      schliessenIcon,
      bearbeitenIcon,
      sendenIcon,

      sonneIcon,
      bioIcon,
      englischIcon,
      erdkundeIcon,
      politikIcon,
      religionIcon,

      bioIconNormal,
      englischIconNormal,
      erdkundeIconNormal,
      politikIconNormal,
      religionIconNormal,
    };
  },
  props: {},
  async created() {
    this.height = window.innerHeight / 2;
  },
  beforeCreate() {
    // Returns the ISO week of the date.
    Date.prototype.getWeek = function () {
      var date = new Date(this.getTime());
      date.setHours(0, 0, 0, 0);
      // Thursday in current week decides the year.
      date.setDate(date.getDate() + 3 - ((date.getDay() + 6) % 7));
      // January 4 is always in week 1.
      var week1 = new Date(date.getFullYear(), 0, 4);
      // Adjust to Thursday in week 1 and count number of weeks from date to week1.
      return (
        1 +
        Math.round(
          ((date.getTime() - week1.getTime()) / 86400000 -
            3 +
            ((week1.getDay() + 6) % 7)) /
            7
        )
      );
    };
  },
  async mounted() {
    this.showLoadingOverlay = true;
    this.requestGroups();
    this.requestSlots();

    // this.requestBlackboard();
  },
  computed: {
    ...mapGetters("groups", ["getGroupStatus"]),
    ...mapGetters('timeslots', [ 'getTimeslotsInvalid' ]),
  },
  watch: {
    group(newValue) {
      this.list = [];
      this.groups.forEach((el) => {
        if (el.name === newValue) {
          const temp = el.participants;
          temp.forEach((el) => {
            this.list.push(el);
          });
        }
      });
    },
    groupBlackboard(newValue) {
      this.groups.forEach((el) => {
        if (el.name === newValue) {
          this.brettEintrag.groupId = el._id;
        }
      });
    },
    getGroupStatus(newVal, oldVal) {
      if (newVal && !oldVal) {
        this.requestGroups();
      }
    },
    getTimeslotsInvalid(newVal, oldVal) {
      if (newVal && !oldVal) {
        this.requestSlots();
      }
    }
  },
  methods: {
    ...mapActions("groups", ["getGroups", "setGroupInvalidStatus"]),
    ...mapActions("timeslots", ["getSlots", 'setTimeslotsStatus']),
    ...mapActions("groups", ["getGroup"]),

    async requestSlots() {
      this.showLoadingOverlay = true;
      this.slots = await this.getSlots();
      //TODO: Evtl. geht das hier schöner ? evtl. direkt im Backend Sortieren ?
      this.slots.forEach(element => {
        let dateOldStart = new Date(element.start);
        dateOldStart.setFullYear(2020,11,18);
        let dateOldEnd = new Date(element.end);
        dateOldEnd.setFullYear(2020,11,18);
        element.start = dateOldStart.toISOString();
        element.end = dateOldEnd.toISOString();
      });
      this.slots.sort(function(a,b){
        return new Date(a.start) - new Date(b.start);
      });
      this.showLoadingOverlay = false;
      this.setTimeslotsStatus(false);
    },

    async requestGroups() {
      //this is a hotfix wich works and i have no clue why so please dont break it
      const response = await backend.getGroups();
      const newArray = await response.json();
      this.groups = newArray.filter((el) => el.name !== "Alle");
      this.setGroupInvalidStatus(false);
      this.reload = !this.reload;

      let temp = JSON.parse(JSON.stringify(this.group));
      this.group = "";

      setTimeout(() => {
        this.group = temp;
      }, 500);
    },

    // async requestBlackboard() {
    //     this.blackboard = await this.getBlackboard();
    // },

    // async eintragErstellen() {
    //     let data = {
    //         message: this.brettEintrag.message,
    //         icon: this.brettEintrag.icon,
    //         color: this.brettEintrag.color,
    //         group: this.brettEintrag.groupId,
    //     };
    //     await this.createBlackboardEntry(data);
    //     this.eintragPopup = false;
    // },
    //
    // async setBrettPopup(i) {
    //     this.currentEntryId = this.blackboard[i]._id;
    //     this.brettEintrag.color = this.blackboard[i].color;
    //     this.brettEintrag.icon = this.blackboard[i].icon;
    //     this.brettEintrag.message = this.blackboard[i].message;
    //     this.brettEintrag.groupId = this.blackboard[i].blackboardId;
    //     let gruppenObj = await this.getGroup(this.blackboard[i].group);
    //     this.groupBlackboard = gruppenObj.name;
    // },
    //
    // resetPopup() {
    //     this.currentEntryId = '';
    //     this.brettEintrag.message = '';
    //     this.brettEintrag.icon = 'sonneIcon';
    //     this.brettEintrag.color = '#fcd20a';
    //     this.brettEintrag.groupId = '';
    //     this.brettEintrag.blackboardId = '';
    //     this.bearbeiten = false;
    // },
    //
    // async eintragBearbeiten() {
    //     let data = {
    //         _id: this.currentEntryId,
    //         message: this.brettEintrag.message,
    //         icon: this.brettEintrag.icon,
    //         color: this.brettEintrag.color,
    //         group: this.brettEintrag.groupId,
    //     };
    //     await this.updateBlackboardEntry(data);
    //     this.blackboard = await this.getBlackboard();
    //     this.eintragPopup = false;
    // },
    //
    // async eintragLoeschen() {
    //     await this.deleteBlackboardEntry(this.currentEntryId);
    //     this.blackboard = await this.getBlackboard();
    // },

    customFilter(item, queryText, itemText) {
      const textOne = item.name.toLowerCase();
      const searchText = queryText.toLowerCase();

      return textOne.indexOf(searchText) > -1;
    },

    ISO8601_week_no(dt) {
      let tdt = new Date(dt.valueOf());
      let dayn = (dt.getDay() + 6) % 7;
      tdt.setDate(tdt.getDate() - dayn + 3);
      let firstThursday = tdt.valueOf();
      tdt.setMonth(0, 1);
      if (tdt.getDay() !== 4) {
        tdt.setMonth(0, 1 + ((4 - tdt.getDay() + 7) % 7));
      }
      return 1 + Math.ceil((firstThursday - tdt) / 604800000);
    },

    //vergleicht displayDate mit today, return true wenn gleich um heutigen Tag einzufärben
    isDisplayDateToday(compareDate) {
      let heute, monat, tag;
      let jahr = this.today.toString().slice(13, 15);
      let unshortenedDay = this.today.toString().slice(8, 10);

      //wenn Tag kleiner als 10, schneide erstes Zeichen weg
      if (unshortenedDay.slice(0, 1) === "0") {
        tag = unshortenedDay.slice(1, 2);
      } else {
        tag = unshortenedDay.slice(0, 2);
      }

      //von Monatskürzel zu Zahl (ohne führende null)
      switch (this.today.toString().slice(4, 7)) {
        case "Jan":
          monat = "1";
          break;
        case "Feb":
          monat = "2";
          break;
        case "Mar":
          monat = "3";
          break;
        case "Apr":
          monat = "4";
          break;
        case "May":
          monat = "5";
          break;
        case "Jun":
          monat = "6";
          break;
        case "Jul":
          monat = "7";
          break;
        case "Aug":
          monat = "8";
          break;
        case "Sep":
          monat = "9";
          break;
        case "Oct":
          monat = "10";
          break;
        case "Nov":
          monat = "11";
          break;
        case "Dec":
          monat = "12";
          break;
      }

      //this.date in umgewandelter Form zusammenbauen
      heute = tag + "." + monat + "." + jahr;

      if (
        compareDate.toString().slice(5, compareDate.toString().length) === heute
      ) {
        return true;
      } else {
        return false;
      }
    },
    getDisplayDateMonday() {
      return (
        "Mo., " +
        this.getMonday(this.date).getDate() +
        "." +
        (this.getMonday(this.date).getMonth() + 1) +
        "." +
        this.getMonday(this.date).getFullYear().toString().slice(-2)
      );
    },
    getDisplayDateTuesday() {
      return (
        "Di., " +
        this.getTuesday(this.date).getDate() +
        "." +
        (this.getTuesday(this.date).getMonth() + 1) +
        "." +
        this.getTuesday(this.date).getFullYear().toString().slice(-2)
      );
    },
    getDisplayDateWednesday() {
      return (
        "Mi., " +
        this.getWednesday(this.date).getDate() +
        "." +
        (this.getWednesday(this.date).getMonth() + 1) +
        "." +
        this.getWednesday(this.date).getFullYear().toString().slice(-2)
      );
    },
    getDisplayDateThursday() {
      return (
        "Do., " +
        this.getThursday(this.date).getDate() +
        "." +
        (this.getThursday(this.date).getMonth() + 1) +
        "." +
        this.getThursday(this.date).getFullYear().toString().slice(-2)
      );
    },
    getDisplayDateFriday() {
      return (
        "Fr., " +
        this.getFriday(this.date).getDate() +
        "." +
        (this.getFriday(this.date).getMonth() + 1) +
        "." +
        this.getFriday(this.date).getFullYear().toString().slice(-2)
      );
    },
    getDay() {
      let date = new Date();
      while (!(date.getDay() % 6)) {
        date = new Date(
          date.getFullYear(),
          date.getMonth(),
          date.getDate() - 1
        );
      }
      return date;
    },
    jumpToCurrentDay() {
      this.date = this.today;
    },
    addWeek() {
      this.date = new Date(
        this.date.getFullYear(),
        this.date.getMonth(),
        this.date.getDate() + 7
      );
    },
    subWeek() {
      this.date = new Date(
        this.date.getFullYear(),
        this.date.getMonth(),
        this.date.getDate() - 7
      );
    },
    getMonday(d) {
      d = new Date(d);
      let day = d.getDay(),
        diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
      return new Date(d.setDate(diff));
    },
    getTuesday(d) {
      d = new Date(d);
      let day = d.getDay(),
        diff = d.getDate() - day + (day == 0 ? -5 : 2); // adjust when day is sunday
      return new Date(d.setDate(diff));
    },
    getWednesday(d) {
      d = new Date(d);
      let day = d.getDay(),
        diff = d.getDate() - day + (day == 0 ? -4 : 3); // adjust when day is sunday
      return new Date(d.setDate(diff));
    },
    getThursday(d) {
      d = new Date(d);
      let day = d.getDay(),
        diff = d.getDate() - day + (day == 0 ? -3 : 4); // adjust when day is sunday
      return new Date(d.setDate(diff));
    },
    getFriday(d) {
      d = new Date(d);
      let day = d.getDay(),
        diff = d.getDate() - day + (day == 0 ? -2 : 5); // adjust when day is sunday
      return new Date(d.setDate(diff));
    },

    getAppointment(slot, day) {
      switch (day) {
        case "mon":
          day = this.getMonday(this.date);
          break;
        case "die":
          day = this.getTuesday(this.date);
          break;
        case "mit":
          day = this.getWednesday(this.date);
          break;
        case "don":
          day = this.getThursday(this.date);
          break;
        case "fre":
          day = this.getFriday(this.date);
          break;
      }
      if (this.groups && this.group) {
        const group = this.groups.find((element) => {
          return element.name === this.group;
        });
        let appointment = null;
        if (group) {
          appointment = group.appointments.find((el) => {
            return el.timeslot === slot && this.sameDay(new Date(el.day), day);
          });
        }
        return appointment;
      } else {
        return null;
      }
    },

    getAppointments(slot, day) {
      switch (day) {
        case "mon":
          day = this.getMonday(this.date);
          break;
        case "die":
          day = this.getTuesday(this.date);
          break;
        case "mit":
          day = this.getWednesday(this.date);
          break;
        case "don":
          day = this.getThursday(this.date);
          break;
        case "fre":
          day = this.getFriday(this.date);
          break;
      }
      if (this.groups && this.group) {
        const group = this.groups.find((element) => {
          return element.name === this.group;
        });
        let appointments = [];
        if (group) {
          appointments = group.appointments.filter((el) => {
            return el.timeslot === slot && this.sameDay(new Date(el.day), day);
          });
        }
        return appointments;
      } else {
        return [];
      }
    },

    sameDay(first, second) {
      return (
        first.getFullYear() === second.getFullYear() &&
        first.getMonth() === second.getMonth() &&
        first.getDate() === second.getDate()
      );
    },

    getMonthText(month) {
      if (month + 1 === 1) {
        return "Januar";
      } else if (month + 1 === 2) {
        return "Februar";
      } else if (month + 1 === 3) {
        return "März";
      } else if (month + 1 === 4) {
        return "April";
      } else if (month + 1 === 5) {
        return "Mai";
      } else if (month + 1 === 6) {
        return "Juni";
      } else if (month + 1 === 7) {
        return "Juli";
      } else if (month + 1 === 8) {
        return "August";
      } else if (month + 1 === 9) {
        return "September";
      } else if (month + 1 === 10) {
        return "Oktober";
      } else if (month + 1 === 11) {
        return "November";
      } else if (month + 1 === 12) {
        return "Dezember";
      }
    },

    chooseIcon(iconName) {
      for (let i of this.icons) {
        if (i.text === iconName) {
          return i.icon;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
table,
th,
td {
  border: 1px solid black;
  border-collapse: collapse;
}

tr {
  height: 40px;
}

table tr th:first-child,
table tr td:first-child {
  width: 10px !important;
}

table tr th:nth-child(2),
table tr td:nth-child(2) {
  width: 5px !important;
}

table tr th:last-child,
table tr td:last-child {
  width: 5px !important;
}

table tr th,
table tr td {
  width: 9em;
  border: none;
}
table,
tr,
td {
  border: none;
}

.fach {
  cursor: pointer;
}

.iconSelect {
  width: 42px;
  padding: 2px;
  border: solid 2px #eaeaea;
  border-radius: 10px;
  margin-right: 2px;
}

.iconSelect[selected] {
  width: 42px;
  padding: 2px;
  border: solid 2px #737373;
  border-radius: 10px;
}

.boardIcon {
  height: 20px;
  filter: brightness(100) saturate(100%) invert(97%) sepia(97%) saturate(0%)
    hue-rotate(36deg) brightness(104%) contrast(105%);
}

.textGrau {
  color: #707070;
}

.blur {
  z-index: 8;
  background-color: grey;
}

.diableHoverButton:hover {
  cursor: default;
}

//make text respect line breaks
.blackboardVCardText {
  white-space: pre-line;
}

input[type="date"] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  min-height: 1.6rem;
}
</style>
