<template>
    <v-footer absolute class="footerContainer blueGradient">
        <p class="text white--text ma-0" style="align-self: center">eKlassenraum.de - kinderleicht digitalisieren</p>
        <a href="tel: +4971312641330" class="link white--text ma-0">+49 (0) 7131 - 264 133 0</a>
        <a href="mailto: info@estundenplan.de" class="link white--text ma-0">info@estundenplan.de</a>
        <a href="/datenschutz" class="link white--text">Datenschutz</a>
        <a href="/impressum" class="link white--text">Impressum</a>
    </v-footer>
</template>

<script>
export default {
    name: "Footer"
}
</script>

<style lang="scss" scoped>
.footerContainer {
    display: flex;
    justify-content: space-evenly;
}

.link {
    text-decoration: none;
    padding-left: 16px;
}

.link:hover {
    text-decoration: underline;
}

.blueGradient {
    background: linear-gradient(to right, #6896CF, #4F5FA8);
}

@media (max-width: 700px) {
    .footerContainer {
        flex-direction: column;
    }
}

</style>