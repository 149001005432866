var render = function() {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("progress-bar", {
        attrs: {
          title: "Hochladen",
          "show-progress": _vm.showProfilepicUploadProgress,
          progress: _vm.profilepicUploadProgress,
          "abort-progress": _vm.teacherXmlHttpRequest
            ? function() {
                _vm.teacherXmlHttpRequest.abort()
              }
            : function() {}
        }
      }),
      _c("v-row", { staticClass: "mx-0 mt-3 mb-4" }, [
        _c(
          "div",
          {
            staticStyle: {
              height: "auto",
              width: "90%",
              margin: "auto",
              "border-radius": "15px",
              "box-shadow": "1px 5px 5px silver"
            }
          },
          [
            _c(
              "div",
              {
                staticStyle: {
                  "background-color": "#6995CD",
                  "border-radius": "15px 15px 0 0",
                  "font-size": "larger",
                  "padding-top": "5px",
                  "padding-bottom": "20px",
                  "text-align": "center",
                  color: "white"
                }
              },
              [
                _c(
                  "div",
                  { staticStyle: { display: "flex", width: "50%" } },
                  [
                    _c("img", {
                      staticStyle: {
                        height: "24px",
                        "margin-left": "24px",
                        "margin-top": "20px",
                        filter:
                          "brightness(0) saturate(100%) invert(100%) sepia(5%) saturate(19%) hue-rotate(65deg) brightness(104%) contrast(105%)"
                      },
                      attrs: { src: _vm.suchIcon, alt: "Such-Icon" }
                    }),
                    _c("v-text-field", {
                      staticClass: "pl-6",
                      staticStyle: { width: "50%" },
                      attrs: {
                        label: "Nach Lehrer suchen",
                        "single-line": "",
                        dark: "",
                        "hide-details": ""
                      },
                      model: {
                        value: _vm.search,
                        callback: function($$v) {
                          _vm.search = $$v
                        },
                        expression: "search"
                      }
                    })
                  ],
                  1
                )
              ]
            ),
            _c(
              "div",
              {
                staticStyle: {
                  display: "flex",
                  "font-size": "larger",
                  "font-family": "Roboto",
                  "margin-top": "24px",
                  "margin-left": "26px"
                }
              },
              [
                _c("img", {
                  staticStyle: { height: "24px", "margin-right": "10px" },
                  attrs: { src: _vm.lehrerIcon }
                }),
                _vm._v(" Lehrer ")
              ]
            ),
            _c("v-data-table", {
              staticClass: "ma-3",
              attrs: {
                headers: _vm.headers,
                items: _vm.list,
                search: _vm.search,
                "disable-pagination": "",
                "sort-by": "lastName",
                "hide-default-footer": "",
                "fixed-header": "",
                height: _vm.height,
                locale: "de-DE",
                "no-data-text": "Keine Daten verfügbar",
                "no-results-text":
                  "Keine übereinstimmenden Aufzeichnungen gefunden"
              },
              scopedSlots: _vm._u([
                {
                  key: "item",
                  fn: function(row) {
                    return [
                      _c("tr", [
                        _c("td", [_vm._v(_vm._s(row.item.lastName))]),
                        _c("td", [_vm._v(_vm._s(row.item.name))]),
                        _c("td", [_vm._v(_vm._s(row.item.gender))]),
                        _c(
                          "td",
                          [
                            _c(
                              "v-btn",
                              {
                                staticClass: "mx-2",
                                staticStyle: {
                                  "border-radius": "5px",
                                  "text-transform": "inherit"
                                },
                                attrs: {
                                  color: "#EAEAEA",
                                  elevation: "1",
                                  small: "",
                                  light: ""
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.editUser(row.item)
                                  }
                                }
                              },
                              [_vm._v(" Lehrerprofil bearbeiten ")]
                            )
                          ],
                          1
                        )
                      ])
                    ]
                  }
                }
              ])
            }),
            _c(
              "v-overlay",
              {
                staticStyle: { width: "100% !important" },
                attrs: { absolute: "", value: _vm.showLoadingOverlay }
              },
              [
                _vm.showLoadingOverlay
                  ? _c("v-progress-circular", {
                      attrs: { indeterminate: "", size: "100" }
                    })
                  : _vm._e()
              ],
              1
            )
          ],
          1
        )
      ]),
      _c("v-row", { staticClass: "mx-0 my-3", attrs: { id: "edit" } }, [
        _c(
          "div",
          { staticClass: "editContainer" },
          [
            _c(
              "v-row",
              {
                staticClass: "mx-0 ",
                staticStyle: {
                  "background-color": "#6995CD",
                  "border-radius": "15px 15px 0 0"
                }
              },
              [
                _c(
                  "v-col",
                  {
                    staticClass: "ma-1 ml-5",
                    staticStyle: { color: "white", "font-size": "larger" }
                  },
                  [
                    _vm.profilePic !== ""
                      ? _c(
                          "v-avatar",
                          {
                            class: { errorInput: _vm.fields.profilePic },
                            attrs: { size: "41" }
                          },
                          [
                            _c("img", {
                              attrs: { id: "profilePic", src: _vm.profilePic }
                            })
                          ]
                        )
                      : _c(
                          "v-avatar",
                          {
                            class: { errorInput: _vm.fields.profilePic },
                            attrs: { size: "41" }
                          },
                          [
                            _c("img", {
                              attrs: {
                                id: "profilePicPlaceholder",
                                src: _vm.diversPic
                              }
                            })
                          ]
                        ),
                    _c("input", {
                      ref: "profilepicUploadInput",
                      attrs: {
                        id: "profilepicUploadInput",
                        type: "file",
                        hidden: ""
                      },
                      on: {
                        change: function() {
                          return _vm.teacherUploadInputChange()
                        }
                      }
                    }),
                    _c(
                      "v-btn",
                      {
                        staticStyle: {
                          "margin-left": "-15px",
                          "margin-top": "19px",
                          "max-width": "22px",
                          "max-height": "22px"
                        },
                        attrs: {
                          fab: "",
                          "x-small": "",
                          elevation: "0",
                          color: "#BDBDBD"
                        },
                        on: { click: _vm.clickProfilepicUploadButton }
                      },
                      [
                        _c("img", {
                          staticStyle: { "max-width": "11px" },
                          attrs: { src: _vm.bearbeitenIcon }
                        })
                      ]
                    ),
                    _vm._v(
                      " Lehrer " +
                        _vm._s(_vm.oldProfile ? "bearbeiten" : "anlegen") +
                        " "
                    )
                  ],
                  1
                ),
                _c(
                  "v-col",
                  {
                    staticClass: "mr-6",
                    staticStyle: {
                      display: "flex",
                      "justify-content": "flex-end"
                    }
                  },
                  [
                    _c(
                      "v-tooltip",
                      {
                        attrs: {
                          top: "",
                          "open-on-click": false,
                          "open-on-hover": true,
                          "content-class": "infoTextToolTip"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _c(
                                  "span",
                                  _vm._g(
                                    _vm._b(
                                      { staticClass: "my-auto" },
                                      "span",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [
                                    !_vm.oldProfile && _vm.debugMode
                                      ? _c(
                                          "v-btn",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                staticClass:
                                                  "mainFunctionButtons ml-3 my-auto",
                                                attrs: {
                                                  color: "#FFFFFF40",
                                                  "x-small": ""
                                                },
                                                on: {
                                                  click:
                                                    _vm.clickExelUploadButton
                                                }
                                              },
                                              "v-btn",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _c("img", {
                                              attrs: { src: _vm.hochladenIcon }
                                            })
                                          ]
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ])
                      },
                      [
                        _c("span", [
                          _vm._v(
                            "Hier können Sie einen Excel von Lehrern hochladen."
                          )
                        ])
                      ]
                    ),
                    _c(
                      "v-btn",
                      {
                        staticClass: "mainFunctionButtons ml-3 my-auto",
                        attrs: {
                          color: "#FFFFFF40",
                          "x-small": "",
                          disabled: !_vm.oldProfile
                        },
                        on: { click: _vm.openDeleteDialog }
                      },
                      [_c("img", { attrs: { src: _vm.loeschIcon } })]
                    ),
                    _c(
                      "v-tooltip",
                      {
                        attrs: {
                          top: "",
                          "open-on-click": false,
                          "open-on-hover": true,
                          "content-class": "infoTextToolTip"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _c(
                                  "span",
                                  _vm._g(
                                    _vm._b(
                                      { staticClass: "my-auto" },
                                      "span",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass:
                                          "mainFunctionButtons ml-3 ",
                                        attrs: {
                                          color: "#FFFFFF40",
                                          "x-small": "",
                                          disabled: ""
                                        },
                                        on: { click: _vm.pdf }
                                      },
                                      [
                                        _c("img", {
                                          attrs: { src: _vm.pdfDocIcon }
                                        })
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ])
                      },
                      [_c("span", [_vm._v("Aktuell noch nicht verfügbar.")])]
                    ),
                    _c(
                      "v-tooltip",
                      {
                        attrs: {
                          top: "",
                          "nudge-right": "100",
                          "open-on-click": true,
                          "open-on-hover": false,
                          "content-class": "infoTextToolTip"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        staticClass:
                                          "mainFunctionButtons ml-3 my-auto",
                                        attrs: {
                                          color: "#FFFFFF40",
                                          "x-small": ""
                                        }
                                      },
                                      "v-btn",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [_c("img", { attrs: { src: _vm.infoIcon } })]
                                )
                              ]
                            }
                          }
                        ])
                      },
                      [
                        _c("span", [
                          _vm._v(
                            "Hier können Sie Lehrer anlegen oder bearbeiten."
                          )
                        ])
                      ]
                    ),
                    _c(
                      "v-btn",
                      {
                        staticClass: "mainControlButtons ml-3 my-auto pa-5",
                        attrs: { color: "#BDBDBD50", dark: "", elevation: "0" },
                        on: { click: _vm.stopEdit }
                      },
                      [_c("span", [_vm._v("Abbrechen")])]
                    ),
                    _c(
                      "v-btn",
                      {
                        staticClass: "mainControlButtons ml-3 my-auto pa-5",
                        attrs: { color: "#69CD70", dark: "", elevation: "4" },
                        on: { click: _vm.openDialog }
                      },
                      [_c("span", [_vm._v("Speichern")])]
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "mx-2 mb-2" },
              [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { staticClass: "mx-5" },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "font-weight-bold",
                            staticStyle: { color: "rgba(0, 0, 0, 0.6)" }
                          },
                          [_vm._v(" Persönliche Daten ")]
                        ),
                        _c(
                          "v-row",
                          [
                            _c("v-col", { attrs: { cols: "5" } }, [
                              _vm._v(" Vorname: ")
                            ]),
                            _c("v-col", { attrs: { cols: "7" } }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.name,
                                    expression: "name"
                                  }
                                ],
                                staticClass: "input",
                                class: { errorInput: _vm.fields.name },
                                attrs: { type: "text" },
                                domProps: { value: _vm.name },
                                on: {
                                  change: function() {
                                    _vm.suggestLoginId(_vm.oldProfile)
                                  },
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.name = $event.target.value
                                  }
                                }
                              })
                            ])
                          ],
                          1
                        ),
                        _vm.fields.name
                          ? _c(
                              "v-row",
                              { attrs: { dense: "" } },
                              [
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "pl-2; pt-0",
                                    staticStyle: { "margin-top": "-10px" }
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          color: "red",
                                          "font-size": "12px"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "Name darf keine Zahlen erhalten."
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "v-row",
                          [
                            _c("v-col", { attrs: { cols: "5" } }, [
                              _vm._v(" Nachname: ")
                            ]),
                            _c("v-col", { attrs: { cols: "7" } }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.lastName,
                                    expression: "lastName"
                                  }
                                ],
                                staticClass: "input",
                                class: { errorInput: _vm.fields.lastName },
                                attrs: { type: "text" },
                                domProps: { value: _vm.lastName },
                                on: {
                                  change: function() {
                                    _vm.suggestLoginId(_vm.oldProfile)
                                  },
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.lastName = $event.target.value
                                  }
                                }
                              })
                            ])
                          ],
                          1
                        ),
                        _vm.fields.lastName && _vm.lastName.length === 0
                          ? _c(
                              "v-row",
                              { attrs: { dense: "" } },
                              [
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "pl-2; pt-0",
                                    staticStyle: { "margin-top": "-10px" }
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          color: "red",
                                          "font-size": "12px"
                                        }
                                      },
                                      [_vm._v("Pflichtfeld")]
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          : _vm.fields.lastName
                          ? _c(
                              "v-row",
                              { attrs: { dense: "" } },
                              [
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "pl-2; pt-0",
                                    staticStyle: { "margin-top": "-10px" }
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          color: "red",
                                          "font-size": "12px"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "Name darf keine Zahlen erhalten."
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "v-row",
                          [
                            _c("v-col", { attrs: { cols: "5" } }, [
                              _vm._v(" Geschlecht: ")
                            ]),
                            _c("v-col", { attrs: { cols: "7" } }, [
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.gender,
                                      expression: "gender"
                                    }
                                  ],
                                  staticClass: "input",
                                  class: { errorInput: _vm.fields.gender },
                                  on: {
                                    change: [
                                      function($event) {
                                        var $$selectedVal = Array.prototype.filter
                                          .call($event.target.options, function(
                                            o
                                          ) {
                                            return o.selected
                                          })
                                          .map(function(o) {
                                            var val =
                                              "_value" in o ? o._value : o.value
                                            return val
                                          })
                                        _vm.gender = $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      },
                                      _vm.validateInput
                                    ]
                                  }
                                },
                                [
                                  _c(
                                    "option",
                                    {
                                      attrs: {
                                        disabled: "",
                                        selected: "",
                                        value: ""
                                      }
                                    },
                                    [_vm._v(" Auswählen")]
                                  ),
                                  _c("option", { attrs: { value: "m" } }, [
                                    _vm._v("Männlich")
                                  ]),
                                  _c("option", { attrs: { value: "w" } }, [
                                    _vm._v("Weiblich")
                                  ]),
                                  _c("option", { attrs: { value: "d" } }, [
                                    _vm._v("Divers")
                                  ])
                                ]
                              )
                            ])
                          ],
                          1
                        ),
                        _vm.fields.gender
                          ? _c(
                              "v-row",
                              { attrs: { dense: "" } },
                              [
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "pt-0",
                                    staticStyle: { "margin-top": "-10px" }
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          color: "red",
                                          "font-size": "12px"
                                        }
                                      },
                                      [_vm._v("Pflichtfeld")]
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { staticClass: "mx-5" },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "font-weight-bold",
                            staticStyle: { color: "rgba(0, 0, 0, 0.6)" }
                          },
                          [_vm._v(" Schulisches ")]
                        ),
                        _c(
                          "v-row",
                          [
                            _c("v-col", { attrs: { cols: "5" } }, [
                              _vm._v(" Gruppen: ")
                            ]),
                            _c(
                              "v-col",
                              { attrs: { cols: "7" } },
                              _vm._l(_vm.teacherGroups, function(item, index) {
                                return _c("v-row", { key: index }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "px-3 py-1 mb-1",
                                      staticStyle: {
                                        "min-width": "200px",
                                        "background-color": "#EAEAEA",
                                        "border-radius": "8px"
                                      }
                                    },
                                    [_vm._v(_vm._s(item.name))]
                                  )
                                ])
                              }),
                              1
                            )
                          ],
                          1
                        ),
                        _c("v-row", [_c("v-col")], 1),
                        _c("v-row", [_c("v-col")], 1),
                        _c(
                          "v-row",
                          [
                            _c("v-col", { attrs: { cols: "5" } }, [
                              _vm._v(" Fächer: ")
                            ]),
                            _c(
                              "v-col",
                              { attrs: { cols: "7" } },
                              _vm._l(_vm.teacherSubjects, function(
                                item,
                                index
                              ) {
                                return _c("v-row", { key: index }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "px-3 py-1 mb-1",
                                      staticStyle: {
                                        "min-width": "200px",
                                        "background-color": "#EAEAEA",
                                        "border-radius": "8px"
                                      }
                                    },
                                    [_vm._v(" " + _vm._s(item.name) + " ")]
                                  )
                                ])
                              }),
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { staticClass: "mx-5", attrs: { cols: "12", md: "3" } },
                      [
                        _c(
                          "v-row",
                          { attrs: { dense: "" } },
                          [
                            _c("v-col", [
                              _c(
                                "div",
                                {
                                  staticClass: "font-weight-bold",
                                  staticStyle: { color: "rgba(0, 0, 0, 0.6)" }
                                },
                                [_vm._v(" Technisches ")]
                              )
                            ]),
                            _c(
                              "v-col",
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "generatePasswordButton",
                                    attrs: { elevation: "0", icon: "" },
                                    on: { click: _vm.makePassword }
                                  },
                                  [
                                    _c("img", {
                                      attrs: { src: _vm.aktualisierenIcon }
                                    }),
                                    _vm._v("Passwort generieren ")
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          [
                            _c("v-col", { attrs: { cols: "5" } }, [
                              _vm._v(" Login-ID: ")
                            ]),
                            _c(
                              "v-col",
                              {
                                attrs: { cols: "7" },
                                on: {
                                  click: function($event) {
                                    _vm.editLoginId = true
                                  }
                                }
                              },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.userName,
                                      expression: "userName"
                                    }
                                  ],
                                  staticClass: "input",
                                  class: { errorInput: _vm.fields.userName },
                                  attrs: {
                                    type: "text",
                                    disabled: !_vm.editLoginId || _vm.oldProfile
                                  },
                                  domProps: { value: _vm.userName },
                                  on: {
                                    change: _vm.validateInput,
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.userName = $event.target.value
                                    }
                                  }
                                })
                              ]
                            )
                          ],
                          1
                        ),
                        _vm.fields.userName
                          ? _c(
                              "v-row",
                              { attrs: { dense: "" } },
                              [
                                _c(
                                  "v-col",
                                  {
                                    staticStyle: {
                                      "padding-top": "0",
                                      "margin-top": "-10px"
                                    }
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          color: "red",
                                          "font-size": "12px"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "Login-ID darf nur Zahlen und Buchstaben erhalten."
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "v-row",
                          [
                            _c("v-col", { attrs: { cols: "5" } }, [
                              _vm._v(" Passwort: ")
                            ]),
                            _c(
                              "v-col",
                              {
                                attrs: { cols: "7" },
                                on: {
                                  click: function($event) {
                                    _vm.editPassword = true
                                  }
                                }
                              },
                              [
                                (_vm.editPassword
                                  ? "text"
                                  : _vm.oldProfile && !_vm.newPassword
                                  ? "password"
                                  : "text") === "checkbox"
                                  ? _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.password,
                                          expression: "password"
                                        }
                                      ],
                                      staticClass: "input",
                                      class: {
                                        errorInput: _vm.fields.password
                                      },
                                      attrs: {
                                        disabled: !_vm.editPassword,
                                        type: "checkbox"
                                      },
                                      domProps: {
                                        checked: Array.isArray(_vm.password)
                                          ? _vm._i(_vm.password, null) > -1
                                          : _vm.password
                                      },
                                      on: {
                                        change: [
                                          function($event) {
                                            var $$a = _vm.password,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? true : false
                                            if (Array.isArray($$a)) {
                                              var $$v = null,
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  (_vm.password = $$a.concat([
                                                    $$v
                                                  ]))
                                              } else {
                                                $$i > -1 &&
                                                  (_vm.password = $$a
                                                    .slice(0, $$i)
                                                    .concat($$a.slice($$i + 1)))
                                              }
                                            } else {
                                              _vm.password = $$c
                                            }
                                          },
                                          _vm.validateInput
                                        ]
                                      }
                                    })
                                  : (_vm.editPassword
                                      ? "text"
                                      : _vm.oldProfile && !_vm.newPassword
                                      ? "password"
                                      : "text") === "radio"
                                  ? _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.password,
                                          expression: "password"
                                        }
                                      ],
                                      staticClass: "input",
                                      class: {
                                        errorInput: _vm.fields.password
                                      },
                                      attrs: {
                                        disabled: !_vm.editPassword,
                                        type: "radio"
                                      },
                                      domProps: {
                                        checked: _vm._q(_vm.password, null)
                                      },
                                      on: {
                                        change: [
                                          function($event) {
                                            _vm.password = null
                                          },
                                          _vm.validateInput
                                        ]
                                      }
                                    })
                                  : _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.password,
                                          expression: "password"
                                        }
                                      ],
                                      staticClass: "input",
                                      class: {
                                        errorInput: _vm.fields.password
                                      },
                                      attrs: {
                                        disabled: !_vm.editPassword,
                                        type: _vm.editPassword
                                          ? "text"
                                          : _vm.oldProfile && !_vm.newPassword
                                          ? "password"
                                          : "text"
                                      },
                                      domProps: { value: _vm.password },
                                      on: {
                                        change: _vm.validateInput,
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.password = $event.target.value
                                        }
                                      }
                                    })
                              ]
                            )
                          ],
                          1
                        ),
                        _vm.fields.password
                          ? _c(
                              "v-row",
                              { attrs: { dense: "" } },
                              [
                                _c(
                                  "v-col",
                                  {
                                    staticStyle: {
                                      "padding-top": "0",
                                      "margin-top": "-10px"
                                    }
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          color: "red",
                                          "font-size": "12px"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "Passwort erfüllt Richtlinie nicht."
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { staticClass: "pt-0", attrs: { cols: "12" } },
                              [
                                _c(
                                  "p",
                                  {
                                    staticClass: "mb-2",
                                    staticStyle: { color: "#808080" }
                                  },
                                  [
                                    _vm._v(
                                      "Muss mind. 8 Zeichen lang sein und einen Buchstaben und eine Zahl enthalten."
                                    )
                                  ]
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _c(
        "v-snackbar",
        {
          attrs: { bottom: "", color: "error", timeout: "3000" },
          model: {
            value: _vm.snackbar,
            callback: function($$v) {
              _vm.snackbar = $$v
            },
            expression: "snackbar"
          }
        },
        [_vm._v(" " + _vm._s(_vm.snackbarText) + " ")]
      ),
      _c(
        "v-snackbar",
        {
          attrs: { bottom: "", color: "success", timeout: "3000" },
          model: {
            value: _vm.snackbarSave,
            callback: function($$v) {
              _vm.snackbarSave = $$v
            },
            expression: "snackbarSave"
          }
        },
        [_vm._v(" Gespeichert! ")]
      ),
      _c(
        "v-dialog",
        {
          staticStyle: { "border-radius": "8px" },
          attrs: { width: "60%", persistent: "" },
          model: {
            value: _vm.deleteTeacherDialog,
            callback: function($$v) {
              _vm.deleteTeacherDialog = $$v
            },
            expression: "deleteTeacherDialog"
          }
        },
        [
          _c(
            "v-row",
            {
              staticClass: "ma-0",
              staticStyle: {
                "background-color": "#505FAA",
                "font-size": "larger",
                height: "60px"
              },
              attrs: { "align-content": "center" }
            },
            [
              _c(
                "v-col",
                { attrs: { cols: "1" } },
                [
                  _vm.deletePagesIndex >= 1
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "ma-auto replaceTeacherControls",
                          attrs: { "x-small": "" },
                          on: {
                            click: function($event) {
                              _vm.deletePagesIndex--
                            }
                          }
                        },
                        [
                          _c("img", {
                            staticStyle: { transform: "rotate(90deg)" },
                            attrs: { src: _vm.arrow }
                          })
                        ]
                      )
                    : _vm._e()
                ],
                1
              ),
              _c("v-col", { attrs: { cols: "10" } }, [
                _c(
                  "p",
                  {
                    staticClass: "ma-auto",
                    staticStyle: {
                      color: "white",
                      "font-size": "larger",
                      "text-align": "center"
                    }
                  },
                  [_vm._v("Lehrer löschen und neu verwalten")]
                )
              ]),
              _c(
                "v-col",
                { attrs: { cols: "1" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "ma-auto replaceTeacherControls",
                      staticStyle: { float: "right" },
                      attrs: { "x-small": "" },
                      on: {
                        click: function() {
                          _vm.deleteTeacherDialog = false
                          _vm.deletePagesIndex = 0
                        }
                      }
                    },
                    [_c("img", { attrs: { src: _vm.abbrechen } })]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("v-card", [
            _vm.deletePagesIndex === 0
              ? _c(
                  "div",
                  { attrs: { id: "delTeacher1" } },
                  [
                    _c(
                      "v-col",
                      [
                        _c(
                          "v-row",
                          [
                            _c("v-col", { attrs: { cols: "10" } }, [
                              _c("p", [
                                _vm._v(
                                  " Wollen Sie diesen Lehrer wirklich löschen? Wählen Sie zwischen 2 Möglichkeiten: "
                                )
                              ]),
                              _c("p", [
                                _c("b", [
                                  _vm._v(
                                    " 1. Lehrer löschen und neu verwalten. (Empfohlen) "
                                  )
                                ]),
                                _c("br"),
                                _vm._v(
                                  " Sie können neue Gruppenleiter und Fachlehrer auswählen und Ihren zu löschenden Lehrer ersetzen. "
                                )
                              ])
                            ]),
                            _c(
                              "v-col",
                              { attrs: { cols: "2" } },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: { color: "#69CD70", disabled: "" },
                                    on: {
                                      click: function($event) {
                                        _vm.deletePagesIndex++
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(" Weiter "),
                                    _c("img", {
                                      staticStyle: {
                                        transform: "rotate(270deg)",
                                        width: "24px",
                                        filter: "brightness(1000%)"
                                      },
                                      attrs: { src: _vm.arrow }
                                    })
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          [
                            _c("v-col", { attrs: { cols: " 10" } }, [
                              _c("p", [
                                _c("b", [
                                  _vm._v(
                                    " 2. Lehrer und alle verknüpften Daten endgültig löschen. "
                                  )
                                ]),
                                _c("br"),
                                _vm._v(
                                  " Daten des zu löschenden Lehrers und alle verknüpften Gruppen werden "
                                ),
                                _c("b", [_vm._v("restlos gelöscht")]),
                                _vm._v(". "),
                                _c("br"),
                                _vm._v(
                                  " Dies führt dazu, dass Sie Gruppen nochmals einpflegen müssen und auch Fächer im Stundenplan erneut Gruppen zuteilen müssen. "
                                )
                              ])
                            ]),
                            _c(
                              "v-col",
                              { attrs: { cols: "2" } },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: { color: "#e6231e", dark: "" },
                                    on: {
                                      click: function() {
                                        _vm.deleteDialog = true
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(" Löschen "),
                                    _c("img", {
                                      staticStyle: {
                                        width: "24px",
                                        filter: "brightness(1000%)"
                                      },
                                      attrs: { src: _vm.abbrechen }
                                    })
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm.deletePagesIndex === 1
              ? _c(
                  "div",
                  { attrs: { id: "delTeacher2" } },
                  [
                    _c(
                      "v-col",
                      [
                        _c(
                          "v-row",
                          [
                            _c("v-col", [
                              _c("p", [
                                _c("b", [_vm._v("1. Gruppen verwalten")]),
                                _c("br"),
                                _vm._v(
                                  " Falls Ihr zu löschender Lehrer ein Gruppenleiter war, werden hier nun die geleiteten Gruppen aufgelistet. Teilen Sie der Gruppe einen neuen Gruppenleiter zu. "
                                )
                              ])
                            ])
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              [
                                _c(
                                  "v-row",
                                  { staticClass: "mx-1" },
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        staticStyle: { color: "#707070" },
                                        attrs: { cols: "4" }
                                      },
                                      [_vm._v("Name")]
                                    ),
                                    _c(
                                      "v-col",
                                      {
                                        staticStyle: { color: "#707070" },
                                        attrs: { cols: "2" }
                                      },
                                      [_vm._v("Aktueller Lehrer")]
                                    ),
                                    _c("v-col", { attrs: { cols: "1" } }),
                                    _c(
                                      "v-col",
                                      {
                                        staticStyle: { color: "#707070" },
                                        attrs: { cols: "2" }
                                      },
                                      [_vm._v("Zukünftiger Lehrer")]
                                    ),
                                    _c("v-col", { attrs: { cols: "3" } })
                                  ],
                                  1
                                ),
                                _vm._l(_vm.deleteGroupData, function(
                                  item,
                                  index
                                ) {
                                  return _c(
                                    "v-row",
                                    {
                                      key: index,
                                      staticClass: "mx-1 pt-1",
                                      staticStyle: {
                                        "border-bottom": "2px solid #EAEAEA"
                                      }
                                    },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "pl-2 mt-2 basicDeleteTeacherColumn",
                                          attrs: { cols: "4" }
                                        },
                                        [_vm._v(_vm._s(item.groupName))]
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "mt-2 basicDeleteTeacherColumn deleteCurrentTeacherDataCol",
                                          attrs: { cols: "2" }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(item.currentFullName) +
                                              " "
                                          ),
                                          _vm.profilePic
                                            ? _c("img", {
                                                attrs: { src: _vm.profilePic }
                                              })
                                            : _c("img", {
                                                attrs: {
                                                  src: _vm.getPersonPic(
                                                    _vm.gender
                                                  )
                                                }
                                              })
                                        ]
                                      ),
                                      _c("v-col", { attrs: { cols: "1" } }, [
                                        _c("img", {
                                          staticClass: "ma-auto",
                                          staticStyle: { width: "20px" },
                                          attrs: { src: _vm.rechtsPfeil }
                                        })
                                      ]),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "mt-2 basicDeleteTeacherColumn deleteCurrentTeacherDataCol",
                                          attrs: { cols: "2" }
                                        },
                                        [
                                          item.newTeacherPicture != null
                                            ? _c("img", {
                                                attrs: {
                                                  src: item.newTeacherPicture
                                                }
                                              })
                                            : item.newTeacher != null &&
                                              item.newTeacherPicture == null
                                            ? _c("img", {
                                                attrs: {
                                                  src: _vm.getPersonPic(
                                                    item.newTeacher.gender
                                                  )
                                                }
                                              })
                                            : _c(
                                                "v-icon",
                                                {
                                                  staticStyle: {
                                                    "font-size": "25px",
                                                    color: "grey"
                                                  }
                                                },
                                                [_vm._v("fas fa-circle")]
                                              )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "mt-1 basicDeleteTeacherColumn deleteTeacherNewSelection",
                                          attrs: { cols: "3" }
                                        },
                                        [
                                          _c(
                                            "v-container",
                                            {
                                              attrs: {
                                                id: "dropdown-example-1"
                                              }
                                            },
                                            [
                                              _c("v-select", {
                                                staticClass:
                                                  "deleteTeacherSelect",
                                                attrs: {
                                                  solo: "",
                                                  "background-color":
                                                    item.newTeacher != null
                                                      ? "success"
                                                      : "error",
                                                  dense: "",
                                                  dark: "",
                                                  items: _vm.list,
                                                  "return-object": "",
                                                  "item-text": function(xyz) {
                                                    return (
                                                      xyz.name +
                                                      "  " +
                                                      xyz.lastName
                                                    )
                                                  }
                                                },
                                                on: {
                                                  change: function($event) {
                                                    return _vm.changeNewTeacher(
                                                      _vm.deleteGroupData[index]
                                                        .newTeacher
                                                    )
                                                  }
                                                },
                                                model: {
                                                  value:
                                                    _vm.deleteGroupData[index]
                                                      .newTeacher,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.deleteGroupData[
                                                        index
                                                      ],
                                                      "newTeacher",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "deleteGroupData[index].newTeacher"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                }),
                                !_vm.deleteGroupData.length
                                  ? _c("v-row", [
                                      _vm._v(
                                        " Dieser Lehrer hatte keine Gruppenleitung! "
                                      )
                                    ])
                                  : _vm._e()
                              ],
                              2
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          [
                            _c("v-col", { attrs: { cols: "2" } }),
                            _c("v-col", {
                              staticStyle: { "justify-content": "center" },
                              attrs: { cols: "8" }
                            }),
                            _c(
                              "v-col",
                              { attrs: { cols: "2" } },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticStyle: { float: "right" },
                                    attrs: {
                                      dark: _vm.isDeleteGroupDataDone,
                                      color: "success",
                                      disabled: !_vm.isDeleteGroupDataDone
                                    },
                                    on: {
                                      click: function($event) {
                                        _vm.deletePagesIndex++
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(" Weiter "),
                                    _c("img", {
                                      staticStyle: {
                                        transform: "rotate(270deg)",
                                        width: "24px",
                                        filter: "brightness(1000%)"
                                      },
                                      attrs: { src: _vm.arrow }
                                    })
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm.deletePagesIndex === 2
              ? _c(
                  "div",
                  { attrs: { id: "delTeacher3" } },
                  [
                    _c(
                      "v-col",
                      [
                        _c(
                          "v-row",
                          [
                            _c("v-col", [
                              _c("p", [
                                _c("b", [_vm._v("2. Fächer verwalten")]),
                                _c("br"),
                                _vm._v(
                                  " Falls Ihr zu löschender Lehrer in Fächern unterrichtet hat, werden hier nun diese Fächer aufgelistet. Teilen Sie dem Fach einen neuen Fachlehrer zu. "
                                )
                              ])
                            ])
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              [
                                _c(
                                  "v-row",
                                  { staticClass: "mx-1" },
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        staticStyle: { color: "#707070" },
                                        attrs: { cols: "4" }
                                      },
                                      [_vm._v("Fächer")]
                                    ),
                                    _c(
                                      "v-col",
                                      {
                                        staticStyle: { color: "#707070" },
                                        attrs: { cols: "2" }
                                      },
                                      [_vm._v("Aktueller Lehrer")]
                                    ),
                                    _c("v-col", { attrs: { cols: "1" } }),
                                    _c(
                                      "v-col",
                                      {
                                        staticStyle: { color: "#707070" },
                                        attrs: { cols: "2" }
                                      },
                                      [_vm._v("Zukünftiger Lehrer")]
                                    ),
                                    _c("v-col", { attrs: { cols: "3" } })
                                  ],
                                  1
                                ),
                                _vm._l(_vm.deleteSubjectData, function(
                                  item,
                                  index
                                ) {
                                  return _c(
                                    "v-row",
                                    {
                                      key: index,
                                      staticClass: "mx-1 pt-1",
                                      staticStyle: {
                                        "border-bottom": "2px solid #EAEAEA"
                                      }
                                    },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "pl-2 mt-2 basicDeleteTeacherColumn",
                                          attrs: { cols: "5" }
                                        },
                                        [_vm._v(_vm._s(item.subject))]
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "mt-2 basicDeleteTeacherColumn deleteCurrentTeacherDataCol",
                                          attrs: { cols: "1" }
                                        },
                                        [
                                          _vm.profilePic
                                            ? _c("img", {
                                                attrs: { src: _vm.profilePic }
                                              })
                                            : _c("img", {
                                                attrs: {
                                                  src: _vm.getPersonPic(
                                                    _vm.gender
                                                  )
                                                }
                                              })
                                        ]
                                      ),
                                      _c("v-col", { attrs: { cols: "1" } }, [
                                        _c("img", {
                                          staticClass: "ma-auto",
                                          staticStyle: { width: "20px" },
                                          attrs: { src: _vm.rechtsPfeil }
                                        })
                                      ]),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "mt-2 basicDeleteTeacherColumn deleteCurrentTeacherDataCol",
                                          attrs: { cols: "2" }
                                        },
                                        [
                                          item.newTeacherPicture != null
                                            ? _c("img", {
                                                attrs: {
                                                  src: item.newTeacherPicture
                                                }
                                              })
                                            : item.newTeacher != null &&
                                              item.newTeacherPicture == null
                                            ? _c("img", {
                                                attrs: {
                                                  src: _vm.getPersonPic(
                                                    item.newTeacher.gender
                                                  )
                                                }
                                              })
                                            : _c(
                                                "v-icon",
                                                {
                                                  staticStyle: {
                                                    "font-size": "25px",
                                                    color: "grey"
                                                  }
                                                },
                                                [_vm._v("fas fa-circle")]
                                              )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "mt-1 basicDeleteTeacherColumn deleteTeacherNewSelection",
                                          attrs: { cols: "3" }
                                        },
                                        [
                                          _c(
                                            "v-container",
                                            {
                                              attrs: {
                                                id: "dropdown-example-1"
                                              }
                                            },
                                            [
                                              _c("v-select", {
                                                staticClass:
                                                  "deleteTeacherSelect",
                                                attrs: {
                                                  solo: "",
                                                  "background-color":
                                                    item.newTeacher != null
                                                      ? "success"
                                                      : "error",
                                                  dense: "",
                                                  dark: "",
                                                  items:
                                                    _vm.teacherSubjects[index]
                                                      .teachers,
                                                  "return-object": "",
                                                  "item-text": function(xyz) {
                                                    return (
                                                      xyz.name +
                                                      "  " +
                                                      xyz.lastName
                                                    )
                                                  }
                                                },
                                                on: {
                                                  change: function($event) {
                                                    return _vm.changeNewTeacher(
                                                      _vm.deleteSubjectData[
                                                        index
                                                      ].newTeacher
                                                    )
                                                  }
                                                },
                                                model: {
                                                  value:
                                                    _vm.deleteSubjectData[index]
                                                      .newTeacher,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.deleteSubjectData[
                                                        index
                                                      ],
                                                      "newTeacher",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "deleteSubjectData[index].newTeacher"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                }),
                                !_vm.deleteSubjectData.length
                                  ? _c("v-row", [
                                      _vm._v(
                                        " Dieser Lehrer hatte keine Fächer! "
                                      )
                                    ])
                                  : _vm._e()
                              ],
                              2
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticStyle: { float: "right" },
                                    attrs: {
                                      color: "success",
                                      disabled: !_vm.isDeleteSubjectDataDone,
                                      dark: _vm.isDeleteSubjectDataDone
                                    },
                                    on: { click: _vm.printAllChanges }
                                  },
                                  [_vm._v(" Speichern ")]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e()
          ])
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "400" },
          model: {
            value: _vm.deleteDialog,
            callback: function($$v) {
              _vm.deleteDialog = $$v
            },
            expression: "deleteDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "headline" }, [
                _vm._v("Benutzer löschen?")
              ]),
              _c("v-card-text", [
                _vm._v(
                  " Sind sie sicher, dass sie den Benutzer löschen möchten? "
                )
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "", text: "" },
                      on: {
                        click: function($event) {
                          _vm.deleteDialog = false
                        }
                      }
                    },
                    [_vm._v("Abbrechen")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "red red-1", text: "" },
                      on: {
                        click: function() {
                          this$1.deleteUser()
                          this$1.deleteDialog = false
                        }
                      }
                    },
                    [_vm._v("Löschen")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "400" },
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "headline" }, [
                _vm._v("Account speichern?")
              ]),
              _c("v-card-text", [
                _vm._v(
                  " Ein Account wurde mit folgenden Daten angelegt.An erster Stelle sehen Sie das Passwort, welches für den neuen Benutzer generiert wurde. Bitte notieren Sie dieses und händigen Sie es an dem Benutzer aus. "
                ),
                _c("br"),
                _vm._v(" "),
                _c("br"),
                _vm._v(
                  " Dieses Passwort wird später nicht mehr angezeigt und kann auch nicht wiederhergestellt werden. Im Zweifelsfall müssen Sie dem Benutzer ein neues Passwort zuweisen, wenn er sich nicht mehr einloggen kann. "
                ),
                _c("br"),
                _vm._v(" "),
                _c("br"),
                _c(
                  "div",
                  { staticClass: "ml-3" },
                  [
                    _c("v-row", [
                      _vm._v(" Passwort: " + _vm._s(_vm.password) + " ")
                    ]),
                    _c("v-row", [
                      _vm._v(" Login-ID: " + _vm._s(_vm.userName) + " ")
                    ]),
                    _c("v-row", [
                      _vm._v(" Vorname: " + _vm._s(_vm.name) + " ")
                    ]),
                    _c("v-row", [
                      _vm._v(" Nachname: " + _vm._s(_vm.lastName) + " ")
                    ]),
                    _c("v-row", [
                      _vm._v(" Geschlecht: " + _vm._s(_vm.gender) + " ")
                    ]),
                    _c(
                      "v-row",
                      {
                        staticClass: "mt-5",
                        attrs: { align: "center", justify: "center" }
                      },
                      [
                        _c(
                          "div",
                          { staticStyle: { width: "200px" } },
                          [
                            _c("qrcode-vue", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: false,
                                  expression: "false"
                                }
                              ],
                              ref: "qrcodecontainer",
                              attrs: { value: _vm.qrCodeData, size: "200" }
                            }),
                            _c(
                              "v-row",
                              {
                                staticClass: "ma-0",
                                staticStyle: {
                                  width: "200px",
                                  "text-align-last": "center"
                                }
                              },
                              [
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "pa-0",
                                    attrs: { cols: "12" }
                                  },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        on: {
                                          click: function($event) {
                                            return _vm.pdf()
                                          }
                                        }
                                      },
                                      [_vm._v("QR Download")]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    )
                  ],
                  1
                )
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "green darken-1", dark: "" },
                      on: { click: _vm.closeDialogs }
                    },
                    [_vm._v("Fertig")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "400" },
          model: {
            value: _vm.dialog2,
            callback: function($$v) {
              _vm.dialog2 = $$v
            },
            expression: "dialog2"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "headline" }, [
                _vm._v("Account speichern?")
              ]),
              _c("v-card-text", [
                _vm._v(
                  " Ein Account wird mit folgenden Daten geändert. An erster Stelle sehen Sie das Passwort, welches für den neuen Benutzer generiert wurde. Bitte notieren Sie dieses und händigen Sie es an dem Benutzer aus. "
                ),
                _c("br"),
                _vm._v(" "),
                _c("br"),
                _vm._v(
                  " Dieses Passwort wird später nicht mehr angezeigt und kann auch nicht wiederhergestellt werden. Im Zweifelsfall müssen Sie dem Benutzer ein neues Passwort zuweisen, wenn er sich nicht mehr einloggen kann. "
                ),
                _c("br"),
                _vm._v(" "),
                _c("br"),
                _c(
                  "div",
                  { staticClass: "ml-3" },
                  [
                    _c("v-row", [
                      _vm._v(" Passwort: " + _vm._s(_vm.password) + " ")
                    ]),
                    _c("v-row", [
                      _vm._v(" Login-ID: " + _vm._s(_vm.userName) + " ")
                    ]),
                    _c("v-row", [
                      _vm._v(" Vorname: " + _vm._s(_vm.name) + " ")
                    ]),
                    _c("v-row", [
                      _vm._v(" Nachname: " + _vm._s(_vm.lastName) + " ")
                    ]),
                    _c("v-row", [
                      _vm._v(" Geschlecht: " + _vm._s(_vm.gender) + " ")
                    ]),
                    _c(
                      "v-row",
                      {
                        staticClass: "mt-5",
                        attrs: { align: "center", justify: "center" }
                      },
                      [
                        _c(
                          "div",
                          { staticStyle: { width: "200px" } },
                          [
                            _c("qrcode-vue", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: false,
                                  expression: "false"
                                }
                              ],
                              ref: "qrcodecontainer",
                              attrs: { value: _vm.qrCodeData, size: "200" }
                            }),
                            _c(
                              "v-row",
                              {
                                staticClass: "ma-0",
                                staticStyle: {
                                  width: "200px",
                                  "text-align-last": "center"
                                }
                              },
                              [
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "pa-0",
                                    attrs: { cols: "12" }
                                  },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        on: {
                                          click: function($event) {
                                            return _vm.pdf()
                                          }
                                        }
                                      },
                                      [_vm._v("QR Download")]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    )
                  ],
                  1
                )
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "green darken-1", dark: "" },
                      on: { click: _vm.closeDialogs }
                    },
                    [_vm._v("Fertig")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("qrcode-vue", {
        directives: [
          { name: "show", rawName: "v-show", value: false, expression: "false" }
        ],
        ref: "hiddenqrcodecontainer",
        attrs: { value: _vm.qrCodeData, size: "200" }
      }),
      _c("input", {
        ref: "exelUploadInput",
        attrs: { id: "exelUploadInput", type: "file", hidden: "" },
        on: {
          change: function() {
            return _vm.exelUploadInputChange()
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }