var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-row", { staticClass: "mx-0" }, [
        _c(
          "div",
          {
            staticClass: "whiteBg",
            staticStyle: {
              height: "auto",
              width: "90%",
              "min-height": "80vh",
              "border-radius": "15px",
              "box-shadow": "1px 5px 5px silver",
              margin: "auto auto 5em"
            }
          },
          [
            _c(
              "div",
              {
                staticStyle: {
                  "background-color": "#6995CD",
                  "border-radius": "15px 15px 0 0",
                  "padding-top": "10px",
                  "padding-bottom": "10px",
                  color: "white"
                }
              },
              [
                this.windowWidth > 600
                  ? _c(
                      "v-row",
                      {
                        staticClass:
                          "mx-2 d-flex align-center justify-space-between",
                        staticStyle: { "max-width": "100%" }
                      },
                      [
                        _c(
                          "v-col",
                          {
                            staticClass: "pa-0 pl-2 d-flex align-center",
                            staticStyle: { "font-size": "larger" },
                            attrs: { cols: "9", md: "8", lg: "8" }
                          },
                          [
                            _c("img", {
                              staticClass: "iconToWhite mr-1",
                              staticStyle: { height: "20px" },
                              attrs: { src: _vm.stundenplanIcon }
                            }),
                            _vm._v(" Stundenplan ")
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "d-flex justify-end align-center" },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "pa-0 calWeek",
                                staticStyle: { "font-size": "medium" }
                              },
                              [
                                _vm._v(
                                  " KW " +
                                    _vm._s(_vm.getMonday(_vm.date).getWeek()) +
                                    " "
                                )
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "pa-0" },
                              [
                                !_vm.small
                                  ? _c(
                                      "v-btn",
                                      {
                                        staticClass: "ml-2",
                                        staticStyle: {
                                          "background-color": "#f8f8f880",
                                          width: "35px",
                                          height: "35px"
                                        },
                                        attrs: { "x-small": "" },
                                        on: {
                                          click: function() {
                                            _vm.planVollbild = true
                                            _vm.requestAppointments()
                                          }
                                        }
                                      },
                                      [
                                        _c("img", {
                                          staticClass: "iconToWhite",
                                          staticStyle: { height: "20px" },
                                          attrs: { src: _vm.vollbildIcon }
                                        })
                                      ]
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          ]
                        )
                      ],
                      1
                    )
                  : _c(
                      "v-row",
                      {
                        staticClass:
                          "d-flex align-center justify-space-between",
                        class: this.windowWidth > 340 ? "mx-2" : "mx-1",
                        staticStyle: {
                          "max-width": "100%",
                          height: "35px",
                          "max-height": "35px"
                        }
                      },
                      [
                        _c(
                          "v-col",
                          {
                            staticClass:
                              "pa-0 d-flex align-center justify-space-between",
                            class: _vm.dateMargin(this.windowWidth),
                            attrs: { cols: _vm.calcCols(this.windowWidth) }
                          },
                          [
                            _c(
                              "v-badge",
                              {
                                attrs: {
                                  color: "red",
                                  content: _vm.past,
                                  value: _vm.past,
                                  overlap: "",
                                  left: ""
                                }
                              },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticStyle: {
                                      "background-color": "#f8f8f880"
                                    },
                                    attrs: {
                                      small: "",
                                      icon: "",
                                      color: "white"
                                    },
                                    on: { click: _vm.subDay }
                                  },
                                  [
                                    _c("img", {
                                      staticClass: "iconToWhite",
                                      staticStyle: { height: "20px" },
                                      attrs: { src: _vm.linksIcon }
                                    })
                                  ]
                                )
                              ],
                              1
                            ),
                            _c(
                              "p",
                              {
                                staticClass: "planWidgetDisplayDate mb-0 mx-1",
                                staticStyle: {
                                  color: "#ffffff",
                                  "font-weight": "500"
                                }
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.getDisplayDateShort()) + " "
                                )
                              ]
                            ),
                            _c(
                              "v-badge",
                              {
                                attrs: {
                                  color: "red",
                                  content: _vm.future,
                                  value: _vm.future,
                                  overlap: ""
                                }
                              },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticStyle: {
                                      "background-color": "#f8f8f880"
                                    },
                                    attrs: {
                                      small: "",
                                      icon: "",
                                      color: "white"
                                    },
                                    on: { click: _vm.addDay }
                                  },
                                  [
                                    _c("img", {
                                      staticClass: "iconToWhite",
                                      staticStyle: { height: "20px" },
                                      attrs: { src: _vm.rechtsIcon }
                                    })
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          {
                            staticClass: "pa-0 d-flex align-center justify-end",
                            class: _vm.todayMargin(this.windowWidth),
                            attrs: { cols: "4" }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "calWeek mr-1",
                                style:
                                  this.windowWidth > 370
                                    ? "font-size: medium"
                                    : "font-size: 12px"
                              },
                              [
                                _vm._v(
                                  " KW " +
                                    _vm._s(_vm.getMonday(_vm.date).getWeek()) +
                                    " "
                                )
                              ]
                            ),
                            _c(
                              "v-btn",
                              {
                                staticClass: "ml-1 pa-0 text-capitalize",
                                staticStyle: {
                                  "background-color": "#f8f8f880",
                                  color: "#ffffff"
                                },
                                attrs: { elevation: "0", small: "" },
                                on: {
                                  click: function($event) {
                                    return _vm.jumpToCurrentDay()
                                  }
                                }
                              },
                              [_vm._v(" Heute ")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "whiteBg",
                staticStyle: { "border-radius": "15px" }
              },
              [
                this.windowWidth > 600
                  ? _c(
                      "v-row",
                      {
                        staticClass: "whiteBg",
                        staticStyle: { width: "99%", margin: "0 auto 0.5em" },
                        attrs: { cols: "12" }
                      },
                      [
                        _c(
                          "v-col",
                          {
                            staticClass: "text-center pb-0 clockIcon",
                            attrs: {
                              cols:
                                this.windowWidth < 960 && this.windowWidth > 900
                                  ? 3
                                  : 2,
                              md: "3"
                            }
                          },
                          [
                            _c("img", {
                              attrs: { src: _vm.uhrIcon, width: "35" }
                            })
                          ]
                        ),
                        _c(
                          "v-col",
                          {
                            staticClass: "px-2",
                            staticStyle: {
                              display: "flex",
                              "justify-content": "center"
                            },
                            attrs: {
                              cols:
                                this.windowWidth < 960 && this.windowWidth > 900
                                  ? 3
                                  : 2,
                              md: "2"
                            }
                          },
                          [
                            _c(
                              "v-badge",
                              {
                                attrs: {
                                  color: "red",
                                  content: _vm.past,
                                  value: _vm.past,
                                  overlap: "",
                                  left: ""
                                }
                              },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticStyle: {
                                      "background-color": "#6995CD"
                                    },
                                    attrs: { icon: "", color: "white" },
                                    on: { click: _vm.subDay }
                                  },
                                  [
                                    _c("img", {
                                      staticClass: "iconToWhite",
                                      staticStyle: { height: "24px" },
                                      attrs: { src: _vm.linksIcon }
                                    })
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          {
                            staticClass: "text-center pa-0 pt-4",
                            attrs: { cols: "6", sm: "6", md: "5" }
                          },
                          [
                            _c(
                              "p",
                              {
                                staticClass: "planWidgetDisplayDate mb-2",
                                style: _vm.isDisplayDateToday(
                                  _vm.getDisplayDate()
                                )
                                  ? "color: #6995CD; font-weight: bold !important"
                                  : "color: #707070; font-weight: 500"
                              },
                              [_vm._v(" " + _vm._s(_vm.getDisplayDate()) + " ")]
                            ),
                            _c(
                              "div",
                              { staticStyle: { width: "100%" } },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "text-capitalize",
                                    class: _vm.isDisplayDateToday(
                                      _vm.getDisplayDate()
                                    )
                                      ? "planWidgetTodayButton"
                                      : "planWidgetTodayButtonActive",
                                    attrs: { elevation: "0", small: "" },
                                    on: {
                                      click: function($event) {
                                        return _vm.jumpToCurrentDay()
                                      }
                                    }
                                  },
                                  [_vm._v(" Heute ")]
                                )
                              ],
                              1
                            )
                          ]
                        ),
                        _c(
                          "v-col",
                          {
                            staticClass: "px-2",
                            staticStyle: {
                              display: "flex",
                              "justify-content": "center"
                            },
                            attrs: {
                              cols:
                                this.windowWidth < 960 && this.windowWidth > 900
                                  ? 3
                                  : 2,
                              md: "2"
                            }
                          },
                          [
                            _c(
                              "v-badge",
                              {
                                attrs: {
                                  color: "red",
                                  content: _vm.future,
                                  value: _vm.future,
                                  overlap: ""
                                }
                              },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticStyle: {
                                      "background-color": "#6995CD"
                                    },
                                    attrs: { icon: "", color: "white" },
                                    on: { click: _vm.addDay }
                                  },
                                  [
                                    _c("img", {
                                      staticClass: "iconToWhite",
                                      staticStyle: { height: "24px" },
                                      attrs: { src: _vm.rechtsIcon }
                                    })
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "vue-custom-scrollbar",
                  {
                    staticClass: "scroll-area",
                    attrs: { settings: _vm.settings }
                  },
                  [
                    _c(
                      "table",
                      {
                        staticStyle: {
                          width: "99%",
                          margin: "0.5em auto 5em auto",
                          color: "#707070"
                        }
                      },
                      _vm._l(_vm.slots, function(item, index) {
                        return _c(
                          "v-row",
                          {
                            key: index,
                            staticStyle: {
                              width: "99%",
                              margin: "0.1em auto 0.1em auto"
                            },
                            attrs: { cols: "12" }
                          },
                          [
                            _c(
                              "v-col",
                              {
                                staticClass: "text-center pa-2",
                                staticStyle: {
                                  "font-weight": "normal",
                                  color: "#707070"
                                },
                                attrs: { cols: "2" }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      (
                                        "0" + new Date(item.start).getHours()
                                      ).slice(-2) +
                                        ":" +
                                        (
                                          "0" +
                                          new Date(item.start).getMinutes()
                                        ).slice(-2)
                                    ) +
                                    " "
                                ),
                                _c("br"),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      (
                                        "0" + new Date(item.end).getHours()
                                      ).slice(-2) +
                                        ":" +
                                        (
                                          "0" + new Date(item.end).getMinutes()
                                        ).slice(-2)
                                    ) +
                                    " "
                                )
                              ]
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "pa-0 pl-4",
                                attrs: { cols: "10" }
                              },
                              [
                                _vm.getAppointmentsToday(item._id).length
                                  ? _c(
                                      "div",
                                      _vm._l(
                                        _vm.getAppointmentsToday(item._id),
                                        function(el) {
                                          return _c(
                                            "div",
                                            { key: JSON.stringify(el) },
                                            [
                                              _c("Fach", {
                                                attrs: {
                                                  "is-running": _vm.isTimeslotCurrentlyRunningToday(
                                                    item
                                                  ),
                                                  "is-joinable": _vm.isJoinable(
                                                    item
                                                  ),
                                                  isOpen: el.open,
                                                  appointment: el,
                                                  requestAppointments:
                                                    _vm.requestAppointments
                                                },
                                                on: {
                                                  "pass-on-bbb-tut":
                                                    _vm.receivedEmit
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        }
                                      ),
                                      0
                                    )
                                  : _c(
                                      "div",
                                      [
                                        _c("Fach", {
                                          attrs: {
                                            "is-running": _vm.isTimeslotCurrentlyRunningToday(
                                              item
                                            ),
                                            requestAppointments:
                                              _vm.requestAppointments,
                                            "is-joinable": _vm.isJoinable(item)
                                          },
                                          on: {
                                            "pass-on-bbb-tut": _vm.receivedEmit
                                          }
                                        })
                                      ],
                                      1
                                    )
                              ]
                            )
                          ],
                          1
                        )
                      }),
                      1
                    )
                  ]
                )
              ],
              1
            )
          ]
        )
      ]),
      _c(
        "v-dialog",
        {
          staticStyle: { "border-radius": "15px" },
          on: {
            "click:outside": function($event) {
              _vm.planVollbild = false
            }
          },
          model: {
            value: _vm.planVollbild,
            callback: function($$v) {
              _vm.planVollbild = $$v
            },
            expression: "planVollbild"
          }
        },
        [
          _c(
            "v-row",
            {
              staticClass: "ma-0",
              staticStyle: { "z-index": "15", "overflow-x": "hidden" }
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    "border-radius": "15px",
                    width: "100%",
                    "box-shadow": "1px 5px 5px silver",
                    background: "white"
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "background-color": "#6995CD",
                        "border-radius": "15px 15px 0 0",
                        "padding-top": "10px",
                        "padding-bottom": "10px",
                        color: "white"
                      }
                    },
                    [
                      _c(
                        "v-row",
                        {
                          staticClass: "mx-4",
                          staticStyle: {
                            display: "flex",
                            "align-items": "center"
                          }
                        },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "pa-0 text-left",
                              staticStyle: {
                                "font-size": "x-large",
                                display: "flex",
                                "align-content": "center"
                              },
                              attrs: { cols: "4" }
                            },
                            [
                              _c("img", {
                                staticClass: "iconToWhite",
                                staticStyle: { height: "35px" },
                                attrs: { src: _vm.stundenplanIcon }
                              }),
                              _c("p", { staticClass: "pl-2 mb-0" }, [
                                _vm._v("Stundenplan")
                              ])
                            ]
                          ),
                          _c(
                            "v-col",
                            { staticClass: "pa-0", attrs: { cols: "3" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mr-4 text-capitalize",
                                  staticStyle: {
                                    "background-color": "#f8f8f880",
                                    color: "white"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.jumpToCurrentDay()
                                    }
                                  }
                                },
                                [_vm._v(" Heute ")]
                              ),
                              _c(
                                "v-badge",
                                {
                                  attrs: {
                                    color: "red",
                                    content: _vm.pastWeek,
                                    value: _vm.pastWeek,
                                    overlap: "",
                                    left: ""
                                  }
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "mx-2",
                                      staticStyle: {
                                        "background-color": "#f8f8f880"
                                      },
                                      attrs: {
                                        elevation: "2",
                                        icon: "",
                                        small: ""
                                      },
                                      on: { click: _vm.subWeek }
                                    },
                                    [
                                      _c("img", {
                                        staticClass: "iconToWhite",
                                        staticStyle: { height: "20px" },
                                        attrs: { src: _vm.linksIcon }
                                      })
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-badge",
                                {
                                  attrs: {
                                    color: "red",
                                    content: _vm.futureWeek,
                                    value: _vm.futureWeek,
                                    overlap: ""
                                  }
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "mx-2",
                                      staticStyle: {
                                        "background-color": "#f8f8f880"
                                      },
                                      attrs: {
                                        elevation: "2",
                                        icon: "",
                                        small: ""
                                      },
                                      on: { click: _vm.addWeek }
                                    },
                                    [
                                      _c("img", {
                                        staticClass: "iconToWhite",
                                        staticStyle: { height: "20px" },
                                        attrs: { src: _vm.rechtsIcon }
                                      })
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "px-0 text-right",
                              attrs: { cols: "2" }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.getDisplayMonth() +
                                      " " +
                                      _vm.getMonday(_vm.date).getFullYear()
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "px-0 text-right",
                              staticStyle: { "font-size": "medium" },
                              attrs: { cols: "2" }
                            },
                            [
                              _vm._v(
                                " KW " +
                                  _vm._s(_vm.getMonday(_vm.date).getWeek()) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "px-0 text-right",
                              attrs: { cols: "1" }
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticStyle: {
                                    "background-color": "#f8f8f880",
                                    width: "48px",
                                    height: "48px"
                                  },
                                  attrs: { "x-small": "", elevation: "2" },
                                  on: {
                                    click: function($event) {
                                      _vm.planVollbild = false
                                    }
                                  }
                                },
                                [
                                  _c("img", {
                                    staticClass: "iconToWhite",
                                    staticStyle: { height: "40px" },
                                    attrs: { src: _vm.schliessenIcon }
                                  })
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    {
                      staticStyle: { width: "99%", margin: "1em auto 0 auto" }
                    },
                    [
                      _c("th", { staticStyle: { width: "10%" } }, [
                        _c("img", {
                          attrs: { src: _vm.uhrIcon, width: "24", height: "24" }
                        })
                      ]),
                      _c("v-row", { staticStyle: { width: "1%" } }),
                      _c(
                        "v-row",
                        {
                          staticClass: "centerHeader",
                          staticStyle: { width: "17%" }
                        },
                        [
                          _vm.isDisplayDateTodayShort(
                            _vm.getDisplayDateMonday()
                          )
                            ? _c(
                                "th",
                                {
                                  staticClass: "colHeaderCurrent text-center",
                                  staticStyle: { width: "100%" }
                                },
                                [
                                  _vm._v(
                                    "Mo., " +
                                      _vm._s(
                                        _vm.getMonday(_vm.date).getDate() +
                                          "." +
                                          (_vm.getMonday(_vm.date).getMonth() +
                                            1) +
                                          "." +
                                          _vm
                                            .getMonday(_vm.date)
                                            .getFullYear()
                                            .toString()
                                            .slice(-2)
                                      )
                                  )
                                ]
                              )
                            : _c(
                                "th",
                                {
                                  staticClass: "colHeader text-center",
                                  staticStyle: { width: "100%" }
                                },
                                [
                                  _vm._v(
                                    "Mo., " +
                                      _vm._s(
                                        _vm.getMonday(_vm.date).getDate() +
                                          "." +
                                          (_vm.getMonday(_vm.date).getMonth() +
                                            1) +
                                          "." +
                                          _vm
                                            .getMonday(_vm.date)
                                            .getFullYear()
                                            .toString()
                                            .slice(-2)
                                      )
                                  )
                                ]
                              )
                        ]
                      ),
                      _c("v-row", { staticStyle: { width: "1%" } }),
                      _c(
                        "v-row",
                        {
                          staticClass: "greyDayHeader centerHeader",
                          staticStyle: { width: "17%" }
                        },
                        [
                          _vm.isDisplayDateTodayShort(
                            _vm.getDisplayDateTuesday()
                          )
                            ? _c(
                                "th",
                                {
                                  staticClass: "colHeaderCurrent text-center",
                                  staticStyle: { width: "100%" }
                                },
                                [
                                  _vm._v(
                                    "Di., " +
                                      _vm._s(
                                        _vm.getTuesday(_vm.date).getDate() +
                                          "." +
                                          (_vm.getTuesday(_vm.date).getMonth() +
                                            1) +
                                          "." +
                                          _vm
                                            .getTuesday(_vm.date)
                                            .getFullYear()
                                            .toString()
                                            .slice(-2)
                                      )
                                  )
                                ]
                              )
                            : _c(
                                "th",
                                {
                                  staticClass: "colHeader text-center",
                                  staticStyle: { width: "100%" }
                                },
                                [
                                  _vm._v(
                                    "Di., " +
                                      _vm._s(
                                        _vm.getTuesday(_vm.date).getDate() +
                                          "." +
                                          (_vm.getTuesday(_vm.date).getMonth() +
                                            1) +
                                          "." +
                                          _vm
                                            .getTuesday(_vm.date)
                                            .getFullYear()
                                            .toString()
                                            .slice(-2)
                                      )
                                  )
                                ]
                              )
                        ]
                      ),
                      _c("v-row", { staticStyle: { width: "1%" } }),
                      _c(
                        "v-row",
                        {
                          staticClass: "centerHeader",
                          staticStyle: { width: "17%" }
                        },
                        [
                          _vm.isDisplayDateTodayShort(
                            _vm.getDisplayDateWednesday()
                          )
                            ? _c(
                                "th",
                                {
                                  staticClass: "colHeaderCurrent text-center",
                                  staticStyle: { width: "100%" }
                                },
                                [
                                  _vm._v(
                                    "Mi., " +
                                      _vm._s(
                                        _vm.getWednesday(_vm.date).getDate() +
                                          "." +
                                          (_vm
                                            .getWednesday(_vm.date)
                                            .getMonth() +
                                            1) +
                                          "." +
                                          _vm
                                            .getWednesday(_vm.date)
                                            .getFullYear()
                                            .toString()
                                            .slice(-2)
                                      )
                                  )
                                ]
                              )
                            : _c(
                                "th",
                                {
                                  staticClass: "colHeader text-center",
                                  staticStyle: { width: "100%" }
                                },
                                [
                                  _vm._v(
                                    "Mi., " +
                                      _vm._s(
                                        _vm.getWednesday(_vm.date).getDate() +
                                          "." +
                                          (_vm
                                            .getWednesday(_vm.date)
                                            .getMonth() +
                                            1) +
                                          "." +
                                          _vm
                                            .getWednesday(_vm.date)
                                            .getFullYear()
                                            .toString()
                                            .slice(-2)
                                      )
                                  )
                                ]
                              )
                        ]
                      ),
                      _c("v-row", { staticStyle: { width: "1%" } }),
                      _c(
                        "v-row",
                        {
                          staticClass: "greyDayHeader centerHeader",
                          staticStyle: { width: "17%" }
                        },
                        [
                          _vm.isDisplayDateTodayShort(
                            _vm.getDisplayDateThursday()
                          )
                            ? _c(
                                "th",
                                {
                                  staticClass: "colHeaderCurrent text-center",
                                  staticStyle: { width: "100%" }
                                },
                                [
                                  _vm._v(
                                    "Do., " +
                                      _vm._s(
                                        _vm.getThursday(_vm.date).getDate() +
                                          "." +
                                          (_vm
                                            .getThursday(_vm.date)
                                            .getMonth() +
                                            1) +
                                          "." +
                                          _vm
                                            .getThursday(_vm.date)
                                            .getFullYear()
                                            .toString()
                                            .slice(-2)
                                      )
                                  )
                                ]
                              )
                            : _c(
                                "th",
                                {
                                  staticClass: "colHeader text-center",
                                  staticStyle: { width: "100%" }
                                },
                                [
                                  _vm._v(
                                    "Do., " +
                                      _vm._s(
                                        _vm.getThursday(_vm.date).getDate() +
                                          "." +
                                          (_vm
                                            .getThursday(_vm.date)
                                            .getMonth() +
                                            1) +
                                          "." +
                                          _vm
                                            .getThursday(_vm.date)
                                            .getFullYear()
                                            .toString()
                                            .slice(-2)
                                      )
                                  )
                                ]
                              )
                        ]
                      ),
                      _c("v-row", { staticStyle: { width: "1%" } }),
                      _c(
                        "v-row",
                        {
                          staticClass: "centerHeader",
                          staticStyle: { width: "17%" }
                        },
                        [
                          _vm.isDisplayDateTodayShort(
                            _vm.getDisplayDateFriday()
                          )
                            ? _c(
                                "th",
                                {
                                  staticClass: "colHeaderCurrent text-center",
                                  staticStyle: { width: "100%" }
                                },
                                [
                                  _vm._v(
                                    "Fr., " +
                                      _vm._s(
                                        _vm.getFriday(_vm.date).getDate() +
                                          "." +
                                          (_vm.getFriday(_vm.date).getMonth() +
                                            1) +
                                          "." +
                                          _vm
                                            .getFriday(_vm.date)
                                            .getFullYear()
                                            .toString()
                                            .slice(-2)
                                      )
                                  )
                                ]
                              )
                            : _c(
                                "th",
                                {
                                  staticClass: "colHeader text-center",
                                  staticStyle: { width: "100%" }
                                },
                                [
                                  _vm._v(
                                    "Fr., " +
                                      _vm._s(
                                        _vm.getFriday(_vm.date).getDate() +
                                          "." +
                                          (_vm.getFriday(_vm.date).getMonth() +
                                            1) +
                                          "." +
                                          _vm
                                            .getFriday(_vm.date)
                                            .getFullYear()
                                            .toString()
                                            .slice(-2)
                                      )
                                  )
                                ]
                              )
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "vue-custom-scrollbar",
                    {
                      staticClass: "scroll-area",
                      attrs: { settings: _vm.settings }
                    },
                    _vm._l(_vm.slots, function(item, index) {
                      return _c(
                        "v-row",
                        {
                          key: index,
                          staticStyle: { width: "99%", margin: "0 auto 0 auto" }
                        },
                        [
                          _c(
                            "td",
                            {
                              staticClass:
                                "d-flex align-center justify-center pa-2",
                              staticStyle: {
                                width: "10%",
                                color: "#707070",
                                "font-weight": "normal"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    (
                                      "0" + new Date(item.start).getHours()
                                    ).slice(-2) +
                                      ":" +
                                      (
                                        "0" + new Date(item.start).getMinutes()
                                      ).slice(-2)
                                  ) +
                                  " "
                              ),
                              _c("br"),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    ("0" + new Date(item.end).getHours()).slice(
                                      -2
                                    ) +
                                      ":" +
                                      (
                                        "0" + new Date(item.end).getMinutes()
                                      ).slice(-2)
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c("v-row", { staticStyle: { width: "1%" } }),
                          _c(
                            "v-row",
                            {
                              staticClass: "centerFach",
                              staticStyle: { width: "17%" }
                            },
                            [
                              _vm.getAppointmentsPerDay(item._id, "mon").length
                                ? _c(
                                    "div",
                                    { staticStyle: { width: "100%" } },
                                    _vm._l(
                                      _vm.getAppointmentsPerDay(
                                        item._id,
                                        "mon"
                                      ),
                                      function(el) {
                                        return _c(
                                          "div",
                                          { key: JSON.stringify(el) },
                                          [
                                            _c("Fach", {
                                              attrs: {
                                                "is-running": _vm.isTimeslotCurrentlyRunning(
                                                  item,
                                                  "mon"
                                                ),
                                                appointment: el,
                                                requestAppointments:
                                                  _vm.requestAppointments,
                                                "is-joinable": _vm.isJoinable(
                                                  item
                                                )
                                              },
                                              on: {
                                                "pass-on-bbb-tut":
                                                  _vm.receivedEmit
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    0
                                  )
                                : _c(
                                    "div",
                                    { staticStyle: { width: "100%" } },
                                    [
                                      _c("Fach", {
                                        attrs: {
                                          "is-running": _vm.isTimeslotCurrentlyRunning(
                                            item,
                                            "mon"
                                          ),
                                          requestAppointments:
                                            _vm.requestAppointments,
                                          "is-joinable": _vm.isJoinable(item)
                                        },
                                        on: {
                                          "pass-on-bbb-tut": _vm.receivedEmit
                                        }
                                      })
                                    ],
                                    1
                                  )
                            ]
                          ),
                          _c("v-row", { staticStyle: { width: "1%" } }),
                          _c(
                            "v-row",
                            {
                              staticClass: "centerFach greyDayBody",
                              staticStyle: { width: "17%" },
                              style:
                                index != Object.keys(_vm.slots).length - 1
                                  ? ""
                                  : "border-radius: 0 0 10px 10px"
                            },
                            [
                              _vm.getAppointmentsPerDay(item._id, "die").length
                                ? _c(
                                    "div",
                                    { staticStyle: { width: "100%" } },
                                    _vm._l(
                                      _vm.getAppointmentsPerDay(
                                        item._id,
                                        "die"
                                      ),
                                      function(el) {
                                        return _c(
                                          "div",
                                          { key: JSON.stringify(el) },
                                          [
                                            _c("Fach", {
                                              attrs: {
                                                "is-running": _vm.isTimeslotCurrentlyRunning(
                                                  item,
                                                  "die"
                                                ),
                                                appointment: el,
                                                requestAppointments:
                                                  _vm.requestAppointments,
                                                "is-joinable": _vm.isJoinable(
                                                  item
                                                )
                                              },
                                              on: {
                                                "pass-on-bbb-tut":
                                                  _vm.receivedEmit
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    0
                                  )
                                : _c(
                                    "div",
                                    { staticStyle: { width: "100%" } },
                                    [
                                      _c("Fach", {
                                        attrs: {
                                          "is-running": _vm.isTimeslotCurrentlyRunning(
                                            item,
                                            "die"
                                          ),
                                          requestAppointments:
                                            _vm.requestAppointments,
                                          "is-joinable": _vm.isJoinable(item)
                                        },
                                        on: {
                                          "pass-on-bbb-tut": _vm.receivedEmit
                                        }
                                      })
                                    ],
                                    1
                                  )
                            ]
                          ),
                          _c("v-row", { staticStyle: { width: "1%" } }),
                          _c(
                            "v-row",
                            {
                              staticClass: "centerFach",
                              staticStyle: { width: "17%" }
                            },
                            [
                              _vm.getAppointmentsPerDay(item._id, "mit").length
                                ? _c(
                                    "div",
                                    { staticStyle: { width: "100%" } },
                                    _vm._l(
                                      _vm.getAppointmentsPerDay(
                                        item._id,
                                        "mit"
                                      ),
                                      function(el) {
                                        return _c(
                                          "div",
                                          { key: JSON.stringify(el) },
                                          [
                                            _c("Fach", {
                                              attrs: {
                                                "is-running": _vm.isTimeslotCurrentlyRunning(
                                                  item,
                                                  "mit"
                                                ),
                                                appointment: el,
                                                requestAppointments:
                                                  _vm.requestAppointments,
                                                "is-joinable": _vm.isJoinable(
                                                  item
                                                )
                                              },
                                              on: {
                                                "pass-on-bbb-tut":
                                                  _vm.receivedEmit
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    0
                                  )
                                : _c(
                                    "div",
                                    { staticStyle: { width: "100%" } },
                                    [
                                      _c("Fach", {
                                        attrs: {
                                          "is-running": _vm.isTimeslotCurrentlyRunning(
                                            item,
                                            "mit"
                                          ),
                                          requestAppointments:
                                            _vm.requestAppointments,
                                          "is-joinable": _vm.isJoinable(item)
                                        },
                                        on: {
                                          "pass-on-bbb-tut": _vm.receivedEmit
                                        }
                                      })
                                    ],
                                    1
                                  )
                            ]
                          ),
                          _c("v-row", { staticStyle: { width: "1%" } }),
                          _c(
                            "v-row",
                            {
                              staticClass: "centerFach greyDayBody",
                              staticStyle: { width: "17%" },
                              style:
                                index != Object.keys(_vm.slots).length - 1
                                  ? ""
                                  : "border-radius: 0 0 10px 10px"
                            },
                            [
                              _vm.getAppointmentsPerDay(item._id, "don").length
                                ? _c(
                                    "div",
                                    { staticStyle: { width: "100%" } },
                                    _vm._l(
                                      _vm.getAppointmentsPerDay(
                                        item._id,
                                        "don"
                                      ),
                                      function(el) {
                                        return _c(
                                          "div",
                                          { key: JSON.stringify(el) },
                                          [
                                            _c("Fach", {
                                              attrs: {
                                                "is-running": _vm.isTimeslotCurrentlyRunning(
                                                  item,
                                                  "don"
                                                ),
                                                appointment: el,
                                                requestAppointments:
                                                  _vm.requestAppointments,
                                                "is-joinable": _vm.isJoinable(
                                                  item
                                                )
                                              },
                                              on: {
                                                "pass-on-bbb-tut":
                                                  _vm.receivedEmit
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    0
                                  )
                                : _c(
                                    "div",
                                    { staticStyle: { width: "100%" } },
                                    [
                                      _c("Fach", {
                                        attrs: {
                                          "is-running": _vm.isTimeslotCurrentlyRunning(
                                            item,
                                            "don"
                                          ),
                                          requestAppointments:
                                            _vm.requestAppointments,
                                          "is-joinable": _vm.isJoinable(item)
                                        },
                                        on: {
                                          "pass-on-bbb-tut": _vm.receivedEmit
                                        }
                                      })
                                    ],
                                    1
                                  )
                            ]
                          ),
                          _c("v-row", { staticStyle: { width: "1%" } }),
                          _c(
                            "v-row",
                            {
                              staticClass: "centerFach",
                              staticStyle: { width: "17%" }
                            },
                            [
                              _vm.getAppointmentsPerDay(item._id, "fre").length
                                ? _c(
                                    "div",
                                    { staticStyle: { width: "100%" } },
                                    _vm._l(
                                      _vm.getAppointmentsPerDay(
                                        item._id,
                                        "fre"
                                      ),
                                      function(el) {
                                        return _c(
                                          "div",
                                          { key: JSON.stringify(el) },
                                          [
                                            _c("Fach", {
                                              attrs: {
                                                "is-running": _vm.isTimeslotCurrentlyRunning(
                                                  item,
                                                  "fre"
                                                ),
                                                appointment: el,
                                                requestAppointments:
                                                  _vm.requestAppointments,
                                                "is-joinable": _vm.isJoinable(
                                                  item
                                                )
                                              },
                                              on: {
                                                "pass-on-bbb-tut":
                                                  _vm.receivedEmit
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    0
                                  )
                                : _c(
                                    "div",
                                    { staticStyle: { width: "100%" } },
                                    [
                                      _c("Fach", {
                                        attrs: {
                                          "is-running": _vm.isTimeslotCurrentlyRunning(
                                            item,
                                            "fre"
                                          ),
                                          requestAppointments:
                                            _vm.requestAppointments,
                                          "is-joinable": _vm.isJoinable(item)
                                        },
                                        on: {
                                          "pass-on-bbb-tut": _vm.receivedEmit
                                        }
                                      })
                                    ],
                                    1
                                  )
                            ]
                          )
                        ],
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }