var render = function() {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "group-chat-wrapper" },
    [
      _c(
        "div",
        { staticClass: "group-chat-header d-flex align-center" },
        [
          _c(
            "v-btn",
            {
              staticClass: "ma-2 group-chat-header-btn",
              attrs: { color: "white", fab: "", text: "", small: "", dark: "" },
              on: { click: _vm.clickBack }
            },
            [
              _c("img", {
                staticClass: "icon iconToWhite",
                staticStyle: { transform: "rotate(90deg)" },
                attrs: { src: _vm.pfeilIcon }
              })
            ]
          ),
          _c("span", { staticClass: "group-chat-header-text" }, [
            _vm._v(
              _vm._s(
                _vm.room.name.includes("private Room")
                  ? _vm.checkRoomName(_vm.room.name)
                  : _vm.room.name
              )
            )
          ])
        ],
        1
      ),
      _vm.room
        ? _c(
            "div",
            {
              staticClass: "chat-body",
              staticStyle: { overflow: "hidden !important" }
            },
            [
              _c(
                "vue-custom-scrollbar",
                {
                  ref: "scroll",
                  staticClass: "scroll-area",
                  attrs: { settings: _vm.settings },
                  on: {
                    "ps-y-reach-start": function($event) {
                      return _vm.loadMore()
                    }
                  }
                },
                [
                  _c(
                    "v-list",
                    _vm._l(_vm.events, function(event, index) {
                      return _c(
                        "v-list-item-group",
                        { key: index, staticClass: "mb-2" },
                        [
                          event.getType() === "m.room.message"
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "v-list-item",
                                    {
                                      class:
                                        (_vm.isMe(event) ? "myMessage " : " ") +
                                        "chat-message"
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        { attrs: { dense: "" } },
                                        [
                                          _c(
                                            "v-col",
                                            [
                                              _c(
                                                "v-row",
                                                {
                                                  directives: [
                                                    {
                                                      name: "long-press",
                                                      rawName: "v-long-press",
                                                      value: 1000,
                                                      expression: "1000"
                                                    }
                                                  ],
                                                  attrs: { dense: "" },
                                                  on: {
                                                    "long-press-start": function(
                                                      $event
                                                    ) {
                                                      _vm.isMe(event) &&
                                                      event.getContent().body
                                                        ? (_vm.selectedEvent = event) &&
                                                          (_vm.deleteDialog = true)
                                                        : null
                                                    }
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass:
                                                        "pa-0 greyText"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.getUsername(event)
                                                        )
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass:
                                                        "text-right greyText",
                                                      staticStyle: {
                                                        "font-size": "smaller"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            new Date(
                                                              _vm.getTime(event)
                                                            ).toLocaleDateString() +
                                                              " " +
                                                              new Date(
                                                                _vm.getTime(
                                                                  event
                                                                )
                                                              )
                                                                .toLocaleTimeString()
                                                                .slice(
                                                                  0,
                                                                  new Date(
                                                                    _vm.getTime(
                                                                      event
                                                                    )
                                                                  ).toLocaleTimeString()
                                                                    .length - 3
                                                                )
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-row",
                                                {
                                                  directives: [
                                                    {
                                                      name: "long-press",
                                                      rawName: "v-long-press",
                                                      value: 1000,
                                                      expression: "1000"
                                                    }
                                                  ],
                                                  class:
                                                    (_vm.isMe(event)
                                                      ? "myMessage-content "
                                                      : " ") +
                                                    "chat-message-content",
                                                  attrs: { dense: "" },
                                                  on: {
                                                    "long-press-start": function(
                                                      $event
                                                    ) {
                                                      _vm.isMe(event) &&
                                                      event.getContent().body
                                                        ? (_vm.selectedEvent = event) &&
                                                          (_vm.deleteDialog = true)
                                                        : null
                                                    }
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "pa-0",
                                                      staticStyle: {
                                                        width: "100%"
                                                      }
                                                    },
                                                    [
                                                      event.getContent().body
                                                        ? _c("div", [
                                                            event
                                                              .getContent()
                                                              .body.startsWith(
                                                                "$fileId:"
                                                              )
                                                              ? _c("div", [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticStyle: {
                                                                        "line-height":
                                                                          "20px"
                                                                      },
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          _vm.loadedAudios.filter(
                                                                            function(
                                                                              el
                                                                            ) {
                                                                              return (
                                                                                el.file ===
                                                                                event
                                                                                  .getContent()
                                                                                  .body.replace(
                                                                                    "$fileId:",
                                                                                    ""
                                                                                  )
                                                                              )
                                                                            }
                                                                          )
                                                                            .length >
                                                                          0
                                                                            ? _vm.playPause(
                                                                                event
                                                                                  .getContent()
                                                                                  .body.replace(
                                                                                    "$fileId:",
                                                                                    ""
                                                                                  )
                                                                              )
                                                                            : _vm.loadFile(
                                                                                event
                                                                                  .getContent()
                                                                                  .body.replace(
                                                                                    "$fileId:",
                                                                                    ""
                                                                                  )
                                                                              )
                                                                        }
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "d-flex align-center",
                                                                          staticStyle: {
                                                                            "min-height":
                                                                              "24px"
                                                                          }
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "p",
                                                                            {
                                                                              staticClass:
                                                                                "mb-0 mr-1"
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "Sprachnachricht"
                                                                              )
                                                                            ]
                                                                          ),
                                                                          !_vm.loadedAudios.filter(
                                                                            function(
                                                                              el
                                                                            ) {
                                                                              return (
                                                                                el.file ===
                                                                                event
                                                                                  .getContent()
                                                                                  .body.replace(
                                                                                    "$fileId:",
                                                                                    ""
                                                                                  )
                                                                              )
                                                                            }
                                                                          )
                                                                            .length >
                                                                          0
                                                                            ? _c(
                                                                                "img",
                                                                                {
                                                                                  class:
                                                                                    (_vm.isMe(
                                                                                      event
                                                                                    )
                                                                                      ? "iconToWhite "
                                                                                      : "") +
                                                                                    "icon",
                                                                                  attrs: {
                                                                                    src:
                                                                                      _vm.downloadIcon
                                                                                  }
                                                                                }
                                                                              )
                                                                            : _vm.audioSrc !==
                                                                              event
                                                                                .getContent()
                                                                                .body.replace(
                                                                                  "$fileId:",
                                                                                  ""
                                                                                )
                                                                            ? _c(
                                                                                "img",
                                                                                {
                                                                                  class:
                                                                                    (_vm.isMe(
                                                                                      event
                                                                                    )
                                                                                      ? "iconToWhite "
                                                                                      : "") +
                                                                                    "icon",
                                                                                  attrs: {
                                                                                    src:
                                                                                      _vm.playIcon
                                                                                  }
                                                                                }
                                                                              )
                                                                            : _c(
                                                                                "img",
                                                                                {
                                                                                  class:
                                                                                    (_vm.isMe(
                                                                                      event
                                                                                    )
                                                                                      ? "iconToWhite "
                                                                                      : "") +
                                                                                    "icon",
                                                                                  attrs: {
                                                                                    src:
                                                                                      _vm.pauseIcon
                                                                                  }
                                                                                }
                                                                              )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  )
                                                                ])
                                                              : _c(
                                                                  "div",
                                                                  [
                                                                    _vm.isMe(
                                                                      event
                                                                    )
                                                                      ? _c(
                                                                          "v-btn",
                                                                          {
                                                                            staticClass:
                                                                              "pa-0",
                                                                            staticStyle: {
                                                                              position:
                                                                                "relative",
                                                                              float:
                                                                                "right",
                                                                              width:
                                                                                "25px !important",
                                                                              "min-width":
                                                                                "25px !important",
                                                                              height:
                                                                                "25px"
                                                                            },
                                                                            attrs: {
                                                                              "x-small":
                                                                                ""
                                                                            }
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "img",
                                                                              {
                                                                                attrs: {
                                                                                  src:
                                                                                    _vm.papierkorbIcon,
                                                                                  height:
                                                                                    "20px"
                                                                                },
                                                                                on: {
                                                                                  click: function(
                                                                                    $event
                                                                                  ) {
                                                                                    _vm.isMe(
                                                                                      event
                                                                                    ) &&
                                                                                    event.getContent()
                                                                                      .body
                                                                                      ? (_vm.selectedEvent = event) &&
                                                                                        (_vm.deleteDialog = true)
                                                                                      : null
                                                                                  }
                                                                                }
                                                                              }
                                                                            )
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          event.getContent()
                                                                            .body
                                                                        ) +
                                                                        " "
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                          ])
                                                        : _c(
                                                            "div",
                                                            {
                                                              staticStyle: {
                                                                "font-style":
                                                                  "italic"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                " Diese Nachricht wurde gelöscht. "
                                                              )
                                                            ]
                                                          )
                                                    ]
                                                  )
                                                ]
                                              ),
                                              !_vm.isMe(event) &&
                                              event.getContent().body &&
                                                !event
                                                  .getContent()
                                                  .body.startsWith("$fileId:")
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        position: "absolute",
                                                        top: "38px",
                                                        right: "-40px"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          staticClass:
                                                            "ml-2 pa-0",
                                                          staticStyle: {
                                                            width: "20px",
                                                            height: "20px"
                                                          },
                                                          attrs: {
                                                            elevation: "0",
                                                            icon: ""
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              _vm.greet(
                                                                event.getContent()
                                                                  .body,
                                                                $event
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c("img", {
                                                            staticStyle: {
                                                              height: "30px"
                                                            },
                                                            attrs: {
                                                              src: _vm.isReading
                                                                ? _vm.lautsprecherAusIcon
                                                                : _vm.lautsprecherIcon
                                                            }
                                                          })
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : event.getContent().body &&
                                                  !event
                                                    .getContent()
                                                    .body.startsWith("$fileId:")
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        position: "absolute",
                                                        top: "38px",
                                                        left: "-40px"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          staticClass:
                                                            "ml-2 pa-0",
                                                          staticStyle: {
                                                            width: "20px",
                                                            height: "20px"
                                                          },
                                                          attrs: {
                                                            elevation: "0",
                                                            icon: ""
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              _vm.greet(
                                                                event.getContent()
                                                                  .body,
                                                                $event
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c("img", {
                                                            staticStyle: {
                                                              height: "30px"
                                                            },
                                                            attrs: {
                                                              src: _vm.isReading
                                                                ? _vm.lautsprecherAusIcon
                                                                : _vm.lautsprecherIcon
                                                            }
                                                          })
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ]
                      )
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.room
        ? _c(
            "div",
            { staticClass: "chat-send-wrapper align-center" },
            [
              _c("twemoji-picker", {
                attrs: {
                  emojiData: _vm.emojiDataAll,
                  emojiGroups: _vm.emojiGroups,
                  pickerWidth: 300,
                  skinsSelection: true,
                  searchEmojisFeat: true,
                  searchEmojiPlaceholder: "Suchen...",
                  searchEmojiNotFound: "Kein Emoji gefunden",
                  isLoadingLabel: "Lädt..."
                },
                on: {
                  emojiUnicodeAdded: function(el) {
                    return (_vm.inputMessage += el)
                  }
                }
              }),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.inputMessage,
                    expression: "inputMessage"
                  }
                ],
                staticClass: "chat-send-input py-3 px-5 my-3",
                attrs: {
                  oncontextmenu: "return false;",
                  placeholder: "Nachricht eingeben...",
                  type: "text"
                },
                domProps: { value: _vm.inputMessage },
                on: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.sendMessage($event)
                  },
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.inputMessage = $event.target.value
                  }
                }
              }),
              !_vm.inputMessage && _vm.audioCheck
                ? _c(
                    "div",
                    { attrs: { oncontextmenu: "return false;" } },
                    [
                      _c(
                        "v-btn",
                        {
                          directives: [
                            {
                              name: "long-press",
                              rawName: "v-long-press",
                              value: 100,
                              expression: "100"
                            }
                          ],
                          staticClass: "ma-0 recordBtn",
                          attrs: {
                            fab: "",
                            text: "",
                            color: !_vm.toggle ? "" : "blau"
                          },
                          on: {
                            "long-press-start": _vm.startRecording,
                            "long-press-stop": _vm.stopRecording
                          }
                        },
                        [
                          _c("div", {
                            class: _vm.toggle ? "iconToWhite" : "",
                            attrs: { id: "micIcon" }
                          })
                        ]
                      )
                    ],
                    1
                  )
                : _c(
                    "v-btn",
                    {
                      staticClass: "ma-0",
                      attrs: { fab: "", text: "" },
                      on: { click: _vm.sendMessage }
                    },
                    [
                      _c("img", {
                        attrs: { src: _vm.sendenIcon, height: "30" }
                      })
                    ]
                  )
            ],
            1
          )
        : _vm._e(),
      !_vm.room ? _c("div", [_vm._v("Keine Gruppe ausgewählt")]) : _vm._e(),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "400" },
          model: {
            value: _vm.deleteDialog,
            callback: function($$v) {
              _vm.deleteDialog = $$v
            },
            expression: "deleteDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "headline" }, [
                _vm._v("Nachricht löschen?")
              ]),
              _c("v-card-text", [
                _vm._v(" Wollen Sie diese wirklich Nachricht löschen? ")
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      staticStyle: { color: "white" },
                      attrs: { color: "#bdbdbd" },
                      on: {
                        click: function($event) {
                          _vm.deleteDialog = false
                        }
                      }
                    },
                    [_vm._v("Abbrechen")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticStyle: { color: "white" },
                      attrs: { color: "red red-1" },
                      on: {
                        click: function() {
                          this$1.deleteMessage()
                          this$1.deleteDialog = false
                        }
                      }
                    },
                    [_vm._v("Löschen")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }