var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-row", { staticClass: "mx-0 mt-3 mb-4" }, [
        _c(
          "div",
          {
            staticStyle: {
              height: "auto",
              width: "90%",
              margin: "auto",
              "border-radius": "15px",
              "box-shadow": "1px 5px 5px silver"
            }
          },
          [
            _c(
              "div",
              {
                staticStyle: {
                  "background-color": "#6995cd",
                  "border-radius": "15px 15px 0 0",
                  "font-size": "larger",
                  "padding-top": "5px",
                  "padding-bottom": "20px",
                  color: "white",
                  display: "flex"
                }
              },
              [
                _c(
                  "div",
                  { staticStyle: { display: "flex", width: "50%" } },
                  [
                    _c("img", {
                      staticStyle: {
                        height: "24px",
                        "margin-left": "24px",
                        "margin-top": "20px",
                        filter:
                          "brightness(0) saturate(100%) invert(100%) sepia(5%)"
                      },
                      attrs: { src: _vm.suchIcon, alt: "Lupen-Icon" }
                    }),
                    _c("v-autocomplete", {
                      staticClass: "pl-6",
                      attrs: {
                        label: "Nach Gruppe suchen",
                        "single-line": "",
                        dark: "",
                        "hide-details": "",
                        "item-text": "name",
                        items: _vm.groups,
                        filter: _vm.customFilter,
                        "no-data-text": "Keine passende Gruppe"
                      },
                      model: {
                        value: _vm.group,
                        callback: function($$v) {
                          _vm.group = $$v
                        },
                        expression: "group"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticStyle: {
                      display: "flex",
                      width: "49%",
                      "justify-content": "flex-end",
                      "padding-bottom": "-15px !important"
                    }
                  },
                  [
                    _c("join-lehrerzimmer-button", {
                      staticStyle: { margin: "auto 0" }
                    })
                  ],
                  1
                )
              ]
            ),
            _c(
              "v-row",
              [
                _c(
                  "v-overlay",
                  { attrs: { absolute: "", value: _vm.showLoadingOverlay } },
                  [
                    _vm.showLoadingOverlay
                      ? _c("v-progress-circular", {
                          attrs: { indeterminate: "", size: "100" }
                        })
                      : _vm._e()
                  ],
                  1
                ),
                _c(
                  "v-col",
                  {
                    staticClass: "textGrau",
                    staticStyle: {
                      "text-align": "left",
                      "margin-left": "105px"
                    },
                    attrs: { cols: "12" }
                  },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "text-capitalize",
                        staticStyle: {
                          "background-color": "#d6d6d6",
                          filter: "opacity(40%)",
                          color: "black"
                        },
                        attrs: { elevation: "0" },
                        on: {
                          click: function($event) {
                            return _vm.jumpToCurrentDay()
                          }
                        }
                      },
                      [_vm._v(" Heute ")]
                    ),
                    _c(
                      "v-btn",
                      {
                        staticClass: "mx-2",
                        staticStyle: {
                          "background-color": "#d6d6d6",
                          filter: "opacity(40%)",
                          color: "black"
                        },
                        attrs: { icon: "", small: "", color: "black" },
                        on: { click: _vm.subWeek }
                      },
                      [
                        _c("img", {
                          staticClass: "boardIcon",
                          staticStyle: { color: "black !important" },
                          attrs: { src: _vm.linksIcon, alt: "links-Icon" }
                        })
                      ]
                    ),
                    _c(
                      "v-btn",
                      {
                        staticClass: "mx-2",
                        staticStyle: {
                          "background-color": "#d6d6d6",
                          filter: "opacity(40%)",
                          color: "black !important"
                        },
                        attrs: { icon: "", small: "", color: "black" },
                        on: { click: _vm.addWeek }
                      },
                      [
                        _c("img", {
                          staticClass: "boardIcon",
                          staticStyle: { color: "black" },
                          attrs: { src: _vm.rechtsIcon, alt: "rechts-Icon" }
                        })
                      ]
                    ),
                    _c("div", { staticStyle: { display: "inline-grid" } }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.getMonthText(
                              _vm.getMonday(_vm.date).getMonth()
                            ) +
                              " " +
                              _vm.getMonday(_vm.date).getFullYear()
                          ) +
                          " "
                      )
                    ]),
                    _c("div", {
                      staticStyle: { display: "inline-grid", width: "20px" }
                    }),
                    _c(
                      "v-btn",
                      {
                        staticClass: "diableHoverButton",
                        staticStyle: {
                          display: "inline-grid",
                          "background-color": "#ffffff !important",
                          filter: "opacity(40%)",
                          color: "black !important"
                        },
                        attrs: { disabled: "", elevation: "0" }
                      },
                      [
                        _vm._v(
                          "KW " + _vm._s(_vm.getMonday(_vm.date).getWeek())
                        )
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "table",
              {
                key: _vm.reload,
                staticStyle: { width: "99%", margin: "0.5em auto 5em auto" }
              },
              [
                _c("tr", [
                  _c("th", [
                    _c("img", {
                      staticStyle: {
                        width: "24px",
                        height: "24px",
                        "margin-top": "5px"
                      },
                      attrs: { src: _vm.uhrIcon, alt: "Uhr" }
                    })
                  ]),
                  _c("th"),
                  _vm.isDisplayDateToday(_vm.getDisplayDateMonday())
                    ? _c("th", { staticStyle: { color: "#6995cd" } }, [
                        _vm._v(
                          " Mo., " +
                            _vm._s(
                              _vm.getMonday(_vm.date).getDate() +
                                "." +
                                (_vm.getMonday(_vm.date).getMonth() + 1) +
                                "." +
                                _vm
                                  .getMonday(_vm.date)
                                  .getFullYear()
                                  .toString()
                                  .slice(-2)
                            ) +
                            " "
                        )
                      ])
                    : _c("th", { staticStyle: { color: "#707070" } }, [
                        _vm._v(
                          " Mo., " +
                            _vm._s(
                              _vm.getMonday(_vm.date).getDate() +
                                "." +
                                (_vm.getMonday(_vm.date).getMonth() + 1) +
                                "." +
                                _vm
                                  .getMonday(_vm.date)
                                  .getFullYear()
                                  .toString()
                                  .slice(-2)
                            ) +
                            " "
                        )
                      ]),
                  _vm.isDisplayDateToday(_vm.getDisplayDateTuesday())
                    ? _c(
                        "th",
                        {
                          staticStyle: {
                            color: "#6995cd",
                            "background-color": "#eaeaea",
                            "border-collapse": "initial",
                            "border-radius": "10px 10px 0 0"
                          }
                        },
                        [
                          _vm._v(
                            " Di., " +
                              _vm._s(
                                _vm.getTuesday(_vm.date).getDate() +
                                  "." +
                                  (_vm.getTuesday(_vm.date).getMonth() + 1) +
                                  "." +
                                  _vm
                                    .getTuesday(_vm.date)
                                    .getFullYear()
                                    .toString()
                                    .slice(-2)
                              ) +
                              " "
                          )
                        ]
                      )
                    : _c(
                        "th",
                        {
                          staticStyle: {
                            color: "#707070",
                            "background-color": "#eaeaea",
                            "border-radius": "10px 10px 0 0"
                          }
                        },
                        [
                          _vm._v(
                            " Di., " +
                              _vm._s(
                                _vm.getTuesday(_vm.date).getDate() +
                                  "." +
                                  (_vm.getTuesday(_vm.date).getMonth() + 1) +
                                  "." +
                                  _vm
                                    .getTuesday(_vm.date)
                                    .getFullYear()
                                    .toString()
                                    .slice(-2)
                              ) +
                              " "
                          )
                        ]
                      ),
                  _vm.isDisplayDateToday(_vm.getDisplayDateWednesday())
                    ? _c("th", { staticStyle: { color: "#6995cd" } }, [
                        _vm._v(
                          " Mi., " +
                            _vm._s(
                              _vm.getWednesday(_vm.date).getDate() +
                                "." +
                                (_vm.getWednesday(_vm.date).getMonth() + 1) +
                                "." +
                                _vm
                                  .getWednesday(_vm.date)
                                  .getFullYear()
                                  .toString()
                                  .slice(-2)
                            ) +
                            " "
                        )
                      ])
                    : _c("th", { staticStyle: { color: "#707070" } }, [
                        _vm._v(
                          " Mi., " +
                            _vm._s(
                              _vm.getWednesday(_vm.date).getDate() +
                                "." +
                                (_vm.getWednesday(_vm.date).getMonth() + 1) +
                                "." +
                                _vm
                                  .getWednesday(_vm.date)
                                  .getFullYear()
                                  .toString()
                                  .slice(-2)
                            ) +
                            " "
                        )
                      ]),
                  _vm.isDisplayDateToday(_vm.getDisplayDateThursday())
                    ? _c(
                        "th",
                        {
                          staticStyle: {
                            color: "#6995cd",
                            "background-color": "#eaeaea",
                            "border-radius": "10px 10px 0 0"
                          }
                        },
                        [
                          _vm._v(
                            " Do., " +
                              _vm._s(
                                _vm.getThursday(_vm.date).getDate() +
                                  "." +
                                  (_vm.getThursday(_vm.date).getMonth() + 1) +
                                  "." +
                                  _vm
                                    .getThursday(_vm.date)
                                    .getFullYear()
                                    .toString()
                                    .slice(-2)
                              ) +
                              " "
                          )
                        ]
                      )
                    : _c(
                        "th",
                        {
                          staticStyle: {
                            color: "#707070",
                            "background-color": "#eaeaea",
                            "border-radius": "10px 10px 0 0"
                          }
                        },
                        [
                          _vm._v(
                            " Do., " +
                              _vm._s(
                                _vm.getThursday(_vm.date).getDate() +
                                  "." +
                                  (_vm.getThursday(_vm.date).getMonth() + 1) +
                                  "." +
                                  _vm
                                    .getThursday(_vm.date)
                                    .getFullYear()
                                    .toString()
                                    .slice(-2)
                              ) +
                              " "
                          )
                        ]
                      ),
                  _vm.isDisplayDateToday(_vm.getDisplayDateFriday())
                    ? _c("th", { staticStyle: { color: "#6995cd" } }, [
                        _vm._v(
                          " Fr., " +
                            _vm._s(
                              _vm.getFriday(_vm.date).getDate() +
                                "." +
                                (_vm.getFriday(_vm.date).getMonth() + 1) +
                                "." +
                                _vm
                                  .getFriday(_vm.date)
                                  .getFullYear()
                                  .toString()
                                  .slice(-2)
                            ) +
                            " "
                        )
                      ])
                    : _c("th", { staticStyle: { color: "#707070" } }, [
                        _vm._v(
                          " Fr., " +
                            _vm._s(
                              _vm.getFriday(_vm.date).getDate() +
                                "." +
                                (_vm.getFriday(_vm.date).getMonth() + 1) +
                                "." +
                                _vm
                                  .getFriday(_vm.date)
                                  .getFullYear()
                                  .toString()
                                  .slice(-2)
                            ) +
                            " "
                        )
                      ]),
                  _c("th")
                ]),
                _vm._l(_vm.slots, function(item, index) {
                  return _c("tr", { key: index }, [
                    _c(
                      "th",
                      {
                        staticClass: "textGrau",
                        staticStyle: { "font-weight": "normal" }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              ("0" + new Date(item.start).getHours()).slice(
                                -2
                              ) +
                                ":" +
                                ("0" + new Date(item.start).getMinutes()).slice(
                                  -2
                                )
                            ) +
                            " "
                        ),
                        _c("br"),
                        _vm._v(
                          " " +
                            _vm._s(
                              ("0" + new Date(item.end).getHours()).slice(-2) +
                                ":" +
                                ("0" + new Date(item.end).getMinutes()).slice(
                                  -2
                                )
                            ) +
                            " "
                        )
                      ]
                    ),
                    _c("th"),
                    _c(
                      "td",
                      { staticStyle: { "background-color": "white" } },
                      [
                        _vm._l(_vm.getAppointments(item._id, "mon"), function(
                          appointment
                        ) {
                          return _c("fach", {
                            key: appointment._id,
                            attrs: {
                              group: _vm.groups.find(function(el) {
                                return el.name === _vm.group
                              }),
                              requestGroup: _vm.requestGroups,
                              timeslot: item,
                              day: _vm.getMonday(_vm.date),
                              appointment: appointment
                            }
                          })
                        }),
                        _vm.getAppointments(item._id, "mon").length === 0
                          ? _c("fach", {
                              attrs: {
                                group: _vm.groups.find(function(el) {
                                  return el.name === _vm.group
                                }),
                                requestGroup: _vm.requestGroups,
                                timeslot: item,
                                day: _vm.getMonday(_vm.date),
                                appointment: null
                              }
                            })
                          : _vm._e()
                      ],
                      2
                    ),
                    index === _vm.slots.length - 1
                      ? _c(
                          "td",
                          {
                            staticStyle: {
                              "background-color": "#eaeaea",
                              "border-radius": "0 0 10px 10px"
                            }
                          },
                          [
                            _vm._l(
                              _vm.getAppointments(item._id, "die"),
                              function(appointment) {
                                return _c("fach", {
                                  key: appointment._id,
                                  attrs: {
                                    group: _vm.groups.find(function(el) {
                                      return el.name === _vm.group
                                    }),
                                    requestGroup: _vm.requestGroups,
                                    timeslot: item,
                                    day: _vm.getTuesday(_vm.date),
                                    appointment: appointment
                                  }
                                })
                              }
                            ),
                            _vm.getAppointments(item._id, "die").length === 0
                              ? _c("fach", {
                                  attrs: {
                                    group: _vm.groups.find(function(el) {
                                      return el.name === _vm.group
                                    }),
                                    requestGroup: _vm.requestGroups,
                                    timeslot: item,
                                    day: _vm.getTuesday(_vm.date),
                                    appointment: null
                                  }
                                })
                              : _vm._e()
                          ],
                          2
                        )
                      : _c(
                          "td",
                          { staticStyle: { "background-color": "#eaeaea" } },
                          [
                            _vm._l(
                              _vm.getAppointments(item._id, "die"),
                              function(appointment) {
                                return _c("fach", {
                                  key: appointment._id,
                                  attrs: {
                                    group: _vm.groups.find(function(el) {
                                      return el.name === _vm.group
                                    }),
                                    requestGroup: _vm.requestGroups,
                                    timeslot: item,
                                    day: _vm.getTuesday(_vm.date),
                                    appointment: appointment
                                  }
                                })
                              }
                            ),
                            _vm.getAppointments(item._id, "die").length === 0
                              ? _c("fach", {
                                  attrs: {
                                    group: _vm.groups.find(function(el) {
                                      return el.name === _vm.group
                                    }),
                                    requestGroup: _vm.requestGroups,
                                    timeslot: item,
                                    day: _vm.getTuesday(_vm.date),
                                    appointment: null
                                  }
                                })
                              : _vm._e()
                          ],
                          2
                        ),
                    _c(
                      "td",
                      { staticStyle: { "background-color": "white" } },
                      [
                        _vm._l(_vm.getAppointments(item._id, "mit"), function(
                          appointment
                        ) {
                          return _c("fach", {
                            key: appointment._id,
                            attrs: {
                              group: _vm.groups.find(function(el) {
                                return el.name === _vm.group
                              }),
                              requestGroup: _vm.requestGroups,
                              timeslot: item,
                              day: _vm.getWednesday(_vm.date),
                              appointment: appointment
                            }
                          })
                        }),
                        _vm.getAppointments(item._id, "mit").length === 0
                          ? _c("fach", {
                              attrs: {
                                group: _vm.groups.find(function(el) {
                                  return el.name === _vm.group
                                }),
                                requestGroup: _vm.requestGroups,
                                timeslot: item,
                                day: _vm.getWednesday(_vm.date),
                                appointment: null
                              }
                            })
                          : _vm._e()
                      ],
                      2
                    ),
                    index === _vm.slots.length - 1
                      ? _c(
                          "td",
                          {
                            staticStyle: {
                              "background-color": "#eaeaea",
                              "border-radius": "0 0 10px 10px"
                            }
                          },
                          [
                            _vm._l(
                              _vm.getAppointments(item._id, "don"),
                              function(appointment) {
                                return _c("fach", {
                                  key: appointment._id,
                                  attrs: {
                                    group: _vm.groups.find(function(el) {
                                      return el.name === _vm.group
                                    }),
                                    requestGroup: _vm.requestGroups,
                                    timeslot: item,
                                    day: _vm.getThursday(_vm.date),
                                    appointment: appointment
                                  }
                                })
                              }
                            ),
                            _vm.getAppointments(item._id, "don").length === 0
                              ? _c("fach", {
                                  attrs: {
                                    group: _vm.groups.find(function(el) {
                                      return el.name === _vm.group
                                    }),
                                    requestGroup: _vm.requestGroups,
                                    timeslot: item,
                                    day: _vm.getThursday(_vm.date),
                                    appointment: null
                                  }
                                })
                              : _vm._e()
                          ],
                          2
                        )
                      : _c(
                          "td",
                          { staticStyle: { "background-color": "#eaeaea" } },
                          [
                            _vm._l(
                              _vm.getAppointments(item._id, "don"),
                              function(appointment) {
                                return _c("fach", {
                                  key: appointment._id,
                                  attrs: {
                                    group: _vm.groups.find(function(el) {
                                      return el.name === _vm.group
                                    }),
                                    requestGroup: _vm.requestGroups,
                                    timeslot: item,
                                    day: _vm.getThursday(_vm.date),
                                    appointment: appointment
                                  }
                                })
                              }
                            ),
                            _vm.getAppointments(item._id, "don").length === 0
                              ? _c("fach", {
                                  attrs: {
                                    group: _vm.groups.find(function(el) {
                                      return el.name === _vm.group
                                    }),
                                    requestGroup: _vm.requestGroups,
                                    timeslot: item,
                                    day: _vm.getThursday(_vm.date),
                                    appointment: null
                                  }
                                })
                              : _vm._e()
                          ],
                          2
                        ),
                    _c(
                      "td",
                      { staticStyle: { "background-color": "white" } },
                      [
                        _vm._l(_vm.getAppointments(item._id, "fre"), function(
                          appointment
                        ) {
                          return _c("fach", {
                            key: appointment._id,
                            attrs: {
                              group: _vm.groups.find(function(el) {
                                return el.name === _vm.group
                              }),
                              requestGroup: _vm.requestGroups,
                              timeslot: item,
                              day: _vm.getFriday(_vm.date),
                              appointment: appointment
                            }
                          })
                        }),
                        _vm.getAppointments(item._id, "fre").length === 0
                          ? _c("fach", {
                              attrs: {
                                group: _vm.groups.find(function(el) {
                                  return el.name === _vm.group
                                }),
                                requestGroup: _vm.requestGroups,
                                timeslot: item,
                                day: _vm.getFriday(_vm.date),
                                appointment: null
                              }
                            })
                          : _vm._e()
                      ],
                      2
                    ),
                    _c("th")
                  ])
                })
              ],
              2
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }