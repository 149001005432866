<template>
    <div>
        <chat-widget class="mt-4"></chat-widget>
    </div>

</template>

<script>
    import ChatWidget from "../Demo/ChatWidget";
    export default {
        name: "ChatVerwaltung",
        components: {ChatWidget}
    }
</script>

<style scoped>

</style>
