const getters = {
  // # Axios ###################
  getAxiosInstance: state => state.axiosInstance,
  // #################### Axios #

  // # server ###################
  getServerAdress: state => state.serverAddress,
  // #################### server #
};

export default getters;
