var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("Header", {
        staticStyle: { position: "fixed", width: "100%", "z-index": "100" }
      }),
      _c(
        "keep-alive",
        [
          _c("router-view", {
            staticStyle: {
              width: "calc(100% - 56px)",
              "margin-left": "56px",
              "margin-top": "100px"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }