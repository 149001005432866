var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "overlay-opacity": "0.7", persistent: true, width: "500" },
      model: {
        value: _vm.showDialog,
        callback: function($$v) {
          _vm.showDialog = $$v
        },
        expression: "showDialog"
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "pa-3", attrs: { id: "card" } },
        [
          _c(
            "v-card-title",
            [
              _c(
                "v-container",
                { staticClass: "pa-0" },
                [
                  _c(
                    "v-row",
                    [
                      _c("v-col", [_c("h1", [_vm._v("Dateiname")])]),
                      _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "ml-2 pa-0",
                              staticStyle: { width: "30px", height: "30px" },
                              attrs: { elevation: "2", "x-small": "" },
                              on: { click: _vm.close }
                            },
                            [
                              _c("img", {
                                staticStyle: { height: "20px" },
                                attrs: { src: _vm.schliesenIcon }
                              })
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "pb-0" },
            [
              _c(
                "v-container",
                { staticClass: "pa-0" },
                [
                  _c(
                    "v-row",
                    { attrs: { align: "center", justify: "center" } },
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-text-field", {
                            model: {
                              value: _vm.newFileName,
                              callback: function($$v) {
                                _vm.newFileName = $$v
                              },
                              expression: "newFileName"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "pa-0 mb-2",
                              staticStyle: { width: "30px", height: "30px" },
                              attrs: {
                                elevation: "2",
                                "x-small": "",
                                color: "secondary"
                              },
                              on: {
                                click: function() {
                                  _vm.callback(
                                    _vm.newFileName,
                                    _vm.selectedUser
                                  )
                                }
                              }
                            },
                            [
                              _c(
                                "v-icon",
                                { attrs: { "x-small": "", dark: "" } },
                                [_vm._v("fas fa-check")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.group
                    ? _c(
                        "v-row",
                        {
                          staticClass: "ma-0",
                          staticStyle: { width: "100%" },
                          attrs: { align: "center", justify: "center" }
                        },
                        [
                          _c(
                            "vue-custom-scrollbar",
                            {
                              staticClass: "scroll-area",
                              attrs: { settings: _vm.settings }
                            },
                            [
                              _c("v-data-table", {
                                staticClass: "ma-0",
                                staticStyle: { width: "100%" },
                                attrs: {
                                  items: this.group.participants,
                                  "disable-pagination": "",
                                  "hide-default-footer": "",
                                  "fixed-header": ""
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "item",
                                      fn: function(row) {
                                        return [
                                          _c("tr", [
                                            _c("td", { staticClass: "pl-0" }, [
                                              _vm._v(_vm._s(row.item.lastName))
                                            ]),
                                            _c("td", [
                                              _vm._v(
                                                " " + _vm._s(row.item.name)
                                              )
                                            ]),
                                            _c(
                                              "td",
                                              [
                                                _c("v-checkbox", {
                                                  staticClass: "mx-2",
                                                  attrs: {
                                                    rounded: "",
                                                    value: row.item.account,
                                                    small: "",
                                                    light: ""
                                                  },
                                                  model: {
                                                    value: _vm.selectedUser,
                                                    callback: function($$v) {
                                                      _vm.selectedUser = $$v
                                                    },
                                                    expression: "selectedUser"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ])
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  2373018189
                                )
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }