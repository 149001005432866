<template>
    <div>

        <progress-bar
                title="Hochladen"
                :show-progress="showPrivateUploadProgress"
                :progress="privateUploadProgress"
                :abort-progress="privateXmlHttpRequest ? () => { privateXmlHttpRequest.abort() }: () => {}"
        />

        <progress-bar
                title="Hochladen"
                :show-progress="showSubjectUploadProgress"
                :progress="subjectUploadProgress"
                :abort-progress="subjectXmlHttpRequest ? () => { subjectXmlHttpRequest.abort() }: () => {}"
        />

        <name-file
                v-model="showNameFile"
                :original-name="uploadFileName"
                :callback="fileNameCallback"
        />


        <v-row class="mx-0" style="width: 100%;">
            <div class="whiteBg" style="height: auto; width: 90%; min-height: 80vh; border-radius: 15px; box-shadow: 1px 5px 5px silver; margin: auto auto 5em;">

                <!--Header of Dateien-->
                <div style="background-color: #F49F31; border-radius: 15px 15px 0 0; padding-top: 10px; padding-bottom: 10px; color: white;">
                    <v-row style="width: 90%; display: flex; align-items: center; height: 35px" class="pr-2 ml-2">
                        <v-col cols="9" md="8" lg="9" class="pa-0 pl-2 d-flex align-center" style="font-size: larger;">
                            <img v-if="!fach && !day && !privateFolder" :src="fileIcon" class="iconToWhite mr-1" style="height: 20px;"/>
                            <v-icon v-else :size="20" @click="privateFolder ? privateFolder = false : (subjectFolder ? subjectFolder = false :(day ? day = null : fach = null))" color="white" class="iconToWhite mr-1" style="height: 20px; width: 20px">mdi-arrow-left</v-icon>
                            Dateien
                        </v-col>
                    </v-row>
                </div>

                <div class="pa-3 my-1 mx-auto d-flex" v-if="!privateFolder" @click="privateFolder = true" :style="'position: relative; border-radius: 10px; color: #707070; box-shadow: 1px 2px 3px silver; width: 90%; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; background-color: #FFF; cursor: pointer'">
                    <img :src="schlossIcon" class="iconSelect" style="background-color: #F49F31">
                    <div class="pl-1" style="height: 24px">Privater Ordner</div>
                </div>

                <!--directory tree-->
                <div style="margin-left: 5%" v-if="!privateFolder" class="pt-2 grey--text font-weight-bold">
                    <div @click="fach = null; day = null; subjectFolder = false" style="display: inline-flex; cursor: pointer">
                        Fächer/
                    </div>
                    <div v-if="fach" @click="day = null" style="display: inline-flex; cursor: pointer">
                        {{fach[0].schoolSubject.name}}/
                    </div>
                    <div v-if="day" style="display: inline-flex; cursor: pointer" >
                        {{new Date(day[0].day).toLocaleDateString()}}
                    </div>
                </div>

                <!--body of Dateien-->
                <vue-custom-scrollbar class="scroll-area" :settings="settings">
                    <!--Fach folder-->
                    <div v-if="!fach && !privateFolder">
                        <div v-for="(el, index) in schoolSubjectGroups" :key="JSON.stringify(el)+ index">
                            <v-badge
                                    color="red"
                                    :content="count(el)"
                                    :value="count(el)"
                                    overlap
                                    right
                                    offset-x="40"
                                    offset-y="20"
                                    style="z-index: 8; width: 100%;"
                            >
                                <div class="pa-3 my-1 mx-auto d-flex" @click="selectSubject(el)" :style="'position: relative; border-radius: 10px; color: #707070; box-shadow: 1px 2px 3px silver; width: 90%; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; background-color: #FFF; cursor: pointer'">
                                    <img v-if="getSrc(el[0].schoolSubject.icon)" class="iconSelect" :style="{ 'background-color': el[0].schoolSubject.color}" :src="getSrc(el[0].schoolSubject.icon)">
                                    <div class="pl-1 d-flex align-center" style="height: 24px">{{el[0].schoolSubject.name}}</div>
                                </div>
                            </v-badge>
                        </div>
                    </div>

                    <!--inside Fach folder-->
                    <div v-else-if="!privateFolder">
                        <div v-if="!day && !subjectFolder">
                            <!--Allgemein folder-->
                            <div v-if="account.role === 'pupil'">
                                <div v-for="(el) in getFachByTeacher()" :key="JSON.stringify(el)" @click="selectSubjectFolder(el[0].teacher._id)" class="pa-3 my-1 mx-auto d-flex" :style="'position: relative; border-radius: 10px; color: #707070; box-shadow: 1px 2px 3px silver; width: 90%; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; background-color: #FFF; cursor: pointer'">
                                    <img v-if="getSrc(fach[0].schoolSubject.icon)" class="iconSelect" :style="{ 'background-color': fach[0].schoolSubject.color}" :src="getSrc(fach[0].schoolSubject.icon)">
                                    <div class="pl-1" style="height: 24px">Allgemein {{ getTeacherDisplayName(el[0].teacher) }}</div>
                                </div>
                            </div>

                            <div v-else>
                                <div v-for="(el) in getFachByTeacher()" :key="JSON.stringify(el)" @click="selectSubjectFolder(el[0].teacher._id)" class="pa-3 my-1 mx-auto d-flex" :style="'position: relative; border-radius: 10px; color: #707070; box-shadow: 1px 2px 3px silver; width: 90%; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; background-color: #FFF; cursor: pointer'">
                                    <img v-if="getSrc(fach[0].schoolSubject.icon)" class="iconSelect" :style="{ 'background-color': fach[0].schoolSubject.color}" :src="getSrc(fach[0].schoolSubject.icon)">
                                    <div class="pl-1" style="height: 24px">Allgemein</div>
                                </div>
                            </div>
                            <!--Appointment folders-->
                            <div v-for="(el, index) in subjectDateGroups" :key="JSON.stringify(el)+ index">
                                <v-badge
                                        color="red"
                                        :content="count(el)"
                                        :value="count(el)"
                                        overlap
                                        right
                                        offset-x="40"
                                        offset-y="20"
                                        style="z-index: 8; width: 100%;"
                                >
                                    <div class="pa-3 my-1 mx-auto d-flex" @click="selectDay(el)" :style="'position: relative; border-radius: 10px; color: #707070; box-shadow: 1px 2px 3px silver; width: 90%; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; background-color: #FFF; cursor: pointer'">
                                        <img v-if="getSrc(fach[0].schoolSubject.icon)" class="iconSelect" :style="{ 'background-color': fach[0].schoolSubject.color}" :src="getSrc(fach[0].schoolSubject.icon)">
                                        <div v-if="account.role === 'pupil'" class="pl-1 d-flex align-center" style="height: 24px">{{new Date(el[0].day).toLocaleDateString() + ' ' +  getTeacherDisplayName(el[0].teacher) }}</div>
                                        <div v-else class="pl-1 d-flex align-center" style="height: 24px">{{new Date(el[0].day).toLocaleDateString() + ' ' + getSlotTime(el[0].timeslot) }}</div>
                                    </div>
                                </v-badge>
                            </div>
                        </div>

                        <!--inside Appointment folder-->
                        <div v-else-if="!subjectFolder">
                            <div style="margin-left: 5%" class="pt-2 font-weight-bold greyText">
                                Material:
                            </div>
                            <div v-for="(el, index) in teacherUploads" :key="JSON.stringify(el)+ index">
                                <v-badge
                                        color="red"
                                        content="1"
                                        :value="!el.seenByMe && account.role == 'pupil'"
                                        overlap
                                        offset-x="40"
                                        offset-y="20"
                                        style="z-index: 8; width: 100%;"
                                >
                                    <div class="pa-3 my-1 mx-auto" :style="'display:flex; position: relative; border-radius: 10px; color: #707070; box-shadow: 1px 2px 3px silver; width: 90%; white-space: nowrap; background-color: #FFF; '">
                                        <img @click="() => clickDownloadTeacherUpload(el)" v-if="getSrc(fach[0].schoolSubject.icon)" class="iconSelect" :style=" 'cursor: pointer; background-color:' + fach[0].schoolSubject.color" :src="getSrc(fach[0].schoolSubject.icon)">
                                        <div @click="() => clickDownloadTeacherUpload(el)" class="align-center pl-1 materialTitle">
                                            {{el.title}}
                                        </div>
                                        <div v-if="account.role == 'pupil'">
                                            <v-btn
                                                    v-if="['mp4', 'wmv', 'flv', 'webm', 'mpg', 'm4v'].includes(el.title.split('.').pop())"
                                                    @click="() => play(el)" x-small class="ml-2 pa-0" elevation="0" style="height: 24px">
                                                <img :src="rechtsIcon" style="height: 20px;"/>
                                            </v-btn>
                                            <v-btn
                                                    v-else-if="['doc', 'docx', 'ppt', 'pptx', 'xls', 'xlsx'].includes(el.title.split('.').pop().toLowerCase())"
                                                    dark @click="fileNotSupported(el)" x-small class="btnStyle" elevation="0" style="background-color: #8CBD46;" >
                                                <img :src="infoIcon" style="height: 20px; filter: brightness(1000%)"/>
                                            </v-btn>

                                            <v-btn v-else @click="openEdit(el.title, el.file, el._id, el.appointment)" x-small
                                                   class="ml-2 pa-0" elevation="0" style="height: 24px">
                                                <img :src="bearbeitenIcon" style="height: 20px;"/>
                                            </v-btn>
                                        </div>

                                        <div v-else-if="account.role == 'teacher'">
                                            <v-btn v-if="['doc', 'docx', 'ppt', 'pptx', 'xls', 'xlsx'].includes(el.title.split('.').pop().toLowerCase())"
                                                   dark @click="fileNotSupported(el)" x-small class="btnStyle" elevation="0" style="background-color: #8CBD46;" >
                                                <img :src="infoIcon" style="height: 20px; filter: brightness(1000%)"/>
                                            </v-btn>
                                            <v-btn v-else @click="(event) => {event.stopPropagation(); openAnsicht(el.title, el.file, el._id, el.appointment,'pupil' )}"
                                                   x-small elevation="0">
                                                <!--                                                            <img :src="papierkorbIcon" style="height: 20px;"/>-->
                                                <v-icon style="width: 20px; color: #939393">fas fa-eye</v-icon>
                                            </v-btn>
                                            <v-btn
                                                @click="() => clickDeleteTeacherUpload(el)" x-small
                                                class="ml-2 pa-0" elevation="0" style="height: 24px"
                                            >
                                                <img :src="papierkorbIcon" style="height: 20px">
                                            </v-btn>
                                        </div>

                                        <div>
                                            <v-btn @click="() => clickDownloadTeacherUpload(el)" x-small elevation="0"
                                                   class="ml-2 pa-0" style="height: 24px">
                                                <img :src="runterladenIcon" style="height: 20px;"/>
                                            </v-btn>
                                        </div>
                                    </div>
                                </v-badge>
                            </div>

                            <div v-if="account.role == 'teacher'">
                                <div style="margin-left: 5%" class="pt-2 font-weight-bold greyText">
                                    Korrigiert:
                                </div>
                                <div v-for="(el, index) in pupileUploads.filter((element) => isCorrectionAvailable(element))" :key="JSON.stringify(el)+ index" >
                                    <div class="pa-3 my-1 mx-auto" :style="'display:flex; position: relative; border-radius: 10px; color: #707070; box-shadow: 1px 2px 3px silver; width: 90%; white-space: nowrap; background-color: #FFF; '">
                                        <img @click="() => clickDownloadPupilCorrection(el)" v-if="getSrc(fach[0].schoolSubject.icon)" class="iconSelect" :style="'cursor: pointer;background-color:' + fach[0].schoolSubject.color" :src="getSrc(fach[0].schoolSubject.icon)">
                                        <div @click="() => clickDownloadPupilCorrection(el)" class="pl-1" :style="'cursor: pointer;width: 90%; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; '">
                                            {{el.title}}
                                        </div>

                                        <div>
                                            <v-btn v-if="['doc', 'docx', 'ppt', 'pptx', 'xls', 'xlsx'].includes(el.title.split('.').pop().toLowerCase())"
                                                    dark @click="fileNotSupported(el)" x-small class="btnStyle" elevation="0" style="background-color: #8CBD46;" >
                                                <img :src="infoIcon" style="height: 20px; filter: brightness(1000%)"/>
                                            </v-btn>
                                            <v-btn v-else @click="openEditTeacher(el.title, el.file, el._id, el.appointment)" x-small
                                                   class="ml-2 pa-0" elevation="0" style="height: 24px">
                                                <img :src="bearbeitenIcon" style="height: 20px;"/>
                                            </v-btn>
                                        </div>

                                        <div>
                                            <v-btn
                                                   @click="clickDownloadPupilCorrection(el)" elevation="0" x-small
                                                   class="ml-2 pa-0" style="width: 20px; height: 24px">
                                                <img :src="bearbeitetIcon" style="height: 20px;"/>
                                            </v-btn>
                                        </div>
                                    </div>
                                </div>
                                <div style="margin-left: 5%" class="pt-2 font-weight-bold greyText">
                                    Unkorrigiert:
                                </div>
                                <div v-for="(el, index) in pupileUploads.filter((element) => !isCorrectionAvailable(element))" :key="JSON.stringify(el)+ index" >
                                    <v-badge
                                            color="red"
                                            content="1"
                                            :value="!el.seenByMe"
                                            overlap
                                            offset-x="40"
                                            offset-y="20"
                                            style="z-index: 8; width: 100%;"
                                    >
                                        <div class="pa-3 my-1 mx-auto" :style="'display:flex; position: relative; border-radius: 10px; color: #707070; box-shadow: 1px 2px 3px silver; width: 90%; white-space: nowrap; background-color: #FFF; '">
                                            <img @click="() => clickDownloadPupilUpload(el)" v-if="getSrc(fach[0].schoolSubject.icon)" class="iconSelect" :style=" 'cursor:pointer; background-color:' + fach[0].schoolSubject.color" :src="getSrc(fach[0].schoolSubject.icon)">
                                            <div @click="() => clickDownloadPupilUpload(el)" style="height: 24px" class="d-flex align-center pl-1" :style="'cursor:pointer; width: 90%; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; '">
                                                {{el.title}}
                                            </div>

                                            <div>
                                                <v-btn v-if="['doc', 'docx', 'ppt', 'pptx', 'xls', 'xlsx'].includes(el.title.split('.').pop().toLowerCase())"
                                                        dark @click="fileNotSupported(el)" x-small class="btnStyle" elevation="0" style="background-color: #8CBD46;" >
                                                    <img :src="infoIcon" style="height: 20px; filter: brightness(1000%)"/>
                                                </v-btn>
                                                <v-btn v-else @click="openEditTeacher(el.title, el.file, el._id, el.appointment)" x-small
                                                       class="ml-2 pa-0" elevation="0" style="height: 24px">
                                                    <img :src="bearbeitenIcon" style="height: 20px;"/>
                                                </v-btn>
                                            </div>

                                            <div>
                                                <v-btn x-small elevation="0" class="ml-2 pa-0" style="width: 20px; height: 24px">
                                                    <img
                                                        :src="offenIcon"
                                                        style="height: 20px"
                                                    />
                                                </v-btn>
                                            </div>
                                        </div>
                                    </v-badge>
                                </div>
                            </div>

                            <div v-else>
                                <div style="margin-left: 5%" class="pt-2 font-weight-bold greyText">
                                    Deine Abgaben:
                                </div>
                                <div v-for="(el, index) in pupileUploads" :key="JSON.stringify(el)+ index" >
                                    <div class="pa-3 my-3 mx-auto" :style="'display:flex; position: relative; border-radius: 10px; color: #707070; box-shadow: 1px 2px 3px silver; width: 90%; white-space: nowrap; background-color: #FFF; '">
                                        <img @click="() => clickDownloadPupilUpload(el)" v-if="getSrc(fach[0].schoolSubject.icon)" class="iconSelect" :style=" 'cursor:pointer; background-color:' + fach[0].schoolSubject.color" :src="getSrc(fach[0].schoolSubject.icon)">
                                        <div @click="() => clickDownloadPupilUpload(el)" class="pl-1" :style="'cursor:pointer; width: 90%; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; '">
                                            {{el.title}}
                                        </div>
                                        <div style="display: inline-block">
                                            <v-btn v-if="['doc', 'docx', 'ppt', 'pptx', 'xls', 'xlsx'].includes(el.title.split('.').pop().toLowerCase())"
                                                    dark @click="fileNotSupported(el)" x-small class="btnStyle" elevation="0" style="background-color: #8CBD46;" >
                                                <img :src="infoIcon" style="height: 20px; filter: brightness(1000%)"/>
                                            </v-btn>
                                            <v-btn v-else @click="(event) => {event.stopPropagation(); openAnsicht(el.title, el.file, el._id, el.appointment,'teacher' )}" class="btnStyle" x-small elevation="0" color="rgba(0,0,0,0)">
                                                <!--                                                            <img :src="papierkorbIcon" style="height: 20px;"/>-->
                                                <v-icon style="width: 20px; color: #939393">fas fa-eye</v-icon>
                                            </v-btn>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!--inside Allgemein folder-->
                        <div v-else>
                            <input
                                    @change="() => subjectUploadInputChange()"
                                    id="subjectUploadInput"
                                    type="file"
                                    hidden
                                    ref="subjectUploadInput"
                            />

                            <div class="d-flex" style="justify-content: center" v-if="account.role == 'teacher'">
                                <v-btn @click="clickSubjectUploadButton" style="padding-left: 50px; padding-right: 50px; color: #707070; border-radius: 10px" class="text-capitalize" elevation="0">
                                    <img :src="hochladenIcon" style="height: 20px;" class="mr-2"/>
                                    Hochladen
                                </v-btn>
                            </div>

                            <div v-if="subjectFiles.length > 0">
                                <div v-for="(el, index) in subjectFiles" :key="JSON.stringify(el)+ index" >
                                    <div class="pa-3 my-3 mx-auto" :style="'display:flex; position: relative; border-radius: 10px; color: #707070; box-shadow: 1px 2px 3px silver; width: 90%; white-space: nowrap; background-color: #FFF; '">
                                        <div @click="() => clickDownloadSubjectUpload(el)" style="height: 24px" class="d-flex align-center" :style="'cursor: pointer; width: 90%; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; '">
                                            {{el.uploadedName}}
                                        </div>

                                        <div style="height: 24px">
                                            <v-btn v-if="account.role == 'teacher'" @click="() => clickDeleteSubjectUpload(el)" x-small elevation="0"
                                                   class="ml-2 pa-0" style="width: 20px; height: 24px">
                                                <img :src="papierkorbIcon" style="height: 20px;"/>
                                            </v-btn>
                                            <v-btn @click="() => clickDownloadSubjectUpload(el)" x-small elevation="0"
                                                   class="ml-2 pa-0" style="width: 20px; height: 24px">
                                                <img :src="runterladenIcon" style="height: 20px;"/>
                                            </v-btn>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-else class="px-4 my-3 mx-auto" style="color: #737373">
                                Hier gibts noch keine Dateien
                            </div>

                        </div>
                    </div>

                    <!--inside Privater folder-->
                    <div v-else class="my-3">
                        <input
                                @change="() => privateUploadInputChange()"
                                id="privateUploadInput"
                                type="file"
                                hidden
                                ref="privateUploadInput"
                        />

                        <div class="d-flex justify-center">
                            <v-progress-linear
                                    id="progressbar"
                                    :value="privateFileProgress"
                                    height="20px"
                                    style="width: 90%; border-radius: 10px"
                                    class="mx-3 my-3"
                                    color="primary"
                            >
                                {{ Math.ceil(privateFileProgress * (account.role == 'teacher' ? 10 : 1)) }}MiB / {{account.role == 'teacher' ? '1000MiB' : '100MiB'}}
                            </v-progress-linear>
                        </div>

                        <div class="d-flex" style="justify-content: center">
                            <v-btn @click="clickPrivateUploadButton" style="padding-left: 50px; padding-right: 50px; color: #707070; border-radius: 10px;" class="text-capitalize" elevation="0">
                                <img :src="hochladenIcon" style="height: 20px;" class="mr-2"/>
                                Hochladen
                            </v-btn>
                        </div>

                        <div v-for="(el, index) in privateFiles" :key="JSON.stringify(el)+ index" >
                            <div class="pa-3 my-3 mx-auto" :style="'display:flex; position: relative; border-radius: 10px; color: #707070; box-shadow: 1px 2px 3px silver; width: 90%; white-space: nowrap; background-color: #FFF; '">
                                  <div @click="() => clickDownloadPrivateUpload(el)" :style="'cursor: pointer; width: 90%; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; '">
                                    {{el.uploadedName}}
                                </div>

                                <div>
                                    <v-btn v-if="['doc', 'docx', 'ppt', 'pptx', 'xls', 'xlsx'].includes(el.uploadedName.split('.').pop().toLowerCase())"
                                            dark @click="fileNotSupported(el)" x-small class="btnStyle" elevation="0" style="background-color: #8CBD46;" >
                                        <img :src="infoIcon" style="height: 20px; filter: brightness(1000%)"/>
                                    </v-btn>
                                    <v-btn v-else @click="(event) => {event.stopPropagation(); openPrivateAnsicht(el)}" class="btnStyle" x-small elevation="0" color="rgba(0,0,0,0)">
                                        <!--                                                            <img :src="papierkorbIcon" style="height: 20px;"/>-->
                                        <v-icon style="width: 20px; color: #939393">fas fa-eye</v-icon>
                                    </v-btn>
                                    <v-btn @click="() => clickDeletePrivateUpload(el)" x-small elevation="0"
                                           class="ml-2 pa-0" style="width: 20px; height: 20px">
                                        <img :src="papierkorbIcon" style="height: 20px;"/>
                                    </v-btn>
                                    <v-btn @click="() => clickDownloadPrivateUpload(el)" x-small elevation="0"
                                           class="ml-2 pa-0" style="width: 20px; height: 20px">
                                        <img :src="runterladenIcon" style="height: 20px;"/>
                                    </v-btn>
                                </div>
                            </div>
                        </div>
                    </div>
                </vue-custom-scrollbar>
            </div>
        </v-row>


        <v-overlay opacity="0.95" v-if="videoUrl" z-index="99">
            <v-icon large style="position: fixed; top: 50px; right: 50px" @click="videoUrl = false" dark>fas fa-times
            </v-icon>
            <video :src="videoUrl" controls>
                Your browser does not support the video tag.
            </video>
        </v-overlay>

        <v-snackbar bottom v-model="snackbar" color="error" timeout="3000">
            {{snackbarText}}
        </v-snackbar>

        <v-snackbar bottom v-model="snackbarDelete" :color="snackbarDeleteColor" timeout="3000">
            {{ snackbarDeleteText }}
        </v-snackbar>

        <v-snackbar bottom v-model="snackbarFileNotSupported" color="grey" timeout="5000" style="z-index: 9999 !important">
            Diese Datei wird vom Editor nicht unterstützt. Bitte lade sie herunter.
        </v-snackbar>
    </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import * as backend from "../api/backend";
import vueCustomScrollbar from "vue-custom-scrollbar";

import fileIcon from '../assets/Icons/leere-korrektur-78.svg'
import bearbeitenIcon from '../assets/Icons/bearbeiten-normal-41.svg'
import rechtsIcon from '../assets/Icons/rechts-11.svg'
import offenIcon from "../assets/Icons/offen-18.svg";
import hochladenIcon from "../assets/Icons/hochladen-15.svg";
import bearbeitetIcon from '../assets/Icons/bearbeitet-17.svg'
import runterladenIcon from '../assets/Icons/runterladen-14.svg'
import schlossIcon from '../assets/Icons/passwort-weiß-02.svg'
import papierkorbIcon from '../assets/Icons/papierkorb-109.svg'
import infoIcon from "../assets/Icons/info-45.svg";

import bioIcon from '../assets/Icons/f-biologie-weiss-35.svg'
import chemieIcon from '../assets/Icons/f-chemie-weiss-37.svg'
import deutschIcon from '../assets/Icons/f-deutsch-weiss-29.svg'
import englischIcon from '../assets/Icons/f-englisch-weiss-30.svg'
import erdkundeIcon from '../assets/Icons/f-erdkunde-weiss-31.svg'
import geschichteIcon from '../assets/Icons/f-geschichte-weiss-40.svg'
import matheIcon from '../assets/Icons/f-mathe-weiss-32.svg'
import musikIcon from '../assets/Icons/f-musik-weiss-82.svg'
import physikIcon from '../assets/Icons/f-physik-weiss-36.svg'
import politikIcon from '../assets/Icons/f-politik-weiss-34.svg'
import religionIcon from '../assets/Icons/f-religion-weiss-39.svg'
import sportIcon from '../assets/Icons/f-sport-weiss-38.svg'
import wirtschaftIcon from '../assets/Icons/f-wirtschaft-weiss-33.svg'

import ProgressBar from "@/components/ProgressBar";
import NameFile from "@/components/NameFile";

export default {
name: "FileWidget",
    components: {
        ProgressBar,
        NameFile,
        vueCustomScrollbar,
    },
    computed: {
        ...mapState("auth", [ "token", "account"]),
        ...mapGetters('util', [ 'currentUploadGroup' ]),
    },
    watch: {
        currentUploadGroup(newVal, oldVal) {
          if (!newVal && oldVal) {
              if (oldVal === 'subject') {
                  this.requestSubjectFolder();
              }

              if (oldVal === 'private') {
                  this.requestPrivateFolder();
              }
          }
        },
    },
    data() {
        return {
            appointments: [],

            schoolSubjectGroups: [],

            subjectDateGroups: [],
            teacherUploads: [],
            pupileUploads: [],

            fach: null,

            privateFolder: false,
            subjectFolder: false,

            videoUrl: null,

            day: null,
            settings: {
                suppressScrollY: false,
                suppressScrollX: true,
                wheelPropagation: false
            },

            snackbar: false,
            snackbarText: '',
            snackbarDelete: false,
            snackbarDeleteText: '',
            snackbarDeleteColor: 'success',
            snackbarFileNotSupported: false,

            fileIcon,
            bearbeitenIcon,
            rechtsIcon,
            runterladenIcon,
            bearbeitetIcon,
            offenIcon,
            hochladenIcon,
            schlossIcon,
            papierkorbIcon,
            infoIcon,
            bioIcon,
            chemieIcon,
            deutschIcon,
            englischIcon,
            erdkundeIcon,
            geschichteIcon,
            matheIcon,
            musikIcon,
            physikIcon,
            politikIcon,
            religionIcon,
            sportIcon,
            wirtschaftIcon,

            showPrivateUploadProgress: false,
            privateUploadProgress: 0.0,
            privateXmlHttpRequest: null,

            showSubjectUploadProgress: false,
            subjectUploadProgress: 0.0,
            subjectXmlHttpRequest: null,

            showNameFile: false,
            uploadFileName: "",
            privateFiles: [],
            privateFileSize: 0,
            privateFileProgress : 0,
            fileNameCallback: () => {},
            teacherId: '',
            subjectFiles: [],
            slots: [
            ],
        };
    },
    async mounted() {
        this.requestAppointments();
        this.requestPrivateFolder();
        this.requestSlots();
    },
    methods: {
        ...mapActions("appointments", ["getAppointments"]),
        ...mapActions("timeslots", ["getSlots"]),
        ...mapActions("privateFolder", ["getPrivateFolder", "deletePrivateFile"]),
        ...mapActions("subjectFolder", ["getSubjectFolder", "deleteSubjectFile"]),
        ...mapActions("util", ['toggleLoading', 'toggleOpenAppointment', 'toggleCurrentUploadGroup']),
        ...mapActions('files', [ 'setSnackbar' ]),
        getTeacherDisplayName(teacher) {
             let prefix = '';

            if (teacher.gender === 'm') {
                prefix = 'Hr. ';
            } else if (teacher.gender === 'w') {
                prefix = 'Fr. ';
            }

            let name = '';
            if (teacher.name.length > 0) {
                name = teacher.name.slice(0, 1) + '. ';
            }

            const lastName = teacher.lastName;

            return prefix + name + lastName;
        },
        async requestPrivateFolder() {
            this.privateFiles = await this.getPrivateFolder();
            this.privateFileSize = 0;
            this.privateFiles.forEach((el) => {
                this.privateFileSize += Number(el.size);
            })
            if(this.account.role == 'teacher') {
                this.privateFileProgress = this.privateFileSize / 1048576000 * 100;
            } else {
                this.privateFileProgress = this.privateFileSize / 104857600 * 100;
            }
        },
        async requestSlots() {
            this.slots = await this.getSlots();
        },

        getSlotTime(slotId) {
            const temp = this.slots.find((el) => el._id === slotId)
            return ("0" + new Date(temp.start).getHours()).slice(-2) + ":" + ("0" + new Date(temp.start).getMinutes()).slice(-2) + '-' +("0" + new Date(temp.end).getHours()).slice(-2) + ":" + ("0" + new Date(temp.end).getMinutes()).slice(-2);
        },
        selectSubjectFolder(teacherId){
            this.teacherId = teacherId;
            this.subjectFolder = true;
            this.requestSubjectFolder();
        },
        async requestSubjectFolder() {
            this.subjectFiles = await this.getSubjectFolder({
                subjectId: this.fach[0].schoolSubject._id,
                teacherId: this.teacherId
            });
        },

        getSrc(icon) {
            return this[icon];
        },
        fileNotSupported(el) {
            this.snackbarFileNotSupported = true;
        },
        count(el) {
            let returnValue = 0;
            if(this.account.role == 'teacher') {
                el.forEach((element) => {
                    returnValue += element.pupilUpload.filter((file) => !file.seenByMe).length;
                });
            } else {
                el.forEach((element) => {
                    returnValue += element.teacherUploads.filter((file) => !file.seenByMe).length
                });
            }
            return returnValue;
        },

        getColor(){
          return this.thisSubject ? this['color' + this.thisSubject.color.substring(1)] : (this.thisAppointment ? this['color' + this.thisAppointment.schoolSubject.color.substring(1)] : this.colorb2b2b2)
        },

        async clickDownloadTeacherUpload(file) {
            file.seenByMe = true;
            const res = await backend.getTeacherUpload(file.appointment, file.file);
            fetch(res.url, {
                method: 'GET',
                headers: new Headers({
                    "Authorization": "Bearer " + this.token
                })
            })
                .then(response => response.blob())
                .then(blob => {
                    var url = window.URL.createObjectURL(blob);
                    var a = document.createElement('a');
                    a.href = url;
                    a.download = file.title;
                    document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                    a.click();
                    a.remove();  //afterwards we remove the element again
                });

        },

        async clickDeleteTeacherUpload(file) {
            const res = await backend.deleteTeacherUpload((file._id).toString());

            if (res.status === 204) {
                this.snackbarDeleteText = "Datei " + file.title + " erfolgreich gelöscht"
                this.snackbarDelete = true;
                let pos = this.teacherUploads.indexOf(file);
                this.teacherUploads.splice(pos, 1);
                await this.requestAppointments();
                await this.selectSubject(this.schoolSubjectGroups.find((el) => {
                    return el[0].schoolSubject._id == this.fach[0].schoolSubject._id
                }));
            } else {
                this.snackbarDeleteText = "Beim Löschen ist ein Fehler aufgetreten"
                this.snackbarDeleteColor = 'error';
                this.snackbarDelete = true;
            }
        },

        async clickDownloadPupilUpload(file) {
            file.seenByMe = true;
            const res = await backend.getPupilUpload(
                file.appointment, file.file
            );
            fetch(res.url, {
                method: "GET",
                headers: new Headers({
                    Authorization: "Bearer " + this.token,
                }),
            })
                .then((response) => response.blob())
                .then((blob) => {
                    var url = window.URL.createObjectURL(blob);
                    var a = document.createElement("a");
                    a.href = url;
                    a.download = file.title;
                    document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                    a.click();
                    a.remove(); //afterwards we remove the element again
                });
        },

        async clickDownloadPupilCorrection(file) {
            let teacherCorrection = null;

            for (let i = 0; i < this.teacherUploads.length; i++) {
                if (this.teacherUploads[i].editParent) {
                    const index = file.editLeafs.indexOf(this.teacherUploads[i]._id);
                    if (index !== -1) {
                        teacherCorrection = this.teacherUploads[i];
                    }
                }
            }

            if (teacherCorrection) {
                teacherCorrection.seenByMe = true;

                const res = await backend.getTeacherUpload(file.appointment, teacherCorrection.file);

                fetch(res.url, {
                    method: 'GET',
                    headers: new Headers({
                        "Authorization": "Bearer " + this.token
                    })
                })
                    .then(response => response.blob())
                    .then(blob => {
                        var url = window.URL.createObjectURL(blob);
                        var a = document.createElement('a');
                        a.href = url;
                        a.download = teacherCorrection.title;
                        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                        a.click();
                        a.remove();  //afterwards we remove the element again
                    });

            }
        },

        async requestAppointments() {
            this.appointments = await this.getAppointments();
            this.schoolSubjectGroups = this.groupBy(this.appointments, 'schoolSubject');
        },

        async selectSubject(subject) {
            this.fach = subject;
            this.subjectDateGroups = this.groupBy(this.fach, 'day');

            this.subjectDateGroups.sort((a, b) => {
                let keyA = new Date(a[0].day),
                    keyB = new Date(b[0].day);
                // Compare the 2 dates
                if (keyA < keyB) return -1;
                if (keyA > keyB) return 1;
                return 0;
            });
        },

        async selectDay(day) {
            this.day = day;
            this.teacherUploads = [];
            this.pupileUploads = [];
            day.forEach((el) => {
                el.teacherUploads.forEach((element) => {
                    element['appointment'] = el._id
                });
                el.pupilUpload.forEach((element) => {
                    element['appointment'] = el._id
                });
                this.teacherUploads = this.teacherUploads.concat(el.teacherUploads);
                this.pupileUploads = this.pupileUploads.concat(el.pupilUpload);
            });
        },


        openEdit(name, id, uploadId, appointmentId) {

            const group = this.fach[0].schoolSubject.name;
            const color = this.fach[0].schoolSubject.color;
            const fileTitle = name;
            const fileId = id;

            this.$router.push({
                name: 'schueler.edit',
                query:
                    {
                        group: group,
                        color: color,
                        aId: appointmentId,
                        title: fileTitle,
                        fId: fileId,
                        uploadid: uploadId
                    }
            });
        },

        openAnsicht(name, id, uploadId, appointmentId, userGroup) {

            const group = this.fach[0].schoolSubject.name;
            const color = this.fach[0].schoolSubject.color;
            const fileTitle = name;
            const fileId = id;

            this.$router.push({
                name: 'schueler.view',
                query:
                    {
                        group: group,
                        color: color,
                        aId: appointmentId,
                        title: fileTitle,
                        fId: fileId,
                        uploadid: uploadId,
                        ug: userGroup
                    }
            });
        },



        openEditTeacher(name, id, uploadId, appointmentId) {
            const group = this.fach[0].schoolSubject.name;
            const color = this.fach[0].schoolSubject.color;
            const fileTitle = name;
            const fileId = id;

            this.$router.push({
                name: 'lehrer.edit',
                query:
                    {
                        group: group,
                        color: color,
                        aId: appointmentId,
                        title: fileTitle,
                        fId: fileId,
                        uploadid: uploadId
                    }
            });
        },

        openAnsichtTeacher(name, id, uploadId, appointmentId) {
            const group = this.fach[0].schoolSubject.name;
            const color = this.fach[0].schoolSubject.color;
            const fileTitle = name;
            const fileId = id;

            this.$router.push({
                name: 'lehrer.view',
                query:
                    {
                        group: group,
                        color: color,
                        aId: appointmentId,
                        title: fileTitle,
                        fId: fileId,
                        uploadid: uploadId
                    }
            });
        },

        isCorrectionAvailable(pupilFile) {
            for (let i = 0; i < this.teacherUploads.length; i++) {
                if (this.teacherUploads[i].editParent) {
                    const index = pupilFile.editLeafs.indexOf(
                        this.teacherUploads[i]._id
                    );
                    if (index !== -1) {
                        return true;
                    }
                }
            }
            return false;
        },

        getCurrentCorrection(pupilFile) {
            for (let i = 0; i < this.teacherUploads.length; i++) {
                if (this.teacherUploads[i].editParent) {
                    const index = pupilFile.editLeafs.indexOf(this.teacherUploads[i]._id);
                    if (index !== -1) {
                        return this.teacherUploads[i];
                    }
                }
            }
            return null;
        },


        async play(file) {
            file.seenByMe = true;
            const res = await backend.getTeacherUpload(file.appointment, file.file);
            fetch(res.url, {
                method: 'GET',
                headers: new Headers({
                    "Authorization": "Bearer " + this.token
                })
            })
                .then(response => response.blob())
                .then(blob => {
                    var url = window.URL.createObjectURL(blob);
                    this.videoUrl = url;

                });

        },

        getFachByTeacher() {
            return this.groupBy(this.fach, 'teacher')
        },
        groupBy(collection, prop) {
            let i = 0, val, index,
                values = [], result = [];
            for (; i < collection.length; i++) {
                val = collection[i][prop];
                index = values.findIndex((el) => {
                    return JSON.stringify(el) === JSON.stringify(val)
                });
                if (index > -1)
                    result[index].push(collection[i]);
                else {
                    values.push(val);
                    result.push([collection[i]]);
                }
            }
            return result;
        },

        async clickDownloadPrivateUpload(file) {
            const res = await backend.getPrivateUpload(
                file._id
            );
            fetch(res.url, {
                method: "GET",
                headers: new Headers({
                    Authorization: "Bearer " + this.token,
                }),
            })
                .then((response) => response.blob())
                .then((blob) => {
                    var url = window.URL.createObjectURL(blob);
                    var a = document.createElement("a");
                    a.href = url;
                    a.download = file.uploadedName;
                    document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                    a.click();
                    a.remove(); //afterwards we remove the element again
                });
        },

        openPrivateAnsicht(file) {
            console.log('openPrivateAnsicht called with file', file);

            const fileTitle = file.uploadedName;
            const fileId = file._id;
            const userGroup = 'private';

            let route = 'lehrer.view';
            if (this.account.role === 'pupil') {
                route = 'schueler.view';
            }

            this.$router.push({
                name: route,
                query:
                    {
                        color: '#6996cd',
                        title: fileTitle,
                        fId: fileId,
                        ug: userGroup
                    }
            });
        },

        async clickDeletePrivateUpload(file) {
            await this.deletePrivateFile(file._id)
            this.requestPrivateFolder();
        },

        clickPrivateUploadButton() {
            this.toggleOpenAppointment(null);
            this.toggleCurrentUploadGroup('private');
            document.getElementById("uploadInput").click();
        },

        async privateUploadInputChange() {
            const input = this.$refs.privateUploadInput;
            const file = input.files[0];
            if (file) {
                const name = file.name;
                const lastDot = name.lastIndexOf(".");
                const fileName = name.substring(0, lastDot);
                const fileExtension = name.substring(lastDot + 1);
                this.uploadFileName = fileName;
                this.fileNameCallback = (newFileName) => {
                    this.showNameFile = false;
                    this.uploadFile( newFileName, fileExtension);
                };
                this.showNameFile = true;
            }
        },

        async uploadFile( newFileName, fileExtension) {
            const input = this.$refs.privateUploadInput;
            const file = input.files[0];
            if (file) {

                if (file.size > 1000000000) {
                    this.setSnackbar({ type: 'bigFileToBig', value: 'true' });
                    return;
                }

                if ((this.account.role === 'teacher' && (file.size + this.privateFileProgress) > 1000000000)
                    || (this.account.role === 'pupil' && (file.size + this.privateFileProgress) > 100000000)) {
                    this.setSnackbar({ type: 'fileDoesntFitInPrivateFolder', value: 'true' });
                    return;
                }

                const formData = new FormData();
                formData.append("file", file, `${newFileName}.${fileExtension}`);

                this.privateXmlHttpRequest = backend.postPrivateUpload(
                    file
                );

                this.privateXmlHttpRequest.onerror = (e) => {
                    console.error("Private upload error:", e);
                    this.showPrivateUploadProgress = false;
                    this.privateXmlHttpRequest = null;
                    this.$refs.privateUploadInput.value = "";
                };

                this.privateXmlHttpRequest.onabort = (e) => {
                    console.warn("Private upload aborted");
                    this.showPrivateUploadProgress = false;
                    this.privateXmlHttpRequest = null;
                    this.$refs.privateUploadInput.value = "";
                };

                this.privateXmlHttpRequest.upload.addEventListener("progress", (e) => {
                    this.privateUploadProgress = (e.loaded / e.total) * 100;
                });

                this.privateXmlHttpRequest.addEventListener("load", (e) => {
                    if (this.privateXmlHttpRequest.status !== 201) {
                        if(this.privateXmlHttpRequest.status === 409) {
                            this.snackbarText = 'Speicher überschritten. Datei konnte nicht gespeichert werden!';
                            this.snackbar = true;
                        }

                        console.error(
                            "Private upload failed:",
                            this.privateXmlHttpRequest.response
                        );
                    }

                    this.showPrivateUploadProgress = false;
                    this.snackbarSave = true;
                    this.requestPrivateFolder();
                    this.privateXmlHttpRequest = null;
                    this.$refs.privateUploadInput.value = "";
                });
                this.privateUploadProgress = 0.0;
                this.showPrivateUploadProgress = true;
                this.privateXmlHttpRequest.send(formData);
            }
        },

        async clickDownloadSubjectUpload(file) {
            const res = await backend.getSubjectFolderUpload(
                file._id
            );
            fetch(res.url, {
                method: "GET",
                headers: new Headers({
                    Authorization: "Bearer " + this.token,
                }),
            })
                .then((response) => response.blob())
                .then((blob) => {
                    var url = window.URL.createObjectURL(blob);
                    var a = document.createElement("a");
                    a.href = url;
                    a.download = file.uploadedName;
                    document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                    a.click();
                    a.remove(); //afterwards we remove the element again
                });
        },

        async clickDeleteSubjectUpload(file) {
            await this.deleteSubjectFile({
                subjectId: this.fach[0].schoolSubject._id,
                teacherId: this.fach[0].teacher._id,
                fileId: file._id
            })
            this.requestSubjectFolder();
        },

        clickSubjectUploadButton() {
            this.toggleOpenAppointment(this.fach[0].schoolSubject);
            this.toggleCurrentUploadGroup('subject');
            document.getElementById("uploadInput").click();
        },

        async subjectUploadInputChange() {
            const input = this.$refs.subjectUploadInput;
            const file = input.files[0];
            if (file) {
                const name = file.name;
                const lastDot = name.lastIndexOf(".");
                const fileName = name.substring(0, lastDot);
                const fileExtension = name.substring(lastDot + 1);
                this.uploadFileName = fileName;
                this.fileNameCallback = (newFileName) => {
                    this.showNameFile = false;
                    this.uploadSubjectFile( newFileName, fileExtension);
                };
                this.showNameFile = true;
            }
        },

        async uploadSubjectFile( newFileName, fileExtension) {
            const input = this.$refs.subjectUploadInput;
            const file = input.files[0];
            if (file) {

                if (file.size > 1000000000) {
                    this.setSnackbar({ type: 'bigFileToBig', value: 'true' });
                    return;
                }

                const formData = new FormData();
                formData.append("file", file, `${newFileName}.${fileExtension}`);

                this.subjectXmlHttpRequest = backend.postSubjectFolderUpload(
                    this.fach[0].schoolSubject._id,
                    file
                );

                this.subjectXmlHttpRequest.onerror = (e) => {
                    console.error("Private upload error:", e);
                    this.showSubjectUploadProgress = false;
                    this.subjectXmlHttpRequest = null;
                    this.$refs.subjectUploadInput.value = "";
                };

                this.subjectXmlHttpRequest.onabort = (e) => {
                    console.warn("Private upload aborted");
                    this.showSubjectUploadProgress = false;
                    this.subjectXmlHttpRequest = null;
                    this.$refs.subjectUploadInput.value = "";
                };

                this.subjectXmlHttpRequest.upload.addEventListener("progress", (e) => {
                    this.subjectUploadProgress = (e.loaded / e.total) * 100;
                });

                this.subjectXmlHttpRequest.addEventListener("load", (e) => {
                    if (this.subjectXmlHttpRequest.status !== 201) {
                        if(this.subjectXmlHttpRequest.status === 409) {
                            this.snackbarText = 'Speicher überschritten. Datei konnte nicht gespeichert werden!';
                            this.snackbar = true;
                        }

                        console.error(
                            "Private upload failed:",
                            this.subjectXmlHttpRequest.response
                        );
                    }

                    this.showSubjectUploadProgress = false;
                    this.snackbarSave = true;
                    this.requestSubjectFolder();
                    this.subjectXmlHttpRequest = null;
                    this.$refs.subjectUploadInput.value = "";
                });
                this.subjectUploadProgress = 0.0;
                this.showSubjectUploadProgress = true;
                this.subjectXmlHttpRequest.send(formData);
            }
        },


    }
}
</script>

<style lang="scss" scoped>

.whiteBg {
    background-color: #ffffff;
}

//filter generated with https://codepen.io/sosuke/pen/Pjoqqp
.iconToWhite {
    filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%) hue-rotate(36deg) brightness(104%) contrast(105%);
}

.scroll-area {
    position: relative;
    margin: auto;
    max-height: calc(80vh - 75px);
}

.iconSelect {
    display: inline-flex;
    width: 24px;
    height: 24px;
    padding: 2px;
    margin-bottom: -6px;
    border-radius: 5px;
    margin-right: 2px;
    background-color: rgba(234, 234, 234, 0.70);
}

.greyText {
    color: #707070;
}

/*.btnStyle {
    min-width: 36px;
    min-height: 36px;
    margin-top: -3px;
}*/

.materialTitle {
    cursor: pointer;
    height: 24px !important;
    width: 70%;
    padding-top: 4px;
    padding-right: 5px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
</style>
