<template>
    <div id="container">
        <v-btn
                v-if="!chatOpen"
                @click="chatOpen = !chatOpen"
                style="background-color: #FFFFFF; width: 50px; height: 50px; border-radius: 8px;"
                x-small elevation="0"
                class="ml-4 animateIcon"
        >
            <img :src="chatIconGruen" style="max-width: 25px; max-height: 25px;"/>
        </v-btn>
        <v-btn
                v-else
                @click="chatOpen = !chatOpen"
                style="background-color: #8cbd46; width: 50px; height: 50px; border-radius: 8px;"
                x-small elevation="0"
                class="ml-4 animateIcon"
        >
            <img :src="chatIcon" class="iconToWhite" style="max-width: 25px; max-height: 25px;"/>
        </v-btn>
        <BabyChat id="ChatWidget" v-show="chatOpen"></BabyChat>
    </div>

</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";

import vueCustomScrollbar from "vue-custom-scrollbar";
import chatIcon from '../../assets/Icons/chat-alternative-102.svg';
import chatIconGruen from '../../assets/Icons/chat-alternative-gruen-102.svg';

import BabyChat from "@/components/Schueler/BabyChatWidget";

export default {
name: "BabyChatDropDown",
    components: {
        BabyChat,
    },
    data() {
        return {
            chatOpen: false,
            chatIcon,
            chatIconGruen,
        };
    },
    mounted() {
    },
    computed : {
    },
    methods: {

    }
}
</script>

<style lang="scss" scoped>
    #container {
        position: relative;
    }

    #ChatWidget {
        position: absolute;
        top: 100%;
        right: 0;
        z-index: 1;
    }


    .animateIcon {
        overflow: hidden;
    }

    .animateIcon:after {
        animation: shine 60s ease-in-out infinite;
        animation-fill-mode: forwards;
        content: "";
        position: absolute;
        top: -110%;
        left: -210%;
        width: 200%;
        height: 200%;
        opacity: 0;
        transform: rotate(30deg);

        background: rgba(255, 255, 255, 0.0);
        background: linear-gradient(
                        to right,
                        rgba(112, 112, 112, 0.2) 0%,
                        rgba(112, 112, 112, 0.2) 77%,
                        rgba(112, 112, 112, 0.5) 92%,
                        rgba(112, 112, 112, 0.0) 100%
        );
    }

    /* Hover state - trigger effect */

    /* Active state */
    .animateIcon:active:after {
        opacity: 0;
    }

    @keyframes shine{
        1% {
            opacity: 1;
            top: -30%;
            left: -30%;
            transition-property: left, top, opacity;
            transition-duration: 0.7s, 0.7s, 0.15s;
            transition-timing-function: ease;
        }
        5% {
            opacity: 0;
            top: -30%;
            left: -30%;
            transition-property: left, top, opacity;
        }
    }

    //filter generated with https://codepen.io/sosuke/pen/Pjoqqp
    .iconToWhite {
        filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%) hue-rotate(36deg) brightness(104%) contrast(105%);
    }
</style>
