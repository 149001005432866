var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        { staticStyle: { "background-color": "#EEEEEE" } },
        [
          _c(
            "v-col",
            { staticStyle: { "margin-left": "5%" } },
            [
              _c(
                "div",
                { staticClass: "topNavMobile" },
                [
                  _c(
                    "v-btn",
                    {
                      ref: "topMenuBtn",
                      staticClass: "btmNavBtn pa-0 ml-4 animateIcon",
                      staticStyle: {
                        "background-color": "#FFFFFF",
                        width: "50px",
                        height: "50px",
                        "border-radius": "8px"
                      },
                      style: _vm.topMenu
                        ? "background-color: #6995CD !important;"
                        : "",
                      attrs: { elevation: "0", "x-small": "" },
                      on: { click: _vm.handleTopMenuClick }
                    },
                    [
                      _c("img", {
                        staticStyle: {
                          "max-width": "25px",
                          "max-height": "25px"
                        },
                        style: _vm.topMenu
                          ? "filter: brightness(1000%);"
                          : "filter: brightness(0) saturate(100%) invert(56%) sepia(46%) saturate(448%) hue-rotate(174deg) brightness(93%) contrast(86%);",
                        attrs: { src: _vm.menuIcon }
                      })
                    ]
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "closable",
                          rawName: "v-closable",
                          value: {
                            exclude: ["topMenuBtn"],
                            handler: "onCloseTop"
                          },
                          expression:
                            "{exclude: ['topMenuBtn'], handler: 'onCloseTop'}"
                        }
                      ],
                      staticClass: "d-flex flex-column pa-2 topMenu",
                      class: _vm.topMenu ? "" : "hideMe",
                      staticStyle: { "z-index": "2" }
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "ml-4",
                          staticStyle: {
                            "background-color": "#FFFFFF",
                            width: "50px",
                            height: "50px",
                            "border-radius": "8px"
                          },
                          attrs: {
                            link: "",
                            to: "/verwaltung/plan",
                            "active-class": "activeLink",
                            "x-small": "",
                            elevation: "0"
                          }
                        },
                        [
                          _c("img", {
                            staticStyle: {
                              "max-width": "25px",
                              "max-height": "25px"
                            },
                            attrs: { src: _vm.stundenplanIcon }
                          })
                        ]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "ml-4",
                          staticStyle: {
                            "background-color": "#FFFFFF",
                            width: "50px",
                            height: "50px",
                            "border-radius": "8px"
                          },
                          attrs: {
                            link: "",
                            to: "/verwaltung/schwarzes-brett",
                            "active-class": "activeLink",
                            "x-small": "",
                            elevation: "0"
                          }
                        },
                        [
                          _c("img", {
                            staticStyle: {
                              "max-width": "25px",
                              "max-height": "25px"
                            },
                            attrs: { src: _vm.swbIcon }
                          })
                        ]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "ml-4",
                          staticStyle: {
                            "background-color": "#FFFFFF",
                            width: "50px",
                            height: "50px",
                            "border-radius": "8px"
                          },
                          attrs: {
                            link: "",
                            to: "/verwaltung/schueler",
                            "active-class": "activeLink",
                            "x-small": "",
                            elevation: "0"
                          }
                        },
                        [
                          _c("img", {
                            staticStyle: {
                              "max-width": "25px",
                              "max-height": "25px"
                            },
                            attrs: { src: _vm.schuelerIcon }
                          })
                        ]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "ml-4",
                          staticStyle: {
                            "background-color": "#FFFFFF",
                            width: "50px",
                            height: "50px",
                            "border-radius": "8px"
                          },
                          attrs: {
                            link: "",
                            to: "/verwaltung/lehrer",
                            "active-class": "activeLink",
                            "x-small": "",
                            elevation: "0"
                          }
                        },
                        [
                          _c("img", {
                            staticStyle: {
                              "max-width": "25px",
                              "max-height": "25px"
                            },
                            attrs: { src: _vm.lehrerIcon }
                          })
                        ]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "ml-4",
                          staticStyle: {
                            "background-color": "#FFFFFF",
                            width: "50px",
                            height: "50px",
                            "border-radius": "8px"
                          },
                          attrs: {
                            link: "",
                            to: "/verwaltung/groups",
                            "active-class": "activeLink",
                            "x-small": "",
                            elevation: "0"
                          }
                        },
                        [
                          _c("img", {
                            staticStyle: {
                              "max-width": "25px",
                              "max-height": "25px"
                            },
                            attrs: { src: _vm.gruppenIcon }
                          })
                        ]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "ml-4",
                          staticStyle: {
                            "background-color": "#FFFFFF",
                            width: "50px",
                            height: "50px",
                            "border-radius": "8px"
                          },
                          attrs: {
                            link: "",
                            to: "/verwaltung/faecher",
                            "active-class": "activeLink",
                            "x-small": "",
                            elevation: "0"
                          }
                        },
                        [
                          _c("img", {
                            staticStyle: {
                              "max-width": "25px",
                              "max-height": "25px"
                            },
                            attrs: { src: _vm.faecherIcon }
                          })
                        ]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "ml-4",
                          staticStyle: {
                            "background-color": "#FFFFFF",
                            width: "50px",
                            height: "50px",
                            "border-radius": "8px"
                          },
                          attrs: {
                            link: "",
                            to: "/verwaltung/zeitslots",
                            "active-class": "activeLink",
                            "x-small": "",
                            elevation: "0"
                          }
                        },
                        [
                          _c("img", {
                            staticStyle: {
                              "max-width": "25px",
                              "max-height": "25px"
                            },
                            attrs: { src: _vm.uhrIcon }
                          })
                        ]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "ml-4",
                          staticStyle: {
                            "background-color": "#FFFFFF",
                            width: "50px",
                            height: "50px",
                            "border-radius": "8px"
                          },
                          attrs: {
                            link: "",
                            to: "../verwaltung/chat-verwaltung",
                            "active-class": "activeLink",
                            "x-small": "",
                            elevation: "0"
                          }
                        },
                        [
                          _c("img", {
                            staticStyle: {
                              "max-width": "25px",
                              "max-height": "25px"
                            },
                            attrs: { src: _vm.chatIcon }
                          })
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  staticClass: "ml-4 topNav",
                                  attrs: {
                                    link: "",
                                    to: "/verwaltung/plan",
                                    "active-class": "activeLink",
                                    "x-small": "",
                                    elevation: "0"
                                  }
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [_c("img", { attrs: { src: _vm.stundenplanIcon } })]
                          )
                        ]
                      }
                    }
                  ])
                },
                [_c("span", [_vm._v("Stundenplan")])]
              ),
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  staticClass: "ml-4 topNav",
                                  attrs: {
                                    link: "",
                                    to: "/verwaltung/schwarzes-brett",
                                    "active-class": "activeLink",
                                    "x-small": "",
                                    elevation: "0"
                                  }
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [_c("img", { attrs: { src: _vm.swbIcon } })]
                          )
                        ]
                      }
                    }
                  ])
                },
                [_c("span", [_vm._v("Schwarzes Brett")])]
              ),
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  staticClass: "ml-4 topNav",
                                  attrs: {
                                    link: "",
                                    to: "/verwaltung/schueler",
                                    "active-class": "activeLink",
                                    "x-small": "",
                                    elevation: "0"
                                  }
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [_c("img", { attrs: { src: _vm.schuelerIcon } })]
                          )
                        ]
                      }
                    }
                  ])
                },
                [_c("span", [_vm._v("Schüler")])]
              ),
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  staticClass: "ml-4 topNav",
                                  attrs: {
                                    link: "",
                                    to: "/verwaltung/lehrer",
                                    "active-class": "activeLink",
                                    "x-small": "",
                                    elevation: "0"
                                  }
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [_c("img", { attrs: { src: _vm.lehrerIcon } })]
                          )
                        ]
                      }
                    }
                  ])
                },
                [_c("span", [_vm._v("Lehrer")])]
              ),
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  staticClass: "ml-4 topNav",
                                  attrs: {
                                    link: "",
                                    to: "/verwaltung/groups",
                                    "active-class": "activeLink",
                                    "x-small": "",
                                    elevation: "0"
                                  }
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [_c("img", { attrs: { src: _vm.gruppenIcon } })]
                          )
                        ]
                      }
                    }
                  ])
                },
                [_c("span", [_vm._v("Gruppen")])]
              ),
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  staticClass: "ml-4 topNav",
                                  attrs: {
                                    link: "",
                                    to: "/verwaltung/faecher",
                                    "active-class": "activeLink",
                                    "x-small": "",
                                    elevation: "0"
                                  }
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [_c("img", { attrs: { src: _vm.faecherIcon } })]
                          )
                        ]
                      }
                    }
                  ])
                },
                [_c("span", [_vm._v("Fächer")])]
              ),
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  staticClass: "ml-4 topNav",
                                  attrs: {
                                    link: "",
                                    to: "/verwaltung/zeitslots",
                                    "active-class": "activeLink",
                                    "x-small": "",
                                    elevation: "0"
                                  }
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [_c("img", { attrs: { src: _vm.uhrIcon } })]
                          )
                        ]
                      }
                    }
                  ])
                },
                [_c("span", [_vm._v("Schulstunden")])]
              ),
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  staticClass: "ml-4 topNav",
                                  attrs: {
                                    link: "",
                                    to: "../verwaltung/chat-verwaltung",
                                    "active-class": "activeLink",
                                    "x-small": "",
                                    elevation: "0"
                                  }
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [_c("img", { attrs: { src: _vm.chatIcon } })]
                          )
                        ]
                      }
                    }
                  ])
                },
                [_c("span", [_vm._v("Chat")])]
              )
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "d-flex align-center", attrs: { cols: "2" } },
            [_c("img", { attrs: { src: _vm.logo, height: "50px" } })]
          ),
          _c(
            "v-col",
            {
              staticClass: "d-flex justify-end align-center",
              staticStyle: { "margin-right": "5%" }
            },
            [
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  staticClass: "ml-4 animateIcon",
                                  staticStyle: {
                                    "background-color": "#FFFFFF",
                                    width: "50px",
                                    height: "50px",
                                    "border-radius": "8px"
                                  },
                                  attrs: { "x-small": "", elevation: "0" },
                                  on: {
                                    click: function($event) {
                                      return _vm.reloadPage()
                                    }
                                  }
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [
                              _c("img", {
                                staticStyle: {
                                  "max-width": "25px",
                                  "max-height": "25px"
                                },
                                attrs: { src: _vm.aktualisierenIcon }
                              })
                            ]
                          )
                        ]
                      }
                    }
                  ])
                },
                [_c("span", [_vm._v("Aktualisieren")])]
              ),
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  staticClass: "ml-4 ",
                                  staticStyle: {
                                    "background-color": "#FFFFFF",
                                    width: "50px",
                                    height: "50px",
                                    "border-radius": "8px"
                                  },
                                  attrs: { "x-small": "", elevation: "0" },
                                  on: { click: _vm.clickLogoutUser }
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [
                              _c("img", {
                                staticStyle: { "max-height": "25px" },
                                attrs: { src: _vm.logoutIcon }
                              })
                            ]
                          )
                        ]
                      }
                    }
                  ])
                },
                [_c("span", [_vm._v("Abmelden")])]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }