var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _vm.showLoading
        ? _c("v-progress-linear", {
            staticStyle: { position: "fixed", top: "0 !important" },
            attrs: {
              indeterminate: "",
              "buffer-value": "100",
              color: "primary",
              height: "10"
            }
          })
        : _vm._e(),
      _c("progress-bar", {
        attrs: {
          title: "Hochladen",
          "show-progress": _vm.showPupilUploadProgress,
          progress: _vm.pupilUploadProgress,
          "abort-progress": _vm.pupilXmlHttpRequest
            ? function() {
                _vm.pupilXmlHttpRequest.abort()
              }
            : function() {}
        }
      }),
      _c("input", {
        ref: "uploadInput",
        attrs: { id: "uploadInput", type: "file", hidden: "" },
        on: {
          change: function() {
            return _vm.uploadInputChange()
          }
        }
      }),
      _c("name-file", {
        attrs: {
          "original-name": _vm.uploadFileName,
          callback: _vm.fileNameCallback,
          group: _vm.currentlyOpenAppointment
            ? _vm.currentlyOpenAppointment.group
            : null
        },
        model: {
          value: _vm.showNameFile,
          callback: function($$v) {
            _vm.showNameFile = $$v
          },
          expression: "showNameFile"
        }
      }),
      _vm.developmentMode ? _c("UserSwitch") : _vm._e(),
      _c("router-view"),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: "5000", color: "success" },
          model: {
            value: _vm.fileSnackbars.dataSaved,
            callback: function($$v) {
              _vm.$set(_vm.fileSnackbars, "dataSaved", $$v)
            },
            expression: "fileSnackbars.dataSaved"
          }
        },
        [_vm._v(" Ergebnisse gespeichert. ")]
      ),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: "5000", color: "success" },
          model: {
            value: _vm.fileSnackbars.dataSubmitted,
            callback: function($$v) {
              _vm.$set(_vm.fileSnackbars, "dataSubmitted", $$v)
            },
            expression: "fileSnackbars.dataSubmitted"
          }
        },
        [_vm._v(" Dein Aufschrieb ist abgegeben. Gut gemacht! ")]
      ),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: "5000", color: "success" },
          model: {
            value: _vm.snackbarSave,
            callback: function($$v) {
              _vm.snackbarSave = $$v
            },
            expression: "snackbarSave"
          }
        },
        [_vm._v(" Gespeichert! ")]
      ),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: "5000", color: "error" },
          model: {
            value: _vm.fileSnackbars.fileToBig,
            callback: function($$v) {
              _vm.$set(_vm.fileSnackbars, "fileToBig", $$v)
            },
            expression: "fileSnackbars.fileToBig"
          }
        },
        [_c("span", [_vm._v("Datei zu groß, max. 5 MB")])]
      ),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: "5000", color: "error" },
          model: {
            value: _vm.fileSnackbars.bigFileToBig,
            callback: function($$v) {
              _vm.$set(_vm.fileSnackbars, "bigFileToBig", $$v)
            },
            expression: "fileSnackbars.bigFileToBig"
          }
        },
        [_c("span", [_vm._v("Datei zu groß, max. 1 GB")])]
      ),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: "5000", color: "error" },
          model: {
            value: _vm.fileSnackbars.fileDoesntFitInPrivateFolder,
            callback: function($$v) {
              _vm.$set(_vm.fileSnackbars, "fileDoesntFitInPrivateFolder", $$v)
            },
            expression: "fileSnackbars.fileDoesntFitInPrivateFolder"
          }
        },
        [_c("span", [_vm._v("Nicht genügend Platz im privaten Ordner")])]
      ),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: "5000", color: "error" },
          model: {
            value: _vm.snackbarPrivateFull,
            callback: function($$v) {
              _vm.snackbarPrivateFull = $$v
            },
            expression: "snackbarPrivateFull"
          }
        },
        [
          _vm._v(
            " Speicher überschritten. Datei konnte nicht gespeichert werden "
          )
        ]
      ),
      _vm.showTranslatedText
        ? _c(
            "v-card",
            { attrs: { id: "translatedTextShow", type: "info" } },
            [
              _c(
                "v-card-text",
                {
                  staticClass: "py-2",
                  staticStyle: { height: "100%", "text-align": "center" }
                },
                [
                  _c("p", { attrs: { id: "translatedTextText" } }, [
                    _vm._v(" " + _vm._s(_vm.translatedText) + " ")
                  ])
                ]
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }