var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: {
        "border-radius": "5px !important",
        "background-color": "#F8F8F8"
      }
    },
    [
      _c(
        "v-row",
        {
          staticClass: "ma-auto mb-1 pa-2",
          style: { backgroundColor: _vm.groupColor, height: "80px" },
          attrs: { align: "center" }
        },
        [
          _c(
            "v-col",
            {
              attrs: {
                cols:
                  _vm.windowWidth < 770 ? (_vm.windowWidth < 380 ? 10 : 9) : 7
              }
            },
            [
              _c(
                "v-menu",
                {
                  staticStyle: { "border-radius": "5px !important" },
                  attrs: {
                    "offset-y": "",
                    "nudge-left": "54",
                    "nudge-bottom": "5"
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  staticClass: "mr-3 toolSelector",
                                  class: {
                                    activeTool:
                                      _vm.currentActiveMethod === "pen"
                                  },
                                  attrs: { "x-small": "" },
                                  on: {
                                    click: function($event) {
                                      return _vm.setTool("pen")
                                    }
                                  }
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [
                              _c("img", {
                                staticStyle: {
                                  "max-width": "25px",
                                  "max-height": "25px"
                                },
                                attrs: { src: _vm.stiftIcon }
                              })
                            ]
                          )
                        ]
                      }
                    }
                  ])
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        width: "200px",
                        height: "25px",
                        display: "inline",
                        "border-radius": "0 !important",
                        "background-color": "#EAEAEA"
                      }
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          staticStyle: {
                            "background-color": "#EAEAEA",
                            "border-radius": "0 !important",
                            width: "54px",
                            height: "49px"
                          },
                          attrs: { text: "", "x-small": "" },
                          on: {
                            click: function($event) {
                              return _vm.changeSize(3)
                            }
                          }
                        },
                        [
                          _c(
                            "v-icon",
                            { staticStyle: { "font-size": "6px !important" } },
                            [_vm._v("fas fa-circle")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "pa-0",
                          staticStyle: {
                            "background-color": "#EAEAEA",
                            "border-radius": "0 !important",
                            width: "54px",
                            height: "49px"
                          },
                          attrs: { text: "", "x-small": "" },
                          on: {
                            click: function($event) {
                              return _vm.changeSize(13)
                            }
                          }
                        },
                        [
                          _c(
                            "v-icon",
                            { staticStyle: { "font-size": "13px !important" } },
                            [_vm._v("fas fa-circle")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "pa-0",
                          staticStyle: {
                            "background-color": "#EAEAEA",
                            "border-radius": "0 !important",
                            width: "54px",
                            height: "49px"
                          },
                          attrs: { text: "", "x-small": "" },
                          on: {
                            click: function($event) {
                              return _vm.changeSize(22)
                            }
                          }
                        },
                        [
                          _c(
                            "v-icon",
                            { staticStyle: { "font-size": "22px !important" } },
                            [_vm._v("fas fa-circle")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "mr-3 toolSelector",
                  class: { activeTool: _vm.currentActiveMethod === "text" },
                  attrs: { "x-small": "" },
                  on: {
                    click: function() {
                      _vm.setTool("text")
                      _vm.spawnTextOnCanvas()
                    }
                  }
                },
                [
                  _c("img", {
                    staticStyle: { "max-width": "28px", "max-height": "35px" },
                    attrs: { src: _vm.textIcon }
                  })
                ]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "toolSelector",
                  class: {
                    activeTool: _vm.currentActiveMethod === "eraser",
                    "mr-1": _vm.windowWidth < 600,
                    "mr-3": _vm.windowWidth > 600
                  },
                  attrs: { "x-small": "" },
                  on: {
                    click: function($event) {
                      return _vm.setTool("eraser")
                    }
                  }
                },
                [
                  _c("img", {
                    staticStyle: { "max-width": "25px", "max-height": "25px" },
                    attrs: { src: _vm.radiererIcon }
                  })
                ]
              ),
              false
                ? _c(
                    "v-btn",
                    {
                      staticClass: "mr-3",
                      staticStyle: { width: "49px", height: "49px" },
                      attrs: { "x-small": "", text: "" },
                      on: {
                        click: function() {
                          _vm.displayOtherButtons = !_vm.displayOtherButtons
                        }
                      }
                    },
                    [
                      _c(
                        "p",
                        {
                          staticStyle: { "font-size": "30px", color: "white" }
                        },
                        [_vm._v("...")]
                      )
                    ]
                  )
                : _vm._e(),
              _vm.displayOtherButtons
                ? _c(
                    "v-menu",
                    {
                      staticStyle: { "border-radius": "5px !important" },
                      attrs: {
                        "offset-y": "",
                        "nudge-left": "162",
                        "nudge-bottom": "5"
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        staticClass: "mr-3 toolSelector",
                                        attrs: { "x-small": "" }
                                      },
                                      "v-btn",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        staticStyle: {
                                          "font-size": "22px !important"
                                        },
                                        attrs: { color: _vm.color }
                                      },
                                      [_vm._v("fas fa-circle")]
                                    )
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        597954644
                      )
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            width: "200px",
                            height: "25px",
                            display: "inline",
                            "border-radius": "0 !important",
                            "background-color": "#EAEAEA"
                          }
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              staticStyle: {
                                "background-color": "#EAEAEA",
                                "border-radius": "0 !important",
                                width: "54px",
                                height: "49px"
                              },
                              attrs: { text: "", "x-small": "" }
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticStyle: {
                                    "font-size": "22px !important",
                                    color: "#fac837"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.changeColor("#fac837")
                                    }
                                  }
                                },
                                [_vm._v("fas fa-circle")]
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "pa-0",
                              staticStyle: {
                                "background-color": "#EAEAEA",
                                "border-radius": "0 !important",
                                width: "54px",
                                height: "49px"
                              },
                              attrs: { text: "", "x-small": "" }
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticStyle: {
                                    "font-size": "22px !important",
                                    color: "#f07d32"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.changeColor("#f07d32")
                                    }
                                  }
                                },
                                [_vm._v("fas fa-circle")]
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "pa-0",
                              staticStyle: {
                                "background-color": "#EAEAEA",
                                "border-radius": "0 !important",
                                width: "54px",
                                height: "49px"
                              },
                              attrs: { text: "", "x-small": "" }
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticStyle: {
                                    "font-size": "22px !important",
                                    color: "#e6231e"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.changeColor("#e6231e")
                                    }
                                  }
                                },
                                [_vm._v("fas fa-circle")]
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "pa-0",
                              staticStyle: {
                                "background-color": "#EAEAEA",
                                "border-radius": "0 !important",
                                width: "54px",
                                height: "49px"
                              },
                              attrs: { text: "", "x-small": "" }
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticStyle: {
                                    "font-size": "22px !important",
                                    color: "#6996cd"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.changeColor("#6996cd")
                                    }
                                  }
                                },
                                [_vm._v("fas fa-circle")]
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "pa-0",
                              staticStyle: {
                                "background-color": "#EAEAEA",
                                "border-radius": "0 !important",
                                width: "54px",
                                height: "49px"
                              },
                              attrs: { text: "", "x-small": "" }
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticStyle: {
                                    "font-size": "22px !important",
                                    color: "#505faa"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.changeColor("#505faa")
                                    }
                                  }
                                },
                                [_vm._v("fas fa-circle")]
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "pa-0",
                              staticStyle: {
                                "background-color": "#EAEAEA",
                                "border-radius": "0 !important",
                                width: "54px",
                                height: "49px"
                              },
                              attrs: { text: "", "x-small": "" }
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticStyle: {
                                    "font-size": "22px !important",
                                    color: "#8cbe46"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.changeColor("#8cbe46")
                                    }
                                  }
                                },
                                [_vm._v("fas fa-circle")]
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "pa-0",
                              staticStyle: {
                                "background-color": "#EAEAEA",
                                "border-radius": "0 !important",
                                width: "54px",
                                height: "49px"
                              },
                              attrs: { text: "", "x-small": "" }
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticStyle: {
                                    "font-size": "22px !important",
                                    color: "#706f6f"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.changeColor("#706f6f")
                                    }
                                  }
                                },
                                [_vm._v("fas fa-circle")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  )
                : _vm._e(),
              false
                ? _c(
                    "v-btn",
                    {
                      staticClass: "mr-3",
                      staticStyle: {
                        "background-color": "#f8f8f8",
                        width: "49px",
                        height: "49px"
                      },
                      attrs: { "x-small": "" },
                      on: { click: _vm.undo }
                    },
                    [
                      _c("img", {
                        staticStyle: {
                          "max-width": "25px",
                          "max-height": "25px"
                        },
                        attrs: { src: _vm.undoIcon }
                      })
                    ]
                  )
                : _vm._e(),
              _vm.displayOtherButtons
                ? _c(
                    "v-btn",
                    {
                      staticClass: "mr-3",
                      staticStyle: {
                        "background-color": "#f8f8f8",
                        width: "49px",
                        height: "49px"
                      },
                      attrs: { "x-small": "" },
                      on: { click: _vm.rotateEditor }
                    },
                    [
                      _c("img", {
                        staticStyle: {
                          "max-width": "25px",
                          "max-height": "25px"
                        },
                        attrs: { src: _vm.drehIcon }
                      })
                    ]
                  )
                : _vm._e(),
              _vm.windowWidth < 600
                ? _c(
                    "v-menu",
                    {
                      attrs: { "offset-y": "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        staticClass: "mr-3",
                                        staticStyle: {
                                          width: "49px",
                                          height: "49px"
                                        },
                                        attrs: {
                                          id: "smallDisplayMoreButtonsTrigger",
                                          "x-small": "",
                                          text: ""
                                        }
                                      },
                                      "v-btn",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [
                                    _c(
                                      "p",
                                      {
                                        staticStyle: {
                                          "font-size": "30px",
                                          color: "white"
                                        }
                                      },
                                      [_vm._v("...")]
                                    )
                                  ]
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        1178558835
                      )
                    },
                    [
                      _c(
                        "div",
                        { attrs: { id: "smallDisplayMoreButtons" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "mr-3",
                              staticStyle: {
                                "background-color": "#f8f8f8",
                                width: "49px",
                                height: "49px"
                              },
                              attrs: { "x-small": "" },
                              on: { click: _vm.rotateEditor }
                            },
                            [
                              _c("img", {
                                staticStyle: {
                                  "max-width": "25px",
                                  "max-height": "25px"
                                },
                                attrs: { src: _vm.drehIcon }
                              })
                            ]
                          ),
                          _c(
                            "v-menu",
                            {
                              staticStyle: {
                                "border-radius": "5px !important"
                              },
                              attrs: {
                                "offset-y": "",
                                "nudge-left": "162",
                                "nudge-bottom": "5"
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      var attrs = ref.attrs
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                staticClass: "mr-3",
                                                staticStyle: {
                                                  "background-color": "#f8f8f8",
                                                  width: "49px",
                                                  height: "49px"
                                                },
                                                attrs: { "x-small": "" }
                                              },
                                              "v-btn",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                staticStyle: {
                                                  "font-size": "22px !important"
                                                },
                                                attrs: { color: _vm.color }
                                              },
                                              [_vm._v("fas fa-circle")]
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                853100742
                              )
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "colorPickerMenu" },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticStyle: {
                                        "background-color": "#EAEAEA",
                                        "border-radius": "0 !important",
                                        width: "54px",
                                        height: "49px"
                                      },
                                      attrs: { text: "", "x-small": "" }
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          staticStyle: {
                                            "font-size": "22px !important",
                                            color: "#fac837"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.changeColor("#fac837")
                                            }
                                          }
                                        },
                                        [_vm._v("fas fa-circle")]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "pa-0",
                                      staticStyle: {
                                        "background-color": "#EAEAEA",
                                        "border-radius": "0 !important",
                                        width: "54px",
                                        height: "49px"
                                      },
                                      attrs: { text: "", "x-small": "" }
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          staticStyle: {
                                            "font-size": "22px !important",
                                            color: "#f07d32"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.changeColor("#f07d32")
                                            }
                                          }
                                        },
                                        [_vm._v("fas fa-circle")]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "pa-0",
                                      staticStyle: {
                                        "background-color": "#EAEAEA",
                                        "border-radius": "0 !important",
                                        width: "54px",
                                        height: "49px"
                                      },
                                      attrs: { text: "", "x-small": "" }
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          staticStyle: {
                                            "font-size": "22px !important",
                                            color: "#e6231e"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.changeColor("#e6231e")
                                            }
                                          }
                                        },
                                        [_vm._v("fas fa-circle")]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "pa-0",
                                      staticStyle: {
                                        "background-color": "#EAEAEA",
                                        "border-radius": "0 !important",
                                        width: "54px",
                                        height: "49px"
                                      },
                                      attrs: { text: "", "x-small": "" }
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          staticStyle: {
                                            "font-size": "22px !important",
                                            color: "#6996cd"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.changeColor("#6996cd")
                                            }
                                          }
                                        },
                                        [_vm._v("fas fa-circle")]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "pa-0",
                                      staticStyle: {
                                        "background-color": "#EAEAEA",
                                        "border-radius": "0 !important",
                                        width: "54px",
                                        height: "49px"
                                      },
                                      attrs: { text: "", "x-small": "" }
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          staticStyle: {
                                            "font-size": "22px !important",
                                            color: "#505faa"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.changeColor("#505faa")
                                            }
                                          }
                                        },
                                        [_vm._v("fas fa-circle")]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "pa-0",
                                      staticStyle: {
                                        "background-color": "#EAEAEA",
                                        "border-radius": "0 !important",
                                        width: "54px",
                                        height: "49px"
                                      },
                                      attrs: { text: "", "x-small": "" }
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          staticStyle: {
                                            "font-size": "22px !important",
                                            color: "#8cbe46"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.changeColor("#8cbe46")
                                            }
                                          }
                                        },
                                        [_vm._v("fas fa-circle")]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "pa-0",
                                      staticStyle: {
                                        "background-color": "#EAEAEA",
                                        "border-radius": "0 !important",
                                        width: "54px",
                                        height: "49px"
                                      },
                                      attrs: { text: "", "x-small": "" }
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          staticStyle: {
                                            "font-size": "22px !important",
                                            color: "#706f6f"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.changeColor("#706f6f")
                                            }
                                          }
                                        },
                                        [_vm._v("fas fa-circle")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ]
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "v-col",
            {
              staticStyle: { display: "flex", "justify-content": "flex-end" },
              attrs: {
                cols:
                  _vm.windowWidth < 770 ? (_vm.windowWidth < 380 ? 2 : 3) : 5
              }
            },
            [
              _c(
                "v-btn",
                {
                  staticClass: "save-image-btn ml-4 mr-3",
                  attrs: { elevation: "3", dark: "", small: "" },
                  on: { click: _vm.saveImage }
                },
                [
                  _c("img", {
                    staticClass: "mr-2",
                    attrs: { src: _vm.fertigIcon }
                  }),
                  _c("p", { staticClass: "mb-0 pa-1" }, [_vm._v("Fertig")])
                ]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "mr-4",
                  attrs: { id: "closeBtn", "x-small": "", elevation: "3" },
                  on: { click: _vm.zwischenspeichern }
                },
                [
                  _c("img", {
                    staticStyle: {
                      height: "40px",
                      filter: "brightness(1000%)"
                    },
                    attrs: { src: _vm.closeIcon }
                  })
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "vuescroll",
        {
          ref: "scrollbar",
          staticStyle: {
            height: "calc(100vh - 85px)",
            "background-color": "#EAEAEA"
          },
          attrs: { ops: _vm.ops }
        },
        [
          _c(
            "v-row",
            {
              staticClass: "ma-0",
              attrs: { align: "center", justify: "center" }
            },
            [
              _c(
                "v-btn",
                {
                  staticClass: "mr-3 zoomControl",
                  attrs: { elevation: "3", "x-small": "" },
                  on: {
                    click: function($event) {
                      return _vm.changeZoom("minus")
                    }
                  }
                },
                [_c("p", [_vm._v("-")])]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "mr-3 zoomControl",
                  staticStyle: { "margin-left": "63px" },
                  attrs: { elevation: "3", "x-small": "" },
                  on: {
                    click: function($event) {
                      return _vm.changeZoom("plus")
                    }
                  }
                },
                [_c("p", [_vm._v("+")])]
              ),
              _c("EditingCanvas", {
                ref: "editingcanvas",
                attrs: {
                  "canvas-height": _vm.canvasHeight,
                  "canvas-width": _vm.canvasWidth,
                  "loading-function": _vm.setLoading
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "hide-overlay": "", persistent: "", width: "150" },
          model: {
            value: _vm.loadingActive,
            callback: function($$v) {
              _vm.loadingActive = $$v
            },
            expression: "loadingActive"
          }
        },
        [
          _c(
            "v-card",
            { attrs: { color: _vm.groupColor } },
            [
              _c(
                "v-card-text",
                { staticClass: "pa-2" },
                [
                  _c("v-progress-circular", {
                    staticClass: "ma-auto",
                    staticStyle: { display: "block" },
                    attrs: { indeterminate: "" }
                  }),
                  _c("br"),
                  _c(
                    "p",
                    {
                      staticClass: "ma-auto",
                      staticStyle: { "text-align": "center" }
                    },
                    [_vm._v(_vm._s(_vm.loadingMessage))]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: "5000", color: "error" },
          model: {
            value: _vm.snackbars.fileNotAllowed,
            callback: function($$v) {
              _vm.$set(_vm.snackbars, "fileNotAllowed", $$v)
            },
            expression: "snackbars.fileNotAllowed"
          }
        },
        [_vm._v(" Zugriff auf diese Datei wurde verweigert. ")]
      ),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: "5000", color: "error" },
          model: {
            value: _vm.snackbars.fileNotAvailable,
            callback: function($$v) {
              _vm.$set(_vm.snackbars, "fileNotAvailable", $$v)
            },
            expression: "snackbars.fileNotAvailable"
          }
        },
        [_vm._v(" Die Datei konnte nicht geladen werden. ")]
      ),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: "5000", color: "error" },
          model: {
            value: _vm.snackbars.dataCouldntBeSaved,
            callback: function($$v) {
              _vm.$set(_vm.snackbars, "dataCouldntBeSaved", $$v)
            },
            expression: "snackbars.dataCouldntBeSaved"
          }
        },
        [
          _vm._v(
            " Ergebnisse konnten nicht gespeichert werden. Bitte versuche es später nochmal. "
          )
        ]
      ),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: "5000", color: "error" },
          model: {
            value: _vm.snackbars.genericError,
            callback: function($$v) {
              _vm.$set(_vm.snackbars, "genericError", $$v)
            },
            expression: "snackbars.genericError"
          }
        },
        [_vm._v(" Ein Fehler ist aufgetreten. ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }