import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#6996cd',
        secondary: '#8cbe46',
        success: '#8cbe46',
        error: '#e6231e',

        //TODO declare Corporate Design colors here and replace color codes with variables
        gruen: '#8CBD46',
        blau: '#6995cd',
      },
    },
  },
});
