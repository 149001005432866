import * as backend from '../../api/backend';
import { checkResponseStatus } from '../../util/check';


const defaultState = {
  teachersInvalid: false,
};

const mutations = {
  setTeachersInvalid: (state, status) => {
    state.teachersInvalid = status;
  },
};

const getters = {
  getTeacherStatus: state => state.teachersInvalid,
}

const actions = {
  setTeachersInvalidStatus ({ commit }, status) {
    commit('setTeachersInvalid', status);
  },
  async createTeacher ({ commit, dispatch }, params) {

    try {
      const res = await backend.postTeacher(params);
      await checkResponseStatus(201, res);
      const account = await res.json();
      return account;
    }
    catch(err) {
      return err.response.status;
    }
  },

  async uploadTeacherPic ({ commit, dispatch }, params) {

    try {
      const res = await backend.postTeacherPicture(params);
      await checkResponseStatus(201, res);
      const account = await res.json();
      return account;
    }
    catch(err) {
      return err.response.status;
    }
  },

  async editTeacher ({ commit, dispatch }, params) {

    try {
      const id = params._id
      delete params._id;
      const res = await backend.patchTeacher(id, params);
      if(res.status === 409) {
        return 409;
      }
      const account = await res.json();
      return account;
    }
    catch(err) {
      return err.response.status;
    }
  },

  async deleteTeacher ({ commit, dispatch }, id) {
    try {
      const res = await backend.deleteTeacher(id);
      if(res.status === 409) {
        return 409;
      }
      await checkResponseStatus(204, res);
      return true;
    }
    catch(err) {
      console.log(err)
      return false;
    }
  },

  async deleteTeacherUpload ({ commit, dispatch }, id) {
    try {
      const res = await backend.deleteTeacherUpload(id);
      if(res.status === 409) {
        return 409;
      }
      await checkResponseStatus(204, res);
      return true;
    }
    catch(err) {
      console.log(err)
      return false;
    }
  },

  async getTeachers ({ commit, dispatch }) {

    try {
      const res = await backend.getTeachers();
      await checkResponseStatus(200, res);
      const tempArray = await res.json();
      const teachers = tempArray.filter(el => el.lastName !== 'Nachname');
      return teachers;
    }
    catch(err) {
      return err.response.status;
    }
  },

  async getMe ({ commit, dispatch }, ) {

    try {
      const res = await backend.getMeTeacher();
      await checkResponseStatus(200, res);
      const pupil = await res.json();
      return pupil;
    }
    catch(err) {
      return err.response.status;
    }
  },

  async getProfilePicture ({ commit, dispatch }, teacherId) {

    try {
      const res = await backend.getProfilePicture(teacherId);
      await checkResponseStatus(200, res);
      const profilePicture = await res.blob();

      return profilePicture;
    }
    catch(err) {
      return err.response.status;
    }
  },

};


export default {
  namespaced: true,
  state: defaultState,
  getters,
  mutations,
  actions,
};
