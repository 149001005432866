<template>
    <div style="position: fixed; bottom: 0; right: 15px; z-index: 2">

      <div class="noselect" :key="`ttstext${update}`" v-if="tutText"
             style="position:relative; background-color: transparent; height: 90px; width: 90px;"
        >
            <div style="position:relative; width: 100%; height: 100%">
                <v-row class="mx-0" style="width: 100%;">
                    <div class="pa-3" style="position: absolute; left: -300px; top:90px;background-color: white;height: auto; width: 300px; border-radius: 15px; box-shadow: 1px 5px 5px silver; margin: auto auto 5em;">
                        Markiere einen Text, klicke mich an und ich lese ihn dir vor.
                    </div>
                </v-row>
            </div>
        </div>


        <div @click="greet" class="noselect" :key="update"
             style="position:relative; background-color: transparent; height: 90px; width: 90px;"
        >
            <div class="rotate-scale-up" style="position:relative; width: 100%; height: 100%" v-if="textSelected || tutText">
                <img style="top: 40%; left: 50%; transform: translate(-50%, -50%); position: inherit" :src="papageiF"/>
            </div>
            <div class="rotate-scale-up"  style="position:relative;  width: 100%; height: 100%" :key="tutText" v-else>
                <img style="top: 60%; left: 50%; transform: translate(-50%, -50%); position: inherit" :src="ei"/>
            </div>
        </div>

    </div>
</template>

<script>
import papageiSW from '@/assets/Papagei-SW.svg'
import papageiSWNeu from '@/assets/Papagei-SW-mitSprechen.svg'
import papageiF from '@/assets/Papagei-Farbig.svg'
import papageiFNeu from '@/assets/Papagei-Farbig-mitSprechen.svg'

import ei from '@/assets/Ei.svg'
import translation from '../util/translate'
import {mapActions, mapGetters} from 'vuex';
import {tutorials} from '@/constants/tutorial';

export default {
    name: "TTS",
    components: {
    },
    data() {
        return {
            name: '',
            selectedText: '',
            update: false,
            // targetLang: 'de',
            papageiSW,
            papageiF,
            papageiSWNeu,
            papageiFNeu,
            ei,
            textSelected: false,
            tutText: false,
            tutorials,
            account: '',
            showTextTimeout: null,
        };
    },
    props: {

    },
    computed: {
        ...mapGetters('translation', [ 'getTargetLang' ]),
        ...mapGetters('pupils', ['getTutorialProgress']),
        getTutProgress() {
            if (!this.account)
                return [];
            return this.getTutorialProgress[this.account] || [];
        },
        targetLang() {
            return this.getTargetLang;
        },

    },
    async mounted() {

      setInterval(() => {
          let selection = JSON.parse(JSON.stringify(this.selectedText));
          if (window.getSelection) {
              selection = window.getSelection();
          }
          // document.getSelection
          else if (document.getSelection) {
              selection = document.getSelection();
          }
          // document.selection
          else if (document.selection) {
              selection =
                  document.selection.createRange().text;
          }
          if(selection.toString() !== this.selectedText) {
              this.update = !this.update;

          }
          this.selectedText = selection.toString();

      }, 100);
        this.isLangPackageAvailable();

        this.tut();
        setInterval(() => {
            this.tut();
        }, 300000);

        this.account = (await this.getMe()).account;
    },

    methods: {
        ...mapActions('pupils', [
            'setTutorialProgress',
            'createPupil',
            'getMe',
        ]),
        ...mapActions('translation', [ 'setTranslatedText', 'showTranslation' ]),

        async finishTut(progress) {
            this.setTutorialProgress({ account: this.account, progress });
        },

        isLangPackageAvailable() {
            for (let i = 0; i < window.speechSynthesis.getVoices().length; i++) {
                if (window.speechSynthesis.getVoices()[i].lang.includes(this.targetLang)) {
                    return true;
                }
            }

            return false;
        },

        async greet () {
            // Code for translation, english as default
            let text = '';
            if (this.targetLang === 'de') {
                text = this.selectedText.toString();
            } else {
                let deText = this.selectedText.toString();
                text = await translation.translateToTargetLang(this.targetLang, deText);

                this.setTranslatedText(text);
                this.showTranslation(true);

                if (this.showTextTimeout) {
                    clearTimeout(this.showTextTimeout);
                    this.showTextTimeout = null;
                }

                this.showTextTimeout = setTimeout(() => {
                    this.showTranslation(false);
                    this.showTextTimeout = null;
                }, 15000)
            }

            if ('speechSynthesis' in window) {
                // Speech Synthesis supported 🎉
            }else{
                // Speech Synthesis Not Supported 😣
                alert("Sorry, your browser doesn't support text to speech!");
            }

            if(window.speechSynthesis.speaking) {
                window.speechSynthesis.cancel()
            } else {
                let msg = new SpeechSynthesisUtterance();
                msg.text = (text !== '' ? text : 'Markiere einen Text, klicke mich an und ich lese ihn dir vor.');
                if (this.isLangPackageAvailable()) {
                    msg.lang = this.targetLang;
                    window.speechSynthesis.speak(msg);
                }
            }

        },


        async tut() {
            this.tutText = true;

            setTimeout(() => {
                this.tutText = false;
            }, 5000);
        }
    },
    watch: {
        selectedText() {
            if(this.selectedText.length) {
                if(!this.textSelected) {
                    this.textSelected = true;
                }
            } else {
                if(this.textSelected) {
                    this.textSelected = false;
                }
            }
        },
    }
};
</script>

<style lang="scss" scoped>
    div[color] {
        background-color: $schueler-blau !important;
    }
    .noselect {
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
    }


    .rotate-scale-up {
        -webkit-animation: rotate-scale-up 0.65s linear both;
        animation: rotate-scale-up 0.65s linear both;
    }


    /* ----------------------------------------------
 * Generated by Animista on 2020-10-14 14:53:36
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

    /**
     * ----------------------------------------
     * animation rotate-scale-up
     * ----------------------------------------
     */
    @-webkit-keyframes rotate-scale-up {
        0% {
            -webkit-transform: scale(1) rotateZ(0);
            transform: scale(1) rotateZ(0);
        }
        50% {
            -webkit-transform: scale(2) rotateZ(180deg);
            transform: scale(2) rotateZ(180deg);
        }
        100% {
            -webkit-transform: scale(1) rotateZ(360deg);
            transform: scale(1) rotateZ(360deg);
        }
    }
    @keyframes rotate-scale-up {
        0% {
            -webkit-transform: scale(1) rotateZ(0);
            transform: scale(1) rotateZ(0);
        }
        50% {
            -webkit-transform: scale(2) rotateZ(180deg);
            transform: scale(2) rotateZ(180deg);
        }
        100% {
            -webkit-transform: scale(1) rotateZ(360deg);
            transform: scale(1) rotateZ(360deg);
        }
    }



</style>
