var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        { staticClass: "mx-0" },
        [
          _c("v-col", { staticClass: "pa-0" }, [
            _c(
              "div",
              {
                staticClass: "whiteBg",
                staticStyle: {
                  height: "auto",
                  width: "90%",
                  "min-height": "80vh",
                  "border-radius": "15px",
                  "box-shadow": "1px 5px 5px silver",
                  margin: "auto auto 5em"
                }
              },
              [
                _c(
                  "div",
                  {
                    staticStyle: {
                      "background-color": "#6995CD",
                      "border-radius": "15px 15px 0 0",
                      "padding-top": "10px",
                      "padding-bottom": "10px",
                      color: "white"
                    }
                  },
                  [
                    _c(
                      "v-row",
                      {
                        staticClass: "pr-2 ml-4",
                        staticStyle: {
                          width: "90%",
                          display: "flex",
                          "align-items": "center"
                        }
                      },
                      [
                        _c(
                          "v-col",
                          {
                            staticClass: "pa-0 text-left d-flex align-center",
                            staticStyle: { "font-size": "xx-large" },
                            attrs: { cols: "9", md: "8", lg: "9" }
                          },
                          [
                            _c("img", {
                              staticClass: "iconToWhite",
                              staticStyle: { height: "30px" },
                              attrs: { src: _vm.stundenplanIcon }
                            }),
                            _c("p", { staticClass: "ma-0 pl-2" }, [
                              _vm._v("Stundenplan")
                            ])
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticStyle: { "border-radius": "15px" } },
                  [
                    _c(
                      "v-row",
                      {
                        staticStyle: { width: "99%", margin: "0 auto 0.5em" },
                        attrs: { cols: "12" }
                      },
                      [
                        _c(
                          "v-col",
                          {
                            staticClass: "text-center pb-0",
                            attrs: { cols: "2" }
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: _vm.uhrIcon,
                                width: "40",
                                height: "40"
                              }
                            })
                          ]
                        ),
                        _c(
                          "v-col",
                          {
                            staticClass: "text-center pb-0",
                            attrs: { cols: "10" }
                          },
                          [
                            _c(
                              "p",
                              {
                                staticStyle: {
                                  display: "inline-block",
                                  color: "#6995CD",
                                  "font-weight": "bold",
                                  "font-size": "x-large"
                                }
                              },
                              [_vm._v(" " + _vm._s(_vm.getDisplayDate()) + " ")]
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    _vm.showLoading
                      ? _c(
                          "div",
                          { staticClass: "d-flex justify-center" },
                          [
                            _c("v-progress-circular", {
                              attrs: {
                                color: "#6995CD",
                                indeterminate: "",
                                size: "100"
                              }
                            })
                          ],
                          1
                        )
                      : _c(
                          "div",
                          [
                            _vm.babySlots.length === 0
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "ma-3",
                                    staticStyle: {
                                      color: "#707070",
                                      "font-size": "large"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " Aktuell ist kein Unterricht. Du hast heute von "
                                    ),
                                    _c("strong", [
                                      _vm._v(
                                        _vm._s(
                                          (
                                            "0" +
                                            new Date(
                                              this.slots[0].start
                                            ).getHours()
                                          ).slice(-2) +
                                            ":" +
                                            (
                                              "0" +
                                              new Date(
                                                _vm.slots[0].start
                                              ).getMinutes()
                                            ).slice(-2)
                                        )
                                      )
                                    ]),
                                    _vm._v(" Uhr bis "),
                                    _c("strong", [
                                      _vm._v(
                                        _vm._s(
                                          (
                                            "0" +
                                            new Date(
                                              this.slots[
                                                this.slots.length - 1
                                              ].end
                                            ).getHours()
                                          ).slice(-2) +
                                            ":" +
                                            (
                                              "0" +
                                              new Date(
                                                _vm.slots[
                                                  this.slots.length - 1
                                                ].end
                                              ).getMinutes()
                                            ).slice(-2)
                                        )
                                      )
                                    ]),
                                    _vm._v(
                                      " Uhr Unterricht, der Unterricht hat entweder noch nicht begonnen oder ist schon vorbei. "
                                    )
                                  ]
                                )
                              : _vm._l(_vm.babySlots, function(item, index) {
                                  return _c(
                                    "v-row",
                                    {
                                      key: index,
                                      staticClass: "pr-2",
                                      attrs: { cols: "12", id: "fachRow" }
                                    },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "text-center pa-2",
                                          staticStyle: {
                                            display: "flex",
                                            "justify-content": "center",
                                            "align-items": "center",
                                            "font-weight": "normal",
                                            "font-size": "larger",
                                            color: "#707070"
                                          },
                                          attrs: { cols: "2" }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                (
                                                  "0" +
                                                  new Date(
                                                    item.start
                                                  ).getHours()
                                                ).slice(-2) +
                                                  ":" +
                                                  (
                                                    "0" +
                                                    new Date(
                                                      item.start
                                                    ).getMinutes()
                                                  ).slice(-2)
                                              ) +
                                              " "
                                          ),
                                          _c("br"),
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                (
                                                  "0" +
                                                  new Date(item.end).getHours()
                                                ).slice(-2) +
                                                  ":" +
                                                  (
                                                    "0" +
                                                    new Date(
                                                      item.end
                                                    ).getMinutes()
                                                  ).slice(-2)
                                              ) +
                                              " "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "pa-2",
                                          attrs: { cols: "10", id: "fachCol" }
                                        },
                                        [
                                          _vm.getAppointmentsToday(item._id)
                                            .length
                                            ? _c(
                                                "div",
                                                _vm._l(
                                                  _vm.getAppointmentsToday(
                                                    item._id
                                                  ),
                                                  function(el) {
                                                    return _c(
                                                      "div",
                                                      {
                                                        key: JSON.stringify(el)
                                                      },
                                                      [
                                                        _c("Fach", {
                                                          staticStyle: {
                                                            width: "100%"
                                                          },
                                                          attrs: {
                                                            "is-running": _vm.isTimeslotCurrentlyRunningToday(
                                                              item
                                                            ),
                                                            "has-run": _vm.hasTimeslotRunAndRoomIsStillOpen(
                                                              item,
                                                              index
                                                            ),
                                                            isOpen: el.open,
                                                            appointment: el,
                                                            requestAppointments:
                                                              _vm.requestAppointments,
                                                            "list-index": index
                                                          },
                                                          on: {
                                                            "pass-on-bbb-tut":
                                                              _vm.receivedEmit
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                0
                                              )
                                            : _c(
                                                "div",
                                                [
                                                  _c("Fach", {
                                                    staticStyle: {
                                                      width: "100%"
                                                    },
                                                    attrs: {
                                                      "is-running": _vm.isTimeslotCurrentlyRunningToday(
                                                        item
                                                      ),
                                                      requestAppointments:
                                                        _vm.requestAppointments,
                                                      "list-index": index
                                                    },
                                                    on: {
                                                      "pass-on-bbb-tut":
                                                        _vm.receivedEmit
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                })
                          ],
                          2
                        )
                  ],
                  1
                )
              ]
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }