import speak from "../assets/Tutorial/text-vorlesen.svg";
import bbb from "../assets/Tutorial/unterricht-beitreten.svg";
import foto from "../assets/Tutorial/Hausaufgaben-fotografieren.svg";
import ha from "../assets/Tutorial/Hausaufgaben-machen.svg";
import swb from "../assets/Tutorial/SchwarzesBrett-anpinnen.svg";
import speakclass from "../assets/Tutorial/Spreche-mit-deiner-Klasse.svg";
import oab from "../assets/Tutorial/online-Arbeitsblätter-einsehen.svg";

import bbbJoinRoom from '../assets/Tutorial/Online eKlassenraum beitreten.svg'
import bbbEchotest from '../assets/Tutorial/echotest.svg'
import bbbWriteOnline from '../assets/Tutorial/schreiben.svg'
import bbbTurnCamOn from '../assets/Tutorial/mikro-kamera an aus.svg'
import bbbPresent from '../assets/Tutorial/praesenteiren.svg'
import bbbPresentTeacher from '../assets/Tutorial/praesenteiren-lehrer.svg'
import bbbLeaveRoom from '../assets/Tutorial/ausloggen-bild-schueler.svg'
import bbbLeaveRoomTeacher from '../assets/Tutorial/konferenz beenden.svg'
import bbbBreakoutRooms from '../assets/Tutorial/breakout-rooms.svg'
import bbbMute from '../assets/Tutorial/stummschalten.svg'

import speaker from '../assets/Icons/lautsprecher-13.svg'
import menu from '../assets/Tutorial/einstellungen-neu-blau-103.svg'
import blueVideo from '../assets/Icons/kamera-video-weiss-72.svg'
import greyCams from '../assets/Icons/kamera.svg'
import greenPen from '../assets/Icons/gruener-stift.svg'
import greenOk from '../assets/Icons/grüner-haken.svg'
import pin from '../assets/Icons/pin-63.svg'
import micro from '../assets/Icons/mikrofon-22.svg'
import appointment from '../assets/Icons/f-mathe-weiss-32.svg'

import bbbIconThumbUp from '../assets/Icons/daumen-hoch.svg'
import bbbIconSend from '../assets/Icons/papierflieger.svg'
import bbbIconCamera from '../assets/Icons/blaue-kamera.svg'
import bbbIconMikro from '../assets/Icons/blaues-mikro.svg'
import bbbIconPlus from '../assets/Icons/blaues-plus.svg'
import bbbIconConfirm from '../assets/Icons/bestätigen.svg'
import bbbIconMore from '../assets/Icons/drei-punkte.svg'
import bbbLogout from '../assets/Icons/Ausloggen.svg'
import bbbLogoutTeacher from '../assets/Icons/konferenz-beenden.svg'
import bbbIconSettings from '../assets/Icons/zahnrad.svg'
import bbbIconMute from '../assets/Icons/mute.svg'
import bbbIconBreakout from '../assets/Icons/breakout.svg'
import bbbIconCreate from '../assets/Icons/erstellen.svg'

import teacherBBBImg from '../assets/Tutorial/unterricht-beitreten.svg';
import teacherShare from '../assets/Tutorial/Notiz-u-Material-hochladen.svg';
import teacherCorrectHomework from '../assets/Tutorial/Hausaufgaben-korrigieren.svg';

import teacherTopPen from '../assets/Icons/grauer-stift.svg';
import teacherUpload from '../assets/Icons/hochladen-weiss.svg';
import teacherGreenPen from '../assets/Icons/gruener-stift.svg';

const babyView = [
  {
    img: speak,
    header: 'Text vorlesen',
    text: 'Drücke und halte deinen Finger auf ein Wort, klicke dann auf <span style="font-weight: bold">das Lautsprechersymbol</span> ' +
        ' <img src="'+speaker+'" style="height: 1.5rem; padding: 3px; border-radius: 7px; margin-bottom: -0.375rem; box-shadow: 1px 2px 3px silver"/> zum Vorlesen.' +
        ' Du kannst die Sprache im <span style="color: #6995CD; font-weight: bold">Menü</span> <img src="'+menu+'" style="height: 1.5rem; padding: 3px; border-radius: 7px; margin-bottom: -0.375rem; box-shadow: 1px 2px 3px silver"/>' +
        ' und dann unter <span style="height: 1.5rem; padding: 5px 10px; border-radius: 7px; margin-bottom: -0.375rem; box-shadow: 1px 2px 3px silver">Sprache</span> ändern.',
  },
  {
    img: bbb,
    header: 'Unterricht beitreten',
    text: 'Drücke auf das <span style="color: #8cbd46; font-weight: bold">grüne Videosymbol</span> ' +
      '<img src="'+blueVideo+'" style="height: 1.5rem; background-color: #8cbd46; padding: 3px; border-radius: 7px; margin-bottom: -0.375rem; box-shadow: 1px 2px 3px silver"/> ' +
      'um deine Mitschüler und Lehrer zu sehen.',
  },
  {
    img: foto,
    header: 'Hausaufgaben fotografieren und hochladen',
    text: 'Berühre das <span style="color: #706f6f; font-weight: bold">graue Kamerasymbol</span> ' +
      '<img src="'+greyCams+'" style="height: 1.5rem; margin-bottom: -0.5rem; border-radius: 7px; box-shadow: 1px 2px 3px silver"/> , ' +
      'mache ein Foto von deiner Aufgabe und teile sie mit deinem Lehrer.',
  },
  {
    img: ha,
    header: 'Hausaufgaben machen',
    text: 'Drücke auf das <span style="color: #8cbd46">grüne Stiftsymbol</span> ' +
      '<img src="'+greenPen+'" style="height: 1.5rem; border-radius: 7px; margin-bottom: -0.375rem; box-shadow: 1px 2px 3px silver"/> , ' +
      'mache deine online Hausaufgaben ' +
      'und drücke dann auf den <span style="color: #8cbe46">grünen Fertigknopf</span> ' +
      '<img src="'+greenOk+'" style="height: 1.5rem; margin-bottom: -0.375rem"/> . ' +
      'Du kannst die Aufgaben entweder mit einem Tablet-Stift, per Hand oder mit einem Laptop bearbeiten.',
  },
];

const bbbPupil = [
  {
    img: bbbJoinRoom,
    header: 'Online eKlassenraum beitreten',
    text: 'Klicke links auf das Mikrofon ' +
      '<img src="'+micro+'" style="height: 1.5rem; padding: 3px; border-radius: 7px; margin-bottom: -0.375rem; box-shadow: 1px 2px 3px silver"/> ' +
      'um mit deinen Mitschülern und Lehrern sprechen zu können.\n' +
      'Später kannst du dein Mikrofon manuell ausschalten.',
  },
  {
    img: bbbEchotest,
    header: 'Echotest bestätigen',
    text: 'Wenn du dich gut hörst klicke auf den <span style="color: #008080">linken grünen Daumen</span> ' +
      '<img src="'+bbbIconThumbUp+'" style="height: 1.5rem; margin-bottom: -0.5rem"/> . ' +
      'Im Echotest hörst du dich so, wie dich später deine Mitschüler und Lehrer hören werden.',
  },
/*  {
    img: bbbWriteOnline,
    header: 'Schreibe im online eKlassenraum',
    text: 'Schreibe mit deinen Mitschülern und Lehrern im online Videoklassenraum. Klicke auf den ' +
      '<span style="color: #505FAA">blauen Papierflieger</span> ' +
      '<img src="'+bbbIconSend+'" style="height: 1.5rem; margin-bottom: -0.5rem"/> um deine Nachricht abzuschicken. ',
  },*/
  {
    img: bbbTurnCamOn,
    header: 'Mikrofon / Kamera an- und ausschalten',
    text: 'Klicke auf das <span style="color:#505FAA;">linke blaue Mikrofon</span> ' +
      '<img src="'+bbbIconMikro+'" style="height: 1.5rem; margin-bottom: -0.5rem "/> um dein Mikrofon auszuschalten, dann hören dich die anderen nicht mehr.' +
      ' Klicke auf die <span style="color:#505FAA;">rechte blaue Kamera</span> ' +
      '<img src="'+bbbIconCamera+'" style="height: 1.5rem; margin-bottom: -0.5rem "/> um deine Kamera auszuschalten, dann sehen dich die anderen nicht mehr.'
  },
  {
    img: bbbPresent,
    header: 'Präsentieren und den anderen Arbeitsblätter zeigen',
    text: 'Klicke links auf das <span style="color: #505FAA;">blaue Plus</span> ' +
      '<img src="'+bbbIconPlus+'" style="height: 1.5rem; margin-bottom: -0.5rem "/> ' +
      ', suche deine zu teilende Datei aus und klicke oben rechts auf den <span style="color: #505FAA;">blauen bestätigen Knopf</span> ' +
      '<img src="'+bbbIconConfirm+'" style="height: 1.5rem; margin-bottom: -0.5rem " /> . Danach kannst du deine Datei zeigen.',
  },
  {
    img: bbbLeaveRoom,
    header: 'Online eKlassenraum verlassen',
    text: 'Klicke auf die <span style="color: #BDBDBD">drei grauen Punkte</span> ' +
      '<img src="'+bbbIconMore+'" style="height: 1.5rem; margin-bottom: -0.5rem " /> oben rechts. Klicke dann auf ' +
      'Ausloggen <img src="'+bbbLogout+'" style="height: 1.5rem; margin-bottom: -0.5rem " /> um deinen online eKlassenraum ' +
      'zu verlassen.',
  },
];

const normalPupil = [
  {
    img: speak,
    header: 'Text vorlesen',
    text: 'Drücke und halte deinen Finger auf ein Wort, klicke dann auf <span style="font-weight: bold">das Lautsprechersymbol</span> ' +
        ' <img src="'+speaker+'" style="height: 1.5rem; padding: 3px; border-radius: 7px; margin-bottom: -0.375rem; box-shadow: 1px 2px 3px silver"/> zum Vorlesen.' +
        ' Du kannst die Sprache im <span style="color: #6995CD; font-weight: bold">Menü</span> <img src="'+menu+'" style="height: 1.5rem; padding: 3px; border-radius: 7px; margin-bottom: -0.375rem; box-shadow: 1px 2px 3px silver"/>' +
        ' und dann unter <span style="height: 1.5rem; padding: 5px 10px; border-radius: 7px; margin-bottom: -0.375rem; box-shadow: 1px 2px 3px silver">Sprache</span> ändern.',
  },
  {
    img: bbb,
    header: 'Unterricht beitreten',
    text: 'Drücke auf das <span style="color: #8cbd46">grüne Videosymbol</span> ' +
      '<img src="'+blueVideo+'" style="height: 1.5rem; background-color: #8cbd46; padding: 3px; border-radius: 7px; margin-bottom: -0.375rem; box-shadow: 1px 2px 3px silver"/> ' +
      'um deine Mitschüler und Lehrer zu sehen.',
  },
  {
    img: ha,
    header: 'Hausaufgaben machen',
    text: 'Drücke auf das <span style="color: #8cbd46">grüne Stiftsymbol</span> ' +
      '<img src="'+greenPen+'" style="height: 1.5rem; border-radius: 7px; margin-bottom: -0.375rem; box-shadow: 1px 2px 3px silver"/> , ' +
      'mache deine online Hausaufgaben ' +
      'und drücke dann auf den <span style="color: #8cbe46">grünen Fertigknopf</span> ' +
      '<img src="'+greenOk+'" style="height: 1.5rem; margin-bottom: -0.375rem"/> . ' +
      'Du kannst die Aufgaben entweder mit einem Tablet-Stift, per Hand oder mit einem Laptop bearbeiten.',
  },
  {
    img: swb,
    header: 'Mitteilungen (Schwarzes Brett) anpinnen',
    text: 'Hier werden die aktuellen Mitteilungen von deiner Schule angezeigt. Um eine Mitteilung immer oben zu fixieren, klicke auf den Pinknopf ' +
      '<img src="'+pin+'" style="height: 1.5rem; padding: 3px; border-radius: 7px; margin-bottom: -0.375rem; box-shadow: 1px 2px 3px silver"/> .',
  },
  {
    img: speakclass,
    header: 'Schreibe mit deiner Klasse',
    text: 'Klicke auf eine Gruppe und verschicke Nachrichten mit Smileys ' +
      '<span style="height: 1.5rem; padding: 3px; border-radius: 7px; margin-bottom: -0.375rem; box-shadow: 1px 2px 3px silver">😀</span> ' +
      'oder Sprachnachrichten ' +
      '<img src="'+micro+'" style="height: 1.5rem; padding: 3px; border-radius: 7px; margin-bottom: -0.375rem; box-shadow: 1px 2px 3px silver"/> .',
  },
  {
    img: oab,
    header: 'Online Arbeitsblätter einsehen',
    text: 'Klicke auf ein Fach ' +
      '<img src="'+appointment+'" style="height: 1.5rem; background-color: #6DC3DC; padding: 3px; border-radius: 7px; margin-bottom: -0.375rem; box-shadow: 1px 2px 3px silver"/> , ' +
      'um die jeweiligen Dateien einzusehen. ' +
      'Deine Arbeitsblätter werden online abgespeichert. ' +
      'Du kannst sie auch von hier bearbeiten und herunterladen.',
  },
];

const bbbTeacher = [
  {
    img: bbbJoinRoom,
    header: 'Online eKlassenraum beitreten',
    text: 'Klicken Sie links auf das Mikrofon ' +
      '<img src="'+micro+'" style="height: 1.5rem; padding: 3px; border-radius: 7px; margin-bottom: -0.375rem; box-shadow: 1px 2px 3px silver"/> ' +
      'um mit Ihren Schülern sprechen zu können.\n' +
      'Später können Sie Ihr Mikrofon manuell ausschalten.',
  },
  {
    img: bbbEchotest,
    header: 'Echotest bestätigen',
    text: 'Wenn Sie sich gut hören klicken Sie auf den <span style="color: #008080">linken grünen Daumen</span> ' +
      '<img src="'+bbbIconThumbUp+'" style="height: 1.5rem; margin-bottom: -0.5rem"/>. ' +
      'Im Echotest hören Sie sich so, wie Sie später Ihre Schüler hören werden.',
  },
  {
    img: bbbWriteOnline,
    header: 'Schreiben im online eKlassenraum',
    text: 'Schreiben Sie mit Ihren Schülern im online Videoklassenraum. Klicken Sie auf den ' +
      '<span style="color: #505FAA">blauen Papierflieger</span> ' +
      '<img src="'+bbbIconSend+'" style="height: 1.5rem; margin-bottom: -0.5rem"/> um eine Nachricht abzuschicken. ',
  },
  {
    img: bbbTurnCamOn,
    header: 'Mikrofon / Kamera an- und ausschalten',
    text: 'Klicken Sie auf das <span style="color:#505FAA;">linke blaue Mikrofon</span> ' +
      '<img src="'+bbbIconMikro+'" style="height: 1.5rem; margin-bottom: -0.5rem"/> um Ihr Mikrofon auszuschalten, dann hört Sie niemand mehr.' +
      ' Klicken Sie auf die <span style="color:#505FAA;">rechte blaue Kamera</span> ' +
      '<img src="'+bbbIconCamera+'" style="height: 1.5rem; margin-bottom: -0.5rem"/> um Ihre Kamera auszuschalten, dann sieht Sie niemand mehr.'
  },
  {
    img: bbbPresentTeacher,
    header: 'Präsentieren und Arbeitsblätter zeigen',
    text: 'Klicken Sie links auf das <span style="color: #505FAA;">blaue Plus</span> ' +
      '<img src="'+bbbIconPlus+'" style="height: 1.5rem; margin-bottom: -0.5rem"/> ' +
      ', suchen Sie Ihre zu teilende Datei aus und klicken Sie oben rechts auf den <span style="color: #505FAA;">blauen bestätigen Knopf</span> ' +
      '<img src="'+bbbIconConfirm+'" style="height: 1.5rem; margin-bottom: -0.5rem"/> . Danach können Sie Ihre Datei zeigen und bearbeiten.',
  },
  {
    img: bbbMute,
    header: 'Schüler stummschalten',
    text: 'Klicken Sie entweder auf das Zahnradsymbol ' +
      '<img src="'+bbbIconSettings+'" style="height: 1.5rem; margin-bottom: -0.5rem "/> ' +
      'um alle Teilnehmer stummzuschalten ' +
      '<img src="'+bbbIconMute+'" style="height: 1.5rem; margin-bottom: -0.5rem " /> ' +
      'oder klicken Sie auf einen einzelnen Teilnehmer um nur diese Person stummzuschalten.',
  },
  {
    img: bbbBreakoutRooms,
    header: 'Einzel- und Gruppenarbeitsräume erstellen',
    text: 'Klicken Sie links in der Leiste auf das Zahnrad ' +
      '<img src="'+bbbIconSettings+'" style="height: 1.5rem; margin-bottom: -0.5rem "/> ' +
      ', und dann auf Breakout-Räume <img src="'+bbbIconBreakout+'" style="height: 1.5rem; margin-bottom: -0.5rem"/> erstellen.' +
      ' Weisen Sie per Drag&Drop (klicken, halten und ziehen) die Einzelschüler den Räumen zu und bestätigen Sie mit' +
      ' Erstellen <img src="'+bbbIconCreate+'" style="height: 1.5rem; margin-bottom: -0.5rem"/>.',
  },
  {
    img: bbbLeaveRoomTeacher,
    header: 'Die online eKlassenraum Konferenz beenden',
    text: 'Klicken Sie auf die <span style="color: #BDBDBD">drei grauen Punkte</span> ' +
      '<img src="'+bbbIconMore+'" style="height: 1.5rem; margin-bottom: -0.5rem"/> oben rechts um Ihren online eKlassenraum ' +
      'zu verlassen. Ihre Schüler werden durch Konferenz beenden <img src="'+bbbLogoutTeacher+'" style="height: 1.5rem; margin-bottom: -0.5rem"/> ' +
      'automatisch aus dem eKlassenraum ausgeloggt.',
  },
];

const normalTeacher = [
  {
    img: teacherBBBImg,
    header: 'Unterricht beitreten',
    text: 'Klicken Sie auf das <span style="color: #8cbd46; font-weight: bold">grüne Videosymbol</span>  ' +
      '<img src="'+blueVideo+'" style="height: 1.5rem; background-color: #8cbd46; padding: 3px; border-radius: 7px; margin-bottom: -0.375rem; box-shadow: 1px 2px 3px silver"/> ,' +
      ' um Ihre Schüler zu sehen und den digitalen Unterricht abzuhalten.',
  },
  {
    img: teacherShare,
    header: 'Mit Schülern Notizen und Aufgaben teilen',
    text: 'Klicken Sie auf das obere Stiftsymbol ' +
      '<img src="'+teacherTopPen+'" style="height: 1.5rem; border-radius: 7px; margin-bottom: -0.375rem; box-shadow: 1px 2px 3px silver"/> ,' +
      ' um Schülern eine Notiz zu hinterlassen. Material (Bilder, PDFs, Videos, Audiodateien) für Ihre Schüler können Sie über das Dateihochladensymbol ' +
      '<img src="'+teacherUpload+'" style="height: 1.5rem; background-color: #707070; padding: 5px; border-radius: 7px; margin-bottom: -0.375rem; box-shadow: 1px 2px 3px silver"/> ' +
      'bereitstellen.',
  },
  {
    img: teacherCorrectHomework,
    header: 'Hausaufgaben korrigieren',
    text: 'Klicken Sie auf das <span style="color: #8cbd46; font-weight: bold">grüne Stiftsymbol</span> ' +
      '<img src="'+teacherGreenPen+'" style="height: 1.5rem; border-radius: 7px; margin-bottom: -0.375rem; box-shadow: 1px 2px 3px silver"/> ,' +
      ' um abgegebene Hausaufgaben direkt online zu bearbeiten. Sie können die Aufgaben mit einem Tablet-Stift, per Hand oder mit einem' +
      ' Laptop bearbeiten.',
  },
  {
    img: swb,
    header: 'Mitteilungen (Schwarzes Brett) anpinnen',
    text: 'Hier werden die aktuellen Mitteilungen von Ihrer Schule angezeigt. Um eine Mitteilung oben zu fixieren ' +
      ', klicken Sie auf den Pin ' +
      '<img src="'+pin+'" style="height: 1.5rem; padding: 3px; border-radius: 7px; margin-bottom: -0.375rem; box-shadow: 1px 2px 3px silver"/> .',
  },
  {
    img: speakclass,
    header: 'Chat-Nachrichten im eKlassenraum verschicken',
    text: 'Klicken Sie auf eine Gruppe und verschicken Sie an alle Schüler Nachrichten mit Smileys ' +
      '<span style="height: 1.5rem; padding: 3px; border-radius: 7px; margin-bottom: -0.375rem; box-shadow: 1px 2px 3px silver">😀</span> ' +
      ', Text oder Sprachnachrichten ' +
      '<img src="'+micro+'" style="height: 1.5rem; padding: 3px; border-radius: 7px; margin-bottom: -0.375rem; box-shadow: 1px 2px 3px silver"/> .',
  },
  {
    img: oab,
    header: 'Online Arbeitsblätter einsehen',
    text: 'Klicken Sie auf ein Fach ' +
      '<img src="'+appointment+'" style="height: 1.5rem; background-color: #6DC3DC; padding: 3px; border-radius: 7px; margin-bottom: -0.375rem; box-shadow: 1px 2px 3px silver"/> , ' +
      'um die jeweiligen Dateien einzusehen. Ihre Arbeitsblätter und die Ihrer Schüler werden online abgespeichert. ' +
      'Sie können diese auch von hier bearbeiten und herunterladen.',
  },
];

export default {
  babyView,
  bbbPupil,
  normalPupil,
  normalTeacher,
  bbbTeacher
};

