<template>
    <v-app-bar flat style="background-color: transparent; height: 130px" class="navBar pa-0">
        <v-img src="./../assets/Bilder/eStundenplan_Logo.png" class="navImg"/>
        <v-spacer></v-spacer>
        <div v-if="this.$vuetify.breakpoint.mdAndUp">
            <a href="#stundenplan" style="color: #707070;" class="navLink">WAS IST MEIN eSTUNDENPLAN</a>
            <a href="#funktionen" style="color: #707070;" class="navLink">FUNKTIONEN</a>
            <a href="#interesse" style="color: #707070;" class="navLink spaceRight">INTERESSE?</a>
        </div>

        <v-menu v-else offset-y close-on-click close-on-content-click tile>
            <template v-slot:activator="{ on }">
                <v-btn v-on="on" elevation="0"><v-icon style="color: #707070;">mdi-menu</v-icon></v-btn>
            </template>

            <v-list>
                <v-list-item>
                    <a href="#stundenplan" style="color: #707070; font-size: 16px">WAS IST MEIN eSTUNDENPLAN</a>
                </v-list-item>
                <v-list-item>
                    <a href="#funktionen" style="color: #707070; font-size: 16px">FUNKTIONEN</a>
                </v-list-item>
                <v-list-item>
                    <a href="#interesse" style="color: #707070; font-size: 16px">INTERESSE?</a>
                </v-list-item>
            </v-list>
        </v-menu>
    </v-app-bar>
</template>

<script>
export default {
    name: "Navbar"
}
</script>

<style lang="scss" scoped>
a {
    text-decoration: none;
    margin-left: 16px;
    font-size: 20px;
}

a:hover {
    text-decoration: underline;
}

.navImg {
    max-width: 300px;
    left: 80px;
    top: 40px;
}

.navLink {
    margin-left: 64px;
}

.spaceRight {
    margin-right: 400px;
}

@media (max-width: 15000px) {
    .spaceRight {
        margin-right: 0;
    }
}

@media (max-width: 960px) {
    .navImg {
        left: 2%;
        top: 10px;
        max-width: 150px;
    }

    .navLink {
        margin-left: 16px;
    }

    .spaceRight {
        margin-right: 20px;
    }
}

@media (max-width: 600px) {
    a {
        margin: 0;
    }

    .navBar {
        height: 65px;
    }

    .navImg {
        left: 2%;
        top: 10px;
        max-width: 150px;
    }
}

</style>