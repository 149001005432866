<template>
    <div>
        <Header style="position: fixed; width: 100%; z-index: 100;">

        </Header>
        <keep-alive>
            <router-view style="width: calc(100% - 56px); margin-left: 56px; margin-top: 100px"></router-view>
        </keep-alive>
    </div>
</template>

<script>
    import { mapState, mapMutations, mapActions } from "vuex";
    import Header from "../components/Verwaltung/Header";
    export default {
        name: 'Main',
        components: {
            Header,

        },
        data() {
            return {
            };
        },
        computed: {
            ...mapState("auth", ["account"]),
        },
        methods: {
            ...mapActions("auth", ["getAccount"]),
        },
        watch: {
            account(newValue) {
                switch (newValue.role) {
                    case 'maintainer':
                        if(!this.$route.name.includes('verwaltung')) {
                            this.$router.replace({ name: 'verwaltung', query: this.$route.query }).catch(err => {});
                        }

                        break;
                    case 'pupil':
                        if(!this.$route.name.includes('schueler')) {
                            this.$router.replace({ name: 'schueler', query: this.$route.query}).catch(err => {});
                        }
                        break;
                    case 'teacher':
                        if(!this.$route.name.includes('lehrer')) {
                            this.$router.replace({ name: 'lehrer', query: this.$route.query}).catch(err => {});
                        }
                        break;
                    default:
                        this.$router.replace({ name: 'main', query: this.$route.query }).catch(err => {});
                        break;
                }
            },
        },
        mounted() {
            this.getAccount();
            switch (this.account.role) {
                case 'maintainer':
                    if(!this.$route.name.includes('verwaltung')) {
                        this.$router.replace({ name: 'verwaltung', query: this.$route.query}).catch(err => {});
                    }

                    break;
                case 'pupil':
                    if(!this.$route.name.includes('schueler')) {
                        this.$router.replace({ name: 'schueler', query: this.$route.query}).catch(err => {});
                    }
                    break;
                case 'teacher':
                    if(!this.$route.name.includes('lehrer')) {
                        this.$router.replace({ name: 'lehrer', query: this.$route.query}).catch(err => {});
                    }
                    break;
                default:
                    this.$router.replace({ name: 'main', query: this.$route.query }).catch(err => {});
                    break;
            }
        }
    }
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
    @import url("https://fonts.googleapis.com/css?family=Indie+Flower:400,500,700,900&display=swap");

    .flowerIndie {
        font-family: "Indie Flower", serif !important;
    }

</style>
