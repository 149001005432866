<template>
    <div v-if="tutorialState" style="background-color: #f8f8f8">

        <!--bottom navigation bar, used only for mobile devices-->
        <div class="btmNav">
            <div class="d-flex flex-row justify-space-around align-center" style="height: 100%; width: 100%">

                <v-btn v-for="item in btmNavItems" :key="item.name" @click="switchSlide(item.goToIndex)" elevation="0" small class="btmNavBtn pa-0" :style="currSlide === item.goToIndex && !btmNavMenu ? 'background-color:' + item.bgColor + '!important;' : ''">
                    <img :src="item.icon" class="btmNavIcon" :style="currSlide === item.goToIndex && !btmNavMenu ? 'filter: brightness(1000%);' : item.color">
                </v-btn>

                <!--menu activator-->
                <v-btn @click="handleBtmMenuClick" ref="btmMenuBtn" elevation="0" small class="btmNavBtn pa-0" :style="btmNavMenu ? 'background-color: #6995CD !important;' : ''">
                    <img :src="menuIcon" class="btmNavIcon" :style="btmNavMenu ? 'filter: brightness(1000%);' : 'filter: brightness(0) saturate(100%) invert(56%) sepia(46%) saturate(448%) hue-rotate(174deg) brightness(93%) contrast(86%);'">
                </v-btn>

                <!--menu content-->
                <div v-closable="{exclude: ['btmMenuBtn', 'btmMenu'], handler: 'onClose'}" ref="btmMenu" class="d-flex flex-column pa-2 btmNavMenu" :class="btmNavMenu ? '' : 'hideMe'" :style="btmNavLangMenu ? 'box-shadow: -5px -2px 4px silver;' : 'border-top-left-radius: 12px;'">

                    <div class="d-flex pa-2 mb-2">
                        <img :src="usedProfilePic" height="25" width="25" style="border-radius: 13px; box-shadow: 1px 5px 5px silver; object-fit: cover;"/>
                        <p class="ml-2 ma-0 ellipsis greyText">{{ person ? person.name : 'Name' }}</p>
                    </div>

                    <join-lehrerzimmer-button />

                    <div v-ripple @click="reloadPage(); closeBtmNavMenus" class="d-flex mb-2 pa-2 btmNavMenuBtn">
                        <img :src="aktualisierenIcon" height="25"/>
                        <p class="ml-2 ma-0">Aktualisieren</p>
                    </div>

                    <div v-ripple @click="resetTutorial(); closeBtmNavMenus" class="d-flex mb-2 pa-2 btmNavMenuBtn">
                        <img :src="fragezeichenIcon" height="25"/>
                        <p class="ml-2 ma-0">Tutorial</p>
                    </div>

                    <div v-ripple @click="clickLogoutUser(); closeBtmNavMenus" class="d-flex pa-2 btmNavMenuBtn">
                        <img :src="logoutIcon" height="25"/>
                        <p class="ml-2 ma-0">Abmelden</p>
                    </div>
                </div>
            </div>
        </div>

        <!--top menu bar-->
        <v-row style="background-color: #EEEEEE;" class="displayHide">
            <v-col class="d-flex align-center" style="margin-left: 5%" cols="4">
                <img :src="logo" height="50px">
            </v-col>

            <!--name and options-->
            <v-col class="d-flex justify-end align-center" style="margin-right: 5%">
                <img v-if="person" :src="usedProfilePic" height="35" width="35" style="border-radius: 50%"/>
                <p class="ma-0 ml-2 ellipsis greyText">{{person ? person.name.slice(0,1) + '. ' + person.lastName : 'Name'}}</p>

                <!-- Lehrerzimmer Button -->
                <join-lehrerzimmer-button />

                <v-btn
                    @click="reloadPage()"
                    style="background-color: #FFFFFF; width: 50px; height: 50px; border-radius: 8px;"
                    x-small elevation="0"
                    class="ml-4 animateIcon"
                >
                    <img :src="aktualisierenIcon" style="max-width: 25px; max-height: 25px;"/>
                </v-btn>

                <!--menu activator-->
                <v-btn @click="handleTopMenuClick" ref="topMenuBtn" elevation="0" small class="btmNavBtn pa-0 ml-4" :style="topMenu ? 'background-color: #6995CD !important;' : ''">
                    <img :src="menuIcon" style="max-width: 25px; max-height: 25px;" :style="topMenu ? 'filter: brightness(1000%);' : 'filter: brightness(0) saturate(100%) invert(56%) sepia(46%) saturate(448%) hue-rotate(174deg) brightness(93%) contrast(86%);'">
                </v-btn>

                <!--menu content-->
                <div v-closable="{exclude: ['topMenuBtn'], handler: 'onCloseTop'}" style="z-index: 2;" class="d-flex flex-column pa-2 topMenu" :class="topMenu ? '' : 'hideMe'">
                    <div v-ripple @click="resetTutorial(); closeTopMenus" class="d-flex mb-2 pa-2 btmNavMenuBtn pointer">
                        <img :src="fragezeichenIcon" height="25"/>
                        <p class="ml-2 ma-0">Tutorial</p>
                    </div>

                    <div v-ripple @click="clickLogoutUser(); closeTopMenus" class="d-flex pa-2 btmNavMenuBtn pointer">
                        <img :src="logoutIcon" height="25"/>
                        <p class="ml-2 ma-0">Abmelden</p>
                    </div>
                </div>
            </v-col>
        </v-row>

        <div class="big" v-if="windowWidth > 900">
            <v-btn fab small color="#eaeaea" style="margin-left: 5px; margin-top: 20%" @click="page= Math.abs((page-1) %2)" class="arrowBtn">
                <img style="position: relative; margin:auto; display: block; width: 20px" :src="left">
            </v-btn>
            <v-row>
                <v-col>
                    <PlanWidget v-if="page === 0" style="height: 100vh" @start-bbb-tut="startBBBTut"></PlanWidget>
                    <FileWidget v-if="page === 1" style="height: 100vh"></FileWidget>
                </v-col>

                <v-col>
                    <BrettWidget v-if="page === 0" style="height: 100vh"></BrettWidget>
                </v-col>
                <v-col>
                    <chat-widget v-if="page === 0"></chat-widget>
                </v-col>
            </v-row>
            <v-btn fab small color="#eaeaea" style="margin-right: 5px; margin-top: 20%" @click="page= Math.abs((page+1) %2)" class="arrowBtn">
                <img style="position: relative; margin:auto; display: block; width: 20px" :src="right">
            </v-btn>
        </div>

        <VueSlickCarousel v-else class="small topPadding" :arrows="false" :initialSlide="0" :infinite="false" :dots="false" ref="carousel" @afterChange="slideChangeAllowed = true; setCurrSlide($refs.carousel.$refs.innerSlider.currentSlide)">
            <PlanWidget :small="true" @start-bbb-tut="startBBBTut"></PlanWidget>
            <BrettWidget style="height: 100vh"></BrettWidget>
            <chat-widget></chat-widget>
            <FileWidget style="height: 100vh"></FileWidget>
        </VueSlickCarousel>
    </div>

    <Tutorial v-else :baby-view="false" :teacher-view="teacherView" :teacher-b-b-b="teacherBBB" @bbb-tut-done="openBBB" :checkTutorialState="checkTutorialState"> </Tutorial>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";

// import TTS from "../TextToSpeech";
import PlanWidget from "@/components/Lehrer/PlanWidgetLehrer";
import BrettWidget from "@/components/BrettWidget";
import FileWidget from "@/components/FileWidget";
import ChatWidget from "../Demo/ChatWidget";
import Tutorial from "../Tutorial/Tutorial";
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

import uhrIcon from '../../assets/Icons/uhr-04.svg'
import teacherIcon from '../../assets/Icons/lehrer-24.svg'
import logoutIcon from '../../assets/Icons/beitretensvg-06.svg'
import left from '../../assets/Icons/links-10.svg'
import right from '../../assets/Icons/rechts-11.svg'
import manPic from "@/assets/Icons/Mann-85.svg";
import womanPic from "@/assets/Icons/Frau-84.svg";
import diversPic from "@/assets/Icons/Divers-87.svg";
import stundenplanIcon from "@/assets/Icons/stundenplan-neu-neu-99.svg";
import brettIcon from "@/assets/Icons/sw-brett-neu-100.svg";
import chatIcon from "@/assets/Icons/chat-alternative-102.svg";
import dateienIcon from "@/assets/Icons/leere-korrektur-78.svg";
import menuIcon from "@/assets/Icons/einstellungen-neu-103.svg";
import fragezeichenIcon from "@/assets/Icons/Fragezeichen-83.svg";
import abbrechenIcon from "@/assets/Icons/abbrechen-08.svg";
import aktualisierenIcon from "@/assets/Icons/aktualisieren-107.svg";
import logo from "@/assets/logo_klassenraum.png";

import Vue from 'vue'
import JoinLehrerzimmerButton from "./JoinLehrerzimmerButton";

// This variable will hold the reference to
// document's click handler
let handleOutsideClick

//directive from https://tahazsh.com/detect-outside-click-in-vue with the modification from Peter Means (in comments)
Vue.directive('closable', {
    bind (el, binding, vnode) {
        // Here's the click/touchstart handler
        // (it is registered below)
        handleOutsideClick = (e) => {
            e.stopPropagation()
            // Get the handler method name and the exclude array
            // from the object used in v-closable
            const { handler, exclude } = binding.value

            // This variable indicates if the clicked element is excluded
            let clickedOnExcludedEl = false
            exclude.forEach(refName => {
                // We only run this code if we haven't detected
                // any excluded element yet
                if (!clickedOnExcludedEl) {
                    // Get the element using the reference name
                    const excludedEl = vnode.context.$refs[refName];
                    let excludedDomEl = null;
                    if (excludedEl) {
                        // If it's a vue component grab the element, otherwise it is the element
                        excludedDomEl = excludedEl.$el ? excludedEl.$el : excludedEl;
                        clickedOnExcludedEl = excludedDomEl.contains(e.target);
                    }
                }
            })
            // We check to see if the clicked element is not
            // the dialog element and not excluded
            if (!el.contains(e.target) && !clickedOnExcludedEl) {
                // If the clicked element is outside the dialog
                // and not the button, then call the outside-click handler
                // from the same component this directive is used in
                vnode.context[handler]()
            }
        }
        // Register click/touchstart event listeners on the whole page
        document.addEventListener('click', handleOutsideClick)
        document.addEventListener('touchstart', handleOutsideClick)
    },

    unbind () {
        // If the element that has v-closable is removed, then
        // unbind click/touchstart listeners from the whole page
        document.removeEventListener('click', handleOutsideClick)
        document.removeEventListener('touchstart', handleOutsideClick)
    }
})

export default {
    name: "Plan",
    components: {
        JoinLehrerzimmerButton,
        ChatWidget,
        BrettWidget,
        PlanWidget,
        FileWidget,
        VueSlickCarousel,
        Tutorial
    },
    data() {
        return {
            windowWidth: window.innerWidth,
            menu: false,
            topMenu: false,
            btmNavMenu: false,
            btmNavLangMenu: false,
            date: new Date(),
            appointments: [],
            person: null,
            slots: [
            ],
            page: 0,

            uhrIcon,
            teacherIcon,
            manPic,
            womanPic,
            diversPic,
            left,
            right,
            logoutIcon,
            menuIcon,
            fragezeichenIcon,
            abbrechenIcon,
            aktualisierenIcon,
            logo,

            usedProfilePic: womanPic,

            profilePictureUrl: "",
            tutorialState: false,
            teacherView: true,
            teacherBBB: false,
            bbbTutState: null,

            callbackMethod: null,   //joinBBB method passed up from Fach-component

            currSlide: 0,               //to color icons of mobile bottom navBar
            slideChangeAllowed: true,   //to prevent spamming of btmNavBar, which would bring highlighting of btmNavIcons and currently displayed widget out of sync

            btmNavItems: [
                { name: 'stundenplan', icon: stundenplanIcon, goToIndex: 0, bgColor: '#6995CD',color: 'filter: brightness(0) saturate(100%) invert(56%) sepia(46%) saturate(448%) hue-rotate(174deg) brightness(93%) contrast(86%);' },
                { name: 'brett', icon: brettIcon, goToIndex: 1, bgColor: '#707070', color: 'filter: brightness(0) saturate(100%) invert(47%) sepia(0%) saturate(98%) hue-rotate(150deg) brightness(90%) contrast(86%);' },
                { name: 'chat', icon: chatIcon, goToIndex: 2, bgColor: '#8CBD46', color: 'filter: brightness(0) saturate(100%) invert(70%) sepia(22%) saturate(1027%) hue-rotate(43deg) brightness(94%) contrast(90%);' },
                { name: 'dateien', icon: dateienIcon, goToIndex: 3, bgColor: '#F49F31', color: 'filter: brightness(0) saturate(100%) invert(67%) sepia(71%) saturate(1286%) hue-rotate(338deg) brightness(104%) contrast(91%);' },
            ],
        };
    },
    props: {

    },
    watch: {
      async person(newVal) {
          if (this.person.profilePicture) {
              this.usedProfilePic = await this.getTeacherPic();
          } else {
              this.usedProfilePic = this.getPersonPic();
          }
      }
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onResize);
    },
    async created() {
        this.checkTutorialState();
    },
    async mounted() {
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);

            window.addEventListener('orientationchange', this.onOrientationChange);
        });
        await this.requestSlots();
        await this.requestAppointments();
        await this.requestPerson();
    },
    computed: {
    },
    methods: {
        ...mapActions("appointments", ["getAppointments"]),
        ...mapActions("timeslots", ["getSlots"]),
        ...mapActions("teachers", ["getMe", "getProfilePicture"]),
        ...mapActions("auth", ["logoutUser", "reloadDontLogout"]),

        startBBBTut(callback) {
            this.tutorialState = false;

            this.bbbTutState = JSON.parse(localStorage.getItem('bbbTut'));
            this.teacherView = false;
            this.teacherBBB = true;
            this.callbackMethod = callback;
        },

        openBBB() {
            this.callbackMethod();
        },

        onResize() {
            this.windowWidth = window.innerWidth;
        },
        async requestAppointments() {
            this.appointments = await this.getAppointments();
        },

        resetTutorial() {
            this.tutorialState = false;
            localStorage.setItem('tutorial', 'false');
        },
        checkTutorialState() {
            this.tutorialState = JSON.parse(localStorage.getItem('tutorial'));
        },
        async requestSlots() {
            this.slots = await this.getSlots();
        },

        async requestPerson() {
            this.person = await this.getMe();
        },

        //if there is an uploaded profile picture for teacher
        async getTeacherPic() {
            let profilePictureBlob = await this.getProfilePicture(this.person._id);
            const objectURL = URL.createObjectURL(profilePictureBlob);

            console.log(objectURL);

            this.profilePictureUrl = objectURL;
            this.person.bild = objectURL;

            return objectURL;
        },

        //else get default profile picture by gender
        getPersonPic() {
            try {
                let gender = this.person.gender;
                switch (gender) {
                    case "m": return manPic;
                    case "w": return womanPic;
                    case "d": return diversPic;
                }
            } catch (e) {
                console.log(e);
            }
        },

        clickLogoutUser() {
            this.logoutUser();
        },

        ISO8601_week_no(dt) {
            let tdt = new Date(dt.valueOf());
            let dayn = (dt.getDay() + 6) % 7;
            tdt.setDate(tdt.getDate() - dayn + 3);
            let firstThursday = tdt.valueOf();
            tdt.setMonth(0, 1);
            if (tdt.getDay() !== 4)
            {
                tdt.setMonth(0, 1 + ((4 - tdt.getDay()) + 7) % 7);
            }
            return 1 + Math.ceil((firstThursday - tdt) / 604800000);
        },

        addWeek() {
            this.date = new Date(this.date .getFullYear(), this.date .getMonth(), this.date .getDate()+7);
        },
        subWeek() {
            this.date = new Date(this.date .getFullYear(), this.date .getMonth(), this.date .getDate()-7);
        },
        getMonday(d) {
            d = new Date(d);
            let day = d.getDay(),
                diff = d.getDate() - day + (day == 0 ? -6:1); // adjust when day is sunday
            return new Date(d.setDate(diff));
        },
        getTuesday(d) {
            d = new Date(d);
            let day = d.getDay(),
                diff = d.getDate() - day + (day == 0 ? -5:2); // adjust when day is sunday
            return new Date(d.setDate(diff));
        },
        getWednesday(d) {
            d = new Date(d);
            let day = d.getDay(),
                diff = d.getDate() - day + (day == 0 ? -4:3); // adjust when day is sunday
            return new Date(d.setDate(diff));
        },
        getThursday(d) {
            d = new Date(d);
            let day = d.getDay(),
                diff = d.getDate() - day + (day == 0 ? -3:4); // adjust when day is sunday
            return new Date(d.setDate(diff));
        },
        getFriday(d) {
            d = new Date(d);
            let day = d.getDay(),
                diff = d.getDate() - day + (day == 0 ? -2:5); // adjust when day is sunday
            return new Date(d.setDate(diff));
        },

        getAppointment(slot, day) {
            switch (day) {
                case 'mon':
                    day = this.getMonday(this.date);
                    break;
                case 'die':
                    day = this.getTuesday(this.date);
                    break;
                case 'mit':
                    day = this.getWednesday(this.date);
                    break;
                case 'don':
                    day = this.getThursday(this.date);
                    break;
                case 'fre':
                    day = this.getFriday(this.date);
                    break;
            }
            return this.appointments.find((el) => { return (el.timeslot === slot && this.sameDay(new Date(el.day), day))})
        },

        sameDay(first, second) {
            return   first.getFullYear() === second.getFullYear() &&
                first.getMonth() === second.getMonth() &&
                first.getDate() === second.getDate();
        },

        reloadPage() {
            this.reloadDontLogout(true);
            this.$router.go();
        },

        /*btmNav methods*/
        switchSlide(index) {
            if(this.slideChangeAllowed && index !== this.currSlide) {
                if (this.babyView) {
                    this.$refs.carouselBabyView.goTo(index);
                } else {
                    this.$refs.carousel.goTo(index);
                }
                this.currSlide = index;
                this.slideChangeAllowed = false;
                this.closeBtmNavMenus();
            }
            /*if btmNavMenu is open and you click on the button for the widget which is already being displayed*/
            else if(this.slideChangeAllowed && this.btmNavMenu) {
                this.btmNavMenu = false; //then just close the btmNavMenu
            }
        },
        setCurrSlide(index) {
            this.currSlide = index;
        },
        closeBtmNavMenus() {
            this.btmNavLangMenu = false;
            this.btmNavMenu = false;
        },
        handleBtmMenuClick() {
            this.btmNavMenu = !this.btmNavMenu;
            this.btmNavLangMenu = false;
        },
        onClose() {
            this.closeBtmNavMenus();
        },
        onOrientationChange() {
            this.switchSlide(0);
            this.slideChangeAllowed = true;
        },

        /*topMenu methods*/
        handleTopMenuClick() {
            this.topMenu = !this.topMenu;
        },
        onCloseTop() {
            this.closeTopMenus();
        },
        closeTopMenus() {
            this.topMenu = false;
        },
    },
};
</script>

<style lang="scss" scoped>
    table, th, td {
        border: 1px solid black;
        border-collapse: collapse;
    }

    tr {
        height: 40px;
    }

    table tr th:first-child,
    table tr td:first-child {
        width: 10px !important;
    }

    table tr th,
    table tr td {
        width: 9em;
        border: none;
    }
    table, tr, td {
        border: none;
    }

    .fach {
        cursor: pointer;
    }

    .small {
        display: none;
    }

    .big {
        display: flex;
    }

    .ellipsis {
        text-overflow: ellipsis;

        /* Required for text-overflow to do anything */
        white-space: nowrap;
        overflow: hidden;
    }

    .greyText {
        color: #707070;
    }

    .btmNav {
        display: none;
        z-index: 10;
        position: fixed;
        bottom: 0;
        width: 100% !important;
        height: 70px !important;
        background-color: #EEEEEE;
    }

    .btmNavBtn {
        height: 50px !important;
        width: 50px !important;
        background-color: white !important;
        border-radius: 8px !important;
    }

    /*removes darker background after btn is clicked
    also works without the specific class in front*/
    .btmNavBtn.v-btn:before {
        opacity: 0 !important;
    }
    .arrowBtn:before {
        opacity: 0 !important;
    }

    .btmNavIcon {
        height: 35px;
    }

    .hideMe {
        display: none !important;
    }

    .topMenu {
        position: fixed;
        top: 74px;
        right: 5%;
        width: 160px;
        max-width: 160px;
        background-color: #FFFFFF;
        border-bottom-left-radius: 12px;
        box-shadow: 0 2px 4px silver;
    }

    .btmNavMenu {
        position: fixed;
        bottom: 70px;
        right: 0;
        width: 160px;
        max-width: 160px;
        background-color: #FFFFFF;
        box-shadow: 0 -2px 4px silver;
    }

    .btmNavMenuBtn {
        color: #707070;
        background-color: #EDEDED;
        border-radius: 8px;
    }

    .btmNavLangNoIcon {
        height: 25px;
        width: 25px;
        background-color: #FFFFFF;
    }

    .btmNavLangMenu {
        position: fixed;
        bottom: 70px;
        right: 140px;
        border-top-left-radius: 12px;
        background-color: #FFFFFF;
        box-shadow: -5px -2px 4px silver;
    }

    .btmNavLangBtn {
        width: 41px; height: 41px;  /*needed so that border and box-sizing work*/
        color: #707070;
        border-radius: 8px;
    }

    .border {
        border: 3px #6995CD solid;
        box-sizing: border-box !important;
    }

    .pointer {
        cursor: pointer;
    }

    @media only screen and (max-width: 900px) {
        .small {
            display: block !important;
        }

        .big {
            display: none !important;
        }

        .topPadding {
            padding-top: 12px;
        }
    }

    .slick-slider {
        user-select: auto!important;
    }

    @media only screen and (max-width: 500px), (max-width: 900px) and (orientation: portrait) {
        .displayHide {
            display: none;
        }

        .btmNav {
            display: block;
        }
    }

    .animateIcon {
        overflow: hidden;
    }

    .animateIcon:after {
        animation: shine 60s ease-in-out infinite;
        animation-fill-mode: forwards;
        content: "";
        position: absolute;
        top: -110%;
        left: -210%;
        width: 200%;
        height: 200%;
        opacity: 0;
        transform: rotate(30deg);

        background: rgba(255, 255, 255, 0.0);
        background: linear-gradient(
                to right,
                rgba(112, 112, 112, 0.2) 0%,
                rgba(112, 112, 112, 0.2) 77%,
                rgba(112, 112, 112, 0.5) 92%,
                rgba(112, 112, 112, 0.0) 100%
        );
    }

    /* Hover state - trigger effect */

    /* Active state */
    .animateIcon:active:after {
        opacity: 0;
    }

    @keyframes shine{
        1% {
            opacity: 1;
            top: -30%;
            left: -30%;
            transition-property: left, top, opacity;
            transition-duration: 0.7s, 0.7s, 0.15s;
            transition-timing-function: ease;
        }
        5% {
            opacity: 0;
            top: -30%;
            left: -30%;
            transition-property: left, top, opacity;
        }
    }

    .v-snack.v-snack--top {
        bottom: initial; /* or auto */
    }

    .v-snack.v-snack--bottom {
        top: initial; /* or auto */
    }
</style>
