var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      key: _vm.update,
      staticClass: "noselect",
      staticStyle: { "background-color": "transparent", "z-index": "2" },
      style: "height:" + _vm.iconSize + "; width:" + _vm.iconSize,
      on: { click: _vm.greet }
    },
    [
      this.iconColor === "white"
        ? _c("img", {
            attrs: {
              src: _vm.isReading
                ? _vm.lautsprecherAusWhite
                : _vm.lautsprecherWhite
            }
          })
        : _c("img", {
            attrs: {
              src: _vm.isReading ? _vm.lautsprecherAus : _vm.lautsprecher
            }
          })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }