<template>
    <div>
        <v-row class="mx-0">
            <div class="whiteBg" style="min-height: 80vh;height: auto; max-width: 90vw ;width: 400px !important; border-radius: 15px; box-shadow: 1px 5px 5px silver; margin: auto auto 5em;">

                <!--Header of Chat-->
                <div
                        style="
            background-color: #8cbd46;
    border-radius: 15px 15px 0 0;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
    color: white;
          "
                >
                    <v-row
                            style="width: 90%; display: flex; align-items: center;"
                            class="pr-2 ml-2"
                    >
                        <v-col
                                cols="9"
                                md="8"
                                lg="9"
                                class="pa-0 pl-2 text-left d-flex align-center"
                                style="font-size: xx-large"
                        >
                            <img
                                    :src="chatIcon"
                                    class="iconToWhite mr-1"
                                    style="height: 30px;  margin-bottom: -3px;"
                            />
                            <p class="ma-0 pl-2">Chat</p>
                        </v-col>
                    </v-row>
                </div>

                <!--body of Chat-->
                <div class="chat-body" >
                    <!--matix user list-->
                    <v-row class="mx-1" v-if="rooms.length >0">
                        <v-col cols="3" v-if="selected && page > 0">
                            <v-btn @click="page === 2 ? page-= 2 : page--" class="pageButton" elevation="0" tile color="#737373" >
                                <img :src="linksIcon" :height="30" class="iconToWhite">
                            </v-btn>
                        </v-col>
                        <v-col cols="3" @click="openPrivateRoom(item)" v-for="(item) in (selected ? matrixUsers.slice(page, ( matrixUsers.length > 3 && (page === 0 || page === matrixUsers.length - 3) ? page + 3 : (matrixUsers.length === 3 ? page+3 : page+2))) : matrixUsers)" :key="JSON.stringify(item)"  class="ellipsis" style="text-align: center; cursor: pointer">
                            <v-row >
                                <v-btn :selected="selected === item._id" class="mx-auto" medium elevation="0" fab color="#6994cc">
                                    <img :selected="selected === item._id && hasProfilePic(item)" :src="getTeachPic(item)" :height="hasProfilePic(item) ? 56 : 25" :class="hasProfilePic(item) ? 'roundImage' : 'iconToWhite'">
                                </v-btn>
                            </v-row>

                            {{item.lastName}}
                        </v-col>
                        <v-col cols="3" v-if="selected && matrixUsers.length > 3 && page < matrixUsers.length - 3">
                            <v-btn @click="page === 0 ? page+= 2 : page++" class="pageButton" elevation="0" tile color="#737373" >
                                <img :src="rechtsIcon" :height="30" class="iconToWhite">
                            </v-btn>
                        </v-col>


                    </v-row>
                    <hr class="mx-6 mb-1" style="color: #737373" v-if="selected">


                    <vue-custom-scrollbar :settings="settings" class="scroll-area" v-if="selected === '0'">
                        <div v-for="item in blackboard" :key="JSON.stringify(item)" class="mx-6 mt-2">
                            <v-row style="color: #737373; line-height: 56px;">
                                <v-col cols="2">
                                    <v-btn class="mx-auto" medium elevation="0" fab :color="item.color">
                                        <img :src="chooseIcon(item.icon)" :height="25">
                                    </v-btn>
                                </v-col>
                                <v-col>
                                    <div class="ellipsis" style="font-weight: bold; font-size: larger;">Verwaltung</div>
                                </v-col>
                                <v-col style="text-align: right">
                                    {{new Date(item.createdOn).toLocaleDateString([], {day: '2-digit', month: '2-digit'}) + ' ' + new Date(item.createdOn).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}}
                                </v-col>

                            </v-row>
                            <div style="display: flex">
                                <div class="pa-3 chat-message-content">
                                    {{item.message}}
                                </div>
                                <div style="width: 15%;">
                                    <v-btn @click="readText(item.message, $event)" class="speakerButton" elevation="0" tile color="#737373" >
                                        <img :src="lautsprecherIcon" :height="30">
                                    </v-btn>
                                </div>

                            </div>
                        </div>
                    </vue-custom-scrollbar>

                    <div v-if="viewingRoom" class="chat-body" style="overflow: hidden !important">
                        <vue-custom-scrollbar ref="scroll" class="scroll-area2" :settings="settings" @ps-y-reach-start="loadMore()">

                            <div :key="index" v-for="(event, index) in events.filter(el => el.getType() === 'm.room.message')" class="mx-6 mt-2">
                                <div v-if="!isMe(event)">
                                    <v-row style="color: #737373; line-height: 56px;">
                                        <v-col cols="2">
                                            <v-btn class="mx-auto" medium elevation="0" fab color="#6994cc" v-if="!hasProfilePic(matrixUsers.find((el) => el._id == selected))" >
                                                <img :src="getTeachPic(matrixUsers.find((el) => el._id == selected))" class="iconToWhite" :height="25">
                                            </v-btn>
                                            <v-btn v-else class="mx-auto" medium elevation="0" fab color="#6994cc">
                                                <img :src="getTeachPic(matrixUsers.find((el) => el._id == selected))" :height="56 " class="roundImage">
                                            </v-btn>
                                        </v-col>
                                        <v-col>
                                            <div class="ellipsis" style="font-weight: bold; font-size: larger;">{{matrixUsers.find((el) => el._id == selected).lastName}}</div>
                                        </v-col>
                                        <v-col style="text-align: right">
                                            {{new Date(getTime(event)).toLocaleDateString([], {day: '2-digit', month: '2-digit'}) + ' ' + new Date(getTime(event)).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}}
                                        </v-col>

                                    </v-row>
                                    <div style="display: flex">
                                        <div class="pa-3 chat-message-content">
                                            <div v-if="event.getContent().body">
                                                <div v-if="event.getContent().body.startsWith('$fileId:')">
                                                    <div @click=" loadedAudios.filter((el) => { return el.file === event.getContent().body.replace('$fileId:','') }).length > 0  ? playPause(event.getContent().body.replace('$fileId:','')) : loadFile(event.getContent().body.replace('$fileId:',''))" style="line-height: 20px">
                                                        <div class="d-flex align-center" style="min-height: 24px">
                                                            <p class="mb-0 mr-1">Sprachnachricht</p>
                                                            <img v-if="!loadedAudios.filter((el) => { return el.file === event.getContent().body.replace('$fileId:','') }).length > 0" :src="downloadIcon" class="icon iconToWhite">
                                                            <img v-else-if="audioSrc !== event.getContent().body.replace('$fileId:','')" :src="playIcon" class="icon iconToWhite">
                                                            <img v-else :src="pauseIcon" class="icon iconToWhite">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div v-else>
                                                    {{ event.getContent().body }}
                                                </div>
                                            </div>
                                            <div v-else style="font-style: italic">
                                                Diese Nachricht wurde gelöscht.
                                            </div>
                                        </div>
                                        <div style="width: 15%;" v-if="!event.getContent().body || !event.getContent().body.startsWith('$fileId:')">
                                            <v-btn @click="readText((event.getContent().body || 'Diese Nachricht wurde gelöscht.'), $event)" class="speakerButton" elevation="0" tile color="#737373" >
                                                <img :src="lautsprecherIcon" :height="30">
                                            </v-btn>
                                        </div>

                                    </div>
                                </div>


                                <div v-else>
                                    <v-row style="color: #737373;">
                                        <v-col style="text-align: right">
                                            {{new Date(getTime(event)).toLocaleDateString([], {day: '2-digit', month: '2-digit'}) + ' ' + new Date(getTime(event)).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}}
                                        </v-col>

                                    </v-row>
                                    <div style="display: flex">
                                        <div style="width: 15%;" v-if="event.getContent().body">
                                            <v-btn @click="(isMe(event) && event.getContent().body) ? ((selectedEvent = event) && (deleteDialog = true)) : null" class="deleteButton" elevation="0" tile color="#737373" >
                                                <img :src="papierkorbIcon" :height="30" class="iconToWhite">
                                            </v-btn>
                                        </div>
                                        <div class="pa-3 my-chat-message-content">
                                            <div v-if="event.getContent().body">
                                                <div v-if="event.getContent().body.startsWith('$fileId:')">
                                                    <div @click=" loadedAudios.filter((el) => { return el.file === event.getContent().body.replace('$fileId:','') }).length > 0  ? playPause(event.getContent().body.replace('$fileId:','')) : loadFile(event.getContent().body.replace('$fileId:',''))" style="line-height: 20px">
                                                        <div class="d-flex align-center" style="min-height: 24px">
                                                            <p class="mb-0 mr-1">Sprachnachricht</p>
                                                            <img v-if="!loadedAudios.filter((el) => { return el.file === event.getContent().body.replace('$fileId:','') }).length > 0" :src="downloadIcon" class="icon iconToWhite">
                                                            <img v-else-if="audioSrc !== event.getContent().body.replace('$fileId:','')" :src="playIcon" class="icon iconToWhite">
                                                            <img v-else :src="pauseIcon" class="icon iconToWhite">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div v-else>
                                                    {{ event.getContent().body }}
                                                </div>
                                            </div>
                                            <div v-else style="font-style: italic">
                                                Diese Nachricht wurde gelöscht.
                                            </div>
                                        </div>


                                    </div>
                                </div>

                            </div>
                        </vue-custom-scrollbar>

                        <hr class="mx-6 mb-1" style="color: #737373" v-if="selected && viewingRoom">

                        <div class="mx-6" v-if="selected && viewingRoom">
                            <div style="display: flex">
                                <div class="pa-2 chatButton" v-long-press="100" @long-press-start="startRecording" @long-press-stop="stopRecording" :style="audioCheck ? (toggle ? 'background-color: red;' :'background-color: #8cbd46;'): 'background-color: #5c5c5c;'">
                                    Sprachnachricht
                                    <img :src="mikrofonIcon" :height="20" class="iconToWhite" style="margin-bottom: -2px">
                                </div>
                                <div style="width: 15%;" class="pt-1">
                                    <v-btn style="margin-top: 0" @click="audioCheck ? readText('Halte das Mikrofon gedrückt und rede. Wenn du das Mikrofon loslässt, schickst du deine Nachricht ab.', $event) : readText('Leider können wir dein Mikrofon nicht benutzen.', $event)" class="speakerButton" elevation="0" tile color="#737373" >
                                        <img :src="lautsprecherIcon" :height="30">
                                    </v-btn>
                                </div>
                            </div>

                            <div style="display: flex" class="pt-1" >
                                <div class="pa-2 chatButton" @click="sendMessage('Ich habe ein Problem 😖')">
                                    Ich habe ein Problem 😖
                                </div>
                                <div style="width: 15%;" class="pt-1">
                                    <v-btn @click="readText('Ich habe ein Problem', $event)" style="margin-top: 0" class="speakerButton" elevation="0" tile color="#737373" >
                                        <img :src="lautsprecherIcon" :height="30">
                                    </v-btn>
                                </div>
                            </div>

                            <div style="display: flex"  class="pt-1" >
                                <div class="chatButton" style="background-color: white; display: inline-flex">
                                    <twemoji-picker
                                            :emojiData="emojiDataAll"
                                            :emojiGroups="emojiGroups"
                                            style="height: 43px; margin-top: -10px"
                                            :pickerWidth="300"
                                            :skinsSelection="true"
                                            :searchEmojisFeat="true"
                                            @emojiUnicodeAdded=" (el) => inputMessage+= el"
                                            searchEmojiPlaceholder="Suchen..."
                                            searchEmojiNotFound="Kein Emoji gefunden"
                                            isLoadingLabel="Lädt..."
                                    ></twemoji-picker>

                                    <input v-model="inputMessage" oncontextmenu="return false;" @keyup.enter="sendMessage" placeholder="Nachricht eingeben..." class="chat-send-input pa-1" type="text"/>

                                </div>
                                <div style="width: 15%;" class="pt-1">
                                    <v-btn @click="inputMessage.trim() ? sendInput() :readText('Tippe eine Nachricht ein um diese an deinen Lehrer zu senden.', $event)" class="speakerButton" elevation="0" style="margin-top: 0" tile color="#737373" >
                                        <img :src="inputMessage.trim() ? sendenIcon : lautsprecherIcon" class="iconToWhite" :height="30">
                                    </v-btn>
                                </div>
                            </div>
                        </div>



                    </div>

                </div>
            </div>
        </v-row>

        <v-dialog v-model="deleteDialog" persistent max-width="400">
            <v-card>
                <v-card-title class="headline">Nachricht löschen?</v-card-title>
                <v-card-text>
                    Wollen Sie diese wirklich Nachricht löschen?
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="#bdbdbd" style="color: white" @click="deleteDialog = false">Abbrechen</v-btn>
                    <v-btn style="color: white" color="red red-1" @click="() => {this.deleteMessage(selectedEvent); this.deleteDialog = false}">Löschen</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";

import vueCustomScrollbar from "vue-custom-scrollbar";
import chatIcon from '../../assets/Icons/chat-alternative-102.svg';
import lehrerIcon from '../../assets/Icons/lehrer-24.svg';


import sonneIcon from "../../assets/Icons/sonne-weiss-46.svg";
import erdkundeIcon from '../../assets/Icons/f-erdkunde-weiss-31.svg';
import religionIcon from '../../assets/Icons/f-religion-weiss-39.svg';
import politikIcon from '../../assets/Icons/f-politik-weiss-34.svg';
import bioIcon from '../../assets/Icons/f-biologie-weiss-35.svg';
import downloadIcon from '../../assets/Icons/download-116.svg';
import playIcon from '../../assets/Icons/Play-114.svg';
import pauseIcon from '../../assets/Icons/Pause-115.svg';
import englischIcon from "../../assets/Icons/f-englisch-30.svg";
import englischIconWeiss from "../../assets/Icons/f-englisch-weiss-30.svg";

import lautsprecherIcon from "../../assets/Icons/lautsprecher-weiss-13.svg";
import lautsprecherAusIcon from "../../assets/Icons/lautsprecher-weiss-aus-89.svg";

import linksIcon from "../../assets/Icons/links-10.svg";
import rechtsIcon from "../../assets/Icons/rechts-11.svg";

import sendenIcon from '../../assets/Icons/senden-23.svg';

import papierkorbIcon from '../../assets/Icons/papierkorb-109.svg';

import mikrofonIcon from '../../assets/Icons/mikrofon-22.svg';

import translation from "@/util/translate";

import { getMatrixAddress } from "../../util/getMatrixAddress";
import * as BackendApi from "../../api/backend";
import * as sdk from "matrix-js-sdk";

import {
    TwemojiPicker
} from '@kevinfaguiar/vue-twemoji-picker';
import EmojiAllData from '@kevinfaguiar/vue-twemoji-picker/emoji-data/de/emoji-all-groups.json';
import EmojiGroups from '@kevinfaguiar/vue-twemoji-picker/emoji-data/emoji-groups.json';


import Recorder from 'js-audio-recorder';

export default {
name: "BabyChatWidget",
    components: {
        vueCustomScrollbar,
        'twemoji-picker': TwemojiPicker,
    },
    data() {
        return {
            inputMessage: "",

            linksIcon,
            rechtsIcon,

            chatIcon,
            lehrerIcon,
            lautsprecherIcon,
            lautsprecherAusIcon,

            downloadIcon,
            playIcon,
            pauseIcon,

            papierkorbIcon,

            mikrofonIcon,

            sendenIcon,
            settings: {
                suppressScrollY: false,
                suppressScrollX: true,
                wheelPropagation: false
            },
            matrixUsers: [],
            teacherPics: [], //collection of teacher profile pictures
            blackboard: [],

            selected: false,

            //Icon array for dynamically picking the icon via string
            icons: [
                {icon: englischIcon, text: "englischIcon"},
                {icon: sonneIcon, text: "sonneIcon"},
                {icon: englischIconWeiss, text: "englischIconWeiss"},
                {icon: politikIcon, text: "politikIcon"},
                {icon: erdkundeIcon, text: "erdkundeIcon"},
                {icon: religionIcon, text: "religionIcon"},
                {icon: bioIcon, text: "bioIcon"},
            ],

            page: 0,

            rooms: [],
            matrixClient: null,
            viewingRoom: null,
            numMessagesToShow: 20,

            audioCheck: false,
            loadedAudios: [],
            recorder: new Recorder(),
            audio: new Audio(),
            audioSrc: '',

            toggle: false,
            chatIds: [], //contains ids that are already have a privte chat

            selectedEvent: null,
            deleteDialog: false,
        };
    },
    async mounted() {
        await this.requestAppointments();
        this.requestTeachersProfilePics();
        this.requestBlackboard();


        const matrixUserResponse = await BackendApi.getCurrentMatrixUser();
        const matrixUser = await matrixUserResponse.json();

        // Transform name like @alice-1603048221873-estundenplan-localhost-simon:estundenplan-synapse-development
        // to alice-1603048221873-estundenplan-localhost-simon
        const loginName = matrixUser.name.slice(1).split(":")[0];

        const matrixServerUrl = getMatrixAddress();
        const loginDataResponse = await fetch(
            `${matrixServerUrl}/_matrix/client/r0/login`,
            {
                method: "POST",
                body: JSON.stringify({
                    type: "m.login.password",
                    user: loginName,
                    password: matrixUser.password,
                }),
            }
        );

        const loginData = await loginDataResponse.json();
        const matrixClient = sdk.createClient({
            baseUrl: matrixServerUrl,
            accessToken: loginData.access_token,
            userId: loginData.user_id,
        });
        this.matrixClient = matrixClient;

        matrixClient.on("sync", this.handleMatrixClientOnSync);
        matrixClient.on("Room", this.handleMatrixClientOnRoom);
        matrixClient.on("Room.timeline", this.handleMatrixClientOnRoomTimeline);

        matrixClient.startClient(10);

        this.checkPermission();
    },
    computed : {
        ...mapState('translation', ['targetLang',]),
        ...mapState("auth", [ "token", "account"]),
        events: function () {
            return this.viewingRoom.timeline;
        },
        emojiDataAll() {
            return EmojiAllData;
        },
        emojiGroups() {
            return EmojiGroups;
        }
    },
    methods: {
        ...mapActions("blackboard", ["getBlackboard"]),
        ...mapActions("appointments", ["getAppointments"]),
        ...mapActions("teachers", ["getProfilePicture"]),
        ...mapActions("matrix", ["createPrivateRoom"]),
        ...mapActions('translation', [ 'setTranslatedText', 'showTranslation' ]),


        async openPrivateRoom(item) {
            if(this.selected === item._id) {
                this.selected = false;
                this.page = 0;
                this.viewingRoom = null;
                return
            }
            this.selected = item._id;
            this.page = this.matrixUsers.findIndex((el) => el._id === item._id);
            this.viewingRoom = null;
            if(item._id != 0) {
                if(!this.chatIds.includes(item.account)) {
                    await this.createPrivateRoom({second: item.account});
                    this.setRoomList();
                    let timer = 0;
                    while (!this.chatIds.includes(item.account)) {
                        await this.sleep(500);
                        timer ++;
                        if(timer% 5 === 0) {
                            this.setRoomList();
                        }
                    }

                    const room = this.getRoomsFromId(item.account);
                    await this.matrixClient.joinRoom(room.roomId);
                    await this.matrixClient.sendReceipt(
                        room.timeline[room.timeline.length - 1],
                        "m.read"
                    );

                    this.viewingRoom = room;
                    await this.$nextTick();
                    while (!this.$refs.scroll) {
                        await this.sleep(500);
                    }
                    this.$refs.scroll.$el.scrollTop = this.$refs.scroll.$el.scrollHeight;
                } else {
                    const room = this.getRoomsFromId(item.account);
                    await this.matrixClient.joinRoom(room.roomId);
                    await this.matrixClient.sendReceipt(
                        room.timeline[room.timeline.length - 1],
                        "m.read"
                    );

                    this.viewingRoom = room;
                    await this.$nextTick();
                    while (!this.$refs.scroll) {
                        await this.sleep(500);
                    }
                    this.$refs.scroll.$el.scrollTop = this.$refs.scroll.$el.scrollHeight;
                }
            }
        },
        getRoomsFromId(id) {
            return this.rooms.find((el) => el.name.includes(id));
        },

        addToChatIds(room) {
            this.chatIds.push(room.name.replace(this.account._id, "").replace("private Room", "").replace("<", "").replace(">", "").replace(",", "").replace(" ", ""));
        },



        // Copy from ChatWidegtGroup Chat

        getUsername(event) {
            /*return event.sender.rawDisplayName.slice(0, event.sender.rawDisplayName.length-1);*/ //TODO and me too
            return event.sender.rawDisplayName;
        },
        //return only the gender char [m, w, d]
        getGender(event) {
            return event.sender.rawDisplayName.slice(event.sender.rawDisplayName.length-1, event.sender.rawDisplayName.length);
        },
        isMe(event) {
            return (this.matrixClient.credentials.userId === event.sender.userId);
        },


        async loadMore() {
            this.matrixClient.scrollback(
                this.viewingRoom,
                1,
                (err, res) => {
                    console.log(err);
                });
            this.$refs.scroll.$el.scrollTop = 10;
        },

        checkPermission() {
            Recorder.getPermission().then(() => {
                this.audioCheck = true;
            }, (error) => {
                console.log(`${error.name} : ${error.message}`);
            });
        },

        async loadFile(file) {
            /*console.log('load')*/
            const res = await BackendApi.getVoiceMessage(
                file
            );
            fetch(res.url, {
                method: "GET",
                headers: new Headers({
                    Authorization: "Bearer " + this.token,
                }),
            })
                .then((response) => response.blob())
                .then((blob) => {
                    /*console.log(file)
                    console.log(blob)*/
                    let url = window.URL.createObjectURL(blob);
                    this.loadedAudios.push({'file' : file, 'url': url})

                });
        },

        playPause(file) {
            /*console.log(this.loadedAudios)*/
            if(this.audio) {
                this.audio.pause();
                this.audio = null;
                if(this.audioSrc === file) {
                    this.audioSrc = '';
                    return
                }
                this.audioSrc = '';
            }
            this.audio = new Audio(this.loadedAudios.find(el => el.file === file).url);
            this.audioSrc = file;
            this.audio.play();
            this.audio.addEventListener("ended", () => {
                this.audioSrc = '';
            });

        },
        startRecording() {
            if(this.audioCheck) {
                this.toggle = true;
                /*console.log("Start Recording");*/
                this.recorder.startRecord();
                /*console.log(this.recorder);*/
            }

        },
        stopRecording() {
            if(this.audioCheck) {
                this.toggle = false;
                /*console.log("Stop Recording")*/
                this.recorder.stopRecord();
                this.handleRecording(this.recorder.getWAVBlob());
            }
        },
        handleRecording(blob) {
            const formData = new FormData();
            formData.append("file", blob, new Date().toLocaleDateString().replaceAll(".", "_") + '.wav');

            this.XmlHttpRequest = BackendApi.postVoiceMessage(
                blob
            );

            this.XmlHttpRequest.onerror = (e) => {
                console.error("Private upload error:", e);
                this.XmlHttpRequest = null;
            };

            this.XmlHttpRequest.onabort = (e) => {
                console.warn("Private upload aborted");
                this.XmlHttpRequest = null;
            };

            this.XmlHttpRequest.addEventListener("load", (e) => {
                if (this.XmlHttpRequest.status !== 201) {
                    console.error(
                        "Private upload failed:",
                        this.XmlHttpRequest.response
                    );
                }
                this.sendMessage('$fileId:' + JSON.parse(this.XmlHttpRequest.response)._id);
                this.inputMessage = '';
                setTimeout(() => {
                    this.$refs.scroll.$el.scrollTop = this.$refs.scroll.$el.scrollHeight
                }, 500)
                this.XmlHttpRequest = null;
            });
            this.XmlHttpRequest.send(formData);
        },

        sleep(milliseconds) {
            return new Promise(resolve => setTimeout(resolve, milliseconds));
        },
        getTime(event) {
            let age = event.getUnsigned().age;
            // TODO: proper format/hide date if message was sent today and always show time
            return new Date(Date.now() - age).toString();
        },

        // end of Copy

        sendInput() {
            if ((this.inputMessage.trim())) {
                this.sendMessage(this.inputMessage)
                this.inputMessage = '';
                setTimeout(() => {
                    this.$refs.scroll.$el.scrollTop = this.$refs.scroll.$el.scrollHeight
                }, 500)
            }
        },

        sendMessage(message) {
            const content = {
                body: message,
                msgtype: "m.text",
            };
            this.matrixClient.sendEvent(
                this.viewingRoom.roomId,
                "m.room.message",
                content,
                "",
                (err, res) => {
                    console.log(err);
                }
            );

            setTimeout(() => {
                this.$refs.scroll.$el.scrollTop = this.$refs.scroll.$el.scrollHeight
            }, 500)
        },

        async deleteMessage(message) {
            this.matrixClient.redactEvent(
                this.viewingRoom.roomId,
                message.event.event_id,
                message._txnId,
                (err, res) => {
                    console.log(err);
                }
            );
            if(this.selectedEvent.getContent().body.startsWith('$fileId:')) {
                const res = await BackendApi.deleteVoiceMessage(this.selectedEvent.getContent().body.replace('$fileId:',''));
            }
        },



        async requestBlackboard() {
            this.blackboard = await this.getBlackboard();

            this.blackboard.sort((a, b) => {
                let keyA = new Date(a.createdOn),
                    keyB = new Date(b.createdOn);
                // Compare the 2 dates
                if (keyA > keyB) return -1;
                if (keyA < keyB) return 1;
                return 0;
            });
        },
        async requestAppointments() {
            const appointments = await this.getAppointments();
            appointments.forEach((el) => {
                if (!this.contains(this.matrixUsers, el.teacher)) {
                    this.matrixUsers.push(el.teacher);
                }
            });
            this.matrixUsers.push({lastName: 'Verwaltung', gender:'m', _id: '0'});
        },
        contains(a, obj) {
            let i = a.length;
            while (i--) {
                if (a[i]._id === obj._id) {
                    return true;
                }
            }
            return false;
        },
        async requestTeachersProfilePics() {
            await this.setTeachProfilePics();
        },

        //get profile pics from all teachers and save them in teacherPics array
        async setTeachProfilePics() {
            for(let i = 0; i < this.matrixUsers.length; i++ ) {
                let profilePictureBlob = await this.getProfilePicture(this.matrixUsers[i]._id);
                const objectURL = URL.createObjectURL(profilePictureBlob);

                this.teacherPics.push([this.matrixUsers[i]._id, objectURL]);
            }
        },
        //get teacher profile pic from teacherPics array
        getTeachPic(user) {
            if(user._id == 0) {
                return null;
            }
            for (let j = 0; j < this.teacherPics.length; j++) {
                let currTeacher = this.teacherPics[j];
                if (currTeacher === undefined) {
                    continue;
                } else if (currTeacher[0] === user._id) {
                    return currTeacher[1];
                }
            }
            return lehrerIcon;
        },
        //checks if chat opponent has a profile pic
        hasProfilePic(user) {
            for (let j = 0; j < this.teacherPics.length; j++) {
                let currTeacher = this.teacherPics[j];
                if (currTeacher === undefined) {
                    continue;
                } else if (currTeacher[0] === user._id) {
                    return true;
                }
            }
            return false;
        },

        chooseIcon(iconName) {
            for(let i of this.icons){
                if(i.text === iconName){
                    return i.icon;
                }
            }
        },


        async readText(text, event) {
            if ('speechSynthesis' in window) {
                // Speech Synthesis supported 🎉
            } else {
                // Speech Synthesis Not Supported 😣
                alert("Sorry, your browser doesn't support text to speech!");
                return
            }

            let txt;
            if (text) {
                txt = text;
            } else {
                txt = 'Dieser Eintrag hat keinen Inhalt.';
            }

            if (this.targetLang !== 'de') {
                txt = await translation.translateToTargetLang(this.targetLang, txt);

                this.setTranslatedText(txt);
                this.showTranslation(true);

                if (this.showTextTimeout) {
                    clearTimeout(this.showTextTimeout);
                    this.showTextTimeout = null;
                }

                this.showTextTimeout = setTimeout(() => {
                    this.showTranslation(false);
                    this.showTextTimeout = null;
                }, 15000)
            }

            if (window.speechSynthesis.speaking) {
                window.speechSynthesis.cancel();
                event.target.src = lautsprecherIcon;
            } else {
                event.target.src = lautsprecherAusIcon;
                let msg = new SpeechSynthesisUtterance();
                msg.text = txt;
                if (this.isLangPackageAvailable()) {
                    msg.lang = this.targetLang;
                    window.speechSynthesis.speak(msg);
                } else {
                    event.target.src = lautsprecherIcon;
                }

                msg.onend = function() {
                    event.target.src = lautsprecherIcon;
                }
            }
        },
        isLangPackageAvailable() {
            for (let i = 0; i < window.speechSynthesis.getVoices().length; i++) {
                if (window.speechSynthesis.getVoices()[i].lang.includes(this.targetLang)) {
                    return true;
                }
            }

            return false;
        },
        expandText(event) {
            //from https://stackoverflow.com/questions/54459816/vue-text-highlight-event
            const isTextHighlighting = window.getSelection().toString().trim() !== '';

            //only expand when no text is being selected
            if (!isTextHighlighting) {
                event.target.classList.toggle('fade');
                event.target.classList.toggle('initHeight');
            }
        },

        async handleExitRoom() {
            await this.matrixClient.sendReceipt(
                this.viewingRoom.timeline[this.viewingRoom.timeline.length - 1],
                "m.read"
            );
            this.viewingRoom = null;
        },
        async handleRoomSelected(room) {
            // console.log(room);
            await this.matrixClient.joinRoom(room.roomId);
            await this.matrixClient.sendReceipt(
                room.timeline[room.timeline.length - 1],
                "m.read"
            );

            this.viewingRoom = room;
            await this.$nextTick();
        },
        handleMatrixClientOnSync(state, prevState, data) {
            switch (state) {
                case "PREPARED":
                    this.setRoomList();
                    break;
            }
        },
        handleMatrixClientOnRoom() {
            this.setRoomList();
            if (!this.viewingRoom) {
                // printRoomList();
                // rl.prompt();
            }
        },
        handleMatrixClientOnRoomTimeline(event, room, toStartOfTimeline) {
            if (toStartOfTimeline) {
                return; // don't print paginated results
            }
            if (!this.viewingRoom || this.viewingRoom.roomId !== room.roomId) {
                return; // not viewing a room or viewing the wrong room.
            }
        },
        setRoomList() {
            const roomList = this.matrixClient
                .getRooms()
                .filter((room) => room._selfMembership === "join");
            roomList.sort(function (a, b) {
                // < 0 = a comes first (lower index) - we want high indexes = newer
                var aMsg = a.timeline[a.timeline.length - 1];
                if (!aMsg) {
                    return -1;
                }
                var bMsg = b.timeline[b.timeline.length - 1];
                if (!bMsg) {
                    return 1;
                }
                if (aMsg.getTs() > bMsg.getTs()) {
                    return -1;
                } else if (aMsg.getTs() < bMsg.getTs()) {
                    return 1;
                }
                return 0;
            });
            this.rooms = roomList;
            console.log(this.rooms)
            this.chatIds = [];
            this.rooms.forEach((el) => this.addToChatIds(el))
        },

    }
}
</script>

<style lang="scss" scoped>

.chat-body {
    position: relative;
    margin: auto;
    max-height: calc(80vh - 75px);
    height: calc(80vh - 75px);
}

.scroll-area {
    position: relative;
    overflow: hidden;
    max-height: calc(80vh - 179px);
    height: calc(80vh - 179px);
    margin-bottom: 2px;
}


.scroll-area2 {
    position: relative;
    overflow: hidden;
    max-height: calc(80vh - 329px);
    height: calc(80vh - 329px);
    margin-bottom: 2px;
}


.whiteBg {
    background-color: #ffffff;
}

//filter generated with https://codepen.io/sosuke/pen/Pjoqqp
.iconToWhite {
    filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%) hue-rotate(36deg) brightness(104%) contrast(105%);
}

.font-style {
  font-size: larger
}

.ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis
}

.roundImage {
    object-fit: cover;
    border-radius: 50%;
    height: 56px;
    width: 56px;
}

.speakerButton {
    background-color: #CACACA !important;
    border-color: #CACACA !important;
    width: 40px;
    height: 40px;
    border-radius: 6px;
    margin-top: 5px;
    margin-left: auto;
    margin-right: 0px;
    min-width: 0px !important;
    display: block;
}

.deleteButton {
    background-color: #CACACA !important;
    border-color: #CACACA !important;
    width: 40px;
    height: 40px;
    border-radius: 6px;
    margin-left: 0;
    margin-top: 5px;
    margin-right: auto;
    min-width: 0px !important;
    display: block;
}

.pageButton {
    background-color: #CACACA !important;
    border-color: #CACACA !important;
    width: 50px !important;
    height: 50px !important;
    border-radius: 6px;
    margin: 3px;
    min-width: 0px !important;
    display: block;
}

[selected] {
    border: solid 5px #8cbd46 !important;
}

.chat-message-content {
    width: 85%;
    background-color: #6994cc;
    color: white;
    font-size: large;
    border-radius: 15px 15px 15px 0px;
}

.my-chat-message-content {
    width: 85%;
    margin-left: auto;
    margin-right: 0;
    background-color: #CACACA;
    color: white;
    font-size: large;
    border-radius: 15px 15px 0px 15px;
}

.chat-send-input {
    cursor: pointer;
    background-color: #CACACA;
    color: white;
    font-size: large;
    outline: none;
    width: 100%;
    border-radius: 15px;
}

.chatButton {
    cursor: pointer;
    width: 85%;
    background-color: #CACACA;
    color: white;
    font-size: large;
    border-radius: 15px;
    text-align: center;
}


.icon {
    height: 20px;
}


#micIcon {
    background-image: url('../../assets/Icons/mikrofon-22.svg');
    width: 30px;
    height: 30px;
}
</style>
