<template>
    <div>
        <v-btn @click="joinLehrerzimmer" id="joinLehrerzimmerBtn" color="gruen"
            elevation="0"
        >
            <img :src="videoIcon"/>
            <p>Lehrerzimmer</p>
        </v-btn>

        <!-- Error Snackbar -->
        <v-snackbar bottom v-model="snackbar" color="error" timeout="5000">
            {{ snackbarText }}
        </v-snackbar>
    </div>
</template>

<script>
import videoIcon from '../../assets/Icons/kamera-video-73.svg';
import * as backend from '../../api/backend';
import { mapActions }  from 'vuex';

export default {
    name: "JoinLehrerzimmerButton",
    data() {
        return {
            videoIcon,
            snackbar: false,
            snackbarText: '',
        };
    },
    methods: {
        ...mapActions('util', [ 'toggleLoading' ]),
        ...mapActions('auth', [ 'reloadDontLogout' ]),
        async joinLehrerzimmer() {
            this.toggleLoading(true);
            const response = await backend.joinTeachersRoomBbbMeetingUrl();

            if (!response.status || response.status === 422 || response.status === 404) {
                this.snackbarText = 'Das Lehrerzimmer konnte nicht geladen werden.';
                this.snackbar = true;
                this.toggleLoading(false);
                return;
            }

            this.reloadDontLogout(true);

            const {redirectUrl} = await response.json();
            window.location.href = redirectUrl;

            this.toggleLoading(false);
        }
    }
}
</script>

<style scoped lang="scss">
#joinLehrerzimmerBtn {
    background-color: #8CBD46 !important;
    color: white;
    text-transform: inherit;
    height: 50px;
    border-radius: 8px;
    margin-left: 15px;

    img {
        height: 30px;
        filter: brightness(1000%);
        margin-right: 5px;
    }

    p {
        font-size: large;
        font-weight: 400;
        line-height: 0;
        margin-bottom: 0;
    }
}

@media only screen and (max-width: 900px) {
    #joinLehrerzimmerBtn {
        height: 40px;
        padding: 8px;
        margin-left: 0;
        margin-bottom: 8px;

        img {
            height: 21px;
            margin-right: 8px;
        }

        p {
            font-size: small;
        }
    }
}
</style>
