var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticStyle: { margin: "1.5rem" }, attrs: { id: "text" } }, [
        _c(
          "div",
          { staticStyle: { display: "inline-flex", "margin-top": "2em" } },
          [
            _c(
              "div",
              { staticStyle: { "margin-right": "0.2em" } },
              [
                _c("router-link", { attrs: { to: "/home" } }, [
                  _vm._v("Startseite")
                ])
              ],
              1
            ),
            _vm._v("/ "),
            _c(
              "div",
              {
                staticStyle: {
                  "border-bottom": "solid 2px  #737373",
                  "margin-left": "0.2em"
                }
              },
              [_vm._v("Kontakt")]
            )
          ]
        ),
        _vm._m(0)
      ]),
      _c("Footer")
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticStyle: { "margin-top": "5em", "margin-bottom": "10em" } },
      [
        _c(
          "div",
          {
            staticClass: "headline",
            staticStyle: { "padding-bottom": "0.5em" }
          },
          [_vm._v("Impressum")]
        ),
        _c(
          "table",
          {
            staticClass: "subline",
            attrs: {
              cellspacing: "0",
              cellpadding: "0",
              border: "0",
              width: "100%"
            }
          },
          [
            _c("tbody", [
              _c("tr", [
                _c("td", { attrs: { width: "100%" } }, [
                  _c("div", { attrs: { align: "left" } }, [
                    _c("p"),
                    _c(
                      "table",
                      {
                        attrs: {
                          border: "0",
                          width: "100%",
                          cellspacing: "0",
                          cellpadding: "0"
                        }
                      },
                      [
                        _c("tbody", [
                          _c("tr", [
                            _c("td", { attrs: { valign: "top" } }, [
                              _c("p", { attrs: { align: "left" } }, [
                                _c("span", {}, [_c("span")])
                              ]),
                              _c("p", { attrs: { align: "left" } }, [
                                _c("span", {}, [
                                  _c("span", {}, [
                                    _c("p", [
                                      _vm._v(
                                        " Verantwortliche Person für die Inhalte der Website im Sinne des Telemediengesetzes ist: "
                                      )
                                    ])
                                  ])
                                ])
                              ]),
                              _c("br"),
                              _c("p", { attrs: { align: "left" } }, [
                                _c("span", {}, [
                                  _c("span", {}, [
                                    _c(
                                      "p",
                                      {
                                        staticStyle: { "font-weight": "bold" }
                                      },
                                      [
                                        _vm._v(
                                          "Herr Rechtsanwalt Waldemar Koch"
                                        )
                                      ]
                                    )
                                  ])
                                ])
                              ]),
                              _c("p", { attrs: { align: "left" } }, [
                                _c("span", {}, [
                                  _c("span", {}, [
                                    _c("p", [
                                      _vm._v("Demo Anwaltsbüro Waldemar Koch")
                                    ])
                                  ])
                                ])
                              ]),
                              _c("p", { attrs: { align: "left" } }, [
                                _c("span", {}, [
                                  _c("span", {}, [
                                    _vm._v("Rechtsanwältin & Mediatorin")
                                  ])
                                ])
                              ]),
                              _c("p", { attrs: { align: "left" } }, [
                                _c("span", [
                                  _c("span", [
                                    _vm._v("Arbeits-, Vertrags-, Miet-,")
                                  ])
                                ])
                              ]),
                              _c("p", { attrs: { align: "left" } }, [
                                _c("span", [
                                  _c("span", [
                                    _vm._v(" Familien- und Strafrecht "),
                                    _c("br"),
                                    _vm._v("Salzstr. 30 "),
                                    _c("br"),
                                    _vm._v("74076 Heilbronn "),
                                    _c("br"),
                                    _vm._v("Tel.: "),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "skype_tb_injection",
                                        attrs: {
                                          title:
                                            "Call this phone number in Germany with  +4971312780794",
                                          id: "softomate_highlight_0",
                                          onmouseup:
                                            "javascript:skype_tb_imgOnOff(this,1,'0',true,16,'');return skype_tb_stopEvents();",
                                          onmousedown:
                                            "javascript:skype_tb_imgOnOff(this,2,'0',true,16,'');return skype_tb_stopEvents();",
                                          onmouseover:
                                            "javascript:skype_tb_imgOnOff(this,1,'0',true,16,'');",
                                          onclick:
                                            "javascript:skype_tb_doRunCMD('call','0',null,0);return skype_tb_stopEvents();",
                                          onmouseout:
                                            "javascript:skype_tb_imgOnOff(this,0,'0',true,16,'');",
                                          durex: "0",
                                          context: "07131/2780794",
                                          iamrtl: "0"
                                        }
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "skype_tb_imgA",
                                            staticStyle: {
                                              "background-image":
                                                "url(file://c:/Users/SILKEW~1/AppData/Local/Temp/Low/__SkypeIEToolbar_Cache/e70d95847a8f5723cfca6b3fd9946506/static/inactive_a.compat.flex.w16.gif)"
                                            },
                                            attrs: {
                                              title: "Skype actions",
                                              id: "skype_tb_droppart_0",
                                              onmouseup:
                                                "javascript:doSkypeFlag(this,'0',1,1,16);return skype_tb_stopEvents();",
                                              onmousedown:
                                                "javascript:doSkypeFlag(this,'0',2,1,16);return skype_tb_stopEvents();",
                                              onmouseover:
                                                "javascript:doSkypeFlag(this,'0',1,1,16);",
                                              onclick:
                                                "javascript:skype_tb_SwitchDrop(this,'0','sms=0');return skype_tb_stopEvents();",
                                              onmouseout:
                                                "javascript:doSkypeFlag(this,'0',0,1,16);"
                                            }
                                          },
                                          [
                                            _c("span", {
                                              staticClass: "skype_tb_imgFlag",
                                              staticStyle: {
                                                "background-image":
                                                  "url(file://c:/Users/SILKEW~1/AppData/Local/Temp/Low/__SkypeIEToolbar_Cache/e70d95847a8f5723cfca6b3fd9946506/static/famfamfam/DE.gif)"
                                              },
                                              attrs: { id: "skype_tb_img_f0" }
                                            })
                                          ]
                                        ),
                                        _c("span", {
                                          staticClass: "skype_tb_imgS",
                                          attrs: { id: "skype_tb_img_s0" }
                                        }),
                                        _c(
                                          "span",
                                          {
                                            staticClass: "skype_tb_injectionIn",
                                            attrs: { id: "skype_tb_text0" }
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "skype_tb_innerText",
                                                attrs: {
                                                  id: "skype_tb_innerText0"
                                                }
                                              },
                                              [
                                                _c("a", [
                                                  _vm._v("01234/1234567")
                                                ])
                                              ]
                                            )
                                          ]
                                        )
                                      ]
                                    ),
                                    _c("br"),
                                    _vm._v("Fax: 07131/7977240 "),
                                    _c("br")
                                  ])
                                ]),
                                _c("a", [
                                  _c("span", [
                                    _c("span", [
                                      _c("p", [
                                        _vm._v("Email: info@kanzlei-koch.demo")
                                      ])
                                    ])
                                  ])
                                ])
                              ]),
                              _c("p", { attrs: { align: "left" } }, [
                                _c("a", [
                                  _c("span", [
                                    _c("span", [
                                      _c("p", [_vm._v("www.kanzlei-koch.demo")])
                                    ])
                                  ])
                                ])
                              ]),
                              _c("br"),
                              _c("p", [
                                _c("span", [
                                  _c("span", [
                                    _c("p", [_vm._v("Steuernummer: Demo")])
                                  ])
                                ])
                              ]),
                              _c("p", [
                                _c("span", [
                                  _c("span", [
                                    _c("p", [
                                      _vm._v(
                                        ' Meine in der Bundesrepublik Deutschland verliehene gesetzliche Berufsbezeichnung lautet "Rechtsanwältin". '
                                      )
                                    ])
                                  ])
                                ])
                              ]),
                              _c("br"),
                              _c("p", [
                                _c("span", {}, [
                                  _c("span", {}, [
                                    _c(
                                      "p",
                                      {
                                        staticStyle: { "font-weight": "bold" }
                                      },
                                      [
                                        _vm._v(
                                          " Zuständige Kammer ist die Rechtsanwaltskammer Stuttgart "
                                        )
                                      ]
                                    )
                                  ])
                                ])
                              ]),
                              _c("p", [
                                _c("span", {}, [
                                  _c("span", {}, [
                                    _c("p", [
                                      _vm._v(" Rechtsanwaltskammer Stuttgart "),
                                      _c("br"),
                                      _vm._v(
                                        "Körperschaftt des öffentlichen Rechts "
                                      ),
                                      _c("br"),
                                      _vm._v(
                                        "vertreten durch die Präsidentin Ulrike Paul "
                                      ),
                                      _c("br"),
                                      _vm._v("Königstraße 14 "),
                                      _c("br"),
                                      _vm._v("D-70173 Stuttgart "),
                                      _c("br"),
                                      _vm._v("Tel.: "),
                                      _c(
                                        "a",
                                        {
                                          attrs: { href: "tel:0711 222150-0" }
                                        },
                                        [_vm._v("07 11 / 22 21 55-0")]
                                      ),
                                      _c("br"),
                                      _vm._v("Fax: 07 11 / 22 21 55-11 "),
                                      _c("br"),
                                      _vm._v("Email: "),
                                      _c(
                                        "a",
                                        {
                                          attrs: {
                                            href: "mailto:info@rak-stuttgart.de"
                                          }
                                        },
                                        [_vm._v("info@rak-stuttgart.de")]
                                      )
                                    ])
                                  ])
                                ]),
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      href: "http://www.rak-stuttgart.de/",
                                      target: "_blank"
                                    }
                                  },
                                  [
                                    _c("span", {}, [
                                      _c("span", {}, [
                                        _vm._v(" www.rak-stuttgart.de "),
                                        _c("br"),
                                        _c("br")
                                      ])
                                    ])
                                  ]
                                ),
                                _c("span", {}, [
                                  _c("span", {}, [
                                    _c(
                                      "p",
                                      {
                                        staticStyle: { "font-weight": "bold" }
                                      },
                                      [
                                        _vm._v(" "),
                                        _c("br"),
                                        _vm._v(
                                          "Die für meine Berufsausübung maßgeblichen berufsrechtlichen Regelungen sind "
                                        )
                                      ]
                                    )
                                  ])
                                ])
                              ]),
                              _c("p", { attrs: { align: "justify" } }, [
                                _c("span", {}, [
                                  _c("span", {}, [
                                    _c("p", [
                                      _vm._v(
                                        "- die Bundesrechtsanwaltsordnung (BRAO)"
                                      )
                                    ])
                                  ])
                                ])
                              ]),
                              _c("p", { attrs: { align: "justify" } }, [
                                _c("span", {}, [
                                  _c("span", {}, [
                                    _c("p", [
                                      _vm._v(
                                        " - das Gesetz über die Vergütung der Rechtsanwältinnen und Rechtsanwälte (RVG) "
                                      ),
                                      _c("br"),
                                      _c("br"),
                                      _vm._v(
                                        "- die Berufsordnung der Rechtsanwälte (BORA) "
                                      )
                                    ])
                                  ])
                                ])
                              ]),
                              _c("p", { attrs: { align: "justify" } }, [
                                _c("span", {}, [
                                  _c("span", {}, [
                                    _c("p", [
                                      _vm._v(
                                        " - die Fachanwaltsordnung für Rechtsanwälte (FAO) "
                                      )
                                    ])
                                  ])
                                ])
                              ]),
                              _c("p", [
                                _c("span", {}, [
                                  _c("span", {}, [
                                    _c("p", [
                                      _vm._v(
                                        " Sie finden diese Regelungen und weitere Informationen auf den Internetseiten der Bundesrechtsanwaltskammer unter: "
                                      )
                                    ])
                                  ])
                                ]),
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      href: "http://www.brak.de/",
                                      target: "_blank"
                                    }
                                  },
                                  [
                                    _c("span", {}, [
                                      _c("span", {}, [_vm._v("www.brak.de")])
                                    ])
                                  ]
                                ),
                                _c("span", {}, [_c("span", {}, [_c("p")])])
                              ]),
                              _c("br"),
                              _c("p", [
                                _c("span", {}, [
                                  _c("span", {}, [
                                    _c(
                                      "p",
                                      {
                                        staticStyle: { "font-weight": "bold" }
                                      },
                                      [_vm._v("Anschrift")]
                                    )
                                  ])
                                ])
                              ]),
                              _c("p", [
                                _c("span", {}, [
                                  _c("span", {}, [
                                    _c("p", [
                                      _vm._v("Bundesrechtsanwaltskammer ")
                                    ])
                                  ])
                                ])
                              ]),
                              _c("p", [
                                _c("span", {}, [
                                  _c("span", {}, [
                                    _c("p", [
                                      _vm._v(
                                        "Körperschaft des öffentlichen Rechts"
                                      )
                                    ])
                                  ])
                                ])
                              ]),
                              _c("p", [
                                _c("span", {}, [
                                  _c("span", {}, [
                                    _c("p", [_vm._v(" Littenstraße 9")])
                                  ])
                                ])
                              ]),
                              _c("p", [
                                _c("span", { staticStyle: {} }, [
                                  _c("span", {}, [
                                    _c("p", [_vm._v("10179 Berlin")])
                                  ])
                                ])
                              ]),
                              _c("p", [
                                _c("span", {}, [
                                  _c("span", {}, [
                                    _c("p", [
                                      _vm._v(
                                        " Meine Website dient lediglich zu Informationszwecken. Auf diesen Seiten erteile ich keinerlei konkreten Rechtsrat. Für den Inhalt externer Links übernehme ich keine Haftung. Dafür sind ausschließlich die Betreiber der jeweiligen Websites verantwortlich. "
                                      )
                                    ])
                                  ])
                                ])
                              ]),
                              _c("p", [
                                _c("span", {}, [
                                  _c("span", {}, [
                                    _c("p", [
                                      _vm._v(
                                        " Wenn Sie mir eine E-Mail zusenden möchten, so beachten Sie bitte, dass ich im Falle der Verwendung dieses elektronischen Kommunikationsmittels keinerlei Haftung für den Fall einer Fehlleitung oder verspäteten Übermittlung übernehme. Ich rufe Nachrichten per Mail zwar möglichst zeitnah ab, dennoch: "
                                      )
                                    ])
                                  ])
                                ])
                              ]),
                              _c("p", [
                                _c("span", {}, [
                                  _c("span", {}, [
                                    _c("p", [
                                      _vm._v(" Sollte es sich um "),
                                      _c("u", [
                                        _vm._v("vertrauliche Informationen")
                                      ]),
                                      _vm._v(
                                        "handeln, so ist eine Fehlleitung an unbefugte Personen nicht auszuschließen, unter datenschutzrechtlichen Gesichtspunkten empfehle ich daher eine persönliche Konversation per Telefon oder in einem vereinbarten Termin. "
                                      )
                                    ])
                                  ])
                                ])
                              ]),
                              _c("p", [
                                _c("span", {}, [
                                  _c("span", {}, [
                                    _c("p", [_c("br")]),
                                    _c("p", [
                                      _vm._v(
                                        " Diese Angaben bzw. die Anbieterkennzeichnung entsprechen dem Telemediengesetz. "
                                      )
                                    ])
                                  ])
                                ])
                              ]),
                              _c(
                                "p",
                                { staticStyle: { "font-weight": "bold" } },
                                [
                                  _c("span", {}, [
                                    _c("span", {}, [
                                      _c("p", [_c("br")]),
                                      _c("p", [
                                        _vm._v("Online-Streitbeilegung")
                                      ])
                                    ])
                                  ])
                                ]
                              ),
                              _c("p", [
                                _c("span", {}, [
                                  _c("span", {}, [
                                    _c("p", [
                                      _vm._v(
                                        " Bei Streitigkeiten sieht die ODR-Verordnung (VO Nr. 524/2013) eine Plattform zur Online-Streitbeilegung (OS) vor, welche Sie unter nachfolgendem Link erreichen können: "
                                      )
                                    ])
                                  ])
                                ]),
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      href:
                                        "https://webgate.ec.europa.eu/odr/main/?event=main.home.show&lng=DE",
                                      target: "_blank"
                                    }
                                  },
                                  [
                                    _c("span", {}, [
                                      _c("span", {}, [
                                        _vm._v("www.webgate.ec.europa.eu")
                                      ])
                                    ])
                                  ]
                                ),
                                _c("span", {}, [_c("span", {}, [_c("p")])])
                              ]),
                              _c("p", [
                                _c("span", {}, [
                                  _c("span", {}, [
                                    _c("p"),
                                    _c("p", [
                                      _vm._v(
                                        " Ich bin nicht bereit, an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen. "
                                      )
                                    ])
                                  ])
                                ])
                              ]),
                              _c(
                                "p",
                                { staticStyle: { "font-weight": "bold" } },
                                [
                                  _c("span", {}, [
                                    _c("span", {}, [
                                      _c("p", [_c("br")]),
                                      _c("p", [
                                        _vm._v(
                                          "Außergerichtliche Streitschlichtung"
                                        )
                                      ])
                                    ])
                                  ])
                                ]
                              ),
                              _c("p", [
                                _c("span", {}, [
                                  _c("span", {}, [
                                    _c("p"),
                                    _c(
                                      "p",
                                      { staticStyle: { display: "inline" } },
                                      [
                                        _vm._v(
                                          " Bei Streitigkeiten zwischen Rechtsanwälten und ihren Auftraggebern besteht auf Antrag die Möglichkeit der außergerichtlichen Streitschlichtung gemäß § 73 Abs. 2 Nr. 3 i.V.m. § 73 Abs. 5 BRAO bei der hier zuständigen regionalen Rechtsanwaltskammer Köln oder gemäß § 191 f BRAO bei der Schlichtungsstelle der Rechtsanwaltschaft bei der Bundesrechtsanwaltskammer, im Internet zu finden über die Homepage der Bundesrechtsanwaltskammer ( "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "a",
                                      {
                                        staticStyle: { display: "inline" },
                                        attrs: { href: "www.brak.de" }
                                      },
                                      [_vm._v("www.brak.de")]
                                    ),
                                    _c(
                                      "p",
                                      { staticStyle: { display: "inline" } },
                                      [_vm._v("), per Mail unter:")]
                                    ),
                                    _c(
                                      "a",
                                      {
                                        attrs: {
                                          href:
                                            "mailto:schlichtungsstelle@brak.de"
                                        }
                                      },
                                      [_vm._v("schlichtungsstelle@brak.de")]
                                    ),
                                    _c(
                                      "p",
                                      { staticStyle: { display: "inline" } },
                                      [_vm._v(".")]
                                    )
                                  ])
                                ])
                              ]),
                              _c(
                                "p",
                                { staticStyle: { "font-weight": "bold" } },
                                [
                                  _c("span", {}, [
                                    _c("span", {}, [
                                      _c("p", [_c("br")]),
                                      _c("p", [
                                        _vm._v("Berufshaftpflichtversicherung:")
                                      ])
                                    ])
                                  ])
                                ]
                              ),
                              _c("p", [
                                _c("span", {}, [
                                  _c("span", {}, [
                                    _c("p"),
                                    _c("p", [
                                      _vm._v(
                                        " HDI Versicherung AG, 30659 Hannover Räumlicher Geltungsbereich: EU-Gebiet "
                                      )
                                    ])
                                  ])
                                ])
                              ]),
                              _c("p", [
                                _c("span", {}, [
                                  _c("span", {}, [
                                    _c("p", [_c("br")]),
                                    _c("p", [
                                      _vm._v(
                                        " Diese Website dient ausschließlich Informationszwecken. Sie ist nicht dazu bestimmt, konkreten Rechtsrat zu erteilen oder ein Mandatsverhältnis anzubahnen. Für den Inhalt der Website wird keine Haftung übernommen. Ebenso erfolgen externe Links zu anderen Webseiten außerhalb dieser Domain ohne inhaltliches Zueigenmachen. "
                                      )
                                    ])
                                  ])
                                ])
                              ]),
                              _c("br"),
                              _c("p", [
                                _c("span", {}, [
                                  _c("span", {}, [
                                    _c("p", [_c("br")]),
                                    _c(
                                      "p",
                                      {
                                        staticStyle: { "font-weight": "bold" }
                                      },
                                      [
                                        _vm._v(
                                          "Individualsoftware, Website, Film und Inhalt created by"
                                        )
                                      ]
                                    )
                                  ])
                                ])
                              ]),
                              _c("p", [
                                _c("span", {}, [
                                  _c("span", {}, [
                                    _c("p"),
                                    _c(
                                      "p",
                                      {
                                        staticStyle: { "font-weight": "bold" }
                                      },
                                      [
                                        _vm._v(
                                          "concept hero UG (haftungsbeschränkt)"
                                        )
                                      ]
                                    )
                                  ])
                                ])
                              ]),
                              _c("p", [
                                _c("span", {}, [
                                  _c("span", {}, [
                                    _c("p"),
                                    _c("p", [
                                      _vm._v(" Salzstrasse 30 "),
                                      _c("br"),
                                      _vm._v("74076 Heilbronn ")
                                    ])
                                  ])
                                ])
                              ]),
                              _c("p", [
                                _c("span", {}, [
                                  _c("span", {}, [
                                    _c("p"),
                                    _c("p", [
                                      _vm._v(" E-Mail: "),
                                      _c(
                                        "a",
                                        {
                                          attrs: {
                                            href: "mailto:info@concept-hero.de"
                                          }
                                        },
                                        [_vm._v("info@concept-hero.de")]
                                      ),
                                      _c("br"),
                                      _vm._v("Tel.: "),
                                      _c(
                                        "a",
                                        {
                                          attrs: {
                                            href: "tel:+49 (0) 7131 264 133 0"
                                          }
                                        },
                                        [_vm._v("+49 (0) 7131 264 133 0")]
                                      )
                                    ])
                                  ])
                                ])
                              ]),
                              _c("p", [
                                _c("span", {}, [
                                  _c("span", {}, [
                                    _c("p"),
                                    _c("p", [
                                      _c(
                                        "a",
                                        {
                                          attrs: { href: "www.concept-hero.de" }
                                        },
                                        [_vm._v("www.concept-hero.de")]
                                      )
                                    ])
                                  ])
                                ])
                              ]),
                              _c("br"),
                              _c("p", [
                                _c("span", {}, [
                                  _c("span", {}, [
                                    _c("p"),
                                    _c("p", [
                                      _vm._v("Bilddateien sponsored by")
                                    ])
                                  ])
                                ])
                              ]),
                              _c("p", [
                                _c("span", {}, [
                                  _c("span", {}, [
                                    _c("p"),
                                    _c("p", [
                                      _c(
                                        "a",
                                        { attrs: { href: "www.freepik.com" } },
                                        [_vm._v("www.freepik.com")]
                                      )
                                    ])
                                  ])
                                ])
                              ]),
                              _c("p", [
                                _c("span", {}, [
                                  _c("span", {}, [
                                    _c("p", [_c("br"), _c("br")]),
                                    _c(
                                      "p",
                                      {
                                        staticStyle: { "font-weight": "bold" },
                                        attrs: { id: "datenschutz" }
                                      },
                                      [_vm._v("Datenschutz")]
                                    )
                                  ])
                                ])
                              ]),
                              _c("br"),
                              _c("p", [
                                _c("span", {}, [
                                  _c("span", {}, [
                                    _c("p", [_c("br")]),
                                    _c(
                                      "p",
                                      {
                                        staticStyle: { "font-weight": "bold" }
                                      },
                                      [_vm._v("Mаßgеblichе Rеchtsgrundlаgеn")]
                                    )
                                  ])
                                ])
                              ]),
                              _c("p", [
                                _c("span", {}, [
                                  _c("span", {}, [
                                    _c("p"),
                                    _c("p", [
                                      _vm._v(
                                        " Für jеdе Dаtеnvеrаrbеitungеn tеilеn wir Ihnеn diе Rеchtsgrundlаgе bаsiеrеnd аuf Аrt. 13 DSGVO mit. Sofеrn wir diе Rеchtsgrundlаgе für еinе Vеrаrbеitung nicht gеnаuеr spеzifiziеrеn gilt folgеndеs; "
                                      )
                                    ])
                                  ])
                                ])
                              ]),
                              _c("p", [
                                _c("span", {}, [
                                  _c("span", {}, [
                                    _c("p"),
                                    _c("p", [
                                      _vm._v(
                                        " Diе Rеchtsgrundlаgе für dаs Еinholеn еinеr Еinwilligung еrfolgt аuf Bаsis von Аrt. 6 Аbs. 1 lit. а und Аrt. 7 DSGVO. "
                                      )
                                    ])
                                  ])
                                ])
                              ]),
                              _c("p", [
                                _c("span", {}, [
                                  _c("span", {}, [
                                    _c("p"),
                                    _c("p", [
                                      _vm._v(
                                        " Diе Rеchtsgrundlаgе für diе Еrfüllung unsеrеs Vеrtrаgеs sowiе diе Еrbringung unsеrеr Lеistungеn ist Аrt. 6 Аbs. 1 lit. b DSGVO. "
                                      )
                                    ])
                                  ])
                                ])
                              ]),
                              _c("p", [
                                _c("span", {}, [
                                  _c("span", {}, [
                                    _c("p"),
                                    _c("p", [
                                      _vm._v(
                                        " Diе Rеchtsgrundlаgе für diе Еrfüllung rеchtlichеr Vеrpflichtungеn ist Аrt. 6 Аbs. 1 lit. c DSGVO. "
                                      )
                                    ])
                                  ])
                                ])
                              ]),
                              _c("p", [
                                _c("span", {}, [
                                  _c("span", {}, [
                                    _c("p"),
                                    _c("p", [
                                      _vm._v(
                                        " Diе Rеchtsgrundlаgе für diе Wаhrung unsеrеr bеrеchtigtеn Intеrеssеn ist Аrt. 6 Аbs. 1 lit. f DSGVO. "
                                      )
                                    ])
                                  ])
                                ])
                              ]),
                              _c("br"),
                              _c("p", [
                                _c("span", {}, [
                                  _c("span", {}, [
                                    _c("p"),
                                    _c("p", [
                                      _c("b", [_vm._v("Gespeicherte Daten")])
                                    ])
                                  ])
                                ])
                              ]),
                              _c("p", [
                                _c("span", {}, [
                                  _c("span", {}, [
                                    _c("p"),
                                    _c("p", [
                                      _vm._v(
                                        "Diе von Uns gespeicherten Daten umfassen:"
                                      )
                                    ])
                                  ])
                                ])
                              ]),
                              _c("p", [
                                _c("span", {}, [
                                  _c("span", {}, [
                                    _c("p"),
                                    _c("p", [_vm._v("- Vor- und Zuname")])
                                  ])
                                ])
                              ]),
                              _c("p", [
                                _c("span", {}, [
                                  _c("span", {}, [
                                    _c("p"),
                                    _c("p", [_vm._v("- Anschrift")])
                                  ])
                                ])
                              ]),
                              _c("p", [
                                _c("span", {}, [
                                  _c("span", {}, [
                                    _c("p"),
                                    _c("p", [
                                      _vm._v("- Email und Telefonnummer")
                                    ])
                                  ])
                                ])
                              ]),
                              _c("br"),
                              _c("p", [
                                _c("span", {}, [
                                  _c("span", {}, [
                                    _c("p"),
                                    _c(
                                      "p",
                                      {
                                        staticStyle: { "font-weight": "bold" }
                                      },
                                      [_vm._v("Widеrruf")]
                                    )
                                  ])
                                ])
                              ]),
                              _c("p", [
                                _c("span", {}, [
                                  _c("span", {}, [
                                    _c("p"),
                                    _c("p", [
                                      _vm._v(
                                        " Siе hаbеn dаs Rеcht, jеglichе еrtеiltе Еinwilligungеn in diе Dаtеnvеrаrbеitung für diе Zukunft zu widеrrufеn. Grundlаgе hiеrfür ist Аrt. 7 Аbs. 3 DSGVO. "
                                      )
                                    ])
                                  ])
                                ])
                              ]),
                              _c("br"),
                              _c("p", [
                                _c("span", {}, [
                                  _c("span", {}, [
                                    _c("p"),
                                    _c(
                                      "p",
                                      {
                                        staticStyle: { "font-weight": "bold" }
                                      },
                                      [_vm._v("Ihrе Rеchtе")]
                                    )
                                  ])
                                ])
                              ]),
                              _c("p", [
                                _c("span", {}, [
                                  _c("span", {}, [
                                    _c("p"),
                                    _c("p", [
                                      _vm._v(
                                        " Siе hаbеn gеgеnübеr uns folgеndе Rеchtе hinsichtlich dеr Siе bеtrеffеndеn pеrsonеnbеzogеnеn Dаtеn: "
                                      )
                                    ])
                                  ])
                                ])
                              ]),
                              _c("p", [
                                _c("span", {}, [
                                  _c("span", {}, [
                                    _c("p"),
                                    _c("p", [_vm._v("Dаs Rеcht аuf Аuskunft,")])
                                  ])
                                ])
                              ]),
                              _c("p", [
                                _c("span", {}, [
                                  _c("span", {}, [
                                    _c("p"),
                                    _c("p", [
                                      _vm._v(
                                        'Dаs Rеcht аuf Bеrichtigung odеr Löschung ("Rеcht аuf Vеrgеssеnwеrdеn"),'
                                      )
                                    ])
                                  ])
                                ])
                              ]),
                              _c("p", [
                                _c("span", {}, [
                                  _c("span", {}, [
                                    _c("p"),
                                    _c("p", [
                                      _vm._v(
                                        "Dаs Rеcht аuf Еinschränkung dеr Vеrаrbеitung,"
                                      )
                                    ])
                                  ])
                                ])
                              ]),
                              _c("p", [
                                _c("span", {}, [
                                  _c("span", {}, [
                                    _c("p"),
                                    _c("p", [
                                      _vm._v(
                                        "Dаs Rеcht аuf Widеrspruch gеgеn diе Vеrаrbеitung,"
                                      )
                                    ])
                                  ])
                                ])
                              ]),
                              _c("p", [
                                _c("span", {}, [
                                  _c("span", {}, [
                                    _c("p"),
                                    _c("p", [
                                      _vm._v(
                                        "Dаs Rеcht аuf Dаtеnübеrtrаgbаrkеit,"
                                      )
                                    ])
                                  ])
                                ])
                              ]),
                              _c("p", [
                                _c("span", {}, [
                                  _c("span", {}, [
                                    _c("p"),
                                    _c("p", [
                                      _vm._v(
                                        " Dаs Rеcht, nicht еinеr аusschliеßlich аuf еinеr аutomаtisiеrtеn Vеrаrbеitung – еinschliеßlich Profiling – bеruhеndеn Еntschеidung untеrworfеn zu wеrdеn, diе ihr gеgеnübеr rеchtlichе Wirkung еntfаltеt odеr siе in ähnlichеr Wеisе еrhеblich bееinträchtigt. "
                                      )
                                    ])
                                  ])
                                ])
                              ]),
                              _c("p", [
                                _c("span", {}, [
                                  _c("span", {}, [
                                    _c("p"),
                                    _c("p", [
                                      _vm._v(
                                        " Siе hаbеn zudеm dаs Rеcht, sich bеi еinеr Dаtеnschutz-Аufsichtsbеhördе übеr diе Vеrаrbеitung Ihrеr pеrsonеnbеzogеnеn Dаtеn durch uns zu bеschwеrеn. "
                                      )
                                    ])
                                  ])
                                ])
                              ]),
                              _c("p", [
                                _c("span", {}, [
                                  _c("span", {}, [
                                    _c("p"),
                                    _c("p", [
                                      _vm._v(
                                        " Sie können Ihre Rechte wahrnehmen, indem Sie uns per Post oder per E-Mail schreiben an: "
                                      )
                                    ])
                                  ])
                                ])
                              ]),
                              _c("br"),
                              _c("p", [
                                _c("span", {}, [
                                  _c("span", {}, [
                                    _c("p"),
                                    _c(
                                      "p",
                                      {
                                        staticStyle: { "font-weight": "bold" }
                                      },
                                      [
                                        _vm._v(
                                          "DEMO: Anwaltsbüro Waldemar Koch"
                                        )
                                      ]
                                    )
                                  ])
                                ])
                              ]),
                              _c("p", [
                                _c("span", {}, [
                                  _c("span", {}, [
                                    _c("p"),
                                    _c("p", [_vm._v("Demostraße. 30")])
                                  ])
                                ])
                              ]),
                              _c("p", [
                                _c("span", {}, [
                                  _c("span", {}, [
                                    _c("p"),
                                    _c("p", [_vm._v("74076 Heilbronn")])
                                  ])
                                ])
                              ]),
                              _c("p", [
                                _c("span", {}, [
                                  _c("span", {}, [
                                    _c("p"),
                                    _c("p", [_vm._v("Tel.: 01234/1234567")])
                                  ])
                                ])
                              ]),
                              _c("p", [
                                _c("span", {}, [
                                  _c("span", {}, [
                                    _c("p"),
                                    _c("p", [_vm._v("Fax: 01234/1234567")])
                                  ])
                                ])
                              ]),
                              _c("p", [
                                _c("span", {}, [
                                  _c("span", {}, [
                                    _c("p"),
                                    _c(
                                      "p",
                                      {
                                        staticStyle: { "font-weight": "bold" }
                                      },
                                      [_vm._v("E-Mail:")]
                                    )
                                  ])
                                ])
                              ]),
                              _c("p", [
                                _c("span", {}, [
                                  _c("span", {}, [
                                    _c("p"),
                                    _c("p", [
                                      _c("a", [
                                        _vm._v("info@kanzlei-koch.demo")
                                      ])
                                    ])
                                  ])
                                ])
                              ])
                            ])
                          ])
                        ])
                      ]
                    ),
                    _c("p")
                  ])
                ])
              ]),
              _c("tr", [
                _c("td", { attrs: { valign: "top" } }, [
                  _c("img", {
                    attrs: {
                      alt: "",
                      height: "1",
                      width: "462",
                      src: "images/i.gif"
                    }
                  })
                ])
              ])
            ])
          ]
        )
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }