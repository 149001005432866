<template>
    <div>
<!--        <div id="versionNumber">Version: 1.0.0f {{ versionNumber }}</div>-->
        <router-view></router-view>
    </div>
</template>

<script>
    import { mapState, mapMutations, mapActions } from "vuex";

    export default {
        name: 'Main',
        components: {
        },
        data() {
            return {
            };
        },
        computed: {
            ...mapState("auth", ["account"]),
            ...mapState("util", ["versionNumber"]),
        },
        methods: {
            ...mapActions("auth", ["getAccount"]),
            ...mapActions('util', [ 'requestSeverTime', 'requestVersionNumber' ]),
        },
        watch: {
            account(newValue) {
                if (newValue.accountName) {
                    this.requestSeverTime();
                    this.requestVersionNumber();
                }

                switch (newValue.role) {
                    case 'maintainer':
                        if(!this.$route.name.includes('verwaltung')) {
                            this.$router.replace({ name: 'verwaltung', query: this.$router.params }).catch(err => {});
                        }
                        break;
                    case 'pupil':
                        if(!this.$route.name.includes('schueler')) {
                            this.$router.replace({ name: 'schueler', query: this.$router.params}).catch(err => {});
                        }
                        break;
                    case 'teacher':
                        if(!this.$route.name.includes('lehrer')) {
                            this.$router.replace({ name: 'lehrer', query: this.$router.params}).catch(err => {});
                        }
                        break;
                    default:
                        this.$router.replace({ name: 'main' }).catch(err => {});
                        break;
                }
            },
        },
        mounted() {
            // this.getAccount();
            switch (this.account.role) {
                case 'maintainer':
                    if(!this.$route.name.includes('verwaltung')) {
                        this.$router.replace({ name: 'verwaltung', query: this.$router.params }).catch(err => {});
                    }
                    break;
                case 'pupil':
                    if(!this.$route.name.includes('schueler')) {
                        this.$router.replace({ name: 'schueler', query: this.$router.params}).catch(err => {});
                    }
                    break;
                case 'teacher':
                    if(!this.$route.name.includes('lehrer')) {
                        this.$router.replace({ name: 'lehrer', query: this.$router.params}).catch(err => {});
                    }
                    break;
                default:
                    this.$router.replace({ name: 'main' }).catch(err => {});
                    break;
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
#versionNumber {
    color: white;
    background-color: rgba(0, 0, 0, 0.4);
    position: fixed;
    top: 5px;
    left: 5px;
    z-index: 1;
}
</style>
