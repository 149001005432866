<template>
    <div>
        <v-row class="mx-0 mt-3 mb-4" >
            <div style="height: auto; width: 90%; margin: auto; border-radius: 15px; box-shadow: 1px 5px 5px silver;">

                <div style="background-color: #6995CD; border-radius: 15px 15px 0 0; font-size: larger; padding-top: 5px; padding-bottom: 20px; text-align: center; color: white;">

                    <div style="display: flex; width: 50%">
                        <img :src="suchIcon" style="height: 24px; margin-left: 24px; margin-top: 20px;
                        filter: brightness(0) saturate(100%) invert(100%) sepia(5%) saturate(19%) hue-rotate(65deg) brightness(104%) contrast(105%);" alt="Lupen-Icon"/>

                        <v-text-field
                            v-model="search"
                            label="Nach Schüler suchen"
                            single-line
                            dark
                            hide-details
                            style="width: 50%"
                            class="pl-6"
                        ></v-text-field>
                    </div>
                </div>
                <div style="display: flex; font-size: larger; font-family: Roboto; margin-top: 24px; margin-left: 26px" class="">
                    <img :src="pupilIcon" style="height: 24px; margin-right: 10px"/>
                    Schüler
                </div>
                <v-data-table
                    :headers="headers"
                    :items="list"
                    :search="search"
                    sort-by="lastName"
                    disable-pagination
                    hide-default-footer
                    fixed-header
                    :height="height"
                    class="ma-3"
                    locale="de-DE"
                    no-data-text="Keine Daten verfügbar"
                    no-results-text="Keine übereinstimmenden Aufzeichnungen gefunden"
                >
                    <template v-slot:item="row">
                        <tr>
                            <td>{{row.item.lastName }}</td>
                            <td> {{row.item.name}}</td>
<!--                            <td>{{formattedBirthdayForShow(row.item.birthday)}}</td>-->
                            <td>{{row.item.gender}}</td>
                            <td>
                                <v-btn class="editPupilButton mx-2" color="#EAEAEA" small @click="editUser(row.item);" elevation="1" light>
                                    <img :src="bearbeitenIcon"/>Schülerprofil bearbeiten
                                </v-btn>
                            </td>
                        </tr>
                    </template>
                </v-data-table>

            <v-overlay absolute :value="showLoadingOverlay"
                       style="width: 100% !important;">
                <v-progress-circular v-if="showLoadingOverlay" indeterminate size="100"/>
            </v-overlay>

            </div>

        </v-row>

        <!-- Schüler anlegen / bearbeiten -->
        <v-row class="mx-0 my-3" id="edit">
            <div class=" editContainer">
                <v-row class="mx-0" style="background-color: #6995CD; border-radius: 15px 15px 0 0">

                    <v-col style="color: white; font-size: larger;" class="ma-1 ml-5">
                        <v-avatar style="width: 24px; " color="rgba(255,255,255,0.8)">
                            <img :src="pupilIcon"  class=""/>
                        </v-avatar>
                         Schüler {{ oldProfile ? 'bearbeiten' : 'anlegen'}}
                    </v-col>
                    <v-col class="mr-6" style="display: flex; justify-content: flex-end;">
                        <v-tooltip top :open-on-click="false" :open-on-hover="true"
                                   content-class="infoTextToolTip">
                            <template v-slot:activator="{ on, attrs }">
                                <span v-bind="attrs" v-on="on" class="my-auto">
                                  <v-btn v-if="!oldProfile && debugMode"  color="#FFFFFF40" x-small class="mainFunctionButtons ml-3 my-auto"
                                         @click="clickExelUploadButton" v-bind="attrs" v-on="on">
                                        <img :src="hochladen">
                                </v-btn>
                                </span>
                            </template>
                            <span>Hier können Sie einen Excel von Schülern hochladen.</span>
                        </v-tooltip>

                        <v-btn color="#FFFFFF40" x-small class="mainFunctionButtons ml-3 my-auto"
                               :disabled="!oldProfile" @click="deleteDialog = true">
                            <img :src="loeschIcon">
                        </v-btn>
                        <v-tooltip top :open-on-click="false" :open-on-hover="true"
                                content-class="infoTextToolTip">
                            <template v-slot:activator="{ on, attrs }">
                                <span v-bind="attrs" v-on="on" class="my-auto">
                                    <v-btn color="#FFFFFF40" x-small class="mainFunctionButtons ml-3 "
                                           disabled @click="pdf" >
                                        <!-- :disabled="!(togglePDFDownload)" -->
                                        <img :src="pdfDocIcon">
                                    </v-btn>
                                </span>
                            </template>
                            <span>Aktuell noch nicht verfügbar.</span>
                        </v-tooltip>
                        <v-tooltip top nudge-right="100" :open-on-click="true" :open-on-hover="false"
                                content-class="infoTextToolTip">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn color="#FFFFFF40" x-small class="mainFunctionButtons ml-3 my-auto"
                                       v-bind="attrs" v-on="on">
                                    <img :src="infoIcon">
                                </v-btn>
                            </template>
                            <span>Hier können Sie Schüler und Erziehungsberechtigte anlegen oder bearbeiten.</span>
                        </v-tooltip>
                        <v-btn class="mainControlButtons ml-3 my-auto pa-5" color="#BDBDBD50" @click="stopEdit" dark elevation="0">
                            <span>Abbrechen</span>
                        </v-btn>
                        <v-btn class="mainControlButtons ml-3 my-auto pa-5" color="#69CD70" @click="openDialog" dark elevation="4">
                            <span>Speichern</span>
                        </v-btn>
                    </v-col>
                </v-row>
                <div class="mx-2 mb-2">
                    <v-row>
                        <v-col class="mx-5">
                            <v-row>
                                <v-col>
                                    <div class="font-weight-bold" style="color: rgba(0, 0, 0, 0.6)">
                                        Persönliche Daten
                                    </div>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="5">
                                    Vorname:
                                </v-col>
                                <v-col cols="7">
                                    <input v-model="name" type="text" class="input" :class="{ errorInput: fields.name }"
                                           @change="() => { suggestLoginId(oldProfile); }"
                                    >
                                </v-col>
                            </v-row>
                            <v-row v-if="fields.name && name.length === 0" dense>
                                <v-col class="pl-2; pt-0" style="margin-top: -10px">
                                    <span style="color: red; font-size: 12px">Pflichtfeld</span>
                                </v-col>
                            </v-row>
                            <v-row v-else-if="fields.name" dense>
                                <v-col class="pl-2; pt-0" style="margin-top: -10px">
                                    <span style="color: red; font-size: 12px">Name darf keine Zahlen erhalten.</span>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="5">
                                    Nachname:
                                </v-col>
                                <v-col cols="7">
                                    <input v-model="lastName" type="text" class="input" :class="{ errorInput: fields.lastName }"
                                       @change="() => { suggestLoginId(oldProfile); }"
                                    >
                                </v-col>
                            </v-row>
                            <v-row v-if="fields.lastName && lastName.length === 0" dense>
                                <v-col class="pl-2; pt-0" style="margin-top: -10px">
                                    <span style="color: red; font-size: 12px">Pflichtfeld</span>
                                </v-col>
                            </v-row>
                            <v-row v-else-if="fields.lastName" dense>
                                <v-col class="pl-2; pt-0" style="margin-top: -10px">
                                    <span style="color: red; font-size: 12px">Name darf keine Zahlen erhalten.</span>
                                </v-col>
                            </v-row>
<!--                            <v-row>-->
<!--                                <v-col cols="5">-->
<!--                                    Geburtstag:-->
<!--                                </v-col>-->
<!--                                <v-col cols="7">-->
<!--                                    <input v-model="birthday" type="date" class="input" :class="{ errorInput: fields.birthday }"-->
<!--                                           @change="validateInput"-->
<!--                                    >-->
<!--                                </v-col>-->
<!--                            </v-row>-->
                            <v-row>
                                <v-col cols="5">
                                    Geschlecht:
                                </v-col>
                                <v-col cols="7">
                                    <select v-model="gender" class="input" :class="{ errorInput: fields.gender }"
                                            @change="validateInput"
                                    >
                                        <option disabled selected value> Auswählen</option>
                                        <option value="m">Männlich</option>
                                        <option value="w">Weiblich</option>
                                        <option value="d">Divers</option>
                                    </select>
                                </v-col>
                            </v-row>
                            <v-row v-if="fields.gender" dense>
                                <v-col class="pt-0" style="margin-top: -10px">
                                    <span style="color: red; font-size: 12px">Pflichtfeld</span>
                                </v-col>
                            </v-row>
                            <v-row>
                            </v-row>
                        </v-col>
                        <v-col class="mx-5">
                            <v-row dense>
                                <v-col>
                                    <div class="font-weight-bold" style="color: rgba(0, 0, 0, 0.6)">
                                        Schulisches
                                    </div>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="5">
                                Gruppen:
                                </v-col>
                                <v-col cols="7">
                                    <v-row v-for="(item, index) in groups" :key="index">
                                        <div class="px-3 py-1 mb-1" style="min-width: 200px; background-color: #EAEAEA; border-radius: 8px">{{item.name}}</div>

                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="5">
                                    Einfache Ansicht:
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon
                                                    v-bind="attrs"
                                                    v-on="on"
                                            >
                                                fa-question-circle
                                            </v-icon>
                                        </template>
                                        <v-row class="ml-0">
                                            Einfache Ansicht:
                                        </v-row>
                                        <v-row>
                                            <img style="height: 100px" :src="easy">
                                        </v-row>
                                        <v-row  class="ml-0">
                                            Normale Ansicht:
                                        </v-row>
                                        <v-row>
                                            <img style="height: 100px" :src="hard">
                                        </v-row>
                                    </v-tooltip>
                                </v-col>
                                <v-col cols="7">
                                    <v-checkbox class="mt-0 pt-0" v-model="babyView" > </v-checkbox>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col  cols="12" sm="12" md="3" class="mx-5">


                            <v-row dense>
                                <v-col>
                                    <div class="font-weight-bold" style="color: rgba(0, 0, 0, 0.6)">
                                        Technisches
                                    </div>
                                </v-col>
                                <v-col>
                                    <v-btn @click="makePassword" class="generatePasswordButton" elevation="0" icon>
                                        <img :src="aktualisierenIcon">Passwort generieren
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="5">
                                    Login-ID*:
                                </v-col>
                                <v-col cols="7" @click="editLoginId = true;">
                                    <input
                                       v-model="userName"
                                       type="text"
                                       class="input"
                                       :class="{ errorInput: fields.userName }"
                                       :disabled="!editLoginId || oldProfile"
                                       @change="validateInput"
                                    >
                                </v-col>
                            </v-row>
                            <v-row v-if="fields.userName" dense>
                                <v-col style="padding-top: 0; margin-top: -10px">
                                    <span style="color: red; font-size: 12px">Login-ID darf nur Zahlen und Buchstaben erhalten.</span>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="5">
                                    Passwort:
                                </v-col>
                                <v-col cols="7" @click="editPassword = true">
                                    <input
                                       :type="editPassword ? 'text' : (oldProfile && !newPassword)  ? 'password' : 'text'"
                                       :disabled="!editPassword" v-model="password" class="input"
                                       :class="{ errorInput: fields.password }"
                                       @change="validateInput"
                                    >
                                </v-col>
                            </v-row>
                            <v-row v-if="fields.password" dense>
                                <v-col style="padding-top: 0; margin-top: -10px">
                                    <span style="color: red; font-size: 12px">Passwort erfüllt Richtlinie nicht.</span>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" class="pt-0">
                                    <p class="mb-2" style="color: #808080;">Muss mind. 8 Zeichen lang sein und einen Buchstaben und eine Zahl enthalten.</p>
                                </v-col>
                            </v-row>
<!--                            <v-row >-->
<!--                                <v-col cols="5">-->
<!--                                    QR-Passwort:-->
<!--                                </v-col>-->
<!--                                <v-col cols="7">-->
<!--                                    <input v-model="qrPassword" type="text" class="input">-->
<!--                                </v-col>-->
<!--                            </v-row>-->
                            <v-row>
                                <v-col cols="7">
                                    <v-btn @click="makePassword" v-if="false" class="generatePasswordButton" elevation="0" icon>
                                        <img :src="aktualisierenIcon">Passwort generieren
                                    </v-btn>
                                </v-col>
                                <v-col cols="5">
                            <v-btn class="" style="max-height: 25px;max-width: 100%; width: 100%; border-radius: 8px; background-color: rgba(230,34,30,0.41)" color="" v-if="false" @click="deleteDialog = true"  small light>
                                Löschen
                            </v-btn>
                                </v-col>
                            </v-row>
                            <v-spacer class="mt-1" />
<!--                            <v-row class="ma-0">-->
<!--                                <v-col cols="8" class="pl-0">-->
<!--                                    <v-btn block color="success" @click="openDialog" small light>-->
<!--                                        Speichern-->
<!--                                    </v-btn>-->
<!--                                </v-col>-->
<!--                                <v-col cols="4" class="pl-0">-->
<!--                                    <v-btn block style="margin-left: auto;margin-right: 0"  @click="stopEdit" color="#EAEAEA" small light>-->
<!--                                        <v-icon>fas fa-times</v-icon>-->
<!--                                    </v-btn>-->
<!--                                </v-col>-->
<!--                            </v-row>-->
                        </v-col>
                    </v-row>
<!--                    Eltern Eintrag-->
<!--                    <v-row>-->
<!--                        <v-col class="mx-5">-->
<!--                            <v-row>-->
<!--                                <v-col>-->
<!--                                    <div class="font-weight-bold" style="color: rgba(0, 0, 0, 0.6)">-->
<!--                                        Daten des Erziehungsberechtigten-->
<!--                                    </div>-->
<!--                                </v-col>-->
<!--                            </v-row>-->
<!--                            <v-row>-->
<!--                                <v-col cols="5">-->
<!--                                    Vorname:-->
<!--                                </v-col>-->
<!--                                <v-col cols="7">-->
<!--                                    <input v-model="name" type="text" class="input" :class="{ errorInput: fields.name }"-->
<!--                                           @change="validateInput"-->
<!--                                    >-->
<!--                                </v-col>-->
<!--                            </v-row>-->
<!--                            <v-row>-->
<!--                                <v-col cols="5">-->
<!--                                    Nachname:-->
<!--                                </v-col>-->
<!--                                <v-col cols="7">-->
<!--                                    <input v-model="lastName" type="text" class="input" :class="{ errorInput: fields.lastName }"-->
<!--                                           @change="validateInput"-->
<!--                                    >-->
<!--                                </v-col>-->

<!--                            </v-row>-->
<!--                            <v-row>-->
<!--                                <v-col cols="5">-->
<!--                                    Geburtstag:-->
<!--                                </v-col>-->
<!--                                <v-col cols="7">-->
<!--                                    <input v-model="birthday" type="date" class="input" :class="{ errorInput: fields.birthday }"-->
<!--                                           @change="validateInput"-->
<!--                                    >-->
<!--                                </v-col>-->
<!--                            </v-row>-->
<!--                            <v-row>-->
<!--                                <v-col cols="5">-->
<!--                                    Geschlecht:-->
<!--                                </v-col>-->
<!--                                <v-col cols="7">-->
<!--                                    <select v-model="gender" class="input" :class="{ errorInput: fields.gender }"-->
<!--                                            @change="validateInput"-->
<!--                                    >-->
<!--                                        <option disabled selected value> Auswählen</option>-->
<!--                                        <option value="m">Männlich</option>-->
<!--                                        <option value="w">Weiblich</option>-->
<!--                                        <option value="d">Divers</option>-->
<!--                                    </select>-->
<!--                                </v-col>-->
<!--                            </v-row>-->
<!--                            <v-row>-->
<!--                                <v-col cols="5">-->

<!--                                </v-col>-->
<!--                                <v-col cols="7">-->

<!--                                </v-col>-->

<!--                            </v-row>-->
<!--                        </v-col>-->
<!--                        <v-col class="mx-5">-->
<!--                            <v-row>-->
<!--                                <v-col>-->
<!--                                    <div class="font-weight-bold" style="color: rgba(0, 0, 0, 0.6)">-->
<!--                                        Erreichbar unter-->
<!--                                    </div>-->
<!--                                </v-col>-->
<!--                            </v-row>-->
<!--                            <v-row>-->
<!--                                <v-col cols="5">-->
<!--                                    E-mail:-->
<!--                                </v-col>-->
<!--                                <v-col cols="7">-->
<!--                                    <input placeholder="E-mail" type="text" class="input"-->
<!--                                    >-->
<!--                                </v-col>-->
<!--                            </v-row>-->
<!--                            <v-row>-->
<!--                                <v-col cols="5">-->
<!--                                    Telefon:-->
<!--                                </v-col>-->
<!--                                <v-col cols="7">-->
<!--                                    <input placeholder="Telefon" type="text" class="input"-->
<!--                                    >-->
<!--                                </v-col>-->
<!--                            </v-row>-->
<!--                        </v-col>-->
<!--                        <v-col  cols="12" sm="12" md="3" class="mx-5">-->


<!--                            <v-row>-->
<!--                                <v-col>-->
<!--                                    <div class="font-weight-bold" style="color: rgba(0, 0, 0, 0.6)">-->
<!--                                       Technisches-->
<!--                                    </div>-->
<!--                                </v-col>-->
<!--                            </v-row>-->
<!--                            <v-row>-->
<!--                                <v-col cols="5">-->
<!--                                    Login-Id*:-->
<!--                                </v-col>-->
<!--                                <v-col cols="7">-->
<!--                                    <input-->
<!--                                            v-model="userName"-->
<!--                                            type="text"-->
<!--                                            class="input"-->
<!--                                            :class="{ errorInput: fields.userName }"-->
<!--                                            @change="validateInput"-->
<!--                                    >-->
<!--                                </v-col>-->
<!--                            </v-row>-->
<!--                            <v-row >-->
<!--                                <v-col cols="5">-->
<!--                                    Passwort:-->
<!--                                </v-col>-->
<!--                                <v-col cols="7">-->
<!--                                    <input :type="(oldProfile && !newPassword)  ? 'password' : 'text'"  v-model="password" disabled class="input">-->
<!--                                </v-col>-->
<!--                            </v-row>-->
<!--                            <v-row >-->
<!--                                <v-col cols="5">-->
<!--                                    QR-Passwort:-->
<!--                                </v-col>-->
<!--                                <v-col cols="7">-->
<!--                                    <input v-model="qrPassword" type="text" class="input">-->
<!--                                </v-col>-->
<!--                            </v-row>-->
<!--                            <v-btn @click="makePassword" style="max-width: 100%; width: 100%; text-transform: inherit; border-radius: 5px" elevation="1" color="#EAEAEA" light>-->
<!--                                Passwort generieren-->
<!--                            </v-btn>-->
<!--                            <v-btn class="mt-3" style="max-width: 100%; width: 100%" color="error" v-if="oldProfile" @click="deleteDialog = true"  small light>-->
<!--                                Profil löschen-->
<!--                            </v-btn>-->
<!--                            <v-spacer class="mt-1" />-->
<!--&lt;!&ndash;                            <v-row class="ma-0">&ndash;&gt;-->
<!--&lt;!&ndash;                                <v-col cols="8" class="pl-0">&ndash;&gt;-->
<!--&lt;!&ndash;                                    <v-btn block color="success" @click="openDialog" small light>&ndash;&gt;-->
<!--&lt;!&ndash;                                        Speichern&ndash;&gt;-->
<!--&lt;!&ndash;                                    </v-btn>&ndash;&gt;-->
<!--&lt;!&ndash;                                </v-col>&ndash;&gt;-->
<!--&lt;!&ndash;                                <v-col cols="4" class="pl-0">&ndash;&gt;-->
<!--&lt;!&ndash;                                    <v-btn block style="margin-left: auto;margin-right: 0"  @click="stopEdit" color="#EAEAEA" small light>&ndash;&gt;-->
<!--&lt;!&ndash;                                        <v-icon>fas fa-times</v-icon>&ndash;&gt;-->
<!--&lt;!&ndash;                                    </v-btn>&ndash;&gt;-->
<!--&lt;!&ndash;                                </v-col>&ndash;&gt;-->
<!--&lt;!&ndash;                            </v-row>&ndash;&gt;-->
<!--                        </v-col>-->
<!--                    </v-row>-->
<!--                    <v-row>-->
<!--                        <v-col cols="9">-->
<!--                            <div style="display: flex" class="mx-3">-->
<!--                                <v-btn fab color="#EAEAEA" class="mx-4" small><img :src="infoIcon" style="width: 24px; display: flex" class="" alt="Info Icon"/></v-btn>-->

<!--                                <div>Hier können Sie Schüler und Erziehungsberechtigte anlegen oder bearbeiten.-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </v-col>-->
<!--                        <v-col cols="2" class="pl-0" style="max-width: 200px!important; margin-left: -5px">-->
<!--                            <v-btn block  @click="stopEdit" color="#BDBDBD" small light>-->
<!--                                Abbrechen-->
<!--                            </v-btn>-->
<!--                        </v-col>-->
<!--                        <v-col cols="2" class="pl-0" style="max-width: 200px!important;">-->
<!--                            <v-btn block  color="#3BAA69" @click="openDialog" small light>-->
<!--                                Speichern-->
<!--                            </v-btn>-->
<!--                        </v-col>-->
<!--                    </v-row>-->

                    <v-row>
<!--                        <v-row>-->
<!--                            <v-col cols="6" >-->
<!--                                <div style="display: flex" class="mx-3">-->
<!--                                    <v-btn fab color="#EAEAEA" class="mx-4" small><img :src="infoIcon" style="width: 24px; display: flex" class="" alt="Info Icon"/></v-btn>-->

<!--                                    <div>Hier können Sie Schüler und Erziehungsberechtigte anlegen oder bearbeiten.-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                            </v-col>-->

<!--                            <v-col cols="3" class="pl-0">-->
<!--                            </v-col>-->
<!--                            <div style="min-width: 80px!important;"></div>-->


<!--                            <v-col cols="1" class="pl-0">-->
<!--                                <v-btn block style="color: white"  @click="stopEdit" color="#BDBDBD" small light>-->
<!--                                    Abbrechen-->
<!--                                </v-btn>-->
<!--                            </v-col>-->
<!--                            <v-col cols="1" class="pl-0">-->
<!--                                <v-btn block style="color: white" color="#3BAA69" @click="openDialog" small light>-->
<!--                                    Speichern-->
<!--                                </v-btn>-->
<!--                            </v-col>-->

<!--                        </v-row>-->
                    </v-row>
                </div>



            </div>

        </v-row>


        <v-snackbar bottom v-model="snackbar" color="error" timeout="3000">
            {{snackbarText}}
        </v-snackbar>

        <v-snackbar bottom v-model="snackbarSave" color="success" timeout="3000">
            Gespeichert!
        </v-snackbar>


        <!--        Delete dialog-->
        <v-dialog v-model="deleteDialog" persistent max-width="400">
            <v-card>
                <v-card-title class="headline">Benutzer löschen?</v-card-title>
                <v-card-text>
                    Sind sie sicher, dass sie den Benutzer löschen möchten?
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="" text @click="deleteDialog = false">Abbrechen</v-btn>
                    <v-btn color="red red-1" text @click="() => {this.deleteUser(); this.deleteDialog = false}">Löschen</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

<!--        Create new Account dialog-->
        <v-dialog v-model="dialog" persistent max-width="400">
            <v-card>
                <v-card-title class="headline">Accountdaten herunterladen?</v-card-title>
                <v-card-text>
                    Ein Account wurde mit folgenden Daten angelegt.An erster Stelle sehen Sie das Passwort, welches für den neuen Benutzer generiert wurde. Bitte notieren Sie dieses und händigen Sie es an den Benutzer aus.

                    <br> <br>
                    Dieses Passwort wird später nicht mehr angezeigt und kann auch nicht wiederhergestellt werden. Im Zweifelsfall müssen Sie dem Benutzer ein neues Passwort zuweisen, wenn er sich nicht mehr einloggen kann.

                    <br> <br>
                    <div class="ml-3">
                        <v-row>
                            Passwort: {{password}}
                        </v-row>
<!--                        <v-row>-->
<!--                            QR-Passwort: {{qrPassword}}-->
<!--                        </v-row>-->
                        <v-row>
                            Login-ID: {{userName}}
                        </v-row>
                        <v-row>
                            Vorname: {{name}}
                        </v-row>
                        <v-row>
                            Nachname: {{lastName}}
                        </v-row>
<!--                        <v-row>-->
<!--                            Geburtstag: {{birthday}}-->
<!--                        </v-row>-->
                        <v-row>
                            Geschlecht: {{gender}}
                        </v-row>
                        <v-row class="mt-1" align="center" justify="center" >
                            <div style="width: 200px">
                                <qrcode-vue ref="qrcodecontainer" v-show="false" :value="qrCodeData" size="200"></qrcode-vue>
                                <v-row style="width: 200px; text-align-last: center;" class="ma-0">
                                    <v-col cols="12" style="" class="pa-0">
                                        <v-btn @click="pdf()" class="" style="">QR Download</v-btn>
                                    </v-col>
                                </v-row>
                                <v-row style="width: 200px; text-align-last: center;" class="ma-0">

                                    <v-col cols="" class="pa-0 mt-2">
                                        <v-btn @click="kuhR()" class="" style="">Kuh-R Bastelvorlage</v-btn>
                                    </v-col>
                                </v-row>

                            </div>
                        </v-row>
                    </div>

                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" dark @click="closeDialogs">Fertig</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!--        Edit Account dialog-->
        <v-dialog v-model="dialog2" persistent max-width="400">
            <v-card>
                <v-card-title class="headline">Accountdaten herunterladen?</v-card-title>
                <v-card-text>
                    Ein Account wurde mit folgenden Daten geändert.An erster Stelle sehen Sie das Passwort, welches für den neuen Benutzer generiert wurde. Bitte notieren Sie dieses und händigen Sie es an dem Benutzer aus.

                    <br> <br>
                    Dieses Passwort wird später nicht mehr angezeigt und kann auch nicht wiederhergestellt werden. Im Zweifelsfall müssen Sie dem Benutzer ein neues Passwort zuweisen, wenn er sich nicht mehr einloggen kann.

                    <br> <br>
                    <div class="ml-3">
                        <v-row>
                            Passwort: {{password}}
                        </v-row>
<!--                        <v-row>-->
<!--                            QR-Passwort: {{qrPassword}}-->
<!--                        </v-row>-->
                        <v-row>
                            Login-ID: {{userName}}
                        </v-row>
                        <v-row>
                            Vorname: {{name}}
                        </v-row>
                        <v-row>
                            Nachname: {{lastName}}
                        </v-row>
<!--                        <v-row>-->
<!--                            Geburtstag: {{birthday}}-->
<!--                        </v-row>-->
                        <v-row>
                            Geschlecht: {{gender}}
                        </v-row>
                        <v-row class="mt-1" align="center" justify="center">
                            <div style="width: 200px">
                                <qrcode-vue ref="qrcodecontainer" v-show="false" :value="qrCodeData" size="200"></qrcode-vue>
                                <v-row style="width: 200px; text-align-last: center;" class="ma-0">
                                    <v-col cols="12" style="" class="pa-0">
                                        <v-btn @click="pdf()" class="" style="">QR Download</v-btn>
                                    </v-col>
                                </v-row>
                                <v-row style="width: 200px; text-align-last: center;" class="ma-0">

                                    <v-col cols="" class="pa-0 mt-2">
                                        <v-btn @click="kuhR()" class="" style="">Kuh-R Bastelvorlage</v-btn>
                                    </v-col>
                                </v-row>

                            </div>
                        </v-row>
                    </div>

                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" @click="closeDialogs" dark>Fertig</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

<!--        <v-dialog v-model="dialog3" persistent max-width="400">-->
<!--            <v-card>-->
<!--                <v-card-title class="headline">Account speichern?</v-card-title>-->
<!--                <v-card-text>-->
<!--                    Ein Account wird mit folgenden Daten geändert.-->
<!--                    <br> <br>-->
<!--                    <div class="ml-3">-->
<!--                        <v-row>-->
<!--                            Login-ID: {{userName}}-->
<!--                        </v-row>-->
<!--                        <v-row>-->
<!--                            Vorname: {{name}}-->
<!--                        </v-row>-->
<!--                        <v-row>-->
<!--                            Nachname: {{lastName}}-->
<!--                        </v-row>-->
<!--&lt;!&ndash;                        <v-row>&ndash;&gt;-->
<!--&lt;!&ndash;                            Geburtstag: {{birthday}}&ndash;&gt;-->
<!--&lt;!&ndash;                        </v-row>&ndash;&gt;-->
<!--                        <v-row>-->
<!--                            Geschlecht: {{gender}}-->
<!--                        </v-row>-->
<!--                    </div>-->

<!--                </v-card-text>-->
<!--                <v-card-actions>-->
<!--                    <v-spacer></v-spacer>-->
<!--                    <v-btn color="red red-1" text @click="dialog3 = false">Abbrechen</v-btn>-->
<!--                    <v-btn color="green darken-1" text @click="patchUser">Speichern</v-btn>-->
<!--                </v-card-actions>-->
<!--            </v-card>-->
<!--        </v-dialog>-->

        <qrcode-vue ref="hiddenqrcodecontainer" v-show="false" :value="qrCodeData" size="200"></qrcode-vue>

        <input
                @change="() => exelUploadInputChange()"
                id="exelUploadInput"
                type="file"
                hidden
                ref="exelUploadInput"
        />

    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
import suchIcon from '../../assets/Icons/suche-26.svg'
import pupilIcon from "../../assets/Icons/profil-schueler.svg"
import hochladen from "../../assets/Icons/hochladen-15.svg"
import bearbeitenIcon from "../../assets/Icons/bearbeiten-normal-weiß-41.svg";
import infoIcon from "../../assets/Icons/ausrufezeichen-62.svg";
import loeschIcon from '../../assets/Icons/papierkorb-109.svg';
import pdfDocIcon from '../../assets/Icons/PDF-65.svg'
import pdfFile from '../../assets/PDF/Schueler-QR-Vorlage.pdf'
import kuhRFile from '../../assets/PDF/Faltkuh.pdf'
import aktualisierenIcon from '../../assets/Icons/aktualisieren-107.svg'
import roboto from '../../assets/Fonts/Roboto-Regular.ttf'
import robotoBold from '../../assets/Fonts/Roboto-Bold.ttf'

import easy from '../../assets/einfache-Ansicht.svg'


    import hard from '../../assets/normale-Ansicht.svg'


    import QrcodeVue from 'qrcode.vue';

import { degrees, PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import * as xlsx from 'xlsx';

import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { compress } from 'shrink-string';
import fontkit from '@pdf-lib/fontkit';

    export default {
    name: "Schueler",
    components: {
        QrcodeVue,
    },
    data() {
        return {
            showLoadingOverlay: false,
            newPassword: null,
            oldProfile: null,
            dialog: false,
            dialog2: false,
            dialog3: false,
            snackbar: false,
            snackbarText: '',
            showInfo: false,
            snackbarSave: false,
            debugMode: false,

            name: '',
            lastName: '',
            birthday: '',
            gender: '',
            babyView: false,
            userName: '',
            password: '',
            qrPassword: '',
            groups: [],

            editPassword: false,
            editLoginId: false,
            deleteDialog: false,
            fields: {
                name: false,
                lastName: false,
                birthday: false,
                gender: false,
                userName: false,
                password: false,
            },
            height: 0,
            search: '',
            headers: [
                { text: 'Name', value: 'lastName' },
                { text: 'Vorname', value: 'name' },
                // { text: 'Geburtstag', value: 'birthday' },
                { text: 'Geschlecht', value: 'gender' },
                { text: '', value: 'name', sortable: false}
            ],
            list: [
            ],
            suchIcon,
            pupilIcon,
            bearbeitenIcon,
            pdfFile,
            kuhRFile,
            easy,
            hard,
            roboto,
            robotoBold,
            infoIcon,
            loeschIcon,
            pdfDocIcon,
            aktualisierenIcon,
            hochladen,
            qrCodeData: "{ name: '', pw: '' }",
        };
    },
    props: {

    },
    async created() {
        this.height = window.innerHeight * 0.3
    },
    async mounted() {
        this.showLoadingOverlay = true;
        this.makePassword();
        this.requestPupils();

        if (this.$route.query.debug) {
            this.debugMode = true;
        }
    },
    computed: {
        ...mapGetters("pupils", ['getPupilListStatus']),
        isUsernameFree() {
            for (let i = 0; i < this.list.length; i++) {
                let pupil = this.list[i];
                if (this.userName === pupil.account.accountName) {
                    return false;
                }
            }

            return true;
        },
        togglePDFDownload() {
            if (!this.validateInput() || (this.oldProfile && !this.newPassword)) {
                return false;
            }

            return true;
        },
    },
    watch: {
        userName: function () {
            this.qrcodeString();
        },
        password: function () {
            this.qrcodeString();
        },
        getPupilListStatus(newVal, oldVal) {
            if (newVal && !oldVal) {
                this.requestPupils();
            }
        },
    },
    methods: {
        ...mapActions("pupils", ["createPupil","getPupils","editPupil", "deletePupile", 'setPupilListStatus']),
        ...mapActions("groups", ["getGroup"]),
        ...mapActions("account", ["isLoginIdAvailable"]),
        async exelUploadInputChange() {
            this.showLoadingOverlay = true;
            const input = this.$refs.exelUploadInput;
            const file = input.files[0];
            if (file) {
                if(file.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                    this.snackbarText = 'Bitte Dateiformat überprüfen! (".xlsx")';
                    this.snackbar = true;
                    this.showLoadingOverlay = false;
                    return
                }
                let reader = new FileReader();
                reader.readAsArrayBuffer(file);
                reader.onload = async (e) => {
                    let data = new Uint8Array(reader.result);
                    let workbook = xlsx.read(data, {type: 'array'});
                    let dateArray = [];
                    let failArray = [];
                    workbook.SheetNames.forEach(sheet => {
                        let rowObject = xlsx.utils.sheet_to_json(workbook.Sheets[sheet]);
                        dateArray = rowObject;
                    });

                    dateArray.forEach((el) => {
                        if(!el.vorname || !el.nachname || !(el.geschlecht === 'w' || el.geschlecht === 'm' || el.geschlecht === 'd')) {
                            el['fehler'] = "Daten unvollständig oder fehlerhaft!";
                            failArray.push(el)
                        }
                    });
                    dateArray = dateArray.filter(el => !el.fehler);
                    let zip = new  JSZip();
                    const sleep = ms => new Promise(resolve => setTimeout(resolve,ms));
                    for(let x = 0; x < dateArray.length; x++) {
                        // sleep(500);
                        let data = {
                            name: dateArray[x].vorname,
                            lastName: dateArray[x].nachname,
                            gender: dateArray[x].geschlecht,
                            note: '',
                            accountName: dateArray[x].loginid ? dateArray[x].loginid : ((dateArray[x].vorname.trim() + dateArray[x].nachname.trim().slice(0, 1)).toLowerCase()),
                            babyView: false,
                            password: this.password,
                            qrPassword: this.qrPassword,
                        };
                        let response = await this.createPupil(data);
                        if(Number.isInteger(response)) {
                            if(response === 409) {
                                dateArray[x]['fehler'] = "Login-ID schon belegt"
                                failArray.push(dateArray[x])
                            } else {
                                dateArray[x]['fehler'] = "Ein unerwarteter Fehler ist aufgetretten!";
                                failArray.push(dateArray[x])
                            }
                        } else {
                            const compressedName = await compress(data.accountName);
                            const compressedPW = await compress(this.password);
                            const compressedString = JSON.stringify({name: compressedName, pw: compressedPW});
                            this.qrCodeData = compressedString;
                            zip.file((dateArray[x].loginid ? dateArray[x].loginid : ((dateArray[x].vorname.trim() + dateArray[x].nachname.trim().slice(0, 1)).toLowerCase())) + '.pdf' , await this.getPDFBytes(data));
                            this.list.push(response);
                            this.setPupilListStatus(true);
                            this.stopEdit();
                            this.makePassword();
                        }
                    }
                    if(failArray.length > 0) {
                        let blob = new Blob([JSON.stringify(failArray)], {type: "text/plain;charset=utf-8"});

                        zip.file('Fehler.txt', blob);
                    }
                    zip.generateAsync({type:"blob"})
                        .then(function(content) {
                            // see FileSaver.js
                            saveAs(content, "Daten.zip");
                        });
                    this.showLoadingOverlay = false;
                };

            }
        },
        clickExelUploadButton() {
            document.getElementById("exelUploadInput").click();
        },
        async openDialog() {
            if (this.editPassword) {
                this.newPassword = this.password;
            }

            if (!this.validateInput()) {
                this.snackbarText = 'Bitte alle Daten eintragen!';
                this.snackbar = true;
                this.showLoadingOverlay = false;
                return;
            }

            if (!this.oldProfile) {
                this.saveUser();
                this.dialog = true;
            } else if (!this.newPassword) {
                await this.patchUser();
                this.stopEdit();
            } else {
                this.patchUser();
                this.dialog2 = true;
            }
        },
        stopEdit() {
            this.oldProfile = null;
            this.name = '';
            this.lastName = '';
            this.birthday = '';
            this.babyView = false;
            this.gender = '';
            this.userName = '';
            this.makePassword();
            this.groups = [];
            this.fields.name = false;
            this.fields.lastName = false;
            this.fields.gender = false;
            this.fields.birthday = false;
            this.fields.userName = false;
            this.fields.password = false;

            this.editPassword = false;
            this.editLoginId = false;
        },
        editUser(profile) {
            this.$vuetify.goTo('#edit');
            this.groups = [];
            this.oldProfile = profile;
            this.name = profile.name;
            this.lastName = profile.lastName;
            // this.birthday = this.formattedBirthday(profile.birthday);
            this.gender = profile.gender;
            this.babyView = profile.babyView;
            this.userName = profile.account.accountName;
            this.qrPassword = profile.qrPassword;
            this.newPassword = null;
            this.insertGroupNames(profile.account.groups);

            this.editPassword = false;
            this.editLoginId = false;
        },

        async deleteUser() {
            try {
                this.showLoadingOverlay = true;

                let response = await this.deletePupile(this.oldProfile._id);
                if(response) {
                    const index = this.list.indexOf(this.oldProfile);
                    if (index > -1) {
                        this.list.splice(index, 1);
                    }

                    this.requestPupils();
                    this.setPupilListStatus(true);

                    this.stopEdit();
                    this.makePassword();
                    this.snackbarSave = true;
                } else {
                    this.snackbarText = 'Ein unerwarteter Fehler ist aufgetretten!';
                    this.snackbar = true;
                }
                this.showLoadingOverlay = false;
            } catch (e) {
                console.log(e);
                this.showLoadingOverlay = false;
            }
        },

        async kuhR() {
            const existingPdfBytes = await fetch(this.kuhRFile).then(res => res.arrayBuffer());

            const qrCode = this.$refs.qrcodecontainer.$refs['qrcode-vue'].toDataURL("image/png");

            // Load a PDFDocument from the existing PDF bytes
            const pdfDoc = await PDFDocument.load(existingPdfBytes);
            pdfDoc.registerFontkit(fontkit);

            const pngImage = await pdfDoc.embedPng(qrCode);
            // Embed the Helvetica font
            const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);

            const robotoBytes = await fetch(this.roboto).then((res) => res.arrayBuffer());
            const robotoFont = await pdfDoc.embedFont(robotoBytes);

            // Get the first page of the document
            const pages = pdfDoc.getPages();
            const firstPage = pages[0];

            firstPage.drawImage(pngImage, {
                x: 338,
                y: 522,
                width: 82,
                height: 82,
            });

            firstPage.drawImage(pngImage, {
                x: 338,
                y: 335,
                width: 82,
                height: 82,
            });

            firstPage.drawText(this.name+" "+this.lastName.slice(0,1)+".", {
                x: 180,
                y: 733,
                size: 16,
                font: robotoFont,
            });

            firstPage.drawText(window.location.hostname, {
                x: 180,
                y: 695,
                size: 12,
                font: robotoFont,
            });

            // Serialize the PDFDocument to bytes (a Uint8Array)
            const pdfBytes = await pdfDoc.save();

            this.saveByteArray( this.name + '-kuhR.pdf', pdfBytes);
        },

        async pdf() {
            const existingPdfBytes = await fetch(this.pdfFile).then(res => res.arrayBuffer());

            const qrCode = this.$refs.qrcodecontainer.$refs['qrcode-vue'].toDataURL("image/png");

            // Load a PDFDocument from the existing PDF bytes
            const pdfDoc = await PDFDocument.load(existingPdfBytes);

            pdfDoc.registerFontkit(fontkit);

            const pngImage = await pdfDoc.embedPng(qrCode);

            const robotoBytes = await fetch(this.roboto).then((res) => res.arrayBuffer());
            const robotoBoldBytes = await fetch(this.robotoBold).then((res) => res.arrayBuffer());


            const robotoFont = await pdfDoc.embedFont(robotoBytes);
            const robotoBoldFont = await pdfDoc.embedFont(robotoBoldBytes);

            // Get the first page of the document
            const pages = pdfDoc.getPages();
            const firstPage = pages[0];
            const form = pdfDoc.getForm();
            const button = form.createButton('some.button.field');

            // Get the width and height of the first page
            const { width, height } = firstPage.getSize();

            // Draw a string of text diagonally across the first page
            firstPage.drawText(this.name + ' ' +this.lastName.slice(0,1) + '.', {
                x: 102,
                y: 700,
                size: 12,
                font: robotoBoldFont,
            });

            firstPage.drawText(this.name + ',', {
                x: 157,
                y: 534.3,
                size: 10,
                font: robotoFont,
            });

            firstPage.drawText(this.userName, {
                x: 100.7,
                y: 395,
                size: 10,
                font: robotoFont,
            });

            firstPage.drawText(this.password, {
                x: 100.7,
                y: 350,
                size: 10,
                font: robotoFont,
            });

            button.addToPage(window.location.hostname, firstPage, {
                x: 100.7,
                y: 305,
                height: 15,
                width: 150,
                textColor: rgb(0,0,0),
                backgroundColor: rgb(1,1,1),
                borderColor: rgb(1,1, 1),
                borderWidth: 0,
                font: robotoFont,
            });

            //QR1
            firstPage.drawText(this.userName, {
                x: 26,
                y: 80,
                size: 10,
                font: robotoFont,
            });

            firstPage.drawText(this.password, {
                x: 26,
                y: 45,
                size: 10,
                font: robotoFont,
            });

            firstPage.drawImage(pngImage, {
                x: 130,
                y: 53,
                width: 50,
                height: 50,
            });

            //QR2
            firstPage.drawText(this.userName, {
                x: 218,
                y: 80,
                size: 10,
                font: robotoFont,
            });

            firstPage.drawText(this.password, {
                x: 218,
                y: 45,
                size: 10,
                font: robotoFont,
            });

            firstPage.drawImage(pngImage, {
                x: 322,
                y: 53,
                width: 50,
                height: 50,
            });

            //QR3
            firstPage.drawText(this.userName, {
                x: 410,
                y: 80,
                size: 10,
                font: robotoFont,
            });

            firstPage.drawText(this.password, {
                x: 410,
                y: 45,
                size: 10,
                font: robotoFont,
            });

            firstPage.drawImage(pngImage, {
                x: 514,
                y: 53,
                width: 50,
                height: 50,
            });

            // Serialize the PDFDocument to bytes (a Uint8Array)
            const pdfBytes = await pdfDoc.save();

            this.saveByteArray( this.userName + '.pdf',pdfBytes)
        },

        async getPDFBytes(data) {
            const existingPdfBytes = await fetch(this.pdfFile).then(res => res.arrayBuffer());

            const qrCode = this.$refs.hiddenqrcodecontainer.$refs['qrcode-vue'].toDataURL("image/png");

            // Load a PDFDocument from the existing PDF bytes
            const pdfDoc = await PDFDocument.load(existingPdfBytes);

            pdfDoc.registerFontkit(fontkit);

            const pngImage = await pdfDoc.embedPng(qrCode);

            const robotoBytes = await fetch(this.roboto).then((res) => res.arrayBuffer());
            const robotoBoldBytes = await fetch(this.robotoBold).then((res) => res.arrayBuffer());


            const robotoFont = await pdfDoc.embedFont(robotoBytes);
            const robotoBoldFont = await pdfDoc.embedFont(robotoBoldBytes);

            // Get the first page of the document
            const pages = pdfDoc.getPages();
            const firstPage = pages[0];
            const form = pdfDoc.getForm()
            const button = form.createButton('some.button.field')

            // Get the width and height of the first page
            const { width, height } = firstPage.getSize();

            // Draw a string of text diagonally across the first page
            firstPage.drawText(data.name + ' ' +data.lastName.slice(0,1) + '.', {
                x: 102,
                y: 700,
                size: 12,
                font: robotoBoldFont,
            });

            firstPage.drawText(data.name + ',', {
                x: 157,
                y: 534.3,
                size: 10,
                font: robotoFont,
            });

            firstPage.drawText(data.accountName, {
                x: 100.7,
                y: 395,
                size: 10,
                font: robotoFont,
            });

            firstPage.drawText(data.password, {
                x: 100.7,
                y: 350,
                size: 10,
                font: robotoFont,
            });

            button.addToPage(window.location.hostname, firstPage, {
                x: 100.7,
                y: 305,
                height: 15,
                width: 150,
                textColor: rgb(0,0,0),
                backgroundColor: rgb(1,1,1),
                borderColor: rgb(1,1, 1),
                borderWidth: 0,
                font: robotoFont,
            });

            //QR1
            firstPage.drawText(data.accountName, {
                x: 26,
                y: 80,
                size: 10,
                font: robotoFont,
            });

            firstPage.drawText(data.password, {
                x: 26,
                y: 45,
                size: 10,
                font: robotoFont,
            });

            firstPage.drawImage(pngImage, {
                x: 130,
                y: 53,
                width: 50,
                height: 50,
            });

            //QR2
            firstPage.drawText(data.accountName, {
                x: 218,
                y: 80,
                size: 10,
                font: robotoFont,
            });

            firstPage.drawText(data.password, {
                x: 218,
                y: 45,
                size: 10,
                font: robotoFont,
            });

            firstPage.drawImage(pngImage, {
                x: 322,
                y: 53,
                width: 50,
                height: 50,
            });

            //QR3
            firstPage.drawText(data.accountName, {
                x: 410,
                y: 80,
                size: 10,
                font: robotoFont,
            });

            firstPage.drawText(data.password, {
                x: 410,
                y: 45,
                size: 10,
                font: robotoFont,
            });

            firstPage.drawImage(pngImage, {
                x: 514,
                y: 53,
                width: 50,
                height: 50,
            });

            // Serialize the PDFDocument to bytes (a Uint8Array)
            const pdfBytes = await pdfDoc.save();

            return pdfBytes;
        },


        saveByteArray(reportName, byte) {
            let blob = new Blob([byte], {type: "application/pdf"});
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            let fileName = reportName;
            link.download = fileName;
            link.click();
        },

        validateInput() {
            let result = true;

            this.fields.name = false;
            this.fields.lastName = false;
            this.fields.gender = false;
            this.fields.birthday = false;
            this.fields.userName = false;

            if (this.name.replace(/\s/g, '') === '' || this.containsNameNumbers(this.name)) {
                this.fields.name = true;
                result = false;
            }
            if (this.lastName.replace(/\s/g, '') === '' || this.containsNameNumbers(this.lastName)) {
                this.fields.lastName = true;
                result = false;
            }
            // if (this.birthday.replace(/\s/g, '') === '' || !this.isBirthdayInPast()) {
            //     this.fields.birthday = true;
            //     result = false;
            // }
            if (this.gender.replace(/\s/g, '') === '') {
                this.fields.gender = true;
                result = false;
            }
            if (this.userName.replace(/\s/g, '') === '' || (!this.isUsernameFree && !this.oldProfile)
                || this.containsUsernameStrangeLetters(this.userName) || this.userName.trim().indexOf(" ") > -1) {
                this.fields.userName = true;
                result = false;
            }

            if(this.editPassword) {
                this.fields.password = false;
                if (!this.checkPw()) {
                    this.fields.password = true;
                    result = false;
                }
            }

            return result;
        },
        containsNameNumbers(name) {
            // eslint-disable-next-line no-useless-escape
            const format = /[0-9]+/;
            return format.test(name);
        },
        containsUsernameStrangeLetters(username) {
            // eslint-disable-next-line no-useless-escape
            const format = /[!@#$äüö%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
            return format.test(username);
        },
        async patchUser() {
            try {
                this.showLoadingOverlay = true;
                let data = {
                    _id: this.oldProfile._id,
                    name: this.name,
                    lastName: this.lastName,
                    // birthday: this.birthday,
                    babyView: this.babyView,
                    gender: this.gender,
                };
                if (this.newPassword) {
                    data['password'] = this.newPassword;
                    data['qrPassword'] = this.qrPassword;
                }
                if (this.userName !== this.oldProfile.account.accountName) {
                    data['accountName'] = this.userName.trim();
                }
                let response = await this.editPupil(data);
                if (Number.isInteger(response)) {
                    if (response === 409) {
                        this.snackbarText = 'Bitte eine andere Login-ID eingeben! (Bereits verwendet)';
                    } else {
                        this.snackbarText = 'Ein unerwarteter Fehler ist aufgetretten!';
                    }
                    this.snackbar = true;
                } else {
                    response['accountName'] = this.userName.trim();

                    const index = this.list.indexOf(this.oldProfile);
                    if (index > -1) {
                        this.list.splice(index, 1);
                    }
                    this.list.push(response);

                    this.requestPupils();
                    this.setPupilListStatus(true);

                    //this.makePassword();
                    this.snackbarSave = true;
                }
                this.showLoadingOverlay = false;
            } catch (e) {
                console.log(e);
                this.showLoadingOverlay = false;
            }
        },
        async saveUser() {
            try {
                this.showLoadingOverlay = true;
                let data = {
                    name: this.name,
                    lastName: this.lastName,
                    // birthday: this.birthday,
                    gender: this.gender,
                    babyView: this.babyView,
                    accountName: this.userName.trim(),
                    password: this.password,
                    qrPassword: this.qrPassword,
                };
                let response = await this.createPupil(data);
                if(Number.isInteger(response)) {
                    if(response === 409) {
                        this.snackbarText = 'Bitte eine andere Login-ID eingeben! (Bereits verwendet)';
                    } else {
                        this.snackbarText = 'Ein unerwarteter Fehler ist aufgetretten!';
                    }
                    this.snackbar = true;
                } else {
                    response['accountName'] = this.userName.trim();
                    this.list.push(response);

                    this.requestPupils();
                    this.setPupilListStatus(true);

                    //this.makePassword();
                    this.snackbarSave = true;
                }
                this.showLoadingOverlay = false;
            } catch (e) {
                console.log(e);
                this.showLoadingOverlay = false;
            }
        },
        closeDialogs(){
            this.stopEdit();
            this.makePassword();
            this.dialog2 = false;
            this.dialog3 = false;
            this.dialog = false;
        },
        formattedBirthday(birthday) {
            try {
                const bdayDate = new Date(birthday);
                if (bdayDate.toString() === 'Invalid Date') {
                    const splitBirthday = birthday.split('.');
                    if (splitBirthday.length === 3) {
                        splitBirthday[0] = splitBirthday[0].length === 1 ? '0'+splitBirthday[0] : splitBirthday[0];
                        splitBirthday[1] = splitBirthday[1].length === 1 ? '0'+splitBirthday[1] : splitBirthday[1];
                    }
                    return splitBirthday.reverse().join('-');
                }
                return birthday;
            } catch (e) {
                console.log(e);
                return birthday;
            }
        },
        formattedBirthdayForShow(birthday) {
            try {
                const bdayDate = new Date(birthday);
                if (bdayDate.toString() === 'Invalid Date') {
                    return birthday;
                }
                return bdayDate.toLocaleDateString();
            } catch (e) {
                console.log(e);
                return birthday;
            }
        },
        isBirthdayInPast() {
            const bdayDate = new Date(this.birthday);
            const now = new Date();
            return bdayDate.toString() !== 'Invalid Date' && bdayDate < now;
        },
        async requestPupils() {
            this.showLoadingOverlay = true;
            this.list = await this.getPupils();
            this.showLoadingOverlay = false;
            this.setPupilListStatus(false);
        },
        makePassword() {
            let result = '';
            let qrResult = '';
            let allCharacters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
            let numbers = '0123456789';
            let numberCount = Math.floor(Math.random()*(6)+1);

            for ( let i = 0; i < 8 - numberCount; i++ ) {
                result += characters.charAt(Math.floor(Math.random() * characters.length));
            }
            for ( let i = 0; i < numberCount; i++ ) {
                result += numbers.charAt(Math.floor(Math.random() * numbers.length));
            }
            for ( let i = 0; i < 16; i++ ) {
                qrResult += allCharacters.charAt(Math.floor(Math.random() * allCharacters.length));
            }
            this.password = result;
            this.newPassword = result;
            this.qrPassword = qrResult;
        },
        async qrcodeString() {
            const compressedName = await compress(this.userName);
            const compressedPW = await compress(this.password);

            const compressedString = JSON.stringify({name: compressedName, pw: compressedPW});

            this.qrCodeData = compressedString;
        },
        async insertGroupNames(groups){

            //TODO: Replace function to not call each group on its own
            for(let i = 0; i < groups.length; i++){
                this.groups.push(await this.getGroup(groups[i]));
            }
            return this.groups;
        },
        async suggestLoginId(oldProfile) {
            if (!this.editLoginId && !oldProfile) {
                let id = this.name.toLowerCase() + this.lastName.slice(0,1).toLowerCase();
                let data = {
                    userName: id,
                };
                let available = await this.isLoginIdAvailable(data);

                if (available) {
                    this.userName = id;
                } else {
                    let i = 1;
                    this.userName = id + i;
                    for (let i = 1; !available; i++) {
                        id = this.name.toLowerCase() + this.lastName.slice(0,1).toLowerCase() + i;
                        data = {
                            userName: id,
                        };
                        available = await this.isLoginIdAvailable(data);
                        this.userName = id;
                    }
                }
            }
            this.validateInput();
        },
        //checks if password is at least of length 8 and contains one letter and one number
        checkPw() {
            const pwCheck = new RegExp("^(?=.*[a-zA-Z])(?=.*[0-9])(?=.{8,})");
            return pwCheck.test(this.password);
        }
    },
};
</script>


<style lang="scss" scoped>
    table, th, td {
        border: 1px solid black;
        border-collapse: collapse;
    }

    tr {
        height: 40px;
    }

    table tr th:first-child,
    table tr td:first-child {
        width: 10px !important;
    }

    table tr th,
    table tr td {
        width: 9em;
        border: none;
    }
    table, tr, td {
        border: none;
    }


    .input{
        display: block;
        width: 100%;
        max-width: 100%;
        border-radius: 5px;
        border-width:0;
        color: black;
        padding: 1px 5px 1px 5px;
        border: none !important;
        background-color: #EAEAEA;
        overflow: hidden;
        appearance:none
    }

    .errorInput {
        border: 2px solid red !important;
    }

    .input[disabled]{
        color: grey;
        background-color: #CACACA;
    }

    .editContainer {
        min-height: 40vh;
        height: fit-content;
        width: 90%;
        border-radius: 15px;
        box-shadow: 1px 5px 5px silver;
        margin: auto;
        margin-bottom: 5em
    }

    .mainControlButtons {
        font-weight: 300;
        font-size: 10px;
        border-radius: 8px;

        & span {
            line-height: 0 !important;
        }
    }

    .mainFunctionButtons {
        width: 38px !important;
        height: 38px !important;
        border-radius: 8px;

        img {
            width: 27px !important;
            height: 27px !important;
            filter: brightness(1000%);
        }
    }

    .infoTextToolTip {
        max-width: 250px;
        background-color: #505FAA;
    }

    .editPupilButton {
        border-radius: 5px;
        text-transform: inherit;

        img {
            filter: brightness(10%);
            width: 15px;
            margin-right: 5px;
            margin-top: -2px;
        }
    }

    .generatePasswordButton {
        max-height: 25px;
        max-width: 100%;
        width: 100%;
        text-transform: inherit;
        border-radius: 8px;

        img {
            /*filter: brightness(10%);*/
            width: 15px;
            margin-right: 5px;
            margin-top: -2px;
        }
    }

    input[type="date"] {
        -webkit-appearance: textfield;
        -moz-appearance: textfield;
        min-height: 1.6rem;
    }
</style>
