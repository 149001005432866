var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: {
        position: "fixed",
        bottom: "0",
        right: "15px",
        "z-index": "2"
      }
    },
    [
      _vm.tutText
        ? _c(
            "div",
            {
              key: "ttstext" + _vm.update,
              staticClass: "noselect",
              staticStyle: {
                position: "relative",
                "background-color": "transparent",
                height: "90px",
                width: "90px"
              }
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    position: "relative",
                    width: "100%",
                    height: "100%"
                  }
                },
                [
                  _c(
                    "v-row",
                    { staticClass: "mx-0", staticStyle: { width: "100%" } },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "pa-3",
                          staticStyle: {
                            position: "absolute",
                            left: "-300px",
                            top: "90px",
                            "background-color": "white",
                            height: "auto",
                            width: "300px",
                            "border-radius": "15px",
                            "box-shadow": "1px 5px 5px silver",
                            margin: "auto auto 5em"
                          }
                        },
                        [
                          _vm._v(
                            " Markiere einen Text, klicke mich an und ich lese ihn dir vor. "
                          )
                        ]
                      )
                    ]
                  )
                ],
                1
              )
            ]
          )
        : _vm._e(),
      _c(
        "div",
        {
          key: _vm.update,
          staticClass: "noselect",
          staticStyle: {
            position: "relative",
            "background-color": "transparent",
            height: "90px",
            width: "90px"
          },
          on: { click: _vm.greet }
        },
        [
          _vm.textSelected || _vm.tutText
            ? _c(
                "div",
                {
                  staticClass: "rotate-scale-up",
                  staticStyle: {
                    position: "relative",
                    width: "100%",
                    height: "100%"
                  }
                },
                [
                  _c("img", {
                    staticStyle: {
                      top: "40%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      position: "inherit"
                    },
                    attrs: { src: _vm.papageiF }
                  })
                ]
              )
            : _c(
                "div",
                {
                  key: _vm.tutText,
                  staticClass: "rotate-scale-up",
                  staticStyle: {
                    position: "relative",
                    width: "100%",
                    height: "100%"
                  }
                },
                [
                  _c("img", {
                    staticStyle: {
                      top: "60%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      position: "inherit"
                    },
                    attrs: { src: _vm.ei }
                  })
                ]
              )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }