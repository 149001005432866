<template>
    <div style="padding-right: 3px; padding-left: -3px">
        <progress-bar
            title="Hochladen"
            :show-progress="showPupilUploadProgress"
            :progress="pupilUploadProgress"
            :abort-progress="pupilXmlHttpRequest ? () => { pupilXmlHttpRequest.abort() }: () => {}"
        />

        <name-file
            v-model="showNameFile"
            :original-name="uploadFileName"
            :callback="fileNameCallback"
        />

        <v-dialog
            v-if="thisAppointment"
            v-model="menu"
            @click:outside="resetDialogContent()"
            max-width="400"
            scrollable
            class="dialog"
            overlay-color="white"
        >
            <template v-slot:activator="{ on, attrs }">
                <!--fach with text and image-->
                <div v-bind="attrs" class="fach pa-2 white--text"
                     style="width: 90%; display: block; padding-left: 0 !important;">
                    <v-row cols="12" class="ml-2 font-weight-medium" justify="center">
                        <v-col
                            :cols="isRunning ? 9 : 12"
                            v-on="on"
                            class="pa-0 ma-0"
                        >
                            <v-badge
                                color="red"
                                :content="thisAppointment.teacherUploads.filter((el) => !el.seenByMe).length"
                                :value="thisAppointment.teacherUploads.filter((el) => !el.seenByMe).length"
                                overlap
                                left
                                offset-x="5"
                                style="z-index: 8; width: 100%; height: 48px !important;"
                            >
                                <div class="pa-3 ma-0 appointmentItem"
                                     :class="{ inactiveAppointment: !isRunning }"
                                     :style="isRunning ? 'width: calc(100% - 3px) !important' : ''"
                                >
                                    <img v-if="getSrc()"
                                         class="iconSelect"
                                         :src="isRunning ? getSrcWhite() : getSrc()"
                                         :style="isRunning ? getBackgroundColor() : 'background-color: transparent'"/>

                                    <div v-else class="iconSelect"></div>
                                    {{ thisAppointment.schoolSubject.name }}
                                    <!--bookmark-->
                                    <img :src="getColorIcon()" style="position: absolute; height: 25px; top: 0; right: 10px;"/>
                                </div>
                                <!--paperclip-->
                                <img v-if="thisAppointment.teacherUploads.length > 0" :src="paperclipIcon" :style="isRunning ? 'right: 30px' : 'right: 27px'" style="opacity: 60%; position: absolute; height: 21px; top: -9px;"/>
                            </v-badge>
                        </v-col>

                        <!--Kamera-Icon neben Fach-->
                        <v-col
                            v-if="isRunning"
                            :cols="3"
                            class="pa-0 mr-0"
                            @click="clickBeitreten"
                            style="min-height: 100%"
                        >
                            <v-btn
                                class="pa-0" small
                                :class="{ isFachRunning: isRunning }"
                                style="height: 48px; width: 100%; background-color: white; border-radius: 8px;"
                            >
                                <img
                                    :src="beitretenKameraIcon"
                                    class="pa-0"
                                    :class="{ isFachRunningIcon: isRunning }"
                                    style="height: 35px !important; width: 35px !important;"
                                />
                            </v-btn>
                        </v-col>
                    </v-row>
                </div>
            </template>

            <!--menu that opens on click on fach in table-->
            <v-card tile style="color: #707070;" id="fachPopup">
                <v-card-title class="pa-0 ma-0">
                    <div class="fach d-flex" style="box-shadow: 0 5px 5px 0 rgba(0,0,0,0.3); font-size: large; align-items: center; padding: 0 20px; height: 59px; width: 100%;">
                        <v-col cols="6" class="d-flex align-center pl-0">
                            <img :src="getColorIcon()" style="position: absolute; right: 20%; top: 0; height: 25px;"/>
                            <img v-if="getSrc()" class="iconSelect" :src="getSrcWhite()" style="margin-bottom: 0" :style="getBackgroundColor()">
                            <div class="pl-2">{{ thisAppointment.schoolSubject.name }}</div>
                        </v-col>

                        <v-col cols="4" class="pa-0 pr-6 d-flex flex-column justify-start align-end"
                               :style="`font-size: ${windowWidth < 400 ? 'small': 'medium'}`">
                            <div>{{getAppropriateTeacherDisplayName}}</div>

                            <!--TODO add start and end time of lesson-->
                            <!--<div></div>-->
                        </v-col>

                        <v-col cols="2" align-self="end">
                            <v-btn @click="menu = false; resetDialogContent()" elevation="2" x-small class="ml-2 pa-0 btnStyle" style="width: 30px; height: 30px">
                                <img :src="schliesenIcon" style="height: 20px;"/>
                            </v-btn>
                        </v-col>
                    </div>
                </v-card-title>

                <v-card-text class="pa-0 ma-0" style="overflow: hidden;">
                    <vue-custom-scrollbar class="scroll-area-fachPopup mb-2" :settings="settings">
                        <div class="mx-5">
                            <div class="mt-2">
                                <v-row>
                                    <v-col class="font-weight-bold py-2 d-flex align-center">
                                        <img :src="infoIcon"
                                             style="height: 20px;"
                                        />
                                        <p class="pl-2 ma-0" style="font-size: medium">Info</p>
                                    </v-col>
                                    <v-col class="text-right py-1">
                                        <v-btn @click="greet($event)" elevation="0" x-small class="btnStyle">
                                            <img :src="lautsprecherIcon" style="height: 20px;"/>
                                        </v-btn>
                                    </v-col>
                                </v-row>

                                <vue-custom-scrollbar
                                    v-if="thisAppointment.note"
                                    style="font-size: medium; font-weight: normal; line-height: 25px;"
                                    class="mb-4 mt-1 scroll-area" :settings="settings"
                                >
                                    <span v-html="urlify(thisAppointment.note)"></span>
                                </vue-custom-scrollbar>
                                <div v-else class="pb-1">
                                    Bitte bereitet schonmal eure Unterlagen vor! Und ladet gegebenfalls vorhandene
                                    Materialien herunter
                                </div>
                            </div>
                        </div>

                        <hr class="eHr">

                        <div class="mx-0 mb-4">
                            <div class="mt-2">
                                <v-row>
                                    <v-col class="font-weight-bold py-2 mx-5 pt-0 d-flex align-center">
                                        <img :src="materialIcon"
                                             style="height: 20px;"
                                        />
                                        <p class="pl-2 ma-0" style="font-size: medium">Material</p>
                                    </v-col>
                                </v-row>
                                <div v-if="thisAppointment.teacherUploads.length === 0" class="mx-5 pb-1" style="font-size: medium;">
                                    Bis jetzt wurden zu diesem Fach noch keine
                                    Materialien hochgeladen.
                                </div>

                                <!--up to two items if collapsed-->
                                <div class="matShort">
                                    <div
                                        v-for="(el,index) in thisAppointment.teacherUploads.filter(item => !item.editParent).slice(0, 2)"
                                        :class="{ brighterFileRow: index % 2 === 1}"
                                        :key="el + index"
                                        class="py-2 px-5 d-flex justify-space-between align-center"
                                        style="font-size: medium; font-weight: normal; line-height: 25px; width: 100%; background-color: rgba(112,112,112, 0.15);"
                                    >
                                        <div class="fileName" style="min-width: 70%; width: 70%;">
                                            {{ el.title }}
                                        </div>

                                        <div style="display: inline-block">
                                            <v-badge
                                                color="red"
                                                content="1"
                                                :value="!el.seenByMe"
                                                overlap
                                                offset-x="15"
                                                style="z-index: 9"
                                            >
                                                <v-btn
                                                    v-if="['mp4', 'wmv', 'flv', 'webm', 'mpg', 'm4v', 'mov', 'mkv'].includes(el.title.split('.').pop().toLowerCase())"
                                                    dark :loading="videoLoadings[index]" @click="() => {play(el, index); menu = false}" x-small class="btnStyle" elevation="0" style="background-color: #8CBD46;" >
                                                    <img :src="rechtsIcon" style="height: 20px; filter: brightness(1000%)"/>
                                                </v-btn>

                                                <v-btn
                                                        v-else-if="['doc', 'docx', 'ppt', 'pptx', 'xls', 'xlsx'].includes(el.title.split('.').pop().toLowerCase())"
                                                        dark @click="fileNotSupported(el)" x-small class="btnStyle" elevation="0" style="background-color: #8CBD46;" >
                                                    <img :src="infoIcon" style="height: 20px; filter: brightness(1000%)"/>
                                                </v-btn>

                                                <v-btn v-else-if="!el.seenByMe"
                                                       @click="() => openEdit(el.title, el.file, el._id)" x-small
                                                       style="background-color: #8CBD46;" class="btnStyle" elevation="0">
                                                    <img :src="stiftIcon" style="height: 20px;"/>
                                                </v-btn>

                                                <v-btn v-else @click="() => openEdit(el.title, el.file, el._id)" x-small
                                                       class="btnStyle" elevation="0">
                                                    <img :src="bearbeitenIcon" style="height: 20px;"/>
                                                </v-btn>
                                            </v-badge>

                                            <v-btn @click="() => clickDownloadTeacherUpload(el)" x-small elevation="0"
                                                   class="btnStyle ml-2" style="width: 20px; height: 20px">
                                                <img :src="runterladenIcon" style="height: 20px;"/>
                                            </v-btn>
                                        </div>
                                    </div>
                                </div>

                                <!--all items if expanded-->
                                <div class="matLong">
                                    <div
                                        v-for="(el,index) in thisAppointment.teacherUploads.filter(item => !item.editParent)"
                                        :class="{ brighterFileRow: index % 2 === 1}"
                                        :key="el + index"
                                        class="py-2 px-5 d-flex justify-space-between align-center"
                                        style="font-size: medium; font-weight: normal; line-height: 25px; width: 100%; background-color: rgba(112,112,112, 0.15);"
                                    >
                                        <div class="fileName" style="min-width: 70%; width: 70%;">
                                            {{ el.title }}
                                        </div>
                                        <div style="display: inline-block">
                                            <v-badge
                                                color="red"
                                                content="1"
                                                :value="!el.seenByMe"
                                                overlap
                                                offset-x="15"
                                                style="z-index: 9"
                                            >
                                                <v-btn
                                                    v-if="['mp4', 'wmv', 'flv', 'webm', 'mpg', 'm4v', 'mov', 'mkv'].includes(el.title.split('.').pop().toLowerCase())"
                                                    dark :loading="videoLoadings[index]" @click="() => {play(el, index); menu = false}" x-small class="btnStyle" elevation="0" style="background-color: #8CBD46;" >
                                                    <img :src="rechtsIcon" style="height: 20px; filter: brightness(1000%)"/>
                                                </v-btn>

                                                <v-btn
                                                    v-else-if="['doc', 'docx', 'ppt', 'pptx', 'xls', 'xlsx'].includes(el.title.split('.').pop().toLowerCase())"
                                                    dark @click="fileNotSupported(el)" x-small class="btnStyle" elevation="0" style="background-color: #8CBD46;" >
                                                    <img :src="infoIcon" style="height: 20px; filter: brightness(1000%)"/>
                                                </v-btn>

                                                <v-btn v-else-if="!el.seenByMe"
                                                       @click="() => openEdit(el.title, el.file, el._id)" x-small
                                                       style="background-color: #8CBD46;" class="btnStyle" elevation="0">
                                                    <img :src="stiftIcon" style="height: 20px;"/>
                                                </v-btn>

                                                <v-btn v-else @click="() => openEdit(el.title, el.file, el._id)" x-small
                                                       class="btnStyle" elevation="0">
                                                    <img :src="bearbeitenIcon" style="height: 20px;"/>
                                                </v-btn>
                                            </v-badge>

                                            <v-btn @click="() => clickDownloadTeacherUpload(el)" x-small elevation="0"
                                                   class="btnStyle ml-2" style="width: 20px; height: 20px">
                                                <img :src="runterladenIcon" style="height: 20px;"/>
                                            </v-btn>
                                        </div>
                                    </div>
                                </div>

                                <!--expand and collapse btns-->
                                <div v-if="thisAppointment.teacherUploads.filter(item => !item.editParent).length > 2" class="d-flex justify-center mt-2">
                                    <v-btn @click="hideEl('matShort'); showEl('matLong')" class="matShort" small elevation="0">
                                        <img :src="arrowDown" style="height: 20px;"/>
                                    </v-btn>

                                    <v-btn @click="hideEl('matLong'); showEl('matShort')" class="matLong" small elevation="0">
                                        <img :src="arrowDown" style="height: 20px; transform: rotate(180deg)"/>
                                    </v-btn>
                                </div>

                            </div>
                        </div>

                        <hr class="eHr">

                        <div class="mx-0">
                            <div class="mt-2">
                                <v-row>
                                    <v-col class="font-weight-bold pb-2 mx-5 pt-0 d-flex align-center">
                                        <img :src="abgabenIcon"
                                             style="height: 20px;"
                                        />
                                        <p class="pl-2 ma-0" style="font-size: medium">Abgaben</p>
                                    </v-col>

                                    <v-col class="text-right py-1">
                                        <input
                                            @change="() => pupilUploadInputChange(thisAppointment._id)"
                                            id="pupilUploadInput"
                                            ref="pupilUploadInput"
                                            type="file"
                                            hidden
                                        />
                                    </v-col>
                                </v-row>

                                <div v-if="thisAppointment.pupilUpload.length === 0" class="mx-5" style="font-size: medium;">
                                    Du hast noch nichts abgegeben.
                                </div>

                                <div class="uploadsShort">
                                    <v-row
                                        v-for="(el,index) in thisAppointment.pupilUpload.slice(0, 2)"
                                        :key="el + index"
                                        :class="{ brighterFileRow: index % 2 === 1, correctionAvailableClass: isCorrectionAvailable(el)}"
                                        class="py-2 px-5 ma-0 d-flex justify-space-between align-center"
                                        style="font-size: medium; font-weight: normal; line-height: 25px; width: 100%; background-color: rgba(112,112,112, 0.15);"
                                    >
                                        <div class="fileName" style="max-width: 75% !important; min-width: 75% !important;">
                                            {{ el.title }}
                                        </div>
                                        <div style="display: inline-block">
                                            <v-btn v-if="true" @click="() => handleOpenAnsichtClick(el)" x-small class="btnStyle mx-1" elevation="0">
<!--                                                <img :src="bearbeitenIcon" style="height: 20px;"/>-->
                                                <v-icon style="width: 20px; color: #939393">fas fa-eye</v-icon>
                                            </v-btn>
                                            <v-btn v-if="isCorrectionAvailable(el)"
                                                   @click="() => clickDownloadPupilCorrection(el)"
                                                   class="btnStyle" elevation="0" x-small>
                                                <img :src="bearbeitetIcon" style="height: 20px;"/>
                                            </v-btn>
                                            <v-btn v-else
                                                   @click="() => clickDownloadPupilUpload(el)" elevation="0" x-small
                                                   class="btnStyle" >
                                                <img :src="offenIcon" style="height: 20px;"/>
                                            </v-btn>
                                        </div>
                                    </v-row>
                                </div>

                                <div class="uploadsLong">
                                    <v-row
                                        v-for="(el,index) in thisAppointment.pupilUpload"
                                        :key="el + index"
                                        :class="{ brighterFileRow: index % 2 === 1, correctionAvailableClass: isCorrectionAvailable(el)}"
                                        class="py-2 px-5 ma-0 d-flex justify-space-between align-center"
                                        style="font-size: medium; font-weight: normal; line-height: 25px; width: 100%; min-height: 49px ;background-color: rgba(112,112,112, 0.15);"
                                    >
                                        <div class="fileName" style="max-width: 75% !important; min-width: 75% !important;">
                                            {{ el.title }}
                                        </div>

                                        <div class="pa-0 ml-0">
                                            <v-btn v-if="true" @click="() => handleOpenAnsichtClick(el)" x-small class="btnStyle mx-1" elevation="0">
<!--                                                <img :src="bearbeitenIcon" style="height: 20px;"/>-->
                                                <v-icon style="width: 20px; color: #939393">fas fa-eye</v-icon>
                                            </v-btn>
                                            <v-btn v-if="isCorrectionAvailable(el)"
                                                   @click="() => clickDownloadPupilCorrection(el)" elevation="0" x-small
                                                   class="ml-2 pa-0" style="width: 20px; height: 20px">
                                                <img :src="bearbeitetIcon" style="height: 20px;"/>
                                            </v-btn>
                                            <v-btn v-else
                                                   @click="() => clickDownloadPupilUpload(el)" elevation="0" x-small
                                                   class="btnStyle" >
                                                <img :src="offenIcon" style="height: 20px;"/>
                                            </v-btn>
                                        </div>
                                    </v-row>
                                </div>

                                <!--expand and collapse btns-->
                                <div v-if="thisAppointment.pupilUpload.length > 2" class="d-flex justify-center mt-2">
                                    <v-btn @click="hideEl('uploadsShort'); showEl('uploadsLong')" class="uploadsShort" small elevation="0">
                                        <img :src="arrowDown" style="height: 20px;"/>
                                    </v-btn>

                                    <v-btn @click="hideEl('uploadsLong'); showEl('uploadsShort')" class="uploadsLong" small elevation="0">
                                        <img :src="arrowDown" style="height: 20px; transform: rotate(180deg)"/>
                                    </v-btn>
                                </div>

                                <div class="my-4">
                                    <div class="d-flex" style="justify-content: center">
                                        <v-btn @click="clickPupilUploadButton" elevation="0" class="text-capitalize"
                                               style="padding-left: 30px; padding-right: 30px; color: #707070">
                                            <img :src="hochladenIcon" class="mr-2" style="height: 20px;"/>
                                            Hochladen
                                        </v-btn>
                                        <v-menu offset-x v-if="true">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn
                                                    class="ml-1 pa-0" elevation="0" small
                                                    style="color: #707070; height: 36px; width: 30px !important;"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    @click="() => { emptyEditorPagesOpen = !emptyEditorPagesOpen }"
                                                >
                                                    <img :src="arrowDown" :style="`height: 20px; transform: rotate(${emptyEditorPagesOpen ? '180' : '0'}deg)`"/>
                                                </v-btn>
                                            </template>

                                            <div id="emptyPageMenu">
                                                <v-btn style="text-transform: inherit; color: #707070" text class="d-flex justify-start" @click="openEmptyEdit('blank')">
                                                    <img :src="leeresBlatt" class="mr-2" style="height: 20px;"/>
                                                    Leeres Blatt
                                                </v-btn>
                                                <v-btn style="text-transform: inherit; color: #707070" text class="d-flex justify-start" @click="openEmptyEdit('kariert')">
                                                    <img :src="kariertesBlatt" class="mr-2" style="height: 20px;"/>
                                                    Kariertes Blatt
                                                </v-btn>
                                                <v-btn style="text-transform: inherit; color: #707070" text class="d-flex justify-start" @click="openEmptyEdit('liniert')">
                                                    <img :src="liniertesBlatt" class="mr-2" style="height: 20px;"/>
                                                    Liniertes Blatt
                                                </v-btn>
                                            </div>
                                        </v-menu>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </vue-custom-scrollbar>
                </v-card-text>

                <v-card-actions class="pa-0 ma-0" style="overflow: hidden;">
                    <!--Beitreten-Button in Fach-Popup-->
                    <v-row>
                        <hr class="eHr mt-2"/>
                        <v-col class="text-center">
                            <v-btn @click="() => { isJoinable ? clickBeitreten() : snackbarRoomClosed = true; }" elevation="0" class="text-capitalize"
                                   style="color: white; padding-left: 50px; padding-right: 50px"
                                   :style="isJoinable ? 'background-color: #8CBD46' : 'background-color: grey'"
                            >
                                <img :src="beitretenKameraIcon" class="mr-5" style="height: 20px;"/>
                                Beitreten
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!--fach with no text and image-->
        <div v-else class="fach pa-2 white--text" style="width: 90%; height: 70.5px !important; display: block; padding-left: 0 !important">
            <v-row class="ml-2 font-weight-medium">
                <v-col cols="12" class="pa-0 ma-0" style="height: 48px !important;">
                    <div class="pa-3 ma-0 appointmentItem inactiveAppointment">
                        <img class="iconSelectFrei"/>
                        Hohlstunde
                    </div>
                </v-col>
            </v-row>
        </div>

        <v-snackbar bottom v-model="snackbar" color="error" timeout="3000">
            {{ snackbarText }}
        </v-snackbar>

        <v-snackbar bottom v-model="snackbarSave" color="success" timeout="3000">
            Gespeichert!
        </v-snackbar>

        <v-snackbar bottom v-model="snackbarRoomClosed" color="grey" timeout="5000" style="z-index: 9999 !important">
            Dieser Raum ist abgeschlossen.
        </v-snackbar>

        <v-snackbar bottom v-model="snackbarFileNotSupported" color="grey" timeout="5000" style="z-index: 9999 !important">
            Diese Datei wird vom Editor nicht unterstützt. Bitte lade sie herunter.
        </v-snackbar>

        <v-overlay opacity="0.95" v-if="videoUrl" z-index="95">
            <v-icon large style="position: fixed; top: 50px; right: 50px; z-index: 99" @click="videoUrl = false" dark>
                fas fa-times
            </v-icon>
            <video :src="videoUrl" controls :width="windowWidth > 900 ? '80%' : '40%%'"
                   :style="{ marginLeft: windowWidth > 900 ? '10%' : '10px' }">
                Your browser does not support the video tag.
            </video>
        </v-overlay>
    </div>
</template>

<script>
import vueCustomScrollbar from 'vue-custom-scrollbar'
import * as backend from "../../api/backend";
import beitretenKameraIcon from '../../assets/Icons/kamera-video-weiss-72.svg'
import schliesenIcon from '../../assets/Icons/abbrechen-08.svg'
import buchzeichenIcon from '../../assets/Icons/lesezeichen-01-43.svg'
import lautsprecherIcon from '../../assets/Icons/lautsprecher-13.svg'
import lehrerIcon from '../../assets/Icons/lehrer-24.svg'
import stiftIcon from '../../assets/Icons/bearbeiten-komplimentär-weiß-88.svg'
import bearbeitenIcon from '../../assets/Icons/bearbeiten-normal-41.svg'
import bearbeitetIcon from '../../assets/Icons/bearbeitet-17.svg'
import runterladenIcon from '../../assets/Icons/runterladen-14.svg'
import hochladenIcon from '../../assets/Icons/hochladen-15.svg'
import rechtsIcon from '../../assets/Icons/rechts-11.svg'
import lesezeichenIcon from '../../assets/Icons/lesezeichen-01-43.svg'
import materialIcon from '../../assets/Icons/material-93.svg'
import abgabenIcon from "../../assets/Icons/bearbeiten-16.svg"
import arrowDown from '../../assets/Icons/unten-dropdown-12.svg'
import offenIcon from "../../assets/Icons/offen-18.svg";
import paperclipIcon from "../../assets/Icons/Bueroklammer-94.svg";
import leeresBlatt from "../../assets/Icons/leeres-blatt-110.svg";
import kariertesBlatt from "../../assets/Icons/kariertes blatt-112.svg";
import liniertesBlatt from "../../assets/Icons/liniertes-blatt-111.svg";
import infoIcon from "../../assets/Icons/info-45.svg";

import bioIcon from '../../assets/Icons/f-biologie-35.svg'
import chemieIcon from '../../assets/Icons/f-chemie-37.svg'
import deutschIcon from '../../assets/Icons/f-deutsch-29.svg'
import englischIcon from '../../assets/Icons/f-englisch-30.svg'
import erdkundeIcon from '../../assets/Icons/f-erdkunde-31.svg'
import geschichteIcon from '../../assets/Icons/f-geschichte-40.svg'
import matheIcon from '../../assets/Icons/f-mathe-32.svg'
import musikIcon from '../../assets/Icons/f-musik-84.svg'
import physikIcon from '../../assets/Icons/f-physik-36.svg'
import politikIcon from '../../assets/Icons/f-politik-34.svg'
import religionIcon from '../../assets/Icons/f-religion-39.svg'
import sportIcon from '../../assets/Icons/f-sport-38.svg'
import wirtschaftIcon from '../../assets/Icons/f-wirtschaft-33.svg'

import bioIconWeiss from "@/assets/Icons/f-biologie-weiss-35.svg";
import chemieIconWeiss from "@/assets/Icons/f-chemie-weiss-37.svg";
import deutschIconWeiss from "@/assets/Icons/f-deutsch-weiss-29.svg";
import englischIconWeiss from "@/assets/Icons/f-englisch-weiss-30.svg";
import erdkundeIconWeiss from "@/assets/Icons/f-erdkunde-weiss-31.svg";
import geschichteIconWeiss from "@/assets/Icons/f-geschichte-weiss-40.svg";
import matheIconWeiss from "@/assets/Icons/f-mathe-weiss-32.svg";
import musikIconWeiss from "@/assets/Icons/f-musik-weiss-82.svg";
import physikIconWeiss from "@/assets/Icons/f-physik-weiss-36.svg";
import politikIconWeiss from "@/assets/Icons/f-politik-weiss-34.svg";
import religionIconWeiss from "@/assets/Icons/f-religion-weiss-39.svg";
import sportIconWeiss from "@/assets/Icons/f-sport-weiss-38.svg";
import wirtschaftIconWeiss from "@/assets/Icons/f-wirtschaft-weiss-33.svg";

import colorfcd20a from '../../assets/Icons/lesezeichen-gelb-47.svg'
import colorf0b423 from '../../assets/Icons/lesezeichen-orange-48.svg'
import colorff6941 from '../../assets/Icons/lesezeichen-rot-49.svg'
import color91c364 from '../../assets/Icons/lesezeichen-hellgrün-50.svg'
import color91c887 from '../../assets/Icons/lesezeichen-mintgrün-51.svg'
import colord7875f from '../../assets/Icons/lesezeichen-braun-52.svg'
import colorebaabe from '../../assets/Icons/lesezeichen-rosa-53.svg'
import color9b91c8 from '../../assets/Icons/lesezeichen-lila-54.svg'
import color6ec3dc from '../../assets/Icons/lesezeichen-blau-55.svg'
import color9bcdc3 from '../../assets/Icons/lesezeichen-minze-56.svg'
import colorb2b2b2 from '../../assets/Icons/lesezeichen-hellgrau-43.svg'
import color707070 from '../../assets/Icons/lesezeichen-dunkelgrau-44.svg'

import {mapState, mapGetters, mapActions} from "vuex";
import ProgressBar from "@/components/ProgressBar";
import NameFile from "@/components/NameFile";
import translation from '../../util/translate';
import lautsprecherAusIcon from "@/assets/Icons/lautsprecher-aus-89.svg";

export default {
    name: "Fach",
    components: {
        NameFile,
        ProgressBar,
        vueCustomScrollbar,
    },
    data() {
        return {
            windowWidth: window.innerWidth,
            menu: false,
            thisAppointment: null,
            snackbar: false,
            snackbarText: '',
            snackbarSave: false,
            snackbarRoomClosed: false,
            snackbarFileNotSupported: false,
            settings: {
                suppressScrollY: false,
                suppressScrollX: true,
                wheelPropagation: false
            },
            emptyEditorPagesOpen: false,
            showPupilUploadProgress: false,
            pupilUploadProgress: 0.0,
            pupilXmlHttpRequest: null,
            showNameFile: false,
            uploadFileName: '',
            fileNameCallback: () => {
            },
            videoUrl: null,
            videoLoadings: [],

            bbbTutState: null,
            bbbTut: false,
            showTextTimeout: null,

            beitretenKameraIcon,
            schliesenIcon,
            buchzeichenIcon,
            lautsprecherIcon,
            lehrerIcon,
            stiftIcon,
            bearbeitenIcon,
            bearbeitetIcon,
            offenIcon,
            runterladenIcon,
            hochladenIcon,
            rechtsIcon,
            lesezeichenIcon,
            materialIcon,
            abgabenIcon,
            arrowDown,
            paperclipIcon,
            leeresBlatt,
            kariertesBlatt,
            liniertesBlatt,
            infoIcon,

            bioIcon,
            chemieIcon,
            deutschIcon,
            englischIcon,
            erdkundeIcon,
            geschichteIcon,
            matheIcon,
            musikIcon,
            physikIcon,
            politikIcon,
            religionIcon,
            sportIcon,
            wirtschaftIcon,

            bioIconWeiss,
            chemieIconWeiss,
            deutschIconWeiss,
            englischIconWeiss,
            erdkundeIconWeiss,
            geschichteIconWeiss,
            matheIconWeiss,
            musikIconWeiss,
            physikIconWeiss,
            politikIconWeiss,
            religionIconWeiss,
            sportIconWeiss,
            wirtschaftIconWeiss,

            colorfcd20a,
            colorf0b423,
            colorff6941,
            color91c364,
            color91c887,
            colord7875f,
            colorebaabe,
            color9b91c8,
            color6ec3dc,
            color9bcdc3,
            colorb2b2b2,
            color707070,
        };
    },
    props: {
        appointment: {required: false, default: null},
        requestAppointments: {required: true},
        isRunning: {type: Boolean, required: false, default: false},
        isOpen: {type: Boolean, required: false, default: false},
        isJoinable: {type: Boolean, required: true, default: false},
    },
    computed: {
        ...mapState("auth", ["token"]),
        ...mapState('translation', ['targetLang']),
        ...mapGetters('util', ['currentlyOpenAppointment']),
        getAppropriateTeacherDisplayName() {
            let prefix = '';

            if (this.thisAppointment.teacher.gender === 'm') {
                prefix = 'Hr. ';
            } else if (this.thisAppointment.teacher.gender === 'w') {
                prefix = 'Fr. ';
            }

            let name = '';
            if (this.thisAppointment.teacher.name.length > 0) {
                name = this.thisAppointment.teacher.name.slice(0, 1) + '. ';
            }

            const lastName = this.thisAppointment.teacher.lastName;

            return prefix + name + lastName;
        },

        getTimeString() {
            let startObj = this.thisAppointment.timeslot;
            console.log(startObj);/*
            let start = startObj.toISOString();
            console.log(start);*/

            return startObj;
        },
    },
    created() {
        this.checkTutorialState();
    },
    mounted() {
        this.checkTutorialState();
        this.thisAppointment = this.appointment;
        this.reloadDontLogout(false);

        this.createVideoLoadArray();
    },
    watch: {
        appointment() {
            this.thisAppointment = this.appointment;
        },
        isOpen() {
            if (!this.menu) {
                this.menu = this.isOpen;
                this.$router.replace({
                    query: {
                        appointment: undefined,
                    }
                })
            }
        },
        // currentlyOpenAppointment(newVal, oldVal) {
        //     if (!newVal && oldVal && this.thisAppointment && oldVal._id === this.thisAppointment._id) {
        //         this.requestAppointments();
        //     }
        // }
    },
    methods: {
        ...mapActions('translation', [ 'setTranslatedText', 'showTranslation' ]),
        ...mapActions("auth", ["reloadDontLogout"]),
        ...mapActions("util", ['toggleLoading', 'toggleOpenAppointment', 'toggleCurrentUploadGroup']),
        ...mapActions('files', [ 'setSnackbar' ]),

        urlify(text) {
            // eslint-disable-next-line no-useless-escape
            var urlRegex = /(([a-z]+:\/\/)?(([a-z0-9\-]+\.)+([a-z]{2}|aero|arpa|biz|com|software|coop|edu|gov|info|int|jobs|mil|museum|name|nato|net|org|pro|travel|local|internal|de))(:[0-9]{1,5})?(\/[a-z0-9_\-\.~]+)*(\/([a-z0-9_\-\.]*)(\?[a-z0-9+_\-\.%=&amp;]*)?)?(#[a-zA-Z0-9!$&'()*+.=-_~:@/?]*)?)(\s+|$)/gi;
            return text.toString().replace(urlRegex, function(url) {
                url = url.includes('http') ? url : 'http://' + url;
                return '<a href="' + url + '" target="_blank" rel="noopener noreferrer">' + url + '</a>';
            })
            // or alternatively
            // return text.replace(urlRegex, '<a href="$1">$1</a>')
        },
        checkTutorialState() {
            this.bbbTutState = JSON.parse(localStorage.getItem('bbbTut'));
            if(this.bbbTutState) {
                this.bbbTut = false;
            }
        },
        getSrc() {
            return this.thisSubject ? this[this.thisSubject.icon] : (this.thisAppointment ? this[this.thisAppointment.schoolSubject.icon] : null);
        },

        getSrcWhite() {
            /**
             * Cant be done like the top one because the second part of string doesnt mathc with the normal icon, so a search is probably needed
             */
            let temp = this.thisSubject
                ? this[this.thisSubject.icon]
                : this.thisAppointment
                    ? this[this.thisAppointment.schoolSubject.icon]
                    : null;
            let searchString = temp.slice(0, temp.length - 16) + "-weiss";
            let returnString = '';

            for (let i = 0; i < Object.keys(this.$data).length; i++) {
                let key = Object.keys(this.$data)[i];
                if (this.$data[key] && this.$data[key].includes && this.$data[key].includes(searchString)) {
                    returnString = this.$data[key];
                    break;
                }
            }

            return returnString.length > 0 ? returnString : temp;
        },

        getColorIcon() {
            return this.thisSubject ? this['color' + this.thisSubject.color.substring(1)] : (this.thisAppointment ? this['color' + this.thisAppointment.schoolSubject.color.substring(1)] : this.colorb2b2b2);
        },

        /*Hintergrund-Farbe für Icon wenn es aktuell ist*/
        getBackgroundColor() {
            let returnString = "background-color: ";
            return returnString + this.thisAppointment.schoolSubject.color;
        },

        getTimeslotTimes() {
            const timeslot = this.$parent.$parent.slots.find((item) => item._id === this.thisAppointment.timeslot);
            console.log("timeslot: ", timeslot);
            if (timeslot) {
                return (
                    ("0" + new Date(timeslot.start).getHours()).slice(-2) + ":" + ("0" + new Date(timeslot.start).getMinutes()).slice(-2)
                    + ' - ' +
                    ("0" + new Date(timeslot.end).getHours()).slice(-2) + ":" + ("0" + new Date(timeslot.end).getMinutes()).slice(-2));
            }
        },
        async pupilUploadInputChange(appointmentId) {
            const input = this.$refs.pupilUploadInput;
            const file = input.files[0];
            if (file) {
                const name = file.name;
                const lastDot = name.lastIndexOf('.');
                const fileName = name.substring(0, lastDot);
                const fileExtension = name.substring(lastDot + 1);
                this.uploadFileName = fileName;
                this.fileNameCallback = (newFileName) => {
                    this.showNameFile = false;
                    this.uploadFile(appointmentId, newFileName, fileExtension);
                };
                this.showNameFile = true;
            }
        },

        async uploadFile(appointmentId, newFileName, fileExtension) {
            const input = this.$refs.pupilUploadInput;
            const file = input.files[0];
            if (file) {
                if (file.size > 1000000000) {
                    this.setSnackbar({ type: 'bigFileToBig', value: 'true' });
                    return;
                }

                const formData = new FormData();
                formData.append('file', file, `${newFileName}.${fileExtension}`);

                this.pupilXmlHttpRequest = backend.postPupilUpload(appointmentId, file);

                this.pupilXmlHttpRequest.onerror = (e) => {
                    console.error('Pupil upload error:', e)
                    this.showPupilUploadProgress = false;
                    this.pupilXmlHttpRequest = null;
                    this.$refs.pupilUploadInput.value = '';
                };

                this.pupilXmlHttpRequest.onabort = (e) => {
                    console.warn('Pupil upload aborted');
                    this.showPupilUploadProgress = false;
                    this.pupilXmlHttpRequest = null;
                    this.$refs.pupilUploadInput.value = '';
                }

                this.pupilXmlHttpRequest.upload.addEventListener('progress', (e) => {
                    this.pupilUploadProgress = (e.loaded / e.total) * 100;
                });

                this.pupilXmlHttpRequest.addEventListener('load', (e) => {
                    if (this.pupilXmlHttpRequest.status !== 201)
                        console.error('Pupil upload failed:', this.pupilXmlHttpRequest.response);
                    this.showPupilUploadProgress = false;
                    this.snackbarSave = true;
                    this.requestAppointments();
                    this.pupilXmlHttpRequest = null;
                    this.$refs.pupilUploadInput.value = '';
                });
                this.pupilUploadProgress = 0.0;
                this.showPupilUploadProgress = true;
                this.pupilXmlHttpRequest.send(formData);
            }
        },
        clickPupilUploadButton() {
            this.toggleOpenAppointment(this.thisAppointment);
            this.toggleCurrentUploadGroup('pupil');
            document.getElementById("uploadInput").click();
        },

        async clickDownloadTeacherUpload(file) {
            file.seenByMe = true;
            const res = await backend.getTeacherUpload(this.thisAppointment._id, file.file);
            fetch(res.url, {
                method: 'GET',
                headers: new Headers({
                    "Authorization": "Bearer " + this.token
                })
            })
                .then(response => response.blob())
                .then(blob => {
                    var url = window.URL.createObjectURL(blob);
                    var a = document.createElement('a');
                    a.href = url;
                    a.download = file.title;
                    document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                    a.click();
                    a.remove();  //afterwards we remove the element again
                });

        },

        async play(file, index) {
            this.videoLoadings[index] = true;
            file.seenByMe = true;
            const res = await backend.getTeacherUpload(this.thisAppointment._id, file.file);
            fetch(res.url, {
                method: 'GET',
                headers: new Headers({
                    "Authorization": "Bearer " + this.token
                })
            })
                .then(response => response.blob())
                .then(blob => {
                    var url = window.URL.createObjectURL(blob);
                    this.videoUrl = url;
                    this.videoLoadings[index] = false;
                });

        },

        async clickDownloadPupilUpload(file) {
            const res = await backend.getPupilUpload(this.thisAppointment._id, file.file);
            fetch(res.url, {
                method: 'GET',
                headers: new Headers({
                    "Authorization": "Bearer " + this.token
                })
            })
                .then(response => response.blob())
                .then(blob => {
                    var url = window.URL.createObjectURL(blob);
                    var a = document.createElement('a');
                    a.href = url;
                    a.download = file.title;
                    document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                    a.click();
                    a.remove();  //afterwards we remove the element again
                });
        },

        isCorrectionAvailable(pupilFile) {
            for (let i = 0; i < this.thisAppointment.teacherUploads.length; i++) {
                if (this.thisAppointment.teacherUploads[i].editParent) {
                    const index = pupilFile.editLeafs.indexOf(this.thisAppointment.teacherUploads[i]._id);
                    if (index !== -1) {
                        return true;
                    }
                }
            }
            return false;
        },

        getCurrentCorrection(pupilFile) {
            for (let i = 0; i < this.thisAppointment.teacherUploads.length; i++) {
                if (this.thisAppointment.teacherUploads[i].editParent) {
                    const index = pupilFile.editLeafs.indexOf(this.thisAppointment.teacherUploads[i]._id);
                    if (index !== -1) {
                        return this.thisAppointment.teacherUploads[i];
                    }
                }
            }
            return null;
        },

        async clickDownloadPupilCorrection(file) {
            let teacherCorrection = null;

            for (let i = 0; i < this.thisAppointment.teacherUploads.length; i++) {
                if (this.thisAppointment.teacherUploads[i].editParent) {
                    const index = file.editLeafs.indexOf(this.thisAppointment.teacherUploads[i]._id);
                    if (index !== -1) {
                        teacherCorrection = this.thisAppointment.teacherUploads[i];
                    }
                }
            }

            if (teacherCorrection) {
                teacherCorrection.seenByMe = true;

                const res = await backend.getTeacherUpload(this.thisAppointment._id, teacherCorrection.file);

                fetch(res.url, {
                    method: 'GET',
                    headers: new Headers({
                        "Authorization": "Bearer " + this.token
                    })
                })
                    .then(response => response.blob())
                    .then(blob => {
                        var url = window.URL.createObjectURL(blob);
                        var a = document.createElement('a');
                        a.href = url;
                        a.download = teacherCorrection.title;
                        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                        a.click();
                        a.remove();  //afterwards we remove the element again
                    });

            }
        },
        //startBBBTut
        startBBB() {
            /*emit to PlanWidget*/
            this.$emit('pass-on-bbb-tut', this.joinBBB);
        },

        async clickBeitreten() {
            this.menu = false;

            if(!this.bbbTutState) {
                this.startBBB();
            }
            else if (this.bbbTutState) {
                await this.joinBBB();
            }
        },

        async joinBBB() {
            const res = await backend.joinSchoolSubjectAppointmentBbbMeetingUrl(
                this.thisAppointment._id
            );
            this.toggleLoading(true);
            this.reloadDontLogout(true);
            const {redirectUrl} = await res.json();
            if (res.status === 422) {
                this.snackbarText = "Aktuell ist der Raum geschlossen!";
                this.snackbar = true;
            } else {
                window.location.href = redirectUrl;
                // window.open(redirectUrl);
            }
            this.toggleLoading(false);
        },

        async greet(event) {
            if ('speechSynthesis' in window) {
                // Speech Synthesis supported 🎉
            } else {
                // Speech Synthesis Not Supported 😣
                alert("Sorry, your browser doesn't support text to speech!");
                return
            }

            let text = this.thisAppointment.note;
            if (this.thisAppointment.note && this.thisAppointment.note !== '') {
                text = 'Info: ' + this.thisAppointment.note;
            } else {
                text = 'Info: Bitte bereitet schonmal eure Unterlagen vor! Und ladet gegebenfalls vorhandene Materialien herunter.';
            }

            if (this.targetLang !== 'de') {
                text = await translation.translateToTargetLang(this.targetLang, text);

                this.setTranslatedText(text);
                this.showTranslation(true);

                if (this.showTextTimeout) {
                    clearTimeout(this.showTextTimeout);
                    this.showTextTimeout = null;
                }

                this.showTextTimeout = setTimeout(() => {
                    this.showTranslation(false);
                    this.showTextTimeout = null;
                }, 15000)
            }

            if (window.speechSynthesis.speaking) {
                window.speechSynthesis.cancel();
                event.target.src = lautsprecherIcon;
            } else {
                event.target.src = lautsprecherAusIcon;
                let msg = new SpeechSynthesisUtterance();
                msg.text = text;
                if (this.isLangPackageAvailable()) {
                    msg.lang = this.targetLang;
                    window.speechSynthesis.speak(msg);
                } else {
                    event.target.src = lautsprecherIcon;
                }

                msg.onend = function() {
                    event.target.src = lautsprecherIcon;
                }
            }
        },
        isLangPackageAvailable() {
            for (let i = 0; i < window.speechSynthesis.getVoices().length; i++) {
                if (window.speechSynthesis.getVoices()[i].lang.includes(this.targetLang)) {
                    return true;
                }
            }

            return false;
        },
        fileNotSupported(el) {
            this.snackbarFileNotSupported = true;
        },
        openEdit(name, id, uploadId) {
            const group = this.appointment.schoolSubject.name;
            const color = this.appointment.schoolSubject.color;
            const appointmentId = this.appointment._id;
            const fileTitle = name;
            const fileId = id;

            this.$router.push({
                name: 'schueler.edit',
                query:
                    {
                        group: group,
                        color: color,
                        aId: appointmentId,
                        title: fileTitle,
                        fId: fileId,
                        uploadid: uploadId
                    }
            });
        },

        handleOpenAnsichtClick(element) {
            console.log('handleOpenAnsichtClick called with element', element);
            if (this.isCorrectionAvailable(element)) {
                const fileToOpen = this.getCurrentCorrection(element);

                if (fileToOpen) {
                    this.openAnsicht(fileToOpen.title, fileToOpen.file, fileToOpen._id, 'pupil');
                }
            } else {
                this.openAnsicht(element.title, element.file, element._id, 'teacher')
            }
        },

        openAnsicht(name, id, uploadId, userGroup) {

            const group = this.appointment.schoolSubject.name;
            const color = this.appointment.schoolSubject.color;
            const appointmentId = this.appointment._id;
            const fileTitle = name;
            const fileId = id;

            this.$router.push({
                name: 'schueler.view',
                query:
                    {
                        group: group,
                        color: color,
                        aId: appointmentId,
                        title: fileTitle,
                        fId: fileId,
                        uploadid: uploadId,
                        ug: userGroup
                    }
            });
        },
        openEmptyEdit(newPageType) {
            const group = this.appointment.schoolSubject.name;
            const color = this.appointment.schoolSubject.color;
            const appointmentId = this.appointment._id;

            this.$router.push({
                name: 'schueler.edit',
                query:
                    {
                        group: group,
                        color: color,
                        aId: appointmentId,
                        title: group + ' ' + new Date().toLocaleDateString(),
                        pagetype: newPageType,
                    }
            });
        },

        hideEl(element) {
            let x = [];
            x = document.getElementsByClassName(element);
            for (let i = 0; i < x.length; i++) {
                x[i].style.display = "none";
            }
        },

        showEl(element) {
            let x = [];
            x = document.getElementsByClassName(element);
            for (let i = 0; i < x.length; i++) {
                x[i].style.display = "block";
            }
        },

        resetDialogContent() {
            this.hideEl('matLong'); this.showEl('matShort');
            this.hideEl('toCorrectLong'); this.showEl('toCorrectShort');
            this.hideEl('toDoLong'); this.showEl('toDoShort');
            this.hideEl('correctedLong'); this.showEl('correctedShort');
        },

        createVideoLoadArray() {
            if (this.thisAppointment) {
                for (let i = 0; i < this.thisAppointment.teacherUploads.length; i++) {
                    this.videoLoadings[i] = false;
                }
            }
        },
    }
};
</script>


<style lang="scss" scoped>
table,
th,
td {
    border: 1px solid black;
    border-collapse: collapse;
}

tr {
    height: 40px;
}

table tr th:first-child,
table tr td:first-child {
    width: 6em !important;
}

table tr th,
table tr td {
    width: 9em;
}

table,
tr,
td {
    border: none;
}

.fach {
    cursor: pointer;
}

.appointmentItem {
    position: relative;
    color: #707070;
    border-radius: 10px;
    box-shadow: 1px 2px 3px silver;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background-color: #FFF;
    width: 100%;
    height: 100%;
}

.inactiveAppointment {
    opacity: 0.4 !important;
}

.scroll-area {
    position: relative;
    margin: auto;
    max-height: 100px;
}

.scroll-area2 {
    position: relative;
    margin: auto;
    max-height: 100px;
}

.scroll-area3 {
    position: relative;
    margin: auto;
    max-height: 50px;
}

.scroll-area-fachPopup {
    position: relative;
    margin: auto;
    max-height: 100%;
}

.buttonIcon {
    align-self: start;
    background-color: #eaeaeacc;
    padding: 2px;
    border-radius: 3px;
    margin-right: 1em;
}

//border-radius of v-menu
.v-menu__content {
    border-radius: 12px;
}

.iconSelect {
    display: inline-flex;
    width: 24px;
    height: 24px;
    padding: 2px;
    margin-bottom: -6px;
    border-radius: 5px;
    margin-right: 2px;
    background-color: rgba(234, 234, 234, 0.7);
}

.iconSelectFrei {
    display: inline-flex;
    width: 24px;
    padding: 2px;
    margin-bottom: -6px;
    border-radius: 5px;
    margin-right: 2px;
    background-color: transparent;
}

.smallFach {
    position: relative;
    color: #707070;
    border-radius: 10px;
    box-shadow: 1px 2px 3px silver;
    white-space: nowrap;
    width: 70%;
    overflow: hidden;
    text-overflow: ellipsis;
    background-color: #ffffff;
}

.isFachRunning {
    color: white;
    background-color: #8cbd46 !important;
}

.isFachRunningIcon {
    filter: brightness(1000%);
}

.eHr {
    width: 90%;
    margin: auto;
    border-width: 0;
    height: 2px;
    color: #eaeaea;
    background-color: #eaeaea;
}

.eButton {
    width: 33px !important;
    min-width: 33px !important;
    height: 33px !important;
    min-height: 33px !important;
    border-radius: 4px !important;
    box-shadow: 0 5px 10px #00000025 !important;
}

.btnStyle {
    min-width: 36px;
    min-height: 36px;
    margin-top: -3px;
}

.fileName {
    max-width: 85%;
    min-width: 85%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
}

.matLong, .uploadsLong {
    display: none;
}

.brighterFileRow {
    background-color: rgba(224,224,224, 0.15) !important;
}

.correctionAvailableClass {
    background-color: rgba(140, 190, 70, 0.30) !important;
}

@media only screen and (max-width: 399px) {
    #fachPopup {
        max-width: 100vw !important;
    }

    #dialog {
        margin: 0;
    }
}

@media only screen and (max-width: 500px), (max-width: 900px) and (orientation: portrait) {
    .scroll-area-fachPopup {
        /*250px = 59px header, 48px join btn, 70px btmNavBar and paddings,margins*/
        max-height: calc(100vh -  250px);
    }
}

#emptyPageMenu {
    display: block;
    background-color: #F8F8F8;

    & button {
        width: 100%;
    }
}

.v-snack.v-snack--top {
    bottom: initial; /* or auto */
}

.v-snack.v-snack--bottom {
    top: initial; /* or auto */
}
</style>
