var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("progress-bar", {
        attrs: {
          title: "Hochladen",
          "show-progress": _vm.showPupilUploadProgress,
          progress: _vm.pupilUploadProgress,
          "abort-progress": _vm.pupilXmlHttpRequest
            ? function() {
                _vm.pupilXmlHttpRequest.abort()
              }
            : function() {}
        }
      }),
      _c("input", {
        ref: "hausiPupilUploadInput",
        attrs: { id: "hausiPupilUploadInput", type: "file", hidden: "" },
        on: {
          change: function() {
            return _vm.pupilUploadInputChange(
              _vm.item.appointmentId,
              _vm.item._id
            )
          }
        }
      }),
      _c(
        "v-row",
        { staticClass: "mx-0" },
        [
          _c("v-col", { staticClass: "pa-0" }, [
            _c(
              "div",
              { attrs: { id: "hausiWidgetBabyContainer" } },
              [
                _c(
                  "div",
                  { attrs: { id: "hausiWidgetHeader" } },
                  [
                    _c(
                      "v-row",
                      {
                        staticClass: "ml-4",
                        staticStyle: {
                          width: "90%",
                          display: "flex",
                          "align-items": "center"
                        }
                      },
                      [
                        _c(
                          "v-col",
                          {
                            staticClass: "pa-0 text-left d-flex align-center",
                            staticStyle: { "font-size": "xx-large" },
                            attrs: { cols: "9", md: "8", lg: "9" }
                          },
                          [
                            _c("img", {
                              staticStyle: {
                                height: "30px",
                                filter: "brightness(1000%)",
                                "margin-bottom": "-3px"
                              },
                              attrs: { src: _vm.hausaufgabenIconLeer }
                            }),
                            _c("p", { staticClass: "ma-0 pl-2" }, [
                              _vm._v("Hausaufgaben")
                            ])
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm.showLoading
                  ? _c(
                      "div",
                      { staticClass: "d-flex justify-center mt-4" },
                      [
                        _c("v-progress-circular", {
                          attrs: {
                            color: "#6995CD",
                            indeterminate: "",
                            size: "100"
                          }
                        })
                      ],
                      1
                    )
                  : _c(
                      "vue-custom-scrollbar",
                      {
                        staticClass: "scroll-area",
                        attrs: { settings: _vm.settings }
                      },
                      [
                        _c(
                          "div",
                          [
                            _c(
                              "v-row",
                              {
                                staticStyle: {
                                  width: "99%",
                                  margin: "0.5em auto 0.5em auto"
                                },
                                attrs: { cols: "12" }
                              },
                              [
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "sectionHeaderCol",
                                    staticStyle: {
                                      display: "flex",
                                      "align-items": "center"
                                    }
                                  },
                                  [
                                    _c("img", {
                                      staticClass: "sectionHeaderImg",
                                      staticStyle: { height: "30px" },
                                      attrs: { src: _vm.toDoIcon }
                                    }),
                                    _c(
                                      "p",
                                      { staticClass: "pl-2 sectionHeaderText" },
                                      [_vm._v("Noch zu machen:")]
                                    )
                                  ]
                                )
                              ],
                              1
                            ),
                            _vm._l(_vm.babyFilesToDoDisplay, function(
                              item,
                              index
                            ) {
                              return _c(
                                "div",
                                {
                                  key: index,
                                  staticClass:
                                    "fileRow ma-2 d-flex align-center",
                                  class: {
                                    brighterFileRow: index % 2 === 1,
                                    disabledFileRow: false
                                  }
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass:
                                        "ml-2 smallBabyViewActionBtn fachLogo",
                                      style:
                                        "background-color: " +
                                        item.fachColor +
                                        " !important",
                                      attrs: { small: "", disabled: "" }
                                    },
                                    [
                                      _c("img", {
                                        staticClass:
                                          "smallBabyViewActionBtnImg",
                                        attrs: {
                                          src: _vm.getSrc(item.fachIcon)
                                        }
                                      })
                                    ]
                                  ),
                                  _c("div", {
                                    staticClass: "fachLine ma-0 pa-0",
                                    style:
                                      "background-color: " +
                                      item.fachColor +
                                      " !important"
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "fileTitle mx-2",
                                      on: {
                                        click: function($event) {
                                          return _vm.readExerciseName(item)
                                        }
                                      }
                                    },
                                    [_vm._v(" " + _vm._s(item.title) + " ")]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "ma-0 mr-2",
                                      staticStyle: { display: "flex" }
                                    },
                                    [
                                      false
                                        ? _c(
                                            "v-btn",
                                            {
                                              staticClass: "babyViewActionBtn",
                                              attrs: { small: "" }
                                            },
                                            [
                                              _c("img", {
                                                staticClass:
                                                  "babyViewActionBtnImg",
                                                attrs: {
                                                  src: _vm.lautsprecherIcon
                                                }
                                              })
                                            ]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "babyViewActionBtn ml-3",
                                          attrs: { small: "" },
                                          on: {
                                            click: function($event) {
                                              return _vm.clickDownloadTeacherUpload(
                                                item
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("img", {
                                            staticClass: "babyViewActionBtnImg",
                                            attrs: { src: _vm.runterladenIcon }
                                          })
                                        ]
                                      ),
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "babyViewActionBtn ml-3",
                                          attrs: { small: "" },
                                          on: { click: _vm.pupilUploadFile }
                                        },
                                        [
                                          _c("img", {
                                            staticClass: "babyViewActionBtnImg",
                                            attrs: { src: _vm.kameraIcon }
                                          })
                                        ]
                                      ),
                                      [
                                        "mp4",
                                        "wmv",
                                        "flv",
                                        "webm",
                                        "mpg",
                                        "m4v"
                                      ].includes(item.title.split(".").pop())
                                        ? _c(
                                            "v-btn",
                                            {
                                              staticClass:
                                                "babyViewActionBtn edit ml-3",
                                              attrs: {
                                                small: "",
                                                dark: "",
                                                loading: _vm.videoLoading
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.play(item)
                                                }
                                              }
                                            },
                                            [
                                              _c("img", {
                                                staticClass:
                                                  "babyViewActionBtnImg editImg",
                                                attrs: { src: _vm.rechtsIcon }
                                              })
                                            ]
                                          )
                                        : [
                                            "doc",
                                            "docx",
                                            "ppt",
                                            "pptx",
                                            "xls",
                                            "xlsx"
                                          ].includes(
                                            item.title
                                              .split(".")
                                              .pop()
                                              .toLowerCase()
                                          )
                                        ? _c(
                                            "v-btn",
                                            {
                                              staticClass:
                                                "babyViewActionBtn edit ml-3",
                                              staticStyle: {
                                                "background-color": "#8CBD46"
                                              },
                                              attrs: {
                                                dark: "",
                                                "x-small": "",
                                                elevation: "0"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.fileNotSupported(
                                                    item
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c("img", {
                                                staticClass:
                                                  "babyViewActionBtnImg editImg",
                                                attrs: { src: _vm.infoIcon }
                                              })
                                            ]
                                          )
                                        : _c(
                                            "v-btn",
                                            {
                                              staticClass:
                                                "babyViewActionBtn edit ml-3",
                                              attrs: { small: "" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.openEdit(item)
                                                }
                                              }
                                            },
                                            [
                                              _c("img", {
                                                staticClass:
                                                  "babyViewActionBtnImg editImg",
                                                attrs: {
                                                  src: _vm.bearbeitenIcon
                                                }
                                              })
                                            ]
                                          )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            }),
                            _vm.babyFilesToDo.length > 3
                              ? _c(
                                  "div",
                                  { staticClass: "d-flex justify-center my-2" },
                                  [
                                    !this.toDoLong
                                      ? _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              small: "",
                                              elevation: "0"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.unsliceList("toDo")
                                              }
                                            }
                                          },
                                          [
                                            _c("img", {
                                              staticStyle: { height: "20px" },
                                              attrs: { src: _vm.arrowDown }
                                            })
                                          ]
                                        )
                                      : _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              small: "",
                                              elevation: "0"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.sliceList("toDo")
                                              }
                                            }
                                          },
                                          [
                                            _c("img", {
                                              staticStyle: {
                                                height: "20px",
                                                transform: "rotate(180deg)"
                                              },
                                              attrs: { src: _vm.arrowDown }
                                            })
                                          ]
                                        )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.babyFilesToDo && _vm.babyFilesToDo.length === 0
                              ? _c(
                                  "v-row",
                                  {
                                    staticClass: "emptyRow ma-2",
                                    attrs: {
                                      align: "center",
                                      justify: "center"
                                    }
                                  },
                                  [
                                    _c("v-col", { staticClass: "pa-0" }, [
                                      _c("p", [
                                        _vm._v(
                                          "Aktuell gibt es nichts zu erledigen"
                                        )
                                      ])
                                    ])
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          2
                        ),
                        _c("hr", {
                          staticStyle: { width: "95%", margin: "auto" }
                        }),
                        _c(
                          "div",
                          [
                            _c(
                              "v-row",
                              {
                                staticStyle: {
                                  width: "99%",
                                  margin: "0.5em auto 0.5em auto"
                                },
                                attrs: { cols: "12" }
                              },
                              [
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "sectionHeaderCol",
                                    staticStyle: {
                                      display: "flex",
                                      "align-items": "center"
                                    }
                                  },
                                  [
                                    _c("img", {
                                      staticClass: "sectionHeaderImg",
                                      staticStyle: { height: "30px" },
                                      attrs: { src: _vm.doneIcon }
                                    }),
                                    _c(
                                      "p",
                                      { staticClass: "pl-2 sectionHeaderText" },
                                      [_vm._v("Erledigt:")]
                                    )
                                  ]
                                )
                              ],
                              1
                            ),
                            _vm._l(_vm.babyFilesDoneDisplay, function(
                              item,
                              index
                            ) {
                              return _c(
                                "div",
                                {
                                  key: index,
                                  staticClass:
                                    "fileRow ma-2 disabledFileRow d-flex align-center",
                                  class: { brighterFileRow: index % 2 === 1 },
                                  staticStyle: { "flex-direction": "row" }
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass:
                                        "ml-2 smallBabyViewActionBtn fachLogo",
                                      style:
                                        "background-color: " +
                                        item.fachColor +
                                        " !important",
                                      attrs: { small: "", disabled: "" }
                                    },
                                    [
                                      _c("img", {
                                        staticClass:
                                          "smallBabyViewActionBtnImg",
                                        attrs: {
                                          src: _vm.getSrc(item.fachIcon)
                                        }
                                      })
                                    ]
                                  ),
                                  _c("div", {
                                    staticClass: "fachLine ma-0 pa-0",
                                    style:
                                      "background-color: " +
                                      item.fachColor +
                                      " !important"
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "fileTitle mx-2",
                                      on: {
                                        click: function($event) {
                                          return _vm.readExerciseName(item)
                                        }
                                      }
                                    },
                                    [_vm._v(" " + _vm._s(item.title) + " ")]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "ma-0 mr-2",
                                      staticStyle: { display: "flex" }
                                    },
                                    [
                                      false
                                        ? _c(
                                            "v-btn",
                                            {
                                              staticClass: "babyViewActionBtn",
                                              attrs: { small: "" }
                                            },
                                            [
                                              _c("img", {
                                                staticClass:
                                                  "babyViewActionBtnImg",
                                                attrs: {
                                                  src: _vm.lautsprecherIcon
                                                }
                                              })
                                            ]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "babyViewActionBtn ml-3",
                                          attrs: { small: "" },
                                          on: {
                                            click: function($event) {
                                              return _vm.downloadCorrectFile(
                                                item
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("img", {
                                            staticClass: "babyViewActionBtnImg",
                                            attrs: { src: _vm.runterladenIcon }
                                          })
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            }),
                            _vm.babyFilesDone.length > 3
                              ? _c(
                                  "div",
                                  { staticClass: "d-flex justify-center my-2" },
                                  [
                                    !this.doneLong
                                      ? _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              small: "",
                                              elevation: "0"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.unsliceList("done")
                                              }
                                            }
                                          },
                                          [
                                            _c("img", {
                                              staticStyle: { height: "20px" },
                                              attrs: { src: _vm.arrowDown }
                                            })
                                          ]
                                        )
                                      : _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              small: "",
                                              elevation: "0"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.sliceList("done")
                                              }
                                            }
                                          },
                                          [
                                            _c("img", {
                                              staticStyle: {
                                                height: "20px",
                                                transform: "rotate(180deg)"
                                              },
                                              attrs: { src: _vm.arrowDown }
                                            })
                                          ]
                                        )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.babyFilesDone && _vm.babyFilesDone.length === 0
                              ? _c(
                                  "v-row",
                                  {
                                    staticClass: "emptyRow ma-2",
                                    attrs: {
                                      align: "center",
                                      justify: "center"
                                    }
                                  },
                                  [
                                    _c("v-col", { staticClass: "pa-0" }, [
                                      _c("p", [
                                        _vm._v(
                                          "Aktuell gibt es noch nichts Erledigtes"
                                        )
                                      ])
                                    ])
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          2
                        ),
                        _c("hr", {
                          staticStyle: { width: "95%", margin: "auto" }
                        }),
                        _c(
                          "div",
                          [
                            _c(
                              "v-row",
                              {
                                staticStyle: {
                                  width: "99%",
                                  margin: "0.5em auto 0.5em auto"
                                },
                                attrs: { cols: "12" }
                              },
                              [
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "sectionHeaderCol",
                                    staticStyle: {
                                      display: "flex",
                                      "align-items": "center"
                                    }
                                  },
                                  [
                                    _c("img", {
                                      staticClass: "sectionHeaderImg",
                                      staticStyle: { height: "30px" },
                                      attrs: { src: _vm.correctedIcon }
                                    }),
                                    _c(
                                      "p",
                                      { staticClass: "pl-2 sectionHeaderText" },
                                      [_vm._v("Korrigiert:")]
                                    )
                                  ]
                                )
                              ],
                              1
                            ),
                            _vm._l(_vm.babyFilesCorrectedDisplay, function(
                              item,
                              index
                            ) {
                              return _c(
                                "div",
                                {
                                  key: index,
                                  staticClass:
                                    "fileRow ma-2 d-flex align-center",
                                  class: {
                                    brighterFileRow: index % 2 === 1,
                                    disabledFileRow: false
                                  }
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass:
                                        "ml-2 smallBabyViewActionBtn fachLogo",
                                      style:
                                        "background-color: " +
                                        item.fachColor +
                                        " !important",
                                      attrs: { small: "", disabled: "" }
                                    },
                                    [
                                      _c("img", {
                                        staticClass:
                                          "smallBabyViewActionBtnImg",
                                        attrs: {
                                          src: _vm.getSrc(item.fachIcon)
                                        }
                                      })
                                    ]
                                  ),
                                  _c("div", {
                                    staticClass: "fachLine ma-0 pa-0",
                                    style:
                                      "background-color: " +
                                      item.fachColor +
                                      " !important"
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "fileTitle mx-2",
                                      on: {
                                        click: function($event) {
                                          return _vm.readExerciseName(item)
                                        }
                                      }
                                    },
                                    [_vm._v(" " + _vm._s(item.title) + " ")]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "ma-0 mr-2",
                                      staticStyle: { display: "flex" }
                                    },
                                    [
                                      false
                                        ? _c(
                                            "v-btn",
                                            {
                                              staticClass: "babyViewActionBtn",
                                              attrs: { small: "" }
                                            },
                                            [
                                              _c("img", {
                                                staticClass:
                                                  "babyViewActionBtnImg",
                                                attrs: {
                                                  src: _vm.lautsprecherIcon
                                                }
                                              })
                                            ]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "babyViewActionBtn ml-3",
                                          attrs: { small: "" },
                                          on: {
                                            click: function($event) {
                                              return _vm.clickDownloadTeacherUpload(
                                                item
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("img", {
                                            staticClass: "babyViewActionBtnImg",
                                            attrs: { src: _vm.runterladenIcon }
                                          })
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            }),
                            _vm.babyFilesCorrected.length > 3
                              ? _c(
                                  "div",
                                  { staticClass: "d-flex justify-center my-2" },
                                  [
                                    !this.correctedLong
                                      ? _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              small: "",
                                              elevation: "0"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.unsliceList(
                                                  "corrected"
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("img", {
                                              staticStyle: { height: "20px" },
                                              attrs: { src: _vm.arrowDown }
                                            })
                                          ]
                                        )
                                      : _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              small: "",
                                              elevation: "0"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.sliceList(
                                                  "corrected"
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("img", {
                                              staticStyle: {
                                                height: "20px",
                                                transform: "rotate(180deg)"
                                              },
                                              attrs: { src: _vm.arrowDown }
                                            })
                                          ]
                                        )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.babyFilesCorrected &&
                            _vm.babyFilesCorrected.length === 0
                              ? _c(
                                  "v-row",
                                  {
                                    staticClass: "emptyRow ma-2",
                                    attrs: {
                                      align: "center",
                                      justify: "center"
                                    }
                                  },
                                  [
                                    _c("v-col", { staticClass: "pa-0" }, [
                                      _c("p", [
                                        _vm._v(
                                          "Aktuell gibt es nichts Korrigiertes"
                                        )
                                      ])
                                    ])
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          2
                        )
                      ]
                    )
              ],
              1
            )
          ])
        ],
        1
      ),
      _vm.videoUrl
        ? _c(
            "v-overlay",
            { attrs: { opacity: "0.95", "z-index": "95" } },
            [
              _c(
                "v-icon",
                {
                  staticStyle: {
                    position: "fixed",
                    top: "50px",
                    right: "50px",
                    "z-index": "99"
                  },
                  attrs: { large: "", dark: "" },
                  on: {
                    click: function($event) {
                      _vm.videoUrl = false
                    }
                  }
                },
                [_vm._v(" fas fa-times ")]
              ),
              _c(
                "video",
                {
                  style: { marginLeft: _vm.windowWidth > 900 ? "10%" : "55%" },
                  attrs: {
                    src: _vm.videoUrl,
                    controls: "",
                    width: _vm.windowWidth > 900 ? "80%" : "40%"
                  }
                },
                [_vm._v(" Your browser does not support the video tag. ")]
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-snackbar",
        {
          staticStyle: { "z-index": "9999 !important" },
          attrs: { bottom: "", color: "grey", timeout: "5000" },
          model: {
            value: _vm.snackbarFileNotSupported,
            callback: function($$v) {
              _vm.snackbarFileNotSupported = $$v
            },
            expression: "snackbarFileNotSupported"
          }
        },
        [
          _vm._v(
            " Diese Datei wird vom Editor nicht unterstützt. Bitte lade sie herunter. "
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }