import * as backend from '../../api/backend';
import { checkResponseStatus } from '../../util/check';


const defaultState = {
  serverDate: null,
  loading: false,
  versionNumber: null,
  openAppointment: null,
  currentGroup: '',
};

const mutations = {
  serverDate(state, date) {
    state.serverDate = date;
  },
  setLoadingShown(state, loading) {
    state.loading = loading;
  },
  versionNumber(state, version) {
    state.versionNumber = version;
  },
  setOpenAppointment(state, id) {
    state.openAppointment = id;
  },
  setCurrentUploadGroup(state, group) {
    state.currentGroup = group;
  }
};

const getters = {
  isLoadingShown: state => state.loading,
  currentlyOpenAppointment: state => state.openAppointment,
  currentUploadGroup: state => state.currentGroup,
  getServerTime: state => state.serverDate,
}

const actions = {
  async requestSeverTime ({ commit, dispatch }) {
    const res = await backend.getServerTime();

    const serverTime = await res.json();
    commit('serverDate', serverTime);

    return serverTime;
  },
  toggleLoading ({ commit }, loading) {
    commit ('setLoadingShown', loading);
  },
  async requestVersionNumber ({commit}) {
    try {
      const res = await backend.getVersionNumber();
      const version = await res.json();
      commit('versionNumber', version);
      return version;
    } catch (e) {
      return e.res.status;
    }
  },
  toggleOpenAppointment ({ commit }, id) {
    commit ('setOpenAppointment', id);
  },
  toggleCurrentUploadGroup ({ commit }, group) {
    commit ('setCurrentUploadGroup', group);
  }
};

export default {
  namespaced: true,
  state: defaultState,
  mutations,
  getters,
  actions,
};
