var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "pt-0", attrs: { fluid: "" } },
    [
      _c("Navbar", { staticStyle: { "z-index": "9" } }),
      _c("div", { staticClass: "grid-container" }, [
        _c(
          "div",
          { staticClass: "pic" },
          [
            _c("v-img", {
              attrs: { src: require("./../assets/Bilder/kids_on_laptop.svg") }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "demo" },
          [
            _c("v-img", {
              attrs: {
                contain: "",
                src: require("./../assets/Bilder/Demo.png")
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "badge" },
          [
            _c("v-img", {
              attrs: {
                contain: "",
                src: require("./../assets/Bilder/badges.png")
              }
            })
          ],
          1
        )
      ]),
      _c("div", [_c("br"), _c("br"), _c("br")]),
      _c(
        "div",
        {
          staticStyle: { display: "flex", "justify-content": "center" },
          attrs: { id: "stundenplan" }
        },
        [
          _c("v-spacer"),
          _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                "flex-direction": "column",
                "justify-content": "center"
              }
            },
            [
              _c(
                "p",
                {
                  staticClass: "flowerIndie blueText text-center",
                  staticStyle: { "font-size": "40px", "line-height": "50px" }
                },
                [
                  _vm._v(
                    " Digitalisieren Sie Ihre Schule mit der Schulplattform von eStundenplan.de "
                  )
                ]
              ),
              _c("p", { staticClass: "someText segoe greyText text-center" }, [
                _vm._v(" eStundenplan bietet eine einfache, sichere auf "),
                _c("strong", [
                  _vm._v("Ihre Schulbedürfnisse personalisierbare "),
                  _c("br"),
                  _vm._v(" Plattform")
                ]),
                _vm._v(
                  " für den digitalen Schulunterricht ab der ersten Klasse. "
                )
              ]),
              _c("p", { staticClass: "someText segoe greyText text-center" }, [
                _vm._v(
                  " Entwickelt wird die Plattform mit und für Schulen und eignet sich daher für "
                ),
                _c("strong", [_vm._v("viele Schultypen")]),
                _vm._v(". ")
              ]),
              _c(
                "div",
                {
                  staticStyle: { display: "flex", "justify-content": "center" }
                },
                [
                  _c("v-img", {
                    staticStyle: { "justify-self": "center" },
                    attrs: {
                      src: require("./../assets/Bilder/Papagei.svg"),
                      "max-width": "180px"
                    }
                  })
                ],
                1
              )
            ]
          ),
          _c("v-spacer")
        ],
        1
      ),
      _c("div", [_c("br"), _c("br"), _c("br")]),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "flex-direction": "row",
            "justify-content": "center"
          },
          attrs: { id: "funktionen" }
        },
        [
          _c("v-spacer"),
          _c(
            "v-carousel",
            {
              staticClass: "carousel",
              staticStyle: { width: "1500px" },
              attrs: {
                cycle: "",
                progress: "",
                "hide-delimiters": "",
                interval: "7000",
                height: "600"
              }
            },
            [
              _c("v-carousel-item", [
                _c(
                  "div",
                  { staticClass: "carouselContainer" },
                  [
                    _c("v-img", {
                      staticClass: "carouselImg",
                      attrs: {
                        contain: "",
                        src: require("./../assets/Bilder/1-bigbluebutton.png")
                      }
                    }),
                    _c("div", { staticClass: "carouselText" }, [
                      _c(
                        "p",
                        {
                          staticClass:
                            "flowerIndie carouselTextTitle blueHeader shadow"
                        },
                        [_vm._v(" Live-Video-Unterricht")]
                      ),
                      _c(
                        "p",
                        { staticClass: "segoe carouselTextBody greyText" },
                        [
                          _vm._v(" Unterricht digital - Schüler und Lehrer "),
                          _c("br"),
                          _vm._v(" im direkten Kontakt über BigBlueButton. "),
                          _c("br"),
                          _vm._v(" Whiteboard als Unterstützung inklusive! ")
                        ]
                      )
                    ])
                  ],
                  1
                )
              ]),
              _c("v-carousel-item", [
                _c(
                  "div",
                  { staticClass: "carouselContainer" },
                  [
                    _c("v-img", {
                      staticClass: "carouselImg",
                      attrs: {
                        contain: "",
                        src: require("./../assets/Bilder/2-Stunde-bearbeiten.png")
                      }
                    }),
                    _c("div", { staticClass: "carouselText" }, [
                      _c(
                        "p",
                        {
                          staticClass:
                            "flowerIndie carouselTextTitle blueHeader shadow"
                        },
                        [_vm._v(" Stundenplanansicht & Bearbeitung")]
                      ),
                      _c(
                        "p",
                        { staticClass: "segoe carouselTextBody greyText" },
                        [
                          _vm._v(" Ihre Schulverwaltung kann kinderleicht "),
                          _c("br"),
                          _vm._v(" Stundenpläne für alle Klassenstufen "),
                          _c("br"),
                          _vm._v(" erstellen und jederzeit bearbeiten. ")
                        ]
                      )
                    ])
                  ],
                  1
                )
              ]),
              _c("v-carousel-item", [
                _c(
                  "div",
                  { staticClass: "carouselContainer" },
                  [
                    _c("v-img", {
                      staticClass: "carouselImg",
                      attrs: {
                        contain: "",
                        src: require("./../assets/Bilder/3-profile-anlegen.png")
                      }
                    }),
                    _c("div", { staticClass: "carouselText" }, [
                      _c(
                        "p",
                        {
                          staticClass:
                            "flowerIndie carouselTextTitle blueHeader shadow"
                        },
                        [_vm._v(" Profile anlegen")]
                      ),
                      _c(
                        "p",
                        { staticClass: "segoe carouselTextBody greyText" },
                        [
                          _vm._v(" Schüler und Lehrer haben ihr eigenes "),
                          _c("br"),
                          _vm._v(" Profil - sicher und digital bearbeitbar. ")
                        ]
                      )
                    ])
                  ],
                  1
                )
              ]),
              _c("v-carousel-item", [
                _c(
                  "div",
                  { staticClass: "carouselContainer" },
                  [
                    _c("v-img", {
                      staticClass: "carouselImg",
                      attrs: {
                        contain: "",
                        src: require("./../assets/Bilder/4-Fächer-anlegen.png")
                      }
                    }),
                    _c("div", { staticClass: "carouselText" }, [
                      _c(
                        "p",
                        {
                          staticClass:
                            "flowerIndie carouselTextTitle blueHeader shadow"
                        },
                        [_vm._v(" Fächer anlegen")]
                      ),
                      _c(
                        "p",
                        { staticClass: "segoe carouselTextBody greyText" },
                        [
                          _vm._v(" Sie können Ihre Schulfächer ganz einfach "),
                          _c("br"),
                          _vm._v(" anlegen. Fachname, Farbe und Lehrer "),
                          _c("br"),
                          _vm._v(" hinzufügen, fertig! ")
                        ]
                      )
                    ])
                  ],
                  1
                )
              ]),
              _c("v-carousel-item", [
                _c(
                  "div",
                  { staticClass: "carouselContainer" },
                  [
                    _c("v-img", {
                      staticClass: "carouselImg",
                      attrs: {
                        contain: "",
                        src: require("./../assets/Bilder/5-vorlesen.png")
                      }
                    }),
                    _c("div", { staticClass: "carouselText" }, [
                      _c(
                        "p",
                        {
                          staticClass:
                            "flowerIndie carouselTextTitle blueHeader shadow"
                        },
                        [_vm._v(" Vorlese-Funktion bei Leseschwäche")]
                      ),
                      _c(
                        "p",
                        { staticClass: "segoe carouselTextBody greyText" },
                        [
                          _vm._v(" Für alle Profilbesitzer ist eine Vorlese-"),
                          _c("br"),
                          _vm._v(" Funktion bereitgestellt. ")
                        ]
                      )
                    ])
                  ],
                  1
                )
              ]),
              _c("v-carousel-item", [
                _c(
                  "div",
                  { staticClass: "carouselContainer" },
                  [
                    _c("v-img", {
                      staticClass: "carouselImg",
                      attrs: {
                        contain: "",
                        src: require("./../assets/Bilder/6-Tutorial.png")
                      }
                    }),
                    _c("div", { staticClass: "carouselText" }, [
                      _c(
                        "p",
                        {
                          staticClass:
                            "flowerIndie carouselTextTitle blueHeader shadow"
                        },
                        [_vm._v(" Tutorialeinstieg & Support")]
                      ),
                      _c(
                        "p",
                        { staticClass: "segoe carouselTextBody greyText" },
                        [
                          _vm._v(" Zum Einstieg gibt es ein begleitendes "),
                          _c("br"),
                          _vm._v(" Tutorial. Aber auch danach sind wir "),
                          _c("br"),
                          _vm._v(" immer für Sie und Ihre Fragen offen. ")
                        ]
                      )
                    ])
                  ],
                  1
                )
              ])
            ],
            1
          ),
          _c("v-spacer")
        ],
        1
      ),
      _c("div", [_c("br"), _c("br"), _c("br")]),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "flex-direction": "column",
            "justify-content": "center"
          }
        },
        [
          _c("p", { staticClass: "moreTitle flowerIndie blueText" }, [
            _vm._v("& mehr!")
          ]),
          _c("p", { staticClass: "moreText flowerIndie blueText" }, [
            _vm._v("(Hausaufgaben-Editor, Eigener Chat, …)")
          ])
        ]
      ),
      _c("div", [_c("br"), _c("br")]),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "flex-direction": "row",
            "justify-content": "center"
          }
        },
        [
          _c(
            "div",
            {
              staticClass:
                "blueHeader blueBox white--text py-4 px-6 borders shadow",
              staticStyle: {
                display: "flex",
                "flex-direction": "column",
                "justify-content": "center"
              }
            },
            [
              _c(
                "p",
                {
                  staticClass: "segoe blueBoxText",
                  staticStyle: { "align-self": "center" }
                },
                [
                  _c("strong", [_vm._v("Wir sind noch in der Entwicklung")]),
                  _vm._v(" - für "),
                  _c("strong", [_vm._v("Wünsche, Anregungen oder Kritik")]),
                  _vm._v(" haben wir immer ein offenes Ohr.")
                ]
              ),
              _c("p"),
              _c(
                "p",
                {
                  staticClass: "segoe ma-0 blueBoxText",
                  staticStyle: { "align-self": "center" }
                },
                [
                  _vm._v(
                    "Fehlen Ihnen nach Ihrer kostenlosen Demo noch Funktionen? "
                  ),
                  _c("strong", [_vm._v("Melden Sie sich bei uns!")])
                ]
              ),
              _c(
                "p",
                {
                  staticClass: "segoe ma-0 blueBoxText",
                  staticStyle: { "align-self": "center" }
                },
                [
                  _vm._v(
                    "Durch unsere erweiterbaren Einstellungen können wir nahezu perfekt auf Ihre Bedürfnisse eingehen."
                  )
                ]
              )
            ]
          )
        ]
      ),
      _c("div", [_c("br"), _c("br"), _c("br")]),
      _c(
        "div",
        {
          staticClass: "mb-12",
          staticStyle: {
            display: "flex",
            "flex-direction": "row",
            "justify-content": "center",
            height: "200px"
          },
          attrs: { id: "interesse" }
        },
        [
          _c(
            "p",
            {
              staticClass: "flowerIndie blueText text-right ma-0",
              staticStyle: { "font-size": "200px" }
            },
            [_vm._v("1.")]
          )
        ]
      ),
      _c(
        "div",
        { staticStyle: { display: "flex", "justify-content": "center" } },
        [
          _c("div", { staticClass: "stepsLeftSpacer" }),
          _c("div", { staticClass: "stepsContainer" }, [
            _c(
              "div",
              {
                staticClass: "stepsImgContainer",
                staticStyle: { display: "flex", "flex-direction": "column" }
              },
              [
                _c("v-img", {
                  staticClass: "mr-8",
                  attrs: {
                    contain: "",
                    width: "500px",
                    src: require("./../assets/Bilder/1-beratung.png")
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "stepsTextContainer",
                staticStyle: {
                  display: "flex",
                  "flex-direction": "column",
                  "justify-content": "center"
                }
              },
              [
                _c(
                  "p",
                  {
                    staticClass: "flowerIndie blueText fourty",
                    staticStyle: { "line-height": "50px" }
                  },
                  [_vm._v(" Erstgespräch ")]
                ),
                _c("p", { staticClass: "stepsText segoe greyText" }, [
                  _vm._v(
                    " Wir beraten Sie gerne erstmal in Ruhe, wenn gewünscht "
                  ),
                  _c("br"),
                  _vm._v(" auch vor "),
                  _c("strong", [_vm._v("Ort oder via Telefon")]),
                  _vm._v(". Dann können Sie schauen, "),
                  _c("br"),
                  _vm._v(
                    " ob unsere Anwendung zu Ihnen und Ihrer Schule passt. "
                  )
                ]),
                _c("p", { staticClass: "stepsText segoe greyText" }, [
                  _vm._v(" Alternativ können Sie auch direkt eine "),
                  _c("strong", [_vm._v("Demo anfordern:")])
                ]),
                _c(
                  "div",
                  {
                    staticStyle: {
                      display: "flex",
                      "flex-direction": "row",
                      "justify-content": "left"
                    }
                  },
                  [
                    _c("v-img", {
                      staticClass: "stepsSmallImg",
                      attrs: {
                        contain: "",
                        src: require("./../assets/Bilder/Demo.png")
                      }
                    })
                  ],
                  1
                )
              ]
            )
          ]),
          _c("div", { staticClass: "stepsRightSpacer" })
        ]
      ),
      _c("div", [_c("br"), _c("br")]),
      _c(
        "div",
        {
          staticClass: "mb-12",
          staticStyle: {
            display: "flex",
            "flex-direction": "row",
            "justify-content": "center",
            height: "200px"
          }
        },
        [
          _c(
            "p",
            {
              staticClass: "flowerIndie blueText text-right ma-0",
              staticStyle: { "font-size": "200px" }
            },
            [_vm._v("2.")]
          )
        ]
      ),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "justify-content": "center",
            "margin-bottom": "20%"
          }
        },
        [
          _c("div", { staticClass: "stepsLeftSpacer" }),
          _c("div", { staticClass: "stepsContainer" }, [
            _c(
              "div",
              {
                staticClass: "stepsImgContainer",
                staticStyle: { display: "flex", "flex-direction": "column" }
              },
              [
                _c("v-img", {
                  staticClass: "mr-8",
                  attrs: {
                    contain: "",
                    width: "500px",
                    src: require("./../assets/Bilder/2-allinpaket.png")
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "stepsTextContainer",
                staticStyle: {
                  display: "flex",
                  "flex-direction": "column",
                  "justify-content": "center"
                }
              },
              [
                _c(
                  "p",
                  {
                    staticClass: "flowerIndie blueText fourty",
                    staticStyle: { "line-height": "50px" }
                  },
                  [_vm._v(" Buchung ")]
                ),
                _c("p", { staticClass: "stepsText segoe greyText" }, [
                  _vm._v(
                    " Gerne helfen wir Ihnen während Ihres Abos mithilfe "
                  ),
                  _c("br"),
                  _vm._v(" unseres "),
                  _c("strong", [_vm._v("All-Inklusive-Paket")]),
                  _vm._v(" (DE-Server & Hosting, "),
                  _c("br"),
                  _vm._v(
                    " Einrichtung, Schulungen, Support, Wartung & Pflege, etc.). "
                  )
                ]),
                _c(
                  "div",
                  {
                    staticStyle: {
                      display: "flex",
                      "flex-direction": "row",
                      "justify-content": "left"
                    }
                  },
                  [
                    _c("v-img", {
                      staticClass: "stepsSmallImg",
                      attrs: {
                        contain: "",
                        src: require("./../assets/Bilder/badges.png")
                      }
                    })
                  ],
                  1
                )
              ]
            )
          ]),
          _c("div", { staticClass: "stepsRightSpacer" })
        ]
      ),
      _c("div", [_c("br"), _c("br"), _c("br")]),
      _c("Footer")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }