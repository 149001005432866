var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.babyView && _vm.tutorialState
    ? _c(
        "div",
        { staticStyle: { "background-color": "#f8f8f8" } },
        [
          _c("div", { staticClass: "btmNav" }, [
            _c(
              "div",
              {
                staticClass:
                  "d-flex flex-row justify-space-around align-center",
                staticStyle: { height: "100%", width: "100%" }
              },
              [
                _vm._l(_vm.btmNavItemsSimple, function(item) {
                  return _c(
                    "v-btn",
                    {
                      key: item.name,
                      staticClass: "btmNavBtn pa-0",
                      style:
                        _vm.currSlide === item.goToIndex && !_vm.btmNavMenu
                          ? "background-color:" + item.bgColor + "!important;"
                          : "",
                      attrs: { elevation: "0", small: "" },
                      on: {
                        click: function($event) {
                          return _vm.switchSlide(item.goToIndex)
                        }
                      }
                    },
                    [
                      _c("img", {
                        staticClass: "btmNavIcon",
                        style:
                          _vm.currSlide === item.goToIndex && !_vm.btmNavMenu
                            ? "filter: brightness(1000%);"
                            : item.color,
                        attrs: { src: item.icon }
                      })
                    ]
                  )
                }),
                !_vm.textSelected
                  ? _c(
                      "v-btn",
                      {
                        ref: "btmMenuBtn",
                        staticClass: "btmNavBtn pa-0",
                        style: _vm.btmNavMenu
                          ? "background-color: #6995CD !important;"
                          : "",
                        attrs: { elevation: "0", small: "" },
                        on: { click: _vm.handleBtmMenuClick }
                      },
                      [
                        _c("img", {
                          staticClass: "btmNavIcon",
                          style: _vm.btmNavMenu
                            ? "filter: brightness(1000%);"
                            : "filter: brightness(0) saturate(100%) invert(56%) sepia(46%) saturate(448%) hue-rotate(174deg) brightness(93%) contrast(86%);",
                          attrs: { src: _vm.menuIcon }
                        })
                      ]
                    )
                  : _c(
                      "v-btn",
                      {
                        staticClass: "btmNavBtn pa-0",
                        attrs: { elevation: "0", small: "" },
                        on: {
                          click: function($event) {
                            if ($event.target !== $event.currentTarget) {
                              return null
                            }
                            return _vm.clickTTS($event)
                          }
                        }
                      },
                      [
                        _c("TTSSmall", {
                          ref: "tts",
                          attrs: { "icon-size": "35px" }
                        })
                      ],
                      1
                    ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "closable",
                        rawName: "v-closable",
                        value: {
                          exclude: ["btmMenuBtn", "btmMenu"],
                          handler: "onClose"
                        },
                        expression:
                          "{exclude: ['btmMenuBtn', 'btmMenu'], handler: 'onClose'}"
                      }
                    ],
                    ref: "btmMenu",
                    staticClass: "d-flex flex-column pa-2 btmNavMenu",
                    class: _vm.btmNavMenu ? "" : "hideMe",
                    style: _vm.btmNavLangMenu
                      ? "box-shadow: -5px -2px 4px silver;"
                      : "border-top-left-radius: 12px;"
                  },
                  [
                    _c("div", { staticClass: "d-flex pa-2 mb-2" }, [
                      _c("img", {
                        staticStyle: {
                          "border-radius": "13px",
                          "box-shadow": "1px 5px 5px silver"
                        },
                        attrs: { src: _vm.getPersonPic(), height: "25" }
                      }),
                      _c("p", { staticClass: "ml-2 ma-0 ellipsis greyText" }, [
                        _vm._v(_vm._s(_vm.person.name))
                      ])
                    ]),
                    _c(
                      "div",
                      {
                        directives: [{ name: "ripple", rawName: "v-ripple" }],
                        staticClass: "d-flex mb-2 pa-2 btmNavMenuBtn pointer",
                        on: {
                          click: function($event) {
                            _vm.btmNavLangMenu = !_vm.btmNavLangMenu
                          }
                        }
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: _vm.btmNavLangMenu
                              ? _vm.abbrechenIcon
                              : _vm.currentTTSLangImg,
                            height: "25"
                          }
                        }),
                        _c("p", { staticClass: "ml-2 ma-0" }, [
                          _vm._v("Sprache")
                        ])
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "pa-2 pr-0 btmNavLangMenu",
                        class: _vm.btmNavLangMenu ? "" : "hideMe"
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "d-flex" },
                          _vm._l(_vm.btmNavLangItems.slice(0, 3), function(
                            lang
                          ) {
                            return _c(
                              "div",
                              {
                                key: lang.countryCode,
                                staticClass:
                                  "btmNavLangBtn d-flex mb-2 mr-2 pa-2",
                                class:
                                  _vm.currentTTSLangImg === lang.icon
                                    ? "justify-center align-center langBtnBg border"
                                    : lang.icon !== ""
                                    ? "langBtnBg pointer"
                                    : "",
                                on: {
                                  click: function($event) {
                                    return _vm.changeLangBtmNav(
                                      lang.countryCode,
                                      lang.icon
                                    )
                                  }
                                }
                              },
                              [
                                lang.icon !== ""
                                  ? _c("img", {
                                      attrs: {
                                        src: lang.icon,
                                        height: "25",
                                        width: "25"
                                      }
                                    })
                                  : _c("div", {
                                      staticClass: "btmNavLangNoIcon"
                                    })
                              ]
                            )
                          }),
                          0
                        ),
                        _c(
                          "div",
                          { staticClass: "d-flex" },
                          _vm._l(_vm.btmNavLangItems.slice(3, 6), function(
                            lang
                          ) {
                            return _c(
                              "div",
                              {
                                key: lang.countryCode,
                                staticClass:
                                  "btmNavLangBtn d-flex mb-2 mr-2 pa-2",
                                class:
                                  _vm.currentTTSLangImg === lang.icon
                                    ? "justify-center align-center langBtnBg border"
                                    : lang.icon !== ""
                                    ? "langBtnBg pointer"
                                    : "",
                                on: {
                                  click: function($event) {
                                    return _vm.changeLangBtmNav(
                                      lang.countryCode,
                                      lang.icon
                                    )
                                  }
                                }
                              },
                              [
                                lang.icon !== ""
                                  ? _c("img", {
                                      attrs: {
                                        src: lang.icon,
                                        height: "25",
                                        width: "25"
                                      }
                                    })
                                  : _c("div", {
                                      staticClass: "btmNavLangNoIcon"
                                    })
                              ]
                            )
                          }),
                          0
                        ),
                        _c(
                          "div",
                          { staticClass: "d-flex" },
                          _vm._l(_vm.btmNavLangItems.slice(6, 9), function(
                            lang
                          ) {
                            return _c(
                              "div",
                              {
                                key: lang.countryCode,
                                staticClass:
                                  "btmNavLangBtn d-flex mb-2 mr-2 pa-2",
                                class:
                                  _vm.currentTTSLangImg === lang.icon
                                    ? "justify-center align-center langBtnBg border"
                                    : lang.icon !== ""
                                    ? "langBtnBg pointer"
                                    : "",
                                on: {
                                  click: function($event) {
                                    return _vm.changeLangBtmNav(
                                      lang.countryCode,
                                      lang.icon
                                    )
                                  }
                                }
                              },
                              [
                                lang.icon !== ""
                                  ? _c("img", {
                                      attrs: {
                                        src: lang.icon,
                                        height: "25",
                                        width: "25"
                                      }
                                    })
                                  : _c("div", {
                                      staticClass: "btmNavLangNoIcon"
                                    })
                              ]
                            )
                          }),
                          0
                        ),
                        _c(
                          "div",
                          { staticClass: "d-flex" },
                          _vm._l(_vm.btmNavLangItems.slice(9, 12), function(
                            lang
                          ) {
                            return _c(
                              "div",
                              {
                                key: lang.countryCode,
                                staticClass:
                                  "btmNavLangBtn d-flex mb-2 mr-2 pa-2",
                                class:
                                  _vm.currentTTSLangImg === lang.icon
                                    ? "justify-center align-center langBtnBg border"
                                    : lang.icon !== ""
                                    ? "langBtnBg pointer"
                                    : "",
                                on: {
                                  click: function($event) {
                                    return _vm.changeLangBtmNav(
                                      lang.countryCode,
                                      lang.icon
                                    )
                                  }
                                }
                              },
                              [
                                lang.icon !== ""
                                  ? _c("img", {
                                      attrs: {
                                        src: lang.icon,
                                        height: "25",
                                        width: "25"
                                      }
                                    })
                                  : _c("div", {
                                      staticClass: "btmNavLangNoIcon"
                                    })
                              ]
                            )
                          }),
                          0
                        ),
                        _c(
                          "div",
                          { staticClass: "d-flex" },
                          _vm._l(_vm.btmNavLangItems.slice(12, 15), function(
                            lang
                          ) {
                            return _c(
                              "div",
                              {
                                key: lang.countryCode,
                                staticClass: "btmNavLangBtn d-flex mr-2 pa-2",
                                class:
                                  _vm.currentTTSLangImg === lang.icon
                                    ? "justify-center align-center langBtnBg border"
                                    : lang.icon !== ""
                                    ? "langBtnBg pointer"
                                    : "",
                                on: {
                                  click: function($event) {
                                    return _vm.changeLangBtmNav(
                                      lang.countryCode,
                                      lang.icon
                                    )
                                  }
                                }
                              },
                              [
                                lang.icon !== ""
                                  ? _c("img", {
                                      attrs: {
                                        src: lang.icon,
                                        height: "25",
                                        width: "25"
                                      }
                                    })
                                  : _c("div", {
                                      staticClass: "btmNavLangNoIcon"
                                    })
                              ]
                            )
                          }),
                          0
                        )
                      ]
                    ),
                    _c(
                      "div",
                      {
                        directives: [{ name: "ripple", rawName: "v-ripple" }],
                        staticClass: "d-flex mb-2 pa-2 btmNavMenuBtn pointer",
                        on: {
                          click: function($event) {
                            _vm.reloadPage()
                            _vm.closeBtmNavMenus
                          }
                        }
                      },
                      [
                        _c("img", {
                          attrs: { src: _vm.aktualisierenIcon, height: "25" }
                        }),
                        _c("p", { staticClass: "ml-2 ma-0" }, [
                          _vm._v("Aktualisieren")
                        ])
                      ]
                    ),
                    _c(
                      "div",
                      {
                        directives: [{ name: "ripple", rawName: "v-ripple" }],
                        staticClass: "d-flex mb-2 pa-2 btmNavMenuBtn pointer",
                        on: {
                          click: function($event) {
                            _vm.resetTutorial()
                            _vm.closeBtmNavMenus
                          }
                        }
                      },
                      [
                        _c("img", {
                          attrs: { src: _vm.fragezeichenIcon, height: "25" }
                        }),
                        _c("p", { staticClass: "ml-2 ma-0" }, [
                          _vm._v("Tutorial")
                        ])
                      ]
                    ),
                    _c(
                      "div",
                      {
                        directives: [{ name: "ripple", rawName: "v-ripple" }],
                        staticClass: "d-flex pa-2 btmNavMenuBtn pointer",
                        on: {
                          click: function($event) {
                            _vm.clickLogoutUser()
                            _vm.closeBtmNavMenus
                          }
                        }
                      },
                      [
                        _c("img", {
                          attrs: { src: _vm.logoutIcon, height: "25" }
                        }),
                        _c("p", { staticClass: "ml-2 ma-0" }, [
                          _vm._v("Abmelden")
                        ])
                      ]
                    )
                  ]
                )
              ],
              2
            )
          ]),
          _c(
            "v-row",
            {
              staticClass: "displayHide",
              staticStyle: { "background-color": "#EEEEEE" }
            },
            [
              _c(
                "v-col",
                {
                  staticClass: "d-flex align-center",
                  staticStyle: { "margin-left": "5%" },
                  attrs: { cols: "4" }
                },
                [_c("img", { attrs: { src: _vm.logo, height: "50px" } })]
              ),
              _c(
                "v-col",
                {
                  staticClass: "d-flex justify-end align-center",
                  staticStyle: { "margin-right": "5%" }
                },
                [
                  _vm.person
                    ? _c("img", {
                        staticStyle: { "max-width": "100px" },
                        attrs: { src: _vm.getPersonPic(), height: "35" }
                      })
                    : _vm._e(),
                  _c("p", { staticClass: "ma-0 ml-2 ellipsis greyText" }, [
                    _vm._v(_vm._s(_vm.person ? _vm.person.name : ""))
                  ]),
                  _c("BabyChatDropDown"),
                  _c(
                    "v-btn",
                    {
                      staticClass: "ml-4 animateIcon",
                      staticStyle: {
                        "background-color": "#FFFFFF",
                        width: "50px",
                        height: "50px",
                        "border-radius": "8px"
                      },
                      attrs: { "x-small": "", elevation: "0" },
                      on: {
                        click: function($event) {
                          return _vm.reloadPage()
                        }
                      }
                    },
                    [
                      _c("img", {
                        staticStyle: {
                          "max-width": "25px",
                          "max-height": "25px"
                        },
                        attrs: { src: _vm.aktualisierenIcon }
                      })
                    ]
                  ),
                  !_vm.textSelected
                    ? _c(
                        "v-btn",
                        {
                          ref: "topMenuBtn",
                          staticClass: "btmNavBtn pa-0 ml-4",
                          style: _vm.topMenu
                            ? "background-color: #6995CD !important;"
                            : "",
                          attrs: { elevation: "0", small: "" },
                          on: { click: _vm.handleTopMenuClick }
                        },
                        [
                          _c("img", {
                            staticStyle: {
                              "max-width": "25px",
                              "max-height": "25px"
                            },
                            style: _vm.topMenu
                              ? "filter: brightness(1000%);"
                              : "filter: brightness(0) saturate(100%) invert(56%) sepia(46%) saturate(448%) hue-rotate(174deg) brightness(93%) contrast(86%);",
                            attrs: { src: _vm.menuIcon }
                          })
                        ]
                      )
                    : _c(
                        "v-btn",
                        {
                          staticClass: "btmNavBtn pa-0 ml-4",
                          staticStyle: {
                            "background-color": "#6995CD !important"
                          },
                          attrs: { elevation: "0", small: "" },
                          on: {
                            click: function($event) {
                              if ($event.target !== $event.currentTarget) {
                                return null
                              }
                              return _vm.clickTTS($event)
                            }
                          }
                        },
                        [
                          _c("TTSSmall", {
                            ref: "tts",
                            attrs: { "icon-color": "white" }
                          })
                        ],
                        1
                      ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "closable",
                          rawName: "v-closable",
                          value: {
                            exclude: ["topMenuBtn"],
                            handler: "onCloseTop"
                          },
                          expression:
                            "{exclude: ['topMenuBtn'], handler: 'onCloseTop'}"
                        }
                      ],
                      staticClass: "d-flex flex-column pa-2 topMenu",
                      class: _vm.topMenu ? "" : "hideMe",
                      staticStyle: { "z-index": "2" },
                      style: _vm.topLangMenu
                        ? "box-shadow: -5px -2px 4px silver;"
                        : "border-bottom-left-radius: 12px"
                    },
                    [
                      _c(
                        "div",
                        {
                          directives: [{ name: "ripple", rawName: "v-ripple" }],
                          staticClass: "d-flex mb-2 pa-2 btmNavMenuBtn pointer",
                          on: {
                            click: function($event) {
                              _vm.topLangMenu = !_vm.topLangMenu
                            }
                          }
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: _vm.topLangMenu
                                ? _vm.abbrechenIcon
                                : _vm.currentTTSLangImg,
                              height: "25"
                            }
                          }),
                          _c("p", { staticClass: "ml-2 ma-0" }, [
                            _vm._v("Sprache")
                          ])
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "pa-2 pr-0 topLangMenu",
                          class: _vm.topLangMenu ? "" : "hideMe"
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex" },
                            _vm._l(_vm.btmNavLangItems.slice(0, 3), function(
                              lang
                            ) {
                              return _c(
                                "div",
                                {
                                  key: lang.countryCode,
                                  staticClass:
                                    "btmNavLangBtn d-flex mb-2 mr-2 pa-2",
                                  class:
                                    _vm.currentTTSLangImg === lang.icon
                                      ? "justify-center align-center langBtnBg border"
                                      : lang.icon !== ""
                                      ? "langBtnBg pointer"
                                      : "",
                                  on: {
                                    click: function($event) {
                                      return _vm.changeLangNav(
                                        lang.countryCode,
                                        lang.icon
                                      )
                                    }
                                  }
                                },
                                [
                                  lang.icon !== ""
                                    ? _c("img", {
                                        attrs: {
                                          src: lang.icon,
                                          height: "25",
                                          width: "25"
                                        }
                                      })
                                    : _c("div", {
                                        staticClass: "btmNavLangNoIcon"
                                      })
                                ]
                              )
                            }),
                            0
                          ),
                          _c(
                            "div",
                            { staticClass: "d-flex" },
                            _vm._l(_vm.btmNavLangItems.slice(3, 6), function(
                              lang
                            ) {
                              return _c(
                                "div",
                                {
                                  key: lang.countryCode,
                                  staticClass:
                                    "btmNavLangBtn d-flex mb-2 mr-2 pa-2",
                                  class:
                                    _vm.currentTTSLangImg === lang.icon
                                      ? "justify-center align-center langBtnBg border"
                                      : lang.icon !== ""
                                      ? "langBtnBg pointer"
                                      : "",
                                  on: {
                                    click: function($event) {
                                      return _vm.changeLangNav(
                                        lang.countryCode,
                                        lang.icon
                                      )
                                    }
                                  }
                                },
                                [
                                  lang.icon !== ""
                                    ? _c("img", {
                                        attrs: {
                                          src: lang.icon,
                                          height: "25",
                                          width: "25"
                                        }
                                      })
                                    : _c("div", {
                                        staticClass: "btmNavLangNoIcon"
                                      })
                                ]
                              )
                            }),
                            0
                          ),
                          _c(
                            "div",
                            { staticClass: "d-flex" },
                            _vm._l(_vm.btmNavLangItems.slice(6, 9), function(
                              lang
                            ) {
                              return _c(
                                "div",
                                {
                                  key: lang.countryCode,
                                  staticClass: "btmNavLangBtn d-flex mr-2 pa-2",
                                  class:
                                    _vm.currentTTSLangImg === lang.icon
                                      ? "justify-center align-center langBtnBg border"
                                      : lang.icon !== ""
                                      ? "langBtnBg pointer"
                                      : "",
                                  on: {
                                    click: function($event) {
                                      return _vm.changeLangNav(
                                        lang.countryCode,
                                        lang.icon
                                      )
                                    }
                                  }
                                },
                                [
                                  lang.icon !== ""
                                    ? _c("img", {
                                        attrs: {
                                          src: lang.icon,
                                          height: "25",
                                          width: "25"
                                        }
                                      })
                                    : _c("div", {
                                        staticClass: "btmNavLangNoIcon"
                                      })
                                ]
                              )
                            }),
                            0
                          )
                        ]
                      ),
                      _c(
                        "div",
                        {
                          directives: [{ name: "ripple", rawName: "v-ripple" }],
                          staticClass: "d-flex mb-2 pa-2 btmNavMenuBtn pointer",
                          on: {
                            click: function($event) {
                              _vm.resetTutorial()
                              _vm.closeTopMenus
                            }
                          }
                        },
                        [
                          _c("img", {
                            attrs: { src: _vm.fragezeichenIcon, height: "25" }
                          }),
                          _c("p", { staticClass: "ml-2 ma-0" }, [
                            _vm._v("Tutorial")
                          ])
                        ]
                      ),
                      _c(
                        "div",
                        {
                          directives: [{ name: "ripple", rawName: "v-ripple" }],
                          staticClass: "d-flex pa-2 btmNavMenuBtn pointer",
                          on: {
                            click: function($event) {
                              _vm.clickLogoutUser()
                              _vm.closeTopMenus
                            }
                          }
                        },
                        [
                          _c("img", {
                            attrs: { src: _vm.logoutIcon, height: "25" }
                          }),
                          _c("p", { staticClass: "ml-2 ma-0" }, [
                            _vm._v("Abmelden")
                          ])
                        ]
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm.windowWidth > 900
            ? _c(
                "div",
                { staticClass: "big" },
                [
                  _c(
                    "v-row",
                    { staticStyle: { "max-width": "100%" } },
                    [
                      _c(
                        "v-col",
                        { staticClass: "pr-0", attrs: { cols: "6" } },
                        [
                          _c("PlanWidgetBaby", {
                            on: { "start-bbb-tut": _vm.startBBBTut }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "px-0", attrs: { cols: "6" } },
                        [_c("HausiWidgetBaby")],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _c(
                "VueSlickCarousel",
                {
                  ref: "carouselBabyView",
                  staticClass: "small topPadding",
                  attrs: {
                    arrows: false,
                    initialSlide: 0,
                    infinite: false,
                    dots: false
                  },
                  on: {
                    afterChange: function($event) {
                      _vm.slideChangeAllowed = true
                      _vm.setCurrSlide(
                        _vm.$refs.carouselBabyView.$refs.innerSlider
                          .currentSlide
                      )
                    }
                  }
                },
                [
                  _c("PlanWidgetBaby", {
                    attrs: { small: true },
                    on: { "start-bbb-tut": _vm.startBBBTut }
                  }),
                  _c("HausiWidgetBaby", { staticStyle: { height: "100vh" } }),
                  _c("BabyChatWidget")
                ],
                1
              )
        ],
        1
      )
    : _vm.tutorialState
    ? _c(
        "div",
        { staticStyle: { "background-color": "#f8f8f8" } },
        [
          _c("div", { staticClass: "btmNav" }, [
            _c(
              "div",
              {
                staticClass:
                  "d-flex flex-row justify-space-around align-center",
                staticStyle: { height: "100%", width: "100%" }
              },
              [
                _vm._l(_vm.btmNavItems, function(item) {
                  return _c(
                    "v-btn",
                    {
                      key: item.name,
                      staticClass: "btmNavBtn pa-0",
                      style:
                        _vm.currSlide === item.goToIndex && !_vm.btmNavMenu
                          ? "background-color:" + item.bgColor + "!important;"
                          : "",
                      attrs: { elevation: "0", small: "" },
                      on: {
                        click: function($event) {
                          return _vm.switchSlide(item.goToIndex)
                        }
                      }
                    },
                    [
                      _c("img", {
                        staticClass: "btmNavIcon",
                        style:
                          _vm.currSlide === item.goToIndex && !_vm.btmNavMenu
                            ? "filter: brightness(1000%);"
                            : item.color,
                        attrs: { src: item.icon }
                      })
                    ]
                  )
                }),
                !_vm.textSelected
                  ? _c(
                      "v-btn",
                      {
                        ref: "btmMenuBtn",
                        staticClass: "btmNavBtn pa-0",
                        style: _vm.btmNavMenu
                          ? "background-color: #6995CD !important;"
                          : "",
                        attrs: { elevation: "0", small: "" },
                        on: { click: _vm.handleBtmMenuClick }
                      },
                      [
                        _c("img", {
                          staticClass: "btmNavIcon",
                          style: _vm.btmNavMenu
                            ? "filter: brightness(1000%);"
                            : "filter: brightness(0) saturate(100%) invert(56%) sepia(46%) saturate(448%) hue-rotate(174deg) brightness(93%) contrast(86%);",
                          attrs: { src: _vm.menuIcon }
                        })
                      ]
                    )
                  : _c(
                      "v-btn",
                      {
                        staticClass: "btmNavBtn pa-0",
                        attrs: { elevation: "0", small: "" },
                        on: {
                          click: function($event) {
                            if ($event.target !== $event.currentTarget) {
                              return null
                            }
                            return _vm.clickTTS($event)
                          }
                        }
                      },
                      [
                        _c("TTSSmall", {
                          ref: "tts",
                          attrs: { "icon-size": "35px" }
                        })
                      ],
                      1
                    ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "closable",
                        rawName: "v-closable",
                        value: {
                          exclude: ["btmMenuBtn", "btmMenu"],
                          handler: "onClose"
                        },
                        expression:
                          "{exclude: ['btmMenuBtn', 'btmMenu'], handler: 'onClose'}"
                      }
                    ],
                    ref: "btmMenu",
                    staticClass: "d-flex flex-column pa-2 btmNavMenu",
                    class: _vm.btmNavMenu ? "" : "hideMe",
                    style: _vm.btmNavLangMenu
                      ? "box-shadow: -5px -2px 4px silver;"
                      : "border-top-left-radius: 12px;"
                  },
                  [
                    _c("div", { staticClass: "d-flex pa-2 mb-2" }, [
                      _c("img", {
                        staticStyle: {
                          "border-radius": "13px",
                          "box-shadow": "1px 5px 5px silver"
                        },
                        attrs: { src: _vm.getPersonPic(), height: "25" }
                      }),
                      _c("p", { staticClass: "ml-2 ma-0 ellipsis greyText" }, [
                        _vm._v(_vm._s(_vm.person ? _vm.person.name : ""))
                      ])
                    ]),
                    _c(
                      "div",
                      {
                        directives: [{ name: "ripple", rawName: "v-ripple" }],
                        staticClass: "d-flex mb-2 pa-2 btmNavMenuBtn pointer",
                        on: {
                          click: function($event) {
                            _vm.btmNavLangMenu = !_vm.btmNavLangMenu
                          }
                        }
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: _vm.btmNavLangMenu
                              ? _vm.abbrechenIcon
                              : _vm.currentTTSLangImg,
                            height: "25"
                          }
                        }),
                        _c("p", { staticClass: "ml-2 ma-0" }, [
                          _vm._v("Sprache")
                        ])
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "pa-2 pr-0 btmNavLangMenu",
                        class: _vm.btmNavLangMenu ? "" : "hideMe"
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "d-flex" },
                          _vm._l(_vm.btmNavLangItems.slice(0, 3), function(
                            lang
                          ) {
                            return _c(
                              "div",
                              {
                                key: lang.countryCode,
                                staticClass:
                                  "btmNavLangBtn d-flex mb-2 mr-2 pa-2",
                                class:
                                  _vm.currentTTSLangImg === lang.icon
                                    ? "justify-center align-center langBtnBg border"
                                    : lang.icon !== ""
                                    ? "langBtnBg pointer"
                                    : "",
                                on: {
                                  click: function($event) {
                                    return _vm.changeLangBtmNav(
                                      lang.countryCode,
                                      lang.icon
                                    )
                                  }
                                }
                              },
                              [
                                lang.icon !== ""
                                  ? _c("img", {
                                      attrs: {
                                        src: lang.icon,
                                        height: "25",
                                        width: "25"
                                      }
                                    })
                                  : _c("div", {
                                      staticClass: "btmNavLangNoIcon"
                                    })
                              ]
                            )
                          }),
                          0
                        ),
                        _c(
                          "div",
                          { staticClass: "d-flex" },
                          _vm._l(_vm.btmNavLangItems.slice(3, 6), function(
                            lang
                          ) {
                            return _c(
                              "div",
                              {
                                key: lang.countryCode,
                                staticClass:
                                  "btmNavLangBtn d-flex mb-2 mr-2 pa-2",
                                class:
                                  _vm.currentTTSLangImg === lang.icon
                                    ? "justify-center align-center langBtnBg border"
                                    : lang.icon !== ""
                                    ? "langBtnBg pointer"
                                    : "",
                                on: {
                                  click: function($event) {
                                    return _vm.changeLangBtmNav(
                                      lang.countryCode,
                                      lang.icon
                                    )
                                  }
                                }
                              },
                              [
                                lang.icon !== ""
                                  ? _c("img", {
                                      attrs: {
                                        src: lang.icon,
                                        height: "25",
                                        width: "25"
                                      }
                                    })
                                  : _c("div", {
                                      staticClass: "btmNavLangNoIcon"
                                    })
                              ]
                            )
                          }),
                          0
                        ),
                        _c(
                          "div",
                          { staticClass: "d-flex" },
                          _vm._l(_vm.btmNavLangItems.slice(6, 9), function(
                            lang
                          ) {
                            return _c(
                              "div",
                              {
                                key: lang.countryCode,
                                staticClass:
                                  "btmNavLangBtn d-flex mb-2 mr-2 pa-2",
                                class:
                                  _vm.currentTTSLangImg === lang.icon
                                    ? "justify-center align-center langBtnBg border"
                                    : lang.icon !== ""
                                    ? "langBtnBg pointer"
                                    : "",
                                on: {
                                  click: function($event) {
                                    return _vm.changeLangBtmNav(
                                      lang.countryCode,
                                      lang.icon
                                    )
                                  }
                                }
                              },
                              [
                                lang.icon !== ""
                                  ? _c("img", {
                                      attrs: {
                                        src: lang.icon,
                                        height: "25",
                                        width: "25"
                                      }
                                    })
                                  : _c("div", {
                                      staticClass: "btmNavLangNoIcon"
                                    })
                              ]
                            )
                          }),
                          0
                        ),
                        _c(
                          "div",
                          { staticClass: "d-flex" },
                          _vm._l(_vm.btmNavLangItems.slice(9, 12), function(
                            lang
                          ) {
                            return _c(
                              "div",
                              {
                                key: lang.countryCode,
                                staticClass:
                                  "btmNavLangBtn d-flex mb-2 mr-2 pa-2",
                                class:
                                  _vm.currentTTSLangImg === lang.icon
                                    ? "justify-center align-center langBtnBg border"
                                    : lang.icon !== ""
                                    ? "langBtnBg"
                                    : "",
                                on: {
                                  click: function($event) {
                                    return _vm.changeLangBtmNav(
                                      lang.countryCode,
                                      lang.icon
                                    )
                                  }
                                }
                              },
                              [
                                lang.icon !== ""
                                  ? _c("img", {
                                      attrs: {
                                        src: lang.icon,
                                        height: "25",
                                        width: "25"
                                      }
                                    })
                                  : _c("div", {
                                      staticClass: "btmNavLangNoIcon"
                                    })
                              ]
                            )
                          }),
                          0
                        ),
                        _c(
                          "div",
                          { staticClass: "d-flex" },
                          _vm._l(_vm.btmNavLangItems.slice(12, 15), function(
                            lang
                          ) {
                            return _c(
                              "div",
                              {
                                key: lang.countryCode,
                                staticClass: "btmNavLangBtn d-flex mr-2 pa-2",
                                class:
                                  _vm.currentTTSLangImg === lang.icon
                                    ? "justify-center align-center langBtnBg border"
                                    : lang.icon !== ""
                                    ? "langBtnBg"
                                    : "",
                                on: {
                                  click: function($event) {
                                    return _vm.changeLangBtmNav(
                                      lang.countryCode,
                                      lang.icon
                                    )
                                  }
                                }
                              },
                              [
                                lang.icon !== ""
                                  ? _c("img", {
                                      attrs: {
                                        src: lang.icon,
                                        height: "25",
                                        width: "25"
                                      }
                                    })
                                  : _c("div", {
                                      staticClass: "btmNavLangNoIcon"
                                    })
                              ]
                            )
                          }),
                          0
                        )
                      ]
                    ),
                    _c(
                      "div",
                      {
                        directives: [{ name: "ripple", rawName: "v-ripple" }],
                        staticClass: "d-flex mb-2 pa-2 btmNavMenuBtn pointer",
                        on: {
                          click: function($event) {
                            _vm.reloadPage()
                            _vm.closeBtmNavMenus
                          }
                        }
                      },
                      [
                        _c("img", {
                          attrs: { src: _vm.aktualisierenIcon, height: "25" }
                        }),
                        _c("p", { staticClass: "ml-2 ma-0" }, [
                          _vm._v("Aktualisieren")
                        ])
                      ]
                    ),
                    _c(
                      "div",
                      {
                        directives: [{ name: "ripple", rawName: "v-ripple" }],
                        staticClass: "d-flex mb-2 pa-2 btmNavMenuBtn pointer",
                        on: {
                          click: function($event) {
                            _vm.resetTutorial()
                            _vm.closeBtmNavMenus
                          }
                        }
                      },
                      [
                        _c("img", {
                          attrs: { src: _vm.fragezeichenIcon, height: "25" }
                        }),
                        _c("p", { staticClass: "ml-2 ma-0" }, [
                          _vm._v("Tutorial")
                        ])
                      ]
                    ),
                    _c(
                      "div",
                      {
                        directives: [{ name: "ripple", rawName: "v-ripple" }],
                        staticClass: "d-flex pa-2 btmNavMenuBtn pointer",
                        on: {
                          click: function($event) {
                            _vm.clickLogoutUser()
                            _vm.closeBtmNavMenus
                          }
                        }
                      },
                      [
                        _c("img", {
                          attrs: { src: _vm.logoutIcon, height: "25" }
                        }),
                        _c("p", { staticClass: "ml-2 ma-0" }, [
                          _vm._v("Abmelden")
                        ])
                      ]
                    )
                  ]
                )
              ],
              2
            )
          ]),
          _c(
            "v-row",
            {
              staticClass: "displayHide",
              staticStyle: { "background-color": "#EEEEEE" }
            },
            [
              _c(
                "v-col",
                {
                  staticClass: "d-flex align-center",
                  staticStyle: { "margin-left": "5%" },
                  attrs: { cols: "4" }
                },
                [_c("img", { attrs: { src: _vm.logo, height: "50px" } })]
              ),
              _c(
                "v-col",
                {
                  staticClass: "d-flex justify-end align-center",
                  staticStyle: { "margin-right": "5%" }
                },
                [
                  _vm.person
                    ? _c("img", {
                        staticStyle: { "max-width": "100px" },
                        attrs: { src: _vm.getPersonPic(), height: "35" }
                      })
                    : _vm._e(),
                  _c("p", { staticClass: "ma-0 ml-2 ellipsis greyText" }, [
                    _vm._v(_vm._s(_vm.person ? _vm.person.name : ""))
                  ]),
                  _c(
                    "v-btn",
                    {
                      staticClass: "ml-4 animateIcon",
                      staticStyle: {
                        "background-color": "#FFFFFF",
                        width: "50px",
                        height: "50px",
                        "border-radius": "8px"
                      },
                      attrs: { "x-small": "", elevation: "0" },
                      on: {
                        click: function($event) {
                          return _vm.reloadPage()
                        }
                      }
                    },
                    [
                      _c("img", {
                        staticStyle: {
                          "max-width": "25px",
                          "max-height": "25px"
                        },
                        attrs: { src: _vm.aktualisierenIcon }
                      })
                    ]
                  ),
                  !_vm.textSelected
                    ? _c(
                        "v-btn",
                        {
                          ref: "topMenuBtn",
                          staticClass: "btmNavBtn pa-0 ml-4",
                          style: _vm.topMenu
                            ? "background-color: #6995CD !important;"
                            : "",
                          attrs: { elevation: "0", small: "" },
                          on: { click: _vm.handleTopMenuClick }
                        },
                        [
                          _c("img", {
                            staticStyle: {
                              "max-width": "25px",
                              "max-height": "25px"
                            },
                            style: _vm.topMenu
                              ? "filter: brightness(1000%);"
                              : "filter: brightness(0) saturate(100%) invert(56%) sepia(46%) saturate(448%) hue-rotate(174deg) brightness(93%) contrast(86%);",
                            attrs: { src: _vm.menuIcon }
                          })
                        ]
                      )
                    : _c(
                        "v-btn",
                        {
                          staticClass: "btmNavBtn pa-0 ml-4",
                          staticStyle: {
                            "background-color": "#6995CD !important"
                          },
                          attrs: { elevation: "0", small: "" },
                          on: {
                            click: function($event) {
                              if ($event.target !== $event.currentTarget) {
                                return null
                              }
                              return _vm.clickTTS($event)
                            }
                          }
                        },
                        [
                          _c("TTSSmall", {
                            ref: "tts",
                            attrs: { "icon-color": "white" }
                          })
                        ],
                        1
                      ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "closable",
                          rawName: "v-closable",
                          value: {
                            exclude: ["topMenuBtn"],
                            handler: "onCloseTop"
                          },
                          expression:
                            "{exclude: ['topMenuBtn'], handler: 'onCloseTop'}"
                        }
                      ],
                      staticClass: "d-flex flex-column pa-2 topMenu",
                      class: _vm.topMenu ? "" : "hideMe",
                      staticStyle: { "z-index": "2" },
                      style: _vm.topLangMenu
                        ? "box-shadow: -3px 2px 4px silver;"
                        : "border-bottom-left-radius: 12px"
                    },
                    [
                      _c(
                        "div",
                        {
                          directives: [{ name: "ripple", rawName: "v-ripple" }],
                          staticClass: "d-flex mb-2 pa-2 btmNavMenuBtn pointer",
                          on: {
                            click: function($event) {
                              _vm.topLangMenu = !_vm.topLangMenu
                            }
                          }
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: _vm.topLangMenu
                                ? _vm.abbrechenIcon
                                : _vm.currentTTSLangImg,
                              height: "25"
                            }
                          }),
                          _c("p", { staticClass: "ml-2 ma-0" }, [
                            _vm._v("Sprache")
                          ])
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "pa-2 pr-0 topLangMenu",
                          class: _vm.topLangMenu ? "" : "hideMe"
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex" },
                            _vm._l(_vm.btmNavLangItems.slice(0, 3), function(
                              lang
                            ) {
                              return _c(
                                "div",
                                {
                                  key: lang.countryCode,
                                  staticClass:
                                    "btmNavLangBtn d-flex mb-2 mr-2 pa-2",
                                  class:
                                    _vm.currentTTSLangImg === lang.icon
                                      ? "justify-center align-center langBtnBg border"
                                      : lang.icon !== ""
                                      ? "langBtnBg pointer"
                                      : "",
                                  on: {
                                    click: function($event) {
                                      return _vm.changeLangNav(
                                        lang.countryCode,
                                        lang.icon
                                      )
                                    }
                                  }
                                },
                                [
                                  lang.icon !== ""
                                    ? _c("img", {
                                        attrs: {
                                          src: lang.icon,
                                          height: "25",
                                          width: "25"
                                        }
                                      })
                                    : _c("div", {
                                        staticClass: "btmNavLangNoIcon"
                                      })
                                ]
                              )
                            }),
                            0
                          ),
                          _c(
                            "div",
                            { staticClass: "d-flex" },
                            _vm._l(_vm.btmNavLangItems.slice(3, 6), function(
                              lang
                            ) {
                              return _c(
                                "div",
                                {
                                  key: lang.countryCode,
                                  staticClass:
                                    "btmNavLangBtn d-flex mb-2 mr-2 pa-2",
                                  class:
                                    _vm.currentTTSLangImg === lang.icon
                                      ? "justify-center align-center langBtnBg border"
                                      : lang.icon !== ""
                                      ? "langBtnBg pointer"
                                      : "",
                                  on: {
                                    click: function($event) {
                                      return _vm.changeLangNav(
                                        lang.countryCode,
                                        lang.icon
                                      )
                                    }
                                  }
                                },
                                [
                                  lang.icon !== ""
                                    ? _c("img", {
                                        attrs: {
                                          src: lang.icon,
                                          height: "25",
                                          width: "25"
                                        }
                                      })
                                    : _c("div", {
                                        staticClass: "btmNavLangNoIcon"
                                      })
                                ]
                              )
                            }),
                            0
                          ),
                          _c(
                            "div",
                            { staticClass: "d-flex" },
                            _vm._l(_vm.btmNavLangItems.slice(6, 9), function(
                              lang
                            ) {
                              return _c(
                                "div",
                                {
                                  key: lang.countryCode,
                                  staticClass: "btmNavLangBtn d-flex mr-2 pa-2",
                                  class:
                                    _vm.currentTTSLangImg === lang.icon
                                      ? "justify-center align-center langBtnBg border"
                                      : lang.icon !== ""
                                      ? "langBtnBg pointer"
                                      : "",
                                  on: {
                                    click: function($event) {
                                      return _vm.changeLangNav(
                                        lang.countryCode,
                                        lang.icon
                                      )
                                    }
                                  }
                                },
                                [
                                  lang.icon !== ""
                                    ? _c("img", {
                                        attrs: {
                                          src: lang.icon,
                                          height: "25",
                                          width: "25"
                                        }
                                      })
                                    : _c("div", {
                                        staticClass: "btmNavLangNoIcon"
                                      })
                                ]
                              )
                            }),
                            0
                          )
                        ]
                      ),
                      _c(
                        "div",
                        {
                          directives: [{ name: "ripple", rawName: "v-ripple" }],
                          staticClass: "d-flex mb-2 pa-2 btmNavMenuBtn pointer",
                          on: {
                            click: function($event) {
                              _vm.resetTutorial()
                              _vm.closeTopMenus
                            }
                          }
                        },
                        [
                          _c("img", {
                            attrs: { src: _vm.fragezeichenIcon, height: "25" }
                          }),
                          _c("p", { staticClass: "ml-2 ma-0" }, [
                            _vm._v("Tutorial")
                          ])
                        ]
                      ),
                      _c(
                        "div",
                        {
                          directives: [{ name: "ripple", rawName: "v-ripple" }],
                          staticClass: "d-flex pa-2 btmNavMenuBtn pointer",
                          on: {
                            click: function($event) {
                              _vm.clickLogoutUser()
                              _vm.closeTopMenus
                            }
                          }
                        },
                        [
                          _c("img", {
                            attrs: { src: _vm.logoutIcon, height: "25" }
                          }),
                          _c("p", { staticClass: "ml-2 ma-0" }, [
                            _vm._v("Abmelden")
                          ])
                        ]
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm.windowWidth > 900
            ? _c(
                "div",
                { staticClass: "big" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "arrowBtn",
                      staticStyle: {
                        "margin-left": "5px",
                        "margin-top": "20%"
                      },
                      attrs: { fab: "", small: "", color: "#eaeaea" },
                      on: {
                        click: function($event) {
                          _vm.page = Math.abs((_vm.page - 1) % 2)
                        }
                      }
                    },
                    [
                      _c("img", {
                        staticStyle: {
                          position: "relative",
                          margin: "auto",
                          display: "block",
                          width: "20px"
                        },
                        attrs: { src: _vm.left }
                      })
                    ]
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _vm.page === 0
                            ? _c("PlanWidget", {
                                staticStyle: { height: "100vh" },
                                on: { "start-bbb-tut": _vm.startBBBTut }
                              })
                            : _vm._e(),
                          _vm.page === 1
                            ? _c("FileWidget", {
                                staticStyle: { height: "100vh" }
                              })
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        [
                          _vm.page === 0
                            ? _c("BrettWidget", {
                                staticStyle: { height: "100vh" }
                              })
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        [_vm.page === 0 ? _c("chat-widget") : _vm._e()],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "arrowBtn",
                      staticStyle: {
                        "margin-right": "5px",
                        "margin-top": "20%"
                      },
                      attrs: { fab: "", small: "", color: "#eaeaea" },
                      on: {
                        click: function($event) {
                          _vm.page = Math.abs((_vm.page + 1) % 2)
                        }
                      }
                    },
                    [
                      _c("img", {
                        staticStyle: {
                          position: "relative",
                          margin: "auto",
                          display: "block",
                          width: "20px"
                        },
                        attrs: { src: _vm.right }
                      })
                    ]
                  )
                ],
                1
              )
            : _c(
                "VueSlickCarousel",
                {
                  ref: "carousel",
                  staticClass: "small topPadding",
                  attrs: {
                    arrows: false,
                    initialSlide: 0,
                    infinite: false,
                    dots: false
                  },
                  on: {
                    afterChange: function($event) {
                      _vm.slideChangeAllowed = true
                      _vm.setCurrSlide(
                        _vm.$refs.carousel.$refs.innerSlider.currentSlide
                      )
                    }
                  }
                },
                [
                  _c("PlanWidget", {
                    staticStyle: { height: "100vh" },
                    attrs: { small: true },
                    on: { "start-bbb-tut": _vm.startBBBTut }
                  }),
                  _c("BrettWidget", { staticStyle: { height: "100vh" } }),
                  _c("chat-widget"),
                  _c("FileWidget", { staticStyle: { height: "100vh" } })
                ],
                1
              )
        ],
        1
      )
    : _c("Tutorial", {
        attrs: {
          babyView: _vm.babyViewTut,
          "bbb-tut": _vm.bbbTut,
          checkTutorialState: _vm.checkTutorialState
        },
        on: { "bbb-tut-done": _vm.openBBB }
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }