import { render, staticRenderFns } from "./BrettWidget.vue?vue&type=template&id=5f55a779&scoped=true&"
import script from "./BrettWidget.vue?vue&type=script&lang=js&"
export * from "./BrettWidget.vue?vue&type=script&lang=js&"
import style0 from "./BrettWidget.vue?vue&type=style&index=0&id=5f55a779&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f55a779",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBadge,VBtn,VCard,VCardText,VCardTitle,VCol,VRow})


/* hot reload */
if (module.hot) {
  var api = require("/home/peternauroth/homeschooling/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5f55a779')) {
      api.createRecord('5f55a779', component.options)
    } else {
      api.reload('5f55a779', component.options)
    }
    module.hot.accept("./BrettWidget.vue?vue&type=template&id=5f55a779&scoped=true&", function () {
      api.rerender('5f55a779', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/BrettWidget.vue"
export default component.exports