var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "group-chat-header pa-4" }, [
        _c("span", { staticClass: "group-chat-header-text" }, [
          _vm.addNew
            ? _c(
                "div",
                { staticStyle: { display: "flex", width: "100%" } },
                [
                  _c("img", {
                    staticClass: "iconToWhite",
                    staticStyle: {
                      height: "20px",
                      "margin-left": "0",
                      "margin-top": "2px"
                    },
                    attrs: { src: _vm.suchIcon }
                  }),
                  _c("v-autocomplete", {
                    staticClass: "pl-2 pt-0 mt-0",
                    staticStyle: { "z-index": "10" },
                    attrs: {
                      label: "Nach Kontakt suchen",
                      "single-line": "",
                      dark: "",
                      "hide-details": "",
                      "item-text": function(el) {
                        return el.name + " " + el.lastName
                      },
                      "item-value": "account",
                      "item-color": "gruen",
                      height: "24",
                      items: _vm.matrixUsers,
                      filter: _vm.customFilter,
                      "no-data-text": "Keine passender Kontakt"
                    },
                    model: {
                      value: _vm.contact,
                      callback: function($$v) {
                        _vm.contact = $$v
                      },
                      expression: "contact"
                    }
                  }),
                  _c("img", {
                    staticClass: "iconToWhite pl-2",
                    staticStyle: { height: "20px", "margin-top": "2px" },
                    attrs: { src: _vm.abbrechenIcon },
                    on: {
                      click: function($event) {
                        ;(_vm.addNew = false) && (_vm.contact = "")
                      }
                    }
                  }),
                  _c("img", {
                    staticClass: "iconToWhite pl-2",
                    staticStyle: { height: "20px", "margin-top": "2px" },
                    attrs: { src: _vm.erledigtIcon },
                    on: {
                      click: function($event) {
                        return _vm.startPrivateRoom()
                      }
                    }
                  })
                ],
                1
              )
            : _c("div", { staticStyle: { width: "100%", display: "flex" } }, [
                _vm._v(" Gruppen "),
                _c("img", {
                  staticClass: "iconToWhite",
                  staticStyle: {
                    display: "block",
                    height: "20px",
                    "margin-left": "auto",
                    "margin-right": "0",
                    cursor: "pointer"
                  },
                  attrs: { src: _vm.hinzufuegenIcon },
                  on: {
                    click: function($event) {
                      ;(_vm.addNew = true) && (_vm.contact = "")
                    }
                  }
                })
              ])
        ])
      ]),
      _vm.rooms.length > 0
        ? _c(
            "vue-custom-scrollbar",
            { staticClass: "scroll-area", attrs: { settings: _vm.settings } },
            [
              _c(
                "v-list",
                { staticClass: "chat-body pa-0" },
                _vm._l(_vm.rooms, function(room, index) {
                  return _c(
                    "v-list-item-group",
                    { key: index },
                    [
                      [
                        _c(
                          "v-list-item",
                          {
                            staticClass: "group-item",
                            on: {
                              click: function() {
                                return _vm.clickSelectRoom(room)
                              }
                            }
                          },
                          [
                            _c(
                              "v-list-item-icon",
                              { staticClass: "my-3" },
                              [
                                _c(
                                  "v-badge",
                                  {
                                    staticStyle: { "z-index": "9" },
                                    attrs: {
                                      color: "red",
                                      content:
                                        _vm.notificationArray[index]
                                          .newMessagesCount,
                                      value:
                                        _vm.notificationArray[index]
                                          .newMessagesAvailable > 10
                                          ? "10+"
                                          : _vm.notificationArray[index]
                                              .newMessagesAvailable,
                                      overlap: "",
                                      "offset-x": "15"
                                    }
                                  },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          medium: "",
                                          fab: "",
                                          color: "#6994cc"
                                        }
                                      },
                                      [
                                        _vm.isTeacher(room)
                                          ? _c("img", {
                                              class: _vm.hasProfilePic(room)
                                                ? "roundImage"
                                                : "iconToWhite",
                                              attrs: {
                                                src: _vm.hasProfilePic(room)
                                                  ? _vm.getTeachPic()
                                                  : _vm.lehrerIcon,
                                                height: _vm.hasProfilePic(room)
                                                  ? 56
                                                  : 25
                                              }
                                            })
                                          : _vm.isPupil(room)
                                          ? _c("img", {
                                              staticClass: "iconToWhite",
                                              attrs: {
                                                src: _vm.schuelerIcon,
                                                height: "25"
                                              }
                                            })
                                          : _c("img", {
                                              staticClass: "iconToWhite",
                                              attrs: {
                                                src: _vm.chatIcon,
                                                height: "25"
                                              }
                                            })
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-list-item-content",
                              [
                                _c("v-list-item-title", {
                                  staticClass: "greyText",
                                  domProps: {
                                    textContent: _vm._s(
                                      room.name.includes("private Room")
                                        ? _vm.checkRoomName(room.name)
                                        : room.name
                                    )
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c("v-divider")
                      ]
                    ],
                    2
                  )
                }),
                1
              )
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "pa-4", staticStyle: { color: "#707070" } },
            [_vm._v("Keine Gruppen")]
          ),
      _c(
        "v-snackbar",
        {
          attrs: { bottom: "", color: "error", timeout: "3000" },
          model: {
            value: _vm.snackbar,
            callback: function($$v) {
              _vm.snackbar = $$v
            },
            expression: "snackbar"
          }
        },
        [_vm._v(" " + _vm._s(_vm.snackbarText) + " ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }