var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "td",
        { staticStyle: { width: "100%", display: "block" } },
        [
          !_vm.appointment
            ? _c(
                "v-menu",
                {
                  attrs: {
                    "close-on-content-click": false,
                    "nudge-width": 70,
                    width: 400,
                    "max-width": 400,
                    "offset-x": "",
                    tile: false
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function(ref) {
                          var attrs = ref.attrs
                          return [
                            _c(
                              "div",
                              _vm._b(
                                {
                                  staticClass: "fach pa-2 white--text",
                                  staticStyle: {
                                    width: "100%",
                                    display: "block"
                                  },
                                  on: { click: _vm.checkGroup }
                                },
                                "div",
                                attrs,
                                false
                              ),
                              [
                                _c(
                                  "v-row",
                                  {
                                    staticClass: "ml-2 mr-1 font-weight-medium"
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "pa-3 mr-2",
                                        style:
                                          "position: relative;border-radius: 10px; color: #BDBDBD; box-shadow: 1px 2px 3px silver; width: 100%; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; background-color: #FFF"
                                      },
                                      [
                                        _vm.getSrc()
                                          ? _c("img", {
                                              staticClass: "iconSelect",
                                              attrs: {
                                                src: _vm.getSrc(),
                                                alt: "Fach-Icon"
                                              }
                                            })
                                          : _c("div", {
                                              staticClass: "iconSelect",
                                              staticStyle: {
                                                "border-radius": "5px"
                                              }
                                            }),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.thisSubject
                                                ? _vm.thisSubject.name
                                                : "Hinzufügen"
                                            ) +
                                            " "
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    389381775
                  ),
                  model: {
                    value: _vm.menu,
                    callback: function($$v) {
                      _vm.menu = $$v
                    },
                    expression: "menu"
                  }
                },
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "div",
                        {
                          staticClass: "fach; white--text pb-3 pt-5",
                          style:
                            "background-color: " +
                            (_vm.thisSubject
                              ? _vm.thisSubject.color
                              : "#CACACA")
                        },
                        [
                          _c(
                            "v-row",
                            {
                              staticClass: "ml-5 font-weight-medium",
                              staticStyle: { "font-size": "large" }
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticStyle: {
                                    "margin-right": "5px",
                                    "margin-bottom": "2px",
                                    "background-color": "#ffffff55",
                                    padding: "5px"
                                  },
                                  attrs: { "x-small": "" }
                                },
                                [_vm._v("fas fa-school")]
                              ),
                              _c(
                                "v-icon",
                                {
                                  staticStyle: {
                                    "margin-left": "auto",
                                    "margin-right": "40px"
                                  },
                                  attrs: { dark: "" },
                                  on: { click: _vm.cancel }
                                },
                                [_vm._v("fas fa-times")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { staticClass: "mx-1" },
                        [
                          _c(
                            "v-col",
                            { staticClass: "textGrau", attrs: { cols: "5" } },
                            [_vm._v(" Fach: ")]
                          ),
                          _c("v-col", { attrs: { cols: "7" } }, [
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.thisSubject,
                                    expression: "thisSubject"
                                  }
                                ],
                                staticClass: "input",
                                on: {
                                  change: function($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call($event.target.options, function(o) {
                                        return o.selected
                                      })
                                      .map(function(o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.thisSubject = $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  }
                                }
                              },
                              _vm._l(_vm.subjects, function(item, index) {
                                return _c(
                                  "option",
                                  {
                                    key: index,
                                    staticClass: "textGrau",
                                    attrs: { selected: "" },
                                    domProps: { value: item }
                                  },
                                  [_vm._v(" " + _vm._s(item.name) + " ")]
                                )
                              }),
                              0
                            )
                          ]),
                          _c(
                            "v-col",
                            { staticClass: "textGrau", attrs: { cols: "5" } },
                            [_vm._v(" Lehrer: ")]
                          ),
                          _c("v-col", { attrs: { cols: "7" } }, [
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.thisTeacher,
                                    expression: "thisTeacher"
                                  }
                                ],
                                staticClass: "input",
                                attrs: { disabled: !_vm.thisSubject },
                                on: {
                                  change: function($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call($event.target.options, function(o) {
                                        return o.selected
                                      })
                                      .map(function(o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.thisTeacher = $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  }
                                }
                              },
                              _vm._l(
                                _vm.thisSubject ? _vm.thisSubject.teachers : [],
                                function(item, index) {
                                  return _c(
                                    "option",
                                    {
                                      key: index,
                                      attrs: { selected: "" },
                                      domProps: { value: item }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            item.lastName + " " + item.name
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                }
                              ),
                              0
                            )
                          ])
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { staticClass: "mx-1 pb-0" },
                        [
                          _c("v-col", { staticClass: "textGrau pb-0" }, [
                            _vm._v(" Wiederholung: ")
                          ])
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { staticClass: "mx-1 py-0" },
                        [
                          _c(
                            "v-col",
                            { staticClass: "py-0", attrs: { cols: "6" } },
                            [
                              _c("v-checkbox", {
                                staticStyle: { "margin-top": "0 !important" },
                                attrs: { label: "Wöchentlich bis" },
                                model: {
                                  value: _vm.weekly,
                                  callback: function($$v) {
                                    _vm.weekly = $$v
                                  },
                                  expression: "weekly"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { staticClass: "py-0", attrs: { cols: "6" } },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.weeklyTill,
                                    expression: "weeklyTill"
                                  }
                                ],
                                staticClass: "input",
                                class: { errorInput: _vm.fields.weeklyField },
                                attrs: { type: "date", disabled: !_vm.weekly },
                                domProps: { value: _vm.weeklyTill },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.weeklyTill = $event.target.value
                                  }
                                }
                              })
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "mb-3 mt-4",
                          staticStyle: {
                            "background-color": "#6995cd",
                            "border-radius": "8px",
                            width: "50%",
                            "margin-left": "auto !important",
                            "margin-right": "auto !important",
                            display: "block"
                          },
                          attrs: { dark: "", tile: "" },
                          on: { click: _vm.saveAppointment }
                        },
                        [_vm._v("Speichern")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "my-0 textGrau",
                          staticStyle: {
                            "background-color": "#eaeaea",
                            "border-radius": "8px",
                            width: "50%",
                            "margin-left": "auto !important",
                            "margin-right": "auto !important",
                            display: "block"
                          },
                          attrs: { tile: "", dark: "" },
                          on: { click: _vm.cancel }
                        },
                        [_vm._v("Abbrechen")]
                      ),
                      _c("br")
                    ],
                    1
                  )
                ],
                1
              )
            : _c(
                "v-menu",
                {
                  attrs: {
                    "close-on-content-click": false,
                    "nudge-width": 70,
                    width: 400,
                    "max-width": 400,
                    "offset-x": "",
                    tile: false
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var attrs = ref.attrs
                        return [
                          _c(
                            "div",
                            _vm._b(
                              {
                                staticClass: "fach; pa-2 white--text",
                                staticStyle: {
                                  width: "100%",
                                  display: "block"
                                },
                                on: { click: _vm.checkGroup }
                              },
                              "div",
                              attrs,
                              false
                            ),
                            [
                              _c(
                                "v-row",
                                { staticClass: "ml-2 mr-1 font-weight-medium" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "pa-3 mr-2",
                                      style:
                                        "position: relative; border-radius: 10px; color: #707070; box-shadow: 1px 2px 3px silver; width: 100%; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; background-color: #FFF"
                                    },
                                    [
                                      _c("img", {
                                        staticStyle: {
                                          position: "absolute",
                                          height: "25px",
                                          top: "0",
                                          right: "10px"
                                        },
                                        attrs: {
                                          src: _vm.getColorIcon(),
                                          alt: "Lesezeichen-Icon"
                                        }
                                      }),
                                      _vm.getSrc()
                                        ? _c("img", {
                                            staticClass: "iconSelect",
                                            attrs: {
                                              src: _vm.getSrc(),
                                              alt: "Fach-Icon"
                                            }
                                          })
                                        : _c("div", {
                                            staticClass: "iconSelect"
                                          }),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.appointment.schoolSubject.name
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ]),
                  model: {
                    value: _vm.menu,
                    callback: function($$v) {
                      _vm.menu = $$v
                    },
                    expression: "menu"
                  }
                },
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "div",
                        {
                          staticClass: "fach; white--text pb-3 pt-5",
                          style:
                            "background-color: " +
                            (_vm.thisSubject
                              ? _vm.thisSubject.color
                              : "#CACACA")
                        },
                        [
                          _c(
                            "v-row",
                            {
                              staticClass: "ml-5 font-weight-medium",
                              staticStyle: { "font-size": "large" }
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticStyle: {
                                    "margin-right": "5px",
                                    "margin-bottom": "2px",
                                    "background-color": "#ffffff55",
                                    padding: "5px"
                                  },
                                  attrs: { "x-small": "" }
                                },
                                [_vm._v("fas fa-school")]
                              ),
                              _c(
                                "v-icon",
                                {
                                  staticStyle: {
                                    "margin-left": "auto",
                                    "margin-right": "40px"
                                  },
                                  attrs: { dark: "" },
                                  on: { click: _vm.cancel }
                                },
                                [_vm._v("fas fa-times")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { staticClass: "mx-1" },
                        [
                          _c(
                            "v-col",
                            { staticClass: "textGrau", attrs: { cols: "5" } },
                            [_vm._v(" Fach: ")]
                          ),
                          _c("v-col", { attrs: { cols: "7" } }, [
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.thisSubject,
                                    expression: "thisSubject"
                                  }
                                ],
                                staticClass: "input textGrau",
                                on: {
                                  change: function($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call($event.target.options, function(o) {
                                        return o.selected
                                      })
                                      .map(function(o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.thisSubject = $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  }
                                }
                              },
                              _vm._l(_vm.subjects, function(item, index) {
                                return _c(
                                  "option",
                                  {
                                    key: index,
                                    staticClass: "textGrau",
                                    attrs: { selected: "" },
                                    domProps: { value: item }
                                  },
                                  [_vm._v(" " + _vm._s(item.name) + " ")]
                                )
                              }),
                              0
                            )
                          ]),
                          _c(
                            "v-col",
                            { staticClass: "textGrau", attrs: { cols: "5" } },
                            [_vm._v(" Lehrer: ")]
                          ),
                          _c("v-col", { attrs: { cols: "7" } }, [
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.thisTeacher,
                                    expression: "thisTeacher"
                                  }
                                ],
                                staticClass: "input",
                                attrs: { disabled: !_vm.thisSubject },
                                on: {
                                  change: function($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call($event.target.options, function(o) {
                                        return o.selected
                                      })
                                      .map(function(o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.thisTeacher = $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  }
                                }
                              },
                              _vm._l(
                                _vm.thisSubject ? _vm.thisSubject.teachers : [],
                                function(item, index) {
                                  return _c(
                                    "option",
                                    {
                                      key: index,
                                      staticClass: "textGrau",
                                      attrs: { selected: "" },
                                      domProps: { value: item }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            item.lastName + " " + item.name
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                }
                              ),
                              0
                            )
                          ])
                        ],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "success mb-3 mt-4",
                          staticStyle: {
                            width: "90%",
                            "margin-left": "auto !important",
                            "margin-right": "auto !important",
                            display: "block"
                          },
                          attrs: { tile: "" },
                          on: { click: _vm.updateAppointment }
                        },
                        [_vm._v("Speichern")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "error mb-3 mt-4",
                          staticStyle: {
                            width: "90%",
                            "margin-left": "auto !important",
                            "margin-right": "auto !important",
                            display: "block"
                          },
                          attrs: { tile: "" },
                          on: {
                            click: function($event) {
                              _vm.deleteDialog = true
                            }
                          }
                        },
                        [_vm._v("Löschen")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "grey my-0",
                          staticStyle: {
                            width: "90%",
                            "margin-left": "auto !important",
                            "margin-right": "auto !important",
                            display: "block"
                          },
                          attrs: { tile: "", dark: "" },
                          on: { click: _vm.cancel }
                        },
                        [_vm._v("Schließen")]
                      ),
                      _c("br")
                    ],
                    1
                  )
                ],
                1
              )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "400" },
          model: {
            value: _vm.deleteDialog,
            callback: function($$v) {
              _vm.deleteDialog = $$v
            },
            expression: "deleteDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "headline" }, [
                _vm._v("Termin löschen?")
              ]),
              _c(
                "v-card-text",
                [
                  _vm._v(
                    " Sind sie sicher, dass sie diesen Termin löschen möchten? "
                  ),
                  _c("br"),
                  _vm.appointment && _vm.appointment.rule
                    ? _c(
                        "v-radio-group",
                        {
                          model: {
                            value: _vm.deleteRecurringAppointment,
                            callback: function($$v) {
                              _vm.deleteRecurringAppointment = $$v
                            },
                            expression: "deleteRecurringAppointment"
                          }
                        },
                        [
                          _c("v-radio", {
                            attrs: {
                              value: "onlyThis",
                              label: "Nur diesen Termin"
                            }
                          }),
                          _c("v-radio", {
                            attrs: {
                              value: "thisAndFollowing",
                              label: "Diesen Termin und alle folgenden"
                            }
                          }),
                          _c("v-radio", {
                            attrs: { value: "allOfThem", label: "Alle Termine" }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "", text: "" },
                      on: {
                        click: function($event) {
                          _vm.deleteDialog = false
                        }
                      }
                    },
                    [_vm._v("Abbrechen")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "red red-1", text: "" },
                      on: { click: _vm.deleteAction }
                    },
                    [_vm._v("Löschen")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-snackbar",
        {
          attrs: { bottom: "", color: "error", timeout: "3000" },
          model: {
            value: _vm.snackbar,
            callback: function($$v) {
              _vm.snackbar = $$v
            },
            expression: "snackbar"
          }
        },
        [_vm._v(" " + _vm._s(_vm.snackbarText) + " ")]
      ),
      _c(
        "v-snackbar",
        {
          attrs: { bottom: "", color: "success", timeout: "3000" },
          model: {
            value: _vm.snackbarSave,
            callback: function($$v) {
              _vm.snackbarSave = $$v
            },
            expression: "snackbarSave"
          }
        },
        [_vm._v(" Gespeichert! ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }