var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "overlay-opacity": "0.7", persistent: true, width: "500" },
      model: {
        value: _vm.showProgress,
        callback: function($$v) {
          _vm.showProgress = $$v
        },
        expression: "showProgress"
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "pa-3", attrs: { id: "card" } },
        [
          _c("v-card-title", [_c("h1", [_vm._v(_vm._s(_vm.title))])]),
          _c(
            "v-card-text",
            [
              _c(
                "v-container",
                { staticClass: "pa-0" },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-progress-linear",
                            {
                              attrs: {
                                id: "progressbar",
                                height: "100%",
                                color: "primary"
                              },
                              model: {
                                value: _vm.progress,
                                callback: function($$v) {
                                  _vm.progress = $$v
                                },
                                expression: "progress"
                              }
                            },
                            [
                              _c("strong", [
                                _vm._v(_vm._s(Math.ceil(_vm.progress)) + "%")
                              ])
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "ml-2 pa-0",
                              staticStyle: { width: "30px", height: "30px" },
                              attrs: { elevation: "2", "x-small": "" },
                              on: { click: _vm.abortProgress }
                            },
                            [
                              _c("img", {
                                staticStyle: { height: "20px" },
                                attrs: { src: _vm.schliesenIcon }
                              })
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }