<template>
        <div id="canvasContainer">
            <canvas ref="editcanvas" id="editcanvas" ></canvas>
        </div>
</template>

<script>
import { fabric } from "fabric";
import range from 'lodash/range'
import {mapActions, mapGetters} from "vuex";
// import vuescroll from 'vuescroll'

    export default {
        name: "EditingCanvas",
        components: {
            // vuescroll,
        },
        data() {
            return {
                localCanvas: null,
                currentActiveTool: 'pen',
                currentColor: '#706f6f',
                currentText: null,
                isObjectSelected: false,
                history: [],
                objects: [],
                textObjects: [],
                isMouseDown: false,
                currentBackgroundImg: null,
                currentStempel: null,
                closeIconList: [
                    { color: '#fac837', icon: 'data:image/svg+xml,%3C%3Fxml version=\'1.0\' encoding=\'utf-8\'%3F%3E%3C!DOCTYPE svg PUBLIC \'-//W3C//DTD SVG 1.1//EN\' \'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd\'%3E%3Csvg version=\'1.1\' id=\'Ebene_1\' xmlns=\'http://www.w3.org/2000/svg\' xmlns:xlink=\'http://www.w3.org/1999/xlink\' x=\'0px\' y=\'0px\' width=\'595.275px\' height=\'595.275px\' viewBox=\'200 215 230 470\' xml:space=\'preserve\'%3E%3Ccircle style=\'fill:%23fac837;\' cx=\'299.76\' cy=\'439.067\' r=\'218.516\'/%3E%3Cg%3E%3Crect x=\'267.162\' y=\'307.978\' transform=\'matrix(0.7071 -0.7071 0.7071 0.7071 -222.6202 340.6915)\' style=\'fill:white;\' width=\'65.545\' height=\'262.18\'/%3E%3Crect x=\'266.988\' y=\'308.153\' transform=\'matrix(0.7071 0.7071 -0.7071 0.7071 398.3889 -83.3116)\' style=\'fill:white;\' width=\'65.544\' height=\'262.179\'/%3E%3C/g%3E%3C/svg%3E'},
                    { color: '#f07d32', icon: 'data:image/svg+xml,%3C%3Fxml version=\'1.0\' encoding=\'utf-8\'%3F%3E%3C!DOCTYPE svg PUBLIC \'-//W3C//DTD SVG 1.1//EN\' \'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd\'%3E%3Csvg version=\'1.1\' id=\'Ebene_1\' xmlns=\'http://www.w3.org/2000/svg\' xmlns:xlink=\'http://www.w3.org/1999/xlink\' x=\'0px\' y=\'0px\' width=\'595.275px\' height=\'595.275px\' viewBox=\'200 215 230 470\' xml:space=\'preserve\'%3E%3Ccircle style=\'fill:%23f07d32;\' cx=\'299.76\' cy=\'439.067\' r=\'218.516\'/%3E%3Cg%3E%3Crect x=\'267.162\' y=\'307.978\' transform=\'matrix(0.7071 -0.7071 0.7071 0.7071 -222.6202 340.6915)\' style=\'fill:white;\' width=\'65.545\' height=\'262.18\'/%3E%3Crect x=\'266.988\' y=\'308.153\' transform=\'matrix(0.7071 0.7071 -0.7071 0.7071 398.3889 -83.3116)\' style=\'fill:white;\' width=\'65.544\' height=\'262.179\'/%3E%3C/g%3E%3C/svg%3E'},
                    { color: '#e6231e', icon: 'data:image/svg+xml,%3C%3Fxml version=\'1.0\' encoding=\'utf-8\'%3F%3E%3C!DOCTYPE svg PUBLIC \'-//W3C//DTD SVG 1.1//EN\' \'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd\'%3E%3Csvg version=\'1.1\' id=\'Ebene_1\' xmlns=\'http://www.w3.org/2000/svg\' xmlns:xlink=\'http://www.w3.org/1999/xlink\' x=\'0px\' y=\'0px\' width=\'595.275px\' height=\'595.275px\' viewBox=\'200 215 230 470\' xml:space=\'preserve\'%3E%3Ccircle style=\'fill:%23e6231e;\' cx=\'299.76\' cy=\'439.067\' r=\'218.516\'/%3E%3Cg%3E%3Crect x=\'267.162\' y=\'307.978\' transform=\'matrix(0.7071 -0.7071 0.7071 0.7071 -222.6202 340.6915)\' style=\'fill:white;\' width=\'65.545\' height=\'262.18\'/%3E%3Crect x=\'266.988\' y=\'308.153\' transform=\'matrix(0.7071 0.7071 -0.7071 0.7071 398.3889 -83.3116)\' style=\'fill:white;\' width=\'65.544\' height=\'262.179\'/%3E%3C/g%3E%3C/svg%3E'},
                    { color: '#6996cd', icon: 'data:image/svg+xml,%3C%3Fxml version=\'1.0\' encoding=\'utf-8\'%3F%3E%3C!DOCTYPE svg PUBLIC \'-//W3C//DTD SVG 1.1//EN\' \'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd\'%3E%3Csvg version=\'1.1\' id=\'Ebene_1\' xmlns=\'http://www.w3.org/2000/svg\' xmlns:xlink=\'http://www.w3.org/1999/xlink\' x=\'0px\' y=\'0px\' width=\'595.275px\' height=\'595.275px\' viewBox=\'200 215 230 470\' xml:space=\'preserve\'%3E%3Ccircle style=\'fill:%236996cd;\' cx=\'299.76\' cy=\'439.067\' r=\'218.516\'/%3E%3Cg%3E%3Crect x=\'267.162\' y=\'307.978\' transform=\'matrix(0.7071 -0.7071 0.7071 0.7071 -222.6202 340.6915)\' style=\'fill:white;\' width=\'65.545\' height=\'262.18\'/%3E%3Crect x=\'266.988\' y=\'308.153\' transform=\'matrix(0.7071 0.7071 -0.7071 0.7071 398.3889 -83.3116)\' style=\'fill:white;\' width=\'65.544\' height=\'262.179\'/%3E%3C/g%3E%3C/svg%3E'},
                    { color: '#505faa', icon: 'data:image/svg+xml,%3C%3Fxml version=\'1.0\' encoding=\'utf-8\'%3F%3E%3C!DOCTYPE svg PUBLIC \'-//W3C//DTD SVG 1.1//EN\' \'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd\'%3E%3Csvg version=\'1.1\' id=\'Ebene_1\' xmlns=\'http://www.w3.org/2000/svg\' xmlns:xlink=\'http://www.w3.org/1999/xlink\' x=\'0px\' y=\'0px\' width=\'595.275px\' height=\'595.275px\' viewBox=\'200 215 230 470\' xml:space=\'preserve\'%3E%3Ccircle style=\'fill:%23505faa;\' cx=\'299.76\' cy=\'439.067\' r=\'218.516\'/%3E%3Cg%3E%3Crect x=\'267.162\' y=\'307.978\' transform=\'matrix(0.7071 -0.7071 0.7071 0.7071 -222.6202 340.6915)\' style=\'fill:white;\' width=\'65.545\' height=\'262.18\'/%3E%3Crect x=\'266.988\' y=\'308.153\' transform=\'matrix(0.7071 0.7071 -0.7071 0.7071 398.3889 -83.3116)\' style=\'fill:white;\' width=\'65.544\' height=\'262.179\'/%3E%3C/g%3E%3C/svg%3E'},
                    { color: '#8cbe46', icon: 'data:image/svg+xml,%3C%3Fxml version=\'1.0\' encoding=\'utf-8\'%3F%3E%3C!DOCTYPE svg PUBLIC \'-//W3C//DTD SVG 1.1//EN\' \'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd\'%3E%3Csvg version=\'1.1\' id=\'Ebene_1\' xmlns=\'http://www.w3.org/2000/svg\' xmlns:xlink=\'http://www.w3.org/1999/xlink\' x=\'0px\' y=\'0px\' width=\'595.275px\' height=\'595.275px\' viewBox=\'200 215 230 470\' xml:space=\'preserve\'%3E%3Ccircle style=\'fill:%238cbe46;\' cx=\'299.76\' cy=\'439.067\' r=\'218.516\'/%3E%3Cg%3E%3Crect x=\'267.162\' y=\'307.978\' transform=\'matrix(0.7071 -0.7071 0.7071 0.7071 -222.6202 340.6915)\' style=\'fill:white;\' width=\'65.545\' height=\'262.18\'/%3E%3Crect x=\'266.988\' y=\'308.153\' transform=\'matrix(0.7071 0.7071 -0.7071 0.7071 398.3889 -83.3116)\' style=\'fill:white;\' width=\'65.544\' height=\'262.179\'/%3E%3C/g%3E%3C/svg%3E'},
                    { color: '#706f6f', icon: 'data:image/svg+xml,%3C%3Fxml version=\'1.0\' encoding=\'utf-8\'%3F%3E%3C!DOCTYPE svg PUBLIC \'-//W3C//DTD SVG 1.1//EN\' \'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd\'%3E%3Csvg version=\'1.1\' id=\'Ebene_1\' xmlns=\'http://www.w3.org/2000/svg\' xmlns:xlink=\'http://www.w3.org/1999/xlink\' x=\'0px\' y=\'0px\' width=\'595.275px\' height=\'595.275px\' viewBox=\'200 215 230 470\' xml:space=\'preserve\'%3E%3Ccircle style=\'fill:%23706f6f;\' cx=\'299.76\' cy=\'439.067\' r=\'218.516\'/%3E%3Cg%3E%3Crect x=\'267.162\' y=\'307.978\' transform=\'matrix(0.7071 -0.7071 0.7071 0.7071 -222.6202 340.6915)\' style=\'fill:white;\' width=\'65.545\' height=\'262.18\'/%3E%3Crect x=\'266.988\' y=\'308.153\' transform=\'matrix(0.7071 0.7071 -0.7071 0.7071 398.3889 -83.3116)\' style=\'fill:white;\' width=\'65.544\' height=\'262.179\'/%3E%3C/g%3E%3C/svg%3E'},
                ],
                scaling: null,
                originalOrientation: null,
                rotationAngle: 0,
            }
        },
        props: {
            canvasWidth: {type: Number, required: true},
            canvasHeight: {type: Number, required: true},
            loadingFunction: { type: Function, required: true },
        },
        computed: {
            ...mapGetters('files', ['getWork']),
            canvasAsImage() {
                return this.localCanvas.toDataURL('image/png', 1);
            },
        },
        methods: {
            ...mapActions('files', ['safeWork']),
            setBackground(img, imgData) {
                try {

                    const element = imgData.path && imgData.path.length > 0 ? imgData.path[0] : imgData.target;

                    const sacale = window.innerWidth * 0.75
                    let width = element.naturalWidth;
                    let height = element.naturalHeight;

                    const fabricImage = new fabric.Image(img);
                    if (element.naturalWidth > sacale) {
                        const scaleH = sacale / element.naturalWidth;
                        fabricImage.scaleToWidth(sacale);
                        fabricImage.scaleToHeight(element.naturalHeight * scaleH);
                        width = sacale;
                        height = element.naturalHeight * scaleH;
                    }

                    this.localCanvas.setDimensions({ width, height });

                    this.localCanvas.setBackgroundImage(fabricImage, this.localCanvas.renderAll.bind(this.localCanvas));
                    this.loadingFunction(false);

                } catch (e) {
                    console.error(e);
                }
            },
            async setBackgroundPDF(pdf) {

                 import('pdfjs-dist/webpack')
                    .then(pdfjs => pdfjs.getDocument({data: pdf}))
                    .then(async pdfTask => {
                        const pdf = await pdfTask.promise;
                        let pagePromises;
                        if (pdf.numPages === 1) {
                            pagePromises = [pdf.getPage(1)];
                        } else {
                            pagePromises = range(1, pdf.numPages).map(number => pdf.getPage(number))
                        }
                        return Promise.all(pagePromises)
                    })
                    .then(async (pages) => {
                        const firstPage = pages[0];
                        const viewport = firstPage.getViewport({scale: 1.5});

                        const createdCanvas = document.createElement('canvas');
                        createdCanvas.width = viewport.width;
                        createdCanvas.height = viewport.height;

                        const canvasContext = createdCanvas.getContext('2d')
                        const renderContext = {canvasContext, viewport}
                        const renderTask = firstPage.render(renderContext)
                        renderTask.promise.then(() => {
                            const zwischenUrl = createdCanvas.toDataURL('image/png');

                            const zwischenImage = new Image();
                            zwischenImage.onload = (el) => {

                                this.setBackground(zwischenImage, el);

                            }
                            zwischenImage.src = zwischenUrl;
                            firstPage.cleanup();
                        });
                    });
            },
            async setBackgroundImg(img) {
                if (img) {
                    img.onload = (imgData) => {
                        this.setBackground(img, imgData);
                    };
                }
            },
            async setEmptyBackground(type) {

                const wantedWidth = window.innerWidth * 0.75;
                const wantedHeight = wantedWidth * Math.sqrt(2);

                this.localCanvas.backgroundColor = "white";
                this.localCanvas.setDimensions({ width: wantedWidth, height: wantedHeight });

                if (type === 'kariert') {

                    const kantenlaenge = wantedHeight/30;

                    for (let i = 0; i < 30; i++) {

                        // Numbers are start and end Coords, sorted: x1, y1, x2, y2
                        let horizontalLine = new fabric.Line([0, 0, wantedWidth, 0], {
                            left: 0,
                            top: i * kantenlaenge,
                            stroke: '#ddd',
                            selectable: false,
                            evented: false
                        });

                        this.localCanvas.add(horizontalLine);
                    }

                    for (let i = 0; i < wantedWidth / kantenlaenge - 1; i++) {
                        // Numbers are start and end Coords, sorted: x1, y1, x2, y2
                        let verticalLine = new fabric.Line([0, 0, 0, wantedHeight], {
                            left: i * kantenlaenge,
                            top: 0,
                            stroke: '#ddd',
                            selectable: false,
                            evented: false
                        });

                        this.localCanvas.add(verticalLine);
                    }

                    this.localCanvas.renderAll();

                } else if (type === 'liniert') {

                    for (let i = 0; i < 20; i++) {
                        // Numbers are start and end Coords, sorted: x1, y1, x2, y2
                        let line = new fabric.Line([0, 0, wantedWidth, 0], {
                            left: 0,
                            top: i * wantedHeight/20,
                            stroke: '#ddd',
                            selectable: false,
                            evented: false
                        });

                        this.localCanvas.add(line);

                    }

                    this.localCanvas.renderAll();

                }

                this.loadingFunction(false);

            },
            safeCurrentWork() {
                let orientation = 'landscape';
                window.orientation === 0 || window.orientation === 180 ? orientation = 'portrait' : orientation = 'landscape';

                console.log(orientation);
                console.log(this.originalOrientation);

                if (this.originalOrientation && orientation !== this.originalOrientation) {
                    this.zoomObjects(this.scaling);
                } else {
                    this.localCanvas.getObjects().forEach((obj) => {
                        obj.angle = obj.angle - this.rotationAngle;
                        let oldTop = obj.top;
                        let oldLeft = obj.left;

                        switch (this.rotationAngle % 360) {
                            case 0: break;
                            case 90:
                                obj.top = (this.localCanvas.width * (1/this.scaling)) - oldLeft * (1/this.scaling);
                                obj.left = oldTop * (1/ this.scaling);
                                obj.scale(1 / this.scaling);
                                break;
                            case 180:
                                obj.top = this.localCanvas.height - oldTop;
                                obj.left = this.localCanvas.width - oldLeft;
                                break;
                            case 270:
                                obj.top = oldLeft * (1/this.scaling);
                                obj.left = (this.localCanvas.height * (1/this.scaling)) - oldTop * (1/this.scaling);
                                obj.scale(1 / this.scaling);
                                break;
                        }

                        console.log(obj);

                        obj.setCoords();
                    });
                }

                const result = [];

                const addedArr = this.history.filter(item => item.action === 'added').map(item => item.object);
                const removedArr = this.history.filter(item => item.action === 'removed').map(item => item.object);

                addedArr.forEach(addedItem => {
                   if (!removedArr.includes(addedItem)) {
                       result.push(addedItem);
                   }
                });

                return result;

            },
            loadWork(safedWork) {
                for (let i = 0; i < safedWork.length; i++) {
                    const elementObj = new fabric.Object(safedWork[i]);

                    const fabricType = fabric.util.string.camelize(elementObj.type.charAt(0).toUpperCase() + elementObj.type.slice(1));

                    var klass = fabric.util.resolveNamespace('fabric.'+fabricType);
                    if (klass) {
                        klass.fromObject(elementObj, (result) => {
                            this.localCanvas.add(result);
                            this.history.push({ action: 'added', object: result });
                        });
                    }
                }

                this.localCanvas.renderAll();
            },
            addedDrawing(event) {
                this.history.push({ action: 'added', object: event.path });
                this.localCanvas.discardActiveObject();
            },
            spawnText(xCoord, yCoord) {
                try {
                    let usableXCoord = xCoord ? xCoord : this.localCanvas.width / 2;
                    let usableYCoord = yCoord ? yCoord : this.localCanvas.height / 2;

                    var imageCopy = new Image();
                    imageCopy.src = (this.closeIconList.find(item => item.color === this.currentColor)).icon;

                    var itext = new fabric.IText('Doppelt auf diesen Text tippen um ihn zu bearbeiten', {
                        top: usableYCoord,
                        left: usableXCoord,
                        originX: 'center',
                        fill: this.currentColor,
                        fontFamily: 'Arial',
                        fontSize: 20,
                        padding: 10,
                        borderColor: this.currentColor,
                        cornerColor: this.currentColor,
                        cornerStyle: 'circle'
                    });

                    // itext.enterEditing();
                    // itext.selectAll();

                    var itextClose = new fabric.Control({
                        x: 0.53,
                        y: -1.15,
                        offsetY: 16,
                        cursorStyle: 'pointer',
                        mouseUpHandler: (eventData, target) => {
                            this.localCanvas.remove(target);
                            this.history.push({ action: 'removed', object: target });
                        },
                        render: (ctx, left, top, styleOverride, fabricObject) => {
                            var size = 24;
                            ctx.save();
                            ctx.translate(left, top);
                            ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
                            ctx.drawImage(imageCopy, -size/2, -size/2, size, size);
                            ctx.restore();
                        },
                        cornerSize: 24,
                        actionHandler: () => {},
                    })

                    itext.controls = { ...itext.controls, itextClose };

                    this.localCanvas.add(itext);
                    this.history.push({ action: 'added', object: itext });

                } catch (e) {
                    console.log(e);
                }
            },
            handleMouseInput(event) {
                // Beispiel für ein Close Icon

                if (this.currentActiveTool === 'text' && this.localCanvas.getActiveObject() == null && !this.isObjectSelected) {
                    let pos = event.pointer;

                    // this.spawnText(pos.x, pos.y);

                } else if (this.currentActiveTool === 'stempel' && this.localCanvas.getActiveObject() == null && !this.isObjectSelected) {
                    let pos = event.pointer;
                    try {

                        fabric.loadSVGFromURL(this.currentStempel, (objects, options) => {
                            var image = fabric.util.groupSVGElements(objects, options);

                            image.set({
                                left: pos.x - 15,
                                top: pos.y - 15,
                            });
                            image.scaleToWidth(75);
                            image.scaleToHeight(75);
                            console.log(image.scaleX);

                            this.localCanvas.add(image);
                            this.history.push({ action: 'added', object: image });

                        });



                    } catch (e) {
                        console.log(e);
                    }
                }
            },
            onCanvasObjectSelected(event) {
                this.isObjectSelected = true;

                // handle eraser
                if (this.currentActiveTool === 'eraser') {

                    for (let i = 0; i < this.localCanvas.getActiveObjects().length; i++) {
                        this.history.push({ action: 'removed', object: this.localCanvas.getActiveObjects()[i] });
                    }

                    for (let i = 0; i < this.localCanvas.getActiveObjects().length; i++) {
                        this.localCanvas.remove(this.localCanvas.getActiveObjects()[i]);
                    }

                }
            },
            onBeforeCanvasObjectDeselected(event) {
                this.isObjectSelected = false;
            },
            onCanvasObjectDeselected(event) {
                this.localCanvas.off('mouse:up', this.handleMouseInput);
                this.isObjectSelected = false;
                setTimeout(() => {
                    this.localCanvas.on('mouse:up', this.handleMouseInput);
                }, 250);
            },
            changeColor(newColor) {
                this.localCanvas.freeDrawingBrush.color = newColor;
                this.currentColor = newColor;
            },
            switchTool(newTool) {
                if (newTool === 'eraser') {
                    this.localCanvas.isDrawingMode = false;
                } else if (newTool === 'pen') {
                    this.localCanvas.isDrawingMode = true;
                    this.localCanvas.freeDrawingBrush.color = this.currentColor;
                } else if (newTool === 'text') {
                    this.localCanvas.isDrawingMode = false;
                } else if (newTool === 'stempel') {
                    this.localCanvas.isDrawingMode = false;
                }

                this.currentActiveTool = newTool;
            },
            undo() {
                if (this.history.length) {
                    if (this.history[this.history.length - 1].action === 'added') {
                        const objToRemove = this.localCanvas.getObjects()
                            .find(item => item === this.history[this.history.length - 1].object);

                        this.localCanvas.fxRemove(objToRemove);
                        this.history.splice(this.history.length - 1);
                        this.localCanvas.renderTop();
                    } else if (this.history[this.history.length - 1].action === 'removed') {
                        const objToAdd = this.history[this.history.length - 1].object;
                        this.localCanvas.add(objToAdd);

                        this.history.splice(this.history.length - 1);
                    }
                }

            },
            changeSize(pixelCount) {
                if (this.localCanvas.isDrawingMode) {
                    this.localCanvas.freeDrawingBrush.width = parseInt(pixelCount);
                }
            },
            setStempelImg(stempelImg) {
                this.currentStempel = stempelImg;
            },
            rotate() {
                let orientation = 'landscape';
                window.orientation === 0 || window.orientation === 180 ? orientation = 'portrait' : orientation = 'landscape';

                const containerWidth = window.innerWidth * 0.75
                // if (this.localCanvas.getWidth() >= containerWidth) {
                    const width = containerWidth;
                    const height = this.localCanvas.getHeight() * (containerWidth / this.localCanvas.getWidth());

                    const scalingFactor = containerWidth / this.localCanvas.getWidth();

                    this.localCanvas.setDimensions({width, height});
                    if (!this.scaling || (this.originalOrientation && this.originalOrientation !== orientation)) {
                        this.scaling = scalingFactor;
                        this.localCanvas.setZoom( scalingFactor);
                        // this.zoomObjects(scalingFactor);
                    } else {
                        // this.zoomObjects(1/this.scaling);
                        this.localCanvas.setZoom(1);
                    }

                    if (!this.originalOrientation) {
                        orientation === 'portrait'
                            ? this.originalOrientation = 'landscape' : this.originalOrientation = 'portrait'
                        // this.originalOrientation = orientation;
                    }
                // }

            },
            zoomObjects(factor) {
                console.log('zoomObjects called');
                var objects = this.localCanvas.getObjects();
                console.log(objects);
                for (var i in objects) {
                    var scaleX = objects[i].scaleX;
                    var scaleY = objects[i].scaleY;
                    var left = objects[i].left;
                    var top = objects[i].top;

                    var tempScaleX = scaleX * factor;
                    var tempScaleY = scaleY * factor;
                    var tempLeft = left * factor;
                    var tempTop = top * factor;

                    objects[i].scaleX = tempScaleX;
                    objects[i].scaleY = tempScaleY;
                    objects[i].left = tempLeft;
                    objects[i].top = tempTop;

                    // objects[i].setCoords();
                }
                this.localCanvas.renderAll();
            },
            computeCurrentRotation() {
                return this.localCanvas.backgroundImage.angle % 360;
            },
            rotateEditor() {
                // Rotating the canvas itsef
                let newCanvasWidth = this.localCanvas.getHeight();
                let newCanvasHeight = this.localCanvas.getWidth();
                const scalingFactor = (window.innerWidth * 0.75) / newCanvasWidth;
                this.scaling = scalingFactor;

                newCanvasHeight = newCanvasHeight * scalingFactor;
                newCanvasWidth = window.innerWidth * 0.75;

                this.localCanvas.setDimensions({ width: newCanvasWidth, height: newCanvasHeight });


                // Rotating canvas background
                this.localCanvas.backgroundImage.angle += 90;
                this.rotationAngle = this.localCanvas.backgroundImage.angle;
                this.localCanvas.backgroundImage.scaleToWidth(newCanvasWidth);
                this.localCanvas.backgroundImage.scaleToHeight(newCanvasHeight);

                switch (this.localCanvas.backgroundImage.angle % 360) {
                    case 0:
                        this.localCanvas.backgroundImage.top = 0;
                        this.localCanvas.backgroundImage.left = 0;
                        break;
                    case 90:
                        this.localCanvas.backgroundImage.top = 0;
                        this.localCanvas.backgroundImage.left = newCanvasWidth;
                        break;
                    case 180:
                        this.localCanvas.backgroundImage.top = newCanvasHeight;
                        this.localCanvas.backgroundImage.left = newCanvasWidth;
                        break;
                    case 270:
                        this.localCanvas.backgroundImage.top = newCanvasHeight;
                        this.localCanvas.backgroundImage.left = 0;
                        break;
                }
                this.localCanvas.backgroundImage.setCoords();

                // Rotating canvas objects
                this.localCanvas.getObjects().forEach((obj) => {
                    console.log(obj);
                    obj.angle += 90 //rotate each object by the same angle
                    let objOrientation = obj.angle;
                    let localScale = Math.abs(objOrientation) % 360 === 0 || Math.abs(objOrientation) % 360 === 180 ? 1 : scalingFactor;
                    // Special case if obj is svg sticker
                    if (obj.svgUid) {
                        const normScale = obj.oldScale ? obj.oldScale : obj.scaleX;
                        obj.oldScale = normScale;
                        localScale = normScale * localScale;
                    }
                    obj.scale(localScale);
                    let oldTop;
                    let oldLeft;
                    oldTop = obj.top;
                    oldLeft = obj.left;
                    console.log(obj.scaleX);

                    obj.top = oldLeft * scalingFactor;
                    obj.left = newCanvasWidth - oldTop * scalingFactor;

                    obj.setCoords();
                });

                this.localCanvas.renderAll()
            }
        },
        mounted() {
            this.localCanvas = new fabric.Canvas(this.$refs.editcanvas);
            this.localCanvas.setDimensions({width: this.canvasWidth, height: this.canvasHeight});
            this.localCanvas.backgroundColor = "#fff";

            this.localCanvas.isDrawingMode = false;
            this.localCanvas.on('path:created', this.addedDrawing);
            this.localCanvas.on('selection:created', this.onCanvasObjectSelected);
            this.localCanvas.on('selection:cleared', this.onCanvasObjectDeselected);
            this.localCanvas.on('before:selection:cleared ', this.onBeforeCanvasObjectDeselected);
            this.localCanvas.on('mouse:up', this.handleMouseInput)

            this.localCanvas.freeDrawingBrush.width = 3;

            window.addEventListener("orientationchange", () => {
                setTimeout(() => {this.rotate()}, 250);
            }, false);
        }
    }
</script>

<style scoped lang="scss">

    #canvasContainer {
        border-radius: 5px;
        border: 1px solid grey;
    }

</style>
