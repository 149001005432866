<template>
    <div style="border-radius: 5px !important; background-color: #F8F8F8">
        <v-row :style="{ backgroundColor: groupColor, height: '80px' }" class="ma-auto mb-1 pa-2" align="center">

            <v-col :cols="windowWidth < 770 ? (windowWidth < 380 ? 10 : 9) : 7">

                <v-menu style="border-radius: 5px !important;" offset-y nudge-left="54" nudge-bottom="5">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                                :class="{ activeTool: currentActiveMethod === 'pen' }"
                                v-bind="attrs"
                                v-on="on"
                                x-small class="mr-3 toolSelector"
                                @click="setTool('pen')"
                        >
                            <img :src="stiftIcon" style="max-width: 25px; max-height: 25px"/>
                        </v-btn>
                    </template>

                    <div style="width: 200px; height: 25px; display: inline; border-radius: 0 !important; background-color: #EAEAEA; ">
                        <v-btn text x-small
                               @click="changeSize(3)"
                               style="background-color: #EAEAEA; border-radius: 0 !important; width: 54px; height: 49px">
                            <v-icon style="font-size: 6px !important;">fas fa-circle</v-icon>
                        </v-btn>
                        <v-btn text x-small
                               @click="changeSize(13)"
                               style="background-color: #EAEAEA; border-radius: 0 !important; width: 54px; height: 49px"
                               class="pa-0">
                            <v-icon style="font-size: 13px !important;">fas fa-circle</v-icon>
                        </v-btn>
                        <v-btn text x-small
                               @click="changeSize(22)"
                               style="background-color: #EAEAEA; border-radius: 0 !important; width: 54px; height: 49px"
                               class="pa-0">
                            <v-icon style="font-size: 22px !important;">fas fa-circle</v-icon>
                        </v-btn>
                    </div>
                </v-menu>

                <v-btn x-small class="mr-3 toolSelector" :class="{ activeTool: currentActiveMethod === 'text' }"
                       @click="() => { setTool('text'); spawnTextOnCanvas(); }">
                    <img :src="textIcon" style="max-width: 28px; max-height: 35px"/>
                </v-btn>

                <!-- Eraser Button -->
                <v-btn :class="{ activeTool: currentActiveMethod === 'eraser', 'mr-1': windowWidth < 600, 'mr-3': windowWidth > 600 }"
                       x-small class="toolSelector"
                       @click="setTool('eraser')">
                    <img :src="radiererIcon" style="max-width: 25px; max-height: 25px"/>
                </v-btn>
                <!--            -->

                <v-btn v-if="false" style="width: 49px; height: 49px;"
                       x-small text class="mr-3"
                       @click="() => { displayOtherButtons = !displayOtherButtons }">
                    <p style="font-size: 30px; color: white">...</p>
                </v-btn>

<!--            Color palette    -->
                <v-menu v-if="displayOtherButtons" style="border-radius: 5px !important;" offset-y nudge-left="162" nudge-bottom="5">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                                v-bind="attrs"
                                v-on="on"
                                x-small class="mr-3 toolSelector"
                        >
                            <v-icon style="font-size: 22px !important;" :color="color">fas fa-circle</v-icon>
                        </v-btn>
                    </template>

                    <div style="width: 200px; height: 25px; display: inline; border-radius: 0 !important; background-color: #EAEAEA; ">
                        <v-btn text x-small
                               style="background-color: #EAEAEA; border-radius: 0 !important; width: 54px; height: 49px">
                            <v-icon @click="changeColor('#fac837')" style="font-size: 22px !important; color: #fac837">fas fa-circle</v-icon>
                        </v-btn>
                        <v-btn text x-small
                               style="background-color: #EAEAEA; border-radius: 0 !important; width: 54px; height: 49px"
                               class="pa-0">
                            <v-icon @click="changeColor('#f07d32')" style="font-size: 22px !important; color: #f07d32">fas fa-circle</v-icon>
                        </v-btn>
                        <v-btn text x-small
                               style="background-color: #EAEAEA; border-radius: 0 !important; width: 54px; height: 49px"
                               class="pa-0">
                            <v-icon @click="changeColor('#e6231e')" style="font-size: 22px !important; color: #e6231e">fas fa-circle</v-icon>
                        </v-btn>
                        <v-btn text x-small
                               style="background-color: #EAEAEA; border-radius: 0 !important; width: 54px; height: 49px"
                               class="pa-0">
                            <v-icon @click="changeColor('#6996cd')" style="font-size: 22px !important; color: #6996cd">fas fa-circle</v-icon>
                        </v-btn>
                        <v-btn text x-small
                               style="background-color: #EAEAEA; border-radius: 0 !important; width: 54px; height: 49px"
                               class="pa-0">
                            <v-icon @click="changeColor('#505faa')" style="font-size: 22px !important; color: #505faa">fas fa-circle</v-icon>
                        </v-btn>
                        <v-btn text x-small
                               style="background-color: #EAEAEA; border-radius: 0 !important; width: 54px; height: 49px"
                               class="pa-0">
                            <v-icon @click="changeColor('#8cbe46')" style="font-size: 22px !important; color: #8cbe46">fas fa-circle</v-icon>
                        </v-btn>
                        <v-btn text x-small
                               style="background-color: #EAEAEA; border-radius: 0 !important; width: 54px; height: 49px"
                               class="pa-0">
                            <v-icon @click="changeColor('#706f6f')" style="font-size: 22px !important; color: #706f6f">fas fa-circle</v-icon>
                        </v-btn>
                    </div>
                </v-menu>

                <v-btn v-if="false" style="background-color: #f8f8f8; width: 49px; height: 49px" x-small class="mr-3" @click="undo">
                    <img :src="undoIcon" style="max-width: 25px; max-height: 25px"/>
                </v-btn>

                <v-btn v-if="displayOtherButtons" style="background-color: #f8f8f8; width: 49px; height: 49px" x-small class="mr-3" @click="rotateEditor">
                    <img :src="drehIcon" style="max-width: 25px; max-height: 25px"/>
                </v-btn>

                <v-menu offset-y v-if="windowWidth < 600">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn style="width: 49px; height: 49px;"
                               id="smallDisplayMoreButtonsTrigger"
                               x-small text class="mr-3" v-bind="attrs"
                               v-on="on" >
                            <p style="font-size: 30px; color: white">...</p>
                        </v-btn>
                    </template>
                    <div id="smallDisplayMoreButtons">
                        <v-btn style="background-color: #f8f8f8; width: 49px; height: 49px" x-small class="mr-3" @click="rotateEditor">
                            <img :src="drehIcon" style="max-width: 25px; max-height: 25px"/>
                        </v-btn>

                        <!--            Color palette    -->
                        <v-menu style="border-radius: 5px !important;" offset-y nudge-left="162" nudge-bottom="5">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                        v-bind="attrs"
                                        v-on="on"
                                        style="background-color: #f8f8f8; width: 49px; height: 49px"
                                        x-small class="mr-3"
                                >
                                    <v-icon style="font-size: 22px !important;" :color="color">fas fa-circle</v-icon>
                                </v-btn>
                            </template>

                            <div class="colorPickerMenu">
                                <v-btn text x-small
                                       style="background-color: #EAEAEA; border-radius: 0 !important; width: 54px; height: 49px">
                                    <v-icon @click="changeColor('#fac837')" style="font-size: 22px !important; color: #fac837">fas fa-circle</v-icon>
                                </v-btn>
                                <v-btn text x-small
                                       style="background-color: #EAEAEA; border-radius: 0 !important; width: 54px; height: 49px"
                                       class="pa-0">
                                    <v-icon @click="changeColor('#f07d32')" style="font-size: 22px !important; color: #f07d32">fas fa-circle</v-icon>
                                </v-btn>
                                <v-btn text x-small
                                       style="background-color: #EAEAEA; border-radius: 0 !important; width: 54px; height: 49px"
                                       class="pa-0">
                                    <v-icon @click="changeColor('#e6231e')" style="font-size: 22px !important; color: #e6231e">fas fa-circle</v-icon>
                                </v-btn>
                                <v-btn text x-small
                                       style="background-color: #EAEAEA; border-radius: 0 !important; width: 54px; height: 49px"
                                       class="pa-0">
                                    <v-icon @click="changeColor('#6996cd')" style="font-size: 22px !important; color: #6996cd">fas fa-circle</v-icon>
                                </v-btn>
                                <v-btn text x-small
                                       style="background-color: #EAEAEA; border-radius: 0 !important; width: 54px; height: 49px"
                                       class="pa-0">
                                    <v-icon @click="changeColor('#505faa')" style="font-size: 22px !important; color: #505faa">fas fa-circle</v-icon>
                                </v-btn>
                                <v-btn text x-small
                                       style="background-color: #EAEAEA; border-radius: 0 !important; width: 54px; height: 49px"
                                       class="pa-0">
                                    <v-icon @click="changeColor('#8cbe46')" style="font-size: 22px !important; color: #8cbe46">fas fa-circle</v-icon>
                                </v-btn>
                                <v-btn text x-small
                                       style="background-color: #EAEAEA; border-radius: 0 !important; width: 54px; height: 49px"
                                       class="pa-0">
                                    <v-icon @click="changeColor('#706f6f')" style="font-size: 22px !important; color: #706f6f">fas fa-circle</v-icon>
                                </v-btn>
                            </div>
                        </v-menu>
                    </div>
                </v-menu>
            </v-col>

            <v-col :cols="windowWidth < 770 ? (windowWidth < 380 ? 2 : 3) : 5" style="display: flex; justify-content: flex-end;">

                <v-btn class="save-image-btn ml-4 mr-3" elevation="3" dark small @click="saveImage">
                    <img :src="fertigIcon" class="mr-2"/>
                    <p class="mb-0 pa-1">Fertig</p>
                </v-btn>

                <v-btn id="closeBtn" @click="zwischenspeichern" x-small class="mr-4" elevation="3">
                    <img :src="closeIcon" style="height: 40px; filter: brightness(1000%)"/>
                </v-btn>
            </v-col>

        </v-row>
        <vuescroll :ops="ops" style="height: calc(100vh - 85px); background-color: #EAEAEA" ref="scrollbar">

            <!-- Editing row -->
            <v-row class="ma-0" align="center" justify="center">
                <v-btn elevation="3" x-small class="mr-3 zoomControl" @click="changeZoom('minus')">
                    <p>-</p>
                </v-btn>

                <v-btn elevation="3" x-small class="mr-3 zoomControl" style="margin-left: 63px" @click="changeZoom('plus')">
                    <p>+</p>
                </v-btn>
                <EditingCanvas
                        :canvas-height="canvasHeight"
                        :canvas-width="canvasWidth"
                        :loading-function="setLoading"
                        ref="editingcanvas"/>
            </v-row>
        </vuescroll>

        <!-- Popup land -->
        <v-dialog v-model="loadingActive" hide-overlay persistent width="150">
            <v-card :color="groupColor">
                <v-card-text class="pa-2">
                    <v-progress-circular indeterminate class="ma-auto" style="display: block"/>
                    <br/>
                    <p class="ma-auto" style="text-align: center">{{ loadingMessage }}</p>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-snackbar
                v-model="snackbars.fileNotAllowed"
                timeout="5000"
                color="error"
        >
            Zugriff auf diese Datei wurde verweigert.
        </v-snackbar>
        <v-snackbar
                v-model="snackbars.fileNotAvailable"
                timeout="5000"
                color="error"
        >
            Die Datei konnte nicht geladen werden.
        </v-snackbar>
        <v-snackbar
                v-model="snackbars.dataCouldntBeSaved"
                timeout="5000"
                color="error"
        >
            Ergebnisse konnten nicht gespeichert werden. Bitte versuche es später nochmal.
        </v-snackbar>
        <v-snackbar
                v-model="snackbars.genericError"
                timeout="5000"
                color="error"
        >
            Ein Fehler ist aufgetreten.
        </v-snackbar>
    </div>
</template>

<script>
    import EditingCanvas from "../EditingCanvas";
    import {mapActions, mapGetters} from 'vuex';
    import stiftIcon from '../../assets/Editing/stift.svg'
    import undoIcon from '../../assets/Editing/rückgänig.svg'
    import closeIcon from '../../assets/Icons/abbrechen-08.svg'
    import drehIcon from '../../assets/Icons/90-grad-drehung.svg'
    import textIcon from '../../assets/Icons/Textfunktion-67.svg'
    import speichernIcon from '../../assets/Icons/speichern-64.svg'
    import radiererIcon from '../../assets/Icons/radierer-73.svg'
    import fertigIcon from '../../assets/Icons/fertig-74.svg'
    import vuescroll from 'vuescroll';
    import scrollbarStyling from '../../util/scrollbarStyling'
    import * as backend from "../../api/backend";

    export default {
        name: 'app',
        components: {
            EditingCanvas,
            vuescroll,
        },
        data() {
            return {
                windowWidth: window.innerWidth,
                currentActiveMethod: 'none',
                params: {},
                color: "#706f6f",
                imageUrl: null,
                croppedImage: false,
                canvasWidth: 300,
                canvasHeight: 600,
                componentKey: 0,
                currentFile: null,
                groupName: 'Deutsch',
                groupColor: 'blue',
                assignmentName: 'Aufgabe 1',
                appointmentId: '',
                fileId: '',
                uploadId: '',
                emptyPageType: null,
                loadingActive: false,
                loadingMessage: 'Dokument wird geladen...',
                snackbars: {
                    dataSaved: false,
                    dataSubmitted: false,
                    fileNotAllowed: false,
                    fileNotAvailable: false,
                    dataCouldntBeSaved: false,
                    genericError: false,
                },
                ops: {
                    rail: {
                        opacity: '0',
                        background: undefined,
                        border: 'none',
                        size: '60px'
                    },
                    bar: {
                        background: '#55555580',
                        keepShow: true,
                        size: '45px',
                        minSize: 0.1
                    },
                    scrollButton: {
                        enable: true,
                        background: '#cecece'
                    },
                    scrollPanel: {
                        easing: 'easeInQuad',
                        speed: 800
                    },
                    vuescroll: {
                        wheelScrollDuration: 0,
                        wheelDirectionReverse: false,
                        sizeStrategy: 'percent',
                        detectResize: true
                    }
                },
                stiftIcon,
                undoIcon,
                closeIcon,
                textIcon,
                speichernIcon,
                radiererIcon,
                fertigIcon,
                scrollbarStyling,
                drehIcon,
                currentZoom: 0,
                displayOtherButtons: false,
                isHorizontalScrollbarStyled: false,
                isVerticalScrollbarStyled: false,
            }
        },
        props: {},
        computed: {
            ...mapGetters('files', ['getCurrentFileUrl', 'getCurrentFile', 'getCurrentFileType', 'getWork']),
        },
        methods: {
            ...mapActions('files', ['pullCurrentFile', 'safeCurrentFile', 'setCurrentFile', 'safeWork', 'pullSavedWork', 'setSnackbar', 'safeEmptyPage', 'safeWorkFromEmptyPage']),
            changeColor(colorHex) {
                this.color = colorHex;
                this.$refs.editingcanvas.changeColor(colorHex);
            },
            async zwischenspeichern() {
                try {

                    this.setLoading(true, 'Warten auf Speichern...');
                    const canvasHistory = this.$refs.editingcanvas.safeCurrentWork();

                    let response = null;
                    if (!this.emptyPageType) {
                        response = await this.safeWork({
                            appointmentId: this.appointmentId,
                            uploadId: this.uploadId,
                            changeHistory: canvasHistory,
                        });
                    } else {
                        // response = await this.safeWorkFromEmptyPage({
                        //     appointmentId: this.appointmentId,
                        //     changeHistory: canvasHistory,
                        // });
                        await this.close();
                    }

                    if (response.status === 200 ) {
                        if (canvasHistory.length > 0) {
                            this.setSnackbar({type: 'dataSaved', value: true });
                        }

                        await this.close();
                    } else {
                        this.snackbars.dataCouldntBeSaved = true;
                    }

                    this.setLoading(false);
                } catch (e) {
                    this.snackbars.dataCouldntBeSaved = true;
                    this.setLoading(false);
                }
            },
            loadWork(savedWork) {
                this.$refs.editingcanvas.loadWork(savedWork);
            },
            async saveImage() {
                this.setLoading(true, 'Warten auf Abgabe...');
                let image = this.$refs.editingcanvas.canvasAsImage;
                if (image && this.fileId) {
                    await this.saveImageAsFile(image);
                } else if (image && this.emptyPageType) {
                    await this.saveNewPage(image);
                }
            },
            async saveImageAsFile(base64) {
                try {
                    const response = await this.safeCurrentFile({
                        userGroup: 'pupil',
                        data: base64,
                        appointmentId: this.appointmentId,
                        fileId: this.fileId,
                        fileName: this.assignmentName
                    });

                    if (response.status === 200
                        || response.status === 201
                        || response.status === 202
                        || response.status === 204) {
                        this.setSnackbar({type: 'dataSubmitted', value: true});
                        await this.close();
                    } else {
                        this.snackbars.dataCouldntBeSaved = true;
                        this.setLoading(false);
                    }
                } catch (e) {
                    this.snackbars.dataCouldntBeSaved = true;
                    this.setLoading(false);
                }
            },
            async saveNewPage(base64) {
                try {
                    const response = await this.safeEmptyPage({
                        data: base64,
                        appointmentId: this.appointmentId,
                        fileName: this.assignmentName
                    });

                    response.addEventListener('load', (e) => {
                        if (response.status === 200
                            || response.status === 201
                            || response.status === 202
                            || response.status === 204) {
                            this.setSnackbar({type: 'dataSubmitted', value: true});
                            this.close();
                        } else {
                            this.snackbars.dataCouldntBeSaved = true;
                            this.setLoading(false);
                        }
                    });
                } catch (e) {
                    this.snackbars.dataCouldntBeSaved = true;
                    this.setLoading(false);
                }
            },
            changeSize(pixelCount) {
                console.log('changeSize called with pixelCount', pixelCount)
                this.$refs.editingcanvas.changeSize(pixelCount);
            },
            changeZoom(direction) {
              this.$refs.editingcanvas.$el.style.transform = '';

              if (direction === 'minus' && this.currentZoom > 0) {
                  this.currentZoom--;
              } else if (direction === 'plus' && this.currentZoom < 10) {
                  this.currentZoom++;
              }

              setTimeout(() => {

                this.$refs.editingcanvas.$el.style.transform = `scale(${1 + (this.currentZoom / 5)}, ${1 + (this.currentZoom / 5)})`;
                this.$refs.editingcanvas.$el.style.transformOrigin = `left top`;

                this.$nextTick(this.$refs.scrollbar.refresh());
                this.$nextTick(this.$refs.scrollbar.refreshInternalStatus());
              }, 150);

            },
            setTool(type) {
                if (this.currentActiveMethod !== type) {
                    this.currentActiveMethod = type;
                    this.$refs.editingcanvas.switchTool(type);
                } else {
                    this.currentActiveMethod = 'none';
                    this.$refs.editingcanvas.switchTool('none');
                }
            },
            undo() {
                // this.currentActiveMethod = this.undo;
                this.$refs.editingcanvas.undo();
            },
            async close() {
                await this.$router.replace({
                    query: {
                        appointment: this.appointmentId,
                    }
                });
                this.setCurrentFile(null);
                this.$router.back();
                this.setLoading(false);
            },
            setLoading(status, msg) {
                this.loadingMessage = msg ? msg : 'Dokument wird geladen...';
                this.loadingActive = status;
            },
            spawnTextOnCanvas() {
                this.$refs.editingcanvas.spawnText(null, null);
            },
            checkForScrollbarStylingUpdates(scrollbarParent) {
                if (scrollbarParent.classList.contains('hasVBar') ) {
                    if (!this.isVerticalScrollbarStyled) {
                        this.scrollbarStyling.styleVerticalScrollbar();
                        this.isVerticalScrollbarStyled = true;
                    }
                } else {
                    this.isVerticalScrollbarStyled = false;
                }

                if (scrollbarParent.classList.contains('hasHBar') ) {
                    if (!this.isHorizontalScrollbarStyled) {
                        this.scrollbarStyling.styleHorizontalScrollbar();
                        this.isHorizontalScrollbarStyled = true;
                    }
                } else {
                    this.isHorizontalScrollbarStyled = false;
                }
            },
            async requestFileForEditing(fileType, userGroup) {
                const response = await this.pullCurrentFile({
                    userGroup,
                    fileType,
                    fileName: this.assignmentName,
                    appointmentId: this.appointmentId,
                    fileId: this.fileId
                });

                if (response && (response.status === 200
                    || response.status === 201
                    || response.status === 204)) {
                    if (response.file) {
                        this.currentFile = response.file;
                        if (this.getCurrentFileType === 'pdf') {
                            await this.$refs.editingcanvas.setBackgroundPDF(this.currentFile);
                        } else {
                            await this.$refs.editingcanvas.setBackgroundImg(this.currentFile);
                        }
                    } else {
                        if (response.error) {
                            this.snackbars.genericError = true;
                        }
                        this.setLoading(false);
                    }
                    this.changeColor('#505faa');
                } else {
                    if (response.status === 401 || response.status === 403 ) {
                        this.snackbars.fileNotAllowed = true;
                    } else if (response.status === 404) {
                        this.snackbars.fileNotAvailable = true;
                    } else {
                        this.snackbars.genericError = true;
                    }
                    this.setLoading(false);
                }
            },
            rotateEditor() {
                // console.log('rotateEditor called in editschueler');
                this.$refs.editingcanvas.rotateEditor();
            }
        },
        async mounted() {

            let fileType = '';
            let localUploadId = '';
            let localAppointmentId = '';
            if (this.$route.query.group) {
                this.groupName = this.$route.query.group;
            }
            if (this.$route.query.color) {
                this.groupColor = this.$route.query.color;
            }
            if (this.$route.query.aId) {
                this.appointmentId = this.$route.query.aId;
                localAppointmentId = this.$route.query.aId;
            }
            if (this.$route.query.fId) {
                this.fileId = this.$route.query.fId;
            }
            if (this.$route.query.title) {
                this.assignmentName = this.$route.query.title;

                fileType = this.assignmentName.split('.')[1];
            }
            if (this.$route.query.uploadid) {
                this.uploadId = this.$route.query.uploadid;
                localUploadId = this.$route.query.uploadid;
            }
            if (this.$route.query.pagetype) {
                this.emptyPageType = this.$route.query.pagetype;
            }

            let localUserGroup = 'pupil';
            if (this.$route.query.ug) {
                localUserGroup = this.$route.query.ug;
            }

            this.setLoading(true);

            if (this.emptyPageType) {
                console.log(this.emptyPageType);
                await this.$refs.editingcanvas.setEmptyBackground(this.emptyPageType)
            } else {
                await this.requestFileForEditing(fileType, localUserGroup);
            }

            // Editor Autosave parts

            const params = {
                appointmentId: localAppointmentId,
                uploadId: localUploadId
            };
            try {

                const bufferResponse = await this.pullSavedWork(params);

                if (bufferResponse) {
                    const responseJson = await bufferResponse.json();
                    this.loadWork(responseJson.changeHistory);
                }
            } catch (e) {
                // Buffer is probably empty, should it be empty tho?
                if (e.name !== 'SyntaxError') {
                    console.error(e);
                }
            }

            this.checkForScrollbarStylingUpdates(document.getElementsByClassName('__vuescroll')[0]);

            const observer = new MutationObserver((mutationsList, observer) => {
                for(const mutation of mutationsList) {
                    if (mutation.type === 'childList') {
                        this.checkForScrollbarStylingUpdates(document.getElementsByClassName('__vuescroll')[0]);
                    }
                }
            });

            // Start observing the target node for configured mutations
            observer.observe(
                document.getElementsByClassName('__vuescroll')[0],
                {attributes: false, childList: true, subtree: false }
            );

            if (this.windowWidth > 600) {
                this.displayOtherButtons = true;
            }
        },
    }
</script>

<style scoped lang="scss">
    @import "~@/styles/globals.scss";

    .save-image-btn {
        margin-left: 5px;
        height: 49px !important;
        background-color: #8CBD46 !important;

        @media (max-width: 380px) {
            width: 40px !important;
            min-width: 40px !important;
            height: 40px !important;
        }

        & img {
            @include zwischenspeichernIconSize();
            filter: brightness(1000%);
        }

        & p {
            @include zwischenspeichernFontSize();
        }
    }

    #closeBtn {
        background-color: #e6221e80;
        width: 49px;
        height: 49px;

        @media (max-width: 380px) {
            width: 40px !important;
            height: 40px !important;
            margin-right: 0 !important;
        }
    }

    .toolSelector {
        background-color: #f8f8f880;
        width: 49px !important;
        height: 49px !important;

        @media (max-width: 380px) {
            width: 40px !important;
            height: 40px !important;
        }
    }

    .activeTool {
        background-color: #d8d8d8 !important;
    }

    .zoomControl {
        opacity: 0.8 !important;
        position: fixed;
        z-index: 8 !important;
        top: 95px;
        left: 20px;
        background-color: #f8f8f8 !important;
        width: 49px !important;
        height: 49px !important;
        border-radius: 8px;

        p {
            line-height: 0;
            font-size: 60px;
            margin-bottom: 0;
            font-weight: 300;
            color: #707070;
        }

        @media (max-width: 380px) {
            width: 40px !important;
            height: 40px !important;

            p {
                font-size: 50px;
            }
        }
    }

    #smallDisplayMoreButtons {
        display: block;
        width: 50px;

        button {
            border-radius: 0 !important;
        }
    }

    #smallDisplayMoreButtonsTrigger {
        width: 49px !important;
        height: 49px !important;
        padding-left: 2px !important;

        @media (max-width: 380px) {
            width: 40px !important;
            height: 40px !important;
            padding-left: 0 !important;
        }
    }

    .colorPickerMenu {
        width: 216px !important;
        height: 25px;
        display: inline;
        border-radius: 0 !important;
        background-color: #EAEAEA;
    }

    .v-snack.v-snack--top {
        bottom: initial; /* or auto */
    }

    .v-snack.v-snack--bottom {
        top: initial; /* or auto */
    }

</style>
