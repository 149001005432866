<template>
    <div style="width: 90%; padding-right: 3px; padding-left: -3px">
        <progress-bar
            title="Hochladen"
            :show-progress="showTeacherUploadProgress"
            :progress="teacherUploadProgress"
            :abort-progress="teacherXmlHttpRequest ? () => { teacherXmlHttpRequest.abort() }: () => {}"
        />
        <name-file
            v-model="showNameFile"
            :original-name="uploadFileName"
            :callback="fileNameCallback"
        />

        <!--simplified view does not have fach popup, to complicate for kids-->
        <div v-if="thisAppointment" style="width: 100%; display: block; padding-left: 0 !important;">
            <!--Fach is currently running-->
            <v-row v-if="isRunning" cols="12" class="pa-0 ma-0 vRowHeight">
                <div @click="readInfo()" class="pa-3 fachContainer" style="width: calc(100% - 76px)">

                    <img v-if="getSrc()" class="iconSelect" :src="getSrcWhite(thisAppointment.schoolSubject.icon)" :style="isRunning ? getBackgroundColor() : 'background-color: transparent'">
                    <div v-else class="iconSelect"></div>

                    <div class="subjectText pl-2">{{ thisAppointment.schoolSubject.name }}</div>

                    <img :src="getColorIcon()" style="position: absolute; height: 40px; top: 0; right: 5%"/>
                </div>

                <!--Kamera-Icon next to fach-->
                <div @click="clickBeitreten" class="pa-0 kameraIcon">
                    <v-btn class="pa-0" :class="{isFachRunning: isRunning}" style="height: 100%; width: 100%; background-color: white; border-radius: 8px;">
                        <img :src="kameraIconWeiss" :class="{isFachRunningIcon: isRunning}" style="height: 48px"/>
                    </v-btn>
                </div>
            </v-row>

            <!--Fach is not running currently-->
            <v-row v-else cols="12" class="pa-0 ma-0 vRowHeight">
                <div @click="readInfo()" class="pa-3 fachContainer" style="opacity: 40%;" :style="hasRun ? 'width: calc(100% - 76px)' : ''">

                    <img v-if="getSrc()" class="iconSelect" :src="getSrc()" style="background-color: transparent;">
                    <div class="iconSelect" v-else></div>

                    <div class="subjectText  pl-2">{{ thisAppointment.schoolSubject.name }}</div>

                    <img :src="getColorIcon()" style="position: absolute; height: 40px; top: 0; right: 5%"/>
                </div>

                <div @click="clickBeitreten" v-if="hasRun" class="pa-0 kameraIcon" style="opacity: 40%">
                    <v-btn class="pa-0" :class="{isFachRunning: hasRun}" style="height: 100%; width: 100%; background-color: white; border-radius: 8px;">
                        <img :src="kameraIconWeiss" :class="{isFachRunningIcon: hasRun}" style="height: 48px"/>
                    </v-btn>
                </div>
            </v-row>
        </div>

        <!--fach with no text and image-->
        <v-row v-else cols="12" class="pa-0 ma-0 vRowHeight">
            <div @click="readInfo()" class="pa-3" style="display: flex; align-content: center; font-size: 25px; width: 100%" :style="'opacity: 40%; border-radius: 10px; box-shadow: 1px 2px 3px silver; background-color: #FFF'">
                <div style="width: 40px"></div>
                <div style="color: #707070;" class="pl-2 d-flex align-center">Hohlstunde</div>
            </div>
        </v-row>

        <v-snackbar bottom v-model="snackbar" color="error" timeout="3000">
            {{ snackbarText }}
        </v-snackbar>

        <v-snackbar bottom v-model="snackbarSave" color="success" timeout="3000">
            Gespeichert!
        </v-snackbar>
    </div>

</template>

<script>
/*import vueCustomScrollbar from "vue-custom-scrollbar";*/
import * as backend from "../../api/backend";
import kameraIconWeiss from '../../assets/Icons/kamera-video-weiss-72.svg'
import schliesenIcon from '../../assets/Icons/abbrechen-08.svg'
import buchzeichenIcon from '../../assets/Icons/lesezeichen-01-43.svg'
import speichernIcon from '../../assets/Icons/speichern-64.svg'
import lehrerIcon from '../../assets/Icons/lehrer-24.svg'
import bearbeitenIcon from '../../assets/Icons/bearbeiten-normal-41.svg'
import bearbeitetIcon from '../../assets/Icons/bearbeitet-17.svg'
import runterladenIcon from '../../assets/Icons/runterladen-14.svg'
import hochladenIcon from '../../assets/Icons/hochladen-15.svg'
import rechtsIcon from '../../assets/Icons/rechts-11.svg'
import lesezeichenIcon from '../../assets/Icons/lesezeichen-01-43.svg'
import offenIcon from '../../assets/Icons/offen-18.svg'

import bioIcon from '../../assets/Icons/f-biologie-35.svg'
import chemieIcon from '../../assets/Icons/f-chemie-37.svg'
import deutschIcon from '../../assets/Icons/f-deutsch-29.svg'
import englischIcon from '../../assets/Icons/f-englisch-30.svg'
import erdkundeIcon from '../../assets/Icons/f-erdkunde-31.svg'
import geschichteIcon from '../../assets/Icons/f-geschichte-40.svg'
import matheIcon from '../../assets/Icons/f-mathe-32.svg'
import musikIcon from '../../assets/Icons/f-musik-84.svg'
import physikIcon from '../../assets/Icons/f-physik-36.svg'
import politikIcon from '../../assets/Icons/f-politik-34.svg'
import religionIcon from '../../assets/Icons/f-religion-39.svg'
import sportIcon from '../../assets/Icons/f-sport-38.svg'
import wirtschaftIcon from '../../assets/Icons/f-wirtschaft-33.svg'

import bioIconWeiss from '../../assets/Icons/f-biologie-weiss-35.svg'
import chemieIconWeiss from '../../assets/Icons/f-chemie-weiss-37.svg'
import deutschIconWeiss from '../../assets/Icons/f-deutsch-weiss-29.svg'
import englischIconWeiss from '../../assets/Icons/f-englisch-weiss-30.svg'
import erdkundeIconWeiss from '../../assets/Icons/f-erdkunde-weiss-31.svg'
import geschichteIconWeiss from '../../assets/Icons/f-geschichte-weiss-40.svg'
import matheIconWeiss from '../../assets/Icons/f-mathe-weiss-32.svg'
import musikIconWeiss from '../../assets/Icons/f-musik-weiss-82.svg'
import physikIconWeiss from '../../assets/Icons/f-physik-weiss-36.svg'
import politikIconWeiss from '../../assets/Icons/f-politik-weiss-34.svg'
import religionIconWeiss from '../../assets/Icons/f-religion-weiss-39.svg'
import sportIconWeiss from '../../assets/Icons/f-sport-weiss-38.svg'
import wirtschaftIconWeiss from '../../assets/Icons/f-wirtschaft-weiss-33.svg'

import colorfcd20a from '../../assets/Icons/lesezeichen-gelb-47.svg'
import colorf0b423 from '../../assets/Icons/lesezeichen-orange-48.svg'
import colorff6941 from '../../assets/Icons/lesezeichen-rot-49.svg'
import color91c364 from '../../assets/Icons/lesezeichen-hellgrün-50.svg'
import color91c887 from '../../assets/Icons/lesezeichen-mintgrün-51.svg'
import colord7875f from '../../assets/Icons/lesezeichen-braun-52.svg'
import colorebaabe from '../../assets/Icons/lesezeichen-rosa-53.svg'
import color9b91c8 from '../../assets/Icons/lesezeichen-lila-54.svg'
import color6ec3dc from '../../assets/Icons/lesezeichen-blau-55.svg'
import color9bcdc3 from '../../assets/Icons/lesezeichen-minze-56.svg'
import colorb2b2b2 from '../../assets/Icons/lesezeichen-hellgrau-43.svg'
import color707070 from '../../assets/Icons/lesezeichen-dunkelgrau-44.svg'

import {mapState, mapMutations, mapActions, mapGetters} from "vuex";
import ProgressBar from "@/components/ProgressBar";
import NameFile from "@/components/NameFile";
import { tutorials } from '@/constants/tutorial';
import translation from "../../util/translate";

export default {
    name: "Fach",
    components: {
        NameFile,
        ProgressBar,
        /*vueCustomScrollbar,*/
    },
    data() {
        return {
            menu: false,
            thisAppointment: null,
            snackbar: false,
            snackbarText: '',
            snackbarSave: false,
            update: false,
            settings: {
                suppressScrollY: false,
                suppressScrollX: true,
                wheelPropagation: false,
            },
            showTeacherUploadProgress: false,
            teacherUploadProgress: 0.0,
            teacherXmlHttpRequest: null,
            showNameFile: false,
            uploadFileName: '',
            fileNameCallback: () => {
            },

            bbbTutState: null,
            bbbTut: false,

            fachIcons: [
                {name: 'bioIcon', icon: bioIconWeiss },
                {name: 'chemieIcon', icon: chemieIconWeiss },
                {name: 'deutschIcon', icon: deutschIconWeiss },
                {name: 'englischIcon', icon: englischIconWeiss },
                {name: 'erdkundeIcon', icon: erdkundeIconWeiss },
                {name: 'geschichteIcon', icon: geschichteIconWeiss },
                {name: 'matheIcon', icon: matheIconWeiss },
                {name: 'musikIcon', icon: musikIconWeiss },
                {name: 'physikIcon', icon: physikIconWeiss },
                {name: 'politikIcon', icon: politikIconWeiss },
                {name: 'religionIcon', icon: religionIconWeiss },
                {name: 'sportIcon', icon: sportIconWeiss },
                {name: 'wirtschaftIcon', icon: wirtschaftIconWeiss },
            ],
            kameraIconWeiss,
            schliesenIcon,
            buchzeichenIcon,
            speichernIcon,
            lehrerIcon,
            bearbeitenIcon,
            bearbeitetIcon,
            runterladenIcon,
            hochladenIcon,
            rechtsIcon,
            lesezeichenIcon,
            offenIcon,

            bioIcon,
            chemieIcon,
            deutschIcon,
            englischIcon,
            erdkundeIcon,
            geschichteIcon,
            matheIcon,
            musikIcon,
            physikIcon,
            politikIcon,
            religionIcon,
            sportIcon,
            wirtschaftIcon,

            bioIconWeiss,
            chemieIconWeiss,
            deutschIconWeiss,
            englischIconWeiss,
            erdkundeIconWeiss,
            geschichteIconWeiss,
            matheIconWeiss,
            musikIconWeiss,
            physikIconWeiss,
            politikIconWeiss,
            religionIconWeiss,
            sportIconWeiss,
            wirtschaftIconWeiss,

            colorfcd20a,
            colorf0b423,
            colorff6941,
            color91c364,
            color91c887,
            colord7875f,
            colorebaabe,
            color9b91c8,
            color6ec3dc,
            color9bcdc3,
            colorb2b2b2,
            color707070,
          tutorials,
          account: '',
            showTextTimeout: null,
        };
    },
    props: {
        appointment: {required: false, default: null},
        requestAppointments: {required: true},
        isRunning: {type: Boolean, required: false, default: false},
        hasRun: { type: Boolean, required: false, default: false },
        isOpen: {type: Boolean, required: false, default: false},
        listIndex: { type: Number, required: false, default: undefined },
    },
    watch: {
        appointment() {
            this.thisAppointment = this.appointment;
        },
        isOpen() {
            if (!this.menu) {
                this.menu = this.isOpen;
                this.$router.replace({
                    query: {
                        appointment: undefined,
                    }
                })
            }
        },
    },
    async mounted() {
        this.checkTutorialState();
        this.thisAppointment = this.appointment;
        this.account = (await this.getMe()).account;
        this.reloadDontLogout(false);
    },
    computed: {
        ...mapState("auth", ["token"]),
        ...mapState('translation', ['targetLang']),
        ...mapGetters('pupils', ['getTutorialProgress']),

        getTutProgress() {
        if (!this.account)
          return [];
        return this.getTutorialProgress[this.account] || [];
      },
    },
    methods: {
        ...mapActions("appointments", ["editAppointment"]),
        ...mapActions('pupils', [
          'setTutorialProgress',
          'createPupil',
          'getMe',
        ]),
        ...mapActions('translation', [ 'setTranslatedText', 'showTranslation' ]),
        ...mapActions("auth", ["reloadDontLogout"]),
        ...mapActions("util", ['toggleLoading']),

        async finishTut(progress) {
        this.setTutorialProgress({ account: this.account, progress });
      },

        async readInfo() {
            if ('speechSynthesis' in window) {
                // Speech Synthesis supported 🎉
            } else {
                // Speech Synthesis Not Supported 😣
                alert("Sorry, your browser doesn't support text to speech!");
            }

            let text = '';
            if (this.thisAppointment.note) {
                text = 'Info: ' + this.thisAppointment.note;
            }
            else {
                text = 'Info: Bitte bereitet schonmal eure Unterlagen vor! Und ladet gegebenfalls vorhandene Materialien herunter.';
            }

            if (this.targetLang !== 'de') {
                text = await translation.translateToTargetLang(this.targetLang, text);

                this.setTranslatedText(text);
                this.showTranslation(true);

                if (this.showTextTimeout) {
                    clearTimeout(this.showTextTimeout);
                    this.showTextTimeout = null;
                }

                this.showTextTimeout = setTimeout(() => {
                    this.showTranslation(false);
                    this.showTextTimeout = null;
                }, 15000)
            }

            if(window.speechSynthesis.speaking) {
                window.speechSynthesis.cancel()
            } else {
                let msg = new SpeechSynthesisUtterance();
                msg.text = text;
                if (this.isLangPackageAvailable()) {
                    msg.lang = this.targetLang;
                    window.speechSynthesis.speak(msg);
                }

            }
        },
        isLangPackageAvailable() {
            for (let i = 0; i < window.speechSynthesis.getVoices().length; i++) {
                if (window.speechSynthesis.getVoices()[i].lang.includes(this.targetLang)) {
                    return true;
                }
            }

            return false;
        },
        checkTutorialState() {
            this.bbbTutState = JSON.parse(localStorage.getItem('bbbTut'));
            if(this.bbbTutState) {
                this.bbbTut = false;
            }
        },

        getSrc() {
            return this.thisSubject ? this[this.thisSubject.icon] : (this.thisAppointment ? this[this.thisAppointment.schoolSubject.icon] : null);
        },

        getSrcWhite(icon) {
            const foundItem = this.fachIcons.find(item => item.name === icon);
            if (foundItem === undefined) {
                return deutschIconWeiss;
            }
            else {
                return foundItem.icon;
            }
        },

        getColorIcon() {
            return this.thisSubject ? this['color' + this.thisSubject.color.substring(1)] : (this.thisAppointment ? this['color' + this.thisAppointment.schoolSubject.color.substring(1)] : this.colorb2b2b2);
        },

        /*Hintergrund-Farbe für Icon wenn es aktuell ist*/
        getBackgroundColor() {
            let returnString =  "background-color: ";
            return (returnString + this.thisAppointment.schoolSubject.color);
        },

        getTimeslotTimes() {
            const timeslot = this.$parent.$parent.slots.find((item) => item._id === this.thisAppointment.timeslot);
            if (timeslot) {
                return (
                    ("0" + new Date(timeslot.start).getHours()).slice(-2) + ":" + ("0" + new Date(timeslot.start).getMinutes()).slice(-2)
                    + ' - ' +
                    ("0" + new Date(timeslot.end).getHours()).slice(-2) + ":" + ("0" + new Date(timeslot.end).getMinutes()).slice(-2));
            }
        },

        async teacherUploadInputChange(appointmentId) {
            const input = this.$refs.teacherUploadInput;
            const file = input.files[0];
            if (file) {
                const name = file.name;
                const lastDot = name.lastIndexOf('.');
                const fileName = name.substring(0, lastDot);
                const fileExtension = name.substring(lastDot + 1);
                this.uploadFileName = fileName;
                this.fileNameCallback = (newFileName) => {
                    this.showNameFile = false;
                    this.uploadFile(appointmentId, newFileName, fileExtension);
                };
                this.showNameFile = true;
            }
        },

        async uploadFile(appointmentId, newFileName, fileExtension) {
            const input = this.$refs.teacherUploadInput;
            const file = input.files[0];
            if (file) {
                const formData = new FormData();
                formData.append('file', file, `${newFileName}.${fileExtension}`);

                this.teacherXmlHttpRequest = backend.postTeacherUpload(appointmentId, file);

                this.teacherXmlHttpRequest.onerror = (e) => {
                    console.error('Teacher upload error:', e)
                    this.showTeacherUploadProgress = false;
                    this.teacherXmlHttpRequest = null;
                    this.$refs.teacherUploadInput.value = '';
                };

                this.teacherXmlHttpRequest.onabort = (e) => {
                    console.warn('Teacher upload aborted');
                    this.showTeacherUploadProgress = false;
                    this.teacherXmlHttpRequest = null;
                    this.$refs.teacherUploadInput.value = '';
                }

                this.teacherXmlHttpRequest.upload.addEventListener('progress', (e) => {
                    this.teacherUploadProgress = (e.loaded / e.total) * 100;
                });

                this.teacherXmlHttpRequest.addEventListener('load', (e) => {
                    if (this.teacherXmlHttpRequest.status !== 201)
                        console.error('Teacher upload failed:', this.teacherXmlHttpRequest.response);
                    this.showTeacherUploadProgress = false;
                    this.snackbarSave = true;
                    this.requestAppointments();
                    this.teacherXmlHttpRequest = null;
                    this.$refs.teacherUploadInput.value = '';
                });
                this.teacherUploadProgress = 0.0;
                this.showTeacherUploadProgress = true;
                this.teacherXmlHttpRequest.send(formData);
            }
        },

        async clickDownloadTeacherUpload(file) {
            const res = await backend.getTeacherUpload(this.thisAppointment._id, file.file);
            fetch(res.url, {
                method: 'GET',
                headers: new Headers({
                    "Authorization": "Bearer " + this.token
                })
            })
                .then(response => response.blob())
                .then(blob => {
                    var url = window.URL.createObjectURL(blob);
                    var a = document.createElement('a');
                    a.href = url;
                    a.download = file.title;
                    document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                    a.click();
                    a.remove();  //afterwards we remove the element again
                });

        },

        async clickDownloadPupilUpload(file) {
            file.seenByMe = true;
            const res = await backend.getPupilUpload(this.thisAppointment._id, file.file);
            fetch(res.url, {
                method: 'GET',
                headers: new Headers({
                    "Authorization": "Bearer " + this.token
                })
            })
                .then(response => response.blob())
                .then(blob => {
                    var url = window.URL.createObjectURL(blob);
                    var a = document.createElement('a');
                    a.href = url;
                    a.download = file.title;
                    document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                    a.click();
                    a.remove();  //afterwards we remove the element again
                });

        },
        updateNote() {
            this.update = true;
        },
        async saveNote() {
            let data = {
                _id: this.thisAppointment._id,
                note: this.thisAppointment.note,

            };
            let response = await this.editAppointment(data);
            if (Number.isInteger(response)) {
                if (response === 409) {
                    this.snackbarText = 'Bitte eine andere Namen eingeben! (Bereits verwendet)';
                } else {
                    this.snackbarText = 'Ein unerwarteter Fehler ist aufgetretten!';
                }
                this.snackbar = true;
            } else {
                this.snackbarSave = true;
                this.update = false;
            }

        },
        clickTeacherUploadButton() {
            this.$refs.teacherUploadInput.click();
        },

        openEdit(name, id) {
            console.log('openEdit called');

            const group = this.appointment.schoolSubject.name;
            const color = this.appointment.schoolSubject.color;
            const appointmentId = this.appointment._id;
            const fileTitle = name;
            const fileId = id;


            this.$router.push({
                name: 'lehrer.edit',
                query:
                    {
                        group: group,
                        color: color,
                        aId: appointmentId,
                        title: fileTitle,
                        fId: fileId
                    }
            });
        },
        //startBBBTut
        startBBB() {
            /*emit to PlanWidgetBaby*/
            this.$emit('pass-on-bbb-tut', this.joinBBB);
        },

        async clickBeitreten() {
            this.menu = false;

            if(!this.bbbTutState) {
                this.startBBB();
            }
            else if (this.bbbTutState) {
                await this.joinBBB();
            }
        },

        async joinBBB() {
            const res = await backend.joinSchoolSubjectAppointmentBbbMeetingUrl(
                this.thisAppointment._id
            );
            this.toggleLoading(true);
            this.reloadDontLogout(true);
            const {redirectUrl} = await res.json();
            if (res.status === 422) {
                this.snackbarText = "Aktuell ist der Raum geschlossen!";
                this.snackbar = true;
            } else {
                window.location.href = redirectUrl;
                //  window.open(redirectUrl);
            }
            this.toggleLoading(false);
        },

        isCorrectionAvailable(pupilFile) {
            for (let i = 0; i < this.thisAppointment.teacherUploads.length; i++) {
                if (this.thisAppointment.teacherUploads[i].editParent) {
                    const index = pupilFile.editLeafs.indexOf(this.thisAppointment.teacherUploads[i]._id);
                    if (index !== -1) {
                        return true;
                    }
                }
            }
            return false;
        },

        async clickDownloadPupilCorrection(file) {
            let teacherCorrection = null;

            for (let i = 0; i < this.thisAppointment.teacherUploads.length; i++) {
                if (this.thisAppointment.teacherUploads[i].editParent) {
                    const index = file.editLeafs.indexOf(this.thisAppointment.teacherUploads[i]._id);
                    if (index !== -1) {
                        teacherCorrection = this.thisAppointment.teacherUploads[i];
                    }
                }
            }

            if (teacherCorrection) {
                teacherCorrection.seenByMe = true;

                const res = await backend.getTeacherUpload(this.thisAppointment._id, teacherCorrection.file);

                fetch(res.url, {
                    method: 'GET',
                    headers: new Headers({
                        "Authorization": "Bearer " + this.token
                    })
                })
                    .then(response => response.blob())
                    .then(blob => {
                        var url = window.URL.createObjectURL(blob);
                        var a = document.createElement('a');
                        a.href = url;
                        a.download = teacherCorrection.title;
                        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                        a.click();
                        a.remove();  //afterwards we remove the element again
                    });

            }
        },
    },
};
</script>


<style lang="scss" scoped>
table,
th,
td {
    border: 1px solid black;
    border-collapse: collapse;
}

tr {
    height: 40px;
}

table tr th:first-child,
table tr td:first-child {
    width: 6em !important;
}

table tr th,
table tr td {
    width: 9em;
}

table,
tr,
td {
    border: none;
}

.fach {
    cursor: pointer;
}

.iconSelect {
    display: inline-flex;
    width: 40px;
    height: 40px;
    border-radius: 5px;
    padding: 6px;
    background-color: rgba(234, 234, 234, 0.7);
}

.iconSelectFrei {
    display: inline-flex;
    width: 40px;
    border-radius: 5px;
    padding: 6px;
    background-color: transparent;
}

.subjectText {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.vRowHeight {
    height: 76px !important;
}

.kameraIcon {
    width: 76px;
    height: 76px;
}

.scroll-area {
    position: relative;
    margin: auto;
    max-height: 100px;
}

.scroll-area2 {
    position: relative;
    margin: auto;
    max-height: 100px;
}

.scroll-area3 {
    position: relative;
    margin: auto;
    max-height: 50px;
}

.buttonIcon {
    align-self: start;
    background-color: #EAEAEACC;
    padding: 2px;
    border-radius: 3px;
    margin-right: 1em;
}

//border-radius of v-menu
.v-menu__content {
    border-radius: 12px;
}

.fachContainer {
    display: flex !important;
    align-items: center !important;
    font-size: 25px;
    width: 100%;
    position: relative !important;
    color: #707070 !important;
    border-radius: 10px;
    box-shadow: 1px 2px 3px silver;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    background-color: #FFF;
}

.fachIconDiv {
    width: 40px;
    height: 40px;
    border-radius: 5px;
}

.smallFach {
    position: relative;
    color: #707070;
    border-radius: 10px;
    box-shadow: 1px 2px 3px silver;
    white-space: nowrap;
    width: 70%;
    overflow: hidden;
    text-overflow: ellipsis;
    background-color: #FFFFFF
}

.whiteIcon {
    filter: brightness(1000%);
}

.isFachRunning {
    color: white;
    background-color: #8CBD46 !important;
}

.isFachRunningIcon {
    filter: brightness(1000%);
}
</style>
