var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.content.length
    ? _c("div", { attrs: { id: "slide" } }, [
        _c("img", { attrs: { src: _vm.logo, id: "logo" } }),
        _c("div", { attrs: { id: "langAndReadWrapper" } }, [
          _c(
            "div",
            { attrs: { id: "langChooserDiv" } },
            [
              _c(
                "v-menu",
                {
                  staticStyle: { "border-radius": "5px !important" },
                  attrs: {
                    "offset-y": "",
                    "nudge-left": "98",
                    "nudge-bottom": "5"
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function(ref) {
                          var on = ref.on
                          var attrs = ref.attrs
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                _vm._b(
                                  {
                                    staticStyle: {
                                      width: "45px",
                                      height: "45px",
                                      "border-radius": "8px",
                                      "box-shadow":
                                        "0 5px 10px rgba(0,0,0, 0.16)"
                                    },
                                    attrs: {
                                      id: "langBtn",
                                      color: "#F8F8F8",
                                      "x-small": ""
                                    }
                                  },
                                  "v-btn",
                                  attrs,
                                  false
                                ),
                                on
                              ),
                              [
                                _c("img", {
                                  staticStyle: {
                                    "max-width": "25px",
                                    "max-height": "25px"
                                  },
                                  attrs: { src: _vm.currentTTSLangImg }
                                })
                              ]
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    2315568518
                  )
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        width: "120px",
                        height: "25px",
                        display: "inline",
                        "border-radius": "0 !important",
                        "background-color": "#EAEAEA"
                      }
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          staticStyle: {
                            "background-color": "#EAEAEA",
                            "border-radius": "0 !important",
                            width: "49px",
                            height: "49px"
                          },
                          attrs: { text: "", "x-small": "" },
                          on: {
                            click: function($event) {
                              return _vm.changeLang("de", _vm.deFlagIcon)
                            }
                          }
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: _vm.deFlagIcon,
                              width: "24",
                              height: "24"
                            }
                          })
                        ]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticStyle: {
                            "background-color": "#EAEAEA",
                            "border-radius": "0 !important",
                            width: "49px",
                            height: "49px"
                          },
                          attrs: { text: "", "x-small": "" },
                          on: {
                            click: function($event) {
                              return _vm.changeLang("en", _vm.enFlagIcon)
                            }
                          }
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: _vm.enFlagIcon,
                              width: "24",
                              height: "24"
                            }
                          })
                        ]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticStyle: {
                            "background-color": "#EAEAEA",
                            "border-radius": "0 !important",
                            width: "49px",
                            height: "49px"
                          },
                          attrs: { text: "", "x-small": "" },
                          on: {
                            click: function($event) {
                              return _vm.changeLang("fr", _vm.frFlagIcon)
                            }
                          }
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: _vm.frFlagIcon,
                              width: "24",
                              height: "24"
                            }
                          })
                        ]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticStyle: {
                            "background-color": "#EAEAEA",
                            "border-radius": "0 !important",
                            width: "49px",
                            height: "49px"
                          },
                          attrs: { text: "", "x-small": "" },
                          on: {
                            click: function($event) {
                              return _vm.changeLang("tr", _vm.trFlagIcon)
                            }
                          }
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: _vm.trFlagIcon,
                              width: "24",
                              height: "24"
                            }
                          })
                        ]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticStyle: {
                            "background-color": "#EAEAEA",
                            "border-radius": "0 !important",
                            width: "49px",
                            height: "49px"
                          },
                          attrs: { text: "", "x-small": "" },
                          on: {
                            click: function($event) {
                              return _vm.changeLang("ar", _vm.arFlagIcon)
                            }
                          }
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: _vm.arFlagIcon,
                              width: "24",
                              height: "24"
                            }
                          })
                        ]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticStyle: {
                            "background-color": "#EAEAEA",
                            "border-radius": "0 !important",
                            width: "49px",
                            height: "49px"
                          },
                          attrs: { text: "", "x-small": "" },
                          on: {
                            click: function($event) {
                              return _vm.changeLang("ru", _vm.ruFlagIcon)
                            }
                          }
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: _vm.ruFlagIcon,
                              width: "24",
                              height: "24"
                            }
                          })
                        ]
                      )
                    ],
                    1
                  )
                ]
              )
            ],
            1
          ),
          _c("div", {
            ref: "readBtn",
            staticStyle: { display: "none" },
            on: { click: _vm.greet }
          }),
          _c(
            "div",
            { attrs: { id: "readDiv" }, on: { click: _vm.toggleReading } },
            [
              _c(
                "v-btn",
                {
                  staticClass: "mx-0",
                  staticStyle: {
                    width: "45px",
                    height: "45px",
                    "border-radius": "8px",
                    "box-shadow": "0 5px 10px rgba(0,0,0, 0.16)"
                  },
                  attrs: { color: "#F8F8F8", "x-small": "" }
                },
                [
                  _vm.readOutAllowed
                    ? _c("img", {
                        staticStyle: { height: "35px", opacity: "0.3" },
                        attrs: { src: _vm.lautsprecherAusIcon }
                      })
                    : _c("img", {
                        staticStyle: { height: "35px", opacity: "0.3" },
                        attrs: { src: _vm.lautsprecherIcon }
                      })
                ]
              ),
              _c("p", [_vm._v("Vorlesen")]),
              _c("img", {
                attrs: { src: _vm.musiknoteRot, id: "musiknoteRot" }
              }),
              _c("img", {
                attrs: { src: _vm.musiknoteGelb, id: "musiknoteGelb" }
              })
            ],
            1
          )
        ]),
        _c("div", { attrs: { id: "content" } }, [
          _c("img", {
            attrs: { id: "contentImg", src: _vm.content[_vm.index].img }
          }),
          _c("div", { attrs: { id: "contentHeaderAndText" } }, [
            _c(
              "div",
              { attrs: { id: "contentHeader" } },
              [
                _vm._v(" " + _vm._s(_vm.currentHeader) + " "),
                false
                  ? _c("v-overlay", {
                      staticStyle: { height: "150px" },
                      attrs: { color: "primary", absolute: "" }
                    })
                  : _vm._e(),
                _vm.showLoading
                  ? _c("v-progress-circular", {
                      attrs: {
                        color: "primary",
                        indeterminate: "",
                        id: "loadingTranslation"
                      }
                    })
                  : _vm._e()
              ],
              1
            ),
            _c("div", {
              attrs: { id: "contentText" },
              domProps: { innerHTML: _vm._s(_vm.currentText) }
            })
          ])
        ]),
        _c("div", { attrs: { id: "bottomBar" } }, [
          _c(
            "div",
            { attrs: { id: "navBar" } },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      staticStyle: { display: "flex" },
                      attrs: { md: "2", sm: "3", cols: "5" }
                    },
                    [
                      _c("div", { staticStyle: { height: "40px" } }),
                      _vm.index > 0
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "navBtn",
                              staticStyle: { opacity: "0.5" },
                              attrs: { text: "", color: "#6f6f6f", large: "" },
                              on: {
                                click: function($event) {
                                  _vm.index--
                                }
                              }
                            },
                            [
                              _c("img", { attrs: { src: _vm.links } }),
                              _vm._v(" Zurück ")
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticStyle: { "text-align": "center", color: "#707070" }
                    },
                    [
                      _vm.$vuetify.breakpoint.width < 700
                        ? _c("div", { attrs: { id: "navCounter" } }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.index + 1) +
                                "/" +
                                _vm._s(_vm.content.length) +
                                " "
                            )
                          ])
                        : _c(
                            "div",
                            {
                              staticStyle: {
                                display: "inline-flex",
                                margin: "auto"
                              }
                            },
                            _vm._l(_vm.content, function(el, elIndex) {
                              return _c("div", {
                                key: JSON.stringify(el),
                                staticClass: "dot",
                                attrs: { active: elIndex <= _vm.index }
                              })
                            }),
                            0
                          )
                    ]
                  ),
                  _c(
                    "v-col",
                    {
                      staticStyle: { display: "flex" },
                      attrs: { md: "2", sm: "3", cols: "5" }
                    },
                    [
                      _vm.index + 1 !== _vm.content.length
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "navBtn",
                              style: _vm.moveAlong
                                ? "filter: invert(0) sepia(1) saturate(2) hue-rotate(170deg)"
                                : "",
                              attrs: {
                                disabled: !_vm.moveAlong,
                                text: "",
                                color: "#6f6f6f",
                                large: ""
                              },
                              on: {
                                click: function($event) {
                                  _vm.index++
                                }
                              }
                            },
                            [
                              _vm._v(" Weiter "),
                              _c("img", {
                                style: _vm.moveAlong ? "" : "opacity: 0.5",
                                attrs: { src: _vm.rechts }
                              })
                            ]
                          )
                        : _c(
                            "v-btn",
                            {
                              staticClass: "navBtn",
                              style: _vm.moveAlong
                                ? "filter: invert(0) sepia(1) saturate(2) hue-rotate(170deg)"
                                : "",
                              attrs: {
                                disabled: !_vm.moveAlong,
                                text: "",
                                color: "#6f6f6f",
                                large: ""
                              },
                              on: { click: _vm.setTutorialDone }
                            },
                            [
                              _vm._v(" Beenden "),
                              _c("img", {
                                style: _vm.moveAlong ? "" : "opacity: 0.5",
                                attrs: { src: _vm.rechts }
                              })
                            ]
                          )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { attrs: { id: "ProgressBarWrapper" } },
            [
              false
                ? _c("v-progress-linear", {
                    staticClass: "progressbar",
                    attrs: {
                      color: "#BDBDBD",
                      rounded: "",
                      "background-color": "rgba(112,112,112,15%)",
                      height: "8"
                    },
                    model: {
                      value: _vm.progressBar,
                      callback: function($$v) {
                        _vm.progressBar = $$v
                      },
                      expression: "progressBar"
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }