import {
  request,
  getRequest,
  patchRequest,
  postRequest,
  deleteRequest,
  filePostRequest,
  fileGetRequest,
  createFileXMLHttpRequest,
} from "./request";

// {"accountName":"m-vr8-xh3-brd","password":"m-bpp-ydc-xu4"}
const loginAccount = async (body) => postRequest('/account/login', body);
const getCurrentAccount = async () => getRequest('/account/get');
const getCurrentMatrixUser = async () => getRequest('/matrix/user/me');
const postVoiceMessage = ( file) => createFileXMLHttpRequest(`/matrix/voice`, file); // Returning XMLHttpRequest object. You've to call ".send(data)".
const deleteVoiceMessage = async (fileId) => deleteRequest(`/matrix/voice/${fileId}`);
const getVoiceMessage = async ( fileId) => getRequest(`/matrix/voice/${fileId}`);
const postPrivateRoom = async (body) => postRequest('/matrix/room/private', body);

const postNameAvailable = async (body) => postRequest('/account/available', body);

const getTimeslots = async () => getRequest('/timeslot');
const postTimeslot = async (body) => postRequest('/timeslot', body);
const patchTimeslot = async (timeslotId, body) => patchRequest(`/timeslot/${timeslotId}`, body);
const deleteTimeslot = async (timeslotId) => deleteRequest(`/timeslot/${timeslotId}`);

const getGroups = async () => getRequest('/group');
const getGroup = async (groupId) => getRequest(`/group/${groupId}`);
const getGroupByAppointment = async (appointmentId) => getRequest(`/groupByAppointment/${appointmentId}`);
const postGroup = async (body) => postRequest('/group', body);
const patchGroup = async (groupId, body) => patchRequest(`/group/${groupId}`, body);
const deleteGroup = async (groupId) => deleteRequest(`/group/${groupId}`);

const getGroupAppointment = async (appointmentId) => getRequest(`/groupByAppointment/${appointmentId}`);

const getMaintainers = async () => getRequest('/maintainer');
const getMaintainer = async (maintainerId) => getRequest(`/maintainer/${maintainerId}`);
const postMaintainer = async (body) => postRequest('/maintainer', body);
const patchMaintainer = async (maintainerId, body) => patchRequest(`/maintainer/${maintainerId}`, body);
const deleteMaintainer = async (maintainerId) => deleteRequest(`/maintainer/${maintainerId}`);

const getPupils = async () => getRequest('/pupil');
const getPupil = async (pupilId) => getRequest(`/pupil/${pupilId}`);
const getMePupil = async () => getRequest(`/pupil/me`);
const postPupil = async (body) => postRequest('/pupil', body);
const patchPupil = async (pupilId, body) => patchRequest(`/pupil/${pupilId}`, body);
const deletePupil = async (pupilId) => deleteRequest(`/pupil/${pupilId}`);

const getSchoolSubjects = async () => getRequest('/schoolsubject');
const getSchoolSubject = async (schoolSubjectId) => getRequest(`/schoolsubject/${schoolSubjectId}`);
const postSchoolSubject = async (body) => postRequest('/schoolsubject', body);
const patchSchoolSubject = async (schoolSubjectId, body) => patchRequest(`/schoolsubject/${schoolSubjectId}`, body);
const deleteSchoolSubject = async (schoolSubjectId) => deleteRequest(`/schoolsubject/${schoolSubjectId}`);

const getSchoolSubjectAppointments = async () => getRequest('/schoolsubjectappointment');
const postSchoolSubjectAppointment = async (body) => postRequest('/schoolsubjectappointment', body);
const patchSchoolSubjectAppointment = async (schoolSubjectAppointmentId, body) => patchRequest(`/schoolsubjectappointment/${schoolSubjectAppointmentId}`, body);
const deleteSchoolSubjectAppointment = async (schoolSubjectAppointmentId) => deleteRequest(`/schoolsubjectappointment/${schoolSubjectAppointmentId}`);
const deleteSchoolSubjectAppointmentRule = async (ruleId) => deleteRequest(`/schoolsubjectappointment/rule/${ruleId}`);
const deleteSchoolSubjectAppointmentFromRule = async (appointmentId, ruleId) => deleteRequest(`/schoolsubjectappointment/${appointmentId}/rule/${ruleId}`);
const joinSchoolSubjectAppointmentBbbMeetingUrl = async (schoolSubjectAppointmentId) => getRequest(`/schoolsubjectappointment/${schoolSubjectAppointmentId}/joinBbbMeeting`);
const joinTeachersRoomBbbMeetingUrl = async () => getRequest(`/teacher/bbb/room`);
const postTeacherUpload = (appointmentId, file) => createFileXMLHttpRequest(`/schoolSubjectAppointment/${appointmentId}/teacherUploads`, file); // Returning XMLHttpRequest object. You've to call ".send(data)".
const postTeacherUploadEdit = async (appointmentId, fileId, file) => filePostRequest(`/schoolSubjectAppointment/${appointmentId}/teacherUploads/${fileId}/edit`, file);
const getTeacherUpload = async (appointmentId, fileId) => getRequest(`/schoolSubjectAppointment/${appointmentId}/teacherUploads/${fileId}`);
const deleteTeacherUpload = async (fileId) => deleteRequest(`/schoolSubjectAppointment/upload/${fileId}`);
const postPupilUpload = (appointmentId, file) => createFileXMLHttpRequest(`/schoolSubjectAppointment/${appointmentId}/pupilUploads`, file);
const postPupilUploadEdit = async (appointmentId, fileId, file) => filePostRequest(`/schoolSubjectAppointment/${appointmentId}/pupilUploads/${fileId}/edit`, file);
const getPupilUpload = async (appointmentId, fileId) => getRequest(`/schoolSubjectAppointment/${appointmentId}/pupilUploads/${fileId}`);

const getTeachers = async () => getRequest('/teacher');
const getTeacher = async (teacherId) => getRequest(`/teacher/${teacherId}`);
const getProfilePicture = async (teacherId) => getRequest(`/teacher/${teacherId}/profilePicture`);
const getMeTeacher = async () => getRequest(`/teacher/me`);
const postTeacher = async (body) => postRequest('/teacher', body);
const postTeacherPicture = (teacherId, file) => createFileXMLHttpRequest(`/teacher/${teacherId}/profilePicture`, file);
const patchTeacher = async (teacherId, body) => patchRequest(`/teacher/${teacherId}`, body);
const deleteTeacher = async (teacherId) => deleteRequest(`/teacher/${teacherId}`);

const patchAutoSave = async (appointmentId, uploadId, body) => patchRequest(`/schoolSubjectAppointment/${appointmentId}/autoSave/${uploadId}`, body);
const getAutoSave = async (appointmentId, uploadId) => getRequest(`/schoolSubjectAppointment/${appointmentId}/uploads/${uploadId}`);

const getBlackboard = async () => getRequest('/blackboard');
const postBlackboard = async (body) => postRequest('/blackboard', body);
const patchBlackboard = async (blackboardId, body) => patchRequest(`/blackboard/${blackboardId}`, body);
const deleteBlackboard = async (blackboardId) => deleteRequest(`/blackboard/${blackboardId}`);

const postPrivateUpload = ( file) => createFileXMLHttpRequest(`/privateFolder`, file); // Returning XMLHttpRequest object. You've to call ".send(data)".
const getPrivateUploads = async () => getRequest('/privatefolder');
const getPrivateUpload = async ( fileId) => getRequest(`/privatefolder/${fileId}`);
const deletePrivateUpload = async (fileId) => deleteRequest(`/privatefolder/${fileId}`);

const postSubjectFolderUpload = (subjectId, file) => createFileXMLHttpRequest(`/subjectFolder/${subjectId}`, file); // Returning XMLHttpRequest object. You've to call ".send(data)".
const getSubjectFolderUploads = async (subjectId, teacherId) => getRequest(`/subjectFolder/${subjectId}/${teacherId}`);
const getSubjectFolderUpload = async (fileId) => getRequest(`/subjectFolder/${fileId}`);
const deleteSubjectFolderUpload = async (subjectId, teacherId, fileId) => deleteRequest(`/subjectFolder/${subjectId}/${teacherId}/${fileId}`);

const getServerTime = async (body) => getRequest('/time', body);
const getVersionNumber = async () => getRequest('/version');

export {
  loginAccount,
  getCurrentAccount,
  getCurrentMatrixUser,
  postVoiceMessage,
  getVoiceMessage,
  deleteVoiceMessage,
  postPrivateRoom,

  postNameAvailable,

  getGroups,
  getGroup,
  getGroupByAppointment,
  postGroup,
  patchGroup,
  deleteGroup,

  getGroupAppointment,

  getMaintainers,
  getMaintainer,
  postMaintainer,
  patchMaintainer,
  deleteMaintainer,

  getPupils,
  getPupil,
  getMePupil,
  postPupil,
  patchPupil,
  deletePupil,

  getSchoolSubjects,
  getSchoolSubject,
  postSchoolSubject,
  patchSchoolSubject,
  deleteSchoolSubject,

  getSchoolSubjectAppointments,
  postSchoolSubjectAppointment,
  patchSchoolSubjectAppointment,
  deleteSchoolSubjectAppointment,
  deleteSchoolSubjectAppointmentRule,
  deleteSchoolSubjectAppointmentFromRule,
  joinSchoolSubjectAppointmentBbbMeetingUrl,
  postTeacherUpload,
  postTeacherUploadEdit,
  getTeacherUpload,
  deleteTeacherUpload,
  postPupilUpload,
  postPupilUploadEdit,
  getPupilUpload,

  joinTeachersRoomBbbMeetingUrl,

  getTeachers,
  getTeacher,
  getProfilePicture,
  getMeTeacher,
  postTeacher,
  postTeacherPicture,
  patchTeacher,
  deleteTeacher,

  getTimeslots,
  postTimeslot,
  patchTimeslot,
  deleteTimeslot,

  getBlackboard,
  postBlackboard,
  patchBlackboard,
  deleteBlackboard,

  patchAutoSave,
  getAutoSave,

  postPrivateUpload,
  getPrivateUploads,
  getPrivateUpload,
  deletePrivateUpload,

  postSubjectFolderUpload,
  getSubjectFolderUploads,
  getSubjectFolderUpload,
  deleteSubjectFolderUpload,

  getServerTime,
  getVersionNumber,
};
