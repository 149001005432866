import { render, staticRenderFns } from "./Plan.vue?vue&type=template&id=17b36582&scoped=true&"
import script from "./Plan.vue?vue&type=script&lang=js&"
export * from "./Plan.vue?vue&type=script&lang=js&"
import style0 from "./Plan.vue?vue&type=style&index=0&id=17b36582&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "17b36582",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VAutocomplete,VBtn,VCol,VOverlay,VProgressCircular,VRow})


/* hot reload */
if (module.hot) {
  var api = require("/home/peternauroth/homeschooling/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('17b36582')) {
      api.createRecord('17b36582', component.options)
    } else {
      api.reload('17b36582', component.options)
    }
    module.hot.accept("./Plan.vue?vue&type=template&id=17b36582&scoped=true&", function () {
      api.rerender('17b36582', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Verwaltung/Plan.vue"
export default component.exports