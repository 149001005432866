var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: {
        position: "absolute",
        top: "0",
        bottom: "0",
        left: "0",
        right: "0",
        "background-color": "#f8f8f8"
      }
    },
    [
      false
        ? _c(
            "v-btn",
            {
              staticClass: "mt-5 mr-5 helpBtn questionmarkIcon",
              attrs: { text: "", fixed: "", right: "", top: "" },
              on: {
                click: function($event) {
                  return _vm.showLoginTutorial()
                }
              }
            },
            [
              _c("v-img", {
                staticClass: "questionmarkIcon",
                attrs: { src: _vm.questionmark, contain: "" }
              })
            ],
            1
          )
        : _vm._e(),
      _c("div", { staticClass: "schoolBoardBackgroundWrapper" }, [
        _c("img", {
          staticClass: "schoolBoardBackground",
          attrs: { src: _vm.tafel }
        })
      ]),
      _c("div", { staticClass: "loginFormWrapper" }, [
        _c(
          "div",
          { staticClass: "loginForm positionLoginForm" },
          [
            _c(
              "div",
              [
                _c("v-img", {
                  staticClass: "center mb-4",
                  staticStyle: { width: "80%" },
                  attrs: { src: _vm.logo }
                })
              ],
              1
            ),
            _c("div", { staticClass: "loginFormInputs" }, [
              _c(
                "div",
                { staticClass: "loginFormRow" },
                [
                  _c("v-text-field", {
                    staticClass: "font-weight-bold",
                    attrs: {
                      label: "Name",
                      outlined: "",
                      light: "",
                      "hide-details": "",
                      color: "#707070 !important"
                    },
                    on: {
                      keydown: function(keyEvent) {
                        if (keyEvent.key === "Enter") {
                          _vm.clickLoginUser()
                        }
                      }
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "append-outer",
                        fn: function() {
                          return [
                            _c("img", {
                              staticStyle: { display: "block", height: "30px" },
                              attrs: { src: _vm.headIcon }
                            })
                          ]
                        },
                        proxy: true
                      }
                    ]),
                    model: {
                      value: _vm.userName,
                      callback: function($$v) {
                        _vm.userName = $$v
                      },
                      expression: "userName"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "loginFormRow" },
                [
                  _c("v-text-field", {
                    staticClass: "font-weight-bold",
                    attrs: {
                      "append-icon": _vm.show1 ? "mdi-eye" : "mdi-eye-off",
                      type: _vm.show1 ? "text" : "password",
                      label: "Passwort",
                      outlined: "",
                      light: "",
                      "hide-details": "",
                      color: "#707070 !important"
                    },
                    on: {
                      "click:append": function($event) {
                        _vm.show1 = !_vm.show1
                      },
                      keydown: function(keyEvent) {
                        if (keyEvent.key === "Enter") {
                          _vm.clickLoginUser()
                        }
                      }
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "append-outer",
                        fn: function() {
                          return [
                            _c("img", {
                              staticStyle: { height: "30px" },
                              attrs: { src: _vm.loginIcon }
                            })
                          ]
                        },
                        proxy: true
                      }
                    ]),
                    model: {
                      value: _vm.password,
                      callback: function($$v) {
                        _vm.password = $$v
                      },
                      expression: "password"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "loginFormRow stayLoggedIn mb-0",
                  staticStyle: { "justify-content": "center" }
                },
                [
                  _c("v-checkbox", {
                    staticClass: "mt-0 pt-0",
                    attrs: { label: "Angemeldet bleiben" },
                    model: {
                      value: _vm.stayLoggedIn,
                      callback: function($$v) {
                        _vm.stayLoggedIn = $$v
                      },
                      expression: "stayLoggedIn"
                    }
                  })
                ],
                1
              )
            ]),
            _c(
              "v-row",
              { staticClass: "loginBtnWrapper" },
              [
                _c(
                  "v-col",
                  { staticClass: "pa-0" },
                  [
                    _c(
                      "v-dialog",
                      {
                        attrs: {
                          fullscreen: "",
                          "hide-overlay": "",
                          transition: "dialog-bottom-transition"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        staticClass: "text-capitalize loginBtn",
                                        attrs: {
                                          large: "",
                                          outlined: "",
                                          id: "qrLoginButton"
                                        }
                                      },
                                      "v-btn",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [
                                    _c("img", {
                                      staticClass: "mr-2 icon",
                                      attrs: { src: _vm.qrLoginIcon }
                                    }),
                                    _vm._v(" QR-Code Login ")
                                  ]
                                )
                              ]
                            }
                          }
                        ]),
                        model: {
                          value: _vm.qrcodeDialog,
                          callback: function($$v) {
                            _vm.qrcodeDialog = $$v
                          },
                          expression: "qrcodeDialog"
                        }
                      },
                      [
                        _c(
                          "v-card",
                          [
                            _c("q-r-scan-login", {
                              attrs: {
                                "code-scan-active": _vm.qrcodeDialog,
                                "return-function": _vm.qrLoginUser,
                                "close-function": function() {
                                  _vm.qrcodeDialog = false
                                }
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-col",
                  { staticClass: "pa-0" },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "loginBtn text-capitalize",
                        attrs: {
                          outlined: "",
                          large: "",
                          id: "regularLoginBtn"
                        },
                        on: { click: _vm.clickLoginUser }
                      },
                      [
                        _c("img", {
                          staticClass: "mr-2 icon",
                          attrs: { src: _vm.beitretenIcon }
                        }),
                        _vm._v(" Login ")
                      ]
                    )
                  ],
                  1
                ),
                _c("v-col", {
                  staticClass: "pa-0",
                  attrs: { cols: _vm.$vuetify.breakpoint.width > 500 ? 1 : 0 }
                })
              ],
              1
            )
          ],
          1
        )
      ]),
      _c(
        "v-snackbar",
        {
          attrs: { bottom: "", color: "error", timeout: "5000" },
          model: {
            value: _vm.snackbars.credentialsNotFound,
            callback: function($$v) {
              _vm.$set(_vm.snackbars, "credentialsNotFound", $$v)
            },
            expression: "snackbars.credentialsNotFound"
          }
        },
        [_vm._v(" Kein Nutzer mit diesen Daten gefunden! ")]
      ),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: "5000", color: "error" },
          model: {
            value: _vm.snackbars.backendNotReachable,
            callback: function($$v) {
              _vm.$set(_vm.snackbars, "backendNotReachable", $$v)
            },
            expression: "snackbars.backendNotReachable"
          }
        },
        [
          _vm._v(
            " Die Server sind gerade nicht erreichbar. Versuche es später nochmal. "
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }