var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-row", { staticClass: "mx-0" }, [
        _c(
          "div",
          { staticClass: "chat-container whiteBg" },
          [
            _c(
              "div",
              {
                staticStyle: {
                  "background-color": "#8cbd46",
                  "border-radius": "15px 15px 0 0",
                  "padding-top": "10px",
                  "padding-bottom": "10px",
                  "text-align": "left",
                  color: "white"
                }
              },
              [
                _c(
                  "v-row",
                  {
                    staticClass: "pr-2 ml-2",
                    staticStyle: {
                      width: "90%",
                      display: "flex",
                      "align-items": "center",
                      height: "35px"
                    }
                  },
                  [
                    _c(
                      "v-col",
                      {
                        staticClass: "pa-0 pl-2 text-left d-flex align-center",
                        staticStyle: { "font-size": "larger" },
                        attrs: { cols: "9", md: "8", lg: "9" }
                      },
                      [
                        _c("img", {
                          staticClass: "iconToWhite mr-1",
                          staticStyle: { height: "20px" },
                          attrs: { src: _vm.chatIcon }
                        }),
                        _vm._v(" Chat ")
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            ),
            !_vm.viewingRoom
              ? _c("ChatWidgetGroupList", {
                  attrs: { rooms: _vm.rooms, matrixClient: _vm.matrixClient },
                  on: {
                    roomSelected: _vm.handleRoomSelected,
                    setRoomList: _vm.setRoomList
                  }
                })
              : _c("ChatWidgetGroupChat", {
                  attrs: {
                    room: _vm.viewingRoom,
                    matrixClient: _vm.matrixClient
                  },
                  on: {
                    exitRoom: _vm.handleExitRoom,
                    sendMessage: _vm.sendMessage,
                    deleteMessage: _vm.deleteMessage
                  }
                })
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }