import axios from 'axios';


const actions = {
// # Axios ###################
  initAxiosInstance: ({commit, state}) => {
    try {
      const instance = axios.create({
        baseURL: state.serverAddress,
        withCredentials: true,
      });

      instance.interceptors.request.use(async (config) => {
        const result = config;
        return result;
      });

      commit('SET_AXIOS_INSTANCE', instance);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('initAxiosInstance', error);
    }
  },
  // #################### Axios #
};

export default actions;
