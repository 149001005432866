import { render, staticRenderFns } from "./PlanWidget.vue?vue&type=template&id=9fc4d06a&scoped=true&"
import script from "./PlanWidget.vue?vue&type=script&lang=js&"
export * from "./PlanWidget.vue?vue&type=script&lang=js&"
import style0 from "./PlanWidget.vue?vue&type=style&index=0&id=9fc4d06a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9fc4d06a",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBadge,VBtn,VCol,VDialog,VRow})


/* hot reload */
if (module.hot) {
  var api = require("/home/peternauroth/homeschooling/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('9fc4d06a')) {
      api.createRecord('9fc4d06a', component.options)
    } else {
      api.reload('9fc4d06a', component.options)
    }
    module.hot.accept("./PlanWidget.vue?vue&type=template&id=9fc4d06a&scoped=true&", function () {
      api.rerender('9fc4d06a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Schueler/PlanWidget.vue"
export default component.exports