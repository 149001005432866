var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticStyle: { height: "100vh" } },
        [
          _c("v-row", { staticClass: "mx-0", staticStyle: { height: "95%" } }, [
            _c(
              "div",
              {
                staticStyle: {
                  height: "auto",
                  width: "90%",
                  "min-height": "80vh",
                  "border-radius": "15px",
                  "box-shadow": "1px 5px 5px silver",
                  margin: "auto auto 5em",
                  "margin-top": "12px"
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "px-1",
                    staticStyle: {
                      display: "inline-flex",
                      width: "100%",
                      "background-color": "#707070",
                      "border-radius": "15px 15px 0 0",
                      "font-size": "larger",
                      "padding-top": "20px",
                      "padding-bottom": "20px",
                      color: "white"
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "mt-1",
                        staticStyle: { "margin-left": "3%" }
                      },
                      [
                        _c("img", {
                          staticClass: "mx-2 boardIcon",
                          attrs: {
                            src: _vm.brettIcon,
                            alt: "Schwarzes Brett-Icon"
                          }
                        }),
                        _c(
                          "p",
                          {
                            staticClass: "ma-0",
                            staticStyle: { display: "inline-block" }
                          },
                          [_vm._v("Schwarzes Brett")]
                        )
                      ]
                    ),
                    _c("v-spacer"),
                    _c(
                      "v-btn",
                      {
                        staticClass:
                          "RightTitleText d-flex justify-end align-center mr-2",
                        staticStyle: {
                          "background-color": "#f8f8f880",
                          width: "35px",
                          height: "35px"
                        },
                        attrs: { "x-small": "" },
                        on: {
                          click: function() {
                            _vm.eintragPopup = true
                            _vm.groupBlackboard = _vm.groups[0]
                            _vm.brettEintrag.groupId = _vm.groups[0]._id
                          }
                        }
                      },
                      [
                        _c("img", {
                          staticClass: "boardIcon",
                          attrs: { src: _vm.plusIcon, alt: "Plus-Icon" }
                        })
                      ]
                    )
                  ],
                  1
                ),
                _c(
                  "v-overlay",
                  { attrs: { absolute: "", value: _vm.showLoadingOverlay } },
                  [
                    _vm.showLoadingOverlay
                      ? _c("v-progress-circular", {
                          attrs: { indeterminate: "", size: "100" }
                        })
                      : _vm._e()
                  ],
                  1
                ),
                _c(
                  "vue-custom-scrollbar",
                  {
                    staticClass: "scroll-area",
                    attrs: { settings: _vm.settings }
                  },
                  [
                    _c(
                      "div",
                      [
                        _vm._l(_vm.blackboard, function(item, index) {
                          return _c(
                            "v-card",
                            { key: index, staticClass: "ma-2" },
                            [
                              _c(
                                "v-card-title",
                                {
                                  staticClass: "pa-1",
                                  staticStyle: {
                                    color: "#707070",
                                    "background-color": "#EAEAEA",
                                    "max-width": "100%"
                                  }
                                },
                                [
                                  _c("img", {
                                    staticClass: "mr-2 boardIconEntries",
                                    style: { "background-color": item.color },
                                    attrs: {
                                      src: _vm.chooseIcon(item.icon),
                                      alt: "Icon"
                                    }
                                  }),
                                  _vm._v(" Verwaltung "),
                                  _c("v-spacer"),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "RightTitleText d-flex justify-end align-center"
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "mr-2 timeAndDate" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: { height: "1.25em" }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.dayname[
                                                    new Date(
                                                      item.createdOn
                                                    ).getDay()
                                                  ] +
                                                    ". " +
                                                    new Date(
                                                      item.createdOn
                                                    ).toLocaleDateString([], {
                                                      day: "2-digit",
                                                      month: "2-digit",
                                                      year: "2-digit"
                                                    })
                                                )
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticStyle: { height: "1.25em" }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  new Date(
                                                    item.createdOn
                                                  ).toLocaleTimeString([], {
                                                    hour: "2-digit",
                                                    minute: "2-digit"
                                                  })
                                                ) + " Uhr"
                                              )
                                            ]
                                          )
                                        ]
                                      ),
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "pa-0",
                                          staticStyle: {
                                            "background-color": "#f8f8f880",
                                            height: "35px",
                                            width: "35px"
                                          },
                                          attrs: { "x-small": "" },
                                          on: {
                                            click: function() {
                                              _vm.bearbeiten = true
                                              _vm.eintragPopup = true
                                              _vm.setBrettPopup(index)
                                            }
                                          }
                                        },
                                        [
                                          _c("img", {
                                            staticClass: "boardIconEntries",
                                            attrs: {
                                              src: _vm.bearbeitenIcon,
                                              alt: "Pin-Icon"
                                            }
                                          })
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c("v-card-text", { staticClass: "pa-2" }, [
                                _c(
                                  "div",
                                  { staticClass: "blackboardVCardText" },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.blackboard[index].message)
                                    )
                                  ]
                                )
                              ])
                            ],
                            1
                          )
                        }),
                        !_vm.blackboard.length
                          ? _c(
                              "v-card",
                              { attrs: { elevation: "0" } },
                              [
                                _c(
                                  "v-card-text",
                                  { staticStyle: { "text-align": "center" } },
                                  [
                                    _vm._v(
                                      "Aktuell ist das Schwarze Brett leer..."
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      2
                    )
                  ]
                )
              ],
              1
            )
          ])
        ],
        1
      ),
      _c(
        "v-overlay",
        {
          staticStyle: { "padding-top": "50px", height: "100vh" },
          attrs: { opacity: 0.5, color: "white", value: _vm.eintragPopup }
        },
        [
          _c(
            "v-row",
            {
              staticClass: "ma-0",
              staticStyle: {
                width: "95%",
                "padding-left": "calc(5% + 56px)",
                "padding-top": "5%",
                "padding-bottom": "5%",
                "max-height": "80% !important"
              }
            },
            [
              _c("v-card", { attrs: { id: "blackboardCardContainer" } }, [
                _c(
                  "div",
                  {
                    staticClass: "px-1 text-uppercase align-start",
                    staticStyle: {
                      display: "inline-flex",
                      width: "100%",
                      "background-color": "#707070",
                      "border-radius": "15px 15px 0 0",
                      "font-size": "larger",
                      "padding-top": "20px",
                      "padding-bottom": "20px",
                      color: "white"
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "mt-3",
                        staticStyle: { "margin-left": "4%" }
                      },
                      [
                        _c("img", {
                          staticClass: "mx-2 boardIcon",
                          attrs: {
                            src: _vm.brettIcon,
                            alt: "Schwarzes Brett-Icon"
                          }
                        }),
                        _vm.bearbeiten
                          ? _c(
                              "p",
                              {
                                staticClass: "ma-0",
                                staticStyle: { display: "inline-block" }
                              },
                              [_vm._v("Eintrag bearbeiten")]
                            )
                          : _c(
                              "p",
                              {
                                staticClass: "ma-0",
                                staticStyle: { display: "inline-block" }
                              },
                              [_vm._v("Neuer Eintrag")]
                            )
                      ]
                    ),
                    _c("v-spacer"),
                    _c(
                      "v-btn",
                      {
                        staticClass:
                          "RightTitleText d-flex justify-end align-center mr-2",
                        staticStyle: {
                          "background-color": "#f8f8f880",
                          width: "35px",
                          height: "35px"
                        },
                        attrs: { "x-small": "" },
                        on: {
                          click: function() {
                            _vm.eintragPopup = false
                            _vm.bearbeiten = false
                            _vm.resetPopup()
                          }
                        }
                      },
                      [
                        _c("img", {
                          staticClass: "boardIcon pl-1",
                          attrs: {
                            src: _vm.schliessenIcon,
                            alt: "schliessen-Icon"
                          }
                        })
                      ]
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "12" } },
                          [
                            _c(
                              "vue-custom-scrollbar",
                              {
                                staticClass: "scroll-area mb-2",
                                attrs: { settings: _vm.settings }
                              },
                              [
                                _c(
                                  "v-card",
                                  {
                                    staticClass: "ma-2",
                                    staticStyle: { "border-radius": "15px" },
                                    attrs: { light: "" }
                                  },
                                  [
                                    _c(
                                      "v-card-title",
                                      {
                                        staticStyle: {
                                          color: "#707070",
                                          "background-color": "#EAEAEA",
                                          "padding-top": "10px",
                                          "padding-bottom": "10px"
                                        }
                                      },
                                      [
                                        _c("img", {
                                          staticClass: "mx-2 boardIconEntries",
                                          style: {
                                            "background-color":
                                              _vm.brettEintrag.color
                                          },
                                          attrs: {
                                            src: _vm.chooseIcon(
                                              _vm.brettEintrag.icon
                                            ),
                                            alt: "Icon"
                                          }
                                        }),
                                        _vm._v(" Verwaltung ")
                                      ]
                                    ),
                                    _c(
                                      "v-card-text",
                                      { staticClass: "pa-2" },
                                      [
                                        _c(
                                          "v-row",
                                          { staticStyle: { width: "100%" } },
                                          [
                                            _c(
                                              "v-col",
                                              {
                                                staticClass: "py-0",
                                                attrs: { cols: "12", md: "8" }
                                              },
                                              [
                                                _c("v-textarea", {
                                                  staticClass: "pa-0 ma-0",
                                                  attrs: {
                                                    rows: "12",
                                                    "no-resize": "",
                                                    "hide-details": "",
                                                    autofocus: ""
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.brettEintrag.message,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.brettEintrag,
                                                        "message",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "brettEintrag.message"
                                                  }
                                                }),
                                                _c(
                                                  "v-row",
                                                  [
                                                    _c(
                                                      "v-col",
                                                      {
                                                        staticClass: "pr-0",
                                                        attrs: { cols: "11" }
                                                      },
                                                      [_c("div")]
                                                    ),
                                                    _c(
                                                      "v-col",
                                                      {
                                                        staticClass: "pl-0",
                                                        attrs: { cols: "1" }
                                                      },
                                                      [
                                                        _vm.bearbeiten
                                                          ? _c(
                                                              "v-btn",
                                                              {
                                                                staticClass:
                                                                  "mr-6",
                                                                staticStyle: {
                                                                  "background-color":
                                                                    "#8CBD46",
                                                                  width: "48px",
                                                                  height: "48px"
                                                                },
                                                                attrs: {
                                                                  "x-small": ""
                                                                },
                                                                on: {
                                                                  click: function() {
                                                                    _vm.eintragBearbeiten()
                                                                    _vm.requestBlackboard()
                                                                    _vm.resetPopup()
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                _c("img", {
                                                                  staticStyle: {
                                                                    height:
                                                                      "40px",
                                                                    color:
                                                                      "white!important"
                                                                  },
                                                                  attrs: {
                                                                    src:
                                                                      _vm.sendenIcon,
                                                                    alt:
                                                                      "senden-Icon"
                                                                  }
                                                                })
                                                              ]
                                                            )
                                                          : _c(
                                                              "v-btn",
                                                              {
                                                                staticClass:
                                                                  "mr-4 justify-center align-center",
                                                                staticStyle: {
                                                                  "background-color":
                                                                    "#8CBD46",
                                                                  width: "35px",
                                                                  height: "35px"
                                                                },
                                                                attrs: {
                                                                  "x-small": ""
                                                                },
                                                                on: {
                                                                  click: function() {
                                                                    _vm.eintragErstellen()
                                                                    _vm.requestBlackboard()
                                                                    _vm.resetPopup()
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                _c("img", {
                                                                  staticClass:
                                                                    "boardIcon",
                                                                  staticStyle: {
                                                                    height:
                                                                      "20px",
                                                                    color:
                                                                      "white!important"
                                                                  },
                                                                  attrs: {
                                                                    src:
                                                                      _vm.sendenIcon,
                                                                    alt:
                                                                      "senden-Icon"
                                                                  }
                                                                })
                                                              ]
                                                            )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-col",
                                              {
                                                attrs: { cols: "12", md: "4" }
                                              },
                                              [
                                                _c(
                                                  "v-row",
                                                  [
                                                    _c(
                                                      "v-col",
                                                      {
                                                        staticClass: "pb-0",
                                                        staticStyle: {
                                                          "font-size": "medium"
                                                        },
                                                        attrs: {
                                                          cols: "12",
                                                          md: "12"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          " Gruppenauswahl: "
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "v-col",
                                                      {
                                                        attrs: {
                                                          cols: "12",
                                                          md: "12"
                                                        }
                                                      },
                                                      [
                                                        _c("v-autocomplete", {
                                                          staticClass:
                                                            "ma-0 pa-0",
                                                          attrs: {
                                                            label: _vm.toString(
                                                              _vm.groupBlackboard
                                                            ),
                                                            "single-line": "",
                                                            "hide-details": "",
                                                            attach: true,
                                                            "item-text": "name",
                                                            items: _vm.groups,
                                                            filter:
                                                              _vm.customFilter,
                                                            "no-data-text":
                                                              "Keine passende Gruppe"
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.groupBlackboard,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.groupBlackboard = $$v
                                                            },
                                                            expression:
                                                              "groupBlackboard"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-col",
                                                      {
                                                        staticClass: "py-0",
                                                        staticStyle: {
                                                          "font-size": "medium"
                                                        },
                                                        attrs: {
                                                          cols: "12",
                                                          md: "12"
                                                        }
                                                      },
                                                      [_vm._v(" Farbauswahl: ")]
                                                    ),
                                                    _c(
                                                      "v-col",
                                                      {
                                                        attrs: {
                                                          cols: "12",
                                                          md: "12"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "verwaltung-color-picker",
                                                          {
                                                            attrs: {
                                                              callback: function(
                                                                result
                                                              ) {
                                                                _vm.brettEintrag.color = result
                                                              },
                                                              "current-color":
                                                                _vm.brettEintrag
                                                                  .color
                                                            }
                                                          }
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-col",
                                                      {
                                                        staticClass: "pb-0",
                                                        staticStyle: {
                                                          "font-size": "medium"
                                                        },
                                                        attrs: {
                                                          cols: "12",
                                                          md: "12"
                                                        }
                                                      },
                                                      [_vm._v(" Iconauswahl: ")]
                                                    ),
                                                    _c(
                                                      "v-col",
                                                      {
                                                        attrs: {
                                                          cols: "12",
                                                          md: "12"
                                                        }
                                                      },
                                                      [
                                                        _c("img", {
                                                          staticClass:
                                                            "iconSelect",
                                                          attrs: {
                                                            selected:
                                                              _vm.brettEintrag
                                                                .icon ===
                                                              "sonneIcon",
                                                            src:
                                                              _vm.sonneIconNormal,
                                                            alt: "Sonne-Icon"
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              _vm.brettEintrag.icon =
                                                                "sonneIcon"
                                                            }
                                                          }
                                                        }),
                                                        _c("img", {
                                                          staticClass:
                                                            "iconSelect",
                                                          attrs: {
                                                            selected:
                                                              _vm.brettEintrag
                                                                .icon ===
                                                              "erdkundeIcon",
                                                            src:
                                                              _vm.erdkundeIconNormal,
                                                            alt: "Erdkunde-Icon"
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              _vm.brettEintrag.icon =
                                                                "erdkundeIcon"
                                                            }
                                                          }
                                                        }),
                                                        _c("img", {
                                                          staticClass:
                                                            "iconSelect",
                                                          attrs: {
                                                            selected:
                                                              _vm.brettEintrag
                                                                .icon ===
                                                              "religionIcon",
                                                            src:
                                                              _vm.religionIconNormal,
                                                            alt: "Religion-Icon"
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              _vm.brettEintrag.icon =
                                                                "religionIcon"
                                                            }
                                                          }
                                                        }),
                                                        _c("img", {
                                                          staticClass:
                                                            "iconSelect",
                                                          attrs: {
                                                            selected:
                                                              _vm.brettEintrag
                                                                .icon ===
                                                              "politikIcon",
                                                            src:
                                                              _vm.politikIconNormal,
                                                            alt: "Politik-Icon"
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              _vm.brettEintrag.icon =
                                                                "politikIcon"
                                                            }
                                                          }
                                                        }),
                                                        _c("img", {
                                                          staticClass:
                                                            "iconSelect",
                                                          attrs: {
                                                            selected:
                                                              _vm.brettEintrag
                                                                .icon ===
                                                              "bioIcon",
                                                            src:
                                                              _vm.bioIconNormal,
                                                            alt: "Biologie-Icon"
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              _vm.brettEintrag.icon =
                                                                "bioIcon"
                                                            }
                                                          }
                                                        }),
                                                        _c("img", {
                                                          staticClass:
                                                            "iconSelect",
                                                          attrs: {
                                                            selected:
                                                              _vm.brettEintrag
                                                                .icon ===
                                                              "englischIcon",
                                                            src:
                                                              _vm.englischIconNormal,
                                                            alt: "Englisch-Icon"
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              _vm.brettEintrag.icon =
                                                                "englischIcon"
                                                            }
                                                          }
                                                        })
                                                      ]
                                                    ),
                                                    _vm.bearbeiten
                                                      ? _c(
                                                          "div",
                                                          [
                                                            _c(
                                                              "v-col",
                                                              {
                                                                staticClass:
                                                                  "pb-0",
                                                                staticStyle: {
                                                                  "font-size":
                                                                    "medium"
                                                                },
                                                                attrs: {
                                                                  cols: "12",
                                                                  md: "12"
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " Eintrag löschen: "
                                                                )
                                                              ]
                                                            ),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "12",
                                                                  md: "12"
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "v-btn",
                                                                  {
                                                                    staticStyle: {
                                                                      color:
                                                                        "red"
                                                                    },
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        _vm.eintragLoeschen()
                                                                        _vm.resetPopup()
                                                                        _vm.eintragPopup = false
                                                                      }
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Ja, Eintrag löschen "
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e()
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }