<template>
  <div>
    <div :key="pupil.name" v-for="pupil in pupilData">
      <div style="display: flex; margin-bottom: 25px; margin-top: 25px">
        <qrcode-vue
          ref="qrcodecontainer"
          :value="pupil.qrCodeText"
          size="200"
        ></qrcode-vue>
        <div
          style="margin-left: 30px; margin-top: auto;  margin-bottom: auto; display: flex; flex-direction: column; justift-content: center; al"
        >
          <div style="margin-bottom: 10px"><b>Name:</b> {{ pupil.name }}</div>
          <div style="margin-bottom: 10px">
            <b>Nachname:</b> {{ pupil.lastName }}
          </div>
          <div style="margin-bottom: 10px">
            <b>Passwort:</b> {{ pupil.password }}
          </div>
          <div style="margin-bottom: 10px">
            <b>Klasse:</b> {{ pupil.class }}
          </div>
          <div style="margin-bottom: 10px">
            <b>Einfacher Modus:</b> {{ pupil.babyView ? "Ja" : "Nein" }}
          </div>
          <div style="margin-bottom: 10px">
            <b>Rolle:</b> {{ roleStrings[pupil.role] }}
          </div>
        </div>
      </div>
      <hr class="item-separator" />
    </div>
  </div>
</template>

<script>
import QrcodeVue from "qrcode.vue";
import { compress } from "shrink-string";
import loginList from "./loginList.json";

const compressIt = async (name, password) => {
  const compressedName = await compress(name);
  const compressedPW = await compress(password);

  const compressedString = JSON.stringify({
    name: compressedName,
    pw: compressedPW,
  });
  return compressedString;
};

export default {
  name: "BackendAPI",
  components: {
    // Login,
    // ChatWidget,
    QrcodeVue,
  },
  async mounted() {
    const transformedUsers = this.transformUsers(this.pupils);
    const pupilData = await Promise.all(
      transformedUsers.map(async (pupil) => ({
        ...pupil,
        qrCodeText: await this.generateQRCodeText(pupil),
      }))
    );
    console.log(pupilData[0].qrCodeText);
    this.pupilData = pupilData;
  },
  data: () => ({
    qrCodeData: "",
    roleStrings: {
      pupil: "Schüler",
      maintainer: "Verwaltung",
      teacher: "Lehrer",
    },
    pupils: loginList,
    pupilData: [],
  }),
  props: {},
  computed: {},
  methods: {
    async generateQRCodeText(pupil) {
      // console.log(this.pupilData);
      const qrCodeText = await compressIt(pupil.name, pupil.qrPassword);
      return qrCodeText;
      // this.pupilData[pupil[0]] = qrCodeData;
      // console.log(JSON.stringify(this.pupilData));
    },
    transformUsers(users) {
      return users.reduce(
        (pv, group) => [
          ...pv,
          { ...group.lead, class: group.name, role: "teacher" },
          ...group.participants.map((participant) => ({
            ...participant,
            class: group.name,
            role: "pupil",
          })),
        ],
        []
      );
    },
  },
};
</script>
<style scoped>
.item-separator {
  border-top: dotted;
}
</style>