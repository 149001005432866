var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "container" } },
    [
      !_vm.chatOpen
        ? _c(
            "v-btn",
            {
              staticClass: "ml-4 animateIcon",
              staticStyle: {
                "background-color": "#FFFFFF",
                width: "50px",
                height: "50px",
                "border-radius": "8px"
              },
              attrs: { "x-small": "", elevation: "0" },
              on: {
                click: function($event) {
                  _vm.chatOpen = !_vm.chatOpen
                }
              }
            },
            [
              _c("img", {
                staticStyle: { "max-width": "25px", "max-height": "25px" },
                attrs: { src: _vm.chatIconGruen }
              })
            ]
          )
        : _c(
            "v-btn",
            {
              staticClass: "ml-4 animateIcon",
              staticStyle: {
                "background-color": "#8cbd46",
                width: "50px",
                height: "50px",
                "border-radius": "8px"
              },
              attrs: { "x-small": "", elevation: "0" },
              on: {
                click: function($event) {
                  _vm.chatOpen = !_vm.chatOpen
                }
              }
            },
            [
              _c("img", {
                staticClass: "iconToWhite",
                staticStyle: { "max-width": "25px", "max-height": "25px" },
                attrs: { src: _vm.chatIcon }
              })
            ]
          ),
      _c("BabyChat", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.chatOpen,
            expression: "chatOpen"
          }
        ],
        attrs: { id: "ChatWidget" }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }