import { render, staticRenderFns } from "./FileWidget.vue?vue&type=template&id=2e144de0&scoped=true&"
import script from "./FileWidget.vue?vue&type=script&lang=js&"
export * from "./FileWidget.vue?vue&type=script&lang=js&"
import style0 from "./FileWidget.vue?vue&type=style&index=0&id=2e144de0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e144de0",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
installComponents(component, {VBadge,VBtn,VCol,VIcon,VOverlay,VProgressLinear,VRow,VSnackbar})


/* hot reload */
if (module.hot) {
  var api = require("/home/peternauroth/homeschooling/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2e144de0')) {
      api.createRecord('2e144de0', component.options)
    } else {
      api.reload('2e144de0', component.options)
    }
    module.hot.accept("./FileWidget.vue?vue&type=template&id=2e144de0&scoped=true&", function () {
      api.rerender('2e144de0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/FileWidget.vue"
export default component.exports