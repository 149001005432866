import { render, staticRenderFns } from "./Faecher.vue?vue&type=template&id=5e6ab9b6&scoped=true&"
import script from "./Faecher.vue?vue&type=script&lang=js&"
export * from "./Faecher.vue?vue&type=script&lang=js&"
import style0 from "./Faecher.vue?vue&type=style&index=0&id=5e6ab9b6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e6ab9b6",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarItems } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VAvatar,VBtn,VCard,VCardActions,VCardText,VCardTitle,VCheckbox,VCol,VDataTable,VDialog,VOverlay,VProgressCircular,VRow,VSnackbar,VSpacer,VTextField,VToolbar,VToolbarItems,VToolbarTitle,VTooltip})


/* hot reload */
if (module.hot) {
  var api = require("/home/peternauroth/homeschooling/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5e6ab9b6')) {
      api.createRecord('5e6ab9b6', component.options)
    } else {
      api.reload('5e6ab9b6', component.options)
    }
    module.hot.accept("./Faecher.vue?vue&type=template&id=5e6ab9b6&scoped=true&", function () {
      api.rerender('5e6ab9b6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Verwaltung/Faecher.vue"
export default component.exports