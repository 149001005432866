var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-btn",
        {
          attrs: { id: "joinLehrerzimmerBtn", color: "gruen", elevation: "0" },
          on: { click: _vm.joinLehrerzimmer }
        },
        [
          _c("img", { attrs: { src: _vm.videoIcon } }),
          _c("p", [_vm._v("Lehrerzimmer")])
        ]
      ),
      _c(
        "v-snackbar",
        {
          attrs: { bottom: "", color: "error", timeout: "5000" },
          model: {
            value: _vm.snackbar,
            callback: function($$v) {
              _vm.snackbar = $$v
            },
            expression: "snackbar"
          }
        },
        [_vm._v(" " + _vm._s(_vm.snackbarText) + " ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }