<template>
    <div class="group-chat-wrapper">
        <div class="group-chat-header d-flex align-center">
            <v-btn @click="clickBack" class="ma-2 group-chat-header-btn" color="white" fab text small dark>
                <img :src="pfeilIcon" class="icon iconToWhite" style="transform: rotate(90deg)">
            </v-btn>
            <span class="group-chat-header-text">{{ room.name.includes('private Room')? checkRoomName(room.name) : room.name }}</span>
        </div>
        <div v-if="room" class="chat-body" style="overflow: hidden !important">
            <vue-custom-scrollbar ref="scroll" class="scroll-area" :settings="settings" @ps-y-reach-start="loadMore()">
                <v-list>
                    <v-list-item-group :key="index" v-for="(event, index) in events" class="mb-2">
                        <div v-if="event.getType() === 'm.room.message'">
                            <v-list-item :class="( isMe(event) ? 'myMessage ' : ' ') + 'chat-message'">
                                <v-row dense>
                                    <v-col>
                                        <v-row dense v-long-press="1000" @long-press-start="(isMe(event) && event.getContent().body)? ((selectedEvent = event) && (deleteDialog = true)) : null">
                                            <v-col class="pa-0 greyText">{{ getUsername(event) }}</v-col>
                                            <v-col class="text-right greyText" style="font-size: smaller;">
                                                {{new Date(getTime(event)).toLocaleDateString() + ' ' + new Date(getTime(event)).toLocaleTimeString().slice(0, new Date(getTime(event)).toLocaleTimeString().length - 3) }}
                                            </v-col>
                                        </v-row>
                                        <v-row
                                            dense
                                            v-long-press="1000" @long-press-start="(isMe(event) && event.getContent().body) ? ((selectedEvent = event) && (deleteDialog = true)) : null"
                                            :class="( isMe(event) ? 'myMessage-content ' : ' ') + 'chat-message-content'"
                                        >
                                            <!-- //TODO FIX ME
                                            :class="( isMe(event) ? 'myMessage-content ' : (getGender(event) === 'm' ? 'male-chat ' : (getGender(event) === 'w' ? 'female-chat ' : ''))) + 'chat-message-content'"-->
                                            <div class="pa-0" style="width: 100%">
                                                <div v-if="event.getContent().body">
                                                    <div v-if="event.getContent().body.startsWith('$fileId:')">
                                                        <div @click=" loadedAudios.filter((el) => { return el.file === event.getContent().body.replace('$fileId:','') }).length > 0  ? playPause(event.getContent().body.replace('$fileId:','')) : loadFile(event.getContent().body.replace('$fileId:',''))" style="line-height: 20px">
                                                            <div class="d-flex align-center" style="min-height: 24px">
                                                                <p class="mb-0 mr-1">Sprachnachricht</p>
                                                                <img v-if="!loadedAudios.filter((el) => { return el.file === event.getContent().body.replace('$fileId:','') }).length > 0" :src="downloadIcon" :class="(isMe(event) ? 'iconToWhite ' : '') + 'icon'">
                                                                <img v-else-if="audioSrc !== event.getContent().body.replace('$fileId:','')" :src="playIcon" :class="(isMe(event) ? 'iconToWhite ' : '') + 'icon'">
                                                                <img v-else :src="pauseIcon" :class="(isMe(event) ? 'iconToWhite ' : '') + 'icon'">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div v-else>
                                                       <v-btn v-if="isMe(event)" x-small class="pa-0" style="position: relative; float: right; width: 25px !important; min-width: 25px !important; height: 25px">
                                                            <img @click="(isMe(event) && event.getContent().body) ? ((selectedEvent = event) && (deleteDialog = true)) : null" :src="papierkorbIcon" height="20px">
                                                       </v-btn>

                                                        {{ event.getContent().body }}
                                                    </div>
                                                </div>
                                                <div v-else style="font-style: italic">
                                                    Diese Nachricht wurde gelöscht.
                                                </div>
                                            </div>
                                        </v-row>

                                        <div v-if="!isMe(event) && (event.getContent().body && !event.getContent().body.startsWith('$fileId:'))" style="position: absolute; top: 38px; right: -40px">
                                            <v-btn @click="greet(event.getContent().body, $event)" elevation="0" icon class="ml-2 pa-0" style="width: 20px; height: 20px">
                                                <img :src="isReading ? lautsprecherAusIcon : lautsprecherIcon" style="height: 30px;"/>
                                            </v-btn>
                                        </div>

                                        <div v-else-if="(event.getContent().body && !event.getContent().body.startsWith('$fileId:'))" style="position: absolute; top: 38px; left: -40px">
                                            <v-btn @click="greet(event.getContent().body, $event)" elevation="0" icon class="ml-2 pa-0" style="width: 20px; height: 20px">
                                                <img :src="isReading ? lautsprecherAusIcon : lautsprecherIcon" style="height: 30px;"/>
                                            </v-btn>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-list-item>
                        </div>
                    </v-list-item-group>

                </v-list>
            </vue-custom-scrollbar>
        </div>
        <div v-if="room" class="chat-send-wrapper align-center">
            <twemoji-picker
                :emojiData="emojiDataAll"
                :emojiGroups="emojiGroups"
                :pickerWidth="300"
                :skinsSelection="true"
                :searchEmojisFeat="true"
                @emojiUnicodeAdded=" (el) => inputMessage+= el"
                searchEmojiPlaceholder="Suchen..."
                searchEmojiNotFound="Kein Emoji gefunden"
                isLoadingLabel="Lädt..."
            ></twemoji-picker>

            <input v-model="inputMessage" oncontextmenu="return false;" @keyup.enter="sendMessage" placeholder="Nachricht eingeben..." class="chat-send-input py-3 px-5 my-3" type="text"/>

            <div v-if="!inputMessage && audioCheck" oncontextmenu="return false;">
                <v-btn fab text :color="!toggle ? '' : 'blau'" v-long-press="100" @long-press-start="startRecording" @long-press-stop="stopRecording" class="ma-0 recordBtn">
                    <div  id="micIcon" :class="toggle ? 'iconToWhite' : ''"> </div>
                </v-btn>
            </div>
            <v-btn v-else fab text @click="sendMessage" class="ma-0">
                <img :src="sendenIcon" height="30">
            </v-btn>
        </div>
        <div v-if="!room">Keine Gruppe ausgewählt</div>


        <v-dialog v-model="deleteDialog" persistent max-width="400">
            <v-card>
                <v-card-title class="headline">Nachricht löschen?</v-card-title>
                <v-card-text>
                    Wollen Sie diese wirklich Nachricht löschen?
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="#bdbdbd" style="color: white" @click="deleteDialog = false">Abbrechen</v-btn>
                    <v-btn style="color: white" color="red red-1" @click="() => {this.deleteMessage(); this.deleteDialog = false}">Löschen</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
//import * as sdk from "matrix-js-sdk";
import * as backend from "../../api/backend";
import vueCustomScrollbar from "vue-custom-scrollbar";
import lautsprecherIcon from '../../assets/Icons/lautsprecher-13.svg'
import lautsprecherAusIcon from '../../assets/Icons/lautsprecher-aus-89.svg'
import abbrechenIcon from '../../assets/Icons/abbrechen-08.svg'
import papierkorbIcon from '../../assets/Icons/papierkorb-109.svg'
import pfeilIcon from '../../assets/Icons/unten-dropdown-12.svg'
import downloadIcon from '../../assets/Icons/download-116.svg'
import playIcon from '../../assets/Icons/Play-114.svg'
import pauseIcon from '../../assets/Icons/Pause-115.svg'
import mikrofonIcon from '../../assets/Icons/mikrofon-22.svg'
import sendenIcon from '../../assets/Icons/senden-23.svg'

import {
    TwemojiPicker
} from '@kevinfaguiar/vue-twemoji-picker';

import translation from '../../util/translate'
import EmojiAllData from '@kevinfaguiar/vue-twemoji-picker/emoji-data/de/emoji-all-groups.json';
import EmojiGroups from '@kevinfaguiar/vue-twemoji-picker/emoji-data/emoji-groups.json';
import {mapActions, mapState} from 'vuex';
import Recorder from 'js-audio-recorder';

export default {
    name: "ChatWidget",
    components: {vueCustomScrollbar, 'twemoji-picker': TwemojiPicker},
    data: () => ({
        subjectXmlHttpRequest: null,
        inputMessage: "",
        url: "http://localhost:8080/img/vollbild-09.a2073d91.svg",
        settings: {
            suppressScrollY: false,
            suppressScrollX: true,
            wheelPropagation: true
        },
        isReading: false,

        lautsprecherIcon,
        lautsprecherAusIcon,
        abbrechenIcon,
        papierkorbIcon,
        pfeilIcon,
        downloadIcon,
        playIcon,
        pauseIcon,
        mikrofonIcon,
        sendenIcon,

        selectedEvent: null,
        deleteDialog: false,
        toggle: false,
        recognition: null,
        showTextTimeout: null,
        audio: new Audio(),
        audioSrc: '',
        matrixUsers: [],
        maintainers: [],
        audioCheck: false,
        loadedAudios: [],
        recorder: new Recorder(),
    }),
    props: {
        room: {required: true},
        matrixClient: {required: true}
    },
    watch: {
        room: function (newVal, oldVal) {
            // watch it
            /*console.log("Prop changed: ", newVal, " | was: ", oldVal);*/
        },
    },
    async created() {
        while (!this.$refs.scroll) {
            await this.sleep(500);
        }
        this.$refs.scroll.$el.scrollTop = this.$refs.scroll.$el.scrollHeight;
    },

    async mounted() {
        this.requestAppointments();
        this.requestPupil();
        this.requestTeachers();
        this.requestMaintainers();
        this.checkPermission();
    },
    computed: {
        ...mapState("auth", ["account"]),
        ...mapState('translation', [
            'targetLang',
        ]),
        ...mapState("auth", [ "token", "account"]),
        events: function () {
            return this.room.timeline;
        },
        emojiDataAll() {
            return EmojiAllData;
        },
        emojiGroups() {
            return EmojiGroups;
        }
    },
    methods: {
        ...mapActions('translation', [ 'setTranslatedText', 'showTranslation' ]),
        ...mapActions("appointments", ["getAppointments"]),
        ...mapActions("pupils", ["getPupils"]),
        ...mapActions("teachers", ["getTeachers"]),
        ...mapActions("maintainers", ["getMaintainers"]),
        async loadMore() {
            this.matrixClient.scrollback(
                this.room,
                1,
                (err, res) => {
                    console.log(err);
                });
            this.$refs.scroll.$el.scrollTop = 10;
        },
        async requestAppointments() {
            const appointments = await this.getAppointments();
            if(this.account.role === 'pupil') {
                appointments.forEach((el) => {
                    const index = this.matrixUsers.indexOf(el.teacher);
                    if (index === -1) {
                        this.matrixUsers.push(el.teacher);
                    }
                })
            }
        },
        async requestPupil() {
            if(this.account.role === 'teacher') {
                this.matrixUsers = await this.getPupils();
                this.matrixUsers.forEach((el) => el.account = el.account._id);
            }
        },
        async requestTeachers() {
            if(this.account.role === 'maintainer') {
                this.matrixUsers = await this.getTeachers();
            }
        },
        async requestMaintainers() {
            if(this.account.role === 'teacher') {
                this.maintainers = await this.getMaintainers();
            }
        },
        checkRoomName(name) {
            const id = name.replace(this.account._id, "").replace("private Room", "").replace("<", "").replace(">", "").replace(",", "").replace(" ", "");
            let secondUser = this.matrixUsers.find((el) => {
                return el.account === id;
            });
            if(secondUser) {
                return secondUser.lastName +  ' ' + secondUser.name;
            } else {
                if(this.account.role === 'teacher') {
                    secondUser = this.maintainers.find((el) => {
                        return el.account._id === id;
                    });
                    if(secondUser) {
                        return 'Verwaltung';
                    } else {
                        return 'Unbekannter Nutzer';
                    }
                }
                return 'Unbekannter Nutzer';
            }
        },
        checkPermission() {
            Recorder.getPermission().then(() => {
                this.audioCheck = true;
            }, (error) => {
                console.log(`${error.name} : ${error.message}`);
            });
        },
        async loadFile(file) {
            /*console.log('load')*/
            const res = await backend.getVoiceMessage(
                file
            );
            fetch(res.url, {
                method: "GET",
                headers: new Headers({
                    Authorization: "Bearer " + this.token,
                }),
            })
                .then((response) => response.blob())
                .then((blob) => {
                    /*console.log(file)
                    console.log(blob)*/
                    let url = window.URL.createObjectURL(blob);
                    this.loadedAudios.push({'file' : file, 'url': url})

                });
        },

        playPause(file) {
            /*console.log(this.loadedAudios)*/
            if(this.audio) {
                this.audio.pause();
                this.audio = null;
                if(this.audioSrc === file) {
                    this.audioSrc = '';
                    return
                }
                this.audioSrc = '';
            }
            this.audio = new Audio(this.loadedAudios.find(el => el.file === file).url);
            this.audioSrc = file;
            this.audio.play();
            this.audio.addEventListener("ended", () => {
                this.audioSrc = '';
            });

        },
        startRecording() {
            this.toggle = true;
            /*console.log("Start Recording");*/
            this.recorder.startRecord();
            /*console.log(this.recorder);*/
        },
        stopRecording() {
            this.toggle = false;
            /*console.log("Stop Recording")*/
            this.recorder.stopRecord();
            this.handleRecording(this.recorder.getWAVBlob());
        },
        handleRecording(blob) {
            const formData = new FormData();
            formData.append("file", blob, new Date().toLocaleDateString().replaceAll(".", "_") + '.wav');

            this.XmlHttpRequest = backend.postVoiceMessage(
                blob
            );

            this.XmlHttpRequest.onerror = (e) => {
                console.error("Private upload error:", e);
                this.XmlHttpRequest = null;
            };

            this.XmlHttpRequest.onabort = (e) => {
                console.warn("Private upload aborted");
                this.XmlHttpRequest = null;
            };

            this.XmlHttpRequest.addEventListener("load", (e) => {
                if (this.XmlHttpRequest.status !== 201) {
                    console.error(
                        "Private upload failed:",
                        this.XmlHttpRequest.response
                    );
                }
                this.$emit('sendMessage', '$fileId:' + JSON.parse(this.XmlHttpRequest.response)._id);
                this.inputMessage = '';
                setTimeout(() => {
                    this.$refs.scroll.$el.scrollTop = this.$refs.scroll.$el.scrollHeight
                }, 500)
                this.XmlHttpRequest = null;
            });
            this.XmlHttpRequest.send(formData);
        },

        sleep(milliseconds) {
            return new Promise(resolve => setTimeout(resolve, milliseconds));
        },
        getTime(event) {
            let age = event.getUnsigned().age;
            // TODO: proper format/hide date if message was sent today and always show time
            return new Date(Date.now() - age).toString();
        },
        //name is sliced as it ends with the gender char. eg. Fritzm means name=Fritz, gender=male

        getUsername(event) {
            /*return event.sender.rawDisplayName.slice(0, event.sender.rawDisplayName.length-1);*/ //TODO and me too
            return event.sender.rawDisplayName;
        },
        //return only the gender char [m, w, d]
        getGender(event) {
            return event.sender.rawDisplayName.slice(event.sender.rawDisplayName.length-1, event.sender.rawDisplayName.length);
        },
        isMe(event) {
            return (this.matrixClient.credentials.userId === event.sender.userId);
        },
        getImage(event) {
            //console.log(event.getContent());
            let mxcurl = event.getContent().info.thumbnail_url;
            let w = event.getContent().info.w;
            let h = event.getContent().info.h;
            console.log(mxcurl);
            let imageuri = this.matrixClient.mxcUrlToHttp(mxcurl, w, h, "scale");
            console.log(imageuri);
            return imageuri;
        },
        getVideo(event) {
            /*console.log(event.getContent());*/
            let mxcurl = event.getContent().url;
            let split = mxcurl.split("/");
            let mediaId = split[split.length - 1];
            let size = event.getContent().info.size;
            let imageuri = `https://estundenplan-synapse-development.concept-hero-projects.de/_matrix/media/r0/download/estundenplan-synapse-development/${mediaId}?size=${size}`;
            //let imageuri = this.matrixClient.mxcUrlToHttp(mxcurl, 200, 200, "scale");
            /*console.log(imageuri);*/
            return imageuri;
        },
        sendMessage() {
            if ((this.inputMessage.trim())) {
                this.$emit('sendMessage', this.inputMessage);
                this.inputMessage = '';
                setTimeout(() => {
                    this.$refs.scroll.$el.scrollTop = this.$refs.scroll.$el.scrollHeight
                }, 500)
            }

        },
        async deleteMessage() {
            this.$emit('deleteMessage', this.selectedEvent);
            if(this.selectedEvent.getContent().body.startsWith('$fileId:')) {
                const res = await backend.deleteVoiceMessage(this.selectedEvent.getContent().body.replace('$fileId:',''));
            }
        },
        updateRoom() {
            /*console.log(this.room.timeline);*/
        },
        clickBack() {
            this.$emit("exitRoom");
        },

        async greet(message, event) {
            if ('speechSynthesis' in window) {
                // Speech Synthesis supported 🎉
            } else {
                // Speech Synthesis Not Supported 😣
                alert("Sorry, your browser doesn't support text to speech!");
                return
            }

            if (this.targetLang !== 'de') {
                message = await translation.translateToTargetLang(this.targetLang, message);

                this.setTranslatedText(message);
                this.showTranslation(true);

                if (this.showTextTimeout) {
                    clearTimeout(this.showTextTimeout);
                    this.showTextTimeout = null;
                }

                this.showTextTimeout = setTimeout(() => {
                    this.showTranslation(false);
                    this.showTextTimeout = null;
                }, 15000)
            }

            if (window.speechSynthesis.speaking) {
                window.speechSynthesis.cancel()
                event.target.src = lautsprecherIcon;
            } else {
                event.target.src = lautsprecherAusIcon;
                let msg = new SpeechSynthesisUtterance();
                msg.text = message;
                if (this.isLangPackageAvailable()) {
                    msg.lang = this.targetLang;
                    window.speechSynthesis.speak(msg);
                } else {
                    event.target.src = lautsprecherIcon;
                }

                msg.onend = () => {
                    event.target.src = lautsprecherIcon;
                }
            }
        },
        isLangPackageAvailable() {
            for (let i = 0; i < window.speechSynthesis.getVoices().length; i++) {
                if (window.speechSynthesis.getVoices()[i].lang.includes(this.targetLang)) {
                    return true;
                }
            }

            return false;
        },
    },
};
</script>

<style lang="scss" scoped>
.chat-send-input {
    background-color: white;
    border-radius: 50px;
    width: 100%;
}

.chat-send-wrapper {
    display: inline-flex;
    background-color: #f2f2f2;
    padding: 0 12px;
    bottom: 0;
    left: 0;
    right: 0;
}

.group-chat-wrapper {
    flex: auto;
    max-height: calc(80vh - 55px);
    display: flex;
    flex-direction: column;
    overflow: auto;
    background-color: white !important;
    border-radius: 0 0 15px 15px;
}

.chat-body {
    // position: fixed;
    // overflow-y: auto;
    flex: auto;
    overflow: hidden;
}

.group-chat-header-btn {
    padding: 0 !important;
    min-width: 0 !important;
}

.group-chat-header-text {
    color: white;
}

.group-chat-header {
    background-color: #95c94b; // 3caa68 - 5% Luminosity
}

.chat-header-btn {
    background-color: white !important;
    width: 35px;
    height: 35px !important;
}

.chat-header-img {
    height: 20px;
    filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%) hue-rotate(36deg) brightness(104%) contrast(105%);
}

.scroll-area {
    position: relative;
    margin: auto;
    max-height: calc(80vh - 176px);
}

.chat-message {
    position: relative;
    border-radius: 5px;
    max-width: 75%;
    margin: 2px;
}

.myMessage {
    margin-left: auto !important;
    margin-right: 2px !important;
}

/*removes darker background after element is clicked
also works without the specific class in front*/
.myMessage:before {
    opacity: 0 !important;
}
.chat-message:before {
    opacity: 0 !important;
}
.recordBtn:before {
    opacity: 0 !important;
}

.chat-message-content {
    border-radius: 0 5px 5px 5px;
    box-shadow: 1px 2px 3px silver;
    background-color: #505faa;
    /*background-color: #9b91c8;*/ //use me when using chat gender colors again
    color: white;
    padding: 9px;
}
.male-chat {
    background-color: #505faa !important;
}
.female-chat {
    background-color: #e6415a !important;
}

.myMessage-content {
    border-radius: 5px 5px 0 5px !important;
    background-color: #dbdbdb;
    color: black;
}

.progressbar {
    border-radius: 5px;
}

.icon {
    height: 20px;
}

.iconToWhite {
    filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%) hue-rotate(36deg) brightness(104%) contrast(105%);
}

.greyText{
    color: #707070;
}

#micIcon {
    background-image: url('../../assets/Icons/mikrofon-22.svg');
    width: 30px;
    height: 30px;
}
</style>
