var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.pupilData, function(pupil) {
      return _c("div", { key: pupil.name }, [
        _c(
          "div",
          {
            staticStyle: {
              display: "flex",
              "margin-bottom": "25px",
              "margin-top": "25px"
            }
          },
          [
            _c("qrcode-vue", {
              ref: "qrcodecontainer",
              refInFor: true,
              attrs: { value: pupil.qrCodeText, size: "200" }
            }),
            _c(
              "div",
              {
                staticStyle: {
                  "margin-left": "30px",
                  "margin-top": "auto",
                  "margin-bottom": "auto",
                  display: "flex",
                  "flex-direction": "column",
                  "justift-content": "center"
                }
              },
              [
                _c("div", { staticStyle: { "margin-bottom": "10px" } }, [
                  _c("b", [_vm._v("Name:")]),
                  _vm._v(" " + _vm._s(pupil.name))
                ]),
                _c("div", { staticStyle: { "margin-bottom": "10px" } }, [
                  _c("b", [_vm._v("Nachname:")]),
                  _vm._v(" " + _vm._s(pupil.lastName) + " ")
                ]),
                _c("div", { staticStyle: { "margin-bottom": "10px" } }, [
                  _c("b", [_vm._v("Passwort:")]),
                  _vm._v(" " + _vm._s(pupil.password) + " ")
                ]),
                _c("div", { staticStyle: { "margin-bottom": "10px" } }, [
                  _c("b", [_vm._v("Klasse:")]),
                  _vm._v(" " + _vm._s(pupil.class) + " ")
                ]),
                _c("div", { staticStyle: { "margin-bottom": "10px" } }, [
                  _c("b", [_vm._v("Einfacher Modus:")]),
                  _vm._v(" " + _vm._s(pupil.babyView ? "Ja" : "Nein") + " ")
                ]),
                _c("div", { staticStyle: { "margin-bottom": "10px" } }, [
                  _c("b", [_vm._v("Rolle:")]),
                  _vm._v(" " + _vm._s(_vm.roleStrings[pupil.role]) + " ")
                ])
              ]
            )
          ],
          1
        ),
        _c("hr", { staticClass: "item-separator" })
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }