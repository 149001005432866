<template>
    <div>
        <v-row class="mx-0">
            <div class="whiteBg" style="height: auto; min-width: 100px !important; width: 90%; min-height: 80vh; border-radius: 15px; box-shadow: 1px 5px 5px silver; margin: auto auto 5em;">

                <!--Header of Schwarzes Brett-->
                <div style="background-color: #707070; border-radius: 15px 15px 0 0; padding-top: 10px; padding-bottom: 10px; color: white;">
                    <v-row style="width: 90%; display: flex; align-items: center; height: 35px" class="pr-2 ml-2">
                        <v-col cols="9" md="8" lg="9" style="white-space: nowrap;" class="pa-0 pl-2 font-style d-flex align-center">
                            <img :src="brettIcon" class="iconToWhite mr-1" style="height: 20px;"/>
                            Schwarzes Brett
                        </v-col>
                    </v-row>
                </div>

                <!--body of Schwarzes Brett-->
                <vue-custom-scrollbar class="scroll-area" :settings="settings">
                    <div :key="updateBlackboard">

                        <!--placeholder if there are no entries-->
                        <div v-if="blackboard.length === 0" class="ma-4" style="z-index: 0">
                            Die Schule hat noch keine Einträge verfasst
                        </div>

                        <!--pinned entry-->
                        <v-card v-for="(item) in blackboard.filter((el) => el.pin)" :key="JSON.stringify(item)" class="ma-2" style="z-index: 0">
                            <v-badge
                                color="red"
                                content="1"
                                :value="!item.seenByMe"
                                overlap
                                offset-x="15"
                                style="z-index: 9; width: 100%"
                            >
                                <v-card-title class="pa-1" style="color: #707070; background-color: #EAEAEA; max-width: 100%">
                                    <div class="d-flex justify-space-between" style="width: 100%;">
                                        <div class="leftTitleText d-flex align-center">
                                            <img :src="chooseIcon(item.icon)" class="boardIcon mr-2" :style="{ 'background-color': item.color}"/>
                                            <div class="ellipsis">Verwaltung</div>
                                        </div>

                                        <div class="RightTitleText d-flex justify-end align-center">
                                            <div class="mr-2 timeAndDate">
                                              <div style="height: 1.25em">{{new Date(item.createdOn).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}}</div>
                                              <div style="height: 1.25em">{{dayname[new Date(item.createdOn).getDay()] + '. ' + new Date(item.createdOn).toLocaleDateString([], {day: '2-digit', month: '2-digit'})}}</div>
                                            </div>
                                            <v-btn v-if="account.role === 'pupil'" @click="readText(item.message, $event)" x-small class="pa-0 mr-2" style="background-color: #F8F8F880 !important; height: 35px;">
                                                <img :src="lautsprecherIcon" style="height: 20px; color: #707070"/>
                                            </v-btn>

                                            <v-btn @click="pinBrettEintrag(item._id)" x-small class="pa-0" :style="{'background-color': item.color}" style="height: 35px; width: 35px">
                                                <img :src="pinIcon" class="iconToWhite" style="height: 20px;"/>
                                            </v-btn>
                                        </div>
                                    </div>

                                </v-card-title>
                                <v-card-text class="pa-2" style="font-size: medium">
                                    <div @click="expandText($event)" class="fade blackboardVCardText">
                                        {{ item.message }}
                                    </div>
                                </v-card-text>
                            </v-badge>
                        </v-card>

                        <!--regular entry-->
                        <v-card v-for="(item) in blackboard.filter((el) => !el.pin)" :key="JSON.stringify(item)" class="ma-2" style="z-index: 0;">
                            <v-badge
                                color="red"
                                content="1"
                                :value="!item.seenByMe"
                                overlap
                                offset-x="15"
                                style="z-index: 9; width:100%"
                            >
                                <v-card-title class="pa-1" style="color: #707070; background-color: #EAEAEA; max-width: 100%">
                                    <div class="d-flex justify-space-between" style="width: 100%;">
                                        <div class="leftTitleText d-flex align-center">
                                            <img :src="chooseIcon(item.icon)" class="boardIcon mr-2" :style="{ 'background-color': item.color}"/>
                                            <div class="ellipsis">Verwaltung</div>
                                        </div>

                                        <div class="RightTitleText d-flex justify-end align-center">
                                            <div class="mr-2 timeAndDate">
                                                <p class="mb-0" style="height: 1.25em">{{ new Date(item.createdOn).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'}) }}</p>
                                                <p class="mb-0" style="height: 1.25em">{{dayname[new Date(item.createdOn).getDay()] + '. ' + new Date(item.createdOn).toLocaleDateString([], {day: '2-digit', month: '2-digit'}) }}</p>
                                            </div>

                                            <v-btn v-if="account.role === 'pupil'" @click="readText(item.message, $event)" x-small class="pa-0 mr-2" style="background-color: #F8F8F880 !important; height: 35px;">
                                                <img :src="lautsprecherIcon" style="height: 20px; color: #707070"/>
                                            </v-btn>
                                            <v-btn @click="pinBrettEintrag(item._id)" x-small class="pa-0" style="background-color: #F8F8F880 !important; height: 35px; width: 35px">
                                                <img :src="pinIcon" style="height: 20px; color: #707070"/>
                                            </v-btn>
                                        </div>
                                    </div>
                                </v-card-title>
                                <v-card-text class="pa-2">
                                    <div class="blackboardVCardText" style="font-size: medium; color: #444444">{{ item.message }}</div>
                                </v-card-text>
                            </v-badge>
                        </v-card>
                    </div>
                </vue-custom-scrollbar>
            </div>
        </v-row>
    </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";

import vueCustomScrollbar from "vue-custom-scrollbar";

import brettIcon from '../assets/Icons/sw-brett-neu-100.svg';
import pinIcon from "../assets/Icons/pin-63.svg";
import lautsprecherIcon from "../assets/Icons/lautsprecher-13.svg";
import lautsprecherAusIcon from "../assets/Icons/lautsprecher-aus-89.svg";

import sonneIcon from "../assets/Icons/sonne-weiss-46.svg";
import erdkundeIcon from '../assets/Icons/f-erdkunde-weiss-31.svg';
import religionIcon from '../assets/Icons/f-religion-weiss-39.svg';
import politikIcon from '../assets/Icons/f-politik-weiss-34.svg';
import bioIcon from '../assets/Icons/f-biologie-weiss-35.svg';
import englischIcon from "../assets/Icons/f-englisch-30.svg";
import englischIconWeiss from "../assets/Icons/f-englisch-weiss-30.svg";
import translation from "@/util/translate";

export default {
name: "BrettWidget",
    components: {
        vueCustomScrollbar,
    },
    data() {
        return {
            group: '',
            icon: '',
            color: '',
            message: '',
            timestamp: '',

            blackboard: [],

            updateBlackboard: true,

            settings: {
                suppressScrollY: false,
                suppressScrollX: true,
                wheelPropagation: false
            },

            pinIcon,
            brettIcon,
            lautsprecherIcon,
            lautsprecherAusIcon,

          //Icon array for dynamically picking the icon via string
          icons: [
            {icon: englischIcon, text: "englischIcon"},
            {icon: sonneIcon, text: "sonneIcon"},
            {icon: englischIconWeiss, text: "englischIconWeiss"},
            {icon: politikIcon, text: "politikIcon"},
            {icon: erdkundeIcon, text: "erdkundeIcon"},
            {icon: religionIcon, text: "religionIcon"},
            {icon: bioIcon, text: "bioIcon"},
              ],

            dayname: [
                'So',
                'Mo',
                'Di',
                'Mi',
                'Do',
                'Fr',
                'Sa',
            ],
            showTextTimeout: null,
        };
    },
    async mounted() {
        this.requestBlackboard();
    },
    computed : {
        ...mapState('translation', ['targetLang',]),
        ...mapState("auth", ["account"]),
    },
    methods: {
        ...mapActions("blackboard", ["getBlackboard", "createBlackboardEntry"]),
        ...mapActions('translation', [ 'setTranslatedText', 'showTranslation' ]),

        getSrc() {
            return this.thisSubject ? this[this.thisSubject.icon] : (this.thisAppointment ? this[this.thisAppointment.schoolSubject.icon] : null);
        },

        /*Hintergrund-Farbe für Icon wenn es aktuell ist*/
        getBackgroundColor() {
            let returnString =  "background-color: ";
            return (returnString + this.thisAppointment.schoolSubject.color);
        },

        async requestBlackboard() {
            this.blackboard = await this.getBlackboard();

            this.blackboard.sort((a, b) => {
                let keyA = new Date(a.createdOn),
                    keyB = new Date(b.createdOn);
                // Compare the 2 dates
                if (keyA > keyB) return -1;
                if (keyA < keyB) return 1;
                return 0;
            });
            this.updatedPin();
        },

        pinBrettEintrag(eintrag) {
            let eintraege = JSON.parse(localStorage.getItem('blackboard'));
            if(!eintraege) {
                eintraege = [];
            }
            if(!eintraege.includes(eintrag)) {
                eintraege.push(eintrag);
            } else {
                eintraege.splice(eintraege.indexOf(eintrag), 1);
            }
            localStorage.setItem('blackboard', JSON.stringify(eintraege));
            this.updatedPin();
        },

        updatedPin() {
            let eintraege = JSON.parse(localStorage.getItem('blackboard'));
            if(!eintraege) {
                eintraege = [];
            }
            this.blackboard.forEach( (el) => {
                if(eintraege.includes(el._id)) {
                    el['pin'] = true;
                } else {
                    el['pin'] = false;
                }
            });
            this.updateBlackboard = !this.updateBlackboard;
        },

        chooseIcon(iconName) {
          for(let i of this.icons){
            if(i.text === iconName){
              return i.icon;
            }
          }
        },

        async readText(text, event) {
            if ('speechSynthesis' in window) {
                // Speech Synthesis supported 🎉
            } else {
                // Speech Synthesis Not Supported 😣
                alert("Sorry, your browser doesn't support text to speech!");
                return
            }

            let txt;
            if (text) {
                txt = text;
            } else {
                txt = 'Dieser Eintrag hat keinen Inhalt.';
            }

            if (this.targetLang !== 'de') {
                txt = await translation.translateToTargetLang(this.targetLang, txt);

                this.setTranslatedText(txt);
                this.showTranslation(true);

                if (this.showTextTimeout) {
                    clearTimeout(this.showTextTimeout);
                    this.showTextTimeout = null;
                }

                this.showTextTimeout = setTimeout(() => {
                    this.showTranslation(false);
                    this.showTextTimeout = null;
                }, 15000)
            }

            if (window.speechSynthesis.speaking) {
                window.speechSynthesis.cancel();
                event.target.src = lautsprecherIcon;
            } else {
                event.target.src = lautsprecherAusIcon;
                let msg = new SpeechSynthesisUtterance();
                msg.text = txt;
                if (this.isLangPackageAvailable()) {
                    msg.lang = this.targetLang;
                    window.speechSynthesis.speak(msg);
                } else {
                    event.target.src = lautsprecherIcon;
                }

                msg.onend = function() {
                    event.target.src = lautsprecherIcon;
                }
            }
        },
        isLangPackageAvailable() {
            for (let i = 0; i < window.speechSynthesis.getVoices().length; i++) {
                if (window.speechSynthesis.getVoices()[i].lang.includes(this.targetLang)) {
                    return true;
                }
            }

            return false;
        },

        expandText(event) {
            //from https://stackoverflow.com/questions/54459816/vue-text-highlight-event
            const isTextHighlighting = window.getSelection().toString().trim() !== '';

            //only expand when no text is being selected
            if (!isTextHighlighting) {
                event.target.classList.toggle('fade');
                event.target.classList.toggle('initHeight');
            }
        },
    }
}
</script>

<style lang="scss" scoped>

.scroll-area {
    position: relative;
    margin: auto;
    max-height: calc(80vh - 75px);
}

.whiteBg {
    background-color: #ffffff;
}

//filter generated with https://codepen.io/sosuke/pen/Pjoqqp
.iconToWhite {
    filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%) hue-rotate(36deg) brightness(104%) contrast(105%);
}

.font-style {
  font-size: larger
}

.fade {
    width: 100%;
    overflow: hidden;
    position: relative;
    max-height: 44px;
}
.fade:after {
    content: "";
    text-align: right;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 25%;
    height: 50%;
    background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 50%);
}

.initHeight {
    max-height: initial !important;
}

//make text respect line breaks
.blackboardVCardText {
    white-space: pre-line;
}

.leftTitleText {
    min-width: 1%;
}

.boardIcon {
    height: 30px;
    color: white !important;
    border-radius: 4px;
    padding: 5px;
}

.ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis
}

.timeAndDate {
    white-space: nowrap;
    font-size: small;
    line-height: 1rem !important;
    text-align: right;
    opacity: 47%;
}

@media only screen and (min-width: 901px) and (max-width: 1110px){
    .timeAndDate {
        display: none;
    }
}
</style>
