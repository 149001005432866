<template>
  <v-app>
    <!-- Universal Loading Bar -->
    <v-progress-linear
      v-if="showLoading"
      indeterminate
      buffer-value="100"
      color="primary"
      height="10"
      style="position: fixed; top: 0 !important;"
    />

    <!-- Universal Upload -->
    <progress-bar
      title="Hochladen"
      :show-progress="showPupilUploadProgress"
      :progress="pupilUploadProgress"
      :abort-progress="pupilXmlHttpRequest ? () => { pupilXmlHttpRequest.abort() }: () => {}"
    />

    <input
      @change="() => uploadInputChange()"
      id="uploadInput"
      ref="uploadInput"
      type="file"
      hidden
    />

    <name-file
      v-model="showNameFile"
      :original-name="uploadFileName"
      :callback="fileNameCallback"
      :group="currentlyOpenAppointment ? currentlyOpenAppointment.group : null"
    />

    <!-- Universal Upload End -->

    <UserSwitch v-if="developmentMode"></UserSwitch>
    <router-view></router-view>

    <!-- Snackbar Area -->

    <v-snackbar
        v-model="fileSnackbars.dataSaved"
        timeout="5000"
        color="success"
    >
      Ergebnisse gespeichert.
    </v-snackbar>
    <v-snackbar
        v-model="fileSnackbars.dataSubmitted"
        timeout="5000"
        color="success"
    >
      Dein Aufschrieb ist abgegeben. Gut gemacht!
    </v-snackbar>
    <v-snackbar
        timeout="5000"
        color="success"
        v-model="snackbarSave"
    >
      Gespeichert!
    </v-snackbar>
    <v-snackbar
        timeout="5000"
        color="error"
        v-model="fileSnackbars.fileToBig"
    >
      <span>Datei zu groß, max. 5 MB</span>
    </v-snackbar>
    <v-snackbar
        timeout="5000"
        color="error"
        v-model="fileSnackbars.bigFileToBig"
    >
      <span>Datei zu groß, max. 1 GB</span>
    </v-snackbar>
    <v-snackbar
        timeout="5000"
        color="error"
        v-model="fileSnackbars.fileDoesntFitInPrivateFolder"
    >
      <span>Nicht genügend Platz im privaten Ordner</span>
    </v-snackbar>
    <v-snackbar
        timeout="5000"
        color="error"
        v-model="snackbarPrivateFull"
    >
      Speicher überschritten. Datei konnte nicht gespeichert werden
    </v-snackbar>

    <v-card
      id="translatedTextShow"
      type="info"
      v-if="showTranslatedText"
    >
      <v-card-text class="py-2" style="height: 100%; text-align: center">
        <p id="translatedTextText">
          {{ translatedText }}
        </p>
      </v-card-text>
    </v-card>
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import UserSwitch from "./components/Demo/UserSwitch";
import NameFile from "./components/NameFile";
import ProgressBar from "./components/ProgressBar";
import * as backend from "./api/backend";

export default {
  name: "App",

  components: { UserSwitch, NameFile, ProgressBar },
  methods: {
    ...mapActions(["initAxiosInstance", 'setFabricLibLoaded']),
    ...mapActions("auth", ["autoLogoutUser"]),
    ...mapActions('translation', [ 'setTargetLang', 'showTranslation' ]),
    ...mapActions('util', [ 'requestSeverTime', 'toggleOpenAppointment', 'toggleCurrentUploadGroup' ]),
    handleOrientationChange() {
      const orientation = window.screen.orientation.type;
      if (orientation === "portrait-primary") {
        // portrait mode
      } else if (orientation === "landscape-primary") {
        // landscape mode
      }
    },
    uploadInputChange() {
      const input = this.$refs.uploadInput;
      const file = input.files[0];

      if (file) {
        const name = file.name;
        const lastDot = name.lastIndexOf('.');
        const fileName = name.substring(0, lastDot);
        const fileExtension = name.substring(lastDot + 1);
        this.uploadFileName = fileName;
        this.fileNameCallback = (newFileName, selectedUser) => {
          this.showNameFile = false;
          this.uploadFile(newFileName, fileExtension, selectedUser);
        };
        this.showNameFile = true;
      }
    },
    uploadFile(fileName, fileExtension, selectedUser) {
      const localAppointment = this.currentlyOpenAppointment;

      const input = this.$refs.uploadInput;
      const file = input.files[0];

      if (file) {
        const formData = new FormData();
        formData.append('file', file, `${fileName}.${fileExtension}`);

        if (this.currentUploadGroup === 'teacher') {
          console.log(selectedUser)
          formData.append('visibleFor', selectedUser);
          this.pupilXmlHttpRequest = backend.postTeacherUpload(localAppointment._id, file);
        } else if (this.currentUploadGroup === 'pupil') {
          this.pupilXmlHttpRequest = backend.postPupilUpload(localAppointment._id, file);
        } else if (this.currentUploadGroup === 'subject') {
          this.pupilXmlHttpRequest = backend.postSubjectFolderUpload(localAppointment._id, file);
        } else {
          // Default: private upload
          this.pupilXmlHttpRequest = backend.postPrivateUpload(file);
        }

        this.pupilXmlHttpRequest.onerror = (e) => {
          console.error('Pupil upload error:', e)
          this.showPupilUploadProgress = false;
          this.pupilXmlHttpRequest = null;
          this.$refs.uploadInput.value = '';
          this.toggleOpenAppointment(null);
          this.toggleCurrentUploadGroup(null);
        };

        this.pupilXmlHttpRequest.onabort = (e) => {
          console.warn('Pupil upload aborted');
          this.showPupilUploadProgress = false;
          this.pupilXmlHttpRequest = null;
          this.$refs.uploadInput.value = '';
          this.toggleOpenAppointment(null);
          this.toggleCurrentUploadGroup(null);
        }

        this.pupilXmlHttpRequest.upload.addEventListener('progress', (e) => {
          this.pupilUploadProgress = (e.loaded / e.total) * 100;
        });

        this.pupilXmlHttpRequest.addEventListener('load', (e) => {
          if (this.pupilXmlHttpRequest.status !== 201) {
            console.error('Pupil upload failed:', this.pupilXmlHttpRequest.response);
            if(this.pupilXmlHttpRequest.status === 409) {
              this.snackbarText = 'Speicher überschritten. Datei konnte nicht gespeichert werden!';
              this.snackbarPrivateFull = true;
            }
          }
          this.showPupilUploadProgress = false;
          this.snackbarSave = true;
          this.toggleOpenAppointment(null);
          this.toggleCurrentUploadGroup(null);
          this.pupilXmlHttpRequest = null;
          this.$refs.uploadInput.value = '';
        });
        this.pupilUploadProgress = 0.0;
        this.showPupilUploadProgress = true;
        this.pupilXmlHttpRequest.send(formData);
      }
    }
  },
  computed: {
    ...mapGetters('files', [ 'getSnackbars', 'getFileToBigSnackbarSize' ]),
    ...mapGetters('translation', [ 'getTranslatedText', 'isTranslationShown' ]),
    ...mapGetters('util', [ 'isLoadingShown', 'currentlyOpenAppointment', 'currentUploadGroup' ]),
    fileSnackbars() {
      return this.getSnackbars;
    },
    translatedText() {
      return this.getTranslatedText;
    },
    showTranslatedText() {
      return this.isTranslationShown;
    },
    showLoading() {
      return this.isLoadingShown;
    }
  },
  created() {
    this.requestSeverTime();
  },
  mounted() {
    window.addEventListener("orientationchange", this.handleOrientationChange);
    window.addEventListener('beforeunload', (event) => {
      this.autoLogoutUser();
    });
    this.setTargetLang('de');
  },
  data: () => ({
    transText: '',
    developmentMode: process.env.NODE_ENV === "development",
    showNameFile: false,
    uploadFileName: '',
    fileNameCallback: () => {},
    showPupilUploadProgress: false,
    pupilUploadProgress: 0.0,
    pupilXmlHttpRequest: null,
    snackbarPrivateFull: false,
    snackbarSave: false,
  }),
  watch: {
    isTranslationShown(value) {
      if (value) {
        setTimeout(() => {
          this.showTranslation(false);
          this.transText = '';
        }, 15000);
      }
      this.transText = this.translatedText;
    },
    getTranslatedText() {
      this.transText = this.getTranslatedText;
    },
    isLoadingShown(value) {
    }
  }
};
</script>


<style lang="scss">

  #translatedTextShow {
    position: fixed;
    bottom: 10px;
    right: 0;
    left: 0;
    margin-right: 10%;
    margin-left: 10%;
    min-width: 300px !important;
    height: 100px;
    border-radius: 15px;
    box-shadow: 1px 5px 5px silver;
  }

  @media only screen and (max-width: 500px), (max-width: 900px) and (orientation: portrait) {
      #translatedTextShow {
          bottom: 80px;
      }
  }

  #translatedTextText {
    text-align: center !important;
    font-size: large !important;
    color: darkgray !important;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4; /* number of lines to show */
    -webkit-box-orient: vertical;
  }

  .v-snack.v-snack--top {
    bottom: initial; /* or auto */
    width: 100vw !important;
  }

  .v-snack.v-snack--bottom {
    top: initial; /* or auto */
    width: 100vw !important;
  }

</style>
