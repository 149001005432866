import * as backend from '../../api/backend'
import {checkResponseStatus} from "@/util/check";

const actions = {
    async getBlackboard ({ commit, dispatch }, params) {
        try {
            const response = await backend.getBlackboard();
            return response.json();
        } catch (e) {
            return e.response.status;
        }
    },
    async createBlackboardEntry ({ commit, dispatch }, params) {
        try {
            const response = await backend.postBlackboard(params);
            await checkResponseStatus(201, response);
            const blackboard = await response.json();
            console.log("created blackboard entry");
            return blackboard;
        } catch (e) {
            return e.response.status;
        }
    },
    async updateBlackboardEntry ({ commit, dispatch }, params) {
        try {
            const id = params._id;
            delete params._id;
            const response = await backend.patchBlackboard(id, params);
            await checkResponseStatus(201, response);
            const blackboard = await response.json();
            console.log("updated blackboard entry");
            return blackboard;
        } catch (e) {
            return e.response.status;
        }
    },
    async deleteBlackboardEntry ({ commit, dispatch }, params) {
        try {
            const id = params;
            console.log(id);
            const response = await backend.deleteBlackboard(id);
            await checkResponseStatus(201, response);
            const blackboard = await response.json();
            console.log("deleted blackboard entry");
            return blackboard;
        } catch (e) {
            return e.response.status;
        }
    },

};

const mutations = {
/*    GET_BLACKBOARD: (state, blackboard) => {
        state.currentBlackboard = blackboard;
    },*/
};

const getters = {
/*    getBlackboard: state => state.blackboard,*/
};

const state = {
    blackboard: null,/*
    currentFileUrl: null,
    workedonFile: null,
    currentFileType: 'pdf',*/
};


export default {
    namespaced: true,
    actions,
    mutations,
    getters,
    state
}
