var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-footer",
    { staticClass: "footerContainer blueGradient", attrs: { absolute: "" } },
    [
      _c(
        "p",
        {
          staticClass: "text white--text ma-0",
          staticStyle: { "align-self": "center" }
        },
        [_vm._v("eKlassenraum.de - kinderleicht digitalisieren")]
      ),
      _c(
        "a",
        {
          staticClass: "link white--text ma-0",
          attrs: { href: "tel: +4971312641330" }
        },
        [_vm._v("+49 (0) 7131 - 264 133 0")]
      ),
      _c(
        "a",
        {
          staticClass: "link white--text ma-0",
          attrs: { href: "mailto: info@estundenplan.de" }
        },
        [_vm._v("info@estundenplan.de")]
      ),
      _c(
        "a",
        { staticClass: "link white--text", attrs: { href: "/datenschutz" } },
        [_vm._v("Datenschutz")]
      ),
      _c(
        "a",
        { staticClass: "link white--text", attrs: { href: "/impressum" } },
        [_vm._v("Impressum")]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }