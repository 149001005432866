var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("progress-bar", {
        attrs: {
          title: "Hochladen",
          "show-progress": _vm.showPrivateUploadProgress,
          progress: _vm.privateUploadProgress,
          "abort-progress": _vm.privateXmlHttpRequest
            ? function() {
                _vm.privateXmlHttpRequest.abort()
              }
            : function() {}
        }
      }),
      _c("progress-bar", {
        attrs: {
          title: "Hochladen",
          "show-progress": _vm.showSubjectUploadProgress,
          progress: _vm.subjectUploadProgress,
          "abort-progress": _vm.subjectXmlHttpRequest
            ? function() {
                _vm.subjectXmlHttpRequest.abort()
              }
            : function() {}
        }
      }),
      _c("name-file", {
        attrs: {
          "original-name": _vm.uploadFileName,
          callback: _vm.fileNameCallback
        },
        model: {
          value: _vm.showNameFile,
          callback: function($$v) {
            _vm.showNameFile = $$v
          },
          expression: "showNameFile"
        }
      }),
      _c("v-row", { staticClass: "mx-0", staticStyle: { width: "100%" } }, [
        _c(
          "div",
          {
            staticClass: "whiteBg",
            staticStyle: {
              height: "auto",
              width: "90%",
              "min-height": "80vh",
              "border-radius": "15px",
              "box-shadow": "1px 5px 5px silver",
              margin: "auto auto 5em"
            }
          },
          [
            _c(
              "div",
              {
                staticStyle: {
                  "background-color": "#F49F31",
                  "border-radius": "15px 15px 0 0",
                  "padding-top": "10px",
                  "padding-bottom": "10px",
                  color: "white"
                }
              },
              [
                _c(
                  "v-row",
                  {
                    staticClass: "pr-2 ml-2",
                    staticStyle: {
                      width: "90%",
                      display: "flex",
                      "align-items": "center",
                      height: "35px"
                    }
                  },
                  [
                    _c(
                      "v-col",
                      {
                        staticClass: "pa-0 pl-2 d-flex align-center",
                        staticStyle: { "font-size": "larger" },
                        attrs: { cols: "9", md: "8", lg: "9" }
                      },
                      [
                        !_vm.fach && !_vm.day && !_vm.privateFolder
                          ? _c("img", {
                              staticClass: "iconToWhite mr-1",
                              staticStyle: { height: "20px" },
                              attrs: { src: _vm.fileIcon }
                            })
                          : _c(
                              "v-icon",
                              {
                                staticClass: "iconToWhite mr-1",
                                staticStyle: { height: "20px", width: "20px" },
                                attrs: { size: 20, color: "white" },
                                on: {
                                  click: function($event) {
                                    _vm.privateFolder
                                      ? (_vm.privateFolder = false)
                                      : _vm.subjectFolder
                                      ? (_vm.subjectFolder = false)
                                      : _vm.day
                                      ? (_vm.day = null)
                                      : (_vm.fach = null)
                                  }
                                }
                              },
                              [_vm._v("mdi-arrow-left")]
                            ),
                        _vm._v(" Dateien ")
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            !_vm.privateFolder
              ? _c(
                  "div",
                  {
                    staticClass: "pa-3 my-1 mx-auto d-flex",
                    style:
                      "position: relative; border-radius: 10px; color: #707070; box-shadow: 1px 2px 3px silver; width: 90%; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; background-color: #FFF; cursor: pointer",
                    on: {
                      click: function($event) {
                        _vm.privateFolder = true
                      }
                    }
                  },
                  [
                    _c("img", {
                      staticClass: "iconSelect",
                      staticStyle: { "background-color": "#F49F31" },
                      attrs: { src: _vm.schlossIcon }
                    }),
                    _c(
                      "div",
                      { staticClass: "pl-1", staticStyle: { height: "24px" } },
                      [_vm._v("Privater Ordner")]
                    )
                  ]
                )
              : _vm._e(),
            !_vm.privateFolder
              ? _c(
                  "div",
                  {
                    staticClass: "pt-2 grey--text font-weight-bold",
                    staticStyle: { "margin-left": "5%" }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "inline-flex",
                          cursor: "pointer"
                        },
                        on: {
                          click: function($event) {
                            _vm.fach = null
                            _vm.day = null
                            _vm.subjectFolder = false
                          }
                        }
                      },
                      [_vm._v(" Fächer/ ")]
                    ),
                    _vm.fach
                      ? _c(
                          "div",
                          {
                            staticStyle: {
                              display: "inline-flex",
                              cursor: "pointer"
                            },
                            on: {
                              click: function($event) {
                                _vm.day = null
                              }
                            }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.fach[0].schoolSubject.name) +
                                "/ "
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm.day
                      ? _c(
                          "div",
                          {
                            staticStyle: {
                              display: "inline-flex",
                              cursor: "pointer"
                            }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  new Date(_vm.day[0].day).toLocaleDateString()
                                ) +
                                " "
                            )
                          ]
                        )
                      : _vm._e()
                  ]
                )
              : _vm._e(),
            _c(
              "vue-custom-scrollbar",
              { staticClass: "scroll-area", attrs: { settings: _vm.settings } },
              [
                !_vm.fach && !_vm.privateFolder
                  ? _c(
                      "div",
                      _vm._l(_vm.schoolSubjectGroups, function(el, index) {
                        return _c(
                          "div",
                          { key: JSON.stringify(el) + index },
                          [
                            _c(
                              "v-badge",
                              {
                                staticStyle: { "z-index": "8", width: "100%" },
                                attrs: {
                                  color: "red",
                                  content: _vm.count(el),
                                  value: _vm.count(el),
                                  overlap: "",
                                  right: "",
                                  "offset-x": "40",
                                  "offset-y": "20"
                                }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "pa-3 my-1 mx-auto d-flex",
                                    style:
                                      "position: relative; border-radius: 10px; color: #707070; box-shadow: 1px 2px 3px silver; width: 90%; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; background-color: #FFF; cursor: pointer",
                                    on: {
                                      click: function($event) {
                                        return _vm.selectSubject(el)
                                      }
                                    }
                                  },
                                  [
                                    _vm.getSrc(el[0].schoolSubject.icon)
                                      ? _c("img", {
                                          staticClass: "iconSelect",
                                          style: {
                                            "background-color":
                                              el[0].schoolSubject.color
                                          },
                                          attrs: {
                                            src: _vm.getSrc(
                                              el[0].schoolSubject.icon
                                            )
                                          }
                                        })
                                      : _vm._e(),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "pl-1 d-flex align-center",
                                        staticStyle: { height: "24px" }
                                      },
                                      [_vm._v(_vm._s(el[0].schoolSubject.name))]
                                    )
                                  ]
                                )
                              ]
                            )
                          ],
                          1
                        )
                      }),
                      0
                    )
                  : !_vm.privateFolder
                  ? _c("div", [
                      !_vm.day && !_vm.subjectFolder
                        ? _c(
                            "div",
                            [
                              _vm.account.role === "pupil"
                                ? _c(
                                    "div",
                                    _vm._l(_vm.getFachByTeacher(), function(
                                      el
                                    ) {
                                      return _c(
                                        "div",
                                        {
                                          key: JSON.stringify(el),
                                          staticClass:
                                            "pa-3 my-1 mx-auto d-flex",
                                          style:
                                            "position: relative; border-radius: 10px; color: #707070; box-shadow: 1px 2px 3px silver; width: 90%; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; background-color: #FFF; cursor: pointer",
                                          on: {
                                            click: function($event) {
                                              return _vm.selectSubjectFolder(
                                                el[0].teacher._id
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _vm.getSrc(
                                            _vm.fach[0].schoolSubject.icon
                                          )
                                            ? _c("img", {
                                                staticClass: "iconSelect",
                                                style: {
                                                  "background-color":
                                                    _vm.fach[0].schoolSubject
                                                      .color
                                                },
                                                attrs: {
                                                  src: _vm.getSrc(
                                                    _vm.fach[0].schoolSubject
                                                      .icon
                                                  )
                                                }
                                              })
                                            : _vm._e(),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "pl-1",
                                              staticStyle: { height: "24px" }
                                            },
                                            [
                                              _vm._v(
                                                "Allgemein " +
                                                  _vm._s(
                                                    _vm.getTeacherDisplayName(
                                                      el[0].teacher
                                                    )
                                                  )
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    }),
                                    0
                                  )
                                : _c(
                                    "div",
                                    _vm._l(_vm.getFachByTeacher(), function(
                                      el
                                    ) {
                                      return _c(
                                        "div",
                                        {
                                          key: JSON.stringify(el),
                                          staticClass:
                                            "pa-3 my-1 mx-auto d-flex",
                                          style:
                                            "position: relative; border-radius: 10px; color: #707070; box-shadow: 1px 2px 3px silver; width: 90%; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; background-color: #FFF; cursor: pointer",
                                          on: {
                                            click: function($event) {
                                              return _vm.selectSubjectFolder(
                                                el[0].teacher._id
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _vm.getSrc(
                                            _vm.fach[0].schoolSubject.icon
                                          )
                                            ? _c("img", {
                                                staticClass: "iconSelect",
                                                style: {
                                                  "background-color":
                                                    _vm.fach[0].schoolSubject
                                                      .color
                                                },
                                                attrs: {
                                                  src: _vm.getSrc(
                                                    _vm.fach[0].schoolSubject
                                                      .icon
                                                  )
                                                }
                                              })
                                            : _vm._e(),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "pl-1",
                                              staticStyle: { height: "24px" }
                                            },
                                            [_vm._v("Allgemein")]
                                          )
                                        ]
                                      )
                                    }),
                                    0
                                  ),
                              _vm._l(_vm.subjectDateGroups, function(
                                el,
                                index
                              ) {
                                return _c(
                                  "div",
                                  { key: JSON.stringify(el) + index },
                                  [
                                    _c(
                                      "v-badge",
                                      {
                                        staticStyle: {
                                          "z-index": "8",
                                          width: "100%"
                                        },
                                        attrs: {
                                          color: "red",
                                          content: _vm.count(el),
                                          value: _vm.count(el),
                                          overlap: "",
                                          right: "",
                                          "offset-x": "40",
                                          "offset-y": "20"
                                        }
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "pa-3 my-1 mx-auto d-flex",
                                            style:
                                              "position: relative; border-radius: 10px; color: #707070; box-shadow: 1px 2px 3px silver; width: 90%; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; background-color: #FFF; cursor: pointer",
                                            on: {
                                              click: function($event) {
                                                return _vm.selectDay(el)
                                              }
                                            }
                                          },
                                          [
                                            _vm.getSrc(
                                              _vm.fach[0].schoolSubject.icon
                                            )
                                              ? _c("img", {
                                                  staticClass: "iconSelect",
                                                  style: {
                                                    "background-color":
                                                      _vm.fach[0].schoolSubject
                                                        .color
                                                  },
                                                  attrs: {
                                                    src: _vm.getSrc(
                                                      _vm.fach[0].schoolSubject
                                                        .icon
                                                    )
                                                  }
                                                })
                                              : _vm._e(),
                                            _vm.account.role === "pupil"
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "pl-1 d-flex align-center",
                                                    staticStyle: {
                                                      height: "24px"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        new Date(
                                                          el[0].day
                                                        ).toLocaleDateString() +
                                                          " " +
                                                          _vm.getTeacherDisplayName(
                                                            el[0].teacher
                                                          )
                                                      )
                                                    )
                                                  ]
                                                )
                                              : _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "pl-1 d-flex align-center",
                                                    staticStyle: {
                                                      height: "24px"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        new Date(
                                                          el[0].day
                                                        ).toLocaleDateString() +
                                                          " " +
                                                          _vm.getSlotTime(
                                                            el[0].timeslot
                                                          )
                                                      )
                                                    )
                                                  ]
                                                )
                                          ]
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              })
                            ],
                            2
                          )
                        : !_vm.subjectFolder
                        ? _c(
                            "div",
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "pt-2 font-weight-bold greyText",
                                  staticStyle: { "margin-left": "5%" }
                                },
                                [_vm._v(" Material: ")]
                              ),
                              _vm._l(_vm.teacherUploads, function(el, index) {
                                return _c(
                                  "div",
                                  { key: JSON.stringify(el) + index },
                                  [
                                    _c(
                                      "v-badge",
                                      {
                                        staticStyle: {
                                          "z-index": "8",
                                          width: "100%"
                                        },
                                        attrs: {
                                          color: "red",
                                          content: "1",
                                          value:
                                            !el.seenByMe &&
                                            _vm.account.role == "pupil",
                                          overlap: "",
                                          "offset-x": "40",
                                          "offset-y": "20"
                                        }
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "pa-3 my-1 mx-auto",
                                            style:
                                              "display:flex; position: relative; border-radius: 10px; color: #707070; box-shadow: 1px 2px 3px silver; width: 90%; white-space: nowrap; background-color: #FFF; "
                                          },
                                          [
                                            _vm.getSrc(
                                              _vm.fach[0].schoolSubject.icon
                                            )
                                              ? _c("img", {
                                                  staticClass: "iconSelect",
                                                  style:
                                                    "cursor: pointer; background-color:" +
                                                    _vm.fach[0].schoolSubject
                                                      .color,
                                                  attrs: {
                                                    src: _vm.getSrc(
                                                      _vm.fach[0].schoolSubject
                                                        .icon
                                                    )
                                                  },
                                                  on: {
                                                    click: function() {
                                                      return _vm.clickDownloadTeacherUpload(
                                                        el
                                                      )
                                                    }
                                                  }
                                                })
                                              : _vm._e(),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "align-center pl-1 materialTitle",
                                                on: {
                                                  click: function() {
                                                    return _vm.clickDownloadTeacherUpload(
                                                      el
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  " " + _vm._s(el.title) + " "
                                                )
                                              ]
                                            ),
                                            _vm.account.role == "pupil"
                                              ? _c(
                                                  "div",
                                                  [
                                                    [
                                                      "mp4",
                                                      "wmv",
                                                      "flv",
                                                      "webm",
                                                      "mpg",
                                                      "m4v"
                                                    ].includes(
                                                      el.title.split(".").pop()
                                                    )
                                                      ? _c(
                                                          "v-btn",
                                                          {
                                                            staticClass:
                                                              "ml-2 pa-0",
                                                            staticStyle: {
                                                              height: "24px"
                                                            },
                                                            attrs: {
                                                              "x-small": "",
                                                              elevation: "0"
                                                            },
                                                            on: {
                                                              click: function() {
                                                                return _vm.play(
                                                                  el
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _c("img", {
                                                              staticStyle: {
                                                                height: "20px"
                                                              },
                                                              attrs: {
                                                                src:
                                                                  _vm.rechtsIcon
                                                              }
                                                            })
                                                          ]
                                                        )
                                                      : [
                                                          "doc",
                                                          "docx",
                                                          "ppt",
                                                          "pptx",
                                                          "xls",
                                                          "xlsx"
                                                        ].includes(
                                                          el.title
                                                            .split(".")
                                                            .pop()
                                                            .toLowerCase()
                                                        )
                                                      ? _c(
                                                          "v-btn",
                                                          {
                                                            staticClass:
                                                              "btnStyle",
                                                            staticStyle: {
                                                              "background-color":
                                                                "#8CBD46"
                                                            },
                                                            attrs: {
                                                              dark: "",
                                                              "x-small": "",
                                                              elevation: "0"
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.fileNotSupported(
                                                                  el
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _c("img", {
                                                              staticStyle: {
                                                                height: "20px",
                                                                filter:
                                                                  "brightness(1000%)"
                                                              },
                                                              attrs: {
                                                                src:
                                                                  _vm.infoIcon
                                                              }
                                                            })
                                                          ]
                                                        )
                                                      : _c(
                                                          "v-btn",
                                                          {
                                                            staticClass:
                                                              "ml-2 pa-0",
                                                            staticStyle: {
                                                              height: "24px"
                                                            },
                                                            attrs: {
                                                              "x-small": "",
                                                              elevation: "0"
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.openEdit(
                                                                  el.title,
                                                                  el.file,
                                                                  el._id,
                                                                  el.appointment
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _c("img", {
                                                              staticStyle: {
                                                                height: "20px"
                                                              },
                                                              attrs: {
                                                                src:
                                                                  _vm.bearbeitenIcon
                                                              }
                                                            })
                                                          ]
                                                        )
                                                  ],
                                                  1
                                                )
                                              : _vm.account.role == "teacher"
                                              ? _c(
                                                  "div",
                                                  [
                                                    [
                                                      "doc",
                                                      "docx",
                                                      "ppt",
                                                      "pptx",
                                                      "xls",
                                                      "xlsx"
                                                    ].includes(
                                                      el.title
                                                        .split(".")
                                                        .pop()
                                                        .toLowerCase()
                                                    )
                                                      ? _c(
                                                          "v-btn",
                                                          {
                                                            staticClass:
                                                              "btnStyle",
                                                            staticStyle: {
                                                              "background-color":
                                                                "#8CBD46"
                                                            },
                                                            attrs: {
                                                              dark: "",
                                                              "x-small": "",
                                                              elevation: "0"
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.fileNotSupported(
                                                                  el
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _c("img", {
                                                              staticStyle: {
                                                                height: "20px",
                                                                filter:
                                                                  "brightness(1000%)"
                                                              },
                                                              attrs: {
                                                                src:
                                                                  _vm.infoIcon
                                                              }
                                                            })
                                                          ]
                                                        )
                                                      : _c(
                                                          "v-btn",
                                                          {
                                                            attrs: {
                                                              "x-small": "",
                                                              elevation: "0"
                                                            },
                                                            on: {
                                                              click: function(
                                                                event
                                                              ) {
                                                                event.stopPropagation()
                                                                _vm.openAnsicht(
                                                                  el.title,
                                                                  el.file,
                                                                  el._id,
                                                                  el.appointment,
                                                                  "pupil"
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                staticStyle: {
                                                                  width: "20px",
                                                                  color:
                                                                    "#939393"
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "fas fa-eye"
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        ),
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        staticClass:
                                                          "ml-2 pa-0",
                                                        staticStyle: {
                                                          height: "24px"
                                                        },
                                                        attrs: {
                                                          "x-small": "",
                                                          elevation: "0"
                                                        },
                                                        on: {
                                                          click: function() {
                                                            return _vm.clickDeleteTeacherUpload(
                                                              el
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c("img", {
                                                          staticStyle: {
                                                            height: "20px"
                                                          },
                                                          attrs: {
                                                            src:
                                                              _vm.papierkorbIcon
                                                          }
                                                        })
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _c(
                                              "div",
                                              [
                                                _c(
                                                  "v-btn",
                                                  {
                                                    staticClass: "ml-2 pa-0",
                                                    staticStyle: {
                                                      height: "24px"
                                                    },
                                                    attrs: {
                                                      "x-small": "",
                                                      elevation: "0"
                                                    },
                                                    on: {
                                                      click: function() {
                                                        return _vm.clickDownloadTeacherUpload(
                                                          el
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c("img", {
                                                      staticStyle: {
                                                        height: "20px"
                                                      },
                                                      attrs: {
                                                        src: _vm.runterladenIcon
                                                      }
                                                    })
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              }),
                              _vm.account.role == "teacher"
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "pt-2 font-weight-bold greyText",
                                          staticStyle: { "margin-left": "5%" }
                                        },
                                        [_vm._v(" Korrigiert: ")]
                                      ),
                                      _vm._l(
                                        _vm.pupileUploads.filter(function(
                                          element
                                        ) {
                                          return _vm.isCorrectionAvailable(
                                            element
                                          )
                                        }),
                                        function(el, index) {
                                          return _c(
                                            "div",
                                            { key: JSON.stringify(el) + index },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "pa-3 my-1 mx-auto",
                                                  style:
                                                    "display:flex; position: relative; border-radius: 10px; color: #707070; box-shadow: 1px 2px 3px silver; width: 90%; white-space: nowrap; background-color: #FFF; "
                                                },
                                                [
                                                  _vm.getSrc(
                                                    _vm.fach[0].schoolSubject
                                                      .icon
                                                  )
                                                    ? _c("img", {
                                                        staticClass:
                                                          "iconSelect",
                                                        style:
                                                          "cursor: pointer;background-color:" +
                                                          _vm.fach[0]
                                                            .schoolSubject
                                                            .color,
                                                        attrs: {
                                                          src: _vm.getSrc(
                                                            _vm.fach[0]
                                                              .schoolSubject
                                                              .icon
                                                          )
                                                        },
                                                        on: {
                                                          click: function() {
                                                            return _vm.clickDownloadPupilCorrection(
                                                              el
                                                            )
                                                          }
                                                        }
                                                      })
                                                    : _vm._e(),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "pl-1",
                                                      style:
                                                        "cursor: pointer;width: 90%; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; ",
                                                      on: {
                                                        click: function() {
                                                          return _vm.clickDownloadPupilCorrection(
                                                            el
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(el.title) +
                                                          " "
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    [
                                                      [
                                                        "doc",
                                                        "docx",
                                                        "ppt",
                                                        "pptx",
                                                        "xls",
                                                        "xlsx"
                                                      ].includes(
                                                        el.title
                                                          .split(".")
                                                          .pop()
                                                          .toLowerCase()
                                                      )
                                                        ? _c(
                                                            "v-btn",
                                                            {
                                                              staticClass:
                                                                "btnStyle",
                                                              staticStyle: {
                                                                "background-color":
                                                                  "#8CBD46"
                                                              },
                                                              attrs: {
                                                                dark: "",
                                                                "x-small": "",
                                                                elevation: "0"
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.fileNotSupported(
                                                                    el
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _c("img", {
                                                                staticStyle: {
                                                                  height:
                                                                    "20px",
                                                                  filter:
                                                                    "brightness(1000%)"
                                                                },
                                                                attrs: {
                                                                  src:
                                                                    _vm.infoIcon
                                                                }
                                                              })
                                                            ]
                                                          )
                                                        : _c(
                                                            "v-btn",
                                                            {
                                                              staticClass:
                                                                "ml-2 pa-0",
                                                              staticStyle: {
                                                                height: "24px"
                                                              },
                                                              attrs: {
                                                                "x-small": "",
                                                                elevation: "0"
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.openEditTeacher(
                                                                    el.title,
                                                                    el.file,
                                                                    el._id,
                                                                    el.appointment
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _c("img", {
                                                                staticStyle: {
                                                                  height: "20px"
                                                                },
                                                                attrs: {
                                                                  src:
                                                                    _vm.bearbeitenIcon
                                                                }
                                                              })
                                                            ]
                                                          )
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "div",
                                                    [
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          staticClass:
                                                            "ml-2 pa-0",
                                                          staticStyle: {
                                                            width: "20px",
                                                            height: "24px"
                                                          },
                                                          attrs: {
                                                            elevation: "0",
                                                            "x-small": ""
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.clickDownloadPupilCorrection(
                                                                el
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c("img", {
                                                            staticStyle: {
                                                              height: "20px"
                                                            },
                                                            attrs: {
                                                              src:
                                                                _vm.bearbeitetIcon
                                                            }
                                                          })
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        }
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "pt-2 font-weight-bold greyText",
                                          staticStyle: { "margin-left": "5%" }
                                        },
                                        [_vm._v(" Unkorrigiert: ")]
                                      ),
                                      _vm._l(
                                        _vm.pupileUploads.filter(function(
                                          element
                                        ) {
                                          return !_vm.isCorrectionAvailable(
                                            element
                                          )
                                        }),
                                        function(el, index) {
                                          return _c(
                                            "div",
                                            { key: JSON.stringify(el) + index },
                                            [
                                              _c(
                                                "v-badge",
                                                {
                                                  staticStyle: {
                                                    "z-index": "8",
                                                    width: "100%"
                                                  },
                                                  attrs: {
                                                    color: "red",
                                                    content: "1",
                                                    value: !el.seenByMe,
                                                    overlap: "",
                                                    "offset-x": "40",
                                                    "offset-y": "20"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "pa-3 my-1 mx-auto",
                                                      style:
                                                        "display:flex; position: relative; border-radius: 10px; color: #707070; box-shadow: 1px 2px 3px silver; width: 90%; white-space: nowrap; background-color: #FFF; "
                                                    },
                                                    [
                                                      _vm.getSrc(
                                                        _vm.fach[0]
                                                          .schoolSubject.icon
                                                      )
                                                        ? _c("img", {
                                                            staticClass:
                                                              "iconSelect",
                                                            style:
                                                              "cursor:pointer; background-color:" +
                                                              _vm.fach[0]
                                                                .schoolSubject
                                                                .color,
                                                            attrs: {
                                                              src: _vm.getSrc(
                                                                _vm.fach[0]
                                                                  .schoolSubject
                                                                  .icon
                                                              )
                                                            },
                                                            on: {
                                                              click: function() {
                                                                return _vm.clickDownloadPupilUpload(
                                                                  el
                                                                )
                                                              }
                                                            }
                                                          })
                                                        : _vm._e(),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex align-center pl-1",
                                                          staticStyle: {
                                                            height: "24px"
                                                          },
                                                          style:
                                                            "cursor:pointer; width: 90%; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; ",
                                                          on: {
                                                            click: function() {
                                                              return _vm.clickDownloadPupilUpload(
                                                                el
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(el.title) +
                                                              " "
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        [
                                                          [
                                                            "doc",
                                                            "docx",
                                                            "ppt",
                                                            "pptx",
                                                            "xls",
                                                            "xlsx"
                                                          ].includes(
                                                            el.title
                                                              .split(".")
                                                              .pop()
                                                              .toLowerCase()
                                                          )
                                                            ? _c(
                                                                "v-btn",
                                                                {
                                                                  staticClass:
                                                                    "btnStyle",
                                                                  staticStyle: {
                                                                    "background-color":
                                                                      "#8CBD46"
                                                                  },
                                                                  attrs: {
                                                                    dark: "",
                                                                    "x-small":
                                                                      "",
                                                                    elevation:
                                                                      "0"
                                                                  },
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.fileNotSupported(
                                                                        el
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                [
                                                                  _c("img", {
                                                                    staticStyle: {
                                                                      height:
                                                                        "20px",
                                                                      filter:
                                                                        "brightness(1000%)"
                                                                    },
                                                                    attrs: {
                                                                      src:
                                                                        _vm.infoIcon
                                                                    }
                                                                  })
                                                                ]
                                                              )
                                                            : _c(
                                                                "v-btn",
                                                                {
                                                                  staticClass:
                                                                    "ml-2 pa-0",
                                                                  staticStyle: {
                                                                    height:
                                                                      "24px"
                                                                  },
                                                                  attrs: {
                                                                    "x-small":
                                                                      "",
                                                                    elevation:
                                                                      "0"
                                                                  },
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.openEditTeacher(
                                                                        el.title,
                                                                        el.file,
                                                                        el._id,
                                                                        el.appointment
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                [
                                                                  _c("img", {
                                                                    staticStyle: {
                                                                      height:
                                                                        "20px"
                                                                    },
                                                                    attrs: {
                                                                      src:
                                                                        _vm.bearbeitenIcon
                                                                    }
                                                                  })
                                                                ]
                                                              )
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "div",
                                                        [
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              staticClass:
                                                                "ml-2 pa-0",
                                                              staticStyle: {
                                                                width: "20px",
                                                                height: "24px"
                                                              },
                                                              attrs: {
                                                                "x-small": "",
                                                                elevation: "0"
                                                              }
                                                            },
                                                            [
                                                              _c("img", {
                                                                staticStyle: {
                                                                  height: "20px"
                                                                },
                                                                attrs: {
                                                                  src:
                                                                    _vm.offenIcon
                                                                }
                                                              })
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        }
                                      )
                                    ],
                                    2
                                  )
                                : _c(
                                    "div",
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "pt-2 font-weight-bold greyText",
                                          staticStyle: { "margin-left": "5%" }
                                        },
                                        [_vm._v(" Deine Abgaben: ")]
                                      ),
                                      _vm._l(_vm.pupileUploads, function(
                                        el,
                                        index
                                      ) {
                                        return _c(
                                          "div",
                                          { key: JSON.stringify(el) + index },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "pa-3 my-3 mx-auto",
                                                style:
                                                  "display:flex; position: relative; border-radius: 10px; color: #707070; box-shadow: 1px 2px 3px silver; width: 90%; white-space: nowrap; background-color: #FFF; "
                                              },
                                              [
                                                _vm.getSrc(
                                                  _vm.fach[0].schoolSubject.icon
                                                )
                                                  ? _c("img", {
                                                      staticClass: "iconSelect",
                                                      style:
                                                        "cursor:pointer; background-color:" +
                                                        _vm.fach[0]
                                                          .schoolSubject.color,
                                                      attrs: {
                                                        src: _vm.getSrc(
                                                          _vm.fach[0]
                                                            .schoolSubject.icon
                                                        )
                                                      },
                                                      on: {
                                                        click: function() {
                                                          return _vm.clickDownloadPupilUpload(
                                                            el
                                                          )
                                                        }
                                                      }
                                                    })
                                                  : _vm._e(),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "pl-1",
                                                    style:
                                                      "cursor:pointer; width: 90%; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; ",
                                                    on: {
                                                      click: function() {
                                                        return _vm.clickDownloadPupilUpload(
                                                          el
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(el.title) +
                                                        " "
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      display: "inline-block"
                                                    }
                                                  },
                                                  [
                                                    [
                                                      "doc",
                                                      "docx",
                                                      "ppt",
                                                      "pptx",
                                                      "xls",
                                                      "xlsx"
                                                    ].includes(
                                                      el.title
                                                        .split(".")
                                                        .pop()
                                                        .toLowerCase()
                                                    )
                                                      ? _c(
                                                          "v-btn",
                                                          {
                                                            staticClass:
                                                              "btnStyle",
                                                            staticStyle: {
                                                              "background-color":
                                                                "#8CBD46"
                                                            },
                                                            attrs: {
                                                              dark: "",
                                                              "x-small": "",
                                                              elevation: "0"
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.fileNotSupported(
                                                                  el
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _c("img", {
                                                              staticStyle: {
                                                                height: "20px",
                                                                filter:
                                                                  "brightness(1000%)"
                                                              },
                                                              attrs: {
                                                                src:
                                                                  _vm.infoIcon
                                                              }
                                                            })
                                                          ]
                                                        )
                                                      : _c(
                                                          "v-btn",
                                                          {
                                                            staticClass:
                                                              "btnStyle",
                                                            attrs: {
                                                              "x-small": "",
                                                              elevation: "0",
                                                              color:
                                                                "rgba(0,0,0,0)"
                                                            },
                                                            on: {
                                                              click: function(
                                                                event
                                                              ) {
                                                                event.stopPropagation()
                                                                _vm.openAnsicht(
                                                                  el.title,
                                                                  el.file,
                                                                  el._id,
                                                                  el.appointment,
                                                                  "teacher"
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                staticStyle: {
                                                                  width: "20px",
                                                                  color:
                                                                    "#939393"
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "fas fa-eye"
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                  ],
                                                  1
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      })
                                    ],
                                    2
                                  )
                            ],
                            2
                          )
                        : _c("div", [
                            _c("input", {
                              ref: "subjectUploadInput",
                              attrs: {
                                id: "subjectUploadInput",
                                type: "file",
                                hidden: ""
                              },
                              on: {
                                change: function() {
                                  return _vm.subjectUploadInputChange()
                                }
                              }
                            }),
                            _vm.account.role == "teacher"
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "d-flex",
                                    staticStyle: { "justify-content": "center" }
                                  },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "text-capitalize",
                                        staticStyle: {
                                          "padding-left": "50px",
                                          "padding-right": "50px",
                                          color: "#707070",
                                          "border-radius": "10px"
                                        },
                                        attrs: { elevation: "0" },
                                        on: {
                                          click: _vm.clickSubjectUploadButton
                                        }
                                      },
                                      [
                                        _c("img", {
                                          staticClass: "mr-2",
                                          staticStyle: { height: "20px" },
                                          attrs: { src: _vm.hochladenIcon }
                                        }),
                                        _vm._v(" Hochladen ")
                                      ]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.subjectFiles.length > 0
                              ? _c(
                                  "div",
                                  _vm._l(_vm.subjectFiles, function(el, index) {
                                    return _c(
                                      "div",
                                      { key: JSON.stringify(el) + index },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "pa-3 my-3 mx-auto",
                                            style:
                                              "display:flex; position: relative; border-radius: 10px; color: #707070; box-shadow: 1px 2px 3px silver; width: 90%; white-space: nowrap; background-color: #FFF; "
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "d-flex align-center",
                                                staticStyle: { height: "24px" },
                                                style:
                                                  "cursor: pointer; width: 90%; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; ",
                                                on: {
                                                  click: function() {
                                                    return _vm.clickDownloadSubjectUpload(
                                                      el
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(el.uploadedName) +
                                                    " "
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticStyle: { height: "24px" }
                                              },
                                              [
                                                _vm.account.role == "teacher"
                                                  ? _c(
                                                      "v-btn",
                                                      {
                                                        staticClass:
                                                          "ml-2 pa-0",
                                                        staticStyle: {
                                                          width: "20px",
                                                          height: "24px"
                                                        },
                                                        attrs: {
                                                          "x-small": "",
                                                          elevation: "0"
                                                        },
                                                        on: {
                                                          click: function() {
                                                            return _vm.clickDeleteSubjectUpload(
                                                              el
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c("img", {
                                                          staticStyle: {
                                                            height: "20px"
                                                          },
                                                          attrs: {
                                                            src:
                                                              _vm.papierkorbIcon
                                                          }
                                                        })
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _c(
                                                  "v-btn",
                                                  {
                                                    staticClass: "ml-2 pa-0",
                                                    staticStyle: {
                                                      width: "20px",
                                                      height: "24px"
                                                    },
                                                    attrs: {
                                                      "x-small": "",
                                                      elevation: "0"
                                                    },
                                                    on: {
                                                      click: function() {
                                                        return _vm.clickDownloadSubjectUpload(
                                                          el
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c("img", {
                                                      staticStyle: {
                                                        height: "20px"
                                                      },
                                                      attrs: {
                                                        src: _vm.runterladenIcon
                                                      }
                                                    })
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  }),
                                  0
                                )
                              : _c(
                                  "div",
                                  {
                                    staticClass: "px-4 my-3 mx-auto",
                                    staticStyle: { color: "#737373" }
                                  },
                                  [_vm._v(" Hier gibts noch keine Dateien ")]
                                )
                          ])
                    ])
                  : _c(
                      "div",
                      { staticClass: "my-3" },
                      [
                        _c("input", {
                          ref: "privateUploadInput",
                          attrs: {
                            id: "privateUploadInput",
                            type: "file",
                            hidden: ""
                          },
                          on: {
                            change: function() {
                              return _vm.privateUploadInputChange()
                            }
                          }
                        }),
                        _c(
                          "div",
                          { staticClass: "d-flex justify-center" },
                          [
                            _c(
                              "v-progress-linear",
                              {
                                staticClass: "mx-3 my-3",
                                staticStyle: {
                                  width: "90%",
                                  "border-radius": "10px"
                                },
                                attrs: {
                                  id: "progressbar",
                                  value: _vm.privateFileProgress,
                                  height: "20px",
                                  color: "primary"
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      Math.ceil(
                                        _vm.privateFileProgress *
                                          (_vm.account.role == "teacher"
                                            ? 10
                                            : 1)
                                      )
                                    ) +
                                    "MiB / " +
                                    _vm._s(
                                      _vm.account.role == "teacher"
                                        ? "1000MiB"
                                        : "100MiB"
                                    ) +
                                    " "
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "d-flex",
                            staticStyle: { "justify-content": "center" }
                          },
                          [
                            _c(
                              "v-btn",
                              {
                                staticClass: "text-capitalize",
                                staticStyle: {
                                  "padding-left": "50px",
                                  "padding-right": "50px",
                                  color: "#707070",
                                  "border-radius": "10px"
                                },
                                attrs: { elevation: "0" },
                                on: { click: _vm.clickPrivateUploadButton }
                              },
                              [
                                _c("img", {
                                  staticClass: "mr-2",
                                  staticStyle: { height: "20px" },
                                  attrs: { src: _vm.hochladenIcon }
                                }),
                                _vm._v(" Hochladen ")
                              ]
                            )
                          ],
                          1
                        ),
                        _vm._l(_vm.privateFiles, function(el, index) {
                          return _c(
                            "div",
                            { key: JSON.stringify(el) + index },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "pa-3 my-3 mx-auto",
                                  style:
                                    "display:flex; position: relative; border-radius: 10px; color: #707070; box-shadow: 1px 2px 3px silver; width: 90%; white-space: nowrap; background-color: #FFF; "
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      style:
                                        "cursor: pointer; width: 90%; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; ",
                                      on: {
                                        click: function() {
                                          return _vm.clickDownloadPrivateUpload(
                                            el
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(el.uploadedName) + " "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    [
                                      [
                                        "doc",
                                        "docx",
                                        "ppt",
                                        "pptx",
                                        "xls",
                                        "xlsx"
                                      ].includes(
                                        el.uploadedName
                                          .split(".")
                                          .pop()
                                          .toLowerCase()
                                      )
                                        ? _c(
                                            "v-btn",
                                            {
                                              staticClass: "btnStyle",
                                              staticStyle: {
                                                "background-color": "#8CBD46"
                                              },
                                              attrs: {
                                                dark: "",
                                                "x-small": "",
                                                elevation: "0"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.fileNotSupported(
                                                    el
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c("img", {
                                                staticStyle: {
                                                  height: "20px",
                                                  filter: "brightness(1000%)"
                                                },
                                                attrs: { src: _vm.infoIcon }
                                              })
                                            ]
                                          )
                                        : _c(
                                            "v-btn",
                                            {
                                              staticClass: "btnStyle",
                                              attrs: {
                                                "x-small": "",
                                                elevation: "0",
                                                color: "rgba(0,0,0,0)"
                                              },
                                              on: {
                                                click: function(event) {
                                                  event.stopPropagation()
                                                  _vm.openPrivateAnsicht(el)
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  staticStyle: {
                                                    width: "20px",
                                                    color: "#939393"
                                                  }
                                                },
                                                [_vm._v("fas fa-eye")]
                                              )
                                            ],
                                            1
                                          ),
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "ml-2 pa-0",
                                          staticStyle: {
                                            width: "20px",
                                            height: "20px"
                                          },
                                          attrs: {
                                            "x-small": "",
                                            elevation: "0"
                                          },
                                          on: {
                                            click: function() {
                                              return _vm.clickDeletePrivateUpload(
                                                el
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("img", {
                                            staticStyle: { height: "20px" },
                                            attrs: { src: _vm.papierkorbIcon }
                                          })
                                        ]
                                      ),
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "ml-2 pa-0",
                                          staticStyle: {
                                            width: "20px",
                                            height: "20px"
                                          },
                                          attrs: {
                                            "x-small": "",
                                            elevation: "0"
                                          },
                                          on: {
                                            click: function() {
                                              return _vm.clickDownloadPrivateUpload(
                                                el
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("img", {
                                            staticStyle: { height: "20px" },
                                            attrs: { src: _vm.runterladenIcon }
                                          })
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              )
                            ]
                          )
                        })
                      ],
                      2
                    )
              ]
            )
          ],
          1
        )
      ]),
      _vm.videoUrl
        ? _c(
            "v-overlay",
            { attrs: { opacity: "0.95", "z-index": "99" } },
            [
              _c(
                "v-icon",
                {
                  staticStyle: {
                    position: "fixed",
                    top: "50px",
                    right: "50px"
                  },
                  attrs: { large: "", dark: "" },
                  on: {
                    click: function($event) {
                      _vm.videoUrl = false
                    }
                  }
                },
                [_vm._v("fas fa-times ")]
              ),
              _c("video", { attrs: { src: _vm.videoUrl, controls: "" } }, [
                _vm._v(" Your browser does not support the video tag. ")
              ])
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-snackbar",
        {
          attrs: { bottom: "", color: "error", timeout: "3000" },
          model: {
            value: _vm.snackbar,
            callback: function($$v) {
              _vm.snackbar = $$v
            },
            expression: "snackbar"
          }
        },
        [_vm._v(" " + _vm._s(_vm.snackbarText) + " ")]
      ),
      _c(
        "v-snackbar",
        {
          attrs: {
            bottom: "",
            color: _vm.snackbarDeleteColor,
            timeout: "3000"
          },
          model: {
            value: _vm.snackbarDelete,
            callback: function($$v) {
              _vm.snackbarDelete = $$v
            },
            expression: "snackbarDelete"
          }
        },
        [_vm._v(" " + _vm._s(_vm.snackbarDeleteText) + " ")]
      ),
      _c(
        "v-snackbar",
        {
          staticStyle: { "z-index": "9999 !important" },
          attrs: { bottom: "", color: "grey", timeout: "5000" },
          model: {
            value: _vm.snackbarFileNotSupported,
            callback: function($$v) {
              _vm.snackbarFileNotSupported = $$v
            },
            expression: "snackbarFileNotSupported"
          }
        },
        [
          _vm._v(
            " Diese Datei wird vom Editor nicht unterstützt. Bitte lade sie herunter. "
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }