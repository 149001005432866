var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-menu",
        {
          attrs: { "close-on-content-click": false, "nudge-bottom": "45px" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                var attrs = ref.attrs
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        {
                          staticClass: "colorButton ml-0",
                          style: "background-color: " + _vm.currentColor,
                          attrs: { dark: "", "x-small": "", elevation: "0" }
                        },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    )
                  )
                ]
              }
            }
          ])
        },
        [
          _c(
            "div",
            { attrs: { id: "colorButtonContainer" } },
            _vm._l(_vm.colors, function(color, index) {
              return _c(
                "v-btn",
                {
                  key: index,
                  staticClass: "colorButton",
                  class: { currentColor: color === _vm.currentColor },
                  style: "background-color: " + color,
                  attrs: { "x-small": "", elevation: "1" },
                  on: {
                    click: function($event) {
                      return _vm.callback(color)
                    }
                  }
                },
                [
                  color === _vm.currentColor
                    ? _c("img", { attrs: { src: _vm.fertigIcon } })
                    : _vm._e()
                ]
              )
            }),
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }