import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import vuetify from './plugins/vuetify';
import store from './store';
import Main from "./views/Main";
import PlanSchueler from "./components/Schueler/PlanSchueler";
import TextToSpeech from "./components/TextToSpeech";
import editImg from "./components/Schueler/EditSchueler";
import EditLehrer from "./components/Lehrer/EditLehrer";
import '@fortawesome/fontawesome-free/css/all.css'
import Login from "./components/Login";
import PlanLehrer from "./components/Lehrer/PlanLehrer";
import BackendApi from "./components/Demo/BackendApi";
import FilePreview from "./components/FilePreview";
import Verwaltung from "./views/Verwaltung";
import Plan from "./components/Verwaltung/Plan";
import Groups from "./components/Verwaltung/Groups";
import Lehrer from "./components/Verwaltung/Lehrer";
import Schueler from "./components/Verwaltung/Schueler";
import Landingpage from "./views/Landingpage";
import Datenschutz from "./views/Datenschutz";
import Impressum from "./views/Impressum";
import './registerServiceWorker'
import Faecher from "./components/Verwaltung/Faecher";
import Zeitslots from "./components/Verwaltung/Zeitslots";
import Chat from "./components/Verwaltung/ChatVerwaltung";
import LongPress from 'vue-directive-long-press';

Vue.directive('long-press', LongPress);

Vue.config.productionTip = false;

Vue.use(VueRouter);
const routes = [
  { path: '', redirect: '/main' },
  { path: '*', redirect: '/' },
  { path: '/tts', name: 'tts', component: TextToSpeech, protected: true},
  { path: '/edit', name: 'edit', component: editImg, protected: true},
  { path: '/view', name: 'view', component: FilePreview, protected: true},
  { path: '/home', name: 'Landingpage', component: Landingpage},
  { path: '/datenschutz', name: 'Datenschutz', component: Datenschutz},
  { path: '/impressum', name: 'Impressum', component: Impressum},
  {
    path: '/main',
    component: Main,
    children: [
      {
        path: '',
        name: 'main',
        redirect: '/main/login'
      },
      {
        path: 'login',
        name: 'main.login',
        component: Login,
        protected: true,
      },
    ],
  },
  {
    path: '/schueler',
    component: Main,
    children: [
      {
        path: '',
        name: 'schueler',
        redirect: '/schueler/plan'
      },
      {
        path: 'plan',
        name: 'schueler.plan',
        component: PlanSchueler,
        protected: true,
      },
      {
        path: 'edit',
        name: 'schueler.edit',
        component: editImg,
        protected: true,
      },
      {
        path: 'view',
        name: 'schueler.view',
        component: FilePreview,
        protected: true,
      },

    ],
  },
  {
    path: '/lehrer',
    component: Main,
    children: [
      {
        path: '',
        name: 'lehrer',
        redirect: '/lehrer/plan'
      },
      {
        path: 'plan',
        name: 'lehrer.plan',
        component: PlanLehrer,
        protected: true,
      },
      {
        path: 'edit',
        name: 'lehrer.edit',
        component: EditLehrer,
        protected: true,
      },
      {
        path: 'view',
        name: 'lehrer.view',
        component: FilePreview,
        protected: true,
      },
    ],
  },
  {
    path: '/verwaltung',
    component: Verwaltung,
    children: [
      {
        path: '',
        name: 'verwaltung',
        redirect: '/verwaltung/plan'
      },
      {
        path: 'plan',
        name: 'verwaltung.plan',
        component: Plan,
        protected: true,
      },
      {
        path: 'schwarzes-brett',
        name: 'verwaltung.blackboard',
        component: SchwarzesBrett,
        protected: true,
      },
      {
        path: 'groups',
        name: 'verwaltung.groups',
        component: Groups,
        protected: true,
      },
      {
        path: 'faecher',
        name: 'verwaltung.faecher',
        component: Faecher,
        protected: true,
      },
      {
        path: 'lehrer',
        name: 'verwaltung.lehrer',
        component: Lehrer,
        protected: true,
      },
      {
        path: 'schueler',
        name: 'verwaltung.schueler',
        component: Schueler,
        protected: true,
      },
      {
        path: 'zeitslots',
        name: 'verwaltung.zeitslots',
        component: Zeitslots,
        protected: true,
      },
      {
        path: 'chat-verwaltung',
        name: 'verwaltung.chatWidget',
        component: ChatVerwaltung,
        protected: true,
      },
    ],
  },
];

if (process.env.NODE_ENV === 'development') {
  console.log("Add backend-api");
  routes.push({ path: '/backend-api', name: 'backendApi', component: BackendApi})
}

const router = new VueRouter({
  mode: 'history',
  routes,
});

import vuescroll from 'vuescroll';
import SchwarzesBrett from "./components/Verwaltung/SchwarzesBrett";
import ChatWidget from "./components/Demo/ChatWidget";
import ChatVerwaltung from "./components/Verwaltung/ChatVerwaltung";

// You can set global config here.
Vue.use(vuescroll);

Vue.prototype.$vuescrollConfig = {
  bar: {
    background: '#0f0'
  }
};

function hasQueryParams(route) {
  return !!Object.keys(route.query).length
}

router.beforeEach((to, from, next) => {
  if(!hasQueryParams(to) && hasQueryParams(from)){
    next({name: to.name, query: from.query});
  } else {
    next()
  }
});

new Vue({
  store,
  router,
  vuetify,
  icons: {
    iconfont: 'fa',
  },
  render: h => h(App)
}).$mount('#app');

